export const salesChartData = {
    monthly: [
        {hKey: 'Jan', Requested: 300, Received: 150},
        {hKey: 'Feb', Requested: 400, Received: 200},
        {hKey: 'Mar', Requested: 350, Received: 175},
        {hKey: 'Apr', Requested: 500, Received: 250},
        {hKey: 'May', Requested: 300, Received: 150},
        {hKey: 'Jun', Requested: 450, Received: 225},
        {hKey: 'Jul', Requested: 500, Received: 250},
        {hKey: 'Aug', Requested: 650, Received: 375},
        {hKey: 'Sep', Requested: 500, Received: 300},
        {hKey: 'Oct', Requested: 700, Received: 350},
        {hKey: 'Nov', Requested: 450, Received: 225},
        {hKey: 'Dec', Requested: 300, Received: 200},
    ],
    yearly: [
        {hKey: '2023', Requested: 250, Received: 125},
        {hKey: '2024', Requested: 350, Received: 175},
        {hKey: '2025', Requested: 320, Received: 160},
        {hKey: '2026', Requested: 400, Received: 200},
        {hKey: '2027', Requested: 550, Received: 275},
        {hKey: '2028', Requested: 450, Received: 225},
        {hKey: '2029', Requested: 600, Received: 375},
        {hKey: '2030', Requested: 400, Received: 300},
        {hKey: '2031', Requested: 500, Received: 350},
        {hKey: '2032', Requested: 650, Received: 225},
        {hKey: '2032', Requested: 450, Received: 300}
    ]
};
