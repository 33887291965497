import React from "react";
import Div from "@jumbo/shared/Div";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonalDetails from "../Teams/PersonalDetails";
import OfficialDetails from "../Teams/OfficialDetails";
import PaymentDetails from "../Teams/PaymentDetails";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Div
          sx={{
            pt: 3,
          }}
        >
          {children}
        </Div>
      )}
    </div>
  );
}

const UpperSelectBar = ({ setAddLawyer }) => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const fromCase = queryParameters.get("fromCase");
  const caseId = queryParameters.get("caseId");
  console.log(fromCase);
  const navigate = useNavigate();
  const [values, setValues] = React.useState(0);
  const [buttonValue, setButtonValue] = React.useState(0);
  const [professionalProfile, setProfessionalProfile] = useState(null);
  const [isLawyerInDB, setIsLawyerInDB] = useState(false);
  const [isPhoneNumberFilled, setIsPhoneNumberFilled] = useState(false);
  const handleNextButton = () => {
    if (fromCase && values === 1) {
      if(caseId){
        navigate(`/lawyers/casedetail?caseId=${caseId}&lawyerId=${lawyerId}`)
      }
      else{
        navigate(`/lawyers/cases?addCase=true&lawyerId=${lawyerId}`);
      } 
     
    } 
    else {
      if (values !== 1) {
        setButtonValue((prevButtonValue) => prevButtonValue + 1);
        setValues((prevButtonValue) => prevButtonValue + 1);
      }
      else{
navigate("/lawyers/teams")
      }
    }
  };

  const handleBackButton = () => {
    if (fromCase) {
      if(caseId){
        navigate(`/lawyers/casedetail?caseId=${caseId}&lawyerId=${lawyerId}`)
      }
      else{
        navigate(`/lawyers/cases?addCase=true&lawyerId=${lawyerId}`);
      }
      
    } else {
      if (values !== 0) {
        setButtonValue((prevButtonValue) => prevButtonValue - 1);
        setValues((prevButtonValue) => prevButtonValue - 1);
      }else{
        navigate("/lawyers/teams");
      }
    }
  };

  const [lawyerId, setLawyerId] = useState("");
  return (
    <Div
      sx={{
        width: "100%",
      }}
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "center",
          // borderBottom: 1,
          // borderColor: "divider",

          //maxWidth: { xs: 300, sm: 600, md: 700, lg: 800 },
          //   overflowX: "scroll",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={values}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            sx={{
              fontSize: { xs: "14px", md: "14px" },
              fontWeight: "800",
            }}
            label="Personal Details"
          />
          <Tab
            sx={{
              fontSize: { xs: "14px", md: "14px" },
              fontWeight: "800",
            }}
            label="Professional Details"
          />
          {/* <Tab
            sx={{
              fontSize: { xs: "14px", md: "14px" },
              fontWeight: "800",
            }}
            label="Payment Details"
          /> */}
        </Tabs>
      </Div>
      <TabPanel value={values} index={0}>
        <PersonalDetails
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
          setAddLawyer={setAddLawyer}
          setProfessionalProfile={setProfessionalProfile}
          setLawyerId={setLawyerId}
          isLawyerInDB={isLawyerInDB}
          setIsLawyerInDB={setIsLawyerInDB}
          isPhoneNumberFilled={isPhoneNumberFilled}
          setIsPhoneNumberFilled={setIsPhoneNumberFilled}
        />
      </TabPanel>
      <TabPanel value={values} index={1}>
        <OfficialDetails
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
          lawyerId={lawyerId}
          setLawyerId={setLawyerId}
          professionalProfile={professionalProfile}
          isLawyerInDB={isLawyerInDB}
          isPhoneNumberFilled={isPhoneNumberFilled}
        />
      </TabPanel>
    </Div>
  );
};

export default UpperSelectBar;
