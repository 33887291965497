import {
  SIDEBAR_ANCHOR_POSITIONS,
  SIDEBAR_SCROLL_TYPES,
  SIDEBAR_STYLES,
  SIDEBAR_VARIANTS,
  SIDEBAR_VIEWS,
} from "@jumbo/utils/constants/layout";
import VerticalDefault from "./vertical-default/VerticalDefault";
import SoloPage from "./solo-page/SoloPage";
import HorizontalLayout from "./horizontal-default/HorizontalLayout";
const LAYOUT_NAMES = {
  VERTICAL_DEFAULT: "vertical-default",
  SOLO_PAGE: "solo-page",
  HORIZONTAL_LAYOUT: "horizontal-layout",
  DEFAULT : "default"
};

const LAYOUTS = [
  {
    name: LAYOUT_NAMES.VERTICAL_DEFAULT,
    label: "Vertical Default",
    component: VerticalDefault,
    layoutOptions: {
      sidebar: {
        hide: false,
        variant: SIDEBAR_VARIANTS.PERSISTENT,
        style: SIDEBAR_STYLES.FULL_HEIGHT,
        view: SIDEBAR_VIEWS.FULL,
        scrollType: SIDEBAR_SCROLL_TYPES.FIXED,
        anchor: SIDEBAR_ANCHOR_POSITIONS.LEFT,
      },
      header: {
        hide: false,
        fixed: true,
      },
      footer: {
        hide: false,
      },
    },
  },
  {
    name: LAYOUT_NAMES.DEFAULT,
    label: "default",
    component: VerticalDefault,
    layoutOptions: {
      sidebar: {
        hide: false,
        variant: SIDEBAR_VARIANTS.PERMANENT,
        view: SIDEBAR_VIEWS.MINI,
        style: SIDEBAR_STYLES.FULL_HEIGHT,
        open: false,
        // scrollType: SIDEBAR_SCROLL_TYPES.FIXED,
        anchor: SIDEBAR_ANCHOR_POSITIONS.LEFT,
      },
      header: {
        hide: false,
        fixed: true,
      },
      footer: {
        hide: false,
      },
    },
  },

  {
    name: LAYOUT_NAMES.SOLO_PAGE,
    label: "Solo Page",
    component: SoloPage,
    layoutOptions: {
      sidebar: {
        hide: true,
        open: false,
        anchor: SIDEBAR_ANCHOR_POSITIONS.BOTTOM,
      },
      // sidebar: {
      //   hide: false,
      //   variant: SIDEBAR_VARIANTS.PERSISTENT,
      //   style: SIDEBAR_STYLES.FULL_HEIGHT,
      //   view: SIDEBAR_VIEWS.FULL,
      //   scrollType: SIDEBAR_SCROLL_TYPES.FIXED,
      //   anchor: SIDEBAR_ANCHOR_POSITIONS.LEFT,
      // },
      header: {
        hide: true,
      },
      footer: {
        hide: true,
      },
    },
  },

  {
    name: LAYOUT_NAMES.HORIZONTAL_LAYOUT,
    label: "Horizontal Layout",
    component: HorizontalLayout,
    layoutOptions: {
      //   sidebar: {
      //     hide: true,
      //     open: false,
      //     anchor: SIDEBAR_ANCHOR_POSITIONS.BOTTOM,
      //   },
      sidebar: {
        hide: false,
        variant: SIDEBAR_VARIANTS.PERSISTENT,
        style: SIDEBAR_STYLES.FULL_HEIGHT,
        view: SIDEBAR_VIEWS.FULL,
        scrollType: SIDEBAR_SCROLL_TYPES.FIXED,
        anchor: SIDEBAR_ANCHOR_POSITIONS.LEFT,
      },
      header: {
        hide: true,
      },
      footer: {
        hide: true,
      },
    },
  },
];

export default LAYOUT_NAMES;
export { LAYOUTS };
