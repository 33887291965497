import { Card, CardHeader, Typography, Divider } from "@mui/material";
import React from "react";
import Div from "@jumbo/shared/Div/Div";

function DisplayCard({ title, subheader, Icon }) {
  const IconComponent = Icon;
  return (
    <Card
      sx={{
        backgroundColor: "black",
        color: "white",
        // minHeight: "104px",
        height: "100%",
        display: "flex",
        pt: 0,
        flexDirection: "column",
        mt:"-2.5px"
      }}
    >
      <CardHeader
      title={<Typography sx={{ fontSize: "1rem" }}>{title ? title : 0}</Typography>}
      avatar={IconComponent}
      sx={{
        px:"20px"
      }}
      subheader={  <Typography
        sx={{ fontSize: "0.70rem"}}
      >{subheader}</Typography>}
    />
    </Card>
  );
}

export default DisplayCard;
