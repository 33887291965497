import React from "react";
import { Typography, useTheme } from "@mui/material";

import Box from "@mui/material/Box";

import Div from "@jumbo/shared/Div/Div";
import GavelIcon from "@mui/icons-material/Gavel";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { updateAddressSettings } from "app/Apis/settings";
import { useState } from "react";
import { Modal } from "@mui/material";
import { Button } from "@mui/material";

const AddressChip = ({
  Add,
  editId,
  setEditId,
  handleOpen,
  initSettings,
  setInitSettings,
  lawyerId,
  accessToken,
  // setIsEditing
}) => {
  console.log(initSettings);
  console.log(Add);
  const theme = useTheme();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const handleDelete = async () => {
    console.log(initSettings);
    console.log(lawyerId);
    console.log(accessToken);
    let addressDetails = initSettings?.addressDetails;
    if (addressDetails) {
      const index = addressDetails.findIndex(
        (add) => add.addressId === Add.addressId
      );
      if (index === -1) {
        throw Error("Id not found for address to be deleted");
      } else {
        addressDetails[index] = { ...Add, isActive: 0 };
      }
      setShowDeleteModal(false);

      const response = await updateAddressSettings(
        initSettings,
        addressDetails,
        lawyerId,
        accessToken
      );
      if (response) {
        console.log(response);
        console.log("address deleted successfully successfully");
        setInitSettings(response);
      }
    }
  };

  const handleEdit = () => {
    if (Add?.addressId) {
      setEditId(Add?.addressId);
      // setIsEditing(true);
      handleOpen();
    }
  };

  // const handleEdit = () => {
  //   if (Add?.addressId) {
  //     setEditId(Add?.addressId);
  //     setShowEditConfirmation(true);
  //   }
  // };

  // const handleEditConfirmation = () => {
  //   setShowEditConfirmation(false);
  //   handleOpen();
  // };

  return (
    <Div
      alignItems="flex-start"
      sx={{
        mt: 1,
        px: 1,
        py: 1,
        minHeight: "150px",
        border: "1px solid rgb(232, 232, 232)",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderColor: theme.palette.divider,
          pb: 1,
          mb: 1,
        }}
      >
        <Typography
          variant="h3"
          color="black"
          sx={{
            fontSize: "1rem",
            mb: "1",
            fontWeight: 500,
            color: "rgb(79,79,79)",
            textTransform: "capitalize",
            width: "100%",
          }}
        >
          {Add?.addressLabel}
        </Typography>

        {(() => {
          if (Add?.addressLabel === "home") {
            return <HomeIcon sx={{ mb: 0 }} />;
          } else if (Add?.addressLabel === "court") {
            return <GavelIcon sx={{ mb: 0 }} />;
          } else if (Add?.addressLabel === "office") {
            return <WorkIcon sx={{ mb: 0 }} />;
          } else {
            return null;
          }
        })()}
      </Div>

      <Box 
      // display="flex" 
      // alignItems="center"
      >
        <Box 
        // display="flex" 
        // alignItems="center"
        height="85%"
        >
          <Typography
  variant="body2"
  fontSize={"0.75rem"}
  fontFamily={"NoirPro, Arial"}
  fontWeight={"400"}
  lineHeight={"1.5"}
  color={"rgb(133, 149, 166)"}
  height={"80%"} 
  sx={{
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    maxWidth: "100%",
    maxHeight: "100%",
  }}
>
  {`${Add?.completeAddress}, ${Add?.streetName}, ${Add?.landMark}`}
</Typography>

        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          position: "absolute",
          bottom: "0.25rem",
          left: "1rem",
          mt: "0.625rem",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          mb={1}
          sx={{
            "&:hover": {
              "& .edit": {
                color: theme.palette.primary.light,
                cursor: "pointer",
              },
              "& .arrow": {
                color: theme.palette.primary.light,
                transform: "translateX(5px)",
                transition: "transform 0.5s ease-in-out",
              },
            },
          }}
        >
          <Typography
            variant="body2"
            className="edit"
            fontSize={"0.875rem"}
            fontFamily={"NoirPro, Arial"}
            fontWeight={"400"}
            lineHeight={"1.5"}
            color={"rgb(133, 149, 166)"}
            onClick={handleEdit}
          >
            Edit
          </Typography>
          <ArrowRightIcon className={"arrow"} />

          <Typography
            variant="body2"
            fontSize={"0.875rem"}
            fontFamily={"NoirPro, Arial"}
            fontWeight={"400"}
            lineHeight={"1.5"}
            color={"rgb(133, 149, 166)"}
            onClick={() => setShowDeleteModal(true)} 
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
              ml: 1,
            }}
          >
            Delete
          </Typography>
        </Box>
      </Box>

      {/* Delete Modal */}
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            minWidth: 300,
            maxWidth: 500,
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" id="delete-modal-title" gutterBottom>
            Are you sure you want to delete this address?
          </Typography>
          <Typography variant="body1" id="delete-modal-description">
            This action cannot be undone.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDelete}
              sx={{ mr: 2 }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

        {/* Edit Confirmation Modal */}
        {/* <Modal
        open={showEditConfirmation}
        onClose={() => setShowEditConfirmation(false)}
        aria-labelledby="edit-confirmation-modal-title"
        aria-describedby="edit-confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            minWidth: 300,
            maxWidth: 500,
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" id="edit-confirmation-modal-title" gutterBottom>
            Are you sure you want to edit?
          </Typography>
          <Typography variant="body1" id="edit-confirmation-modal-description">
            This action cannot be undone.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditConfirmation}
              sx={{ mr: 2 }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowEditConfirmation(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal> */}
    </Div>
  );
};

export default AddressChip;