import React, { useEffect, useState } from "react";

import { Alert, Button, Snackbar, Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import EachClient from "./EachClient";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { ClientValidations } from "app/utils/validators";
import RenderClient from "./RenderClient";
import { caseOppClient } from "app/Apis/case";
import { useSelector,useDispatch } from "react-redux";
import { updateCaseOppClients } from "app/redux/actions/dashboard";
import { updateCaseListItem } from "app/redux/actions/case";
function OppositeClient({ handleBackButton, handleNextButton, setTeam,setIsLoading }) {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [count, setCount] = useState(1);
  const oppClientsFromRedux = useSelector((state)=>state?.dashboard?.oppositeClients);
  const [addClient, setAddClient] = useState(false);
  const [addClientError, setAddClientError] = useState();
  const [error, setError] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [selectedPeople,setSelectedPeople] =useState(oppClientsFromRedux);
  const [checkSubmit, setCheckSubmit] = useState(false);
  const [showData, setShowData] = useState(false);

  const caseId = useSelector((state)=>state?.dashboard?.caseDetails?._id);

  const AddClient = () => {
    setAddClientError(false);
    setCount((state) => state + 1);
  };
  const UpdateCaseListItem = (value) => {
    dispatch(updateCaseListItem(value));
  };
  const handleSubmit = async()=>{
    try{
      setIsLoading(true)
      if(selectedPeople.length>0){
        const response = await caseOppClient(caseId,selectedPeople);
        if(response){
          console.log(response);
          dispatch(updateCaseOppClients(response.oppositeClients))
          UpdateCaseListItem(response)
        }
        
      } 
      setIsLoading(false)
      handleNextButton();
    }catch(err){
      setIsLoading(false)
      console.log(err);
    }
  }


  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },
          height: "100%",
        }}
      >
        <RenderClient
          addClient={addClient}
          setAddClient={setAddClient}
          showData={showData}
          setShowData={setShowData}
          setTeam={setTeam}
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
          selectedPeople={selectedPeople}
          setSelectedPeople={setSelectedPeople}
          clientNo={count}
          setClientNo={setCount}
          name={"Opposite_Client"}
          setError={setError}
          poepleType={"Opposite_Client"}
        />

        <Div
          sx={{
            width: "100%",
            pl: 2,
            pr: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
            // backgroundColor: "green",
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            //   disabled={values !== 0 ? false : true}
            variant="contained"
            sx={{
              "width": 100,
              "padding": 1,
              "backgroundColor": "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={handleSubmit}
            color="secondary"
            sx={{
              "width": 100,
              "padding": 1,
              "backgroundColor": "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default OppositeClient;
