import React, { useEffect, useState,lazy } from "react";
import { NoCaseContainer } from "./style";
import HearingListLayout from "./HearingListLayout";
import useHearings from "app/hooks/useHearings";
import { useLocation, useParams } from "react-router-dom";
import { getCasesList } from "app/Apis/case";
import Cookies from "js-cookie";
import { filter } from "app/utils/filter";

const AddHearing = lazy(()=> import("./AddHearings"));



const optionsFilter = ["Select","Today","Yesterday","Tomorrow", "This Week","This Month","Choose Date"];
function NoHearings(){
  const [addHearing,setAddHearing]=useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hearingEdit, setHearingEdit] = useState(null);
  const [hearingList, setHearingList] = useState(null);
  const [showUpdate, setShowUpdate] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState("5");
  const [basicFilter, setBasicFilter] = useState(optionsFilter[0]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [update, setUpdate] = useState(false)
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const hearing = queryParameters.get("hearing");
  const accessToken = Cookies.get("accessToken");
  const date=filter(basicFilter,startDate,endDate)
  const getHearingsList = useHearings();
  const getAllHearings = async () => {
    setIsLoading(true);
    const response = await getCasesList(accessToken,pageNumber, pageSize,0,basicFilter, startDate,endDate);
    if (response) {
      console.log(response?.cases);
      setHearingList(response?.cases);
      setTotalPages(response?.totalPages)
      setIsLoading(false);
    }
  };
 
  useEffect(() => {
   
    if (basicFilter !== "Choose Date") {
      getAllHearings()
    }
    else if (basicFilter === "Choose Date") {
      if (startDate && endDate) {
        getAllHearings()
      }
    }
  }, [pageSize, basicFilter,pageNumber]);
  useEffect(() => {
    getAllHearings()
  }, [update]);
  

  useEffect(() => {
    setAddHearing(false);
    if(hearing){
      setAddHearing(true)
       console.log(" trigger")
    }
  }, []);
console.log(addHearing)
  if (addHearing) {
    return (
      <AddHearing
        setAddHearing={setAddHearing}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        hearingEdit={hearingEdit}
        setHearingEdit={setHearingEdit}
        setShowUpdate={setShowUpdate}
        showUpdate={showUpdate}
        setUpdate={setUpdate}
      />
    );
  } else {
    return (
      <NoCaseContainer>
          <HearingListLayout
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          optionsFilter={optionsFilter}
          basicFilter={basicFilter}
          setBasicFilter={setBasicFilter}
            setAddHearing={setAddHearing}
            setIsEditing={setIsEditing}
            setHearingEdit={setHearingEdit}
            hearingList={hearingList}
            setHearingList={setHearingList}
            totalPages={totalPages}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            showUpdate={showUpdate}
            setShowUpdate={setShowUpdate}
            isLoading={isLoading}
            getAllHearings={getAllHearings}
          />
      </NoCaseContainer>
    );
  }
}

export default NoHearings;
