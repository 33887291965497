import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import {
	Autocomplete,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useFormik } from "formik";
import { todoEditValidations, todoValidations } from "app/utils/validators";
import { LoadingButton } from "@mui/lab";
import { createTodoTask } from "app/Apis/task";
import { getCases } from "app/Apis/case";
import { useSelector } from "react-redux";
import AddDocuments from "app/Components/Documents/AddDocuments/AddDocuments";
import EditDocuments from "app/Components/Documents/EditDocuments/EditDocuments";
import { useNavigate } from "react-router-dom";
const priorityOptions = ["Low", "Medium", "High"];

const TaskDetails = ({
	setTasksList,
	setAddTaskOpen,
	setTaskAdded,
	taskEditItem,
	setTaskEditItem,
	inCase,
}) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	console.log(taskEditItem);
	const accessToken = useSelector((state) => {
		return state?.onboardingInputs?.accessToken;
	});
	const [caseDetails, setCaseDetails] = React.useState(
		taskEditItem ? taskEditItem : null
	);
	console.log(taskEditItem);
	const [fieldTask, setFieldTask] = React.useState(
		taskEditItem?.taskName ? taskEditItem?.taskName : ""
	);
	const [fieldDescription, setFieldDescription] = React.useState(
		taskEditItem?.taskDescription ? taskEditItem?.taskDescription : ""
	);
	const [documentsArray, setDocumentsArray] = useState(null);
	const [documentsAPIList, setDocumentsAPIList] = useState(
		taskEditItem?.documentLists ? taskEditItem?.documentLists : null
	);

	const [priority, setPriority] = React.useState(
		taskEditItem?.priority >= 0
			? priorityOptions[taskEditItem?.priority]
			: "High"
	);
	console.log(
		taskEditItem?.priority >= 0
			? priorityOptions[taskEditItem?.priority]
			: "High"
	);
	console.log(taskEditItem?.priority);

	const fullName = useSelector((state) => {
		return state?.onboardingInputs?.fullName;
	});
	// const accessToken = useSelector((state) => {
	//   return state?.onboardingInputs?.accessToken;
	// });

	const [openCase, setOpenCase] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [caseOptions, setCaseOptions] = useState([]);
	const loading = openCase && caseOptions.length === 0;

	React.useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			const response = await getCases(accessToken, 0, 100, 1);
			console.log(response);
			if (active) {
				setCaseOptions(response);
			}
		})();

		return () => {
			active = false;
		};
	}, [loading]);
	React.useEffect(() => {
		if (!openCase) {
			setCaseOptions([]);
		}
	}, [openCase]);
	const navigate = useNavigate();
	console.log(taskEditItem?.taskDescription);
	const formik = useFormik({
		initialValues: {
			caseDetails: caseDetails,
			taskName: fieldTask,
			taskDescription: fieldDescription,
			priority: priority,
		},
		validationSchema: todoEditValidations,
		enableReinitialize: true,
		onSubmit: async (values) => {
			setBtnLoading(true);
			console.log(values, "FORMIK");
			let updatedTaskToAPI = {
				isActive: 1,
				taskDescription: formik.values.taskDescription,
				taskName: formik.values.taskName,
				documentLists: documentsAPIList,
				priority: priorityOptions.findIndex(
					(option) => option === formik.values.priority
				),
				taskType: "todo",
				isVisibleToClient: 1,
				caseId: formik.values.caseDetails
					? formik.values.caseDetails?.caseId
					: null,
			};
			if (taskEditItem) {
				updatedTaskToAPI = {
					isActive: 1,
					tasksId: taskEditItem?.tasksId,
					taskDescription: formik.values.taskDescription,
					taskName: formik.values.taskName,
					documentLists: documentsAPIList,
					priority: priorityOptions.findIndex(
						(option) => option === formik.values.priority
					),
					taskType: "todo",
					isVisibleToClient: 1,
					isAdmissibleEvidence: 1,
					caseId: formik.values.caseDetails
						? formik.values.caseDetails?.caseId
						: null,
				};
			}
			const response = await createTodoTask(accessToken, updatedTaskToAPI);
			if (response) {
				console.log(response);
				console.log("task added successFully");
				setTaskAdded(true);
				setAddTaskOpen(false);
				setBtnLoading(false);
				if (taskEditItem) {
					setTaskEditItem(null);
				}
				if (formik.values.caseDetails?.caseId) {
					navigate(
						`/lawyers/casedetail?caseId=${formik.values.caseDetails?.caseId}&value=1`
					);
				}
			}
		},
	});

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				paddingBottom: "30px",
			}}
		>
			{/* <CaseContainer> */}
			<Div
				sx={{
					marginLeft: { xs: "5%", md: "18%" },
					marginRight: { xs: "5%", md: "18%" },

					height: "100%",
				}}
			>
				<Typography variant="h6" sx={{ mt: "0px" }}>
					{taskEditItem ? "Edit Task" : "Add Task"}
				</Typography>

				{/* task name */}

				<TextField
					sx={{ mb: 0, mt: 2 }}
					style={{ width: "100%" }}
					id="outlined-multiline-flexible"
					label="Task Name*"
					// multiline
					color="secondary"
					// inputProps={{ maxLength: 15 }}
					// name="barcouncilnumber"
					maxRows={4}
					value={formik.values.taskName}
					// onChange={formik.handleChange}
					onChange={(e) => setFieldTask(e.target.value)}
					error={formik.errors.taskName && formik.touched.taskName}
					name="taskName"
					helperText={formik.errors.taskName}
				/>

				{/* description */}

				<TextField
					sx={{ mb: 0, mt: 2, width: "100%" }}
					id="outlined-multiline-static"
					label="Task Description"
					color="secondary"
					placeholder="Task Description"
					multiline
					rows={4}
					value={formik.values.taskDescription}
					//  onChange={formik.handleChange}
					onChange={(e) => setFieldDescription(e.target.value)}
					error={
						formik.errors.taskDescription && formik.touched.taskDescription
					}
					name="taskDescription"
					helperText={formik.errors.taskDescription}
					// value={fieldDescription}
					// onChange={(e) => setFieldDescription(e.target.value)}
				/>

				{/* Task for case */}

				{taskEditItem?.caseId ? (
					<TextField
						disabled
						value={taskEditItem?.caseName}
						sx={{ mb: 0, mt: 2, width: "100%" }}
					/>
				) : (
					<Autocomplete
						style={{ width: "100%" }}
						value={formik.values.caseDetails}
						// disabled={Boolean(taskEditItem)}
						onChange={(event, value) => {
							// formik.setFieldValue("caseId", value?.caseId);
							setCaseDetails(value);
							console.log(value?.caseId);
						}}
						open={openCase}
						onOpen={() => {
							setOpenCase(true);
						}}
						onClose={() => {
							setOpenCase(false);
						}}
						loading={loading}
						options={caseOptions}
						isOptionEqualToValue={(option, value) => {
							return option.caseId === value.caseId;
						}}
						getOptionLabel={(option) =>
							`${option?.caseFirstName} vs ${option?.caseSecondName}`
						}
						renderOption={(props, option, { selected }) => {
							console.log(
								`${option?.caseFirstName}vs${option?.caseSecondName}`
							);
							return (
								<li
									{...props}
								>{`${option?.caseFirstName} vs ${option?.caseSecondName}`}</li>
							);
						}}
						sx={{ width: 500, mt: 2 }}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Case"
								placeholder="Case"
								color="secondary"
								error={formik.errors.caseDetails && formik.touched.caseDetails}
								name="caseId"
								helperText={formik.errors.caseDetails}
							/>
						)}
					/>
				)}

				{/* assigned by */}

				<TextField
					disabled
					value={fullName}
					sx={{ mb: 0, mt: 2, width: "100%" }}
				/>

				{/* priority */}
				<Div
					sx={{
						display: "flex",
						alignItems: { sm: "center", xs: "flex-start" },
						mb: 0,
						mt: 2,
						flexDirection: { xs: "column", sm: "row" },
					}}
				>
					<Typography variant="h6" sx={{ mr: 3 }}>
						Priority*
					</Typography>
					<FormControl
						sx={{
							display: { sm: "flex" },
							flexDirection: { sm: "column" },
							alignItems: { sm: "center" },
						}}
					>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="priority"
							defaultValue={priority}
							onChange={(e, value) => setPriority(value)}
							error={formik.errors.priority && formik.touched.priority}
							helperText={formik.errors.priority}
						>
							<FormControlLabel
								value="High"
								control={<Radio color="secondary" />}
								label="High"
							/>
							<FormControlLabel
								value="Medium"
								control={<Radio color="secondary" />}
								label="Medium"
							/>
							<FormControlLabel
								value="Low"
								control={<Radio color="secondary" />}
								label="Low"
							/>
						</RadioGroup>
					</FormControl>
				</Div>

				{/* attached documents */}
				<Typography variant="h6" sx={{ mt: "0px" }}>
					{taskEditItem ? "Documents" : "Upload Documents"}
				</Typography>
				{taskEditItem ? (
					<EditDocuments
						documentsArray={documentsArray}
						setDocumentsArray={setDocumentsArray}
						documentsAPIList={documentsAPIList}
						setDocumentsAPIList={setDocumentsAPIList}
					/>
				) : (
					<AddDocuments
						documentsArray={documentsArray}
						setDocumentsArray={setDocumentsArray}
						documentsAPIList={documentsAPIList}
						setDocumentsAPIList={setDocumentsAPIList}
						type={"tasks"}
					/>
				)}
			</Div>
			<Div
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					textAlign: "center",
					alignItems: "center",
					paddingLeft: { xs: "0%", md: "18%" },
					paddingRight: { xs: "0%", md: "18%" },
					mt: 5,
				}}
			>
				{inCase ? (
					<LoadingButton
						type="submit"
						onClick={formik.handleSubmit}
						style={{
							backgroundColor: "black",
						}}
						sx={{ width: 100, padding: 1 }}
						variant="contained"
					>
						{taskEditItem ? "Edit " : "Add "}
					</LoadingButton>
				) : (
					<>
						<LoadingButton
							type="button"
							onClick={() => {
								setAddTaskOpen(false);
								setTaskEditItem(null);
							}}
							disabled={btnLoading}
							sx={{
								width: 100,
								padding: 1,
								"&:hover": {
									backgroundColor: "#F1C40F",
								},
							}}
							variant="contained"
						>
							Back
						</LoadingButton>
						<LoadingButton
							type="submit"
							onClick={formik.handleSubmit}
							loading={btnLoading}
							sx={{
								width: 100,
								padding: 1,
								"&:hover": {
									backgroundColor: "#F1C40F",
								},
							}}
							variant="contained"
						>
							{taskEditItem ? "Submit " : "Add "}
						</LoadingButton>
					</>
				)}
			</Div>
		</div>
	);
};

export default TaskDetails;
