import React from 'react';
import Div from "@jumbo/shared/Div/Div";
import { Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


function AddAddressChip({handleOpen}) {
  return (
    <Div
      sx={{
        display:"flex",
        alignContent:"center",
        justifyContent:"center",
        mt: 1,
        p:1,
        border: "1px solid rgb(232, 232, 232)",
        borderRadius: "8px",
        cursor:"pointer",
        "&:hover":{
            backgroundColor:"rgb(232, 232, 232)"
        },
        // height:"100%",
        minHeight:"150px",
      }}
      onClick={handleOpen}
    >
        <Div
            sx={{
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"center"
            
            }}
        >
            <AddCircleOutlineIcon/>
            <Typography
                sx={{
                    fontSize:"1rem",
                    color:"rgb(79, 79, 79)",
                    fontWeight:500,
                    textTransform:"capitalize",
                }}
            >
                Add Address
            </Typography>
        </Div>    
    </Div>
  )
}

export default AddAddressChip;