import Div from "@jumbo/shared/Div";
import React from "react";
import { NoCaseContainer } from "app/pages/MyCases/style";
import NoCaseImage from "app/ImageAssets/NoCaseImage.png";
import {
  Button,

  CardContent,
  Typography,
} from "@mui/material";

const NoDraft = ({ setAddApp}) => {
  return (
    <>
    
      <Div sx={{ height: "100%", width: "100%" }}>
        <Div
          sx={{
            backgroundColor: "white",
            height: "100%",
            position: "relative",
            paddingTop: "10px",
            border: 0,
          }}
        >
          <CardContent>
            <NoCaseContainer>
              <Div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Div
                  sx={{
                    width: { xs: "60%", sm: "30%", md: "150px" },
                    height: "30%",
                  }}
                >
                  <img
                    src={NoCaseImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </Div>

                <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    textAlign: "center",
                    mt: 2,
                  }}
                >  
                  You haven't added any Drafts yet!
                </Typography>
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 4,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "secondary.main",
                      mb: 2,
                      width: "200px",
                    }}
                    onClick={() => setAddApp(true)}
                  >
                    Add Draft
                  </Button>
                </Div>
              </Div>
            </NoCaseContainer>
          </CardContent>
        </Div>
      </Div>
    </>
  );
};

export default NoDraft;
