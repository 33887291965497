import { getHearingsList } from "app/Apis/case";
import Cookies from "js-cookie";

const useHearings = () => {
  const accessToken = Cookies.get("accessToken");
  const getAllHearings = async (pageNumber, pageSize,basicFilter,startDate,endDate) => {
    if (!accessToken) {
      throw Error("No access Token provided");
    }
    const response = await getHearingsList(accessToken, pageNumber, pageSize,basicFilter,startDate,endDate);
    if (response) {
      return response;
    } else {
      return null;
    }
  };
  return getAllHearings;
};
export default useHearings;
