import React from 'react'
import NoDraft from './NoDraft'
import { DraftList } from './draftList'
import Skeletons from './Skeletons'
import Div from '@jumbo/shared/Div/Div'
import { Grid } from '@mui/material'

export const DraftingListLayout = ({draftList,isLoading, pageSize,setAddApp,setEdit,setEditItem}) => {
    console.log(draftList)
  return (
    <>
        {isLoading ? 
         <Div
         sx={{
           pl: { sm: "0", lg: "18%" },
           pr: { sm: "0", lg: "18%" },
         }}>
          <Grid container spacing={2} sx={{mt:0}}>
           {Array.from(Array(parseInt(pageSize))).map((item,index)=>{
           return <Grid item xs={12} md={6} key={index}>
             <Skeletons />
            </Grid>
             }) }
          </Grid>
         </Div>
              :
             (
              draftList?.length > 0 ?
              <DraftList
                draftList={draftList}
                setEdit={setEdit}
                setEditItem={setEditItem}
              /> :
              <NoDraft setAddApp={setAddApp}/>

            )
            }
    </>
  )
}
