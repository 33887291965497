import React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { Button, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SingleDocumentPreview from "./SingleDocumentPreview";
import { useMediaQuery } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

function DisplayDocumentsAPI({
	handleClick,
	setDocumentsAPIList,
	documentsAPIList,
	formDisable,
}) {
	const [currentDoc, setCurrentDoc] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const isMobile = useMediaQuery("(max-width: 990px)");

	const handleOpen = () => {
		setOpenModal(true);
	};
	const handleClose = () => {
		setOpenModal(false);
	};
	const handleDelete = () => {
		setModalOpen(true);
	};
	const removeDocument = (name) => {
		const newdocumentsAPIList = documentsAPIList.filter(
			(document) => document?.documentId !== name
		);
		console.log(newdocumentsAPIList);
		setDocumentsAPIList(newdocumentsAPIList);
	};
	console.log(document?.documentExtension);
	return (
		<Grid container spacing={2} direction={"row"} alignItems={"stretch"}>
			{!formDisable && (
				<Grid item md={3} xs={12}>
					<Div
						sx={{
							display: "flex",
							alignContent: "center",
							justifyContent: "center",
							// mt: 1,
							p: 1,
							border: "1px solid rgb(232, 232, 232)",
							borderRadius: "8px",
							cursor: "pointer",
							"&:hover": {
								backgroundColor: "rgb(232, 232, 232)",
							},
							minHeight: "150px",
							height: "100%",
						}}
						onClick={handleClick}
					>
						<Div
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<AddCircleOutlineIcon />
							<Typography
								sx={{
									fontSize: "1rem",
									color: "rgb(79, 79, 79)",
									fontWeight: 500,
									textTransform: "capitalize",
								}}
							>
								Add Files
							</Typography>
						</Div>
					</Div>
				</Grid>
			)}
			{documentsAPIList &&
				documentsAPIList.map((document, index) => (
					<Grid item md={3} xs={12}>
						<Div
							key={index}
							sx={{
								position: "relative",
								backgroundColor: "rgb(243 243 243)",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
								borderRadius: "8px",
							}}
						>
							{document?.documentExtension.startsWith("image/") ? (
								<img
									style={{
										width: "100%",
										height: "150px",
										borderRadius: "5px",
										objectFit: "contain",
										"&:hover": {
											cursor: "pointer",
										},
									}}
									src={document?.documentUrl}
									alt="document"
									onClick={() => {
										setCurrentDoc(documentsAPIList[index]);
										console.log("clicked");
										handleOpen();
									}}
								/>
							) : document?.documentExtension === "application/pdf" ? (
								<Div
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
										height: "150px",
										borderRadius: "5px",
										backgroundColor: "#EABF4E",
										fontSize: "1rem",
										"&:hover": {
											cursor: "pointer",
										},
									}}
									onClick={() => {
										setCurrentDoc(documentsAPIList[index]);
										console.log("clicked");
										handleOpen();
									}}
								>
									<PictureAsPdfIcon size={16} color="red" />
									<Typography
										sx={{
											maxWidth: "120px",
											overflow: "hidden", // Add overflow hidden to hide overflowing content
											whiteSpace: "nowrap", // Prevent text from wrapping
											textOverflow: "ellipsis",
										}}
									>
										{document?.documentName}
									</Typography>
								</Div>
							) : document?.documentExtension ===
							  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
								<Div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
										height: "150px",
										borderRadius: "5px",
										backgroundColor: "#EABF4E",
									}}
									onClick={() => {
										window.location.href = documentsAPIList[index].documentUrl;
									}}
								>
									<PictureAsPdfIcon
										size={12}
										color="red"
										style={{ marginLeft: "5px" }}
									/>

									<Typography
										sx={{
											maxWidth: "120px",
											overflow: "hidden", // Add overflow hidden to hide overflowing content
											whiteSpace: "nowrap", // Prevent text from wrapping
											textOverflow: "ellipsis",
										}}
									>
										{document?.documentName}
									</Typography>
								</Div>
							) : null}
							<span
								style={{
									position: " absolute",
									right: " -8px",
									top: "-8px",
									cursor: "pointer",
									borderRadius: "50%",
									height: "22px",
									width: "22px",
									// backgroundColor: "rgb(232 232 232)",
								}}
							>
								{!formDisable && (
									<CancelTwoToneIcon
										onClick={() => setModalOpen(true)}
										sx={{}}
									/>
								)}
							</span>
						</Div>
						<Modal
							open={openModal}
							onClose={handleClose}
							closeAfterTransition
							slots={{ backdrop: Backdrop }}
							slotProps={{
								backdrop: {
									timeout: 1000,
								},
							}}
						>
							<Fade in={openModal}>
								<Box
									sx={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										width: isMobile ? "100%" : "600px",
										height: isMobile ? "85%" : "550px",
										bgcolor: "background.paper",
										border: "1px solid #000",
										borderRadius: "8px",
										boxShadow: 24,
										p: 4,
									}}
								>
									<span
										style={{
											position: " absolute",
											right: " -12px",
											top: "-14px",
											cursor: "pointer",
											borderRadius: "50%",
											height: "30px",
											width: "30px",
											backgroundColor: "white",
											textAlign: "center",
											fontSize: "26px",
										}}
									>
										<CancelTwoToneIcon
											onClick={handleClose}
											fontSize="inherit"
										/>
									</span>
									<Div sx={{ width: "100%", height: "100%" }}>
										<SingleDocumentPreview document={currentDoc} />
									</Div>
								</Box>
							</Fade>
						</Modal>
						<Modal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							aria-labelledby="delete-modal-title"
							aria-describedby="delete-modal-description"
						>
							<Box
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									bgcolor: "background.paper",
									boxShadow: 24,
									p: 4,
									outline: "none",
									minWidth: 300,
									maxWidth: 500,
									borderRadius: "8px",
									textAlign: "center",
								}}
							>
								<Typography variant="h6" id="delete-modal-title" gutterBottom>
									Are you sure you want to delete this document?
								</Typography>
								<Typography variant="body1" id="delete-modal-description">
									This action cannot be undone.
								</Typography>
								<Box sx={{ mt: 2 }}>
									<Button
										variant="contained"
										color="primary"
										onClick={() => {
											removeDocument(document?.documentId);
											setModalOpen(false);
										}}
										sx={{ mr: 2 }}
									>
										Delete
									</Button>
									<Button
										variant="outlined"
										color="primary"
										onClick={() => setModalOpen(false)}
									>
										Cancel
									</Button>
								</Box>
							</Box>
						</Modal>
					</Grid>
				))}
		</Grid>
	);
}

export default DisplayDocumentsAPI;
