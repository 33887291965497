import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { PersonalDetailsValidations } from "app/utils/validators";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { createLawyersForTeams } from "app/Apis/teams";
import { searchLawyer } from "app/Apis/teams";
import EditOffIcon from "@mui/icons-material/EditOff";
import { InputAdornment, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { getUserProfile } from "app/Apis/Login";

const PersonalDetails = ({
  handleBackButton,
  handleNextButton,
  setAddLawyer,
  setLawyerId,
  setProfessionalProfile,
  isLawyerInDB,
  setIsLawyerInDB,
  isPhoneNumberFilled,
  setIsPhoneNumberFilled,
}) => {
  const nav = useNavigate();
  // const handleBackButton = () => {
  //   nav("/lawyers/teams");
  // };
  const accessToken = Cookies.get("accessToken");
  const [selfPhoneNumber, setSelfPhoneNumber]=useState()
  const [btnDisable,setBtnDisable]=useState(false)
  const [btnLoading,setBtnLoading]=useState(false)
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
    },
    validationSchema: PersonalDetailsValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setBtnLoading(true)
      console.log(values, "FORMIK");
      let PersonalDetails = {
        email: values?.email,
        fullname: values?.fullName,
        password: values?.phone,
        phone: values?.phone,
        roles: ["lawyer"],
      };
      const response = await createLawyersForTeams(PersonalDetails);
      if (response) {
        setBtnLoading(false)
        console.log(response);
        setLawyerId(response?.data?.data?.id);
        console.log(response?.data?.data?.id);
        handleNextButton();
      } else {
        console.log("ERROR");
      }
    },
  });
console.log(btnDisable)
  const searchPhoneInDB = async (phoneNumber) => {
    try {
      if (phoneNumber.length !== 10) {
        throw Error("Number not of full length");
      }
    
        const response = await searchLawyer(phoneNumber);
      if (response) {
        console.log(response);
        console.log(response?.fullname);
        formik.setFieldValue("email", response?.email);
        formik.setFieldValue("fullName", response?.fullname);
        setProfessionalProfile(response?.professionalProfile);
        setIsLawyerInDB(true);
      } else {
        formik.setFieldValue("email", "");
        formik.setFieldValue("fullName", "");
        setProfessionalProfile(null);
        setIsLawyerInDB(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(()=>{
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile(accessToken);
        if (response) {
          setSelfPhoneNumber(response?.data?.data?.phone)
        }
 
      } catch (error) {
        console.error("Error fetching user profile:", error);
   
      }
    };
    fetchUserProfile() 
    if(formik.values.phone===selfPhoneNumber){
      setBtnDisable(true)
    }
    else{
      setBtnDisable(false)
    }
  },[formik.values.phone])

  useEffect(() => {
    console.log(formik.values.phone);
  
    if (formik.values.phone.length === 10) {
      searchPhoneInDB(formik.values.phone);
      setIsPhoneNumberFilled(true);
    }
  }, [formik.values.phone]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "0", md: "18%" },
          marginRight: { xs: "0", md: "18%" },

          height: "100%",
        }}
      >
        {/* <form> */}
        <TextField
          fullWidth
          name="phone"
          label="Phone Number"
          type="tel"
          value={formik.values.phone}
          // onChange={formik.handleChange}
          onChange={formik.handleChange}
          error={formik.errors.phone}
          helperText={formik.errors.phone}
          color="secondary"
          required
          sx={{ mb: 2 }}
          inputProps={{ maxLength: 10 }}
          InputProps={{
            endAdornment: <SearchIcon color="secondary" />,
          }}
        />
        {btnDisable &&
          <Div>
            <Typography sx={{color:"red", mb:2,textAlign:"center"}}>
             Sorry You Cannout Add YourSelf !!!
              </Typography> </Div>
        }
        {/* <SearchIcon sx={{display:"inline"}} color="secondary" /> */}
        <TextField
          fullWidth
          label="Full Name"
          type="text"
          color="secondary"
          disabled={isPhoneNumberFilled ? isLawyerInDB : true}
          required
          value={formik.values.fullName}
          onChange={formik.handleChange}
          error={formik.errors.fullName}
          name="fullName"
          helperText={formik.errors.fullName}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (isPhoneNumberFilled ? isLawyerInDB : true) && (
              <InputAdornment position="end">
                <EditOffIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          name="email"
          label="Email"
          type="text"
          value={formik.values.email}
          disabled={isPhoneNumberFilled ? isLawyerInDB : true}
          onChange={formik.handleChange}
          color="secondary"
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (isPhoneNumberFilled ? isLawyerInDB : true) && (
              <InputAdornment position="end">
                <EditOffIcon />
              </InputAdornment>
            ),
          }}
        />
        {/* </form> */}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            variant="contained"
            disabled={btnLoading}
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          {btnDisable ?   <LoadingButton 
          color="primary"
          disabled
          type="button"
          sx={{ width: 100, padding: 1 }}
          variant="contained"
         >
            Continue
         </LoadingButton>:<LoadingButton
            onClick={formik.handleSubmit}
            loading={btnLoading}
            sx={{ width: 100, padding: 1, "&:hover": {
              backgroundColor: "#F1C40F",
            }, }}
            variant="contained"
          >
            Continue
          </LoadingButton>}
         
         
        </Div>
      </Div>
    </div>
  );
};

export default PersonalDetails;
