import React from "react";
import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

const shareTextTemp =
	"I used the Vakily web erp for managing my daily practice, case handling,  online payments, client management etc! Check this out. You can access the erp and do the same https://vakily.app";

function SingleDocumentPreview({ document }) {
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const handleSnackbarClose = (event, reason) => {
		setOpenSnackBar(false);
	};
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		// setAnchorEl(event.currentTarget);
		setOpenSnackBar(true);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "share-popover" : undefined;

	const handleShareFacebook = () => {
		window.open(
			"https://www.facebook.com/sharer/sharer.php?u=https://www.lawinzo.com"
		);
		handleClose();
	};

	const handleShareWhatsApp = async () => {
		try {
			const response = await fetch(document?.documentUrl); // Fetch the file
			const blob = await response.blob(); // Create a Blob object from the file data

			const fileReader = new FileReader();
			fileReader.onloadend = function () {
				const base64Data = fileReader.result.split(",")[1]; // Extract base64-encoded data

				const shareText = encodeURIComponent(shareTextTemp);
				const encodedShareText = encodeURIComponent(shareText);
				const encodedFileData = encodeURIComponent(base64Data);

				// Construct the final WhatsApp share URL with data URI
				const whatsappShareUrl = `whatsapp://send?text=${encodedShareText}%0A%0A${encodedFileData}`;

				// Open the WhatsApp share URL
				window.location.href = whatsappShareUrl;
			};

			fileReader.readAsDataURL(blob); // Read the file as data URL
		} catch (error) {
			console.error("Error sharing file on WhatsApp:", error);
		}

		handleClose();
	};

	const handleShareEmail = () => {
		const emailSubject = encodeURIComponent("Vakily");
		const emailBody = encodeURIComponent(shareTextTemp);
		window.open(`mailto:?subject=${emailSubject}&body=${emailBody}`);
		handleClose();
	};
	return (
		<>
			<Div>
				{openSnackBar && (
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						open={openSnackBar}
						autoHideDuration={2000}
						onClose={handleSnackbarClose}
						sx={{ zIndex: 1000, mt: 10 }}
					>
						<Alert
							onClose={handleSnackbarClose}
							severity="success"
							sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
						>
							<Typography variant="h6">Message sent via whatsapp</Typography>
						</Alert>
					</Snackbar>
				)}
				<Div
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						height: "100%",
						overfloyY: "auto",
						width: "100%",
					}}
				>
					{document.documentExtension.startsWith("image/") ? (
						<>
							<img
								style={{
									width: "100%",
									height: "300px",
									borderRadius: "5px",
									objectFit: "contain",
								}}
								src={document?.documentUrl}
								alt="selected image"
							/>
						</>
					) : document.documentExtension === "application/pdf" ? (
						<iframe
							src={document?.documentUrl}
							title="PDF Preview"
							style={{ width: "100%", height: "300px" }}
						/>
					) : document.documentExtension ===
					  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
						<iframe
							src={`https://docs.google.com/gview?url=${document.documentUrl}&embedded=true`}
							style={{ width: "100%", height: "300px" }}
							title="Docx Preview"
						></iframe>
					) : null}
					<Div
						sx={{
							flex: 1,
							mt: 2,
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-evenly",
							height: "100%",
						}}
					>
						<Div
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography
								variant="h3"
								sx={{
									lineHeight: 1.5,
									// margin-bottom: 4px;
									fontSize: "18px",
									fontWeight: 700,
									color: "#151513",
								}}
							>
								{document?.documentType} - {document?.documentName}
							</Typography>
							{/* <span
              style={{
                borderRadius: "50%",
                backgroundColor: "rgb(234 191 78 / 34%)",
                textAlign: "center",
                fontSize: "2rem",
                color: "#EABF4E",
                height: "60px",
                width: "60px",
              }}
              onClick={handleClick}
            >
              <ShareIcon style={{ fontSize: "2.2rem", margin: "10px" }} />
            </span> */}
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
							>
								<Button
									onClick={handleShareFacebook}
									startIcon={<FacebookIcon />}
								>
									Facebook
								</Button>
								<Button
									onClick={handleShareWhatsApp}
									startIcon={<WhatsAppIcon />}
								>
									WhatsApp
								</Button>
								<Button onClick={handleShareEmail} startIcon={<EmailIcon />}>
									Email
								</Button>
							</Popover>
						</Div>
						<Div
							sx={{
								mt: 2,
								// flex: 1,
								// backgroundColor: "whitesmoke",
								// borderRadius: "8px",
								// p: 2,
							}}
						>
							<Accordion
								sx={{
									backgroundColor: "whitesmoke",
									borderRadius: "8px",
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography
										variant="body1"
										sx={{
											fontSize: "1.25rem",
											overflowY: "auto",
											fontWeight: 600,
										}}
									>
										Description
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										variant="body1"
										sx={{ fontSize: "1rem", overflowY: "auto" }}
									>
										{Boolean(document?.documentDescription)
											? document?.documentDescription
											: "No desription added"}
									</Typography>
								</AccordionDetails>
							</Accordion>
						</Div>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default SingleDocumentPreview;
