import { TableCell, TableRow } from "@mui/material";
import React,{useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import { Chip ,Box,Modal,Link} from "@mui/material";
import { formatDate } from "app/utils/dateTimeHelpers";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderBottom: "1px solid black",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },

  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProductSaleItem = ({ item, paymentsList, index }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [loading,setLoading] = useState(true);
  const divRef = useRef(null);
  // const handlePayment =  () => {
  //   // const url = `https://erp.lawinzo.com/payment/initiate?order_id=${item?.paymentRequest?.order_id}&retry=1&platform=W`;
  //   // window.location.href = url;
  // };
  const [htmlContent, setHtmlContent] = useState('');
  const handlePayment =  () => {
    console.log(`https://erp.lawinzo.com/payment/initiate?order_id=${item?.paymentRequest?.order_id}&retry=1&platform=W`);
    fetch(`https://erp.lawinzo.com/payment/initiate?order_id=${item?.paymentRequest?.order_id}&retry=1&platform=W`)
    .then((response) => response.text())
    .then(data => {
      const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');

    const content = doc.body.innerHTML;
    console.log(content);
      console.log(data);
      divRef.current.innerHTML = content;
    })
    .catch((err)=>console.log(err));
    setOpenModal(true);
  };

  // const handlePayment = ()=>{
  //   // setOpenModal(true);
  //   window.open(`https://erp.lawinzo.com/payment/initiate?order_id=${item?.paymentRequest?.order_id}&retry=1&platform=W`, '_blank', 'width=800,height=600')
  // }



  return (
    <>
    <StyledTableRow>
      <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "left" }}>
        {index >= 9 ? `#${index + 1}` : `#0${index + 1}`}
      </TableCell>
      {/* <TableCell sx={{ pl: (theme) => theme.spacing(2), textAlign: "center" }}>
         {item?.cases?.caseFirstName || "First Party"} vs {item?.cases?.caseSecondName ||"Second Party"}
      </TableCell> */}
      <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
      {item?.clientName || "Client Name"}
      </TableCell>
      <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
      {item?.createdDate ?formatDate(item?.createdDate): "date" }
      </TableCell>
      <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
        ₹{item?.lawyer_amount}
      </TableCell>

      <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
        <Chip
          label={item?.order_status}
          sx={{
            color: "#F57202",
            backgroundColor: "rgb(245,114,2,16%)",
            fontWeight: "semi-bold",
          }}
        />
      </TableCell>
      {/* <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
        ₹{item?.lawinzo_amount}
      </TableCell> */}
      {/* <TableCell align="right" sx={{ pr: (theme) => theme.spacing(3) }}>
          <Chip 
          label={item?.order_status !== "Success"?"Pay Now":"Details"} 
          onClick={item?.order_status === "Success"?null:handlePayment}
          variant="primary"
          sx={{
            fontSize:"12px",
            fontWeight:700,
            color:"#151513",
            backgroundColor:"#EABF4E",
            minWidth:"73.62px"
          }}
          />
      </TableCell> */}
    </StyledTableRow>
    <Modal
    open={openModal}
    onClose={() => setOpenModal(false)}
    aria-labelledby="delete-modal-title"
    aria-describedby="delete-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        outline: "none",
        height:500,
        width:500,
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
     {/* {item?.paymentRequest?.order_id} */}
      {/* <iframe
      height={"100%"} 
      width={"100%"} 
      style={{border:"none"}} 
      src={`https://erp.lawinzo.com/payment/initiate?order_id=${item?.paymentRequest?.order_id}&retry=1&platform=W`}
      // src={`https://secure.ccavenue.com/transaction.do?command=initiateTransaction&merchant_id=${item?.merchant_id}&encRequest=${item?.encRequest}&access_code=AVRY04KF99BT78YRTB`}
      /> */}
      <div ref={divRef}> </div>
    </Box>
  </Modal>
  </>
  );
};
export default ProductSaleItem;
