import Div from "@jumbo/shared/Div/Div";
import { Divider, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { Chip } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Card, CardHeader, Link, CardContent } from "@mui/material";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { formatDate, formattedTime } from "app/utils/dateTimeHelpers";
import { useNavigate } from "react-router-dom";
import ManIcon from "@mui/icons-material/Man";
import CakeIcon from "@mui/icons-material/Cake";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { LoadingButton } from "@mui/lab";
import CaseListCard from "./CaseListCard";
import { getCasesList } from "app/Apis/case";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { getCasesAssignedToLawyer } from "app/Apis/teams";

const LawyerProfileCard = ({ handleNextButton,update,setUpdate }) => {
  const navigate = useNavigate();
  const {lawyerId} = useParams();
//   console.log(lawyerId);
  const handleBackButton = () => {
    navigate("/lawyers/teams");
  };
  const [allCases, setAllCases] = useState([]);
  const [addCase, setAddCase] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState("5");
  // const [update, setUpdate] = useState(false);



  const getAllCases = async () => {
    setIsLoading(true);
    const response = await getCasesAssignedToLawyer(lawyerId);
    if (response) {
      console.log(response);
      setAllCases(response);
      setTotalPages(response.totalPages);
    }
    setIsLoading(false);
  };


  useEffect(() => {
    getAllCases();
    setAddCase(false);
  }, [pageNumber, pageSize, update]);
  

  return (
    <>
      <CaseListCard
        setAddCase={setAddCase}
        isLoading={isLoading}
        allCaseList={allCases}
        totalPages={totalPages}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setUpdate={setUpdate}
        addCase={addCase}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default LawyerProfileCard;
