import React from "react";
import JudgementItem from "./JudgementItem";
import { Grid } from "@mui/material";

const JudgementList = ({
  judgementList,
  setJudgementEdit,
  isJudgement
}) => {
  return (
    <React.Fragment>
      <Grid
        container
    sx={{mb:4}}
        // spacing={2}
      >
        {judgementList.map((judgement, index) => {
        //   if (judgement?.isActive) {
            return (
              <Grid item xs={12} md={12}>
                <JudgementItem
                  key={judgement.id}
                  item={judgement}
                  setJudgementEdit={setJudgementEdit}
                  isJudgement={isJudgement}
                //   setShowUpdate={setShowUpdate}
                //   showUpdate={showUpdate}
                //   setjudgementDetails={setjudgementDetails}
                //   setDetailItem={setDetailItem}
                />
              </Grid>
            );
        //   }
        })}
      </Grid>
    </React.Fragment>
  );
};

export default JudgementList;