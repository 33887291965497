import React from "react";
import Div from "@jumbo/shared/Div/Div";
import {useDispatch} from "react-redux";
import {
    defaultAccessToken,
    DefaultName,
    DefaultProfileImage,
    defaultProfileStatus,
    defaultRole,
    DefaultUserEmail,
    DefaultUserMobile,
    DefaultUserName,
  } from "app/redux/actions/OnboardingInputs";
  import {
    UserBarCouncil,
    UserPracticeStates,
    UserPracticeCourts,
    UserLawyerEducation,
    UserLawyerSpecialisation,
    UserLawyerLanguage,
  } from "app/redux/actions/UpdatedInputs";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { rootReducer } from "app/redux/reducers";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onLogout = () => {
        Cookies.remove("isLoggedIn");
        Cookies.remove("assignedRole");
        Cookies.remove("accessToken");
        dispatch(defaultAccessToken(""));
        dispatch(DefaultUserEmail(""));
        dispatch(DefaultName(""));
        dispatch(DefaultUserName(""));
        dispatch(DefaultProfileImage(""));
        dispatch(DefaultUserMobile(""));
        dispatch(defaultProfileStatus(""));
        dispatch(defaultRole({role:"",onboarding:false}));
    
        dispatch(UserBarCouncil(""));
        dispatch(UserPracticeStates(""));
        dispatch(UserPracticeCourts(""));
        dispatch(UserLawyerEducation(""));
        dispatch(UserLawyerSpecialisation(""));
        dispatch(UserLawyerLanguage(""));
    
        dispatch(rootReducer);
    
        navigate("/cc/enquiry/login");
      };
  return (
    <Div
      sx={{
        width: "100%",
        height: "80px",
        position: "fixed",
        top: 0,
        backgroundColor: "#EABF4E",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pr: 1,
        pl:2,
        zIndex:100
      }}
    >
        <img
          src="/images/lawinzo/vakily-logo-black.png"
          // src='/images/vakily-logo-black.png'
          alt="Logo"
          style={{
            width: "74.32px",
            height: "90.29px",
            // marginLeft: isBelow990px ? "50px" : "150px",
          }}
        />
      <Button
      variant="contained"
      color="primary"
      onClick={onLogout}
      >Logout</Button>
    </Div>
  );
};

export default Header;
