import jitsiTheme from'./theme';

export const config = {
  disableReactions: true,
  disablePolls: false,
  disableSelfView: false,
  disableSelfViewSettings: false,
  resolution: 720,
  startVideoMuted: 5,
  toolbarButtons: [
    "camera",
    "chat",
    "fullscreen",
    "hangup",
    "microphone",
    "profile",
    "settings",
    "tileview",
    "toggle-camera",
    // 'whiteboard',
  ],
  recordingService: {
    enabled: false,
    sharingEnabled: false,
    hideStorageWarning: false,
  },
  logging: {
    

    // Application logo url
    defaultLogoUrl: 'https://lawinzo-profile-dev.s3.ap-south-1.amazonaws.com/64ac5358c4009727e3768dc1/tasks/854f5f66-e021-49ea-8436-dacb61ab22e1.jpeg',

    
  }
  
};
