import React, { useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Checkbox,
  Chip,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Tabs } from "@mui/material";
import {
  AvatarGroup,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import { ASSET_AVATARS } from "app/utils/constants/paths";
import { getAssetPath } from "app/utils/appHelpers";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  LawyerProfileUpdate,
  getCaseCity,
  getCourt,
  getEdu,
  getLang,
  getLawyerState,
  getSpec,
} from "app/Apis/User";
import { useFormik } from "formik";
import { loginValidations2 } from "app/utils/validators";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoIcon from "@mui/icons-material/Info";
import {
  UserBarCouncil,
  UserLawyerEducation,
  UserLawyerLanguage,
  UserLawyerSpecialisation,
  UserPracticeCourts,
  UserPracticeStates,
} from "app/redux/actions/UpdatedInputs";
import { useNavigate } from "react-router-dom";
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: "50%",
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
  border: `solid 1px ${theme.palette.divider}`,
}));
const MyChip = ({ registrationNo }) => {
  return <Chip label={registrationNo} />;
};

const Court = ({
  details,
  dataEdit,
  submit,
  setDataEdit,
  setShowUpdate,
  setOpenSnackBar,
}) => {
  console.log(details);
  const [State, setState] = useState([]);
  const [Court, setCourt] = useState([]);
  const [Specialisation, setSpecialisation] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [courts, setCourts] = useState([]);
  const [spec, setSpecial] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (dataEdit) {
      setCourts(details?.professionalProfile?.practiceCourts);
      setSpecial(details?.professionalProfile?.lawyerSpecialisation);
      setStates(details?.professionalProfile?.practiceStates);
      setCities(details?.professionalProfile?.practiceDistricts);
    }
  }, [dataEdit]);

  //error
  const [error, setError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [practiceCourtError, setPracticeCourtError] = useState(false);
  const [specialisationError, setSpecialisationError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );

  const getStates = async () => {
    const state = await getLawyerState();
    if (!state) {
      console.log("error while getting states list", state);
    }
    state.shift();
    // console.log(state);
    setState(state);
  };
  const getCourts = async () => {
    const courts = await getCourt();
    if (!courts) {
      console.log("error while getting courts list", courts);
    }
    // state.shift();
    // console.log(courts);
    setCourt(courts);
  };

  const getSpecialisatioin = async () => {
    const spec = await getSpec();
    if (!spec) {
      console.log("error while getting pec list", spec);
    }
    // state.shift();
    // console.log(spec);
    setSpecialisation(spec);
  };

  useEffect(() => {
    const getCity = async () => {
      try {
        if (states.length > 0) {
          states?.map(async (state) => {
            const response = await getCaseCity(state?.stateId);
            if (response) {
              setCityList((prev) => [...prev, ...response]);
              console.log(response);
            } else {
              console.log("No City Found");
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCity();
  }, [states.length]);

  useEffect(() => {
    const getInitValues = async () => {
      await getStates();
      await getCourts();
      await getSpecialisatioin();
    };
    getInitValues();
  }, []);

  const ValuesToReduxForState = () => {
    setStateError(false);
    dispatch(UserPracticeStates(states));
  };

  const ValuesToReduxForCourt = () => {
    setPracticeCourtError(false);
    dispatch(UserPracticeCourts(courts));
  };
  const ValuesToReduxForSpecialisation = () => {
    setSpecialisationError(false);
    dispatch(UserLawyerSpecialisation(spec));
  };

  const Incrementfn = async () => {
    let state = [];
    let court = [];
    let speciality = [];
    let city = [];
    let educ = [];
    let lan = [];
    // debugger
    if (states.length === 0) {
      setError(true);
      setStateError(true);
    } else {
      console.log("in else state");
      states.map((ele) => {
        state.push({
          stateId: ele.stateId,
        });
      });
      setStates(state);
      console.log(state);
    }
    if (details?.professionalProfile?.lawyerEducations === 0) {
      setError(true);
      // setEducationError(true);
    } else {
      details?.professionalProfile?.lawyerEducations.map((ele) => {
        educ.push({
          educationId: ele.educationId,
        });
      });
      console.log(educ);
    }
    if (details?.professionalProfile?.lawyerLanguage === 0) {
      setError(true);
      // setEducationError(true);
    } else {
      details?.professionalProfile?.lawyerLanguage.map((ele) => {
        lan.push({
          languageId: ele.languageId,
        });
      });
      console.log(educ);
    }
    if (cities.length === 0) {
      setError(true);
      setCityError(true);
    } else {
      console.log("in else city");
      cities.map((ele) => {
        city.push({
          districtId: ele.districtId,
        });
      });
      setCities(city);
      console.log(city);
    }
    if (courts.length === 0) {
      setError(true);
      setPracticeCourtError(true);
    } else {
      courts.map((ele) => {
        court.push({
          courtsId: ele.courtsId,
        });
      });
      console.log(court);
    }
    if (spec.length === 0) {
      setError(true);
      setSpecialisationError(true);
    } else {
      spec.map((ele) => {
        speciality.push({
          specialityId: ele.specialityId,
        });
      });
      console.log(speciality);
      setError(false);
      setStateError(false);
      setPracticeCourtError(false);
      setSpecialisationError(false);
      setCityError(false);
      let obj = {
        barCouncilRegistrationNo:
          details?.professionalProfile?.barCouncilRegistrationNo,
        practiceStates: state,
        practiceCourts: court,
        // DISTRICT_COURT:city,
        practiceDistricts: city,
        lawyerEducations: educ,
        lawyerSpecialisation: speciality,
        lawyerLanguage: lan,
      };
      console.log(obj);

      const response = await LawyerProfileUpdate(accessToken, obj);
      if (response) {
        console.log("lawyer data is ", response);
        setDataEdit(false);
        setShowUpdate(true);
        setOpenSnackBar(true);
      } else {
        console.log("something went wrong!");
      }
    }
  };
  useEffect(() => {
    if (submit) {
      Incrementfn();
    }
  }, [submit]);

  const theme = useTheme();
  return (
    <>
      <List
        disablePadding
        sx={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "-25px",
          margin: (theme) => theme.spacing(-2, -2),
        }}
      >
        <ListItem
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
              // xl: "33.33%",
              alignItems: "flex-start",
            },
          }}
        >
          <StyledListItemIcon>
            <AccountBalanceIcon
              sx={{
                color: theme.palette.primary.main,
              }}
              fontSize={"inherit"}
            />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography
                fontSize={"12px"}
                variant="h6"
                color="text.secondary"
                mb={0.5}
              >
                Bar Council Number{" "}
                {dataEdit ? (
                  <Tooltip title="To update Bar Council Number, please send a request to connect@vakily.app">
                    <span>
                      <InfoIcon sx={{ mx: 1, pt: 0.5 }} />
                    </span>
                  </Tooltip>
                ) : null}
              </Typography>
            }
            secondary={
              <div>
                <MyChip
                  registrationNo={
                    details?.professionalProfile?.barCouncilRegistrationNo
                  }
                />
              </div>
            }
          />
        </ListItem>
        <ListItem
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
              // xl: "33.33%",
              alignItems: "flex-start",
            },
          }}
        >
          <StyledListItemIcon>
            <AccountBalanceIcon
              sx={{
                color: theme.palette.primary.main,
              }}
              fontSize={"inherit"}
            />
          </StyledListItemIcon>
          {dataEdit ? (
            <Div>
              <Autocomplete
                style={{ width: "300px" }}
                value={courts}
                onChange={(event, value) => setCourts(value)}
                // isOptionEqualToValue={(option, value) => option.stateId === value.stateId}
                multiple
                disableCloseOnSelect
                options={Court}
                // disableCloseOnSelect
                getOptionLabel={(option) => {
                  return option.courtName;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.courtName}
                  </li>
                )}
                sx={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    sx={{ marginTop: "5px", backgroundColor: "white" }}
                    {...params}
                    label="Select Practice Court"
                    placeholder="Practice Court"
                    color="secondary"
                    required
                    error={practiceCourtError}
                    onBlur={() =>
                      courts.length > 0
                        ? ValuesToReduxForCourt()
                        : setPracticeCourtError(false)
                    }
                  />
                )}
              />
              {practiceCourtError && (
                <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
                  Please Select atleast one value!
                </Typography>
              )}
            </Div>
          ) : (
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Practice Court
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  <Stack direction="row" spacing={1}>
                    {details?.professionalProfile?.practiceCourts
                      .slice(0, 2)
                      .map((court, key) => {
                        return (
                          <>
                            <Chip label={court.courtName} />
                          </>
                        );
                      })}
                    {details?.professionalProfile?.practiceCourts.length >
                      2 && (
                      <Chip
                        label={`+${
                          details?.professionalProfile?.practiceCourts.length -
                          2
                        }`}
                        sx={{
                          maxWidth: { xs: "100%", sm: "65%" },
                        }}
                      />
                    )}
                  </Stack>
                </Typography>
              }
            />
          )}
        </ListItem>
      </List>
      <List
        disablePadding
        sx={{
          display: "flex",
          flexWrap: "wrap",
          margin: (theme) => theme.spacing(-2, -2),
        }}
      >
        <ListItem
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
              // xl: "33.33%",
              alignItems: "flex-start",
            },
          }}
        >
          <StyledListItemIcon>
            <AccountBalanceIcon
              sx={{
                color: theme.palette.primary.main,
              }}
              fontSize="inherit"
            />
          </StyledListItemIcon>
          {dataEdit ? (
            <Div>
              <Autocomplete
                style={{ width: "300px" }}
                value={states}
                onChange={(event, value) => {
                  setStates(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.stateId === value.stateId
                }
                multiple
                disableCloseOnSelect
                options={State}
                // disableCloseOnSelect
                getOptionLabel={(option) => {
                  return option.stateName;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.stateName}
                  </li>
                )}
                sx={{ width: 500 }}
                renderInput={(params) => {
                  return (
                    <TextField
                      sx={{ marginTop: "5px", backgroundColor: "white" }}
                      {...params}
                      label="Select State"
                      placeholder="State"
                      color="secondary"
                      error={stateError}
                      onBlur={() =>
                        states.length > 0
                          ? ValuesToReduxForState()
                          : setStateError(false)
                      }
                    />
                  );
                }}
              />
              {stateError && (
                <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
                  Please Select atleast one value!
                </Typography>
              )}
            </Div>
          ) : (
            <ListItemText
              primary={
                <Typography
                  fontSize="12px"
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Practice State
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                    {details?.professionalProfile?.practiceStates
                      .slice(0, 2)
                      .map((pstate, key) => (
                        <Chip
                          key={key}
                          label={pstate.stateName}
                          sx={{
                            maxWidth: { xs: "65%", sm: "100%" },
                          }}
                        />
                      ))}
                    {details?.professionalProfile?.practiceStates.length >
                      2 && (
                      <Chip
                        label={`+${
                          details.professionalProfile.practiceStates.length - 2
                        }`}
                        sx={{
                          maxWidth: { xs: "65%", sm: "100%" },
                        }}
                      />
                    )}
                  </Stack>
                </Typography>
              }
            />
          )}
        </ListItem>
        <ListItem
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
              // xl: "33.33%",
              alignItems: "flex-start",
            },
          }}
        >
          <StyledListItemIcon>
            <AccountBalanceIcon
              sx={{
                color: theme.palette.primary.main,
              }}
              fontSize={"inherit"}
            />
          </StyledListItemIcon>
          {dataEdit ? (
            <Div>
              <Autocomplete
                style={{ width: "300px" }}
                value={spec}
                onChange={(event, value) => setSpecial(value)}
                multiple
                disableCloseOnSelect
                // id="checkboxes-tags-demo"
                options={Specialisation}
                // disableCloseOnSelect
                getOptionLabel={(option) => {
                  return option.specialityName;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.specialityName}
                  </li>
                )}
                sx={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    sx={{ marginTop: "5px", backgroundColor: "white" }}
                    {...params}
                    label="Specialisation"
                    placeholder="Specialisation"
                    required
                    color="secondary"
                    error={specialisationError}
                    onBlur={() =>
                      spec.length > 0
                        ? ValuesToReduxForSpecialisation()
                        : setSpecialisationError(false)
                    }
                  />
                )}
              />
              {specialisationError && (
                <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
                  Please Select atleast one value!
                </Typography>
              )}
            </Div>
          ) : (
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  Specialisation
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  <Stack direction="row" spacing={1}>
                    {details?.professionalProfile?.lawyerSpecialisation
                      .slice(0, 2)
                      .map((skills, key) => {
                        return (
                          <>
                            <Chip label={skills.specialityName} />
                          </>
                        );
                      })}
                    {details?.professionalProfile?.lawyerSpecialisation.length >
                      2 && (
                      <Chip
                        label={`+${
                          details?.professionalProfile?.lawyerSpecialisation
                            .length - 2
                        }`}
                        sx={{
                          maxWidth: { xs: "65%", sm: "100%" },
                        }}
                      />
                    )}
                  </Stack>
                </Typography>
              }
            />
          )}
        </ListItem>
      </List>
      <List
        disablePadding
        sx={{
          display: "flex",
          flexWrap: "wrap",
          margin: (theme) => theme.spacing(-2, -2),
        }}
      >
        <ListItem
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
              // xl: "33.33%",
              alignItems: "flex-start",
            },
          }}
        >
          <StyledListItemIcon>
            <AccountBalanceIcon
              sx={{
                color: theme.palette.primary.main,
              }}
              fontSize={"inherit"}
            />
          </StyledListItemIcon>
          {dataEdit ? (
            <Div>
              <Autocomplete
                style={{ width: "300px" }}
                value={cities}
                onChange={(event, value) => {
                  setCities(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.districtId === value.districtId
                }
                multiple
                disableCloseOnSelect
                options={cityList}
                // disableCloseOnSelect
                getOptionLabel={(option) => {
                  return option.districtName;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.districtName}
                  </li>
                )}
                sx={{ width: 500 }}
                renderInput={(params) => {
                  return (
                    <TextField
                      sx={{ marginTop: "10px", backgroundColor: "white" }}
                      {...params}
                      label="Select District"
                      placeholder="District"
                      color="secondary"
                      error={cityError}
                      // onBlur={() =>
                      //   cities.length > 0
                      //     ? null
                      //     : setCityError(false)
                      // }
                    />
                  );
                }}
              />
              {cityError && (
                <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
                  Please Select atleast one value!
                </Typography>
              )}
            </Div>
          ) : (
            <ListItemText
              primary={
                <Typography
                  fontSize={"12px"}
                  variant="h6"
                  color="text.secondary"
                  mb={0.5}
                >
                  District
                </Typography>
              }
              secondary={
                <Typography variant="body1" color="text.primary">
                  <Stack direction="row" spacing={1}>
                    {details?.professionalProfile?.practiceDistricts.map(
                      (skills, key) => {
                        return (
                          <>
                            <Chip label={skills.districtName} />
                          </>
                        );
                      }
                    )}
                    {details?.professionalProfile?.practiceDistricts.length >
                      2 && (
                      <Chip
                        label={`+${
                          details?.professionalProfile?.practiceDistricts
                            .length - 2
                        }`}
                        sx={{
                          maxWidth: { xs: "65%", sm: "100%" },
                        }}
                      />
                    )}
                  </Stack>
                </Typography>
              }
            />
          )}
        </ListItem>
      </List>
    </>
  );
};

export default Court;
