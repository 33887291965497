import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, InputAdornment } from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { paymentRequest } from "app/Apis/Payment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { getEnquiryPaymentList } from "app/Apis/Enquiry";
import PaymentsList from "./PaymentList";
import { Navigate } from "react-router-dom";
import { enquiryPaymentValidationSchema } from "app/utils/validators";

function Payment({ enquiryDetails, handleBackButton, handleNextButton }) {
  const [paymentHistory, setPaymentHistory] = useState(false);
  const [paymentList, setPaymentList] = useState(false);
  const [paymentType] = useState(
    enquiryDetails?.enquiryTypes === "Expert_Case_Review"
      ? "Enquiry Fee"
      : "Bail Filing Fee"
  );
  const [update, setUpdate] = useState(false);
  const formik = useFormik({
    initialValues: {
      amount: "",
      userId: "",
      payment_type:
        enquiryDetails?.enquiryTypes === "Expert_Case_Review"
          ? "ENQUIRY_FEE"
          : "BAIL_FILING_FEE",
      paymentDescription: "",
      type: null,
      // client:clients ? clients[0] : null,
    },
    enableReinitialize: true,
    validationSchema:enquiryPaymentValidationSchema,
    onSubmit: async (values) => {
      console.log(values);

      try {
        const response = await paymentRequest({
          amount: values.amount,
          enquiryId: enquiryDetails?.enquiryId,
          userId: enquiryDetails?.userId,
          payment_type: "ENQUIRY_FEE",
          payment_mode: "ONLINE",
          paymentDescription: values?.paymentDescription,
          requester: "cloud",
        });
        if (response) {
          console.log("req sent", response);
          setUpdate((prev) => !prev);
          setPaymentHistory(true);
          formik.handleReset();
        }
      } catch (err) {
        console.log(err);
      }
    },
  });
  const list = async () => {
    const response = await getEnquiryPaymentList(enquiryDetails?.enquiryId, 10);
    console.log(response);
    if (response) {
      setPaymentList(response?.data?.paymentAndTransactionsDetails);
      return;
    }
    console.log("error fetching payment list");
  };
  useEffect(() => {
    if (paymentHistory) {
      list();
    } else {
      setPaymentList([]);
    }
  }, [paymentHistory]);

  // const paymentTypeHandler = (enquiryType)=>{
  //   if(enquiryType==="Expert_Case_Review"){
  //     setEnquiryType("Enquiry Fee");
  //   }else if(enquiryType==="Anticipatory_Bail_Application"){
  //     setEnquiryType("Bail Filing Fee");
  //   }else if(enquiryType==="Regular_Bail_Application"){
  //     setEnquiryType("Bail Filing Fee");
  //   }
  // }
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        pl: { sm: "0", lg: "18%" },
        pr: { sm: "0", lg: "18%" },
        mt: "20px",
        paddingBottom: "30px",
      }}
    >
      {enquiryDetails?.payment && (
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            mb: 3,
            pl: { sm: "0", lg: "18%" },
            pr: { sm: "0", lg: "18%" },
            mt: "20px",
          }}
        >
          {!paymentHistory ? (
            <LoadingButton
              onClick={() => {
                setPaymentHistory(true);
              }}
              color="secondary"
              sx={{
                width: 200,
                padding: 1,
                backgroundColor: "#eabf4e",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="contained"
            >
              See Payment History
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={() => {
                setPaymentHistory(false);
              }}
              color="secondary"
              sx={{
                width: 200,
                padding: 1,
                backgroundColor: "#eabf4e",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="contained"
            >
              New Payment
            </LoadingButton>
          )}
        </Div>
      )}
      {paymentHistory ? (
        <>
          <Div
            sx={{
              pl: { xs: "0%", md: "18%" },
              pr: { xs: "0%", md: "18%" },
              height: "100%",
            }}
          >
            {paymentList ? (
              <PaymentsList paymentsList={paymentList} />
            ) : (
              "No payments"
            )}

            <Div
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 5,
                // backgroundColor: "green",
              }}
            >
              <LoadingButton
                onClick={handleBackButton}
                //   disabled={values !== 0 ? false : true}
                variant="contained"
                sx={{
                  width: 100,
                  padding: 1,
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "#eabf4e",
                    color: "black",
                  },
                }}
              >
                Back
              </LoadingButton>
              <LoadingButton
                onClick={handleNextButton}
                color="secondary"
                sx={{
                  width: 100,
                  padding: 1,
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "#eabf4e",
                    color: "black",
                  },
                }}
                variant="contained"
              >
                Continue
              </LoadingButton>
            </Div>
          </Div>
        </>
      ) : (
        <Div
          sx={{
            pl: { xs: "0%", md: "18%" },
            pr: { xs: "0%", md: "18%" },
            height: "100%",
          }}
        >
          <TextField
            sx={{ mb: 0, mt: 2, width: "100%" }}
            label="Enter Amount*"
            color="secondary"
            variant="outlined"
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount) }
            helperText={formik.touched.amount && formik.errors.amount}
            name="amount"
            id="amount"
            InputProps={{
              inputProps: {
                inputMode: "numeric",
                pattern: "[0-9]*",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{ mb: 0, mt: 2, width: "100%" }}
            label="Payment Type"
            color="secondary"
            variant="outlined"
            value={paymentType}
            disabled
          />

          <Div>
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="Write Payment Notes..."
              variant="outlined"
              multiline
              rows={3}
              value={formik.values.paymentDescription}
              name="paymentDescription"
              color="secondary"
              onChange={formik.handleChange}
            />
          </Div>
          <Div
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 5,
              // backgroundColor: "green",
            }}
          >
            <LoadingButton
              onClick={handleBackButton}
              //   disabled={values !== 0 ? false : true}
              variant="contained"
              sx={{
                width: 100,
                padding: 1,
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#eabf4e",
                  color: "black",
                },
              }}
            >
              Back
            </LoadingButton>
            <LoadingButton
              onClick={formik.handleSubmit}
              color="secondary"
              sx={{
                width: 100,
                padding: 1,
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#eabf4e",
                  color: "black",
                },
              }}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Div>
        </Div>
      )}
    </div>
  );
}

export default Payment;
