import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import Skeletons from "../legalDrafting/Skeletons";
import Cookies from "js-cookie";
import { getDraftList } from "app/Apis/drafting";
import { NoCaseContainer } from "app/pages/MyCases/style";
import NoCaseImage from "app/ImageAssets/NoCaseImage.png";
import { Button, CardContent, Typography } from "@mui/material";
import AddLegalDrafting from "./draftingForm";
import { DraftCard } from "./DraftCard";
import { LoadingButton } from "@mui/lab";
import { EditLegalDrafting } from "./draftingEditForm";

export const DraftingEdit = ({
  caseDetails,
  setUpdate,
  setCaseDetails,
  caseId,
  handleNextButton,
  handleBackButton,
  setSnackBarText,
  setOpenSnackBar,
  formDisable
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [draftList, setDraftList] = useState([]);
  const [AddApp, setAddApp] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [isUpdate, setIsUpdate] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    const getDrafts = async () => {
      setIsLoading(true);
      const response = await getDraftList(accessToken, caseDetails.caseId);
      console.log(response);
      if (response) {
        setDraftList(response.data.drafts);
      }
      setIsLoading(false);
    };
    getDrafts();
  }, []);
  useEffect(() => {
    const getDrafts = async () => {
      setIsLoading(true);
      const response = await getDraftList(accessToken, caseDetails.caseId);
      console.log(response);
      if (response) {
        setDraftList(response.data.drafts);
      }
      setIsLoading(false);
    };
    getDrafts();
  }, [isUpdate]);
  if (editItem) {
    return (
      <EditLegalDrafting
        setEdit={setEdit}
        setEditItem={setEditItem}
        item={editItem}
        setUpdate={setIsUpdate}
        setSnackBarText={setSnackBarText}
        setOpenSnackBar={setOpenSnackBar}
      />
    );
  }
  if (AddApp) {
    return (
      <AddLegalDrafting
        setAddApp={setAddApp}
        setUpdate={setIsUpdate}
        caseId={caseDetails.caseId}
        setSnackBarText={setSnackBarText}
        setOpenSnackBar={setOpenSnackBar}
      />
    );
  }

  return (
    <Div
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Div
        sx={{
          pl: { sm: "0", lg: "18%" },
          pr: { sm: "0", lg: "18%" },
          mt: 3,
        }}
      >
       {!formDisable && <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            mb: 3,
          }}
        >
          <LoadingButton
          disabled={formDisable}
            onClick={() => {
              setAddApp(true);
            }}
            color="secondary"
            sx={{
              width: 200,
              padding: 1,
              backgroundColor: "#eabf4e",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
            variant="contained"
          >
            Add Draft
          </LoadingButton>
        </Div>}
        {isLoading ? (
          Array.from(Array(parseInt(3))).map((item, index) => {
            return <Skeletons key={index} />;
          })
        ) : draftList?.length > 0 ? (
          draftList.map((item) => {
            return (
              <DraftCard
              formDisable={formDisable}
                item={item}
                setEdit={setEdit}
                setEditItem={setEditItem}
              />
            );
          })
        ) : (
          <Div sx={{ height: "100%", width: "100%" }}>
            <Div
              sx={{
                backgroundColor: "white",
                height: "100%",
                position: "relative",
                paddingTop: "10px",
                border: 0,
              }}
            >
              <CardContent>
                <NoCaseContainer>
                  <Div
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Div
                      sx={{
                        width: { xs: "60%", sm: "30%", md: "150px" },
                        height: "30%",
                      }}
                    >
                      <img
                        src={NoCaseImage}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </Div>

                  {formDisable?
                  <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  No Drafts 
                </Typography>:
                  <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  You haven't added any Drafts yet!
                </Typography>  
                }
                  {!formDisable && <Div
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 4,
                      }}
                    >
                      <Button
                      disabled={formDisable}
                        variant="contained"
                        sx={{
                          backgroundColor: "secondary.main",
                          mb: 2,
                          width: "200px",
                        }}
                        onClick={() => setAddApp(true)}
                      >
                        Add Draft
                      </Button>
                    </Div>}
                  </Div>
                </NoCaseContainer>
              </CardContent>
            </Div>
          </Div>
        )}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            disabled={false}
            variant="contained"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={() => {
              handleNextButton();
            }}
            color="secondary"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </Div>
  );
};
