import React from "react";
import StreamOutlinedIcon from "@mui/icons-material/StreamOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { SvgIcon } from '@mui/material';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import { ReactComponent as DraftingIcon } from "../../../../ImageAssets/pngegg.svg"

import {
  GavelOutlined,
  BookmarkAddOutlined,
  Diversity3Outlined,
  GroupOutlined,
  ManageAccountsOutlined,
  PaymentsOutlined,
  ChatBubble,
} from "@mui/icons-material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';

export const LawyerMenu = [
  {
    label: "Main Menu",
    type: "section",
    children: [
      {
        uri: "/lawyers/dashboards",
        label: "Dashboard",
        type: "nav-item",
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/cases",
        label: "My Cases",
        type: "nav-item",
        icon: <GavelOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/hearings",
        label: "Hearings",
        type: "nav-item",
        icon: <WorkOutlineIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/appointments",
        label: "Appointments",
        type: "nav-item",
        icon: <CalendarMonthOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/legalDrafting",
        label: "Legal Drafting",
        type: "nav-item",
        icon: <SvgIcon component={DraftingIcon} sx={{
          fontSize: 20, color: "white",
        }} viewBox="0 0 700 600" />
        // <GradingOutlinedIcon sx={{ fontSize: 20 }}  />,
      },
      {
        uri: "/lawyers/todo",
        label: "Tasks",
        type: "nav-item",
        icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/teams",
        label: "Teams",
        type: "nav-item",
        icon: <GroupsOutlinedIcon sx={{ fontSize: 20 }} />,
      },

      {
        uri: "/lawyers/payments",
        label: "Payments",
        type: "nav-item",
        icon: <PaymentsOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/clients",
        label: "Clients",
        type: "nav-item",
        icon: <GroupOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/chatbot",
        label: "Legal Chat",
        type: "nav-item",
        icon: <ChatBubble sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/lawyers/settings",
        label: "Profile",
        type: "nav-item",
        icon: <ManageAccountsOutlined sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "See Others",
    type: "section",
    children: [
      // {
      //   uri: "/contact-us",
      //   label: "FAQs",
      //   type: "nav-item",
      //   icon: <QuizOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/terms",
        label: "Terms & Conditions",
        type: "nav-item",
        icon: <StreamOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/help",
        label: "Help & Support",
        type: "nav-item",
        icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "/refer",
      //   label: "Refer",
      //   type: "nav-item",
      //   icon: <ShareOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
    ],
  },
];

export const ClientMenu = [
  {
    label: "Main Menu",
    type: "section",
    children: [
      {
        uri: "/clients/dashboards",
        label: "Dashboard",
        type: "nav-item",
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/clients/cases",
        label: "My Cases",
        type: "nav-item",
        icon: <GavelOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/clients/hearings",
        label: "Hearings",
        type: "nav-item",
        icon: <CalendarMonthOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/clients/appointments",
        label: "Appointments",
        type: "nav-item",
        icon: <CalendarMonthOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/clients/todo",
        label: "Tasks",
        type: "nav-item",
        icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/clients/payments",
        label: "Payments",
        type: "nav-item",
        icon: <PaymentsOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/clients/clients",
        label: "Clients",
        type: "nav-item",
        icon: <GroupOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/clients/enquiry",
        label: "Enquiry",
        type: "nav-item",
        icon: <LiveHelpIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/clients/settings",
        label: "Profile",
        type: "nav-item",
        icon: <ManageAccountsOutlined sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "See Others",
    type: "section",
    children: [
      // {
      //   uri: "/contact-us",
      //   label: "FAQs",
      //   type: "nav-item",
      //   icon: <QuizOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/terms",
        label: "Terms & Conditions",
        type: "nav-item",
        icon: <StreamOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "/help",
      //   label: "Help & Support",
      //   type: "nav-item",
      //   icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "/refer",
      //   label: "Refer",
      //   type: "nav-item",
      //   icon: <ShareOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
    ],
  },

];

export const AdminMenu = [
  {
    label: "Main Menu",
    type: "section",
    children: [
      {
        uri: "/admin/dashboard",
        label: "Dashboard",
        type: "nav-item",
        icon: <AssessmentIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/admin/judgement/list",
        label: "Judgement",
        type: "nav-item",
        icon: <GavelOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/admin/cc/enquiry/list",
        label: "Enquiry",
        type: "nav-item",
        icon: <LiveHelpIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/admin/publications",
        label: "Publications",
        type: "nav-item",
        icon: <ArticleIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "See Others",
    type: "section",
    children: [
      // {
      //   uri: "/contact-us",
      //   label: "FAQs",
      //   type: "nav-item",
      //   icon: <QuizOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/terms",
        label: "Terms & Conditions",
        type: "nav-item",
        icon: <StreamOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/help",
        label: "Help & Support",
        type: "nav-item",
        icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "/refer",
      //   label: "Refer",
      //   type: "nav-item",
      //   icon: <ShareOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
    ],
  },
];
export const ModeratorMenu = [
  {
    label: "Main Menu",
    type: "section",
    children: [
      {
        uri: "/moderator/dashboard",
        label: "Dashboard",
        type: "nav-item",
        icon: <AssessmentIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/moderator/judgement/list",
        label: "Judgement",
        type: "nav-item",
        icon: <GavelOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/moderator/cc/enquiry/list",
        label: "Enquiry",
        type: "nav-item",
        icon: <LiveHelpIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/moderator/publications",
        label: "Publications",
        type: "nav-item",
        icon: <ArticleIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "See Others",
    type: "section",
    children: [
      // {
      //   uri: "/contact-us",
      //   label: "FAQs",
      //   type: "nav-item",
      //   icon: <QuizOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/terms",
        label: "Terms & Conditions",
        type: "nav-item",
        icon: <StreamOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/help",
        label: "Help & Support",
        type: "nav-item",
        icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "/refer",
      //   label: "Refer",
      //   type: "nav-item",
      //   icon: <ShareOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
    ],
  },
];

// export default menus;
