import React, { useEffect, useState } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { alpha, Paper, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import { formatDate } from "app/utils/dateTimeHelpers";
import { useNavigate } from "react-router-dom";
import { Modal, Box, Fade, Backdrop, Button } from "@mui/material";
import { deleteCase } from "app/Apis/case";
import ActionsMenu from "./ActionMenu";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  // marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const JudgementItem = ({ item, setUpdate, key, setJudgementEdit,isJudgement }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [judges, setJudges] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const nav = useNavigate();
  // console.log(item);
  const handleExpandClick = () => {
    // navigate(`/lawyers/casedetail?caseId=${item?.caseId}`)
    setExpanded((prev) => !prev);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleDelete = async () => {
    const response = await deleteCase(item);
    if (response) {
      setUpdate((prev) => !prev);
    }
  }
  const handleEdit = () => {
    setJudgementEdit(item);
    // if(!isJudgement){
    //   nav(`/admin/judgement/list?from=publication&edit=${item?.publicationsId}`);
    // }
  }
  // let judges=[];
  const judgeNameFetch = () => {
    const judgeString = item?.judge;
    if (judgeString) {
      judgeString.includes(";");
      const tempJudges = judgeString.split(";");
      console.log(tempJudges);
      setJudges(tempJudges);
    }
  };

  const handleDocumentClick = () => {
    handleOpen();
  };

  useEffect(() => {
    judgeNameFetch();
  }, []);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        cursor: "default",
        position: "relative",
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-1px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",
          cursor: "default",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            m: 0,
            padding: "12px 0",
            "&.Mui-expanded": {
              padding: "12px 0",
            },
            cursor: "default",
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
            "&:hover": {
              cursor: "default",
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>
        <Div
          sx={{
            width: { xs: "auto", sm: "20%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item.caseName}
          </Typography>
          <Typography
            variant={"h3"}
            color={"text.secondary"}
            fontSize={14}
            fontWeight="700"
          >
            Section&nbsp;{item.sections?.map((section) => `${section} `)}
          </Typography>
        </Div>

        {/* <Div
          sx={{
            display: { xs: "none", lg: "flex" },
            width: "20%",
            flexShrink: 0,
            px: 2,
            gap: 5,
          }}
        >
          <Div sx={{display:"flex",alignItems:"column"}}>
            {judges && judges.map((judgeName)=>{
              console.log(judgeName);
              return (
                <Chip
                label={judgeName}
                color="primary"
                size="medium"
                variant="outlined"
                sx={{ fontSize: 14, fontWeight: "700", maxWidth: "100%" }}
              />
              )
            })}
           
            {/* <Typography fontSize={"14px"}>{item.caseType?.caseName}</Typography> 
          </Div>
          {/* <Div>
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Lawyer
            </Typography>
            <Typography fontSize={"14px"}>{item.isPetitioner ? "Respondent" : "Petitioner"}</Typography>
          </Div> 
        </Div> */}
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Court
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <AccountBalanceIcon color="secondary" />
            <Typography fontSize={"14px"}>{item.court}</Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
{            isJudgement?"Date of Decision":"Date of Creation"}
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <CalendarMonthIcon color="secondary" />
            <Typography fontSize={"14px"}>
              {item.dateOfDecision
                ? item.dateOfDecision
                : "Date not given"}
            </Typography>
          </Div>
        </Div>
        <Div
          sx={{
            width: { xs: "auto", sm: "fit-content" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
            gap: 1,
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item.sections?.map((section) => {
              return (
                <Chip
                  label={section}
                  color="primary"
                  size="medium"
                  variant="outlined"
                  sx={{ fontSize: 14, fontWeight: "700", ml: "12px", mt: 1 }}
                />
              );
            })}
            {item?.court ? (
              <Chip
                label={item?.court}
                color="primary"
                size="medium"
                variant="outlined"
                sx={{ fontSize: 14, fontWeight: "700", ml: "12px", mt: 1 }}
              />
            ) : null}
            {judges &&
              judges.map((judgeName) => {
                return (
                  <Chip
                    label={judgeName}
                    color="primary"
                    size="medium"
                    variant="outlined"
                    sx={{ fontSize: 14, fontWeight: "700", ml: "12px", mt: 1 }}
                  />
                );
              })}
          </Typography>
        </Div>
        <Div sx={{ position: "absolute", right: "10px" }}>
          <ActionsMenu handleEdit={handleEdit} />
        </Div>
      </AccordionSummary>

      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Div>
            <Typography variant={"h5"}>Facts</Typography>
            <Typography variant={"h6"}>{item?.facts}</Typography>
          </Div>
          <Div>
            <Typography variant={"h5"}>Binding Law To Facts</Typography>
            <Typography variant={"h6"}>{item?.bindingLawToFacts}</Typography>
          </Div>
          <Div>
            <Typography variant={"h5"}>History Of Case</Typography>
            <Typography variant={"h6"}>{item?.historyOfCase}</Typography>
          </Div>
          <Div>
            <Typography variant={"h5"}>Issues</Typography>
            <Typography variant={"h6"}>{item?.issues}</Typography>
          </Div>
          <Div>
            <Typography variant={"h5"}>Law Acts Applied</Typography>
            <Typography variant={"h6"}>{item?.lawActsApplied}</Typography>
          </Div>
          <Div>
            <Typography variant={"h5"}>Ratio Of Case</Typography>
            <Typography variant={"h6"}>{item?.ratioOfCase}</Typography>
          </Div>
          <Div>
            <Typography variant={"h5"}>Conclusion Ruling</Typography>
            <Typography variant={"h6"}>{item?.conclusionRuling}</Typography>
          </Div>
        </Div>
        {item?.rawDocumentUrls && isJudgement &&  (
          <>
            <Typography variant={"h5"} sx={{mt:2}}>Uploaded Document</Typography>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "150px",
                height: "150px",
                borderRadius: "5px",
                backgroundColor: "#EABF4E",
                fontSize: "1rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={handleDocumentClick}
            >
              <PictureAsPdfIcon
                size={16}
                color="red"
                style={{ marginLeft: "5px" }}
              />
              <Typography
                sx={{
                  maxWidth: "120px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.documentName}
              </Typography>
            </div>
            <Modal
              open={openModal}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 1000,
                },
              }}
            >
              <Fade in={openModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "100%", sm: "600px" },
                    height: { xs: "85%", sm: "550px" },
                    bgcolor: "background.paper",
                    border: "1px solid #000",
                    borderRadius: "8px",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <span
                    style={{
                      position: " absolute",
                      right: " -12px",
                      top: "-14px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      backgroundColor: "white",
                      textAlign: "center",
                      fontSize: "26px",
                    }}
                  >
                    <CancelTwoToneIcon
                      onClick={handleClose}
                      fontSize="inherit"
                    />
                  </span>
                  <Div sx={{ width: "100%", height: "100%" }}>
                    <iframe
                      title="document preview"
                      src={item?.rawDocumentUrls}
                      height={"100%"}
                      width={"100%"}
                    />
                  </Div>
                </Box>
              </Fade>
            </Modal>
          </>
        )}
        {item?.rawDocumentUrls && !isJudgement &&  (
          <>
            <Typography variant={"h5"} sx={{mt:2}}>Uploaded Document</Typography>
            {" "}
            <div
              style={{
                  position: "relative",
                  backgroundColor: "rgb(243 243 243)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "200px",
                  height: "auto",
                  borderRadius: "8px",
              }}
              onClick={handleDocumentClick}
            >
              <img
									style={{
										width: "100%",
										height: "150px",
										borderRadius: "5px",
										objectFit: "contain",
										"&:hover": {
											cursor: "pointer",
										},
									}}
									src={item?.rawDocumentUrls}
									alt="document"
									
								/>
            </div>
            <Modal
              open={openModal}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 1000,
                },
              }}
            >
              <Fade in={openModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "100%", sm: "600px" },
                    height: { xs: "85%", sm: "550px" },
                    bgcolor: "background.paper",
                    border: "1px solid #000",
                    borderRadius: "8px",
                    boxShadow: 24,
                    // p: 4,
                  }}
                >
                  <span
                    style={{
                      position: " absolute",
                      right: " -12px",
                      top: "-14px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      backgroundColor: "white",
                      textAlign: "center",
                      fontSize: "26px",
                    }}
                  >
                    <CancelTwoToneIcon
                      onClick={handleClose}
                      fontSize="inherit"
                    />
                  </span>
                  <Div sx={{ width: "100%", height: "100%" }}>
                    <img
                      title="document preview"
                      src={item?.rawDocumentUrls}
                      height={"100%"}
                      width={"100%"}
                    />
                  </Div>
                </Box>
              </Fade>
            </Modal>
          </>
        )}
        {/* <Typography variant={"h5"}>Details</Typography> */}
        <Div
          sx={{
            display: { xs: "flex", lg: "none" },
            minWidth: 0,
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              width: { xs: "auto", sm: "20%" },
              flexShrink: 0,
              px: 1,
              flex: { xs: "1", lg: "0 1 auto" },
            }}
          >
            <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
              {item.caseName}
            </Typography>
            <Typography
              variant={"h3"}
              color={"text.secondary"}
              fontSize={14}
              fontWeight="700"
            >
              Section&nbsp;{item.sections?.map((section) => `${section} `)}
            </Typography>
          </Div>

          <Div
            sx={{
              display: { xs: "none", lg: "flex" },
              width: "20%",
              flexShrink: 0,
              px: 2,
              gap: 5,
            }}
          >
            <Div>
              <Chip
                label={item.judge}
                color="primary"
                size="medium"
                variant="outlined"
                sx={{ fontSize: 14, fontWeight: "700", maxWidth: "100%" }}
              />
              {/* <Typography fontSize={"14px"}>{item.caseType?.caseName}</Typography> */}
            </Div>
            {/* <Div>
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Lawyer
            </Typography>
            <Typography fontSize={"14px"}>{item.isPetitioner ? "Respondent" : "Petitioner"}</Typography>
          </Div> */}
          </Div>
          <Div
            sx={{
              display: { xs: "none", lg: "block" },
              width: "15%",
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Court
            </Typography>
            <Div sx={{ display: "flex", gap: 1 }}>
              <AccountBalanceIcon color="secondary" />
              <Typography fontSize={"14px"}>{item.court}</Typography>
            </Div>
          </Div>
          {/* <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Uploaded By
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>Lawyer</Typography>
          </Div>
        </Div> */}
          <Div
            sx={{
              display: { xs: "none", lg: "block" },
              width: "15%",
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Date of Decision
            </Typography>
            <Div sx={{ display: "flex", gap: 1 }}>
              <CalendarMonthIcon color="secondary" />
              <Typography fontSize={"14px"}>
                {item.dateOfDecision
                  ? item.dateOfDecision
                  : "Date not given"}
              </Typography>
            </Div>
          </Div>
          <Div
            sx={{
              width: { xs: "auto", sm: "fit-content" },
              flexShrink: 0,
              px: 1,
              flex: { xs: "1", lg: "0 1 auto" },
              gap: 1,
            }}
          >
            <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
              {item.sections?.map((section) => {
                return (
                  <Chip
                    label={section}
                    color="primary"
                    size="medium"
                    variant="outlined"
                    sx={{ fontSize: 14, fontWeight: "700", ml: "12px", mt: 1 }}
                  />
                );
              })}
              {item?.court ? (
                <Chip
                  label={item?.court}
                  color="primary"
                  size="medium"
                  variant="outlined"
                  sx={{ fontSize: 14, fontWeight: "700", ml: "12px", mt: 1 }}
                />
              ) : null}
              {item?.judge ? (
                <Chip
                  label={item?.judge}
                  color="primary"
                  size="medium"
                  variant="outlined"
                  sx={{ fontSize: 14, fontWeight: "700", ml: "12px", mt: 1 }}
                />
              ) : null}
            </Typography>
          </Div>
        </Div>
      </Collapse>
    </Paper>
  );
};
/* Todo item prop define */
export default JudgementItem;
