import { Grid, Typography,Table,TableHead,TableBody,TableCell,TableRow } from "@mui/material";
import React from "react";
import PaymentsListItem from "./PaymentsListItem";
import styled from "@emotion/styled";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  backgroundColor:"#6D6A75",
  color:"white"
}));

function PaymentsList({ paymentsList }) {
  if (!paymentsList) {
    return <Typography>No Payments To display</Typography>;
  }
  
  return (
    <div style={{ overflow: 'auto', maxHeight: '980px'}}>
   <Table stickyHeader sx={{my:3,borderRadius:"20px",overflow:"hidden"}}>
        <TableHead>
          <TableRow>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "left",
                width: "5%",
              }}
            >
              S.No
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 2,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "21%",
              }}
            >
              Case Name
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
              Client Name
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
                Date
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
              Amount
            </StyledTableCell>

            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
              Status
            </StyledTableCell>
            {/* <StyledTableCell
              sx={{ pl: 3, fontSize: "1rem", fontWeight: 600, width: "16%" }}
              align={"center"}
            >
              Remaning
            </StyledTableCell> */}
            {/* <StyledTableCell
              sx={{ pr: 3, fontSize: "1rem", fontWeight: 600, width: "10%" }}
              align={"right"}
            >
              Action
            </StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody sx={{
           borderBottom:"2"
        }
        }>
          {paymentsList &&
            paymentsList.map((payment, index) => (
              <PaymentsListItem
                paymentsList={paymentsList}
                item={payment}
                key={index}
                index={index}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default PaymentsList;
