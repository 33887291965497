import React from "react";
import VerticalLinearStepper from "./veritcalSteps";
import Div from "@jumbo/shared/Div";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";

const VerticalStepper = () => {
  const isBelow990px = useMediaQuery((theme) => theme.breakpoints.down(990));

  return (
    <Div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100%",
        background: "#fff",
      }}
    >
      <Div sx={{ display: "flex", height: "15%" }}>
        <img
          src="/images/lawinzo/vakily-logo-black.png"
          alt="Logo"
          style={{
            objectFit:"cover",
            width: "74.32px",
            height: "74.32px",
            marginTop:'15px',
            marginLeft: isBelow990px ? "50px" : "150px",
          }}
        />
      </Div>
      <VerticalLinearStepper />
      <Div
        sx={{
          py: 2,
          px: { lg: 6, xs: 4 },
          borderTop: 2,
          borderColor: "divider",
          bgcolor: "black",
        }}
      >
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "sans-serif",
              fontHeight: 400,
              fontSize: "1rem",
              lineHeight: 1.5,
              color: "#F5F7FA",
            }}
            variant={"body1"}
            color={"white"}
          >
            Copyright Vakily © 2023
          </Typography>
          <Div sx={{ display: "flex", alignItems: "center",justifyContent: 'end' }}>
            <Typography
              variant={"body1"}
              sx={{
                display: { xs: "none", sm: "block" },
                mb: 0,
                mr: 2,
                fontFamily: "sans-serif",
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: 1.5,
                color: "#F5F7FA",
                marginBottom: "0px",
                marginRight: "16px",
              }}
            >
               Made with <span style={{color:"red",fontWeight:"700",fontSize: "1.3rem", paddingLeft:"4px",paddingRight:"4px"}}>♥️</span> In India
             {/* Made with <FavoriteIcon /> In India */}
             {/* <img style={{ width: 100, height: 100 }} src="/Make_In_India.png" alt="makeInIndia" /> */}
            </Typography>
            <img style={{ width: "15%" }} src="/Make_In_India-min.webp" alt="makeInIndia" />
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default VerticalStepper;
