import VerticalDefault from "../../layouts/vertical-default/VerticalDefault";
import SoloPage from "../../layouts/solo-page/SoloPage";
// import Enquiry from "../../layouts/Enquiry/Enquiry";
import HorizontalLayout from "../../layouts/horizontal-default/HorizontalLayout";

const LAYOUT_NAMES = {
  VERTICAL_DEFAULT: "vertical-default",
  SOLO_PAGE: "solo-page",
  HORIZONTAL_LAYOUT: "horizontal-layout",
  DEFAULT:"default",
  // ENQUIRY:"Enquiry"
};

const LAYOUTS = [
  {
    name: LAYOUT_NAMES.VERTICAL_DEFAULT,
    label: "Vertical Default",
    component: VerticalDefault,
  },
  {
    name: LAYOUT_NAMES.DEFAULT,
    label: "default",
    component: VerticalDefault,
  },
  // {
  //   name: LAYOUT_NAMES.ENQUIRY,
  //   label: "Enquiry",
  //   component: Enquiry,
  // },
  {
    name: LAYOUT_NAMES.SOLO_PAGE,
    label: "Solo Page",
    component: SoloPage,
  },
  {
    name: LAYOUT_NAMES.HORIZONTAL_LAYOUT,
    label: "Solo Page",
    component: HorizontalLayout,
  },
];

export { LAYOUTS, LAYOUT_NAMES };
