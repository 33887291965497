import Div from "@jumbo/shared/Div";
import React from "react";
import { NoCaseContainer } from "app/pages/MyCases/style";
import NoCaseImage from "app/ImageAssets/NoCaseImage.png";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { GavelOutlined } from "@mui/icons-material";

const NoAppointment = ({ setAddApp,hide}) => {
  return (
    <>
    
      <Div sx={{ height: "100%", width: "100%" }}>
      {!hide && 
      <Div sx={{ mb: 2, mt: -3,}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} fontSize="inherit" />
              Home
            </Typography>

            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <GavelOutlined sx={{ fontSize: 20 }} />
              Appointments
            </Typography>
          </Breadcrumbs>
        </Div>}
        <Div
          sx={{
            backgroundColor: "white",
            height: "100%",
            position: "relative",
            paddingTop: "10px",
            border: 0,
          }}
        >
          <CardContent>
          {!hide &&  <Div
              sx={{
                width: "100%",
                position: "relative",
                height: "fit-content",
                mt: -2,
                mb: 8,
              }}
            >
              <Div
                sx={{
                  position: "absolute",
                  borderBottom: "1px solid #D3D3D3 ",
                  width: "100%",
                  pt: 0,
                  pb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" color="text.primary">
                  Appointments
                </Typography>
              </Div>
            </Div> }
            <NoCaseContainer>
              <Div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Div
                  sx={{
                    width: { xs: "60%", sm: "30%", md: "150px" },
                    height: "30%",
                  }}
                >
                  <img
                    src={NoCaseImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </Div>

                <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    textAlign: "center",
                    mt: 2,
                  }}
                >  
                  You haven't added any Appointments yet!
                </Typography>
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 4,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "secondary.main",
                      mb: 2,
                      width: "200px",
                    }}
                    onClick={() => setAddApp(true)}
                  >
                    Add Appointment
                  </Button>
                </Div>
              </Div>
            </NoCaseContainer>
          </CardContent>
        </Div>
      </Div>
    </>
  );
};

export default NoAppointment;
