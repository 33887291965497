import {
  UPDATE_CASE_ID,
  UPDATE_CASE_NAME,
  UPDATE_CASE_TYPE,
  UPDATE_CASE_NUMBER,
  UPDATE_GR_NO,
  UPDATE_FIR_NO,
  UPDATE_COURT,
  UPDATE_ASSIGN_LAWYER,
  UPDATE_CASE_DESCRIPTION,
  UPDATE_UNDER_SECTION,
  UPDATE_FILLING_NUMBER,
  UPDATE_REGISTRATION_NUMBER,
  UPDATE_CNR_NUMBER,
  UPDATE_FILLING_DATE,
  UPDATE_REGISTRATION_DATE,
  UPDATE_CASE_FIRST_NAME,
  UPDATE_CASE_SECOND_NAME,
  UPDATE_IS_PETITIONER,
  UPDATE_LAST_HEARING_DATE,
  UPDATE_NEXT_HEARING_DATE,
  UPDATE_OPPOSING_LAWYER,
  UPDATE_CASE_STAGE,
  UPDATE_DOCUMENT_LISTS,
  UPDATE_CASE_RESPONSE,
  UPDATE_WITNESS_DETAILS,
  UPDATE_HEARING_DOCUMENT_LISTS,
  UPDATE_HEARING_PURPOSE,
  UPDATE_HEARING_DATE,
  UPDATE_CASE_TASK_DESCRIPTION,
  UPDATE_CASE_TASK_NAME,
  UPDATE_CASE_TASK_DOCUMENT_LISTS,
  UPDATE_CASE_TASK_PRIORITY,
  UPDATE_HEARING_DESCRIPTION,
  CLEAR_CASE_DATA,
  UPDATE_CLIENTS,
  UPDATE_WITNESS,
  UPDATE_OPP_CLIENTS // Added action type for updating witness details
} from "../../utils/constants/dashboard";

export const updateCaseResponse = (caseResponse) => ({
  type: UPDATE_CASE_RESPONSE,
  payload: caseResponse,
});

export const updateCaseId = (caseId) => ({
  type: UPDATE_CASE_ID,
  payload: caseId,
});

export const updateOpposingLawyer = (opposingLawyer) => ({
  type: UPDATE_OPPOSING_LAWYER,
  payload: opposingLawyer,
});

export const updateCaseStage = (caseStage) => ({
  type: UPDATE_CASE_STAGE,
  payload: caseStage,
});

export const updateLastHearingDate = (date) => ({
  type: UPDATE_LAST_HEARING_DATE,
  payload: date,
});

export const updateNextHearingDate = (date) => ({
  type: UPDATE_NEXT_HEARING_DATE,
  payload: date,
});

export const updateCaseName = (name) => ({
  type: UPDATE_CASE_NAME,
  payload: name,
});
export const updateCaseFirstName = (name) => ({
  type: UPDATE_CASE_FIRST_NAME,
  payload: name,
});
export const updateCaseSecondName = (name) => ({
  type: UPDATE_CASE_SECOND_NAME,
  payload: name,
});

export const updateCaseType = (type) => ({
  type: UPDATE_CASE_TYPE,
  payload: type,
});
export const updateIsPetitioner = (type) => ({
  type: UPDATE_IS_PETITIONER,
  payload: type,
});

export const updateCaseNumber = (number) => ({
  type: UPDATE_CASE_NUMBER,
  payload: number,
});

export const updateGRNo = (grNo) => ({
  type: UPDATE_GR_NO,
  payload: grNo,
});

export const updateFIRNo = (firNo) => ({
  type: UPDATE_FIR_NO,
  payload: firNo,
});

export const updateCourt = (court) => ({
  type: UPDATE_COURT,
  payload: court,
});

export const updateAssignLawyer = (lawyer) => ({
  type: UPDATE_ASSIGN_LAWYER,
  payload: lawyer,
});

export const updateCaseDescription = (description) => ({
  type: UPDATE_CASE_DESCRIPTION,
  payload: description,
});

export const updateUnderSection = (underSection) => ({
  type: UPDATE_UNDER_SECTION,
  payload: underSection,
});

export const updateFillingNumber = (fillingNumber) => ({
  type: UPDATE_FILLING_NUMBER,
  payload: fillingNumber,
});

export const updateRegistrationNumber = (registrationNumber) => ({
  type: UPDATE_REGISTRATION_NUMBER,
  payload: registrationNumber,
});

export const updateCnrNumber = (cnrNumber) => ({
  type: UPDATE_CNR_NUMBER,
  payload: cnrNumber,
});

export const updateFillingDate = (fillingDate) => ({
  type: UPDATE_FILLING_DATE,
  payload: fillingDate,
});

export const updateRegistrationDate = (registrationDate) => ({
  type: UPDATE_REGISTRATION_DATE,
  payload: registrationDate,
});

export const updateWitnessDetails = (witnessDetails) => ({
  type: UPDATE_WITNESS_DETAILS,
  payload: witnessDetails,
});

export const updateDocumentLists = (documentLists) => ({
  type: UPDATE_DOCUMENT_LISTS,
  payload: documentLists,
});

export const updateHearingDocumentLists = (documentLists) => ({
  type: UPDATE_HEARING_DOCUMENT_LISTS,
  payload: documentLists,
});
export const updateHearingPurpose = (purpose) => ({
  type: UPDATE_HEARING_PURPOSE,
  payload: purpose,
});
export const updateHearingDate = (date) => ({
  type: UPDATE_HEARING_DATE,
  payload: date,
});
export const updateHearingDescription = (description) => ({
  type: UPDATE_HEARING_DESCRIPTION,
  payload: description,
});
export const updateCaseTaskDescription = (description) => ({
  type: UPDATE_CASE_TASK_DESCRIPTION,
  payload: description,
});
export const updateCaseTaskName = (name) => ({
  type: UPDATE_CASE_TASK_NAME,
  payload: name,
});
export const updateCaseTaskPriority = (priority) => ({
  type: UPDATE_CASE_TASK_PRIORITY,
  payload: priority,
});
export const updateCaseTaskDocumentLists = (documentLists) => ({
  type: UPDATE_CASE_TASK_DOCUMENT_LISTS,
  payload: documentLists,
});

export const clearCaseData = () => ({
  type: CLEAR_CASE_DATA,
});


export const updateCaseClients = (clientsArray) => ({
  type: UPDATE_CLIENTS,
  payload: clientsArray,
});
export const updateCaseOppClients = (clientsArray) => ({
  type: UPDATE_OPP_CLIENTS,
  payload: clientsArray,
});
export const updateCaseWitness = (clientsArray) => ({
  type: UPDATE_WITNESS,
  payload: clientsArray,
});
