import { Typography } from '@mui/material';
import React from 'react'
import PaymentCardItem from './PaymentCardItem';

const PaymentCardList = ({ paymentsList }) => {
    if (!paymentsList) {
        return <Typography>No Payments To display</Typography>;
      }
  return (
    <div style={{ overflow: 'auto', maxHeight: '980px'}}>
        {
            paymentsList && paymentsList.length>0 && (
                <>
                    {paymentsList.map((payment,index)=>{
                        return(
                            <PaymentCardItem
                                item={payment}
                                key={index}
                            />
                        )
                    })}
                </>
            )
        }
    </div>
  )
}

export default PaymentCardList