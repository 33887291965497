import Div from "@jumbo/shared/Div";
import {ImageList,ImageListItem, Typography } from "@mui/material";
import React from "react";
import Link from "@mui/material/Link";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function LoginFooter() {
  return (
    <>
    <Div sx={{ mt: 0 }}>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxHeight: "25%",
          marginTop: 0,
        }}
      >
        <Div
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 5,
          }}
        >
          <Link
            variant={"h2"}
            sx={{
              "color": "#EABF4E",
              "fontSize": "12px",
              "fontWeight": "500",
              "mb": 4,
              "cursor": "pointer",
              "&:hover": {
                color: "black",
              },
            }}
            href="https://vakily.app/terms.html"
            underline="none"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </Link>
          <Link
            variant={"h2"}
            sx={{
              "color": "#EABF4E",
              "fontSize": "12px",
              "fontWeight": "500",
              "mb": 4,
              "cursor": "pointer",
              "&:hover": {
                color: "black",
              },
            }}
            href="https://vakily.app/privacy.html"
            underline="none"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            variant={"h2"}
            sx={{
              "color": "#EABF4E",
              "fontSize": "12px",
              "fontWeight": "500",
              "mb": 4,
              "cursor": "pointer",
              "&:hover": {
                color: "black",
              },
            }}
            href="/helpline"
            underline="none"
            target="_blank"
            rel="noreferrer"
          >
            Help
          </Link>
        </Div>
        {/* <Div sx={{ display: "flex", alignItems: "start" }}>
      <Typography
        variant={"h2"}
        sx={{
          color: "#EABF4E",
          fontSize: "12px",
          fontWeight: "500",
          mb: 4,
          cursor: "pointer",
        }}
      >
   English
      </Typography>
      
      <KeyboardArrowUpIcon color="secondary" fontSize="inherit"   mb= "4" />
    </Div> */}
      </Div>

      <Typography
        variant={"h2"}
        sx={{
          color: "#151513",
          fontSize: "14px",
          fontWeight: "600",
          mb: 2,
        }}
      >
        Vakily is India's online legal service marketplace,that especially
        build for lawyers and law firms.
      </Typography>
      <Typography
        variant={"h2"}
        sx={{
          color: "#151513",
          fontSize: "14px",
          fontWeight: "700",
        }}
      >
        © 2023 Vakily.app @ All Rights Reserved
      </Typography>
    <Div
      sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
    >
        <Typography
          variant={"body1"}
          sx={{
            mb: 0,
            mr: 2,
            color: "text.primary",
          }}
        >
          Made with{" "}
          <span style={{ color: "red", fontWeight: "700", fontSize: "1.3rem" }}>
            ♥️
          </span>{" "}
          In India
        </Typography>
        <ImageList sx={{ display: { xs: "block", sm: "block", width: "10%" } }}>
          <ImageListItem>
            <img src="/Make_In_India-min.webp" loading="lazy" width="10%" />
          </ImageListItem>
        </ImageList>
      </Div>
    </Div>
      </>
  );
}

export default LoginFooter;
