import React from 'react';
import {Typography} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import ScheduleItem from "./ScheduleItem";
import {scheduleData} from "./data";

const ScheduleList = ({hearingList,setHearingEdit , setIsEditing,setAddHearing}) => {
    console.log(hearingList)
    console.log(setHearingEdit)

    return (
      <React.Fragment>
        <Typography variant={"h5"} color={"text.secondary"} mb={2}>
          Hearings
        </Typography>
        <Timeline
          sx={{
            m: 0,
            p: 0,
          }}
        >
          {hearingList?.map((item, index) => (
            <ScheduleItem
              index={index}
              item={item}
              key={index}
              setAddHearing={setAddHearing}
              setHearingEdit={setHearingEdit}
              setIsEditing={setIsEditing}
            />
          ))}
        </Timeline>
      </React.Fragment>
    );
};

export default ScheduleList;
