import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import {  getHearingPurpose } from "app/Apis/case";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import {  ECourtValidations } from "app/utils/validators";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFillingNumber,
  updateRegistrationNumber,
  updateCnrNumber,
  updateFillingDate,
  updateRegistrationDate,
  updateNextHearingDate,
  updateLastHearingDate,
  updateOpposingLawyer,
  updateCaseStage,
  updateCaseResponse
} from "../../redux/actions/dashboard";
import { updateCaseListItem } from "app/redux/actions/case";
import { Today } from "@mui/icons-material";

function ECourtDetails({ handleBackButton, handleNextButton,setIsLoading }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchHearingPurpose = async () => {
      try {
        const hearingOptions = await getHearingPurpose(lawyer.accessToken);
        console.log("Response from getCaseId:", hearingOptions);
        setPurpose(hearingOptions?.data?.mPurposeHearings);
        console.log("User profile data fetched:", hearingOptions);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchHearingPurpose();
  }, []);
  
  const caseResponse = useSelector((state)=>state.dashboard.caseResponse)
  const ECourtDetails = async (values,caseResponse) => {
    const ecourtDetails ={
        caseStage: values.caseStage,
        cnrNumber: values.CnrNumber,
        filingDate: values.FillingDate?values.FillingDate.format("YYYY-MM-DD HH:mm:ss"):"",
        fillingNumber:values.FillingNumber,
        hearingDate:values.nextHearingDate? values.nextHearingDate.format("YYYY-MM-DD HH:mm:ss"):"",
        lastHearingDate:values.lastHearingDate?values.lastHearingDate.format("YYYY-MM-DD HH:mm:ss"):"",
        oppositeLawyer: values.opposingLawyer,
        registrationDate:values.RegistrationDate? values.RegistrationDate.format("YYYY-MM-DD HH:mm:ss"):"",
        registrationNumber: values.RegistrationNumber
      }    
    const payload = {
      caseId: caseResponse.caseId,
      ecourtDetails:ecourtDetails 
    }
    console.log(payload)
    setIsLoading(true)
    fetch(`https://erp.lawinzo.com/api/v1/cases?pageNo=1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
        Authorization: `Bearer ${lawyer.accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        
        CaseResponse(data)
        console.log(data);
        UpdateCaseListItem(data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error("Error:", error);
      });
  };
  const [reset, setReset] = useState();
  const _id = useSelector((state) => {
    return state;
  });
  const userDetailsFromRedux = useSelector((state)=>state.onboardingInputs)
  const lawyer = {
    fullName: userDetailsFromRedux.fullName,
    lawyerId:userDetailsFromRedux.lawyerId,
    accessToken :userDetailsFromRedux.accessToken
  }
  console.log(_id);
  const eCourtDetailsFromRedux = useSelector((state)=>state.dashboard.ECourtDetails)
  const [FillingNumber, setFillingNumber] = useState(eCourtDetailsFromRedux.fillingNumber);
  const [RegistrationNumber, setRegistrationNumber] = useState(eCourtDetailsFromRedux.registrationNumber);
  const [CnrNumber, setCnrNumber] = useState(eCourtDetailsFromRedux.cnrNumber);
  const [opposingLawyer, setOpposingLawyer] = useState(eCourtDetailsFromRedux.opposingLawyer);
  const [FillingDate, setFillingDate] = useState(eCourtDetailsFromRedux.fillingDate? eCourtDetailsFromRedux.fillingDate : null);
  const [RegistrationDate, setRegistrationDate] = useState(eCourtDetailsFromRedux.registrationDate?eCourtDetailsFromRedux.registrationDate:null);
  const [lastHearingDate, setLastHearingDate] = useState(eCourtDetailsFromRedux.lastHearingDate?eCourtDetailsFromRedux.lastHearingDate:null)
  const [nextHearingDate, setNextHearingDate] = useState(eCourtDetailsFromRedux.nextHearingDate?eCourtDetailsFromRedux.nextHearingDate:null)
  const [caseStage , setCaseStage] = useState(eCourtDetailsFromRedux.caseStage?eCourtDetailsFromRedux.caseStage:null)
  const [purpose, setPurpose] = useState([])
  
  const dispatch = useDispatch();
  const UpdateCaseListItem = (value) => {
    dispatch(updateCaseListItem(value));
  };


  const CaseResponse = (value) => {
    dispatch( updateCaseResponse(value));
    console.log(value);
  };

  const FillingNo = (value) => {
    dispatch(updateFillingNumber(value));
    console.log(value);
  };

  const RegNo = (value) => {
    dispatch(updateRegistrationNumber(value));
    console.log(value);
  };

  const CaseStage = (value) => {
    dispatch(updateCaseStage(value));
    console.log(value);
  };

  const CnrNo = (value) => {
    dispatch(updateCnrNumber(value));
    console.log(value);
  };
  const OpposingLawyer = (value) => {
    dispatch(updateOpposingLawyer(value));
    console.log(value);
  };

  const NextHearingDate = (value) => {
    dispatch(updateNextHearingDate(value));
    console.log(value);
  };
  const LastHearingDate = (value) => {
    dispatch(updateLastHearingDate(value));
    console.log(value);
  };


  const FDate = (value) => {
    dispatch(updateFillingDate(value));
    console.log(value);
  };

  const RegDate = (value) => {
    dispatch(updateRegistrationDate(value));
    console.log(value);
  };

  const formik = useFormik({
    initialValues: {
      FillingNumber: FillingNumber,
      RegistrationNumber: RegistrationNumber,
      CnrNumber: CnrNumber,
      FillingDate: FillingDate,
      RegistrationDate: RegistrationDate,
      lastHearingDate: lastHearingDate,
      nextHearingDate: nextHearingDate,
      opposingLawyer: opposingLawyer,
      caseStage : caseStage
    },
    validationSchema: ECourtValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      FillingNo(FillingNumber);
      RegNo(CnrNumber);
      CnrNo(RegistrationNumber);
      FDate(FillingDate);
      RegDate(RegistrationDate);
      NextHearingDate(nextHearingDate);
      LastHearingDate(lastHearingDate);
      OpposingLawyer(opposingLawyer);
      CaseStage(caseStage);
      handleNextButton();
      ECourtDetails(values,caseResponse);
    },
  });

  if (reset) {
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        {/* <form> */}

        <TextField
          sx={{ mb: 2, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Filling Number"
          color="secondary"
          variant="outlined"
          value={FillingNumber}
          onChange={(e) => setFillingNumber(e.target.value)}
          // error={formik.errors.FillingNumber}
          name="FillingNumber"
          // helperText={formik.errors.FillingNumber}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="Case Filling Date"
              format="DD/MM/YYYY"
              disableFuture
              value={formik.values.FillingDate}
              onChange={(newValue) => setFillingDate(newValue)}
              slotProps={{
                textField: {
                  error: formik.errors.FillingDate,
                  helperText:formik.errors.FillingDate,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="FillingDate"
                  color="secondary"
                  sx={{  width: "100%",mt: 2 }}
                  // error={formik.errors.FillingDate && formik.touched.FillingDate}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>

        <TextField
          sx={{ mb: 2, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Registration Number"
          color="secondary"
          variant="outlined"
          value={RegistrationNumber}
          onChange={(e) => setRegistrationNumber(e.target.value)}
          error={formik.errors.RegistrationNumber}
          name="RegistrationNumber"
          helperText={formik.errors.RegistrationNumber}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="Case Registration Date"
              disableFuture

              format="DD/MM/YYYY"
              value={formik.values.RegistrationDate}
              onChange={(newValue) => setRegistrationDate(newValue)}
              slotProps={{
                textField: {
                  error:formik.errors.RegistrationDate,
                  helperText:formik.errors.RegistrationDate,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="RegistrationDate"
                  color="secondary"
                  sx={{ width: "100%" ,mt: 2}}
                  error={formik.errors.RegistrationDate && formik.touched.RegistrationDate}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>

        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="CNR Number"
          color="secondary"
          variant="outlined"
          value={CnrNumber}
          onChange={(e) => setCnrNumber(e.target.value)}
          error={formik.errors.CnrNumber}
          name="Cnr Number"
          helperText={formik.errors.CnrNumber}
        />

        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Opposing Lawyer"
          color="secondary"
          variant="outlined"
          value={opposingLawyer}
          onChange={(e) => setOpposingLawyer(e.target.value)}
          // error={formik.errors.opposingLawyer}
          name="Opposing Lawyer"
          // helperText={formik.errors.opposingLawyer}
        />

          <Autocomplete
            style={{ width: "100%" }}
            value={formik.values.caseStage}
            onChange={(e,v) => {
              setCaseStage(v)
            }}
            isOptionEqualToValue={(option, value) =>
              option.purposeId === value.purposeId
            }
            name="caseStage"
            options={purpose}
            // disableCloseOnSelect
            getOptionLabel={(option) => option?.purposeHearing}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option?.purposeHearing}</li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "16px" }}
                {...params}
                label="Case Stage"
                placeholder="Select Case Stage"
                color="secondary"
                // error={caseError}
                error={formik.errors.caseStage && formik.touched.caseStage}
                helperText={formik.errors.caseStage}
                name="caseStage"
              />
            )}
          />


        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3} sx={{  width: "100%",mt: 2 }}>
            <DatePicker
              label="Last Hearing Date"
              format="DD/MM/YYYY"
              disableFuture
              minDate={FillingDate}
              maxDate={Today}
              value={formik.values.lastHearingDate}
              onChange={(newValue) => setLastHearingDate(newValue)}
              slotProps={{
                textField: {
                  error: formik.errors.lastHearingDate,
                  helperText: formik.errors.lastHearingDate,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //  error={formik.errors.FillingDate}
                  name="lastHearingDate"
                  color="secondary"
                  // helperText={formik.errors.FillingDate}
                  sx={{  width: "100%",mt: 2 }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3} sx={{  width: "100%",mt: 2 }}>
          <DatePicker
            label="Next Hearing Date"
            format="DD/MM/YYYY"
            minDate={lastHearingDate}
            value={formik.values.nextHearingDate}
            onChange={(newValue) => setNextHearingDate(newValue)}
            slotProps={{
              textField: {
                error: formik.errors.nextHearingDate,
                  helperText: formik.errors.nextHearingDate,
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                //  error={formik.errors.FillingDate}
                name="nextHearingDate"
                color="secondary"
                // helperText={formik.errors.FillingDate}
                sx={{  width: "100%",mt: 2 }}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>

        {/* </form> */}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            disabled={false}
            variant="contained"
            sx={{
              "width": 100,
              "padding": 1,
              "backgroundColor": "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={()=> {formik.handleSubmit()
            console.log(formik.errors)
              console.log(formik.values)
          }
            }
            color="secondary"
            sx={{
              "width": 100,
              "padding": 1,
              "backgroundColor": "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default ECourtDetails;
