import { ASSET_IMAGES } from "app/utils/constants/paths";
import React, { useEffect, useRef, useState } from "react";
import ChatbotUiBlocks from "./ChatbotUiBlocks";

import "./chatbot.css";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import ChatbotLangSelect from "./ChatbotLangSelect";
import {
	FormControlLabel,
	Switch,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import RecordRTC from "recordrtc";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";

const { default: axios } = require("axios");

function ChatbotUi() {
	const [recorder, setRecorder] = useState(null);
	const [audioBlob, setAudioBlob] = useState(null);
	const [startRecorder, setStartRecorder] = useState(false);
	const [recording, setRecording] = useState(false);
	const [remainingTime, setRemainingTime] = useState(120);
	const [shouldLogin, setShouldLogin] = useState(false);

	useEffect(() => {
		let countdownInterval;

		if (recording) {
			countdownInterval = setInterval(() => {
				setRemainingTime((prevTime) => prevTime - 1);
			}, 1000);
		}

		return () => {
			clearInterval(countdownInterval);
		};
	}, [recording]);

	const startRecording = async () => {
		try {
			setRemainingTime(120);

			setStartRecorder(true);
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			const options = {
				type: "audio",
				mimeType: "audio/wav",
				recorderType: RecordRTC.StereoAudioRecorder,
			};

			const record = new RecordRTC(stream, options);
			record.startRecording();

			setRecorder(record);
			setRecording(true);

			// Automatically stop recording after 2 minutes (120 seconds)
			setTimeout(() => {
				stopRecording();
			}, 120000);
		} catch (error) {
			console.log(error.message);
		}
	};

	const stopRecording = () => {
		try {
			setStartRecorder(false);
			recorder.stopRecording(async () => {
				const blob = recorder.getBlob();
				setAudioBlob(blob);
				const formData = new FormData();
				formData.append("audio", blob, "recorded_audio.wav");
				formData.append("lang", lang);

				setisResponseLoaded(false);
				setChats([...chats, { type: "chatbotLoading", content: "" }]);
				try {
					const response = await fetch(
						"https://api.lawinzo.com/node/chatbotreply",
						{
							method: "POST",
							body: formData,
							headers: {
								// "Content-Type": "application/json",
								Authorization: `Bearer ${Cookies.get("accessToken")}`,
								"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
							},
						}
					);

					if (response.ok) {
						console.log("Audio uploaded successfully");
					} else {
						console.error("Error uploading audio");
					}

					const data = await response.json();
					setChats([
						...chats,
						{ type: "user", content: data.data.content },
						{ type: "chatbot", content: data.data.response },
					]);
					setShouldLogin(data.data.login);
					setisResponseLoaded(true);
				} catch (error) {
					console.error("Network error:", error);
				}
			});
		} catch (error) {
			console.log(error.message);
		}
	};
	const location = useLocation();
	const navigate = useNavigate();
	const [lang, setLang] = useState("English");
	const queryParams = new URLSearchParams(location.search);
	const [chats, setChats] = useState([
		{
			type: "chatbot",
			content:
				"Welcome to Sargun Vakily AI Chatbot!\n How May I help you today?",
		},
	]);
	const [user, setUser] = useState(null);
	const [isActive, setActive] = useState(false);
	const [isActiveInput, setActiveInput] = useState(false);
	const [isResponseLoaded, setisResponseLoaded] = useState(true);
	const [isLastLoaded, setisLastLoaded] = useState(false);

	console.log(lang);

	useEffect(() => {
		if (queryParams.get("isMobile")) {
			console.log("mobile");
			navigate(`/chatbot?jwt=${queryParams.get("jwt")}`);
		}
		async function fetchUser(token) {
			let config = {
				method: "get",
				maxBodyLength: Infinity,
				url: "https://erp.lawinzo.com/user/me", //prev https://erp.lawinzo.com
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};
			const response = await axios.request(config);
			setUser(response.data);

			if (queryParams.get("isLastTrue")) {
				var myHeaders = new Headers();
				myHeaders.append("x-api-key", "449772DE-2780-4412-B9F7-E49E48605875");
				myHeaders.append(
					"Authorization",
					`Bearer ${Cookies.get("accessToken")}`
				);
				var requestOptions = {
					method: "GET",
					headers: myHeaders,
					redirect: "follow",
				};

				const lastresponse = await fetch(
					"https://api.lawinzo.com/node/getlastmsg",
					requestOptions
				);
				const lastmsgdata = await lastresponse.json();
				console.log(lastmsgdata.success);
				if (!lastmsgdata.success) {
					return;
				}

				console.log(lastmsgdata.data.response);

				setChats([
					...chats,
					{ type: "user", content: lastmsgdata.data.content },
					{ type: "chatbot", content: lastmsgdata.data.response },
				]);
			}
		}
		fetchUser(Cookies.get("accessToken"));
	}, []);

	const inputRef = useRef(null);
	const scrollDownRef = useRef(null);
	const autoScrollRef = useRef(null);

	useEffect(() => {
		if (chats.length) {
			autoScrollRef.current?.scrollIntoView({
				behaviour: "smooth",
				block: "end",
			});
		}
	}, [chats.length]);

	const handleChat = async () => {
		setActiveInput(true);

		if (inputRef.current.value === "") {
			setActiveInput(false);
			return;
		}
		const text = inputRef.current.value;
		inputRef.current.value = "";
		setisResponseLoaded(false);
		setChats([
			...chats,
			{ type: "user", content: text },
			{ type: "chatbotLoading", content: "" },
		]);
		async function fetchResponse(dataa) {
			const response = await fetch(
				"https://api.lawinzo.com/node/chatbotreply", // "https://api.lawinzo.com/node/api/v1/documnetDraft"
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${Cookies.get("accessToken")}`,
						"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
					},
					body: JSON.stringify({
						data: dataa,
						lang,
					}),
				}
			);
			const data = await response.json();

			console.log(data.data);
			setisResponseLoaded(true);
			setChats([
				...chats,
				{ type: "user", content: text },
				{ type: "chatbot", content: data.data.response },
			]);
		}
		await fetchResponse(text);

		setActiveInput(false);
		setActive(true);
	};

	const handleKeyPress = (event) => {
		setActive(true);
		if (inputRef.current.value === "") {
			setActive(false);
		}
		if (event.key === "Enter") {
			handleChat();
		}
	};
	return (
		user && (
			<div
				className=""
				style={{
					position: "relative",
					height: "100vh",
					overflow: "hidden",
					borderRadius: "15px",
				}}
			>
				{/* <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/home" underline="none">
            <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
            >
                <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
                Home
            </Typography>
        </Link>
        <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
        >
            <CalendarMonthOutlinedIcon sx={{ fontSize: 20 }} />
            &nbsp;Draft Editor
        </Typography>
    </Breadcrumbs> */}

				<div
					ref={scrollDownRef}
					style={{
						position: "relative",
						backgroundColor: "#343541",
						height: "100%",
						color: "white",
						overflow: "hidden",
						// overflowY: "scroll",
						position: "relative",
					}}
				>
					<header
						style={{
							position: "absolute",
							width: "100%",
							backgroundColor: "inherit",
							top: "0",
							display: "flex",
							alignItems: "stretch",
							justifyContent: "space-between",
							padding: "1.5rem 2.5rem",
							borderBottom: "2px solid rgba(130, 132, 153, 1)",
						}}
					>
						<div
							className=""
							style={{ display: "flex", alignItems: "center", gap: "10px" }}
						>
							<div
								className=""
								style={{
									width: "2.5rem",
									aspectRatio: "1/1",
									borderRadius: "5px",
									backgroundColor: "#eabf4e",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<img
									src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
									style={{ width: "100%" }}
									alt=""
									srcset=""
								/>
							</div>
							<div className="">
								<h3
									style={{
										color: "white",
										margin: "0",
										position: "relative",
										top: "1px",
									}}
								>
									Chat with
								</h3>
								<h4
									style={{
										color: "white",
										margin: "0",
										position: "relative",
										top: "-4px",
									}}
								>
									Sargun
								</h4>
							</div>
						</div>
						{/* <ChatbotLangSelect setLang={setLang} /> */}
						{/* <FormControlLabel
							control={
								<Switch
									style={{ color: lang === "English" ? "purple" : "blue" }}
									onChange={() => {
										if (lang === "English") {
											setLang("Hindi");
										} else {
											setLang("English");
										}
									}}
									defaultChecked
								/>
							}
							label={lang}
						/> */}
						<div
							className=""
							style={{
								position: "relative",
								height: "100%",
							}}
						>
							{/* <JumboDemoCard
								title={"Color"}
								wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
							>
								<ToggleButtonGroup
									style={{
										position: "relative",
										height: "100%",
									}}
									color="primary"
									value={lang}
									exclusive
									onChange={(event, newLang) => {
										console.log(newLang);
										setLang(newLang);
									}}
								>
									<ToggleButton style={{ padding: "6px 12px" }} value="English">
										English
									</ToggleButton>
									<ToggleButton style={{ padding: "6px 12px" }} value="Hindi">
										Hindi
									</ToggleButton>
								</ToggleButtonGroup>
							</JumboDemoCard> */}
							<div class="switches-container">
								<input
									type="radio"
									id="switchMonthly"
									name="switchPlan"
									value="Hindi"
									onChange={(event) => {
										setLang("Hindi");
									}}
									checked={lang === "Hindi" ? "checked" : ""}
								/>
								<input
									type="radio"
									id="switchYearly"
									name="switchPlan"
									onChange={(event) => {
										setLang("English");
									}}
									value="English"
									checked={lang === "English" ? "checked" : ""}
								/>
								<label for="switchMonthly">Hindi</label>
								<label for="switchYearly">English</label>
								<div class="switch-wrapper">
									<div class="switch">
										<div>Hindi</div>
										<div>English</div>
									</div>
								</div>
							</div>
						</div>
					</header>
					<div
						className="chat-grid"
						style={{
							height: "100%",
							padding: "6rem 0",
							overflow: "hidden",
							overflowY: "scroll",
						}}
					>
						{chats.map((e, index) => {
							return (
								<ChatbotUiBlocks
									user={user}
									isResponseLoaded={isResponseLoaded}
									key={index}
									type={e.type}
									content={e.content}
								/>
							);
						})}

						<div
							className=""
							style={{ paddingTop: "150px" }}
							ref={autoScrollRef}
						></div>
					</div>
					<footer
						style={{
							padding: "1.5rem 2.5rem",
							position: "absolute",
							bottom: "0",
							width: "100%",
							backgroundColor: "inherit",
						}}
					>
						<div
							style={{
								width: "100%",
								maxWidth: "800px",
								margin: "0 auto",
								position: "relative",
							}}
						>
							<input
								ref={inputRef}
								onKeyDown={handleKeyPress}
								type="text"
								style={{
									width: "100%",
									backgroundColor: "rgba(67, 70, 84, 1)",
									border: "none",
									padding: "18px 16px",
									paddingRight: "100px",
									fontSize: "1rem",
									color: "white",
									borderRadius: "8px",
								}}
								placeholder="Send a message"
							/>
							<div
								className=""
								style={{
									position: "absolute",
									right: "0",
									height: "100%",
									aspectRatio: "1/1",
									top: "0",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									gap: "10px",
									padding: "8px",
								}}
							>
								<button
									className=""
									onClick={handleChat}
									style={{
										width: "100%",
										height: "100%",
										borderRadius: "8px",
										border: "none",
										backgroundColor: "#eabf4e",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="#ffffff"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
										class="lucide lucide-send-horizontal"
									>
										<path d="m3 3 3 9-3 9 19-9Z" />
										<path d="M6 12h16" />
									</svg>
								</button>
								{!startRecorder ? (
									<button
										onClick={startRecording}
										style={{
											width: "100%",
											height: "100%",
											borderRadius: "8px",
											border: "none",
											backgroundColor: "#eabf4e",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											stroke="#ffffff"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
											class="lucide lucide-mic"
										>
											<path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z" />
											<path d="M19 10v2a7 7 0 0 1-14 0v-2" />
											<line x1="12" x2="12" y1="19" y2="22" />
										</svg>
									</button>
								) : (
									<button
										onClick={stopRecording}
										style={{
											width: "100%",
											height: "100%",
											borderRadius: "8px",
											border: "none",
											backgroundColor: "#eabf4e",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											stroke="#ffffff"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
											class="lucide lucide-pause"
										>
											<rect width="4" height="16" x="6" y="4" />
											<rect width="4" height="16" x="14" y="4" />
										</svg>
									</button>
								)}
							</div>
							<div
								className=""
								style={{
									position: "absolute",
									right: "0",
									height: "100%",
									aspectRatio: "1/1",
									top: "-100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									gap: "10px",
									padding: "8px",
								}}
							>
								<p>{startRecorder ? remainingTime : null}</p>
							</div>
						</div>
					</footer>
				</div>
				{/* </Div>
    </Card> */}
			</div>
		)
	);
}

export default ChatbotUi;
