import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, InputAdornment } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormik } from "formik";
import { Typography, Box, Autocomplete } from "@mui/material";
import Div from "@jumbo/shared/Div";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { TextField } from "@mui/material";
import { ClientValidations } from "app/utils/validators";
import { updateCaseId } from "../../redux/actions/dashboard";
import { useDispatch } from "react-redux";
import { getLawyerState } from "app/Apis/User";
import { LoadingButton } from "@mui/lab";
import { getCaseCity } from "app/Apis/User";
import EditOffIcon from '@mui/icons-material/EditOff';
import { getPeopleByNumber, postPeople } from "app/Apis/people";

const RenderAccordian = ({
  count,
  clientNo,
  setClientNo,
  name,
  handleNextButton,
  selectedPeople,
  setSelectedPeople,
  pref,
  newClientsAdded,
  setNewClientsAdded,
  poepleType
}) => {
  console.log(pref);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [FullName, setFullName] = useState(pref?.fullName ? pref?.fullName : null);
  const [email, setEmail] = useState(pref?.emailId ? pref?.emailId : null);
  const [add1, setAdd1] = useState(pref?.addressLine1 ? pref?.addressLine1 : null);
  const [add2, setAdd2] = useState();
  const [city, setCity] = useState(pref?.city ? pref?.city : null);
  const [cityList, setCityList] = useState([]);
  const [State, setState] = useState([]);
  const [states, setStates] = useState(pref?.state ? pref?.state : null);
  const [num, setNum] = React.useState(pref?.mobileNo ? pref?.mobileNo : null);
  const [pin, setPin] = React.useState(pref?.pinCode ? pref?.pinCode : null);
  const dispatch = useDispatch();
  const [documentUploaded, setDocumentUploaded] = useState();
  const [country, setCountry] = useState("INDIA");
  const countries = ["INDIA"];
  const removeDocument = () => {
    setDocumentUploaded(null);
    setFileUploaded(false);
  };

  const hiddenFileInput = React.useRef(null);
  const [expanded, setExpanded] = React.useState(count === 0 ? true : false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);

    setDocumentUploaded(URL.createObjectURL(event.target.files[0]));
  };
  const [lastClientId, setLastClientId] = useState(newClientsAdded);

  useEffect(() => {
   
    const getStates = async () => {
      try {
        const response = await getLawyerState();
        if (!response) {
          console.log("No States Found");
        }
        response.shift();
        setState(response);
        if(pref?.state?.stateId){
          function findStateById(statesArray, targetState) {
            console.log(targetState)
            for (const stateObj of statesArray) {
              if (stateObj.stateId === targetState.stateId) {
                console.log(stateObj)
                return stateObj;
              }
            }
            return targetState;
          }
          setStates(findStateById(response,pref?.state))
        }
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };
    getStates();
  }, []);

  useEffect(() => {
    const getCity = async () => {
      try {
        const response = await getCaseCity(states.stateId);
        if (!response) {
          console.log("No City Found");
        }
        setCityList(response);
        console.log(response);
        if(pref?.city?.districtId){
          function findStateById(cityArray, targetCity) {
            console.log(targetCity)
            for (const cityObj of cityArray) {
              if (cityObj.districtId === targetCity.districtId) {
                console.log(cityObj)
                return cityObj;
              }
            }
            return targetCity;
          }
          setCity(findStateById(response,pref?.city))
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCity();
  }, [states]);

  const formik = useFormik({
    initialValues: {
      FullName: FullName,
      email: email,
      num: num,
      add1: add1,
      add2: add2,
      city: city,
      pin: pin,
      states: states,
      country: country,
    },

    validationSchema: ClientValidations,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (formik.values?.num) {
        const data = await getPeopleByNumber(formik.values?.num);
        console.log(data);
        if (data?.length > 0) {
          formik.setFieldError("num","Mobile Number already exists. Please select another number.");
          console.log(formik.errors)
        } else {
          const newClientId = lastClientId + 1;
          setNewClientsAdded(newClientsAdded + 1);
          const body = {
            fullName: values.FullName ? values.FullName : "",
            emailId: values.email ? values.email : "",
            mobileNo: values.num ? values.num : "",
            add1: values.add1 ? values.add1 : "",
            add2: values.add2 ? values.add2 : "",
            city: values.city ? values.city?.stateId : "",
            pinCode: values.pin ? values.pin : "",
            state: values?.states?.stateId ? values?.states?.stateId : "",
            country: values.country ? values.country : "",
            peopleType: poepleType,
          };
          console.log(values);
          const response = await postPeople(body);
          console.log(response);
          if (response) {
            console.log(response, "added succ!!");
            setSelectedPeople([...selectedPeople, response]);
          } else {
            console.log("error adding");
          }
          console.log([
            ...selectedPeople,
            {
              id: newClientId,
              fullName: FullName,
              mobileNo: num,
              emailId: values.email,
              state: values.states,
              city: values.city,
              country: country,
              addressLine1: add2 ? add1 + add2 : add1,
              pinCode: pin,
            },
          ]);
          setLastClientId(newClientId);
          setClientNo(clientNo - 1);
        }
      }
    },
  });

  return (
    <Accordion
      elevation={0}
      sx={{
        marginBottom: 1,
        width: "100%",
        border: "none",
      }}
      expanded={expanded === true}
      onChange={handleChange(true)}
    >
      <AccordionSummary
        sx={{ height: "50px" }}
        expandIcon={<ExpandMoreIcon />}
        id="panel1bh-header"
      >
        <Typography
          variant="h6"
          sx={{
            width: "90%",
            flexShrink: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {pref?"Edit "+name:"New " + name}
          {
            <p onClick={() => setClientNo(clientNo - 1)}>
              <DeleteOutlineIcon sx={{ fontSize: "24px" }} />
            </p>
          }
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <form>
            {/* <CaseContainer> */}
            <Div
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              {/* first name */}
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label="Full Name*"
                variant="outlined"
                value={formik.values.FullName}
                onChange={(e)=>setFullName(e.target.value)}
                error={formik.errors.FullName && formik.touched.FullName}
                name="FullName"
                color="secondary"
                helperText={formik.errors.FullName}
              />
              <TextField
                sx={{ mb: 0, mt: 2, width: "100%" }}
                id="outlined-basic"
                label="Mobile Number*"
                disabled = { pref?.mobileNo ? true :false}
                variant="outlined"
                value={formik.values.num}
                onChange={(e)=>setNum(e.target.value)}
                error={formik.errors.num && formik.touched.num}
                name="num"
                color="secondary"
                helperText={formik.errors.num}
                inputProps={{
                  maxLength: 10,
                  endAdornment: pref?.mobileNo  && (
                    <InputAdornment position="end">
                      <EditOffIcon/>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{ mb: 0, mt: 2, width: "100%" }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={(e)=>setEmail(e.target.value)}
                error={formik.errors.email && formik.touched.email}
                name="email"
                color="secondary"
                helperText={formik.errors.email && formik.touched.email}
              />

              <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
                Add Address
              </Typography>

              <TextField
                sx={{ mb: 0, mt: 2, width: "100%" }}
                id="outlined-basic"
                label="Address Line 1"
                variant="outlined"
                value={formik.values.add1}
                onChange={(event) => {
                  formik.handleChange(event);
                  setAdd1(event.target.value);
                }}
                error={formik.errors.add1 && formik.touched.add1}
                name="Add1"
                color="secondary"
                helperText={formik.errors.add1 && formik.touched.add1}
              />
              <TextField
                sx={{ mb: 0, mt: 2, width: "100%" }}
                id="outlined-basic"
                label="Address Line 2"
                variant="outlined"
                value={formik.values.add2}
                onChange={(event) => {
                  formik.handleChange(event);
                  setAdd2(event.target.value);
                }}
                error={formik.errors.add2}
                name="Add2"
                color="secondary"
                helperText={formik.errors.add2}
              />

              
              <Autocomplete
                style={{ width: "100%" }}
                options={State}
                isOptionEqualToValue={(option, value) =>
                  option.stateId === value.stateId
                }
                value={states}
                onChange={(event, value) => {
                  setStates(value);
                  formik.setFieldValue("states",value)
                }}
                getOptionLabel={(option) => {
                  return option.stateName;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: 0, mt: 2 }}
                    color="secondary"
                    label="Select State"
                    placeholder="Select State"
                    error={
                      formik.touched.states && Boolean(formik.errors.states)
                    }
                    helperText={formik.touched.states && formik.errors.states}
                  />
                )}
              />
              <Autocomplete
                style={{ width: "100%" }}
                options={cityList}
                value={city}
                isOptionEqualToValue={(option, value) =>
                  option.districtId === value.districtId
                }
                onChange={(event, value) => {
                  setCity(value);
                  formik.setFieldValue("city",value)

                }}
                getOptionLabel={(option) => {
                  return option.districtName;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: 0, mt: 2 }}
                    color="secondary"
                    label="Select City"
                    placeholder="Select City"
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                )}
              />

              <TextField
                sx={{ mb: 0, mt: 2, width: "100%" }}
                id="outlined-basic"
                label="Pin Code"
                variant="outlined"
                value={formik.values.pin}
                onChange={(event) => {
                  formik.handleChange(event);
                  setPin(event.target.value);
                }}
                error={formik.errors.pin && formik.touched.pin}
                name="Pin Code"
                color="secondary"
                inputProps={{
                  maxLength: 6,
                }}
                helperText={formik.errors.pin && formik.touched.pin}
              />
              {/* Country */}
              <Autocomplete
                style={{ width: "100%" }}
                options={countries}
                onChange={(event, value) => {
                  setCountry(value);
                }}
                disabled
                value={country}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: 0, mt: 2 }}
                    color="secondary"
                    label="Select Country"
                    disabled
                    placeholder="Select Country"
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
              />

              
              

              
              <Div
                sx={{
                  mt: 5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {count !== 0 && (
                  <Div
                    onClick={() => setClientNo(clientNo - 1)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                      Delete Client
                    </Typography>
                    <DeleteOutlineIcon sx={{ fontSize: "26px" }} />
                  </Div>
                )}
              </Div>
            </Div>
          </form>
          <Div sx={{display:"flex",justifyContent:"flex-end"}}>
          <LoadingButton
            onClick={formik.handleSubmit}
            type="submit"
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ width: 100, padding: 1 }}
            variant="contained"
          >
            ADD
          </LoadingButton>
          </Div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default RenderAccordian;
