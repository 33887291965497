import {
  ROLE,
  NAME,
  EMAIL,
  MOBILE,
  ACCESSTOKEN,
  PROFILESTATUS,
  PROFILEIMAGE,
  USERNAME,
  EMAILVERIFIED,
  MOBILEVERIFIED,
  LAWYERID,
  USERID
} from "app/utils/constants/LawinzoLogin";

export const defaultRole = (role) => {
  return (dispatch) => {
    dispatch({ type: ROLE, payload: role });
  };
};

export const defaultLawyerId = (lawyerId) => {
  return (dispatch) => {
    dispatch({ type: LAWYERID, payload: lawyerId });
  };
};
export const defaultUserId = (userId) => {
  return (dispatch) => {
    dispatch({ type: USERID, payload: userId });
  };
};

export const defaultAccessToken = (accessToken) => {
  return (dispatch) => {
    dispatch({ type: ACCESSTOKEN, payload: accessToken });
  };
};
export const defaultProfileStatus = (profileStatus) => {
  return (dispatch) => {
    dispatch({ type: PROFILESTATUS, payload: profileStatus });
  };
};

export const DefaultName = (name) => {
  return (dispatch) => {
    dispatch({ type: NAME, payload: name });
  };
};

export const DefaultUserName = (name) => {
  return (dispatch) => {
    dispatch({ type: USERNAME, payload: name });
  };
};

export const DefaultUserEmail = (email) => {
  return (dispatch) => {
    dispatch({ type: EMAIL, payload: email });
  };
};

export const DefaultUserMobile = (mobile) => {
  return (dispatch) => {
    dispatch({ type: MOBILE, payload: mobile });
  };
};

export const DefaultProfileImage = (url) => {
  return (dispatch) => {
    dispatch({ type: PROFILEIMAGE, payload: url });
  };
};

export const DefaultEmailVerified = (condition) => {
  return (dispatch) => {
    dispatch({ type: EMAILVERIFIED, payload: condition });
  };
};

export const DefaultPhoneVerified = (condition) => {
  return (dispatch) => {
    dispatch({ type: MOBILEVERIFIED, payload: condition });
  };
};

// export const onContactDeleteStart = (contact) => {
//     return dispatch => {
//         dispatch({type: CONTACT_DELETE_STARTED, payload: contact});
//     }
// };
