import Div from "@jumbo/shared/Div";
import React from "react";
import { NoCaseContainer } from "app/pages/MyCases/style";
import NoCaseImage from "app/ImageAssets/NoCaseImage.png";
import {
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MobileDateRangePicker } from "@mui/lab";


function NoDataDisplay({text,url,buttonText,minHeight,fromPayment,marginLeft="0rem",formDisable}) {
    const navigate = useNavigate();
  return (
      <NoCaseContainer>
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flex:1,
            minHeight:minHeight,
            marginLeft:marginLeft
          }}
        >
          <Div
            sx={{
              width: { xs: "60%", sm: "30%", md: "150px" },
              height: "30%",
            }}
          >
            <img
              src={NoCaseImage}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              alt=""
            />
          </Div>

          <Typography
            sx={{
              fontSize: { xs: "18px", md: "24px" },
              textAlign: "center",
              mt: 2,
            }}
          >
            {text}
          </Typography>
          <Div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
            }}
          >
         {!formDisable &&  <Button
              variant="contained"
              sx={
                fromPayment? 
                {
                backgroundColor: "secondary.main",
                mb: 2,
                width: "200px",
                
                "&:hover": {
                  color: "white",
                  backgroundColor: "#BFBDC1",
                },
              }
                :
                {
                  backgroundColor: "secondary.main",
                  mb: 2,
                  width: "200px",
                }
            }
              onClick={() =>navigate(url)}
            >
              {buttonText}
            </Button>}
          </Div>
        </Div>
      </NoCaseContainer>
  )
}

export default NoDataDisplay