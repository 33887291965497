import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Link from "@mui/material/Link";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'react-redux';
import {useTheme} from '@mui/material';



const Personal = () => {
    const theme = useTheme()
    const name = useSelector(
        (state) => state?.onboardingInputs?.fullName
      );
    const mobileNo = useSelector(
        (state) => state?.onboardingInputs?.mobileNumber
      );
    const email = useSelector(
        (state) => state?.onboardingInputs?.emailId
      );
    return (
        <JumboCardQuick title={<Typography sx={{fontSize:"1.25rem"}}>Personal Details</Typography>} 
        headerSx={{
            borderBottom: 1, borderColor: 'divider'
        }}
       
        noWrapper>
            <List disablePadding sx={{mb: 2}}>
            <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <PersonIcon
                         sx={{color:theme.palette.primary.main}}/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Full Name</Typography>}
                        secondary={<Typography variant="body1" href="#" underline="none">{name}</Typography>}
                    />
                </ListItem>
               
               
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <LocalPhoneOutlinedIcon
                        sx={{color:theme.palette.primary.main}}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Phone</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">+91-{mobileNo}</Typography>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <EmailOutlinedIcon
                         sx={{color:theme.palette.primary.main}}/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Email</Typography>}
                        secondary={<Link variant="body1" href="#" underline="none">{email}</Link>}
                    />
                </ListItem>
            </List>
        </JumboCardQuick>
    );
};

export default Personal;
