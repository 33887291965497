import React from "react";
import Div from "@jumbo/shared/Div";
import { TextField } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { updateAddressSettings } from "app/Apis/settings";
import AddressMap from "./addressMap";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchPlaces from "./searchPlaces";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CompleteAddressForm from "./completeAddressForm";
import { useTheme } from "@mui/material";
const AddressForm = ({
  showUpdate,
  setShowUpdate,
  handleClose,
  setInitSettings,
  initSettings,
  editId,
  setEditId,
  setIsEditing,
  addressLabel
}) => {
  const [confirm, setConfirm] = useState(false);
  const [Address, setAddress] = useState("Add Address");
  const [AddressObject, setAddressObject] = useState(
    Boolean(editId && initSettings)
      ? initSettings?.addressDetails.filter(
          (address) => address?.addressId === editId
        )
      : null
  );
  const [searchOpen, setSearchOpen] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const theme = useTheme();

  return (
    <Div>
      {searchOpen ? (
        <SearchPlaces
          setAddress={setAddress}
          setSearchOpen={setSearchOpen}
          setMarkerPosition={setMarkerPosition}
        />
      ) : (
        <>
          <Div
            sx={{
              height: Boolean(confirm || editId !== "") ? "225px" : "500px",
            }}
          >
            {/* <GoogleMap /> */}
            <AddressMap markerPosition={markerPosition} />
          </Div>
          {Boolean(confirm || editId !== "") ? (
            <CompleteAddressForm
              // setIsEditing={setIsEditing}
              AddressObject={AddressObject ? AddressObject[0] : null}
              Address={Address}
              setConfirm={setConfirm}
              setSearchOpen={setSearchOpen}
              handleClose={handleClose}
              setInitSettings={setInitSettings}
              initSettings={initSettings}
              editId={editId}
              setEditId={setEditId}
              showUpdate={showUpdate}
              setShowUpdate={setShowUpdate}
              addressLabel = {addressLabel}
            />
          ) : (
            <Div
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "24px 24px 4px 24px",
                width: "100%",
                height: "100%",
              }}
            >
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h3"
                  color="black"
                  sx={{
                    fontSize: "1.875rem",
                    fontWeight: 500,
                    mb: "20px",
                  }}
                >
                  Set your address
                </Typography>
                <CloseIcon
                  sx={{
                    mt: 0.5,
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </Div>
              <Div>
                {/* <Typography
                  sx={{
                    width: "100%",
                    margin: "1.25rem 0px 0.5rem 0px",
                    fontSize: "1.15rem",
                    fontWeight: 600,
                    lineHeight: "150%",
                    textTransform: "uppercase",
                  }}
                >
                  Address
                </Typography> */}
                <Div
                  onClick={() => setSearchOpen(true)}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <TextField
                    value={Address}
                    disabled
                    sx={{
                      width: "100%",
                      // border:"1px solid black",
                      mt: "0.4rem",
                      mb: "0.4rem",
                      // px:"1rem",
                      // py:"0.255rem",
                      fontSize: "1rem",
                      color: "black",
                      borderRadius: "8px",
                      cursor: "pointer",
                      lineHeight: "1.5rem",
                      fontWeight: 700,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <LocationOnOutlinedIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      ),
                      endAdornment: (
                        <LoadingButton
                          variant="outlined"
                          onClick={() => setSearchOpen(true)}
                          sx={{
                            border: "0px",

                            "&:hover": {
                              border: "0px",
                              backgroundColor: "white",
                            },
                          }}
                        >
                          Change
                        </LoadingButton>
                      ),
                      sx: {
                        "& input:hover": {
                          cursor: "pointer",
                        },
                      },
                    }}
                  />
                </Div>
              </Div>
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  type="submit"
                  onClick={(e) => setConfirm(true)}
                  sx={{
                    backgroundColor: "black",
                    width: "45%",
                    color: "white",
                    p: 1,
                    mb: "5px",
                    mt: "5px",
                    fontWeight: 500,
                    fontSize: "1rem",
                    "&:hover": {
                      backgroundColor: "#eabf4e",
                    },
                    "@media (max-width: 990px)": {
                      width: "60%",
                    },
                  }}
                >
                  Confirm Address
                </LoadingButton>
              </Div>
            </Div>
          )}
        </>
      )}
    </Div>
  );
};

export default AddressForm;
