import UnauthorisedAccessPage from 'app/pages/404';
import { useLocation,Navigate,Outlet } from 'react-router-dom';
import Page from "@jumbo/shared/Page";
import Cookies from 'js-cookie';
import useLogin from 'app/hooks/useLogin';


const RequireAuth = ({allowedRoles})=>{
    const login = useLogin(true);
    
    const accessToken  = Cookies.get('accessToken');
    const assignedRole = Cookies.get("assignedRole");
    const isLoggedIn = Cookies.get("isLoggedIn");
    console.log(assignedRole);
    console.log(allowedRoles);
    const location = useLocation();
    login(accessToken);
    if(!isLoggedIn){
        return <Navigate to="/" state={{from: location}} replace/>
    }
   const allowed = allowedRoles.find((role)=>role===assignedRole)
    if(!allowed){
        return <Page component={UnauthorisedAccessPage} />
    }
    return (
        <Outlet/>
    )
}
export default RequireAuth;