import { getAssetPath } from "../../utils/appHelpers";
import { ASSET_AVATARS } from "../../utils/constants/paths";

export const documentData = [
  {
    id: 1,
    documentName: "Document Name1",
    documentType: "FIR",
    caseType: "Case 1",
    fileName: "document Name.pdf",
    LawyerName: "User 1",
    clientName: "User 2",

    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
  {
    id: 2,
    documentName: "Document Name1",
    documentType: "FIR",
    caseType: "Case 1",
    fileName: "document Name.pdf",
    LawyerName: "User 1",
    clientName: "User 2",

    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
  },
  {
    id: 3,
    documentName: "Document Name1",
    documentType: "FIR",
    caseType: "Case 1",
    fileName: "document Name.pdf",
    LawyerName: "User 1",
    clientName: "User 2",

    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
  },
  {
    id: 4,
    documentName: "Document Name1",
    documentType: "FIR",
    caseType: "Case 1",
    fileName: "document Name.pdf",
    LawyerName: "User 1",
    clientName: "User 2",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar6.jpg`, "40x40"),
  },
  {
    id: 5,
    documentName: "Document Name1",
    documentType: "FIR",
    caseType: "Case 1",
    fileName: "document Name.pdf",
    LawyerName: "User 1",
    clientName: "User 2",

    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar9.jpg`, "40x40"),
  },
  {
    id: 5,
    documentName: "Document Name1",
    documentType: "FIR",
    caseType: "Case 1",
    fileName: "document Name.pdf",
    LawyerName: "User 1",
    clientName: "User 2",
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
  },
];
