import React from 'react'
import { Breadcrumbs,Typography,Link } from '@mui/material'
import HomeIcon from "@mui/icons-material/Home";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
function BreadCrumbs() {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
       <Link
            href="/home"
            underline="none"
          >
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Home
            </Typography>
    </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
              &nbsp;Tasks
            </Typography>
          </Breadcrumbs>
  )
};

export default BreadCrumbs;