import React, { useEffect, useState } from "react";

import { Alert, Snackbar, Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import RenderClient from "./RenderClient";
import { caseClient } from "app/Apis/case";
import { useSelector ,useDispatch} from "react-redux";
import { updateCaseClients } from "app/redux/actions/dashboard";
import { updateCaseListItem } from "app/redux/actions/case";

function CorporateClient({ handleBackButton, handleNextButton, setTeam,setIsLoading }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const clientsFromRedux = useSelector((state)=>state?.dashboard?.clients);
  const [addClient, setAddClient] = useState(false);
  const [count, setCount] = useState(1);
  const [addClientClick, setAddClientClick] = useState(0);

  const [addClientError, setAddClientError] = useState();
  const [error, setError] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState(clientsFromRedux);
  const [showData, setShowData] = useState(false);
  const caseId = useSelector((state) => state?.dashboard?.caseDetails?._id);
  const dispatch = useDispatch();

  const AddClient = () => {
    setAddClientError(false);
    setCount((state) => state + 1);
    setAddClientClick((state) => state + 1);
  };

  console.log(count);
  const UpdateCaseListItem = (value) => {
    dispatch(updateCaseListItem(value));
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      if (selectedPeople.length > 0) {
        const response = await caseClient(caseId, selectedPeople);
        if (response) {
          console.log(response);
          UpdateCaseListItem(response)
          dispatch(updateCaseClients(response.clients));
        
        } 
        
      }
      setIsLoading(false) 
      handleNextButton();

    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },
          height: "100%",
        }}
      >
        <RenderClient
          addClient={addClient}
          setAddClient={setAddClient}
          showData={showData}
          setShowData={setShowData}
          setTeam={setTeam}
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
          selectedPeople={selectedPeople}
          setSelectedPeople={setSelectedPeople}
          clientNo={count}
          setClientNo={setCount}
          name={"Corporate Client"}
          setError={setError}
          poepleType={"Corporate_Client"}
        />

        <Div
          sx={{
            width: "100%",
            pl: 2,
            pr: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            variant="contained"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={handleSubmit}
            color="secondary"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default CorporateClient;
