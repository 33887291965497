import React, { useEffect, useState } from "react";
import {
  Typography,
  Link,
  Card,
  Breadcrumbs,
  Button,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import HomeIcon from "@mui/icons-material/Home";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import CardFooter from "../components/CardFooter";
import { getEquiryList } from "app/Apis/Enquiry";
import NoEnquiry from "./NoEnquiry";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useNavigate } from "react-router-dom";
import EnquiryList from "./EnquiryList";
import TeamSkeletons from "../Teams/TeamSkeletons";


const optionsPageSize = ["5", "10", "20", "25", "50"];

function Enquiry() {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState("5");
  const [enquiryList, setEnquiryList] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [showUpdate, setShowUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const list = async () => {
      console.log(pageNumber);
      const response = await getEquiryList(pageNumber, pageSize);
      if (response) {
        console.log(response);
        setEnquiryList(response?.data?.enquiry);
        setTotalPages(response?.data?.totalPages);
      }
    };
    setIsLoading(true)
    list();
    setIsLoading(false)
  }, [pageNumber, showUpdate]);
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/home" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <LiveHelpIcon sx={{ fontSize: 20 }} />
          &nbsp;Enquiry
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <Div sx={{ display: "flex", alignItems: "center" }}>
                <Div
                  style={{
                    height: "19px",
                    width: "5px",
                    backgroundColor: "#EABF4E",
                    marginRight: "8px",
                  }}
                ></Div>
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    lineHeight: 1.5,
                    fontWeight: 400,
                  }}
                >
                  All Enquiries
                </Typography>
              </Div>
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={() => {
                  navigate("/enquiry?isLogin=true");
                }}
              >
                Add Enquiry
              </Button>
            </Div>
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Div>
                <Select
                  labelId="select-label"
                  value={pageSize}
                  sx={{ maxHeight: "40px", mr: "10px" }}
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  {optionsPageSize.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Div>
              <Div sx={{ flex: 1 }}>
                <Button
                  style={{ fontWeight: "600", minWidth: "100%" }}
                  startIcon={<FilterAltOutlinedIcon />}
                  color="secondary"
                  size="small"
                >
                  Filter By
                </Button>
              </Div>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10 }}>
          {isLoading ? 
            Array.from(Array(parseInt(pageSize))).map((item,index)=>{
            return <TeamSkeletons key={index} />
              }) : (enquiryList?.length > 0 ? (
                <EnquiryList
                  enquiryList={enquiryList}
                  showUpdate={showUpdate}
                  setShowUpdate={setShowUpdate}
                />
              ) : (
                <NoEnquiry />
              ))
              
              }
          </Div>
          <Div
            sx={{
              position: "absolute",
              bottom: 20,
              right: { sm: 0, lg: 25 },
            }}
          >
            {enquiryList && (
              <CardFooter
                setPageNumber={setPageNumber}
                totalPages={totalPages}
              />
            )}
          </Div>
        </Div>
      </Card>
    </>
  );
}

export default Enquiry;
