import {
  Typography,
  Radio,
  FormControlLabel,
  Box,
  Card,
  Divider,
  TextField,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import Bank from "../userProfile/components/Bank/bank";
import UPI from "../userProfile/components/Bank/upi";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import useSettings from "app/hooks/useSettings";
import Cookies from "js-cookie";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useNavigate } from "react-router-dom";
const caseOption = ["Case1", "case2", "case3"];
const options = ["Case", "Hearing", "Others"];

const PaymentDetails = ({
  setFormOpen,
  handleBackButton,
  handleNextButton,
}) => {
  const accessToken = Cookies.get("accessToken");
  const lawyerId = Cookies.get("lawyerId");
  const [basicOption, setBasicOption] = useState();
  const [setSnackBarMessage, setOpenSnackBar] = useState();
  const [cases, setCases] = useState();
  const [comingSoon]=useState(true);
  const getSetting = useSettings();
  const navigate = useNavigate();
  const [initSettings, setInitSettings] = useState(null);
  // const [purpose, setPurpose] = useState()
  const isBelow990px = useMediaQuery((theme) => theme.breakpoints.down(990));
  const [selectedOption, setSelectedOption] = useState("UPI ID");
  const theme = useTheme();
  useEffect(() => {
    const fetchSettings = async () => {
      console.log("lawyerId", lawyerId);
      if (lawyerId && accessToken) {
        const response = await getSetting(lawyerId, accessToken);

        if (response) {
          setInitSettings(response);
          console.log(response);
        }
      }
    };
    fetchSettings();
  }, []);
  const formik = useFormik({
    initialValues: {},

    onSubmit: (values) => {
      console.log(values, "FORMIK");
      // handleNextButton();
      handleNextButton();
    },
  });
  console.log(basicOption);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  if (comingSoon) {
    return (
      <Div sx={{display:"flex",flexDirection:"column",gap:1,alignItems:"center",justifyContent:"center",width:"100%"}}>
        <Typography
          variant="h1"
          sx={{
            fontSize: "4rem",
            fontWeight: "700",
            color: "#EABF4E",
          }}
        >
          Coming Soon
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            color: "black",
          }}
        >
          We are about to launch this feature very soon
        </Typography>
      </Div>
    );
  }

  return (
    <>
      <React.Fragment>
        <Div
          sx={{
            width: "100%",
          }}
        ></Div>

        <Card
          sx={{
            p: 3,
            position: "relative",
            height: "100%",
          }}
        >
          <Div
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              ></Div>
            </Div>
            {/* <Divider sx={{ mt: 2 }} /> */}
            <Div sx={{ mb: 10 }}>
              <Div
                sx={{
                  display: "block",
                  marginLeft: isBelow990px ? "2%" : "30%",
                }}
              >
                <Div></Div>
                <TextField
                  sx={{ mb: 0, mt: 2, width: isBelow990px ? "100%" : "60%" }}
                  color="secondary"
                  label="Amount"
                  placeholder="Amount"
                />

                <Autocomplete
                  value={basicOption}
                  name="teamLawyer"
                  options={options}
                  // disableCloseOnSelect
                  onChange={(e, v) => setBasicOption(v)}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option}</li>
                  )}
                  sx={{ width: isBelow990px ? "100%" : "60%" }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ mb: 0, mt: 2 }}
                      {...params}
                      label="Select Purpose"
                      placeholder="Select Purpose"
                      color="secondary"
                      // error={caseError}
                    />
                  )}
                />

                {basicOption === "Case" ? (
                  <Autocomplete
                    value={cases}
                    name="teamLawyer"
                    options={caseOption}
                    // disableCloseOnSelect
                    onChange={(e, v) => {
                      setCases(v);
                    }}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option}</li>
                    )}
                    sx={{ width: isBelow990px ? "100%" : "60%" }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ mb: 0, mt: 2 }}
                        {...params}
                        label="Select Case"
                        placeholder="Select Case"
                        color="secondary"
                        // error={caseError}
                      />
                    )}
                  />
                ) : (
                  <>
                    {basicOption === "Hearing" ? (
                      <>
                        <Autocomplete
                          value={cases}
                          name="teamLawyer"
                          options={caseOption}
                          // disableCloseOnSelect
                          onChange={(e, v) => {
                            setCases(v);
                          }}
                          getOptionLabel={(option) => option}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>{option}</li>
                          )}
                          sx={{ width: isBelow990px ? "100%" : "60%" }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ mb: 0, mt: 2 }}
                              {...params}
                              label="Select Case"
                              placeholder="Select Case"
                              color="secondary"
                              // error={caseError}
                            />
                          )}
                        />

                        <Autocomplete
                          value={cases}
                          name="teamLawyer"
                          options={caseOption}
                          // disableCloseOnSelect
                          onChange={(e, v) => {
                            setCases(v);
                          }}
                          getOptionLabel={(option) => option}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>{option}</li>
                          )}
                          sx={{ width: isBelow990px ? "100%" : "60%" }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ mb: 0, mt: 2 }}
                              {...params}
                              label="Select Hearing"
                              placeholder="Select Haering"
                              color="secondary"
                              // error={caseError}
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        {basicOption === "Others" ? (
                          <TextField
                            sx={{
                              mb: 0,
                              mt: 2,
                              width: isBelow990px ? "100%" : "60%",
                            }}
                            color="secondary"
                            label="Enter Purpose"
                            placeholder="Enter Purpose"
                          />
                        ) : null}{" "}
                      </>
                    )}
                  </>
                )}
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  <Div>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedOption === "UPI ID"}
                          onChange={handleOptionChange}
                          value="UPI ID"
                          sx={{
                            color: theme.palette.secondary.main,
                            "&.Mui-checked": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        />
                      }
                      label="UPI Id"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedOption === "Bank Account"}
                          onChange={handleOptionChange}
                          value="Bank Account"
                          sx={{
                            color: theme.palette.secondary.main,
                            "&.Mui-checked": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Bank account
                        </Box>
                      }
                    />
                  </Div>
                  <Div>
                    <Button
                      color="secondary"
                      onClick={() => navigate("/lawyers/paymentHistory")}
                    >
                      <ReceiptIcon sx={{ mx: 1 }} /> Payment History
                    </Button>
                  </Div>
                </Div>
                {selectedOption === "Bank Account" && (
                  <Bank
                    setOpenSnackBar={setOpenSnackBar}
                    setInitSettings={setInitSettings}
                    initSettings={initSettings}
                    setFormOpen={setFormOpen}
                    setSnackBarMessage={setSnackBarMessage}
                  />
                )}
                {selectedOption === "UPI ID" && (
                  <UPI
                    setOpenSnackBar={setOpenSnackBar}
                    setInitSettings={setInitSettings}
                    initSettings={initSettings}
                    setFormOpen={setFormOpen}
                    setSnackBarMessage={setSnackBarMessage}
                  />
                )}
              </Div>
            </Div>
          </Div>
          <Div
            sx={{
              // width: "100%",

              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              mt: 0,
            }}
          >
            <LoadingButton
              onClick={handleBackButton}
              // disabled={values !== 0 ? false : true}
              variant="contained"
              sx={{ width: 100, padding: 1 }}
            >
              Back
            </LoadingButton>
            <LoadingButton
              onClick={formik.handleSubmit}
              color="secondary"
              style={{
                backgroundColor: "black",
              }}
              sx={{ width: 150, padding: 1 }}
              variant="contained"
            >
              Send Payment
            </LoadingButton>
          </Div>
        </Card>
      </React.Fragment>
    </>
  );
};

export default PaymentDetails;
