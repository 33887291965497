import React from "react";
import {
  Card,
  Typography,
  CardHeader,
  Link,
  CardContent,
  Chip,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { formatDate } from "app/utils/dateTimeHelpers";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const DraftCard = ({ item, setEdit, setEditItem }) => {
  console.log(item);
  return (
    <Card sx={{ boxShadow: "none", backgroundColor: "#f9f8f9" }}>
      <CardHeader
        sx={{ p: 2 }}
        action={
          <Chip
            label={
              item?.createdDate
                ? `${formatDate(item?.createdDate)}`
                : "Hearing Date"
            }
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        }
        title={
          <Chip
            label={item?.langId}
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        }
      />
      <CardContent
        sx={{ p: 2, pt: 0, display: "flex", flexDirection: "column", gap: 1 }}
      >
        {item?.draftName && (
        <Div sx={{
          width:"100%",
          display:"flex",
          justifyContent:"space-between"
        }}>
          <Typography sx={{ fontSize: "19.2px" }}>
            {item.draftName}
            {item?.googleDocCreated === 1 && item.isUpdate === 0 && (
              <Link
                sx={{
                  marginLeft: 2,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEdit(true);
                  setEditItem(item);
                }}
              >
                <EditOutlinedIcon sx={{ fontSize: "19.2px" }} />
              </Link>
            )}
          </Typography>
          <Typography sx={{ fontSize: "14.2px" }}>
            {item.documentType}
          </Typography>
        </Div>
        )}
        <Typography variant={"body1"} color={"text.secondary"}>
          {item?.caseDetails &&
            `${item?.caseDetails?.caseFirstName} Vs ${item?.caseDetails?.caseSecondName}`}
        </Typography>
        <Div
          sx={{
            backgroundColor: "#FFFAEA",
            mx: "-16px",
            mb: "-24px",
            display: "flex",
            p: 2,
            pt: 1,
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Div>
            {item?.googleDocCreated === 1 ? (
              item.isUpdate === 0 ? (
                <Link href={item?.googleDocLink} target="_blank" sx={{ justifySelf: "end"}}>
                  <Chip
                    label={"View Document"}
                    icon={<ArticleOutlinedIcon color="secondary" />}
                    color="secondary"
                    size="medium"
                    variant="outlined"
                    sx={{
                      fontSize: 14,
                      fontWeight: "700",
                      ":hover": { cursor: "pointer" },
                    }}
                  />{" "}
                </Link>
              ) : (
                <Chip
                  label={"Draft Status: Updating"}
                  icon={<LoopOutlinedIcon color="secondary" />}
                  color="secondary"
                  size="medium"
                  variant="outlined"
                  sx={{ fontSize: 14, fontWeight: "700" }}
                />
              )
            ) : (
              <Chip
                label={"Draft Status: Processing"}
                icon={<LoopOutlinedIcon color="secondary" />}
                color="secondary"
                size="medium"
                variant="outlined"
                sx={{ fontSize: 14, fontWeight: "700" }}
              />
            )}
          </Div>
        </Div>
      </CardContent>
    </Card>
  );
};
