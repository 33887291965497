import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import EditorSelect from "./EditorSelect";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const EditorPopup = ({
	convertAndDownload,
	open,
	setOpen,
	apiDataCases,
	setResponseData,
	responseData,
	Loading,
}) => {
	// const [open, setOpen] = React.useState(false);
	const [Case, setCase] = React.useState("");
	// const handleOpen = () => {
	// 	setOpen(true);
	// };
	const DocName = React.useRef(null);
	function handleResponseData() {
		const response = {
			caseId: Case,
			fileName: DocName.current.value,
		};

		setResponseData(response);
		if (responseData) {
			convertAndDownload();
		}

		setOpen(false);
	}
	const handleClose = () => setOpen(false);
	// console.log(apiDataCases.cases);
	return (
		<JumboDemoCard title={"Transitions"}>
			<Div>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={open}>
						<Div sx={style}>
							{/* <Typography
								id="transition-modal-title"
								variant="h4"
								component="h2"
							>
								Text in a modal
							</Typography> */}
							<Typography id="transition-modal-description">
								{/* <form action="" onSubmit={handleResponseData}> */}
								<label style={{ fontWeight: "bolder" }}>Document Name</label>
								<input
									type="text"
									style={{
										marginTop: "3px",
										marginBottom: "10px",
										width: "100%",
										padding: "8px 12px",
										border: "2px solid gray",
										borderRadius: "5px",
									}}
									ref={DocName}
								/>
								<label style={{ fontWeight: "bolder" }}>Select Case</label>
								<EditorSelect
									CaseData={Case}
									setCaseData={setCase}
									apiDataCases={apiDataCases}
								/>
								<button
									style={{
										width: "100%",
										marginTop: "25px",
										padding: "8px 0",
										background: "none",
										border: "2px solid black",
										borderRadius: "5px",
										cursor: "pointer",
									}}
									disabled={Loading}
									onClick={handleResponseData}
								>
									Submit
								</button>
								{/* </form> */}
							</Typography>
						</Div>
					</Fade>
				</Modal>
			</Div>
		</JumboDemoCard>
	);
};
export default EditorPopup;
