import React from "react";
import {
  Alert,
  alpha,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Span from "@jumbo/shared/Span";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useState } from "react";
import CardDetailModal from "./CardDetailModal";

const PropertyItem = ({
  item,
  handleScreen,
  openSnackBar,
  setOpenSnackBar,
}) => {
  const { theme } = useJumboTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleModalClose = (value) => {
    setOpen(false);
    console.log(open);
  };

  const [open, setOpen] = React.useState(false);

  return (
    <ListItemButton
      disableRipple
      alignItems="flex-start"
      sx={{
        position: "relative",
        zIndex: 1,
        justifyContent: "space-between",
        p: 3,
        transition: "all 0.2s",
        borderBottom: 1,
        borderBottomColor: "divider",
        alignItems: "center",

        [theme.breakpoints.down("md")]: {
          //  flexWrap: "wrap",
        },
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-4px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      {/* each card details modal */}
      {open && (
        <CardDetailModal
          item={item}
          open={open}
          setOpen={setOpen}
          handleModalClose={handleModalClose}
          handleScreen={handleScreen}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          // handleSnackbarClose={handleSnackbarClose}
        />
      )}

      <Div
        sx={{
          width: "100vw",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
        onClick={() => setOpen(true)}
      >
        <ListItemAvatar
          sx={{
            width: 60,
            mt: 0,
            mr: 3,
            // display: "flex",
            // justifyContent: "center",

            [theme.breakpoints.down("md")]: {
              width: 60,
              mr: 0,
              mb: 3,
            },
          }}
        >
          <Avatar
            src={item.profilePic}
            variant={"rounded"}
            key={item.id}
            sx={{
              borderRadius: "100%",
              width: "100%",
              height: 60,
              [theme.breakpoints.down("md")]: {
                height: 60,
              },
            }}
          />
        </ListItemAvatar>
        <ListItemText>
          <Typography component={"div"}>
            <Typography variant={"h5"} mb={1.25}>
              {item.documentName}
            </Typography>
            <Typography variant={"h5"} mb={1.25}>
              {item.documentType}
            </Typography>
            {/* <Typography variant={"h6"} color={"text.primary"} mb={0.5}>
              {item.address}
              {item.caseName}
            </Typography> */}
            <Div
              sx={{
                display: "flex",

                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                },
              }}
            >
              {/* <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                Bedrooms:{" "}
                Lawyer Name :
                <Span sx={{ color: "text.primary", mr: 1 }}>
                  {item.LawyerName}
                </Span>
              </Typography> */}
              {/* <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                Client Name :
                <Span sx={{ color: "text.primary", mr: 1 }}>
                  {item.clientName}
                </Span>
              </Typography> */}
            </Div>
            <Stack
              color={"text.secondary"}
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              <Span
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 13,
                  mt: 2,
                }}
              >
                <PictureAsPdfIcon sx={{ fontSize: 16, mr: 0.2 }} />
                {item.fileName}
              </Span>
            </Stack>
          </Typography>
        </ListItemText>
      </Div>
    </ListItemButton>
  );
};
/* Todo properties item prop */
export default PropertyItem;
