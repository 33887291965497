import React from "react";
import Div from "@jumbo/shared/Div";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

// import DisplayDocuments from "../DisplayDocuments/DisplayDocuments";
import DocumentModal from "./DocumentModal";
import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DisplayDocumentsAPI from "../DisplayDocuments/DisplayDocumentsAPI";

function AddDocuments({
	documentsArray,
	setDocumentsArray,
	documentsAPIList,
	setDocumentsAPIList,
	type,
	fromEnquiry,
	formDisable,
}) {
	const fileInput = React.useRef(null);
	const [openModal, setOpenModal] = useState(false);
	const [documentSaveAPI, setDocumentSaveAPI] = useState(false);

	const isMobile = useMediaQuery("(max-width: 990px)");

	const handleOpen = () => {
		setOpenModal(true);
	};
	const handleClose = () => {
		setOpenModal(false);

		setDocumentsArray(null);
	};
	const handleClick = () => {
		fileInput.current.click();
	};

	useEffect(() => {
		console.log(documentsArray, documentsAPIList, documentSaveAPI);
	}, [documentsArray, documentsAPIList, documentSaveAPI]);

	const handleFileInputChange = (e) => {
		console.log(documentsArray);
		console.log(e.target.files);
		// debugger;
		if (documentsArray?.length > 0) {
			const array = [...e.target.files, ...documentsArray];
			console.log(array);
			setDocumentsArray([...array]);
		} else {
			console.log(e.target.files);
			const array = [...e.target.files];
			console.log(array);
			setDocumentsArray([...array]);
			handleOpen();
		}
	};
	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		// setOpenSnackBar(false);
	};
	return (
		<Div
			sx={{
				width: "100%",
				border: "1px dotted #d3d3d3",
				overflowY: "auto",
				mt: 2,
				p: 2,
			}}
		>
			<input
				style={{ marginTop: "10px", width: "30vw" }}
				accept={
					type === "drafting"
						? ".jpg,.jpeg,.png,.pdf,.doc,.docx"
						: "image/*,application/pdf,.doc,.docx"
				}
				multiple={type === "drafting" ? false : true}
				type="file"
				ref={fileInput}
				onChange={handleFileInputChange}
				hidden
			/>

			{!(documentsAPIList && documentSaveAPI) && (
				<UploadFileIcon sx={{ fontSize: "40px" }} onClick={handleClick} />
			)}
			{documentsAPIList && documentSaveAPI && (
				<DisplayDocumentsAPI
					handleClick={handleClick}
					documentsAPIList={documentsAPIList}
					setDocumentsAPIList={setDocumentsAPIList}
				/>
			)}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={openModal}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={openModal}>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: { xs: "93%", lg: "600px" },
							height: { xs: "85%", lg: "600px" },
							bgcolor: "background.paper",
							border: "1px solid #000",
							borderRadius: "8px",
							boxShadow: 24,
							px: 4,
							py: 1,
						}}
					>
						<span
							style={{
								position: " absolute",
								right: " -8px",
								top: "-8px",
								cursor: "pointer",
								borderRadius: "50%",
								height: "22px",
								width: "22px",
								display: "inline-flex",
								backgroundColor: "rgb(232 232 232)",
								fontSize: "22px",
							}}
						>
							<CancelTwoToneIcon
								fontSize="inherit"
								onClick={handleClose}
								variant
							/>
						</span>
						<Div sx={{ width: "100%", height: "100%" }}>
							<DocumentModal
								handleClose={handleClose}
								handleClick={handleClick}
								documentsArray={documentsArray}
								setDocumentsArray={setDocumentsArray}
								setDocumentSaveAPI={setDocumentSaveAPI}
								documentsAPIList={documentsAPIList}
								setDocumentsAPIList={setDocumentsAPIList}
								type={type}
								fromEnquiry={fromEnquiry}
							/>
						</Div>
					</Box>
				</Fade>
			</Modal>
		</Div>
	);
}

export default AddDocuments;
