import { ASSET_IMAGES } from "app/utils/constants/paths";
import React from "react";
import "./chatbot.css";
import PersonIcon from "@mui/icons-material/Person";

function ChatbotUiBlocks({ type, content, isResponseLoaded, user }) {
	if (type === "chatbotLoading") {
		return (
			<div
				className=""
				style={{
					padding: "1.5rem 2.5rem",
					borderBottom: ".5px solid rgba(130, 132, 153, 1)",
					backgroundColor: "rgba(67, 70, 84, 1)",
				}}
			>
				<div
					className=""
					style={{
						width: "100%",
						maxWidth: "800px",
						margin: "0  auto",
						display: "flex",
						alignItems: "center",
						gap: "40px",
					}}
				>
					<div
						className=""
						style={{
							minWidth: "2.5rem",
							width: "2.5rem",
							aspectRatio: "1/1",
							borderRadius: "5px",
							backgroundColor: "#eabf4e",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img
							src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
							style={{ width: "100%" }}
							alt=""
							srcset=""
						/>
					</div>
					<h3
						style={{
							margin: "0",
							fontWeight: "normal",
							paddingTop: ".3rem",
						}}
					>
						{!isResponseLoaded && <div className="dot-pulse"></div>}
					</h3>
				</div>
			</div>
		);
	}
	if (type === "user") {
		return (
			<div
				className=""
				style={{
					padding: "1.5rem 2.5rem",
					display: "flex",
					maxWidth: "800px",
					margin: "0 auto",
					justifyContent: "flex-end",
				}}
			>
				<div
					className=""
					style={{
						display: "flex",
						alignItems: "flex-start",
						gap: "20px",
					}}
				>
					<h3
						style={{
							margin: "0",
							fontWeight: "normal",
							paddingTop: ".3rem",
						}}
					>
						{content}
					</h3>
					<div
						className=""
						style={{
							minWidth: "2.5rem",
							width: "2.5rem",
							aspectRatio: "1/1",
							borderRadius: "5px",
							backgroundColor: "#eabf4e",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							backgroundImage: `url(${user.data.imageUrl})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}
					>
						{!user.data.imageUrl && <PersonIcon />}
					</div>
				</div>
			</div>
		);
	}
	if (type === "chatbot") {
		return (
			<div
				className=""
				style={{
					padding: "1.5rem 2.5rem",
					borderBottom: ".5px solid rgba(130, 132, 153, 1)",
					backgroundColor: "rgba(67, 70, 84, 1)",
				}}
			>
				<div
					className=""
					style={{
						width: "100%",
						maxWidth: "800px",
						margin: "0  auto",
						display: "flex",
						alignItems: "flex-start",
						gap: "20px",
					}}
				>
					<div
						className=""
						style={{
							minWidth: "2.5rem",
							width: "2.5rem",
							aspectRatio: "1/1",
							borderRadius: "5px",
							backgroundColor: "#eabf4e",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img
							src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
							style={{ width: "100%" }}
							alt=""
							srcset=""
						/>
					</div>
					<h3
						style={{
							margin: "0",
							fontWeight: "normal",
							paddingTop: ".3rem",
						}}
					>
						{content}
					</h3>
				</div>
			</div>
		);
	}
}

export default ChatbotUiBlocks;
