import React from "react";
import {
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
const HearingDashCard = ({ item, hearingList }) => {
  function formatDate(dateString) {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString();
    return `${day} ${month} ${year}`;
  }
  console.log(item);
  return (
    <ListItemButton
      alignItems={"flex-start"}
      // key={task.id}
      sx={{
        px: 3,
        m: 3,
        borderRadius: "8px",
        backgroundColor:"white",
        "&:hover .update-task": {
          opacity: 1,
          color: "grey.700",
          backgroundColor: "common.white",
          transform: "translateY(-50%) scale(1)",
        },
      }}
    >
      <ListItemText
        primary={
          <Typography component={"div"}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Typography mt={1} variant={"h5"} mb={0.8}>
                  Case Name
                </Typography>

                <Typography variant={"body1"} color={"text.secondary"} ml={0.5}>
                  {"Supreme Court Of India"}
                </Typography>
                {/* <Typography variant={"body1"} color={"text.secondary"} ml={0.5}>
                  {"Respondent"}
                </Typography> */}
                {/* <Chip
                  label={"Respondent"}
                  sx={{
                    color: "#F57202",
                    backgroundColor: "rgb(245,114,2,16%)",
                    fontSize: "15.6px",
                    fontWeight: "semi-bold",
                  }}
                /> */}
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
        
                  
                }}
              >
                <Typography mt={1} variant={"h5"} mb={0.8}>
                  {item.hearingDate
                    ? formatDate(item?.hearingDate)
                    : "No Date Added"}
                </Typography>
                {/* <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <EventIcon style={{ marginRight: 8 , marginTop:"1px" , color:"#EABF4E"}} />
                  <Typography variant={"body1"} color={"text.secondary"} fontSize={"18px"}>
                    Next hearing Date
                  </Typography>
                </div>

                <Typography variant={"body1"} color={"text.primary"} marginRight={"40px"}>
                  {item.hearingDate
                    ? formatDate(item?.hearingDate)
                    : "No Date Added"}
                </Typography>
                
                <div>
                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AutoStoriesIcon style={{ marginRight: 8 , marginTop:"10px" , color:"#EABF4E"}} />
                  <Typography variant={"body1"} color={"text.secondary"} fontSize={"15px"} marginTop={"10px"} marginRight={"70px"}>
                    Case Stage
                  </Typography>
                </div>

                <Typography variant={"body1"} color={"text.primary"} marginLeft={"32px"} >
                 {"Stage 1"}
                </Typography>
                </div> */}


              </div>
            </div>
          </Typography>
        }
      />
    </ListItemButton>
  );
};
/* Todo item prop define */
export default HearingDashCard;
