import React, { useEffect } from 'react'
import { useState } from "react";
import Div from '@jumbo/shared/Div/Div'
import { ArrowForwardIos, CurrencyRupee, KeyboardArrowRight, NorthEast, SouthWest, SouthWestOutlined } from '@mui/icons-material';
import { Alert, Button, Snackbar, Typography } from '@mui/material';
import { AccountBalanceWallet } from '@material-ui/icons';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useFormik } from 'formik';
import { PaymentsWithdraw } from 'app/Apis/Payment';
import { PaymentWithdrawValidations } from 'app/utils/validators';
import Cookies from 'js-cookie';
import usePayments from 'app/hooks/usePayments';
import { getPaymentLists } from 'app/Apis/Payment';

const Withdraw = () => {
const accessToken = Cookies.get("accessToken");
const [paymentsReport, setPaymentsReport] = useState(null);
const getPaymentsReports = usePayments();
const [error,setErrors]=useState("")
    const navigate = useNavigate();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleSnackbarClose = (event, reason) => {
        setOpenSnackBar(false);
    };
    useEffect(()=>{
        const getPaymentReport = async () => {
            const response = await getPaymentsReports(accessToken);
            if (response) {
              console.log(response);
              setPaymentsReport(response);
            } else {
              console.log("error getting payment reports");
            }
          };
          getPaymentReport()
    },[])

    const [caseLawyer, setCaseLawyer] = useState([]);

    const handleWithdrawalHistory = () => {
        navigate('/lawyers/withdrawal/history')
    };
    const formik = useFormik({
        initialValues: {
            amount: "",
            totalBalance:paymentsReport?.[2],
        },
        enableReinitialize: true,
        validationSchema:PaymentWithdrawValidations,
        onSubmit: async (values) => {
            console.log(values);
            if(values.amount>values.totalBalance){
                setErrors("Amount should be smaller or equal to Total Balance")
            }else{
                try {
                    const response = await PaymentsWithdraw({
                        amount: values.amount,
                    });
                    if (response.status===200) {
                        setErrors(false)
                        setOpenSnackBar(true)
                    }
                } catch (err) {
                    console.log(err);
                }
            }
           
        },
    });

    return (
        <JumboCardQuick
            sx={{ height: "35rem", }}
            wrapperSx={{ p: 0 }}
            // sx={{p:"-24px"}}
            title={
                <Typography sx={{ fontSize: "1.25rem" }}>Withdraw</Typography>
            }
            headerSx={{
                borderBottom: 1,
                borderColor: "divider",
            }}
        // sx={{ mb: 2.75 }}
        >
            <Div >
                {openSnackBar && (
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={openSnackBar}
                        autoHideDuration={2000}
                        onClose={handleSnackbarClose}
                        sx={{ zIndex: 1000, mt: 10 }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity="success"
                            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
                        >
                            <Typography variant="h6">
                                We received your request, this will take up to 24 hours for transfer in your account.
                            </Typography>
                        </Alert>
                    </Snackbar>
                )}
                <Div sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }} >
                    <Div sx={{ backgroundColor: "#FFFAEA", width: "100%", height: "150px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }} >
                        <Div sx={{ mt: 1 }} >
                            <Typography variant='h6' >Vakily Available Balance</Typography>
                        </Div>
                        <Div sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                            <Div sx={{ display: "flex", alignItems: "center" }} >
                                <AccountBalanceWallet sx={{ fonrSize: 20, color: "black" }} />
                            </Div>
                            <Div sx={{ m: 2, width: "1px", backgroundColor: "black", height: "35px" }} >
                                      
                            </Div>
                            <Div>
                                <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }} variant='h6' >
                                {formik.values.totalBalance}
                                </Typography>
                            </Div>
                        </Div>
                        {/* <Div sx={{display: "flex", flexDirection: "column", alignItems: "center", m:1}} >
                <Div>
                    <Typography sx={{color: "green", fontWeight: 700}} variant='h6' >
                        <CurrencyRupee sx={{fontSize: 14, color: "green"}} />
                        3000
                    </Typography>
                </Div>
                <Div sx={{display: "flex", flexDirection: "row"}} >
                        <SouthWest sx={{color: "green"}} />
                    <Typography>
                         Received Amount
                    </Typography>
                </Div>
            </Div>
                <Div sx={{m:2, width: "1px", backgroundColor: "black", height: "50px" }} >

                </Div>
            <Div sx={{display: "flex", flexDirection: "column", alignItems: "center", m:1}} >
                <Div>
                    <Typography  sx={{color: "Red", fontWeight: 700}} variant='h6' >
                    <CurrencyRupee sx={{fontSize: 14, color: "Red"}} />
                        10000
                    </Typography>
                </Div>
                <Div sx={{display: "flex", flexDirection: "row"}} >
                    <NorthEast sx={{color: "red"}} />
                    <Typography>
                         Invoice Raised
                    </Typography>
                </Div>
            </Div> */}
                    </Div>
                </Div>
                {/* enter amount */}
                <Div sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }} >
                    <Div sx={{ backgroundColor: "white", mt: 2, width: "100%", height: "200px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }} >
                        <Div sx={{ mb: 2, width: "50%" }} >
                            <TextField
                                sx={{ mb: 0, mt: 2, width: "100%", }}
                                //   id="outlined-multiline-static"
                                //   label="Enter Amount*"
                                color="secondary"
                                //   variant="outlined"
                                placeholder='Enter amount here'
                                required
                                //   value={formik.values.caseLawyer}
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                name="amount"
                                id="amount"
                                error={formik.errors.amount && formik.touched.amount}
                                helperText={formik.errors.amount}
                                InputProps={{
                                    inputProps: {
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CurrencyRupeeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                             {error && formik.values.amount &&<Typography sx={{color:"red",fontSize:"15px"}} variant="h6">
                               {error}
                            </Typography>}

                            {/* {formik.errors.caseLawyer && (
  <Typography
    sx={{ fontSize: "10px", color: "#E73145", ml: 1.8, mt: 0.3 }}
  >
    Please enter amount
  </Typography>
)} */}
                            {/* We received your request, this will take up to 24 hours for transfer in your account. */}
                        </Div>
                        <Div sx={{ display: "flex", flexDirection: "row", cursor: 'pointer' }} >
                            {/* <Typography onClick={handleWithdrawalHistory} sx={{color: "#EABF4E" }} >Withdrawal History</Typography> */}

                            {/* <ArrowForwardIos sx={{fontSize: 10}} /> */}
                            {/* <KeyboardArrowRight sx={{color: "#EABF4E" }} /> */}
                        </Div>
                        <Div
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                mt: 4,
                            }}
                        >
                            <Button
                             onClick={formik.handleSubmit}
                             disabled={formik.values.totalBalance<=0}
                                variant="contained"
                                sx={{
                                    backgroundColor: "secondary.main",
                                    mb: 2,
                                    width: "200px",

                                    "&:hover": {
                                        color: "white",
                                        backgroundColor: "black",
                                    },
                                }
                                }
                                
                            >
                                Withdraw
                            </Button>
                        </Div>
                        {/* <Div sx={{display: "flex", flexDirection: "column", alignItems: "center", m:1}} >
                <Div>
                    <Typography sx={{color: "green", fontWeight: 700}} variant='h6' >
                        <CurrencyRupee sx={{fontSize: 14, color: "green"}} />
                        3000
                    </Typography>
                </Div>
                <Div sx={{display: "flex", flexDirection: "row"}} >
                        <SouthWest sx={{color: "green"}} />
                    <Typography>
                         Received Amount
                    </Typography>
                </Div>
            </Div>
                <Div sx={{m:2, width: "1px", backgroundColor: "black", height: "50px" }} >

                </Div>
            <Div sx={{display: "flex", flexDirection: "column", alignItems: "center", m:1}} >
                <Div>
                    <Typography  sx={{color: "Red", fontWeight: 700}} variant='h6' >
                    <CurrencyRupee sx={{fontSize: 14, color: "Red"}} />
                        10000
                    </Typography>
                </Div>
                <Div sx={{display: "flex", flexDirection: "row"}} >
                    <NorthEast sx={{color: "red"}} />
                    <Typography>
                         Invoice Raised
                    </Typography>
                </Div>
            </Div> */}
                    </Div>
                </Div>
                <Div sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }} >
                    <Div sx={{ backgroundColor: "white", mt: 4, width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start" }} >
                        <Div>
                            {/* <Typography sx={{fontWeight: 700}} variant='h6' >Send Payment</Typography> */}
                        </Div>
                        <Div>
                            {/* wallet */}
                        </Div>
                        {/* <Div sx={{display: "flex", flexDirection: "column", alignItems: "center", m:1}} >
                <Div>
                    <Typography sx={{color: "green", fontWeight: 700}} variant='h6' >
                        <CurrencyRupee sx={{fontSize: 14, color: "green"}} />
                        3000
                    </Typography>
                </Div>
                <Div sx={{display: "flex", flexDirection: "row"}} >
                        <SouthWest sx={{color: "green"}} />
                    <Typography>
                         Received Amount
                    </Typography>
                </Div>
            </Div>
                <Div sx={{m:2, width: "1px", backgroundColor: "black", height: "50px" }} >

                </Div>
            <Div sx={{display: "flex", flexDirection: "column", alignItems: "center", m:1}} >
                <Div>
                    <Typography  sx={{color: "Red", fontWeight: 700}} variant='h6' >
                    <CurrencyRupee sx={{fontSize: 14, color: "Red"}} />
                        10000
                    </Typography>
                </Div>
                <Div sx={{display: "flex", flexDirection: "row"}} >
                    <NorthEast sx={{color: "red"}} />
                    <Typography>
                         Invoice Raised
                    </Typography>
                </Div>
            </Div> */}
                    </Div>
                </Div>
            </Div>
        </JumboCardQuick>
    )
}

export default Withdraw
