import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import AddressForm from "./addressForm";
import AddAddressChip from "./addAddressChip";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  height: "743px",
  // maxHeight: "743px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 12,
  borderRadius: "8px",
  overflow: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1)",
  // Add custom scrollbar styles
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    width: "0", // Hide the scrollbar by setting its width to zero
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
  },
};

const AddAddress = ({
  setInitSettings,
  initSettings,
  handleClose,
  handleOpen,
  open,
  editId,
  setEditId,
  showUpdate,
  setShowUpdate,
  addressLabel
  // setIsEditing
}) => {
  console.log(addressLabel)
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <AddressForm
            // setIsEditing={setIsEditing}
              handleClose={handleClose}
              initSettings={initSettings}
              setInitSettings={setInitSettings}
              editId={editId}
              setEditId={setEditId}
              showUpdate={showUpdate}
              setShowUpdate={setShowUpdate}
              addressLabel={addressLabel}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddAddress;
