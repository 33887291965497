import React from "react";
import { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import useSettings from "app/hooks/useSettings";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { updateBankSettings } from "app/Apis/settings";
import { useSelector } from "react-redux";
import { UPIValidations } from "../../ValidationsProfile";
import {Alert, Snackbar,Typography} from "@mui/material";

function UPI({setOpenSnackBar,setSnackBarMessage,initSettings,setInitSettings,setFormOpen}) {
  console.log(initSettings);
  const lawyerId = useSelector((state)=>{return state?.onboardingInputs?.lawyerId});
  const accessToken = useSelector((state)=>{return state?.onboardingInputs?.accessToken});
  const [setting, setSetting] = useState({
    upiId: "",
  });
  // const [initSettings, setInitSettings] = useState({});

  const handleSubmit = async () => {
    console.log(formik.values);
    console.log(initSettings);
    console.log(lawyerId);
    console.log(accessToken);
    const response = await updateBankSettings(
      initSettings,
      {...formik.values,isActive:1},
      lawyerId,
      accessToken
    );
    console.log(response);
    setInitSettings(response);
    setFormOpen(false);
    setOpenSnackBar(true)
    setSnackBarMessage("UPI Added Successfully!")
  };




  const formik = useFormik({
    initialValues: {
      upiId: setting.upiId || "",
    },
    enableReinitialize: true,
    validationSchema: UPIValidations,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    console.log(setting);
  }, [setting]);

  const fieldMargin = {
    marginTop: "2%",
  };

  return (
    
    <Div>
     
 
      <form>
        
        <TextField
          style={{
            ...fieldMargin,
            width: "60%",
            backgroundColor: "white",
          }}
          name={"upiId"}
          label="UPI Id"
          color="secondary"
          inputProps={{ maxLength: 150 }}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.upiId}
          error={formik.errors.upiId && formik.touched.upiId}
          helperText={formik.touched.upiId ? formik.errors.upiId : ""}
        />
       
      </form>
      <LoadingButton
          type="submit"
          onClick={formik.handleSubmit}
          color="secondary"
        
          sx={{
            width: "200px",
            padding: 1,
            mt: "20px",
            mb: "10px",
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "#eabf4e",
            },
          }}
          variant="contained"
        >
        Add UPI ID
      </LoadingButton>
    </Div>
  );
}

export default UPI;