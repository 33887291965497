import * as React from "react";
import { useState } from "react";
import { Alert, Autocomplete, Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import { paymentRequest } from "app/Apis/Payment";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";



import { useNavigate } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsIcon from "@mui/icons-material/Sms";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import InputAdornment from "@mui/material/InputAdornment";

const payment_type_options = [
  {
    label:"Daily Cases Fee",
    value:"DAILY_CASES_FEE"
  },
  {
    label:"Bail Filing Fee",
    value:"BAIL_FILING_FEE"
  },
  {
    label:"Appointment Fee",
    value:"APPOINTMENT_FEE"
  },
  {
    label:"Enquiry Fee",
    value:"ENQUIRY_FEE"
  },
  {
    label:"Other Court Fee",
    value:"OTHER_COURT_FEE"
  },
  {
    label:"Certified Copy Fee",
    value:"CERTIFIED_COPY_FEE"
  },
];

const Hearings = ({ caseName, clients ,caseId}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const Validation = Yup.object().shape({
    amount:Yup.string().required("Please Enter Amount").nullable(),
    type:Yup.object().required("Select Payment Type").nullable(),
    paymentDescription:Yup.string(),
  });

  const handleSnackbarClose=()=>{
    setOpenSnackBar(false)
    navigate(`/lawyers/casedetail?caseId=${caseId}&value=3`);
  }
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      amount: "",
      userId: "",
      payment_type: "DAILY_CASES_FEE",
      paymentDescription: "",
      type: null,
      client: clients ? clients[0] : null,
    },
    enableReinitialize: true,
    validationSchema:Validation,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await paymentRequest({
          amount: values.amount,
          userId: values?.client.peopleId,
          payment_type: values?.type?.value,
          payment_mode: "ONLINE",
          paymentDescription: values?.paymentDescription,
          requester: "cloud",
          caseId
        });
        console.log("req sent", response);
        if(response){
          setOpenSnackBar(true)
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleCancel = () => {
    navigate(`/lawyers/casedetail?caseId=${caseId}&value=3`);
  };

  return (
    <Div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "16px",
      }}
    >
        {openSnackBar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackBar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            sx={{ zIndex: 1000, mt: 10 }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
            >
              <Typography variant="h6">Payment Request send Successfully</Typography>
            </Alert>
          </Snackbar>
        )}

      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        <Div>
          <Typography variant="h6" sx={{ mt: 1.8 }}>
            Payment-Raise Invoice
          </Typography>

          <TextField
            sx={{ mb: 0, mt: 2, width: "100%" }}
            id="outlined-multiline-static"
            color="secondary"
            placeholder="Case"
            variant="outlined"
            value={caseName}
            disabled
          />

          <Autocomplete
            style={{ width: "100%" }}
            value={formik.values.client}
            onChange={(event, value) => formik.setFieldValue("client", value)}
            options={clients}
            isOptionEqualToValue={(o, v) => o.peopleId === v.peopleId}
            getOptionLabel={(option) => option.fullName}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.fullName}</li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "16px" }}
                {...params}
                label="Client*"
                placeholder="Client"
                color="secondary"
                name="type"
              />
            )}
          />

          <TextField
            sx={{ mb: 0, mt: 2, width: "100%" }}
            id="outlined-multiline-static"
            label="Enter Amount*"
            color="secondary"
            variant="outlined"
            value={formik.values.amount}
            onChange={formik.handleChange}
            error = {formik.errors.amount && formik.touched.amount}
            helperText = {formik.errors.amount && formik.touched.amount ? formik.errors.amount:""}
            name="amount"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon />
                </InputAdornment>
              ),
            }}
          />


          <Autocomplete
            style={{ width: "100%" }}
            value={formik.values.type}
            onChange={(event, value) => formik.setFieldValue("type",value)}
            options={payment_type_options}
            // disableCloseOnSelect
            isOptionEqualToValue={(o,v)=>o.label===v.label}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.label}</li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "16px" }}
                {...params}
                label="Payment Type*"
                placeholder="Select Payment Type"
                color="secondary"
                // error={caseError}
                error={
                  formik.errors.type && formik.touched.type
                }
                helperText = {formik.errors.type && formik.touched.type ? formik.errors.type:""}

                name="type"
              />
            )}
          />
        </Div>

        <Div>
          <TextField
            sx={{ mb: 0, mt: 2, width: "100%" }}
            id="outlined-basic"
            label="Write Payment Notes..."
            variant="outlined"
            multiline
            rows={4}
            value={formik.values.paymentDescription}
            name="paymentDescription"
            color="secondary"
            onChange={formik.handleChange}
            helperText = {formik.errors.amount && formik.touched.amount ? formik.errors.amount:""}
            error={formik.errors.amount && formik.touched.amount}
          />
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleCancel}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            style={{
              backgroundColor: "black",
            }}
            sx={{ padding: 1 }}
            variant="contained"
          >
            Send Invoice
          </LoadingButton>
        </Div>
      </Div>
    </Div>
  );
};

export default Hearings;
