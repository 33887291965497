import React, { useEffect, useState } from "react";
import { ImageList, ImageListItem, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Div from "@jumbo/shared/Div";
// import { ASSET_IMAGES } from "../../../utils/constants/paths";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginFooter from "app/pages/components/LoginFooter";
import PersonIcon from "@mui/icons-material/Person";
import InputAdornment from "@mui/material/InputAdornment";
// import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import SwipeableTextMobileStepper from "../components/SwipeableTextMobileStepper";
import { useNavigate } from "react-router-dom";
import { Image } from "@mui/icons-material";
import appStore from "app/ImageAssets/appStore.png"
import playStore from "app/ImageAssets/playStore.png"

const ClientScreen = () => {
    const isBelow990px = useMediaQuery((theme) => theme.breakpoints.down(990));
    const nav=useNavigate()
    const handleDownload=()=>{
       window.open("https://play.google.com/store/apps/details?id=com.lawinzo.android")
       console.log("Download Now")
    }
    return (
        <>
            <Div
              sx={{
                backgroundColor: "#FFFFFF",
                margin: 0,
                display: "flex",
                flex: "1 1",
                minWidth: 0,
                flexDirection: { xs: "column", md: "row" },
                height: "100%",
                borderRadius: "0",
              }}
            >
              <Div
                sx={{
                  flex: 1,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Div
                  sx={{
                    flex: "1",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    my: "0",
                    marginLeft: "auto",
                    marginBottom: "0rem",
                    marginRight: isBelow990px ? "auto" : "15%",
                    width: "380px",
                    padding: "20px",
                  }}
                >
                  <Div
                    sx={{
                      display:"flex",
                      mb: 1,
                    }}
                  >
                    <Link href="https://vakily.app">
                      <img
                        src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
                        style={{
                          objectFit: "contain",
                          width: "74.33px",
                          // width: "74.33px",
                        }}
                        alt="Vakily"
                      />
                    </Link>
                  </Div>
                  <Typography
                    variant={"h2"}
                    sx={{
                      color: "#364a63",
                      fontSize: "2rem",
                      fontWeight: "700",
                      fontFamily: "Nunito, sans-serif",
                      mb: 2,
                    }}
                  >
                    Vakily Mobile App
                  </Typography>
                  <form  autoComplete="off">
                    {/* <Div sx={{ mb: 4, color: "#3c4d62", fontWeight: "400" ,}}>
                    </Div> */}
                
    
                    <Div sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Div sx={{ display: "flex" }}>
                      </Div>
                    </Div>
                    {/* <Link  href="https://lawinzo.com"  sx={{
                        mb: 4,
                        backgroundColor: "#EABF4E",
                        position: "relative",
                        letterSpacing: "0.02em",
                        alignItems: "center",
                        fontFamily: "Nunito, sans-serif",
                        fontWeight: "900",
                      }}> DownLoad Now</Link> */}
                   
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      disableElevation
                      sx={{
                        mb: 4,
                        backgroundColor: "#EABF4E",
                        position: "relative",
                        letterSpacing: "0.02em",
                        alignItems: "center",
                        fontFamily: "Nunito, sans-serif",
                        fontWeight: "900",
                      }}
                      onClick={handleDownload}

                    >
                      DownLoad Now
                    </LoadingButton>
                  </form>  
                  <Div sx={{width:"340px",display:"flex"}}>
                  <img onClick={handleDownload} alt="playstore" style={{maxWidth:"170px", maxHeight:"100px",cursor:"pointer"}} src={`${ASSET_IMAGES}/playStore.png`} />
                  <img  alt="appStore" style={{maxWidth:"170px", maxHeight:"55px",marginTop:"3px"}}  src={`${ASSET_IMAGES}/appStore.png`} />  
                  </Div>
                
                </Div>
            
              </Div>
            
                <Div
                  sx={{
                    display: { xs: 'none', sm: 'flex' },
                    height: "100%",
                    backgroundColor: "#f5f6fa",
                    width: "53%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    padding: "2%",
                    textAlign: "center",
                  }}
                >
        <ImageList sx={{display: { xs: 'none', sm: 'block' }}}>
        <ImageListItem>
          <img
            src={`${ASSET_IMAGES}/lawinzo-hero.png`}
            loading="lazy"
            width="462px"
            height="385px" 
          />
        </ImageListItem>
    </ImageList>
                  {/* <Image  src={`${ASSET_IMAGES}/lawinzo-hero.png`} /> */}
                  {/* <img  style={ { width: "462px",
                 height: "385px",display:{sm:"none"}}} src={`${ASSET_IMAGES}/lawinzo-hero.png`} /> */}
                  {/* <SwipeableTextMobileStepper /> */}
                </Div>
                
            </Div>
        </>
      );
}

export default ClientScreen