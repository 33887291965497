import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "@emotion/styled";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import NonActiveLawyerCard from "./NonActiveLawyerCard";
import Cookies from "js-cookie";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  textAlign:"left",
  backgroundColor:"#fff"
}));

const NonActiveLawyersList = ({ list }) => {
  const assignedRole = Cookies.get("assignedRole");
  return (
    // <JumboScrollbar
    //   autoHeight={true}
    //   autoHideTimeout={4000}
    //   autoHeightMin={350}
    //   autoHide={true}
    //   hideTracksWhenNotNeeded
    // >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell
              sx={{
                pl: 2,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "27%",
              }}
            >
              Lawyer Name
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "27%",
              }}
            >
              Specialisation
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "27%",
              }}
            >
              Practice Court
            </StyledTableCell>
            {assignedRole==="ROLE_ADMIN" && (
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "9%",
              }}
            >
              Contact
            </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {list &&
            list.map((lawyer, index) => (
              <NonActiveLawyerCard
                list={list}
                item={lawyer}
                key={index}
                index={index}
              />
            ))}
        </TableBody>
      </Table>
    // </JumboScrollbar>
  );
};

export default NonActiveLawyersList;
