import * as React from "react";
import { useState, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import { getHearingPurpose } from "app/Apis/case";
import AddDocuments from "app/Components/Documents/AddDocuments/AddDocuments";
import EditDocuments from "app/Components/Documents/EditDocuments/EditDocuments";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { CaseHearingValidations } from "app/utils/validators";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
	updateCaseResponse,
	updateHearingDate,
	updateHearingDescription,
	updateHearingDocumentLists,
	updateHearingPurpose,
	updateNextHearingDate,
} from "../../redux/actions/dashboard";
import { updateCaseListItem } from "app/redux/actions/case";
const teamLawyerList = ["Ashish", "Rahul", "Ram", "Rocky"];

const Hearing = ({ handleBackButton, handleNextButton, setIsLoading }) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const userDetailsFromRedux = useSelector((state) => state.onboardingInputs);
	const [teamLayer, setTeamLawyer] = useState();
	const documentListsFromRedux = useSelector(
		(state) => state.dashboard.hearingDetails.documentLists
	);
	const hearingDetailsFromRedux = useSelector(
		(state) => state.dashboard.hearingDetails
	);
	const eCourtDetailsFromRedux = useSelector(
		(state) => state.dashboard.ECourtDetails
	);

	const caseResponse = useSelector((state) => state.dashboard.caseResponse);

	const dispatch = useDispatch();

	const lawyer = {
		fullName: userDetailsFromRedux.fullName,
		lawyerId: userDetailsFromRedux.lawyerId,
		accessToken: userDetailsFromRedux.accessToken,
	};

	const UpdateCaseListItem = (value) => {
		dispatch(updateCaseListItem(value));
	};

	const HearingDescription = (value) => {
		dispatch(updateHearingDescription(value));
		console.log(value);
	};
	const HearingDate = (value) => {
		dispatch(updateHearingDate(value));
		console.log(value);
	};
	const HearingDocumentLists = (value) => {
		dispatch(updateHearingDocumentLists(value));
		console.log(value);
	};
	const HearingPurpose = (value) => {
		dispatch(updateHearingPurpose(value));
		console.log(value);
	};
	const CaseResponse = (value) => {
		dispatch(updateCaseResponse(value));
		console.log(value);
	};

	useEffect(() => {
		const fetchHearingPurpose = async () => {
			try {
				const hearingOptions = await getHearingPurpose(lawyer.accessToken);
				console.log("Response from getCaseId:", hearingOptions);
				setPurpose(hearingOptions?.data?.mPurposeHearings);
				console.log("User profile data fetched:", hearingOptions);
			} catch (error) {
				console.error("Error fetching user profile:", error);
			}
		};

		fetchHearingPurpose();
	}, []);
	const NextHearingDate = (value) => {
		dispatch(updateNextHearingDate(value));
		console.log(value);
	};
	const HearingDetails = async (values, documentLists, caseResponse) => {
		NextHearingDate(values.hearingDate);
		setIsLoading(true);
		console.log(values, documentLists);
		const payload = {
			caseId: caseResponse.caseId,
			hearingDetails: [
				{
					caseId: caseResponse.caseId,
					lawyer: { id: lawyer.lawyerId },
					documentLists: documentLists,
					hearingDate: values.hearingDate,
					hearingDescription: values.hearingDescription,
					hearingNotes: values?.hearingPurpose?.purposeHearing,
					isActive: 1,
				},
			],
		};
		fetch("https://erp.lawinzo.com/api/v1/cases?pageNo=6", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
				Authorization: `Bearer ${lawyer.accessToken}`,
			},

			body: JSON.stringify(payload),
		})
			.then((response) => response.json())
			.then((data) => {
				// Handle the response data
				CaseResponse(data);
				UpdateCaseListItem(data);
				setIsLoading(false);
				console.log(data);
			})
			.catch((error) => {
				// Handle any errors
				setIsLoading(false);
				console.error("Error:", error);
			});
	};

	const [hearingPurpose, setHearingPurpose] = useState(
		hearingDetailsFromRedux.hearingPurpose
			? hearingDetailsFromRedux.hearingPurpose
			: null
	);
	const [hearingDate, setHearingDate] = useState(
		hearingDetailsFromRedux.hearingDate
			? hearingDetailsFromRedux.hearingDate
			: eCourtDetailsFromRedux.nextHearingDate
			? eCourtDetailsFromRedux.nextHearingDate
			: null
	);
	const [hearingDescription, setHearingDescription] = useState(
		hearingDetailsFromRedux.hearingDescription
	);
	const [purpose, setPurpose] = useState([]);
	const [documentsArray, setDocumentsArray] = React.useState(null);
	const [documentsAPIList, setDocumentsAPIList] = React.useState(
		documentListsFromRedux ? documentListsFromRedux : null
	);

	const formik = useFormik({
		initialValues: {
			teamLayer: teamLayer,
			hearingPurpose: hearingPurpose,
			hearingDate: hearingDate,
			hearingDescription: hearingDescription,
		},
		validationSchema: CaseHearingValidations,
		enableReinitialize: true,
		onSubmit: (values) => {
			if (Object.values(values).filter((e) => e === null).length >= 2) {
				return handleNextButton();
			}
			if (values.hearingDate) {
				CaseHearingValidations.fields.hearingPurpose = Yup.object().required();
			}

			HearingDescription(hearingDescription);
			HearingDocumentLists(documentsAPIList);
			HearingPurpose(hearingPurpose);
			HearingDate(hearingDate);
			HearingDetails(values, documentsAPIList, caseResponse);
			handleNextButton();
			// ECourtDetails(values,caseResponse);
		},
	});

	return (
		<Div
			style={{
				height: "100%",
				width: "100%",
				paddingBottom: "30px",
			}}
		>
			{/* <CaseContainer> */}
			<Div
				sx={{
					marginLeft: { xs: "5%", md: "18%" },
					marginRight: { xs: "5%", md: "18%" },
					marginTop: "20px",
					height: "100%",
				}}
			>
				{/* <Hearings
       inCase = {true}
       /> */}
				<div
					style={{
						height: "100%",
						width: "100%",
						paddingBottom: "30px",
					}}
				>
					{/* <CaseContainer> */}
					<Div
						sx={{
							marginTop: "20px",
							height: "100%",
						}}
					>
						<Autocomplete
							style={{ width: "100%" }}
							value={formik.values.hearingPurpose}
							onChange={(e, v) => {
								setHearingPurpose(v);
							}}
							isOptionEqualToValue={(option, value) =>
								option.purposeId === value.purposeId
							}
							name="caseStage"
							options={purpose}
							// disableCloseOnSelect
							getOptionLabel={(option) => option?.purposeHearing}
							renderOption={(props, option, { selected }) => (
								<li {...props}>{option?.purposeHearing}</li>
							)}
							sx={{ width: 500, marginBottom: 2 }}
							renderInput={(params) => (
								<TextField
									sx={{ marginTop: "16px" }}
									{...params}
									label="Hearing Purpose"
									placeholder="Select Hearing Purpose"
									color="secondary"
									// error={caseError}
									error={
										formik.errors.hearingPurpose &&
										formik.touched.hearingPurpose
									}
									helperText={formik.errors.hearingPurpose}
									name="hearingPurpose"
								/>
							)}
						/>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack spacing={3}>
								<DatePicker
									label="Hearing Date"
									format="DD/MM/YYYY"
									value={formik.values.hearingDate}
									onChange={(newValue) => setHearingDate(newValue)}
									renderInput={(params) => (
										<TextField
											{...params}
											//  error={formik.errors.FillingDate}
											name="hearingDate"
											color="secondary"
											// helperText={formik.errors.FillingDate}
											sx={{ width: "100%", mt: 2 }}
										/>
									)}
								/>
							</Stack>
						</LocalizationProvider>
						{formik.errors.hearingDate && (
							<Typography
								sx={{ fontSize: "10px", color: "#E73145", ml: 1.8, mt: 0.3 }}
							>
								Please choose a date!
							</Typography>
						)}
						{/* <Autocomplete
            style={{ width: "100%" }}
            value={formik.values.teamLawyer}
            name="teamLawyer"
            options={teamLawyerList}
            // disableCloseOnSelect
            onChange={(e, v) => {
              setTeamLawyer(v)
            }}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option}</li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ mb: 0, mt: 2 }}
                {...params}
                label="Select Lawyer"
                placeholder="Select Lawyer"
                color="secondary"
              // error={caseError}
              />
            )}
          /> */}
						<TextField
							sx={{ mb: 0, mt: 2, width: "100%" }}
							id="outlined-multiline-static"
							label="Write Hearing Description..."
							multiline
							color="secondary"
							rows={4}
							onChange={(e) => {
								setHearingDescription(e.target.value);
							}}
							value={formik.values.hearingDescription}
							error={
								formik.touched.hearingDescription &&
								Boolean(formik.errors.hearingDescription)
							}
							name="hearingDescription"
							helperText={formik.errors.hearingDescription}
						/>
						<Div>
							<Typography variant="h6" sx={{ mt: "8px" }}>
								{documentListsFromRedux?.length > 0
									? "Documents"
									: "Upload Documents"}
							</Typography>
							{documentListsFromRedux?.length > 0 ? (
								<EditDocuments
									documentsArray={documentsArray}
									setDocumentsArray={setDocumentsArray}
									documentsAPIList={documentsAPIList}
									setDocumentsAPIList={setDocumentsAPIList}
								/>
							) : (
								<AddDocuments
									documentsArray={documentsArray}
									setDocumentsArray={setDocumentsArray}
									documentsAPIList={documentsAPIList}
									setDocumentsAPIList={setDocumentsAPIList}
									type={"tasks"}
								/>
							)}
						</Div>
					</Div>
				</div>
				<Div
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 5,
					}}
				>
					<LoadingButton
						onClick={handleBackButton}
						//   disabled={values !== 0 ? false : true}
						variant="contained"
						sx={{
							width: 100,
							padding: 1,
							backgroundColor: "black",
							"&:hover": {
								backgroundColor: "#eabf4e",
								color: "black",
							},
						}}
					>
						Back
					</LoadingButton>
					<LoadingButton
						onClick={() => {
							formik.handleSubmit();
							console.log(formik.values);
							console.log(formik.errors);
						}}
						color="secondary"
						sx={{
							width: 100,
							padding: 1,
							backgroundColor: "black",
							"&:hover": {
								backgroundColor: "#eabf4e",
								color: "black",
							},
						}}
						variant="contained"
					>
						Continue
					</LoadingButton>
				</Div>
			</Div>
		</Div>
	);
};

export default Hearing;
