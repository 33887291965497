import Div from "@jumbo/shared/Div";
import { Breadcrumbs, Button, Card, Divider, FormControl, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";

import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CardFooter from "../components/CardFooter";
import TeamSkeletons from "../Teams/TeamSkeletons";
import HomeIcon from "@mui/icons-material/Home";
import { CalendarMonthOutlined } from "@mui/icons-material";
import HearingList from "./HearingList";
import { Select, MenuItem,Link } from "@mui/material";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const optionsPageSize = ["5", "10", "20", "25", "50"];
const CaseListLayout = ({
  setAddHearing,
  hearingList,
  setIsEditing,
  setHearingList,
  totalPages,
  setPageNumber,
  setHearingEdit,
  pageSize,
  setPageSize,
  showUpdate,
  setShowUpdate,
  isLoading,
  optionsFilter,
  startDate,
  endDate,
  basicFilter,
  setBasicFilter,
  setStartDate,
  setEndDate,
  getAllHearings
}) => {
  const [values,setValues]=useState(0)
  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      <Link
            href="/home"
            underline="none"
          >
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
          Home
        </Typography>
</Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <WorkOutlineIcon sx={{ fontSize: 20 }} />
          &nbsp;Hearings
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position:"relative",
          height:"95%"

        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              mt:-1
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <Div sx={{ display: {sm: "flex", xs:'none'}, alignItems: "center" }}>
                <Div
                  style={{
                    height: "19px",
                    width: "5px",
                    backgroundColor: "#EABF4E",
                    marginRight: "8px",
                  }}
                ></Div>
                <Typography
                  sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 400 }}
                >
                  All Hearings
                </Typography>
              </Div>
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={() => setAddHearing(true)}
              >
                Add Hearing
              </Button>
            </Div>
            {/* <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            // borderBottom: 1,
            // borderColor: "divider",

            //maxWidth: { xs: 300, sm: 600, md: 700, lg: 800 },
            //   overflowX: "scroll",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={values}
            textColor="secondary"
            indicatorColor="secondary"
            sx={{mb:0}}

          >
            <Tab
              onClick={() => setValues(0)}
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
                mx:5,
              }}
              label="My Hearings"
            />
            <Tab
              onClick={() => setValues(1)}
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
              }}
              label="Team Hearings"
            />
          </Tabs>
        </Div> */}
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
          <Div sx={{display:"flex",gap:1,alignItems:"center",paddingTop:"6px"}}>
        <Typography sx={{fontWeight:"bold",display:{xs:"none",sm:"block"}}}>Filter By</Typography>
        <FormControl >
          <Select
            labelId="select-label"
            value={basicFilter}
            onChange={(e) => setBasicFilter(e.target.value)}
            sx={{ backgroundColor: "white" ,minWidth:"100px",mb:1}}
          >
            {optionsFilter.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {basicFilter === "Choose Date" &&
              <>  
   <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label="Start Date"
        value={startDate}
        onChange={(newValue) => setStartDate(newValue)}
        maxDate={endDate}
        format="DD/MM/YYYY"
        sx={{backgroundColor: "white",width:"9rem",mb:1}}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          format="DD/MM/YYYY"
          onChange={(newValue) => setEndDate(newValue)}
          minDate={startDate}
          sx={{  backgroundColor: "white" ,width:"9rem",mb:1}}
        />
    </LocalizationProvider>

              {/* <BasicDatePicker  setFromDate={setFromDate}  setToDate={setToDate} /> */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "secondary.main",
                    mb: 1,
                    width: "20px",
                    height:"50px",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "black",
                    },
                  }

                  }
                  onClick={()=>getAllHearings()}
                >
                  <ManageSearchIcon sx={{ fontSize: 34 }} />
                </Button>

              </>}
      </Div>
              <Div>
                <Select
                  labelId="select-label"
                  value={pageSize}
                  sx={{backgroundColor: "white",ml:2}}
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  {optionsPageSize.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Div>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10,  
          height:"100%"
        }}>
            {isLoading ? 
            Array.from(Array(parseInt(pageSize))).map((item,index)=>{
            return <TeamSkeletons key={index} />
              }) :
             (
              hearingList?.length > 0 ?
              <HearingList
                setIsEditing={setIsEditing}
                setHearingEdit={setHearingEdit}
                setAddHearing={setAddHearing}
                hearingList={hearingList?.sort((a, b) => new Date(a.hearingDate) - new Date(b.hearingDate))}
                setHearingList={setHearingList}
                showUpdate={showUpdate}
                setShowUpdate={setShowUpdate}
              /> :
              <Div
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                width: { xs: "60%", sm: "30%", md: "150px" },
                height: "30%",
              }}
            >
              <img
                src={NoCaseImage}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                alt=""
              />
            </Div>

            <Typography
              sx={{
                fontSize: { xs: "18px", md: "24px" },
                textAlign: "center",
                mt: 2,
              }}
            >
              You haven't added any Records yet!
            </Typography>
            <Div
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 4,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "secondary.main",
                  mb: 2,
                  width: "150px",
                }}
                onClick={() => setAddHearing(true)}
              >
                Add Hearing
              </Button>
            </Div>
          </Div>

            )
            }

          </Div>
          <Div
            sx={{ position: "absolute", bottom: 20, right: { sm: 0, lg: 25 } }}
          >
            <CardFooter totalPages={totalPages} setPageNumber={setPageNumber} />
          </Div>
        </Div>
      </Card>
    </React.Fragment>
  );
};

export default CaseListLayout;
