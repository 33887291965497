import {
  UPDATE_FILLING_NUMBER,
  UPDATE_REGISTRATION_NUMBER,
  UPDATE_CNR_NUMBER,
  UPDATE_FILLING_DATE,
  UPDATE_REGISTRATION_DATE,
  UPDATE_LAST_HEARING_DATE,
  UPDATE_NEXT_HEARING_DATE,
  UPDATE_CASE_TYPE,
  UPDATE_CASE_ID,
  UPDATE_CASE_NUMBER,
  UPDATE_GR_NO,
  UPDATE_FIR_NO,
  UPDATE_COURT,
  UPDATE_ASSIGN_LAWYER,
  UPDATE_CASE_DESCRIPTION,
  UPDATE_UNDER_SECTION,
  UPDATE_CASE_FIRST_NAME,
  UPDATE_CASE_SECOND_NAME,
  UPDATE_IS_PETITIONER,
  UPDATE_OPPOSING_LAWYER,
  UPDATE_WITNESS_DETAILS,
  UPDATE_CASE_STAGE,
  UPDATE_DOCUMENT_LISTS,
  UPDATE_CASE_RESPONSE,
  UPDATE_HEARING_DATE,
  UPDATE_HEARING_DESCRIPTION,
  UPDATE_HEARING_DOCUMENT_LISTS,
  UPDATE_CASE_TASK_DESCRIPTION,
  UPDATE_CASE_TASK_NAME,
  UPDATE_CASE_TASK_DOCUMENT_LISTS,
  UPDATE_CASE_TASK_PRIORITY,
  UPDATE_HEARING_PURPOSE,
  CLEAR_CASE_DATA,
  UPDATE_CLIENTS,
  UPDATE_WITNESS,
  UPDATE_OPP_CLIENTS
} from "../../utils/constants/dashboard";

const INIT_STATE = {
  caseResponse : {},
  caseDetails: {
    _id: "",
    // caseName: "",
    caseFirstName:"",
    caseSecondName:"",
    caseType: null,
    caseNumber: "",
    grNo: "",
    firNumber: "",
    underSection:null,
    courtSelection: null,
    assignLawyer: null,
    isPetitioner:null,
    description: ""
  },
  ECourtDetails: {
    fillingNumber: "",
    registrationNumber: "",
    cnrNumber: "",
    fillingDate: "",
    registrationDate: "",
    lastHearingDate:"",
    nextHearingDate:"",
    opposingLawyer:"",
    caseStage:"",
    documentLists:"",
  },
  hearingDetails : {
    hearingPurpose: "",
    hearingDate : "",
    hearingDescription : "",
    documentLists:"",
  },
  taskDetails : {
    taskName: "",
    taskDescription : "",
    taskPriority : "",
    documentLists:"",
  },
  clients:[],
  witness:[],
  oppositeClients:[],
  witnessDetails: [] // Added witnessDetails property
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_CASE_DATA:
      return INIT_STATE;
    case UPDATE_CASE_ID:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          _id: action.payload,
        },
      };

    case UPDATE_CASE_FIRST_NAME:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          caseFirstName: action.payload,
        },
      };
    case UPDATE_UNDER_SECTION:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          underSection: action.payload,
        },
      };
    case UPDATE_CASE_SECOND_NAME:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          caseSecondName: action.payload,
        },
      };

    case UPDATE_CASE_TYPE:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          caseType: action.payload,
        },
      };

    case UPDATE_CASE_NUMBER:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          caseNumber: action.payload,
        },
      };

    case UPDATE_GR_NO:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          grNo: action.payload,
        },
      };

    case UPDATE_FIR_NO:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          firNumber: action.payload,
        },
      };

    case UPDATE_COURT:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          courtSelection: action.payload,
        },
      };

    case UPDATE_ASSIGN_LAWYER:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          assignLawyer: action.payload,
        },
      };

    case UPDATE_CASE_DESCRIPTION:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          description: action.payload,
        },
      };
    case UPDATE_IS_PETITIONER:
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          isPetitioner: action.payload,
        },
      };

    case UPDATE_FILLING_NUMBER:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          fillingNumber: action.payload,
        },
      };

    case UPDATE_REGISTRATION_NUMBER:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          registrationNumber: action.payload,
        },
      };

    case UPDATE_CNR_NUMBER:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          cnrNumber: action.payload,
        },
      };
    case UPDATE_OPPOSING_LAWYER:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          opposingLawyer: action.payload,
        },
      };
    case UPDATE_CASE_STAGE:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          caseStage: action.payload,
        },
      };

    case UPDATE_FILLING_DATE:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          fillingDate: action.payload,
        },
      };

    case UPDATE_REGISTRATION_DATE:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          registrationDate: action.payload,
        },
      };
    case UPDATE_LAST_HEARING_DATE:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          lastHearingDate: action.payload,
        },
      };
    case UPDATE_NEXT_HEARING_DATE:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          nextHearingDate: action.payload,
        },
      };
    case UPDATE_DOCUMENT_LISTS:
      return {
        ...state,
        ECourtDetails: {
          ...state.ECourtDetails,
          documentLists: action.payload,
        },
      };
    case UPDATE_CASE_RESPONSE:
      return {
        ...state,
        caseResponse: action.payload,
      };

    case "UPDATE_WITNESS_DETAILS": // Action type for updating witness details
      return {
        ...state,
        witnessDetails: {
          ...state.witnessDetails,
          ...action.payload,
        },
      };
    case UPDATE_HEARING_DATE: 
      return {
        ...state,
        hearingDetails: {
          ...state.hearingDetails,
          hearingDate : action.payload,
        },
      };
      case UPDATE_HEARING_DESCRIPTION: 
      return {
        ...state,
        hearingDetails: {
          ...state.hearingDetails,
          hearingDescription : action.payload,
        },
      };
      case UPDATE_HEARING_DOCUMENT_LISTS: 
      return {
        ...state,
        hearingDetails: {
          ...state.hearingDetails,
          documentLists : action.payload,
        },
      };
      case UPDATE_HEARING_PURPOSE: 
      return {
        ...state,
        hearingDetails: {
          ...state.hearingDetails,
          hearingPurpose : action.payload,
        },
      };
      case UPDATE_CASE_TASK_DESCRIPTION: 
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          taskDescription : action.payload,
        },
      };
      case UPDATE_CASE_TASK_NAME: 
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          taskName : action.payload,
        },
      };
      case UPDATE_CASE_TASK_DOCUMENT_LISTS: 
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          documentLists : action.payload,
        },
      };
      case UPDATE_CASE_TASK_PRIORITY: 
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          taskPriority : action.payload,
        },
      };
      case UPDATE_CLIENTS: 
      return {
        ...state,
        clients: action.payload,
      };
      case UPDATE_WITNESS: 
      return {
        ...state,
        witness: action.payload,
      };
      case UPDATE_OPP_CLIENTS: 
      return {
        ...state,
        oppositeClients : action.payload,
      };
      

    default:
      return state;
  }
};

export default reducer;
