import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid } from "@mui/material";
import DisplayDocuments from "../DisplayDocuments/DisplayDocuments";
import EditDocumentForm from "./EditDocumentForm";

import { LoadingButton } from "@mui/lab";
// import SaveDocuments from "./SaveDocuments";
import { useState } from "react";
import DisplayDocumentsAPI from "../DisplayDocuments/DisplayDocumentsAPI";


const DocumentModal = ({
  handleClose,
  handleClick,
  setDocumentsArray,
  documentsArray,
  setDocumentSaveAPI,
  documentsAPIList,
formDisable,
  setDocumentsAPIList,
}) => {
  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(true);
  };
  // if (confirm) {
  //   return (
  //     <SaveDocuments
  //       documentsArray={documentsArray}
  //       handleClose={handleClose}
  //       setDocumentSaveAPI={setDocumentSaveAPI}
  //       setDocumentsAPIList={setDocumentsAPIList}
  //       documentsAPIList={documentsAPIList}
  //     />
  //   );

    return (
      <>

        <Div
          style={{
            height: "100%",
            width: "100%",
            paddingBottom: "0px",
          }}
        >
          <Div
            sx={{
              height: "100%",
            }}
          >

            {/* <Div

              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2px",
                cursor: "pointer",
              }}
            >
              <CloseIcon onClick={handleClose} />

            </Div> */}


            <Div
              style={{
                height: "100%",
                width: "100%",
                paddingBottom: "0px",
              }}
            >
              <Div
                sx={{
                  height: "100%",

                  display:"flex",
                  justifyContent:"space-between",
                  flexDirection:"column",
                  overflowY:"auto"

                }}
              >
                <Div>
                  <DisplayDocuments
                   formDisable={formDisable}
                    handleClick={handleClick}
                    documentsArray={documentsArray}
                    setDocumentsArray={setDocumentsArray}
                    enablePreview={false}

                  />
                </Div>

                <EditDocumentForm
                  documentsArray={documentsArray}
                  handleClose={handleClose}
                  setDocumentSaveAPI={setDocumentSaveAPI}
                  setDocumentsAPIList={setDocumentsAPIList}
                  documentsAPIList={documentsAPIList}
                  
                />

                {/* <Div
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <LoadingButton
                    style={{
                      backgroundColor: "black",
                    }}
                    sx={{ width: 100, padding: 1 }}
                    variant="contained"
                    onClick={handleClose}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    style={{
                      backgroundColor: "black",
                    }}
                    sx={{ width: 100, padding: 1 }}
                    variant="contained"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </LoadingButton>
                </Div> */}

              </Div>
            </Div>
          </Div>
        </Div>

      </>
    );

};
export default DocumentModal;
