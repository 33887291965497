import * as React from "react";
import Div from "@jumbo/shared/Div";
import { Breadcrumbs, Button, CardContent, Typography } from "@mui/material";
import TaskDetails from "./TaskDetails";
import { useState } from "react";
import { Card } from "@mui/material";
import BreadCrumbs from "./Components/BreadCrumbs";
const AddTask = ({setAddTaskOpen,setTasksList,setTaskAdded,taskEditItem,setTaskEditItem}) => {
  console.log(taskEditItem)
    return (
      <Div sx={{ height: "100%", width: "100%" }}>
        <Div sx={{ mb: 2 }}>
          <BreadCrumbs />
        </Div>

        <Card
          sx={{
            backgroundColor: "white",
            height: "100%",
          }}
        >
          <CardContent>
            <Div
              sx={{
                height: "100%",
                position: "relative",
                paddingTop: "10px",
                //  paddingBottom: "0px",
              }}
            >
              <Div
                sx={{
                  width: "100%",
                  position: "relative",

                  height: "fit-content",
                  // height: "10%",
                  mt: -2,
                  mb: 8,
                }}
              >
                <Div
                  sx={{
                    position: "absolute",

                    borderBottom: "1px solid #D3D3D3 ",
                    width: "100%",
                    pt: 0,
                    pb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <GavelOutlined sx={{ fontSize: 20 }} /> */}
                    <Typography
                      variant="h5"
                      color="text.primary"
                      // sx={{ marginLeft: "10px" }}
                    >
                      Tasks
                    </Typography>
                  </Breadcrumbs>

                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={{ cursor: "pointer" }}
                    //   onClick={resetValue}
                    // sx={{ marginLeft: "10px" }}
                  >
                    {/* Reset */}
                  </Typography>
                </Div>
              </Div>
              {/* breadcrum */}
              <Div
                sx={{
                  pl: { sm: "0", lg: "11%" },
                  pr: { sm: "0", lg: "11%" },
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",

                  alignItems: "center",
                }}
              >
                {/* Add Task Area */}
                <TaskDetails
                  setAddTaskOpen={setAddTaskOpen}
                  setTasksList={setTasksList}
                  setTaskAdded={setTaskAdded}
                  taskEditItem={taskEditItem}
                  setTaskEditItem={setTaskEditItem}
                  inCase={false}
                />
              </Div>
            </Div>
          </CardContent>
        </Card>
      </Div>
    );
};

export default AddTask;
