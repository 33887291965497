import React, { useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { Typography } from "@mui/material";

import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Div from "@jumbo/shared/Div";
import { Screen3Container, Screen3InputSection } from "./style";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { loginValidations, loginValidations2 } from "app/utils/validators";
import { lang } from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
	UserBarCouncil,
	UserLawyerEducation,
	UserLawyerLanguage,
	UserLawyerSpecialisation,
	UserPracticeCourts,
	UserPracticeStates,
} from "app/redux/actions/UpdatedInputs";
import {
	LawyerProfileUpdate,
	getLawyerState,
	getCourt,
	getEdu,
	getLang,
	getSpec,
	getCaseCity,
} from "app/Apis/User";
import Cookies from "js-cookie";
const Screen3 = ({
	activeStep,
	progressIncrement,
	progressDecrement,
	screenState,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// List
	const [cityList, setCityList] = useState([]);
	const [State, setState] = useState([]);
	const [Court, setCourt] = useState([]);
	const [Edu, setEdu] = useState([]);
	const [Specialisation, setSpecialisation] = useState([]);
	const [Language, setLanguage] = useState([]);
	// StateValue
	const [states, setStates] = useState([]);
	const [courts, setCourts] = useState([]);
	const [education, setEducation] = useState([]);
	const [spec, setSpecial] = useState([]);
	const [lang, setLang] = useState([]);
	const [cities, setCities] = useState([]);

	//error
	const [error, setError] = useState(false);
	const [stateError, setStateError] = useState(false);
	const [practiceCourtError, setPracticeCourtError] = useState(false);
	const [specialisationError, setSpecialisationError] = useState(false);
	const [languageError, setLanguageError] = useState(false);
	const [educationError, setEducationError] = useState(false);
	const [cityError, setCityError] = useState(false);

	const getStates = async () => {
		const state = await getLawyerState();
		if (!state) {
			console.log("error while getting states list", state);
		}
		state.shift();
		// console.log(state);
		setState(state);
	};
	const getCourts = async () => {
		const courts = await getCourt();
		if (!courts) {
			console.log("error while getting courts list", courts);
		}
		// state.shift();
		// console.log(courts);
		setCourt(courts);
	};
	const getEduc = async () => {
		const edu = await getEdu();
		if (!edu) {
			console.log("error while getting edu list", edu);
		}
		// console.log(edu);
		setEdu(edu);
	};
	const getSpecialisatioin = async () => {
		const spec = await getSpec();
		if (!spec) {
			console.log("error while getting pec list", spec);
		}
		// state.shift();
		// console.log(spec);
		setSpecialisation(spec);
	};
	const getLanguage = async () => {
		const lang = await getLang();
		if (!lang) {
			console.log("error while getting lang list", lang);
		}
		// state.shift();
		// console.log(lang);
		setLanguage(lang);
	};

	useEffect(() => {
		const getCity = async () => {
			try {
				if (states.length > 0) {
					states?.map(async (state) => {
						const response = await getCaseCity(state?.stateId);
						if (response) {
							setCityList((prev) => [prev, ...response]);
							console.log(response);
						} else {
							console.log("No City Found");
						}
					});
				}
			} catch (error) {
				console.log(error);
			}
		};
		getCity();
	}, [states.length]);

	useEffect(() => {
		const getInitValues = async () => {
			await getStates();
			await getCourts();
			await getEduc();
			await getSpecialisatioin();
			await getLanguage();
		};
		getInitValues();
	}, []);
	const accessToken = Cookies.get("accessToken");

	const barCouncilRegistrationNo = useSelector(
		(state) => state?.updateInputs?.barCouncilRegistrationNo
	);

	const practiceStates = useSelector(
		(state) => state?.updateInputs?.practiceStates
	);

	const practiceCourts = useSelector(
		(state) => state?.updateInputs?.practiceCourts
	);

	const lawyerEducations = useSelector(
		(state) => state?.updateInputs?.lawyerEducations
	);

	const lawyerSpecialisation = useSelector(
		(state) => state?.updateInputs?.lawyerSpecialisation
	);

	const lawyerLanguage = useSelector(
		(state) => state?.updateInputs?.lawyerLanguage
	);

	const formik = useFormik({
		initialValues: {
			barcouncilnumber: barCouncilRegistrationNo,
		},
		validationSchema: loginValidations2,
		enableReinitialize: true,
		onSubmit: (values) => {
			Incrementfn();
			// navigate("/onboarding");
		},
	});

	const Incrementfn = async () => {
		let state = [];
		let court = [];
		let educ = [];
		let speciality = [];
		let lan = [];
		let city = [];
		if (states.length === 0) {
			setError(true);
			setStateError(true);
		} else {
			console.log("in else state");
			states.map((ele) => {
				state.push({
					stateId: ele.stateId,
				});
			});
			setStates(state);
			console.log(state);
		}
		if (cities.length === 0) {
			setError(true);
			setCityError(true);
		} else {
			console.log("in else city");
			cities.map((ele) => {
				city.push({
					districtId: ele.districtId,
				});
			});
			setCities(city);
			console.log(city);
		}
		if (courts.length === 0) {
			setError(true);
			setPracticeCourtError(true);
		} else {
			courts.map((ele) => {
				court.push({
					courtsId: ele.courtsId,
				});
			});
			console.log(court);
		}
		if (education.length === 0) {
			setError(true);
			setEducationError(true);
		} else {
			education.map((ele) => {
				educ.push({
					educationId: ele.educationId,
				});
			});
			console.log(educ);
		}
		if (spec.length === 0) {
			setError(true);
			setSpecialisationError(true);
		} else {
			spec.map((ele) => {
				speciality.push({
					specialityId: ele.specialityId,
				});
			});
			console.log(speciality);
			setEducation(education);
		}
		if (lang.length === 0) {
			setError(true);
			setLanguageError(true);
		} else {
			lang.map((ele) => {
				lan.push({
					languageId: ele.languageId,
				});
			});
			console.log(lan);
			setError(false);
			setStateError(false);
			setPracticeCourtError(false);
			setEducationError(false);
			setSpecialisationError(false);
			setLanguageError(false);
			setCityError(false);

			let obj = {
				barCouncilRegistrationNo,
				practiceStates: state,
				practiceCourts: court,
				lawyerEducations: educ,
				lawyerSpecialisation: speciality,
				lawyerLanguage: lan,
				practiceDistricts: city,
			};
			const response = await LawyerProfileUpdate(accessToken, obj);

			if (response) {
				console.log("lawyer data is ", response);
				navigate("/onboardingsuccess");
				// navigate("/comingSoon");
				// console.log("onboarding")
			} else {
				console.log("something went wrong!");
			}
			// navigate("/onboardingsuccess");
		}
	};

	const ValuesToReduxForState = () => {
		setStateError(false);
		dispatch(UserPracticeStates(states));
	};

	const ValuesToReduxForCourt = () => {
		setPracticeCourtError(false);
		dispatch(UserPracticeCourts(courts));
	};

	const ValuesToReduxForEducation = () => {
		setEducationError(false);
		dispatch(UserLawyerEducation(education));
	};

	const ValuesToReduxForSpecialisation = () => {
		setSpecialisationError(false);
		dispatch(UserLawyerSpecialisation(spec));
	};

	const valuesToReduxForLanguage = () => {
		setLanguageError(false);
		dispatch(UserLawyerLanguage(lang));
	};

	useEffect(() => {
		const getCity = async () => {
			if (states)
				try {
					const response = await getCaseCity(states.stateId);
					if (!response) {
						console.log("No City Found");
					}
					setCityList(response);
					console.log(response);
				} catch (error) {
					console.log(error);
				}
		};
		getCity();
	}, [states]);

	return (
		<Screen3Container>
			<Screen3InputSection>
				<Typography
					style={{
						marginBottom: "30px",
						fontWeight: "600",
						marginTop: "35px",
						fontSize: "20px",
						textAlign: "left",
					}}
				>
					Lets learn about your profession
				</Typography>

				<Div>
					<TextField
						// style={{ width: "30vw" }}
						style={{ width: "100%", backgroundColor: "white" }}
						id="outlined-multiline-flexible"
						label="Bar Council Number"
						required
						color="secondary"
						inputProps={{ maxLength: 15 }}
						name="barcouncilnumber"
						maxRows={4}
						error={formik.errors.barcouncilnumber}
						value={formik.values.barcouncilnumber}
						onChange={formik.handleChange}
						helperText={formik.errors.barcouncilnumber}
						onBlur={dispatch(UserBarCouncil(formik.values.barcouncilnumber))}
					/>
				</Div>
				{/* state */}
				<Div>
					<Autocomplete
						style={{ width: "100%" }}
						value={states}
						onChange={(event, value) => {
							setStates(value);
						}}
						isOptionEqualToValue={(option, value) =>
							option.stateId === value.stateId
						}
						multiple
						disableCloseOnSelect
						options={State}
						// disableCloseOnSelect
						getOptionLabel={(option) => {
							return option.stateName;
						}}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.stateName}
							</li>
						)}
						sx={{ width: 500 }}
						renderInput={(params) => {
							return (
								<TextField
									sx={{ marginTop: "10px", backgroundColor: "white" }}
									{...params}
									label="Select State"
									placeholder="State"
									color="secondary"
									error={stateError}
									onBlur={() =>
										states.length > 0
											? ValuesToReduxForState()
											: setStateError(true)
									}
								/>
							);
						}}
					/>
					{stateError && (
						<Typography sx={{ fontSize: "10px", color: "#E73145" }}>
							Please Select atleast one value!
						</Typography>
					)}
				</Div>
				{/* District */}

				<Div>
					<Autocomplete
						style={{ width: "100%" }}
						value={cities}
						onChange={(event, value) => {
							setCities(value);
						}}
						isOptionEqualToValue={(option, value) =>
							option.districtId === value.districtId
						}
						multiple
						disableCloseOnSelect
						options={cityList}
						// disableCloseOnSelect
						getOptionLabel={(option) => {
							return option.districtName;
						}}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.districtName}
							</li>
						)}
						sx={{ width: 500 }}
						renderInput={(params) => {
							return (
								<TextField
									sx={{ marginTop: "10px", backgroundColor: "white" }}
									{...params}
									label="Select District"
									placeholder="District"
									color="secondary"
									error={cityError}
									onBlur={() => (cities.length > 0 ? null : setCityError(true))}
								/>
							);
						}}
					/>
					{cityError && (
						<Typography sx={{ fontSize: "10px", color: "#E73145" }}>
							Please Select atleast one value!
						</Typography>
					)}
				</Div>

				{/* <Div>
            <Autocomplete
              style={{ width: "100%" }}
              options={cityList}
              value={city}
              onChange={(event, value) => {
                setCity(value);
                if (cityError) {
                  setCityError(false);
                }
              }}
              getOptionLabel={(option) => {
                return option.districtName;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ marginTop: "10px", backgroundColor: "white" }}
                  color="secondary"
                  label="Select City"
                  autocomplete="off"
                  placeholder="Select City"
                  error={cityError}
                  helperText={formik.touched.city && formik.errors.city}
                />
              )}
            />
          </Div> */}

				{/* practice court */}
				<Div sx={{ mt: 0 }}>
					<Autocomplete
						style={{ width: "100%" }}
						value={courts}
						onChange={(event, value) => setCourts(value)}
						// isOptionEqualToValue={(option, value) => option.stateId === value.stateId}
						multiple
						disableCloseOnSelect
						options={Court}
						// disableCloseOnSelect
						getOptionLabel={(option) => {
							return option.courtName;
						}}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.courtName}
							</li>
						)}
						sx={{ width: 500 }}
						renderInput={(params) => (
							<TextField
								sx={{ marginTop: "10px", backgroundColor: "white" }}
								{...params}
								label="Select Practice Court"
								placeholder="Practice Court"
								color="secondary"
								required
								error={practiceCourtError}
								onBlur={() =>
									Court.length > 0
										? ValuesToReduxForCourt()
										: setPracticeCourtError(false)
								}
							/>
						)}
					/>
					{practiceCourtError && (
						<Typography sx={{ fontSize: "10px", color: "#E73145" }}>
							Please Select atleast one value!
						</Typography>
					)}
				</Div>

				{/* Education */}
				<Div>
					<Autocomplete
						style={{ width: "100%" }}
						value={education}
						onChange={(event, value) => setEducation(value)}
						multiple
						disableCloseOnSelect
						// id="checkboxes-tags-demo"
						options={Edu}
						// disableCloseOnSelect
						getOptionLabel={(option) => {
							return option.educationName;
						}}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.educationName}
							</li>
						)}
						sx={{ width: 500 }}
						renderInput={(params) => (
							<TextField
								sx={{ marginTop: "10px", backgroundColor: "white" }}
								{...params}
								error={educationError}
								label="Select Education"
								placeholder="Education"
								required
								color="secondary"
								onBlur={() =>
									education.length > 0
										? ValuesToReduxForEducation()
										: setEducationError(true)
								}
							/>
						)}
					/>
					{educationError && (
						<Typography sx={{ fontSize: "10px", color: "#E73145" }}>
							Please Select atleast one value!
						</Typography>
					)}
				</Div>

				{/* specialisation */}
				<Div>
					<Autocomplete
						style={{ width: "100%" }}
						value={spec}
						onChange={(event, value) => setSpecial(value)}
						multiple
						disableCloseOnSelect
						// id="checkboxes-tags-demo"
						options={Specialisation}
						// disableCloseOnSelect
						getOptionLabel={(option) => {
							return option.specialityName;
						}}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.specialityName}
							</li>
						)}
						sx={{ width: 500 }}
						renderInput={(params) => (
							<TextField
								sx={{ marginTop: "10px", backgroundColor: "white" }}
								{...params}
								label="Specialisation"
								placeholder="Specialisation"
								required
								color="secondary"
								error={specialisationError}
								onBlur={() =>
									spec.length > 0
										? ValuesToReduxForSpecialisation()
										: setSpecialisationError(true)
								}
							/>
						)}
					/>
					{specialisationError && (
						<Typography sx={{ fontSize: "10px", color: "#E73145" }}>
							Please Select atleast one value!
						</Typography>
					)}
				</Div>

				{/* Language */}
				<Div>
					<Autocomplete
						style={{ width: "100%" }}
						multiple
						disableCloseOnSelect
						value={lang}
						onChange={(event, value) => setLang(value)}
						// id="checkboxes-tags-demo"
						options={Language}
						// disableCloseOnSelect
						getOptionLabel={(option) => {
							return option.languageName;
						}}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.languageName}
							</li>
						)}
						sx={{ width: 500 }}
						renderInput={(params) => (
							<TextField
								sx={{ marginTop: "10px", backgroundColor: "white" }}
								{...params}
								label="Language"
								placeholder="Select Language"
								required
								color="secondary"
								error={languageError}
								onBlur={() =>
									lang.length > 0
										? valuesToReduxForLanguage()
										: setLanguageError(true)
								}
							/>
						)}
					/>
					{languageError && (
						<Typography sx={{ fontSize: "10px", color: "#E73145" }}>
							Please Select atleast one value!
						</Typography>
					)}
				</Div>
				<Div
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 5,
					}}
				>
					<LoadingButton
						// onClick={handleNext}
						disabled={activeStep !== 1 ? false : true}
						onClick={progressDecrement}
						style={{
							backgroundColor: activeStep !== 1 ? "black" : "",
						}}
						variant="contained"
						sx={{ width: 100, padding: 1 }}
					>
						Back
					</LoadingButton>

					<LoadingButton
						// onClick={handleNext}
						onClick={formik.handleSubmit}
						style={{
							backgroundColor: "black",
						}}
						variant="contained"
						sx={{ width: 100, padding: 1 }}
					>
						Continue
					</LoadingButton>
				</Div>
			</Screen3InputSection>
		</Screen3Container>
	);
};

export default Screen3;
