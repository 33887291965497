import * as React from "react";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { DocumentValidations } from "app/utils/validators";
import AddDocuments from "app/Components/Documents/AddDocuments/AddDocuments";
import EditDocuments from "app/Components/Documents/EditDocuments/EditDocuments";
import { updateCaseResponse, updateDocumentLists } from "../../redux/actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { updateCaseListItem } from "app/redux/actions/case";

const Document = ({ handleBackButton, handleNextButton,setIsLoading }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const UpdateCaseListItem = (value) => {
    dispatch(updateCaseListItem(value));
  };
  const DocumentLists = (value) => {
    dispatch(updateDocumentLists(value));
    console.log(value);
  };
  const caseResponse = useSelector((state)=>state.dashboard.caseResponse)
  const userDetailsFromRedux = useSelector((state)=>state.onboardingInputs)
  const lawyer = {
    fullName: userDetailsFromRedux.fullName,
    lawyerId:userDetailsFromRedux.lawyerId,
    accessToken :userDetailsFromRedux.accessToken
  }
  const CaseResponse = (value) => {
    dispatch( updateCaseResponse(value));
    console.log(value);
  };
  const DocumentPost = async (documentList,caseResponse) => {
      
    const payload = {
      caseId:caseResponse.caseId,
      documentLists:documentList
    }
    console.log(payload)
    setIsLoading(true)
    fetch(`https://erp.lawinzo.com/api/v1/cases?pageNo=2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
        Authorization: `Bearer ${lawyer.accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        // CaseId(data.caseId);
        
        CaseResponse(data)
        UpdateCaseListItem(data)
        setIsLoading(false)
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors
        setIsLoading(false)
        console.error("Error:", error);
      });
  };
  const hiddenFileInput = React.useRef(null);

  const [documentsArray, setDocumentsArray] = React.useState(null);

  const documentListsFromRedux = useSelector((state)=>state.dashboard.ECourtDetails.documentLists)
  console.log(documentListsFromRedux)
  const [documentsAPIList, setDocumentsAPIList] = React.useState(documentListsFromRedux?documentListsFromRedux:null);
  

  const [documentUploaded, setDocumentUploaded] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      DocumentDescription: "",
      DocumentName: "",
    },
 
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      DocumentLists(documentsAPIList)
      DocumentPost(documentsAPIList,caseResponse)
      handleNextButton();
    },
  });

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const files = event.target.files;
    const updatedDocuments = [...documentUploaded];

    for (let i = 0; i < files.length; i++) {
      updatedDocuments.push({
        url: URL.createObjectURL(files[i]),
        type: files[i].type,
        name: files[i].name,
      });
    }

    setDocumentUploaded(updatedDocuments);
  };

  const removeDocument = (deletedUrl) => {
    const newDocumentUploaded = documentUploaded.filter(
      (document) => document.url !== deletedUrl
    );

    setDocumentUploaded(newDocumentUploaded);
  };

  return (
    <div style={{ height: "100%", width: "100%", paddingBottom: "30px" }}>
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        <Typography variant="h6" sx={{ mt: "0px" }}>
          Upload Documents
        </Typography>
        {documentListsFromRedux?.length> 0 ?
        
        <EditDocuments
          documentsArray={documentsArray}
          setDocumentsArray={setDocumentsArray}
          documentsAPIList={documentsAPIList}
          setDocumentsAPIList={setDocumentsAPIList}
        />
        :
        <AddDocuments
          documentsArray={documentsArray}
          setDocumentsArray={setDocumentsArray}
          documentsAPIList={documentsAPIList}
          setDocumentsAPIList={setDocumentsAPIList}
          type={"cases"}
        />
        }

        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            variant="contained"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
};

export default Document;
