import Cookies from "js-cookie";
import { apiHeader, apiUrl } from "../config";
import axios from "axios";
import { filter } from "app/utils/filter";

export const getPaymentLists = async (
  accessToken,
  type = "lawyer",
  dayRange = "All",
  size = 25,
  pageNumber = 0,
  dayRangeFilter, startDate, endDate

) => {
  const date = filter(dayRangeFilter, startDate, endDate)
  console.log(startDate,date,dayRangeFilter);
  if (!accessToken) {
    throw Error("No accessToken provided");
  }
  try {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": apiHeader,
      Authorization: "Bearer " + accessToken,
    };


    const params = new URLSearchParams();
    params.append("type", type);
    params.append("dayRange", dayRange);
    params.append("size", size);
    params.append("page", pageNumber);
    params.append("fromDate", date?.startDate);
    params.append("toDate", date?.endDate);
    console.log(date?.startDate,date?.endDate);

    const url = apiUrl + "/payment/list";
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.get(urlWithParams, { headers });

    if (response.status === 200) {
      console.log("payment list", response);
      return response?.data?.data;
    }

    return null;
  } catch (err) {
    console.error(err);
  }

};
export const getPaymentUrl = async (
  body,
  orderId
) => {
  if (!body || !orderId) {
    throw Error("Provide all parameters to request function");
  }
  try {
    const accessToken = Cookies.get("accessToken");
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": apiHeader,
      Authorization: "Bearer " + accessToken,
    };
    const params = new URLSearchParams();
    params.append("retry", 1);
    params.append("platform", "cloud");
    params.append("order_id", orderId);
    const url = apiUrl + "/payment/initiate";
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.get(urlWithParams, { headers });

    if (response.status === 200) {
      console.log("payment request sent");
      return response?.data?.data;
    }

    return null;

  } catch (err) {
    console.error(err);
  }
};

export const getPaymentReport = async (
  accessToken,
  filters = true
) => {
  if (!accessToken) {
    throw Error("No accessToken provided");
  }
  try {
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const url = "https://api.lawinzo.com/node/payments/reports";
    let response = null;
    if (filters) {
      response = await axios.post(url, filters, { headers });
    } else {
      response = await axios.post(url, { order_status: "Success" }, { headers });
    }
    if (response.status === 200) {
      console.log("payment report", response);
      return response?.data?.data;
    }
    return null;
  } catch (err) {
    console.error(err);
  }

};

export const paymentRequest = async (
  body
) => {
  if (!body) {
    throw Error("No body provided");
  }
  try {
    const accessToken = Cookies.get("accessToken");
    const Body = {
      ...body,
      payment_mode: "ONLINE",
      // requester:"cloud",
    };
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": apiHeader,
      Authorization: "Bearer " + accessToken,
    };

    const url = apiUrl + "/payment/request";

    const response = await axios.post(url, Body, { headers });

    if (response.status === 200) {
      console.log("payment request sent");
      return response?.data?.data;
    }

    return null;

  } catch (err) {
    console.error(err);
  }
};

export const PaymentsWithdraw = async (
  body
) => {
  if (!body) {
    throw Error("No body provided");
  }
  try {
    const accessToken = Cookies.get("accessToken");
    const Body = {
      withdraw_amount: body?.amount
    };
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": apiHeader,
      Authorization: "Bearer " + accessToken,
    };

    const url = apiUrl + "/payment/withdraw";
    const response = await axios.post(url, Body, { headers });

    if (response.status === 200) {
      console.log("payment request sent");
      return response
    }

    return null;

  } catch (err) {
    console.error(err);
  }
}



// https://erp.lawinzo.com/payment/verify/LW202308020744317760 sample to get payment by orderId

export const getPaymentByOrderId = async(orderId)=>{
  try{
    if(!orderId){
      throw Error("No orderId");
    }
    const accessToken = Cookies.get("accessToken");
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": apiHeader,
      Authorization: "Bearer " + accessToken,
    };

    const response = axios.get(apiUrl+`/payment/verify/${orderId}`,{headers});
    if (response.status === 200) {
      console.log("recieved");
      return response?.data
    }

    return null;
  }catch(err){
    console.log(err);
  }
}



