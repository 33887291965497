import React, { useEffect, useState } from "react";
import { CardContent, TextField, Typography } from "@mui/material";

import Link from "@mui/material/Link";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import SwipeableTextMobileStepper from "../components/SwipeableTextMobileStepper";
import Otp from "../authPages/otp/Otp";
import SocialButtons from "app/pages/components/SocialButtons";
import LoginFooter from "app/pages/components/LoginFooter";
import { generateOtp } from "app/Apis/Login";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Animation from "app/pages/components/Animations/Animation";
import PersonIcon from "@mui/icons-material/Person";
import InputAdornment from "@mui/material/InputAdornment";
// import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import OtpF from "./OtpF";

const Edit = ({ disableSmLogin, editNumber,verifiedOtp, setVerifiedOtp  }) => {
  // const theme = useTheme();
  const isBelow990px = useMediaQuery((theme) => theme.breakpoints.down(990));

  useEffect(() => {
    console.log("is mobile", disableSmLogin);
  }, [disableSmLogin]);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const phoneUrl = queryParameters.get("phoneNumber");
  const [isLoading, setIsLoading] = useState(false);

  const [showOtp, setShowOtp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const getOtp = async () => {
    if (phoneNumber) {
      const response = await generateOtp(phoneNumber);
    } else {
      const response = await generateOtp(phoneUrl);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phoneNumber)
    ) {
      setShowOtp(true);
      setError("");
      getOtp();
    } else {
      setError("Enter a valid phone number");
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };
  useEffect(() => {
    if (phoneUrl) {
      if (
        /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phoneUrl)
      ) {
        setShowOtp(true);
        getOtp();
      } else {
        setError("Enter a valid phone number");
      }
    }
    if (editNumber) {
      setPhoneNumber(editNumber);
    }
  }, [phoneUrl]);

  return (
    <>
      {showOtp ? (
        <OtpF
          phoneNumber={phoneNumber ? phoneNumber : phoneUrl}
          disableSmLogin={disableSmLogin}
          verifiedOtp={verifiedOtp} setVerifiedOtp={setVerifiedOtp}
        />
      ) : (
        <Div
          sx={{
            backgroundColor: "#FFFFFF",
            margin: 0,
            display: "flex",
            flex: "1 ",
            minWidth: 0,
            flexDirection: { xs: "column", md: "row" },
            height: "100%",
            borderRadius: "0",
          }}
        >
          <Div
            sx={{
              flex: 1,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Div
              sx={{
                flex: "1",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                my: "1rem",
                marginLeft: "auto",
                marginBottom: "1rem",
                marginRight: isBelow990px ? "auto" : "15%",
                width: "100%",
                // padding: "20px",
              }}
            >
              <form onSubmit={handleSubmit} autoComplete="off">
                <Div sx={{ mb: 2, color: "#3c4d62", fontWeight: "400" }}>
                  <label>
                    <TextField
                      fullWidth
                      id="Enter Mobile Number"
                      label="Enter Mobile Number"
                      placeholder="Enter Mobile Number"
                      type="tel"
                      color="secondary"
                      onChange={handleChange}
                      value={phoneNumber}
                      required
                    />
                  </label>
                  {error && (
                    <Div sx={{ display: "flex", alignItems: "center" }}>
                      <ErrorOutlineIcon color="error" fontSize="small" />
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          ml: 1,
                        }}
                      >
                        {error}
                      </Typography>
                    </Div>
                  )}
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  disableElevation
                  sx={{
                    mb: -1,
                    backgroundColor: "#EABF4E",
                    position: "relative",
                    letterSpacing: "0.02em",
                    alignItems: "center",
                    fontFamily: "Nunito, sans-serif",
                    fontWeight: "900",
                  }}
                  // onClick={getOtp}
                >
                  Get OTP
                </LoadingButton>
              </form>
            </Div>
          </Div>
        </Div>
      )}
    </>
  );
};

export default Edit;
