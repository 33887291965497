import { apiHeader, apiUrl } from "../config";

export const getSettings = async (lawyerId, accessToken) => {
  if (!lawyerId) {
    console.log("no lawyer id provided");
  } else {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("x-api-key", apiHeader);
      headers.append("Authorization", "Bearer " + accessToken);
      const response = await fetch(apiUrl + `/api/v1/lawyers/${lawyerId}`, {
        method: "GET",
        headers,
      });
      if (response.ok) {
        const data = await response.json();
        console.log("updated successfully",data);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  }
};
export const updateNotificationsSettings = async (
  prevSettings,
  newSettings,
  lawyerId,
  accessToken
) => {
  if (!lawyerId) {
    console.log("no lawyer id provided");
  }
  if (!prevSettings) {
    console.log("no prev Settings provided");
  }
  if (!newSettings) {
    console.log("no new settings provided");
  }
  const settings = {
    ...prevSettings,
    lawyerId: lawyerId,
    website: "string",
    notificationsDetails: newSettings,
  };
  try {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + `/api/v1/lawyers/`, {
      method: "POST",
      headers,
      body: JSON.stringify(settings),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("updated successfully");
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateAddressSettings = async (
  prevSettings,
  newSettings,
  lawyerId,
  accessToken
) => {
  if (!lawyerId) {
    console.log("no lawyer id provided");
  }
  if (!prevSettings) {
    console.log("no prev Settings provided");
  }
  if (!newSettings) {
    console.log("no new settings provided");
  }
  const settings = {
    ...prevSettings,
    lawyerId: lawyerId,
    website: "string",
    addressDetails: newSettings,
  };
  try {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + `/api/v1/lawyers/`, {
      method: "POST",
      headers,
      body: JSON.stringify(settings),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("updated successfully");
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateTimingSettings = async (
  prevSettings,
  newSettings,
  lawyerId,
  accessToken
) => {
  if (!lawyerId) {
    console.log("no lawyer id provided");
  }
  if (!prevSettings) {
    console.log("no prev Settings provided");
  }
  if (!newSettings) {
    console.log("no new settings provided");
  }
  const settings = {
    ...prevSettings,
    lawyerId: lawyerId,
    website: "string",
    timingDetails: newSettings,
  };
  try {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + `/api/v1/lawyers/`, {
      method: "POST",
      headers,
      body: JSON.stringify(settings),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("updated successfully");
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateBudgetSettings = async (
  prevSettings,
  newSettings,
  lawyerId,
  accessToken
) => {
  if (!lawyerId) {
    console.log("no lawyer id provided");
  }
  if (!prevSettings) {
    console.log("no prev Settings provided");
  }
  if (!newSettings) {
    console.log("no new settings provided");
  }
  const settings = {
    ...prevSettings,
    lawyerId: lawyerId,
    website: "string",
    practiceFeeDetails: newSettings,
  };
  try {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + `/api/v1/lawyers/`, {
      method: "POST",
      headers,
      body: JSON.stringify(settings),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("updated successfully");
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateBankSettings = async (
  prevSettings,
  newSettings,
  lawyerId,
  accessToken
) => {
  if (!lawyerId) {
    console.log("no lawyer id provided");
  }
  if (!prevSettings) {
    console.log("no prev Settings provided");
  }
  if (!newSettings) {
    console.log("no new settings provided");
  }
  const settings = {
    ...prevSettings,
    lawyerId: lawyerId,
    website: "string",
    paymentDetails: newSettings,
  };
  try {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + `/api/v1/lawyers/`, {
      method: "POST",
      headers,
      body: JSON.stringify(settings),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("updated successfully");
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};
