import { apiHeader, apiUrl } from "app/config";
import { filter } from "app/utils/filter";

export const getTasksList = async (accessToken, page, pageSize,basicFilter,fromDate,toDate) => {
  const date=filter(basicFilter,fromDate,toDate)
  console.log(date)
  
  try {
    let headers = new Headers();
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("size", pageSize);

      params.append("fromDate",date?.startDate);
      params.append("toDate",date?.endDate);
    
   
    // headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const url = apiUrl + "/api/v1/tasks";
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await fetch(urlWithParams, {
      method: "GET",
      headers,
      // body: JSON.stringify(obj),
    });

    // console.log(response);
    if (response.status === 200) {
      const data = await response.json();
      console.log("all tasks fetched", data);
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const getCasesList = async (accessToken) => {
  try {
    let headers = new Headers();
    // headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const response = await fetch(apiUrl + "/api/v1/cases", {
      method: "GET",
      headers,
      // body: JSON.stringify(obj),
    });

    // console.log(response);
    if (response.status===200) {
      const data = await response.json();
      console.log("all cases fetched", data);
      return data.cases;
    }
  } catch (e) {
    console.log(e);
  }
};

export const createTodoTask = async (accessToken, newTask) => {
  try {
    console.log(accessToken);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    // headers.append('Authorization', 'Bearer ' + 'application/json');

    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    console.log();
    const response = await fetch(apiUrl + "/api/v1/tasks/todo", {
      method: "POST",
      headers,
      body: JSON.stringify(newTask),
    });
    // console.log(response);

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteTodoTask = async (accessToken, task) => {
  // console.log("Checking Task...", task);
  try {
    if (!accessToken) {
      throw Error("No accessToken Provided");
    }
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    // headers.append('Authorization', 'Bearer ' + 'application/json');
    const deletedTask = {
      ...task,
      isActive: 0,
    };
    // console.log("checking deletedTask", deletedTask);
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    console.log();
    const response = await fetch(apiUrl + "/api/v1/tasks/todo", {
      method: "POST",
      headers,
      body: JSON.stringify(deletedTask),
    });
    // console.log(response);

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};
