import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
// import Div from "@jumbo/shared/Div/Div";

function SingleDataCard({ title, subheader, Icon }) {
  const IconComponent = Icon;
  return (
    <Card
      sx={{
        backgroundColor: "black",
        color: "white",
        // minHeight: "104px",
        height: "100%",
        display: "flex",
        // p: 2.5,
        p:0,
        pt: 0,
        flexDirection: "column",
      }}
    >
      <CardHeader
      title={<Typography sx={{ fontSize: "1rem" }}>{title ? title : 0}</Typography>}
      avatar={IconComponent}
      subheader={  <Typography
        sx={{ fontSize: "0.70rem" }}
      >{subheader}</Typography>}
    />
    </Card>
  );
}

export default SingleDataCard;
