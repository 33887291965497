import React from "react";
import Div from "@jumbo/shared/Div";
import { useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import {
  Alert,
  Breadcrumbs,
  Radio,
  RadioGroup,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { Grid } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { FilePresentOutlined } from "@mui/icons-material";
import { Card, Divider, InputBase, TextField } from "@mui/material";
import { useJumboTheme } from "@jumbo/hooks";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useMediaQuery } from "@mui/material";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { useFormik } from "formik";
import { documentValidations } from "app/utils/validators";
import { createDocument } from "app/Apis/document";

const Case = ["Case 1", "Case 2"];
const Type = [
  "FIR",
  "Complaint Case",
  "Bail Order",

  "Chargesheet Copy",
  "Anticipatory Bail Order",

  "Case Diary",
  "Petition Draft",
  "Petition Filled",
  "Rejoinder Draft",
  "Rejoinder Filled",
  "Others",
];

const EditDocumentForm = ({
  documentsArray,
  handleClose,
  setDocumentSaveAPI,
  documentsAPIList,

  setDocumentsAPIList,
  currentPage,
  handleNext,
}) => {
  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );
  const [isLoading, setIsLoading] = useState(false);

  let APIList = [];

  if (documentsAPIList) {
    APIList = [...documentsAPIList];
  }
  const handleContinue = async () => {
    setIsLoading(true);
    console.log(formik.values);
    console.log(documentsArray);
    console.log(APIList);
    const responses = [];
    documentsArray.map(async (document, index) => {
      const documentData = {
        documentName: documentName,
        documentFile: document,
        documentType: documentType,
        documentDescription: fieldDescription,
        isVisibleToClient: 1,
        documentExtention: document?.type,
        isActive: 1,
      };
      console.log(documentData);
      const response = await createDocument(accessToken, documentData, "tasks");
      if (response) {
        responses.push(response);
        console.log("API List", APIList);
        // handleNext();
        console.log("document created successfully");
        if (responses.length === documentsArray.length) {
          APIList = [...APIList, ...responses];
          setDocumentsAPIList(APIList);
          console.log(APIList);
          setDocumentSaveAPI(true);
          handleClose();
        }
      }
    });
    if (responses.length === documentsArray.length) {
      setIsLoading(false);
    }
    if (!APIList) {
      console.log("error sinding docs to api");
    }
  };

  const [fieldDescription, setFieldDescription] = React.useState("");
  const [documentName, setDocumentName] = React.useState();
  const [documentType, setDocumentType] = useState();

  const isBelow768px = useMediaQuery((theme) => theme.breakpoints.down(768));
  const formik = useFormik({
    initialValues: {
      documentType: documentType,

      documentName: documentName,
      documentDescription: fieldDescription,
    },
    validationSchema: documentValidations,
    enableReinitialize: true,

    onSubmit: handleContinue,

    //   setConfirm(true);
  });

  return (
    <>
      <Div
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-evenly",
          mt: 1,
        }}
      >
        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.documentType}
          onChange={(event, value) => setDocumentType(value)}
          options={Type}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>{option}</li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "10px" }}
              {...params}
              label="Document Type*"
              placeholder="Select Document Type*"
              color="secondary"
              error={formik.errors.documentType && formik.touched.documentType}
              helperText={formik.errors.documentType}
              name="documentType"
            />
          )}
        />

        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Document Name*"
          placeholder="Enter Document Name*"
          variant="outlined"
          color="secondary"
          value={formik.values.documentName}
          error={formik.errors.documentName && formik.touched.documentName}
          helperText={formik.errors.documentName}
          name="documentName"
          onChange={(e) => setDocumentName(e.target.value)}
        />

        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-multiline-static"
          label="Write Description here"
          color="secondary"
          placeholder="Write Description here"
          multiline
          rows={4}
          value={formik.values.fieldDescription}
          onChange={(e) => setFieldDescription(e.target.value)}
          name="documentDescription"
          error={
            formik.errors.documentDescription &&
            formik.touched.documentDescription
          }
          helperText={formik.errors.documentDescription}
        />

        <Div
          sx={{
            display: "flex",
            marginTop: "1rem",
            alignItems: "center",
          }}
        >
          <Checkbox color="secondary" />

          <Typography>Admissible Evidence</Typography>
        </Div>
        <Div
          style={{
            display: "flex",
            alignItems: isBelow768px ? "normal" : "center",
            flexDirection: isBelow768px ? "column" : "row",
          }}
        >
          <Typography
            style={{
              fontFamily: "Nunito",
              fontWeight: 400,
              fontSize: "0.8571428571428571rem",
              lineHeight: 1.5,
            }}
          >
            Should this be visible to client?
          </Typography>
          <RadioGroup
            row
            aria-label="visibility"
            style={{
              marginLeft: isBelow768px ? "0px" : "12px",
              display: "flex",
              flexDirection: "row",
            }}
            defaultValue="yes"
          >
            <FormControlLabel
              value="yes"
              control={<Radio style={{ color: "#f1c40f" }} />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio style={{ color: "#f1c40f" }} />}
              label="No"
            />
          </RadioGroup>
        </Div>
      </Div>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          margin: "1rem 0",
        }}
      >
        <Button
          variant="contained"
          onClick={formik.handleSubmit}
          disabled={isLoading}
          sx={{
            mt: 0,
            mr: 0,
            width: "100%",
            padding: 1,
            backgroundColor: "#f1c40f",
          }}
        >
          {/* {currentPage===documentsArray.length-1?"Save":"Next"} */}
          {isLoading ? (
            <>
              <CircularProgress
                size="1.2rem"
                sx={{ color: "white", mr: "5px" }}
              />
              {"Uploading Document"}
            </>
          ) : (
            "Confirm"
          )}
        </Button>
      </Div>
    </>
  );
};

export default EditDocumentForm;
