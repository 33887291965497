import React from "react";
import PaymentTable from "./PaymentTable";
import Div from "@jumbo/shared/Div";

const PaymentsList = ({ paymentsList }) => {
  return (
    <>
      <React.Fragment>
        {paymentsList  ? (
          <PaymentTable
            paymentsList={paymentsList}
          />
        ) : (
          <Div
            sx={{
              p: 3,
            }}
          >
            No payments to display
          </Div>
        )}
      </React.Fragment>
    </>
  );
};

export default PaymentsList;
