import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "@emotion/styled";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import NonActiveLawyerCard from "./NonActiveLawyerCard";
import CaseReportCard from "./CaseReportCard";
import Cookies from "js-cookie";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    textAlign:"left",
    backgroundColor:"#fff",
    color:"#8F8F90"
  }));

const CaseReportList = ({list}) => {
  const assignedRole = Cookies.get("assignedRole");
    return (
        // <JumboScrollbar
        //   autoHeight={true}
        //   autoHideTimeout={4000}
        //   autoHeightMin={350}
        //   autoHide={true}
        //   hideTracksWhenNotNeeded
        // >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    pl: 2,
                    fontSize: "1rem",
                    fontWeight: 600,
                    textAlign: "center",
                    width: "44%",
                  }}
                >
                  Uploaded By
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    pl: 3,
                    fontSize: "1rem",
                    fontWeight: 600,
                    textAlign: "center",
                    width: "44%",
                  }}
                >
                  Sections
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    pl: 3,
                    fontSize: "1rem",
                    fontWeight: 600,
                    textAlign: "center",
                    width: "3%",
                  }}
                >
                  Count
                </StyledTableCell>
                {assignedRole==="ROLE_ADMIN" && (
                <StyledTableCell
                  sx={{
                    pl: 3,
                    fontSize: "1rem",
                    fontWeight: 600,
                    textAlign: "center",
                    width: "9%",
                  }}
                >
                  Action
                </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {list &&
                list.map((lawyer, index) => (
                  <CaseReportCard
                    list={list}
                    item={lawyer}
                    key={index}
                    index={index}
                  />
                ))}
            </TableBody>
          </Table>
        // </JumboScrollbar>
      );
}

export default CaseReportList;