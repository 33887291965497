import * as React from "react";
import { useState, useEffect } from "react";
import { Autocomplete, InputLabel, MenuItem, Select } from "@mui/material";
import { getHearingPurpose } from "app/Apis/case";
import AddDocuments from "app/Components/Documents/AddDocuments/AddDocuments";
import EditDocuments from "app/Components/Documents/EditDocuments/EditDocuments";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import { CaseTaskValidations } from "app/utils/validators";
import { useDispatch, useSelector } from "react-redux";
import {
	updateCaseResponse,
	updateCaseTaskDescription,
	updateCaseTaskName,
	updateCaseTaskDocumentLists,
	updateCaseTaskPriority,
} from "../../redux/actions/dashboard";
import { updateCaseListItem } from "app/redux/actions/case";

const ToDo = ({ handleBackButton, handleNextButton, setIsLoading }) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const userDetailsFromRedux = useSelector((state) => state.onboardingInputs);
	const documentListsFromRedux = useSelector(
		(state) => state.dashboard.taskDetails.documentLists
	);
	const taskDetailsFromRedux = useSelector(
		(state) => state.dashboard.taskDetails
	);

	const caseResponse = useSelector((state) => state.dashboard.caseResponse);

	const dispatch = useDispatch();

	const lawyer = {
		fullName: userDetailsFromRedux.fullName,
		lawyerId: userDetailsFromRedux.lawyerId,
		accessToken: userDetailsFromRedux.accessToken,
	};

	const UpdateCaseListItem = (value) => {
		dispatch(updateCaseListItem(value));
	};
	const CaseResponse = (value) => {
		dispatch(updateCaseResponse(value));
		console.log(value);
	};
	const CaseTaskName = (value) => {
		dispatch(updateCaseTaskName(value));
		console.log(value);
	};
	const CaseTaskPriority = (value) => {
		dispatch(updateCaseTaskPriority(value));
		console.log(value);
	};
	const CaseTaskDescription = (value) => {
		dispatch(updateCaseTaskDescription(value));
		console.log(value);
	};
	const CaseTaskDocumentLists = (value) => {
		dispatch(updateCaseTaskDocumentLists(value));
		console.log(value);
	};

	const priorityOptions = ["Low", "Medium", "High"];
	const CaseTaskDetails = async (values, documentLists, caseResponse) => {
		const payload = {
			caseId: caseResponse.caseId,
			tasksRecords: [
				...(caseResponse?.tasksRecords || []),
				{
					caseId: caseResponse.caseId,
					assignedBy: lawyer.lawyerId,
					documentLists: documentLists,
					priority: priorityOptions.findIndex(
						(option) => option === values.taskPriority
					),
					taskDescription: values.taskDescription,
					taskName: values.taskName,
					isActive: 1,
					remainderDate: values.remainderDate,
					status: taskStatusRes,
				},
			],
		};
		console.log(payload);
		setIsLoading(true);
		fetch("https://erp.lawinzo.com/api/v1/cases?pageNo=7", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
				Authorization: `Bearer ${lawyer.accessToken}`,
			},

			body: JSON.stringify(payload),
		})
			.then((response) => response.json())
			.then((data) => {
				// Handle the response data
				CaseResponse(data);
				UpdateCaseListItem(data);
				setIsLoading(false);
				console.log(data);
			})
			.catch((error) => {
				// Handle any errors
				setIsLoading(false);
				console.error("Error:", error);
			});
	};

	const [taskName, setTaskName] = useState(taskDetailsFromRedux.taskName);
	const [taskDescription, setTaskDescription] = useState(
		taskDetailsFromRedux.taskDescription
	);
	const [taskRemainderDate, settaskRemainderDate] = useState(null);
	const [taskStatus, settaskStatus] = useState("");
	const [taskStatusRes, settaskStatusRes] = useState("");
	const [taskPriority, setTaskPriority] = useState(
		taskDetailsFromRedux.taskPriority
			? taskDetailsFromRedux?.taskPriority
			: "High"
	);
	const [documentsArray, setDocumentsArray] = React.useState(null);
	const [documentsAPIList, setDocumentsAPIList] = React.useState(
		documentListsFromRedux ? documentListsFromRedux : null
	);
	const formik = useFormik({
		initialValues: {
			taskName: taskName,
			taskDescription: taskDescription,
			taskPriority: taskPriority,
			taskRemainderDate: taskRemainderDate,
			taskStatus: taskStatus,
		},
		validationSchema: CaseTaskValidations,
		enableReinitialize: true,
		onSubmit: (values) => {
			console.log(values);
			CaseTaskDescription(taskDescription);
			CaseTaskName(taskName);
			CaseTaskPriority(taskPriority);
			CaseTaskDocumentLists(documentsAPIList);
			handleNextButton();
			if (formik.values.taskName.length > 0) {
				CaseTaskDetails(values, documentsAPIList, caseResponse);
			}
		},
	});

	console.log(formik.values.taskStatus);

	return (
		<Div
			style={{
				height: "100%",
				width: "100%",
				paddingBottom: "30px",
			}}
		>
			{/* <CaseContainer> */}
			<Div
				sx={{
					marginLeft: { xs: "5%", md: "18%" },
					marginRight: { xs: "5%", md: "18%" },
					marginTop: "20px",
					height: "100%",
				}}
			>
				{/* <Hearings
       inCase = {true}
       /> */}
				<div
					style={{
						height: "100%",
						width: "100%",
						paddingBottom: "30px",
					}}
				>
					{/* <CaseContainer> */}
					<Div
						sx={{
							marginTop: "20px",
							height: "100%",
						}}
					>
						<TextField
							sx={{ mb: 0, mt: 2 }}
							style={{ width: "100%" }}
							id="outlined-multiline-flexible"
							label="Task Name"
							// multiline
							color="secondary"
							// name="barcouncilnumber"
							// maxRows={4}
							value={formik.values.taskName}
							// onChange={formik.handleChange}
							onChange={(e) => setTaskName(e.target.value)}
							error={formik.errors.taskName}
							name="taskName"
							helperText={formik.errors.taskName}
						/>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack spacing={3} sx={{ width: "100%", mt: 2 }}>
								<DatePicker
									label="Task Remainder Date"
									format="DD/MM/YYYY"
									value={formik.values.taskRemainderDate}
									onChange={(newValue) => settaskRemainderDate(newValue)}
									renderInput={(params) => (
										<TextField
											{...params}
											//  error={formik.errors.FillingDate}
											name="hearingDate"
											color="secondary"
											// helperText={formik.errors.FillingDate}
										/>
									)}
								/>
							</Stack>
						</LocalizationProvider>

						{/* <Autocomplete
							style={{ width: "100%" }}
							value={formik.values.taskStatus}
							onChange={(e, v) => {
								if (v === "Open") {
									settaskStatus(v);
									return settaskStatusRes("OpenTask");
								} else if (v === "CLosed") {
									settaskStatus(v);
									return settaskStatusRes("CloseTask");
								}

								settaskStatus("");
							}}
							isOptionEqualToValue={(option, value) => option === value}
							name="Task Status"
							options={["Open", "Closed"]}
							// disableCloseOnSelect
							getOptionLabel={(option) => option}
							renderOption={(props, option, { selected }) => (
								<li {...props}>{option}</li>
							)}
							sx={{ width: 500 }}
							renderInput={(params) => (
								<TextField
									sx={{ marginTop: "16px" }}
									{...params}
									label="Task Status"
									placeholder="Select Task Status"
									color="secondary"
									// error={caseError}
									error={formik.errors.caseStage && formik.touched.caseStage}
									helperText={formik.errors.caseStage}
									name="caseStage"
								/>
							)}
						/> */}

						{/* <FormControl fullWidth sx={{ marginTop: "20px" }}>
							<InputLabel id="demo-simple-select-label">Task State</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={formik.values.taskStatus}
								label="Task State"
								onChange={(e) => {
									console.log(formik.values.taskStatus);
									settaskStatusRes(e.target.value);
								}}
							>
								<MenuItem
									onClick={() => settaskStatus("Open")}
									value={"OpenTask"}
								>
									Open
								</MenuItem>
								<MenuItem
									onClick={() => settaskStatus("Closed")}
									value={"CloseTask"}
								>
									Closed
								</MenuItem>
							</Select>
						</FormControl> */}

						<Autocomplete
							style={{ width: "100%" }}
							value={formik.values.taskStatus}
							onChange={(e, v) => {
								settaskStatus(v);
								if (v === "Open") {
									settaskStatusRes("OpenTask");
								} else {
									settaskStatusRes("CloseTask");
								}
							}}
							// isOptionEqualToValue={(option, value) =>
							// 	option.purposeId === value.purposeId
							// }
							name="Task Status"
							options={["Open", "Closed"]}
							// disableCloseOnSelect
							getOptionLabel={(option) => option}
							renderOption={(props, option, { selected }) => (
								<li {...props}>{option}</li>
							)}
							sx={{ width: 500 }}
							renderInput={(params) => (
								<TextField
									sx={{ marginTop: "16px" }}
									{...params}
									label="Task Status"
									placeholder="Select Task Status"
									color="secondary"
									// error={caseError}
									error={formik.errors.caseStage && formik.touched.caseStage}
									helperText={formik.errors.caseStage}
									name="caseStage"
								/>
							)}
						/>

						{/* <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Task Status</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={formik.values.taskStatus}
								label="Age"
								onChange={(e) => {
									console.log(formik.values.taskStatus);
									settaskStatusRes(e.target.value);
								}}
							>
								<MenuItem value={10}>Ten</MenuItem>
								<MenuItem value={20}>Twenty</MenuItem>
								<MenuItem value={30}>Thirty</MenuItem>
							</Select>
						</FormControl> */}
						<TextField
							sx={{ mb: 0, mt: 2, width: "100%" }}
							id="outlined-multiline-static"
							label="Write Task Description..."
							multiline
							color="secondary"
							rows={4}
							onChange={(e) => {
								setTaskDescription(e.target.value);
							}}
							value={formik.values.taskDescription}
							error={formik.errors.taskDescription}
							name="taskDescription"
							helperText={formik.errors.taskDescription}
						/>
						<Div
							sx={{
								display: "flex",
								alignItems: { sm: "center", xs: "flex-start" },
								mb: 0,
								mt: 2,
								flexDirection: { xs: "column", sm: "row" },
							}}
						>
							<Typography variant="h6" sx={{ mr: 3 }}>
								Priority
							</Typography>
							<FormControl
								sx={{
									display: { sm: "flex" },
									flexDirection: { sm: "column" },
									alignItems: { sm: "center" },
								}}
							>
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="priority"
									defaultValue={taskPriority}
									// value={taskPriority}
									onChange={(e, value) => setTaskPriority(value)}
									error={formik.errors.taskPriority}
									helperText={formik.errors.taskPriority}
								>
									<FormControlLabel
										value="High"
										control={<Radio color="secondary" />}
										label="High"
									/>
									<FormControlLabel
										value="Medium"
										control={<Radio color="secondary" />}
										label="Medium"
									/>
									<FormControlLabel
										value="Low"
										control={<Radio color="secondary" />}
										label="Low"
									/>
								</RadioGroup>
							</FormControl>
						</Div>

						<Div>
							<Typography variant="h6" sx={{ mt: "0px" }}>
								{documentListsFromRedux?.length > 0
									? "Documents"
									: "Upload Documents"}
							</Typography>
							{documentListsFromRedux?.length > 0 ? (
								<EditDocuments
									documentsArray={documentsArray}
									setDocumentsArray={setDocumentsArray}
									documentsAPIList={documentsAPIList}
									setDocumentsAPIList={setDocumentsAPIList}
								/>
							) : (
								<AddDocuments
									documentsArray={documentsArray}
									setDocumentsArray={setDocumentsArray}
									documentsAPIList={documentsAPIList}
									setDocumentsAPIList={setDocumentsAPIList}
									type={"tasks"}
								/>
							)}
						</Div>
					</Div>
				</div>
				<Div
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 5,
					}}
				>
					<LoadingButton
						onClick={handleBackButton}
						//   disabled={values !== 0 ? false : true}
						variant="contained"
						sx={{
							width: 100,
							padding: 1,
							backgroundColor: "black",
							"&:hover": {
								backgroundColor: "#eabf4e",
								color: "black",
							},
						}}
					>
						Back
					</LoadingButton>
					<LoadingButton
						onClick={() => {
							formik.handleSubmit();
							console.log(formik.values);
							console.log(formik.errors);
						}}
						color="secondary"
						sx={{
							width: 100,
							padding: 1,
							backgroundColor: "black",
							"&:hover": {
								backgroundColor: "#eabf4e",
								color: "black",
							},
						}}
						variant="contained"
					>
						Continue
					</LoadingButton>
				</Div>
			</Div>
		</Div>
	);
};

export default ToDo;
