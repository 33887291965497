import { alpha } from "@mui/material/styles";

export const sidebarTheme = {
  type: "light",
  typography: {
    fontFamily: ["Poppins", "sans-serif"],

    //... other typography style in JSS
  },
  palette: {
    primary: {
      main: "#7352C7",
      light: "#A67FFB",
      dark: "#5E3BB7",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#E44A77",
      light: "#FF7EA6",
      dark: "#DF295E",
      contrastText: "#FFF",
    },
    error: {
      main: "#E73145",
      light: "#FF6A70",
      dark: "#AD001E",
      contrastText: "#FFF",
    },
    warning: {
      main: "#F39711",
      light: "#FFC84C",
      dark: "#BB6900",
      contrastText: "#FFF",
    },
    info: {
      main: "#2EB5C9",
      light: "#6FE7FC",
      dark: "#008598",
      contrastText: "#FFF",
    },
    success: {
      main: "#3BD2A2",
      light: "#78FFD3",
      dark: "#00A073",
      contrastText: "#FFF",
    },
    text: {
      primary: "#FFF",
      secondary: "#EABF4E",
      disabled: "#A2B2C3",
    },
    nav: {
      action: {
        active: "#151513",
        hover: "#151513",
      },
      background: {
        active: alpha("#EABF4E", 0.98),
        hover: "#FFFAEA",
      },
      tick: {
        active: "#151513",
        hover: "#151513",
      },
    },
    divider: "#EABF4E",
    background: {
      paper: "#151513",
      default: "#EABF4E",
    },
    action: {
      active: "#EABF4E",
      hover: "#F4F4F4",
    },
  },
};
