import Div from "@jumbo/shared/Div";
import React, { useState } from "react";
import { NoCaseContainer } from "../MyCases/style";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import { Button, Typography } from "@mui/material";
import { Alert, Snackbar} from "@mui/material";

function NoPeople({showAddPeople,setShowAddPeople}) {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleAddPeople = () => {
    setShowAddPeople(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <>
    {openSnackBar && (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        sx={{ zIndex: 1000, mt: 10 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
        >
          <Typography variant="h6">Added Successfully!</Typography>
          
        </Alert>
      </Snackbar>
    )}
    <NoCaseContainer>
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Div
            sx={{
              width: { xs: "60%", sm: "30%", md: "150px" },
              height: "30%",
            }}
          >
            <img
              src={NoCaseImage}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt=""
            />
          </Div>

          <Typography
            sx={{
              fontSize: { xs: "18px", md: "24px" },
              textAlign: "center",
              mt: 2,
            }}
          >
            No people found!
          </Typography>

          <Div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "secondary.main",
                mb: 2,
                width: "150px",
              }}
              onClick={handleAddPeople}
            >
              Add People
            </Button>
          </Div>
        </Div>
    
    </NoCaseContainer>
    </>
  );
}

export default NoPeople;
