import * as React from "react";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { DocumentValidations } from "app/utils/validators";
import AddDocuments from "app/Components/Documents/AddDocuments/AddDocuments";
import EditDocuments from "app/Components/Documents/EditDocuments/EditDocuments";
import { updateDocumentList } from "app/redux/actions/enquiry";
import { useDispatch, useSelector } from "react-redux";
import { createEnquiry } from "app/Apis/Enquiry";

const Document = ({
  handleBackButton,
  handleNextButton,
  enquiryDetails,
  caseId,
  setCaseDetails,
  setUpdate,
}) => {
  const documentsFromRedux = useSelector((state)=>state?.enquiry?.documents);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userDetailsFromRedux = useSelector((state) => state.onboardingInputs);
  const lawyer = {
    fullName: userDetailsFromRedux.fullName,
    lawyerId: userDetailsFromRedux.lawyerId,
    accessToken: userDetailsFromRedux.accessToken,
  };
  const dispatch = useDispatch();
  const handleAddDocument = async () => {
    try {
      const response = await createEnquiry({
        ...enquiryDetails,
        documentLists: documentsAPIList,
      });
      console.log(response);
      if (response) {
          dispatch(updateDocumentList(documentsAPIList));
        // setShowUpdate(!showUpdate);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const hiddenFileInput = React.useRef(null);

  const [documentsArray, setDocumentsArray] = React.useState(null);

  const [documentsAPIList, setDocumentsAPIList] = React.useState(
    documentsFromRedux.length>0 ? documentsFromRedux : enquiryDetails?.documentLists
  );

  const [documentUploaded, setDocumentUploaded] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      DocumentDescription: "",
      DocumentName: "",
    },

    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      handleAddDocument();
      handleNextButton();
    },
  });

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const files = event.target.files;
    const updatedDocuments = [...documentUploaded];

    for (let i = 0; i < files.length; i++) {
      updatedDocuments.push({
        url: URL.createObjectURL(files[i]),
        type: files[i].type,
        name: files[i].name,
      });
    }

    setDocumentUploaded(updatedDocuments);
  };

  const removeDocument = (deletedUrl) => {
    const newDocumentUploaded = documentUploaded.filter(
      (document) => document.url !== deletedUrl
    );

    setDocumentUploaded(newDocumentUploaded);
  };

  return (
    <div style={{ height: "100%", width: "100%", paddingBottom: "30px" }}>
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        <Typography variant="h6" sx={{ mt: "0px" }}>
          Upload Documents
        </Typography>
        {enquiryDetails?.documentLists?.length > 0 ? (
          <EditDocuments
            documentsArray={documentsArray}
            setDocumentsArray={setDocumentsArray}
            documentsAPIList={documentsAPIList}
            setDocumentsAPIList={setDocumentsAPIList}
          />
        ) : (
          <AddDocuments
            documentsArray={documentsArray}
            setDocumentsArray={setDocumentsArray}
            documentsAPIList={documentsAPIList}
            setDocumentsAPIList={setDocumentsAPIList}
            type={"cases"}
          />
        )}

        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            variant="contained"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
};

export default Document;
