import Div from "@jumbo/shared/Div";
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Typography,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";

import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CardFooter from "../components/CardFooter";
import TeamSkeletons from "../Teams/TeamSkeletons";
import HomeIcon from "@mui/icons-material/Home";
import { GavelOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import UpperSelectBar from "./UpperSelectBar";
import CaseList from "../MyCases/CaseList";
import {  Diversity3Outlined } from "@mui/icons-material";
import AssignCase from "./AssignCase";
import CaseCard from "./CaseCard";



const optionsPageSize = ["5", "10", "20", "25", "50"];
const CaseListCard = ({
  setUpdate,
  isLoading,
  allCaseList,
  pageSize,
  setPageSize,
  setPageNumber,
  totalPages,
  setIsLoading
}) => {

  const navigate = useNavigate();
  const [isAssignCase,setIsAssignCase] = useState(false);
  let caseIds = [];
  return (
  <React.Fragment>
    <Div
      sx={{
        width: "100%",
      }}
    >
    </Div>
    <Card
      sx={{
        p: 3,
        position: "relative",
        height: "100%"
      }}
    >
      <Div
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 5,
            }}
          >
            <Div sx={{   display: {xs:"none",sm:"flex"}, alignItems: "center" }}>
              <Div
                style={{
                  height: "19px",
                  width: "5px",
                  backgroundColor: "#EABF4E",
                  marginRight: "8px",
                }}
              >

              </Div>
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  lineHeight: 1.5,
                  fontWeight: 400,
                }}
              >
                All Cases
              </Typography>
            </Div>
            {!isAssignCase && (
            <Button
              style={{ fontWeight: "700" }}
              startIcon={<AddIcon />}
              color="secondary"
              // onClick={()=> navigate("/lawyers/cases?addCase=true")}
              onClick={()=> setIsAssignCase(true)}
            >
              Assign Cases
            </Button>
            )}
          </Div>
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {/* Search Bar */}
            <Div>
              <Select
                labelId="select-label"
                value={pageSize}
                sx={{ maxHeight: "40px", mr: "10px" }}
                onChange={(e) => {
                  setPageSize(e.target.value);
                }}
              >
                {optionsPageSize.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Div>
            {/* <Div sx={{ width: "100%" }}>
              <Button
                style={{ fontWeight: "600" }}
                startIcon={<FilterAltOutlinedIcon />}
                color="secondary"
                size="small"
              >
                Filter By
              </Button>
            </Div> */}
          </Div>
        </Div>
        <Divider sx={{ mt: 2 }} />
        {
          isAssignCase
          ?(<AssignCase setIsAssignCase={setIsAssignCase} allCaseList={allCaseList} setUpdate={setUpdate}/>)
          :(
        <Div sx={{ mb: 10 }}>
          {/* replace "5" with pageSize when implemented */}
          {isLoading ? (
            Array.from(Array(parseInt(pageSize))).map((item, index) => {
              return <TeamSkeletons key={index} />;
            })
          ) : allCaseList?.length > 0 ? (
            <>
              {allCaseList.map((team) => {
              if (team.isActive) {
                return (
                  <CaseCard
                    key={team.id}
                    item={team}
                    setUpdate={setUpdate}
                    caseIds={caseIds}
                    isAssignCase={false}
                    setIsLoading={setIsLoading}
                  />
                );
              }
            })}
            </>
          ) : (
            <Div
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Div
                sx={{
                  width: { xs: "60%", sm: "30%", md: "150px" },
                  height: "30%",
                }}
              >
                <img
                  src={NoCaseImage}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </Div>

              <Typography
                sx={{
                  fontSize: { xs: "18px", md: "24px" },
                  textAlign: "center",
                  mt: 2,
                }}
              >
                You haven't added any Records yet!
              </Typography>
              <Div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                {/* <Link to="/lawyers/addcases" style={{ textDecoration: "none" }}> */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "secondary.main",
                    mb: 2,
                    width: "150px",
                  }}
                  onClick={()=>setIsAssignCase(true)}
                >
                  Assign Case
                </Button>
                {/* </Link> */}

              </Div>
            </Div>
          )}
          <Div
            sx={{
              position: "absolute",
              bottom: 20,
              right: { sm: 0, lg: 25 },
            }}
          >
            <CardFooter
              setPageNumber={setPageNumber}
              totalPages={totalPages}
            />
          </Div>
        </Div>)
        }
      </Div>
    </Card>
  </React.Fragment>
  );
};

export default CaseListCard;
