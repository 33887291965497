import { getPaymentReport } from "app/Apis/Payment";
import Cookies from "js-cookie";

const usePayments = () => {
  const accessToken = Cookies.get("accessToken");
  const getPaymentsReports = async (filter=false) => {
    if (!accessToken) {
      throw Error("No access Token provided");
    }
    let response=null;
    if(filter){
     response = await getPaymentReport(accessToken, filter);
    }else{
        response = await getPaymentReport(accessToken);
    }
    let report=[0,0,0];
    console.log(report);
    if (response) {
        response.map((payment)=>{
          //[0] total payment requested
            report[0] +=payment?.total_lawyer_amount; 
            if(payment?.order_status==="Success"){
              //Received
                report[2]+=payment?.total_lawyer_amount
            }else{
              //Requested
                report[1]+=payment?.total_lawyer_amount;
            }
        })
      return report;
    } else {
      return null;
    }
  };
  return getPaymentsReports;
};
export default usePayments;