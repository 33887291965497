import {
  UPDATE_DOCUMENTS_LIST,
  UPDATE_NOTES_LIST,
  ENQUIRY_DETAILS_REST
} from "app/utils/constants/enquiry";

const initialState = {
  documents: [],
  notes: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DOCUMENTS_LIST:
      return {
        ...state,
        documents: action.payload,
      };
    case UPDATE_NOTES_LIST:
      return {
        ...state,
        notes: action.payload,
      };
    case ENQUIRY_DETAILS_REST:
      return {
        ...initialState
      }  
    default:
      return state;
  }
};
export default reducer;
