import React from "react";
import { Grid, Typography } from "@mui/material";
import HearingDashCard from "./HearingDashCard";
import Div from "@jumbo/shared/Div/Div";

const HearingDashList = ({ hearingList }) => {
  if (!hearingList) {
    return <Typography>No Tasks To display</Typography>;
  }
  console.log(hearingList)
  return (
    <Div sx={{ overflow: 'auto',height:{xs:"400px",sm:"980px"}, backgroundColor:"#f2f2f3"}}>
      <Grid container spacing={2} sx={{mt:0}}>


        {hearingList?.sort((a, b) => new Date(a.hearingDate) - new Date(b.hearingDate))?.map((item, index) => (
          <Grid item xs={12}>
        <HearingDashCard item={item} index={index} key={item?.paymentId} />

          </Grid>
        ))}
      </Grid>
    </Div>
  );
};

export default HearingDashList;
