import React, { useState, useEffect } from "react";
import { sendBlobToAPI, useRecorderPermission } from "../hook";
import { invokeSaveAsDialog } from "recordrtc";
import Div from "@jumbo/shared/Div/Div";
import { Button, Typography } from "@mui/material";
import StopOutlinedIcon from "@mui/icons-material/StopOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
export const AudioRecorderWidget = ({
  setDocumentListFromApi,
  documentListFromApi,
  setBigChunk,
  time,
  setTime,
  languageSelection
}) => {
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false)
  const [hasFinishRecording, setHasFinishRecording] = useState(false)
  const [allBlobSize, setAllBlobSize] = useState(0)
  const [stream, setStream] = useState(null)

  useEffect(() => {
    if (isRecording && !isPaused) {
      const interval = setInterval(() => {
        setTime((prevTime) => {
          const newSeconds = prevTime.seconds + 1;
          if (newSeconds === 60) {
            return { minutes: prevTime.minutes + 1, seconds: 0 };
          } else {
            return { ...prevTime, seconds: newSeconds };
          }
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isRecording,isPaused]);
  const recorder = useRecorderPermission(
    "audio",
    setRecordedChunks,
    setDocumentListFromApi,
    setAllBlobSize,
    setStream
  );
  useEffect(()=>{
    console.log(recorder)
    if(isPaused){
      recorder?.pauseRecording()
    } else {
      recorder?.resumeRecording()
    }
  },[isPaused])
  const startRecording = async () => {
    setIsRecording(true);
    recorder.startRecording();
  };
  const stopRecording = async () => {
    await recorder.stopRecording();
    let blob = await recorder.getBlob();
    setBigChunk(blob)
    setRecordedChunks([blob]);
    setIsRecording(false);
    console.log("duration",blob);
    setHasFinishRecording(true)
  };
  return (
    <Div
      style={{
        // height: "100%",
        width: "100%",
        paddingBottom: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Div>
        {documentListFromApi.length > 0 && (
          <Div
            sx={{
              width: "100%",
              marginY: "1rem",
            }}
          >
            {recordedChunks.map((chunk, index) => {
              return (
                <Div
                  sx={{
                    width: "100%",
                    marginY: "0.5rem",
                  }}
                  key={index}
                >
                  <audio controls src={URL.createObjectURL(chunk)} />
                </Div>
              );
            })}
          </Div>
        )}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginY: "1rem",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "2.25rem",
              lineHeight: 1.5,
              fontWeight: 400,
              marginRight: "5px",
            }}
          >
            {" "}
            {time.minutes < 10 ? "0" + time.minutes : time.minutes}:
            {time.seconds < 10 ? "0" + time.seconds : time.seconds}
          </Typography>
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginY: "1rem",
            alignItems: "center",
          }}
        >
          {isRecording ? (
            <>
              {isPaused ? (
                <Button
                  variant="contained"
                  onClick={()=>setIsPaused(false)}
                  sx={{ height: "fit-content", marginRight:"5px"}}
                >
                  <PlayCircleFilledWhiteOutlinedIcon sx={{marginRight:1}} />
                  Resume
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={()=>setIsPaused(true)}

                  sx={{ height: "fit-content", marginRight:"5px" }}
                >
                  <PauseCircleOutlineOutlinedIcon sx={{marginRight:1}} />
                  Pause
                </Button>
              )}
              <Button
                variant="contained"
                onClick={stopRecording}
                sx={{ height: "fit-content", backgroundColor:"red"  }}
              >
                <StopOutlinedIcon sx={{marginRight:1}} />
                Stop recording
              </Button>
            </>
          ) : (
            (languageSelection && !hasFinishRecording )?
            <Button
              variant="contained"
              onClick={startRecording}
              sx={{ height: "fit-content" }}
            >
              <MicNoneOutlinedIcon sx={{marginRight:1}} />
              Start recording
            </Button> :
            <Button
            variant="contained"
            disabled
            onClick={startRecording}
            sx={{ height: "fit-content" }}
          >
            <MicNoneOutlinedIcon sx={{marginRight:1}} />
            Start recording
          </Button>
          )}
        </Div>
      </Div>
    </Div>
  );
};
