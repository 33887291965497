import { LAYOUT_CONTAINER_STYLES } from "@jumbo/utils/constants/layout";
import { mainTheme } from "../themes/main/default";
import { headerTheme } from "../themes/header/default";
import { sidebarTheme } from "../themes/sidebar/default";
import { footerTheme } from "../themes/footer/default";
import LAYOUT_NAMES from "../layouts/layouts";
import { createJumboTheme } from "@jumbo/utils";
import jwtAuthAxios from "../services/auth/jwtAuth";
import authServices from "app/services/auth-services";

const config = {
  // HorizontalLayout: LAYOUT_NAMES.HORIZONTAL_LAYOUT,
  defaultLayout: LAYOUT_NAMES.DEFAULT,
  containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,

  authSetting: {
    axiosObject: jwtAuthAxios,
    fallbackPath: "/user/login",
    getAuthUserService: authServices.getCurrentUser,
    redirectNotAuthenticatedPath: "/dashboards/misc",
  },

  theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),
};

export { config };
