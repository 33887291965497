import { ASSET_IMAGES } from "app/utils/constants/paths";
import React from "react";
import "./chatbot.css";
import PersonIcon from "@mui/icons-material/Person";

function Chatblock({ type, content, isResponseLoaded }) {
	if (type === "chatbotLoading") {
		return (
			<div
				className=""
				style={{
					padding: "1.5rem 1rem",
					borderBottom: ".5px solid rgba(130, 132, 153, 1)",
					backgroundColor: "rgba(67, 70, 84, 1)",
				}}
			>
				<div
					className=""
					style={{
						width: "100%",
						maxWidth: "800px",
						margin: "0  auto",
						display: "flex",
						alignItems: "center",
						gap: "40px",
					}}
				>
					<div
						className=""
						style={{
							minWidth: "2.5rem",
							width: "2.5rem",
							aspectRatio: "1/1",
							borderRadius: "5px",
							backgroundColor: "#eabf4e",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img
							src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
							style={{ width: "100%" }}
							alt=""
							srcset=""
						/>
					</div>
					<h3
						style={{
							margin: "0",
							fontWeight: "normal",
							position: "relative",
							top: "-1px",
						}}
					>
						{!isResponseLoaded && <div className="dot-pulse"></div>}
					</h3>
				</div>
			</div>
		);
	}
	if (type === "user") {
		return (
			<div
				className=""
				style={{
					display: "flex",
					justifyContent: "flex-end",
					padding: "1.5rem 1rem",
					borderBottom: ".5px solid rgba(130, 132, 153, 1)",
				}}
			>
				<div
					className=""
					style={{
						display: "flex",
						alignItems: "flex-start",
						gap: "10px",
					}}
				>
					<h3
						style={{
							margin: "0",
							fontWeight: "normal",
							position: "relative",
							top: "-1px",
						}}
					>
						{content}
					</h3>
					<div
						className=""
						style={{
							minWidth: "2.5rem",
							width: "2.5rem",
							aspectRatio: "1/1",
							borderRadius: "5px",
							backgroundColor: "#eabf4e",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}
					>
						<PersonIcon />
					</div>
				</div>
			</div>
		);
	}
	if (type === "chatbot") {
		return (
			<div
				className=""
				style={{
					padding: "1.5rem 1rem",
					borderBottom: ".5px solid rgba(130, 132, 153, 1)",
					backgroundColor: "rgba(67, 70, 84, 1)",
				}}
			>
				<div
					className=""
					style={{
						width: "100%",
						maxWidth: "800px",
						margin: "0  auto",
						display: "flex",
						alignItems: "flex-start",
						gap: "10px",
					}}
				>
					<div
						className=""
						style={{
							minWidth: "2.5rem",
							width: "2.5rem",
							aspectRatio: "1/1",
							borderRadius: "5px",
							backgroundColor: "#eabf4e",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img
							src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
							style={{ width: "100%" }}
							alt=""
							srcset=""
						/>
					</div>
					<div className="">
						<h3
							style={{
								margin: "0",
								fontWeight: "normal",
								position: "relative",
								top: "-2.5px",
							}}
						>
							{content.split("^link")[0] ? "" : content.split("^link")[0]}
						</h3>
						<h3
							style={{
								margin: 0,
								marginTop: "5px",
								fontWeight: "normal",
								position: "relative",
								top: "-2.5px",
							}}
						>
							{content.split("^link")[0]
								? content.split("^link")[0]
								: content.split(". ")[1]}
						</h3>
						<a
							href={content.split("^link")[1]}
							target="_blank"
							style={{ color: "white", display: "inline" }}
						>
							<h3
								style={{
									margin: "0",
									fontWeight: "normal",
									position: "relative",
									top: "-2.5px",
								}}
							>
								{content.split("^link")[1]}
							</h3>
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default Chatblock;
