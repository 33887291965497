import {
    Card,
    CardContent,
    Typography,
  } from "@mui/material";
 


const PageNotFound = ()=>{
    return (
        <Card
          sx={{
            backgroundColor: "white",
            height: "100%",
            position: "relative",
            paddingTop: "10px",
          }}
        >
            <CardContent
                sx={{
                    height:"100%",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    flexDirection:"column"
                }}
            >
                <Typography 
                    variant="h1"
                    sx={{
                        fontSize:"10rem",
                        fontWeight:"900",
                        color:"#EABF4E"
                    }}
                >
                    404 
                </Typography>
                <Typography 
                    variant="h3"
                    sx={{
                        textAlign:"center"
                    }}
                >
                   Oops!!! Page Not Found...
                </Typography>
            </CardContent>
        </Card>    
    )
}
export default PageNotFound;