import React, { useState } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";

function AddressMap({markerPosition}) {
  console.log("map marker position",markerPosition)
  const [map, setMap] = useState(null);
  
  const [markerAddress, setMarkerAddress] = useState('');

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAhUg40iVr0KQrBm3lxQWXJWNDla8Ap4UI",
    libraries:['places']
  });


  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={markerPosition? markerPosition:{ lat: 28.679079, lng: 77.06971 }}
          zoom={markerPosition?16:12}
          options={{
            zoomControl:false,
            streetViewControl:false,
            mapTypeControl:false,
            fullscreenControl:false
          }}
          onLoad={(map) => setMap(map)}
          // onClick={(e) =>
          //   setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          // }
        >
          {markerPosition && (
            <Marker
              position={markerPosition}
            />
          )}
        </GoogleMap>
      )}
    </>
  );
}

export default AddressMap;