import React, { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div/Div";
import EditIcon from "@mui/icons-material/Edit";
import { updateBankSettings } from "app/Apis/settings";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from "@mui/material";
import { Button } from "@mui/material";

const BankCard = ({
  setCardOpen,
  Bank,
  name,
  setEdiFormOpen,
  initSettings,
  setInitSettings,
  setFormOpen,
  setSnackBarMessage,
  setOpenSnackBar,
}) => {
  const lawyerId = useSelector((state) => {
    return state?.onboardingInputs?.lawyerId;
  });
  const accessToken = useSelector((state) => {
    return state?.onboardingInputs?.accessToken;
  });
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);

  const handleDelete = () => {
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    console.log(initSettings);
    console.log(lawyerId);
    console.log(accessToken);
    const response = await updateBankSettings(
      initSettings,
      { ...initSettings?.paymentDetails, isActive: 0 },
      lawyerId,
      accessToken
    );
    console.log(response);
    setInitSettings(response);
    setCardOpen(true);
    setModalOpen(false);
    setFormOpen(true);
    setOpenSnackBar(true);
    setSnackBarMessage("Deleted Successfully!");
  };

  const handleEdit = () => {
    // setShowForm(true)
    // setValue(1)
  };

  return (
    <Div
      alignItems="flex-start"
      sx={{
        // mt: 1,
        p: 1,
        // py: 1,
        // minHeight: "150px",
        border: "1px solid rgb(232, 232, 232)",
        borderRadius: "8px",

        width: "50%", 
        "@media (max-width: 600px)": {
          width: "100%"
        }

      }}
    >
      {/* <Div
        sx={{
          display: "flex",
          borderBottom: "1px solid",
          borderColor: theme.palette.divider,
          pb: 1,
          mb: 1,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          color="black"
          sx={{
            fontSize: "1rem",
            mb: "1",
            fontWeight: 500,
            color: "rgb(79,79,79)",
            textTransform: "capitalize",
            width: "100%",
          }}
        >
          {name}
        </Typography>
        <EditIcon
          sx={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => setEdiFormOpen(true)}
        />
        <DeleteIcon
          sx={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleDelete}
        />
      </Div> */}
      {name === "UPI ID" && (
        <>
        <Div
        sx={{
          display: "flex",
          borderColor: theme.palette.divider,
          alignItems: "center",
        }}
      >
      
       <Typography
          variant="h3"
          color="black"
          sx={{
            fontSize: "1rem",
            mb: "1",
            fontWeight: 500,
            color: "rgb(79,79,79)",
            textTransform: "capitalize",
            width: "100%",
          }}
        >
          {name} : <Typography mb={1}
              fontSize="0.875rem"
              fontFamily="NoirPro, Arial"
              fontWeight="400"
              lineHeight="1.5"
              color="rgb(133, 149, 166)"
              display={"inline-block"}
              textTransform="lowercase" >
          {Bank?.upiId}
          </Typography>
        </Typography>
     
        <EditIcon
          sx={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => setEdiFormOpen(true)}
        />
        <DeleteIcon
          sx={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleDelete}
        />
      </Div>
        
        </>
      )}
      {name === "Bank Account" && (
        <>
         <Div
        sx={{
          display: "flex",
          borderBottom: "1px solid",
          borderColor: theme.palette.divider,
          pb: 1,
          mb: 1,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          color="black"
          sx={{
            fontSize: "1rem",
            mb: "1",
            fontWeight: 500,
            color: "rgb(79,79,79)",
            textTransform: "capitalize",
            width: "100%",
          }}
        >
          {name}
        </Typography>
        <EditIcon
          sx={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => setEdiFormOpen(true)}
        />
        <DeleteIcon
          sx={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleDelete}
        />
      </Div>
          <Div sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              mb={1}
              fontSize="0.875rem"
              fontFamily="NoirPro, Arial"
              fontWeight="400"
              lineHeight="1.5"
              color="rgb(133, 149, 166)"
              display={"inline-block"}
            >
              Payee Name : {Bank?.payeeName}
            </Typography>
            <Typography
              mb={1}
              fontSize="0.875rem"
              fontFamily="NoirPro, Arial"
              fontWeight="400"
              lineHeight="1.5"
              color="rgb(133, 149, 166)"
              display={"inline-block"}
            >
              Bank Name : {Bank?.bankName}
            </Typography>
            <Typography
              mb={1}
              fontSize="0.875rem"
              fontFamily="NoirPro, Arial"
              fontWeight="400"
              lineHeight="1.5"
              color="rgb(133, 149, 166)"
              display={"inline-block"}
            >
              Bank Account Number : {Bank?.accountNumber}
            </Typography>
            {/* <Typography
              mb={1}
              fontSize="0.875rem"
              fontFamily="NoirPro, Arial"
              fontWeight="400"
              lineHeight="1.5"
              color="rgb(133, 149, 166)"
              display={"inline-block"}
            >
              Confirm Bank Account Number : {Bank?.bankName}
            </Typography> */}

            <Typography
              mb={1}
              fontSize="0.875rem"
              fontFamily="NoirPro, Arial"
              fontWeight="400"
              lineHeight="1.5"
              color="rgb(133, 149, 166)"
              display={"inline-block"}
            >
              Branch Name : {Bank?.branchName}
            </Typography>
            <Typography
              mb={1}
              fontSize="0.875rem"
              fontFamily="NoirPro, Arial"
              fontWeight="400"
              lineHeight="1.5"
              color="rgb(133, 149, 166)"
              display={"inline-block"}
            >
              Branch Address : {`${Bank?.branchAddress} `}
            </Typography>
            <Typography
              mb={1}
              fontSize="0.875rem"
              fontFamily="NoirPro, Arial"
              fontWeight="400"
              lineHeight="1.5"
              color="rgb(133, 149, 166)"
              display={"inline-block"}
            >
              IFSC Code : {`${Bank?.ifscCode}   `}
            </Typography>
            
          </Div>
        </>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            minWidth: 300,
            maxWidth: 500,
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" id="delete-modal-title" gutterBottom>
            Are you sure you want to delete this bank account?
          </Typography>
          <Typography variant="body1" id="delete-modal-description">
            This action cannot be undone.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={confirmDelete}
              sx={{ mr: 2 }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Div>
  );
};

export default BankCard;