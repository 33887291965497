import UnauthorisedAccessPage from 'app/pages/404';
import { useLocation,Navigate,Outlet } from 'react-router-dom';
import Page from "@jumbo/shared/Page";
import Cookies from 'js-cookie';
import useLogin from 'app/hooks/useLogin';



const RequireAuth = ({allowedRole})=>{
    const login = useLogin(false);
    const accessToken  = Cookies.get('accessToken');
    const role = Cookies.get("assignedRole");
    const isLoggedIn = Cookies.get("isLoggedIn");
    console.log(role);
    console.log(allowedRole);
    const location = useLocation();
    if(!isLoggedIn){
        return <Navigate to="/" state={{from: location}} replace/>
    }
    if(isLoggedIn && role!==allowedRole){
        login(accessToken);
        return <Page component={UnauthorisedAccessPage} />
    }
    if(isLoggedIn && role===allowedRole && accessToken){
        login(accessToken);
    }

    return (
        <Outlet/>
    )
}
export default RequireAuth;