import * as React from "react";
import Div from "@jumbo/shared/Div";
import { useEffect } from "react";
import { Breadcrumbs, CardContent, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { Card } from "@mui/material";
import HearingsPayment from "./HearingsPayment";
import { PaymentsOutlined } from "@mui/icons-material";
import { getCaseByCaseId } from "app/Apis/case";
import { useSelector } from "react-redux";

const HearingPayments = ({ setAddHearing }) => {
  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );
 
  const { caseId } = useParams();
  const [caseDetails, setCaseDetails] = React.useState(null);
  const [clients,setClients] = React.useState(null);


  useEffect(() => {
    const getCaseDetails = async () => {
      const response = await getCaseByCaseId(accessToken, caseId);
      console.log(response);
      if (response) {
        setCaseDetails(response[0]);
        setClients(response[0].clients);
      } else {
        console.log("Error fetching case details");
      }
    };
    getCaseDetails();
  }, []);

  const [value, setValue] = React.useState(0);
  const [buttonValue, setButtonValue] = React.useState(0);

  console.log("button is ", buttonValue);
  console.log("value rn is", value);

  const handleBackButton = () => {
    if (value !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValue((prevButtonValue) => prevButtonValue - 1);
    }
  };



  return (
    <Div sx={{ height: "100%", width: "100%" }}>
      
      <Div sx={{ mb: 2}}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} fontSize="inherit" />
            Home
          </Typography>

          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <PaymentsOutlined sx={{ fontSize: 20 }} />
            &nbsp; Payments
          </Typography>
        </Breadcrumbs>
      </Div>

      <Card
        sx={{
          backgroundColor: "white",
          height: "100%",
        }}
      >
        <CardContent>
          <Div
            sx={{
              height: "100%",
              position: "relative",
              paddingTop: "10px",
            }}
          >
            <Div
              sx={{
                width: "100%",
                position: "relative",
                height: "fit-content",
                mt: -2,
                mb: 8,
              }}
            >
              <Div
                sx={{
                  position: "absolute",

                  borderBottom: "1px solid #D3D3D3 ",
                  width: "100%",
                  pt: 0,
                  pb: 2,
                  display: "flex",
                  justifyContent: "start",
                  alignItems:"center"
                }}
              >
                
                <Div
                  style={{
                    height: "19px",
                    width: "5px",
                    backgroundColor: "#EABF4E",
                    marginRight: "8px",
                  }}
                ></Div>
                  <Typography variant="h5" color="text.primary">
                    Payments
                  </Typography>
              
              </Div>
            </Div>

            <Div
              sx={{
                pl: { sm: "0", lg: "11%" },
                pr: { sm: "0", lg: "11%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <HearingsPayment
                setAddHearing={setAddHearing}
                clients={clients}
                caseId={caseId}
                caseName={`${caseDetails?.caseFirstName} vs ${caseDetails?.caseSecondName}`}
              />
            </Div>
          </Div>
        </CardContent>
      </Card>
    </Div>
  );
};

export default HearingPayments;
