import React, { useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import {Tabs} from "@mui/material";
import { LawyerProfileUpdate, getCourt, getEdu, getLang, getLawyerState, getSpec } from "app/Apis/User";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  UserBarCouncil,
  UserLawyerEducation,
  UserLawyerLanguage,
  UserLawyerSpecialisation,
  UserPracticeCourts,
  UserPracticeStates,
} from "app/redux/actions/UpdatedInputs";
import {
  AvatarGroup,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material';
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";
import { ASSET_AVATARS } from "app/utils/constants/paths";
import { getAssetPath } from "app/utils/appHelpers";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import { useDispatch, useSelector } from "react-redux";

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: "50%",
  minWidth: 42,
  marginRight: 16,
  padding: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
  border: `solid 1px ${theme.palette.divider}`,
}));
const Education = ({details,dataEdit,setDataEdit,submit,setShowUpdate,setOpenSnackBar}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [education,setEducation] = useState([]);
  const [lang,setLang] = useState([]);
  const [Language,setLanguage] = useState([]);
  const [Edu,setEdu] = useState([]);
  const [languageError, setLanguageError] = useState(false);
  const [educationError, setEducationError] = useState(false);
  const [error, setError] = useState(false);

  useEffect(()=>{
    if(dataEdit){
      setEducation(details?.professionalProfile?.lawyerEducations)
      setLang(details?.professionalProfile?.lawyerLanguage)
    }
  
  },[dataEdit])

  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );
 
  const getEduc = async()=>{
    const edu = await getEdu();
    if(!edu){
      console.log("error while getting edu list",edu);
    }
    // console.log(edu);
    setEdu(edu);
  }
  const getLanguage = async()=>{
    const lang = await getLang();
    if(!lang){
      console.log("error while getting lang list",lang);
    }
    // state.shift();
    // console.log(lang);
    setLanguage(lang);
  }
  useEffect(()=>{
    const getInitValues = async()=>{
      await getEduc();
      await getLanguage();
    }
    getInitValues();
  },[]);

  const Incrementfn = async () => {
    let educ = [];
    let lan = [];
    if (education.length === 0) {
      setError(true);
      setEducationError(true);
    }
    else{
      education.map((ele)=>{
        educ.push({
          educationId:ele.educationId
        })
      })
      console.log(educ);
    }
    if (lang.length === 0) {
      setError(true);
      setLanguageError(true);
    } else {
      lang.map((ele)=>{
        lan.push({
          languageId:ele.languageId
        })
      })
      console.log(lan);
      setError(false);
      setEducationError(false);
      setLanguageError(false);
    let obj = {
      barCouncilRegistrationNo:details?.professionalProfile?.barCouncilRegistrationNo,
      practiceStates:details?.professionalProfile?.practiceStates,
      practiceCourts:details?.professionalProfile?.practiceCourts,
      practiceDistricts:details?.professionalProfile?.practiceDistricts,
      lawyerEducations:education,
      lawyerSpecialisation:details?.professionalProfile?.lawyerSpecialisation,
      lawyerLanguage:lang
    }
      console.log(obj)

    const response = await LawyerProfileUpdate(accessToken, obj);

    if (response) {
      console.log("lawyer data is ", response);
      setDataEdit(false)
      setShowUpdate(true)
      // setOpenSnackBar(true)
      // navigate("/onboardingsuccess");
      console.log(response)
      // navigate("/comingSoon");
      // console.log("onboarding")
    } else {
      console.log("something went wrong!");
    }
  //   // navigate("/onboardingsuccess");
   }
  }

  useEffect(()=>{
    if (submit) {
      Incrementfn();
    }
  },[submit])
    return(
<List
        disablePadding
        sx={{
          display: "flex",
          flexWrap: "wrap",
          margin: (theme) => theme.spacing(-2, -2),
        }}
      >
        {/* <ListItem
          sx={{
            width: { xs: "100%", sm: "50%", xl: "33.33%" },
          }}
        >
          <StyledListItemIcon>
            <AccountBalanceIcon fontSize={"inherit"} />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography
                fontSize={"12px"}
                variant="h6"
                color="text.secondary"
                mb={0.5}
              >
                Bar Council Number
              </Typography>
            }
            secondary={
              <Typography variant="body1" color="text.primary">
                {details?.professionalProfile?.barCouncilRegistrationNo}
              </Typography>
            }
          />
        </ListItem> */}

        {/* <List>
      <Typography
                fontSize={"12px"}
                variant="h6"
                color="text.secondary"
                mb={0.5}
              >
                Practice Court
              </Typography>
        {details?.professionalProfile?.practiceCourts.map((court, key) => {
          return <>
          <ListItem key = {key}>
            <ListItemText primary={court.courtName} />
          </ListItem>
          </>
        })}
      </List> */}

<ListItem
  sx={{
    width: { xs: "100%", sm: "52%", xl: "50.33%", alignItems: "flex-start" },
  }}
>
  <StyledListItemIcon>
    <SchoolIcon fontSize="inherit"
      sx={{
        color: theme.palette.primary.main,
      }} />
  </StyledListItemIcon>
  {dataEdit?  <Div>
          <Autocomplete
            style={{ width: "400px" }}
            value={education}
            onChange={(event, value) => setEducation(value)}
            multiple
            disableCloseOnSelect
            // id="checkboxes-tags-demo"
            options={Edu}
            // disableCloseOnSelect
            getOptionLabel={(option) =>{return option.educationName}}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.educationName}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px" ,backgroundColor:"white"}}
                {...params}
                error={educationError}
                label="Select Education"
                placeholder="Education"
                required
                color="secondary"
                onBlur={() =>
                  education.length > 0
                    ? setEducationError(false)
                    : setEducationError(true)
                }
              />
            )}
          />
          {educationError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )}
        </Div>:
  <ListItemText
    primary={
      <Typography
        fontSize="12px"
        variant="h6"
        color="text.secondary"
        mb={0.5}
      >
        Education
      </Typography>
    }
    secondary={
      <Typography variant="body1" color="text.primary">
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          {details?.professionalProfile?.lawyerEducations.slice(0, 2).map(
            (lawyer, key) => (
              <Chip
                key={key}
                label={lawyer.educationName}
                sx={{
                  maxWidth: { xs: "100%", sm: "65%" },
                }}
              />
            )
          )}
          {details?.professionalProfile?.lawyerEducations.length > 2 && (
            <Chip
              label={`+${details?.professionalProfile?.lawyerEducations.length - 2}`}
              sx={{
                maxWidth: { xs: "100%", sm: "65%" },
              }}
            />
          )}
        </Stack>
      </Typography>
    }
  />}
</ListItem>

<ListItem
  sx={{
    width: { xs: "100%", sm: "52%", xl: "50.33%", alignItems: "flex-start" },
  }}
>
  <StyledListItemIcon>
    <LanguageIcon fontSize="inherit"
      sx={{
        color: theme.palette.primary.main,
      }} />
  </StyledListItemIcon>
  {dataEdit? <Div>
          <Autocomplete
            style={{ width: "400px" }}
            multiple
            disableCloseOnSelect
            value={lang}
            onChange={(event, value) => setLang(value)}
            // id="checkboxes-tags-demo"
            options={Language}
            // disableCloseOnSelect
            getOptionLabel={(option) => {return option.languageName}}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.languageName}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px" ,backgroundColor:"white"}}
                {...params}
                label="Language"
                placeholder="Select Language"
                required
                color="secondary"
                error={languageError}
                onBlur={() =>
                  lang.length > 0
                    ?setLanguageError(false)
                    : setLanguageError(true)
                }
              />
            )}
          />
          {languageError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )}
        </Div>:
  <ListItemText
    primary={
      <Typography
        fontSize="12px"
        variant="h6"
        color="text.secondary"
        mb={0.5}
      >
        Languages
      </Typography>
    }
    secondary={
      <Typography variant="body1" color="text.primary">
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          {details?.professionalProfile?.lawyerLanguage.slice(0, 2).map(
            (lang, key) => (
              <Chip key={key} label={lang.languageName} sx={{
                maxWidth: { xs: "100%", sm: "65%" },
              }} />
              
            )
          )}
          {details?.professionalProfile?.lawyerLanguage.length > 2 && (
            <Chip
              label={`+${details?.professionalProfile?.lawyerLanguage.length - 2}`}
              sx={{
                maxWidth: { xs: "100%", sm: "65%" },
              }}
            />
          )}
        </Stack>
      </Typography>
    }
  />}
</ListItem>
  
      </List>
      );
}

export default Education;