import React from 'react';
import {Typography} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "./TimelineItem";

const TimelineList = ({keys,notesObject,title}) => {


    return (
      <React.Fragment>
        <Typography variant={"h5"} color={"text.secondary"} mb={2}>
          {title}
        </Typography>
        <Timeline
          sx={{
            m: 0,
            p: 0,
          }}
        >
          {keys?.map((item, index) => (
            <TimelineItem
              index={index}
              key={item}
              date={item}
              notesObject={notesObject}
            />
          ))}
        </Timeline>
      </React.Fragment>
    );
};

export default TimelineList;
