import { useCallback, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

const TOOLBAR_OPTIONS = [
	[{ header: [1, 2, 3, 4, 5, 6, false] }],
	[{ font: [] }],
	[{ list: "ordered" }, { list: "bullet" }],
	["bold", "italic", "underline"],
	[{ color: [] }, { background: [] }],
	[{ script: "sub" }, { script: "super" }],
	[{ align: [] }],
	["image", "blockquote", "code-block"],
	["clean"],
];

export default function DraftEditor3({ setHtml }) {
	const wrapperRef = useCallback((wrapper) => {
		if (wrapper == null) return;

		wrapper.innerHTML = "";
		const editor = document.createElement("div");
		wrapper.append(editor);
		const q = new Quill(editor, {
			theme: "snow",

			modules: { toolbar: TOOLBAR_OPTIONS },
		});
		q.on("text-change", () => {
			const cfg = {};
			let converter = new QuillDeltaToHtmlConverter(q.getContents().ops, cfg);
			const html = converter.convert();
			setHtml(html);
		});
	}, []);
	return <div className="container" ref={wrapperRef}></div>;
}
