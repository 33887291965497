import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ProductSaleItem from "./ProductSaleItem";
import styled from "@emotion/styled";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const ProductSalesList = ({ products, paymentsList }) => {
  return (
    <JumboScrollbar
      autoHeight={true}
      autoHideTimeout={4000}
      autoHeightMin={350}
      autoHide={true}
      hideTracksWhenNotNeeded
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "left",
                width: "10%",
                
              }}
            >
              S.No
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 2,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
              Case Name
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
              Client Name
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
                Date
            </StyledTableCell>
            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
              Amount
            </StyledTableCell>

            <StyledTableCell
              sx={{
                pl: 3,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "center",
                width: "16%",
              }}
            >
              Status
            </StyledTableCell>
            {/* <StyledTableCell
              sx={{ pl: 3, fontSize: "1rem", fontWeight: 600, width: "16%" }}
              align={"center"}
            >
              Remaning
            </StyledTableCell> */}
            {/* <StyledTableCell
              sx={{ pr: 3, fontSize: "1rem", fontWeight: 600, width: "10%" }}
              align={"right"}
            >
              Action
            </StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentsList &&
            paymentsList.map((payment, index) => (
              <ProductSaleItem
                paymentsList={paymentsList}
                item={payment}
                key={index}
                index={index}
              />
            ))}
        </TableBody>
      </Table>
      
    </JumboScrollbar>
  );
};
/* Todo products prop define */
export default ProductSalesList;
