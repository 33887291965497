import React from "react";

import HearingDashCard from "./hearingDCard";

const HearingDashList = ({
  hearingList,
  showUpdate,
  setShowUpdate,
}) => {
  return (
    <React.Fragment>
      {hearingList &&
        hearingList.map((hearing, index) => {
          if (hearing.isActive) {
            return (
     <HearingDashCard
     Key={hearing.id}
                item={hearing}
                hearingList={hearingList}
                showUpdate={showUpdate}
                setShowUpdate={setShowUpdate}
     />
            );
          }
        })}
    </React.Fragment>
  );
};

export default HearingDashList;
