import Div from "@jumbo/shared/Div";
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import TeamList from "./TeamList";
import CardFooter from "../components/CardFooter";
import TeamSkeletons from "./TeamSkeletons";
import HomeIcon from "@mui/icons-material/Home";
import { GavelOutlined, Diversity3Outlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";
import TeamLawyerList from "./TeamLawyerList";


const LawyerListLayout = ({teamList,loading}) => {

  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      <Link
            href="/home"
            underline="none"
          >
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
          Home
        </Typography>
        </Link>

        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Diversity3Outlined
            sx={{ fontSize: 20, mr: 0.5 }}
            fontSize="inherit"
          />
       My teams
        </Typography>
        {/* <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Diversity3Outlined
            sx={{ fontSize: 20, mr: 0.5 }}
            fontSize="inherit"
          />
     My Teams
        </Typography> */}
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Typography sx={{display: {xs:"none",sm:"flex"}}} variant={"h5"}>My Teams</Typography>
              {/* Add New */}
              <Link style={{ textDecoration: "none" }} to="/lawyers/addlawyer">
                <Button
                  style={{ fontWeight: "700" }}
                  startIcon={<AddIcon />}
                  color="secondary"
                >
                  Add Lawyer
                </Button>
              </Link>
            </Div>
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,

              }}
            >
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          {loading ? (
          Array.from(Array(parseInt("5"))).map((item, index) => {
            return <TeamSkeletons key={index} />;
          })
        ) : teamList.length > 0 && !loading ? 
          <>
            <TeamLawyerList teamList={teamList}/>
      </>:
     <Div
     sx={{
       display: "flex",
       flexDirection: "column",
       height: "100%",
       justifyContent: "center",
       alignItems: "center",
     }}
   >
     <Div
       sx={{ width: { xs: "60%", sm: "30%", md: "150px" }, height: "30%" }}
     >
       <img
         src={NoCaseImage}
         style={{ width: "100%", height: "100%", objectFit: "contain" }}
         alt=""
       />
     </Div>
     <Typography
       sx={{
         fontSize: { xs: "18px", md: "24px" },
         textAlign: "center",
         mt: 2,
       }}
     >
       You haven't added any Lawyers yet!
     </Typography>

     <Div
       sx={{
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "center",
         mt: 4,
       }}
     >
       <Link style={{ textDecoration: "none" }} to="/lawyers/addlawyer">
     
         <Button
         // onClick={()=>setAddLawyer(true)}
           variant="contained"
           sx={{
             backgroundColor: "secondary.main",
             mb: 2,
             width: "150px",
           }}
         >
           Add Lawyer
         </Button>
       </Link>
     </Div>
   </Div>
        }
         
        </Div>
      </Card>
    </React.Fragment>
  );
};

export default LawyerListLayout;
