import React, { useEffect, useState } from "react";
import {
	Autocomplete,
	Button,
	Checkbox,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Div from "@jumbo/shared/Div";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { CaseValidations } from "app/utils/validators";
import { getCourt } from "app/Apis/User";
import { useDispatch, useSelector } from "react-redux";
import {
	updateCaseId,
	updateCaseType,
	updateCaseNumber,
	updateGRNo,
	updateFIRNo,
	updateCourt,
	updateAssignLawyer,
	updateCaseDescription,
	updateUnderSection,
	updateCaseFirstName,
	updateCaseSecondName,
	updateIsPetitioner,
	updateCaseResponse,
} from "../../redux/actions/dashboard";
import { updateCaseListItem } from "app/redux/actions/case";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { getTeamList } from "app/Apis/teams";
import Cookies from "js-cookie";
import { AccountCircle } from "@mui/icons-material";
import { ErrorOutline } from "@material-ui/icons";
// const teamLawyerList = ["Ashish", "Rahul", "Ram", "Rocky"];

function Case({ handleBackButton, handleNextButton, setIsLoading }) {
	const [teamList, setTeamList] = useState([]);
	const lawyerId = Cookies.get("lawyerId");
	const location = useLocation();
	const queryParameters = new URLSearchParams(location.search);
	const teamMemberLawyerId = queryParameters.get("lawyerId");
	useEffect(() => {
		const obj = teamList.find((obj) => obj.lawyerId === teamMemberLawyerId);
		if (obj) {
			setTeamLawyer((prev) => {
				if (prev) {
					return [...prev, obj];
				}
				return [obj];
			});
		}
	}, [teamMemberLawyerId, teamList]);
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const navigate = useNavigate();
	useEffect(() => {
		const fetchCaseTypes = async () => {
			try {
				const response = await fetch(
					"https://erp.lawinzo.com/api/v1/caseType",
					{
						headers: {
							"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
						},
					}
				);
				const data = await response.json();
				console.log(data);
				if (data.success) {
					setCaseTypes(data.data.caseTypeRecords);
					console.log(caseType);
					console.log(caseTypes);
				}
			} catch (error) {
				console.error("Failed to fetch case types:", error);
			}
		};

		fetchCaseTypes();
	}, []);

	useEffect(() => {
		const fetchUnderSections = async () => {
			try {
				const response = await fetch("https://erp.lawinzo.com/api/v1/section", {
					headers: {
						"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
					},
				});
				const data = await response.json();
				if (data.success) {
					setUnderSections(data.data.mSectionRecords);
				}
			} catch (error) {
				console.error("Failed to fetch under sections:", error);
			}
		};

		fetchUnderSections();
	}, []);
	useEffect(() => {
		const getLawyerTeamList = async () => {
			try {
				const response = await getTeamList();
				console.log(response);
				if (response) {
					const filteredArray = response?.filter(
						(lawyer) => lawyer?.lawyerId !== lawyerId
					);
					setTeamList(filteredArray);
				}
			} catch (err) {
				console.log(err);
			}
		};

		getLawyerTeamList();
	}, []);

	const CaseDetails = async (values) => {
		console.log({
			caseDescription: values.description,
			caseFirstName: values.caseFirstName,
			caseNumber: values.caseNumber,
			caseSecondName: values.caseSecondName,
			caseType: {
				caseTypeId: values.caseType.caseTypeId,
			},
			currentCourt: { courtsId: values.courtSelection.courtsId },
			firNumber: values.FIRNumber,
			grnumber: values.grNo,
			isActive: 1,
			isPetitioner: values.isPetitioner.value,
			lawyerId: lawyer.lawyerId,
			screenNo: 0,
			sections: values.underSection.map((section) => section.sectionId),
		});
		setIsLoading(true);
		fetch("https://erp.lawinzo.com/api/v1/cases?pageNo=0", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
				Authorization: `Bearer ${lawyer.accessToken}`,
			},

			body: JSON.stringify({
				caseDescription: values.description,
				caseFirstName: values.caseFirstName,
				caseNumber: values.caseNumber,
				caseSecondName: values.caseSecondName,
				caseType: {
					caseTypeId: values.caseType.caseTypeId,
				},
				currentCourt: { courtsId: values.courtSelection.courtsId },
				firNumber: values.FIRNumber,
				grnumber: values.grNo,
				isActive: 1,
				isPetitioner: values.isPetitioner.value,
				lawyerId: lawyer.lawyerId,
				screenNo: 0,
				teamIds: values?.team?.map((team) => {
					return team?.lawyerId;
				}),
				teamProfiles: values?.team?.map((team) => {
					return { lawyerId: team?.lawyerId };
				}),
				sections: values?.underSection?.map((section) => {
					return {
						sectionId: section.sectionId,
						sectionName: section.sectionName,
					};
				}),
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				// Handle the response data
				CaseId(data.caseId);
				CaseResponse(data);
				UpdateCaseListItem(data);
				setIsLoading(false);
				handleNextButton();
				console.log(data);
			})
			.catch((error) => {
				setIsLoading(false);

				// Handle any errors
				console.error("Error:", error);
			});
	};

	const dispatch = useDispatch();

	// console.log(caseType);
	// console.log(error);

	const [reset] = useState();

	// const [caseName, setcaseName] = useState();
	const caseDetailsFromRedux = useSelector(
		(state) => state.dashboard.caseDetails
	);
	const userDetailsFromRedux = useSelector((state) => state.onboardingInputs);
	const lawyer = {
		fullName: userDetailsFromRedux.fullName,
		lawyerId: userDetailsFromRedux.lawyerId,
		accessToken: userDetailsFromRedux.accessToken,
	};
	const [caseFirstName, setCaseFirstName] = useState(
		caseDetailsFromRedux.caseFirstName
	);
	const [caseSecondName, setCaseSecondName] = useState(
		caseDetailsFromRedux.caseSecondName
	);
	const [caseNumber, setCaseNumber] = useState(caseDetailsFromRedux.caseNumber);
	const [grNo, setGrNo] = useState(caseDetailsFromRedux.grNo);
	const [FIRNumber, setFIRNumber] = useState(caseDetailsFromRedux.firNumber);
	const [underSection, setUnderSection] = useState(
		caseDetailsFromRedux.underSection ? caseDetailsFromRedux.underSection : []
	);
	const [underSections, setUnderSections] = useState([]);
	const [assignLawyer, setAssingLawyer] = useState(
		caseDetailsFromRedux.assignLawyer
			? caseDetailsFromRedux.assignLawyer
			: lawyer
	);
	const [description, setDescription] = useState(
		caseDetailsFromRedux.description
	);
	const [caseTypes, setCaseTypes] = useState([]);
	const [caseType, setCaseType] = useState(
		caseDetailsFromRedux.caseType ? caseDetailsFromRedux.caseType : null
	);
	const [courtSelection, setCourtSelection] = useState(
		caseDetailsFromRedux.courtSelection
			? caseDetailsFromRedux.courtSelection
			: null
	);
	const [courtOptions, setCourtOptions] = useState([]);

	const [isPetitioner, setIsPetitioner] = useState(
		caseDetailsFromRedux.isPetitioner ? caseDetailsFromRedux.isPetitioner : null
	);
	const [areYouOptions, setAreYouOptions] = useState([]);
	const [teamLawyer, setTeamLawyer] = useState();

	useEffect(() => {
		const fetchAreYouOptions = () => {
			setAreYouOptions([
				{ label: "Petitioner Lawyer", value: 0 },
				{ label: "Respondent Lawyer", value: 1 },
			]);
		};

		fetchAreYouOptions();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const courts = await getCourt();
			setCourtOptions(courts);
		};

		fetchData();
	}, []);
	const CaseFirstName = (value) => {
		dispatch(updateCaseFirstName(value));
		console.log(value);
	};
	const CaseResponse = (value) => {
		dispatch(updateCaseResponse(value));
		console.log(value);
	};

	const CaseId = (value) => {
		dispatch(updateCaseId(value));
		console.log(value);
	};
	const CaseSecondName = (value) => {
		dispatch(updateCaseSecondName(value));
		console.log(value);
	};
	const UpdateCaseListItem = (value) => {
		dispatch(updateCaseListItem(value));
	};
	const CaseType = (value) => {
		dispatch(updateCaseType(value));
		// console.log(value);
	};
	const IsPetitioner = (value) => {
		dispatch(updateIsPetitioner(value));
		// console.log(value);
	};

	const CaseNumber = (value) => {
		dispatch(updateCaseNumber(value));
		// console.log(value);
	};

	const GRNo = (value) => {
		dispatch(updateGRNo(value));
		console.log(value);
	};

	const FIRNo = (value) => {
		dispatch(updateFIRNo(value));
		// console.log(value);
	};

	const Court = (value) => {
		dispatch(updateCourt(value));
		// console.log(value);
	};

	const Lawyer = (value) => {
		dispatch(updateAssignLawyer(value));
		// console.log(value);
	};

	const CaseDescription = (value) => {
		dispatch(updateCaseDescription(value));
		// console.log(value);
	};
	const UnderSection = (value) => {
		dispatch(updateUnderSection(value));
		// console.log(value);
	};

	const formik = useFormik({
		initialValues: {
			// caseName: caseName,
			caseFirstName: caseFirstName,
			caseSecondName: caseSecondName,
			caseType: caseType,
			caseNumber: caseNumber,
			grNo: grNo,
			team: teamLawyer,
			FIRNumber: FIRNumber,
			underSection: underSection,
			courtSelection: courtSelection,
			assignLawyer: assignLawyer,
			isPetitioner: isPetitioner,
			description: description,
		},
		validationSchema: CaseValidations,
		enableReinitialize: true,
		onSubmit: (values) => {
			CaseFirstName(values.caseFirstName);
			CaseSecondName(values.caseSecondName);
			CaseType(values.caseType);
			CaseNumber(values.caseNumber);
			GRNo(values.grNo ? values.grNo : 0);
			FIRNo(values.FIRNumber ? values.FIRNumber : 0);
			Court(values.courtSelection);
			Lawyer(values.assignLawyer);
			CaseDescription(values.description ? values.description : "");
			UnderSection(values.underSection);
			IsPetitioner(values.isPetitioner);
			CaseDetails(values);
		},
	});
	if (reset) {
		// console.log("reset clicked");
		// setcaseName("");
		// setCaseType([]);
		// setAssignLawyer();
		// setDescription("");
		// // setUnderSection();
		// //setAssignLawyer(null);
		// setReset(false);
	}

	const saveToRedux = () => {
		CaseFirstName(caseFirstName);
		CaseSecondName(caseSecondName);
		CaseType(caseType);
		CaseNumber(caseNumber);
		GRNo(grNo);
		FIRNo(FIRNumber);
		Court(courtSelection);
		Lawyer(assignLawyer);
		CaseDescription(description);
		UnderSection(underSection);
		IsPetitioner(isPetitioner);
		// debugger;
	};
	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				paddingBottom: "30px",
			}}
		>
			{/* <CaseContainer> */}
			<Div
				sx={{
					marginLeft: { xs: "5%", md: "18%" },
					marginRight: { xs: "5%", md: "18%" },
					marginTop: "20px",
					height: "100%",
				}}
			>
				{/* <form> */}
				{/* <TextField
          sx={{ mb: 0, mt: 0, width: "100%" }}
          id="outlined-basic"
          label="Case Name"
          required
          color="secondary"
          variant="outlined"
          value={formik.values.caseName}
          onChange={(e) => setcaseName(e.target.value)}
          error={formik.errors.caseName && formik.touched.caseName}
          name="caseName"
          helperText={formik.errors.caseName}
        /> */}
				<TextField
					sx={{ mb: 0, mt: 0, width: "100%" }}
					id="outlined-basic"
					label="First Party Name"
					required
					color="secondary"
					variant="outlined"
					value={formik.values.caseFirstName}
					onChange={(e) => {
						setCaseFirstName(e.target.value);
					}}
					error={formik.errors.caseFirstName && formik.touched.caseFirstName}
					name="caseFirstName"
					helperText={formik.errors.caseFirstName}
				/>
				<Typography
					sx={{
						fontSize: { xs: "14px", md: "14px" },
						fontWeight: "800",
						textAlign: "center",
						mt: 1,
					}}
				>
					VS
				</Typography>
				<TextField
					sx={{ mb: 0, mt: 1, width: "100%" }}
					id="outlined-basic"
					label="Second Party Name"
					required
					color="secondary"
					variant="outlined"
					value={formik.values.caseSecondName}
					onChange={(e) => setCaseSecondName(e.target.value)}
					error={
						formik.touched.caseSecondName &&
						Boolean(formik.errors.caseSecondName)
					}
					name="caseSecondName"
					helperText={formik.errors.caseSecondName}
				/>

				<Autocomplete
					value={formik.values.caseType}
					style={{ width: "100%" }}
					options={caseTypes}
					getOptionLabel={(option) => option.caseName}
					renderOption={(props, option, { selected }) => (
						<li {...props}>{option.caseName}</li>
					)}
					isOptionEqualToValue={(option, value) =>
						option.caseTypeId === value.caseTypeId
					}
					name="caseType"
					onChange={(e, v) => {
						setCaseType(v);
					}}
					sx={{ width: 500 }}
					renderInput={(params) => (
						<TextField
							sx={{ mb: 0, mt: 2 }}
							{...params}
							color="secondary"
							label="Case Type*"
							placeholder="Case Type*"
							error={formik.touched.caseType && Boolean(formik.errors.caseType)}
							name="caseType"
							helperText={formik.errors.caseType}
						/>
					)}
				/>

				<TextField
					sx={{ mb: 0, mt: 2, width: "100%" }}
					id="outlined-basic"
					label="Case Number"
					required
					color="secondary"
					variant="outlined"
					value={formik.values.caseNumber}
					onChange={(e) => setCaseNumber(e.target.value)}
					error={formik.touched.caseNumber && Boolean(formik.errors.caseNumber)}
					name="caseNumber"
					helperText={formik.errors.caseNumber}
				/>

				<Tooltip
					title="If you don't have GR Number , enter 0"
					arrow
					placement="bottom-start"
				>
					<TextField
						sx={{ mb: 0, mt: 2, width: "100%" }}
						// InputProps={{
						// 	startAdornment: (
						// 		<InputAdornment position="start">
						// 			<ErrorOutline />
						// 		</InputAdornment>
						// 	),
						// }}
						id="outlined-basic"
						label="GR Number"
						color="secondary"
						variant="outlined"
						value={formik.values.grNo}
						onFocus={() => {
							if (formik.values.grNo) {
								return setGrNo(formik.values.grNo);
							} else {
								return setGrNo(0);
							}
						}}
						onChange={(e) => {
							return setGrNo(e.target.value);
						}}
						error={formik.touched.grNo && Boolean(formik.errors.grNo)}
						name="grNo"
						helperText={formik.errors.grNo}
					/>
				</Tooltip>

				<Tooltip
					title="If you don't have FIR Number , enter 0"
					arrow
					placement="bottom-start"
				>
					<TextField
						sx={{ mb: 0, mt: 2, width: "100%" }}
						id="outlined-basic"
						label="FIR/Complaint Number"
						color="secondary"
						variant="outlined"
						value={formik.values.FIRNumber}
						onFocus={() => {
							if (formik.values.grNo) {
								return setFIRNumber(formik.values.FIRNumber);
							} else {
								return setFIRNumber(0);
							}
						}}
						onChange={(e) => {
							return setFIRNumber(e.target.value);
						}}
						error={formik.touched.FIRNumber && Boolean(formik.errors.FIRNumber)}
						name="FIRNumber"
						helperText={formik.errors.FIRNumber}
					/>
				</Tooltip>

				<Autocomplete
					style={{ width: "100%" }}
					multiple
					onChange={(e, v) => {
						setUnderSection(v);
					}}
					name="underSection"
					value={formik.values.underSection}
					isOptionEqualToValue={(option, value) =>
						option.sectionId === value.sectionId
					}
					options={underSections}
					getOptionLabel={(option) =>
						option.hasOwnProperty("sectionName") ? option.sectionName : ""
					}
					renderOption={(props, option, { selected }) => (
						<li {...props}>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
								checkedIcon={<CheckBoxIcon fontSize="small" />}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.hasOwnProperty("sectionName") ? option.sectionName : ""}
						</li>
					)}
					sx={{ width: 500 }}
					renderInput={(params) => (
						<TextField
							sx={{ mb: 0, mt: 2 }}
							{...params}
							color="secondary"
							label="Select Under Section*"
							placeholder="Select Under Section*"
							error={
								formik.touched.underSection &&
								Boolean(formik.errors.underSection)
							}
							helperText={formik.errors.underSection}
							name="underSection"
						/>
					)}
				/>

				<Autocomplete
					style={{ width: "100%" }}
					value={formik.values.courtSelection}
					options={courtOptions}
					onChange={(e, v) => setCourtSelection(v)}
					isOptionEqualToValue={(option, value) =>
						option.courtsId === value.courtsId
					}
					name="courtSelection"
					// disableCloseOnSelect
					getOptionLabel={(option) => option.courtName}
					renderOption={(props, option, { selected }) => (
						<li {...props}>{option.courtName}</li>
					)}
					sx={{ width: 500 }}
					renderInput={(params) => (
						<TextField
							sx={{ mb: 0, mt: 2 }}
							{...params}
							color="secondary"
							label="Select Court Selection*"
							placeholder="Select Court Selection*"
							error={
								formik.touched.courtSelection &&
								Boolean(formik.errors.courtSelection)
							}
							name="courtSelection"
							helperText={formik.errors.courtSelection}
						/>
					)}
				/>

				{/* assign lawyer */}
				<TextField
					sx={{ mb: 0, mt: 2, width: "100%" }}
					id="outlined-multiline-static"
					label="Case Lawyer*"
					color="secondary"
					placeholder="Case Lawyer"
					variant="outlined"
					disabled
					value={lawyer.fullName}
					name="caseLawyer"
				/>

				<Div sx={{ display: "flex", width: { sm: "100%", md: "130%" } }}>
					<Autocomplete
						style={{ width: "100%" }}
						value={formik.values.team}
						name="team"
						options={teamList}
						// disableCloseOnSelect
						multiple
						onChange={(e, v) => {
							setTeamLawyer(v);
						}}
						isOptionEqualToValue={(o, v) => o?.lawyerId === v?.lawyerId}
						getOptionLabel={(option) =>
							option?.user?.fullname ? option?.user?.fullname : "Fellow Lawyer"
						}
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option?.user?.fullname
									? option?.user?.fullname
									: "Fellow Lawyer"}
							</li>
						)}
						sx={{ width: 500 }}
						renderInput={(params) => (
							<TextField
								sx={{ mb: 0, mt: 2 }}
								{...params}
								label="Share Case with"
								placeholder="Share Case with"
								color="secondary"
								// error={caseError}
							/>
						)}
					/>
					<Button
						style={{ fontWeight: "700", width: "30%", marginTop: "10px" }}
						startIcon={<AddIcon />}
						color="secondary"
						onClick={() => {
							saveToRedux();
							navigate("/lawyers/addlawyer?fromCase=true");
						}}
					>
						Add Team Member
					</Button>
					{/* <IconButton color="primary" aria-label="Add Client" onClick={handleAddClient}>
                  <AddIcon />
                   </IconButton> */}
				</Div>

				<Autocomplete
					style={{ width: "100%" }}
					value={formik.values.isPetitioner}
					name="isPetitioner"
					isOptionEqualToValue={(option, value) => option.value === value.value}
					options={areYouOptions}
					// disableCloseOnSelect
					onChange={(e, v) => {
						setIsPetitioner(v);
					}}
					getOptionLabel={(option) => option?.label}
					renderOption={(props, option, { selected }) => (
						<li {...props}>{option?.label}</li>
					)}
					sx={{ width: 500 }}
					renderInput={(params) => (
						<TextField
							sx={{ marginTop: "16px", marginBottom: "16px" }}
							{...params}
							label="Are You--"
							placeholder="Are You--"
							color="secondary"
							// error={caseError}
							error={
								formik.touched.isPetitioner &&
								Boolean(formik.errors.isPetitioner)
							}
							helperText={formik.errors.isPetitioner}
							name="isPetitioner"
						/>
					)}
				/>

				{/* description */}
				<TextField
					sx={{ mb: 0, mt: 2, width: "100%" }}
					id="outlined-multiline-static"
					label="Write Case Description..."
					multiline
					color="secondary"
					rows={4}
					onChange={(e) => setDescription(e.target.value)}
					value={formik.values.description}
					error={
						formik.touched.description && Boolean(formik.errors.description)
					}
					name="description"
					helperText={formik.errors.description}
				/>
				{/* </form> */}
				<Div
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 5,
					}}
				>
					<LoadingButton
						onClick={handleBackButton}
						variant="contained"
						sx={{
							width: 100,
							padding: 1,
							backgroundColor: "black",
							"&:hover": {
								backgroundColor: "#eabf4e",
							},
						}}
					>
						Back
					</LoadingButton>
					<LoadingButton
						onClick={() => {
							formik.handleSubmit();
							console.log(formik.values, formik.errors);
						}}
						color="secondary"
						sx={{
							width: 100,
							padding: 1,
							backgroundColor: "black",
							"&:hover": {
								backgroundColor: "#eabf4e",
								color: "black",
							},
						}}
						variant="contained"
					>
						Continue
					</LoadingButton>
				</Div>
			</Div>
		</div>
	);
}

export default Case;
