import React from "react";
import PropTypes from "prop-types";
import { alpha, Checkbox, ListItemButton, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Box from "@mui/material/Box";
import { ListItemIcon, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import faviconImage from "../../ImageAssets/vakily-logo.png";
import Avatar from "@mui/material/Avatar";
import lawyerImage from "../../ImageAssets/lawyer.png";

const WitnessCard = ({
  inOption,
  item,
  selected,
  onClick,
  selectedPeople,
  setSelectedPeople,
  showIcons = true,
  addClient,
  formDisable
}) => {
	console.log(item);
	const deleteSelection = () => {
		setSelectedPeople((prevSelected) => {
			if (item.peopleId) {
				return prevSelected.filter(
					(person) => person.peopleId !== item.peopleId
				);
			} else {
				return prevSelected.filter((person) => person.id !== item.id);
			}
		});
	};
	// console.log(selectedPeople)
	const handleEdit = () => {
		// console.log("EDIT");
		let selectedPerson;
		if (item.peopleId) {
			selectedPerson = selectedPeople.find(
				(obj) => obj.peopleId === item.peopleId
			);
		} else {
			selectedPerson = selectedPeople.find((obj) => obj.id === item.id);
		}
		// console.log(selectedPerson)
		addClient(selectedPerson);
		deleteSelection();
	};

  return (
    <ListItemButton
      alignItems="flex-start"
      key={item.peopleId}
      sx={{
        "px": 3,
        "py": 2,
        "borderBottom": 1,
        "borderBottomColor": "divider",
        "&:hover .update-task": {
          opacity: 1,
          color: "grey.700",
          backgroundColor: "common.white",
          transform: "translateY(-50%) scale(1)",
        },
      }}
    >
    {! formDisable && <ListItemIcon sx={{ minWidth: 40, mt: 0 }}>
        <Checkbox
          edge="start"
          checked={inOption ? selected : true}
          onClick={inOption ? onClick : deleteSelection}
          sx={{
            "color": "rgba(234, 191, 78, 0.98)",
            "&.Mui-checked": {
              color: "rgba(234, 191, 78, 0.98)",
            },
          }}
        />
      </ListItemIcon>
}
      <Avatar
        sx={{
          marginRight: "15px",
          width: { sm: 50, lg: 70 },
          height: { sm: 50, lg: 70 },
        }}
        alt={item?.name}
        src={lawyerImage}
        variant="rounded"
      />
      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Typography
              variant="h5"
              mb={0.5}
              sx={{
                marginRight: "10px",
                fontSize: "20px",
                lineHeight: "1.2",
                fontWeight: 400,
                color: "rgb(55, 55, 60)",
                fontFamily: "NoirPro, Arial",
              }}
            >
              {item?.user?.fullname ? item?.user?.fullname : item.fullName}
            </Typography>
            {item.peopleId && (
              <Box display="flex" alignItems="center">
                <img
                  src={faviconImage}
                  alt="VERIFIED"
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />
              </Box>
            )}
          </Box>
        }
        secondary={
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} mr={1} fontWeight={400} fontSize="15px">
              <Box display="flex" alignItems="center" mb={1} mt={1}>
                <LocalPhoneOutlinedIcon color="secondary" />
                <Typography
                  variant="body2"
                  ml={1}
                  fontSize={"0.875rem"}
                  fontFamily={"NoirPro, Arial"}
                  fontWeight={"400"}
                  lineHeight={"1.5"}
                  color={"rgb(133, 149, 166)"}
                  noWrap
                >
                 			{item.mobileNo ? item.mobileNo : "Not Available"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <EmailOutlinedIcon color="secondary" />
                <Typography
                  variant="body2"
                  ml={1}
                  fontSize={"0.875rem"}
                  fontFamily={"NoirPro, Arial"}
                  fontWeight={"400"}
                  lineHeight={"1.5"}
                  color={"rgb(133, 149, 166)"}
                  noWrap
                >
             		{item.emailId ? item.emailId : "Not Available"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <LocationOnIcon color="secondary" />
                <Typography
                  variant="body2"
                  ml={1}
                  fontSize={"0.875rem"}
                  fontFamily={"NoirPro, Arial"}
                  fontWeight={"400"}
                  lineHeight={"1.5"}
                  color={"rgb(133, 149, 166)"}
                  noWrap
                >
                 {item.addressLine1 ? item.addressLine1 : "Not Available"}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
      />
      {!formDisable &&  <IconButton
          onClick={()=>{handleEdit() 
          console.log("from edit")
          }}
          className="update-task"
          aria-label="update-task"
          sx={{
            position: "absolute",
            zIndex: 5,
            right: 20,
            bottom: "20%",
            opacity: 0,
            boxShadow: 1,
            color: "text.primary",
            backgroundColor: "common.white",
            transform: "translateY(-50%) scale(0.5)",
            transition: "all 0.2s ease-in-out",
          }}
        >
          <Box display="flex" alignItems="center">
            <CreateIcon fontSize="small" />
          </Box>
        </IconButton>}
      {!formDisable &&  <IconButton
          onClick={deleteSelection}
          className="update-task"
          aria-label="update-task"
          sx={{
            position: "absolute",
            zIndex: 5,
            right: 20,
            top: "30%",
            opacity: 0,
            boxShadow: 1,
            color: "text.primary",
            backgroundColor: "common.white",
            transform: "translateY(-50%) scale(0.5)",
            transition: "all 0.2s ease-in-out",
          }}
        >
          <Box display="flex" alignItems="center">
            {/* <CreateIcon fontSize="small" /> */}
            <DeleteOutlineIcon fontSize="small" />
          </Box>
        </IconButton>}
        
    </ListItemButton>
  );

};

WitnessCard.propTypes = {
	inOption: PropTypes.bool,
	item: PropTypes.object,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	selectedPeople: PropTypes.array,
	setSelectedPeople: PropTypes.func,
};

export default WitnessCard;
