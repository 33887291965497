import React from "react";
import Pagination from "@mui/material/Pagination";

const CardFooter = ({ totalPages, setPageNumber }) => {
  return (
    <div>
      <Pagination
        count={totalPages}
        onChange={(e, value) => {
          setPageNumber(value - 1)
        }}
      />
    </div>
  );
};

export default CardFooter;
