import {
  UPDATE_CASE_LIST,
  UPDATE_CASE_LIST_ITEM,
} from "app/utils/constants/case";

const initialState = {
  cases: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CASE_LIST:
      return { ...state, cases: action.payload };

    case UPDATE_CASE_LIST_ITEM:
      if (state.cases.length > 0) {
        const item = action.payload;
        const itemExists = state.cases.some(
          (temp) => temp.caseId === item.caseId
        );
        const newArr = state.cases.filter((object) => {
          return object.id !== item.id;
        });
        console.log(itemExists);
        console.log(state.cases);
        console.log(newArr);
        if (itemExists) {
          return { ...state, cases: newArr };
        } else {
          return { ...state, cases: [action.payload, ...state.cases] };
        }
      } else {
        return { ...state };
      }
    // Add other cases as needed
    default:
      return state;
  }
};

export default reducer;
