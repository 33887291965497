import React, { useEffect, useState } from 'react'
import Div from '@jumbo/shared/Div/Div'
import Grid from "@mui/material/Grid";
import DocumentPayments from '../DocumentsPayments/DocumentPayments';
import IdeaPayments from '../IdeasPayments/IdeaPayments';
import IdeaPayments2 from '../IdeasPayment2/IdeaPayments2';
import Cookies from 'js-cookie';
import usePayments from 'app/hooks/usePayments';
import { getPaymentLists } from 'app/Apis/Payment';


const BalanceWidgets = ({showUpdate}) => {
  const accessToken = Cookies.get("accessToken");
  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentsReport, setPaymentsReport] = useState(null);
  const getPaymentsReports = usePayments();
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const getPaymentReport = async () => {
      const response = await getPaymentsReports(accessToken);
      if (response) {
        console.log(response);
        setPaymentsReport(response);
      } else {
        console.log("error getting payment reports");
      }
    };
    const paymentList = async () => {
      const response = await getPaymentLists(
        accessToken,
        "lawyer",
        "today",
        5,
        0
      );
      if (response) {
        console.log(response);
        setPaymentsList(response);
      } else {
        console.log("error getting payment lists");
      }
    };
    setIsLoading(true)
    paymentList();
    getPaymentReport();
    setIsLoading(false)

  }, [showUpdate]);
  return (
    <Div>
      <Grid container spacing={3.75}>
  <Grid item xs={12} lg={4}>
    <Div id="documentPayment">
        {/* balance */}
        <IdeaPayments paymentRequested={paymentsReport?.[1]}/>
    </Div>
  </Grid>
  <Grid item xs={12} lg={4}>
    <Div id="ideaPayment">
    <IdeaPayments2 paymentReceived={paymentsReport?.[2]}/>   
    </Div>
  </Grid>
  <Grid item xs={12} lg={4}>
    <Div id="ideaPayment2" >
    <DocumentPayments balance={paymentsReport?.[0]}/>
    </Div>
  </Grid>
</Grid>

    </Div>
  )
}

export default BalanceWidgets
