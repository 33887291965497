import Div from "@jumbo/shared/Div";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { formatDate } from "app/utils/dateTimeHelpers";
import React from "react";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import NorthEastIcon from "@mui/icons-material/NorthEast";

const PaymentCardItem = ({ item }) => {

  const handleClick = ()=>{
    window.open(`https://erp.lawinzo.com/payment/invoice?order_id=${item?.order_id}`, '_blank').focus();
  }
  return (
    <Card sx={{ px: "10px", mt: 2 }}>
      <CardHeader
        sx={{ p: 0, pt: 2, gap: "8px",
        "& .MuiCardHeader-title":{
          fontWeight:600
        }
      }}
        title={`${item?.cases?.caseFirstName || "First Party"} vs ${
          item?.cases?.caseSecondName || "Second Party"
        }`}
        subheader={item?.clientName || "Client Name"}
        action={
          <Div sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Div
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <Typography>Invoice Date</Typography>
              <Typography>
                {item?.createdDate ? formatDate(item?.createdDate) : "date"}
              </Typography>
            </Div>
            {item?.order_status==="Success" && ( // take are of the equality sign
            <Div>
              <Typography sx={{ 
                color: "#eabf4e",
                textAlign:"right" ,
                fontWeight:800,
                "&:hover":{
                  cursor:"pointer"
                }
              }}
              onClick={handleClick}
              >View Invoice</Typography>
            </Div>
            )}
          </Div>
        }
      />
      <Divider />
      <CardContent sx={{ p: 0, pt: 1 }}>
        <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          <Div sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "0.85rem", color: "#8595A6" }}>
              Requested
            </Typography>
            <Div sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
              <NorthEastIcon
                style={{ fontSize: "inherit", color: "red", fontWeight: 600 }}
              />
              <Typography sx={{ color: "red", fontWeight: 600 }}>
                {item?.lawyer_amount ? item?.lawyer_amount : "NILL"}
              </Typography>
            </Div>
          </Div>
          <Div
            sx={{ display: "flex", flexDirection: "column", color: "#8595A6" }}
          >
            <Typography sx={{ fontSize: "0.85rem" }}>Recieved</Typography>
            <Div sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <SouthWestIcon
                style={{ fontSize: "inherit", color: "green", fontWeight: 600 }}
              />
              <Typography sx={{ color: "green", fontWeight: 600 }}>
                {item?.lawyer_amount ? item?.lawyer_amount : "NILL"}
              </Typography>
            </Div>
          </Div>
        </Div>
      </CardContent>
    </Card>
  );
};

export default PaymentCardItem;
