import React from "react";
import ChartAppUsers from "./ChartAppUsers";
import { Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import {Divider} from "@mui/material";
import {CardActions, Link} from "@mui/material";

const AppUsers = () => {
  const { t } = useTranslation();
  return (
    <JumboCardQuick
      title={<Typography variant={"h5"} fontSize={"20.5px"}>{"Reports"}</Typography>}
      subheader={
        <Typography variant={"h6"} color={"text.secondary"} mb={0}>
          {"Reports for past month"}
        </Typography>
      }
      wrapperSx={{ p:0}}
      // sx={{width:"100%"}}
    >
      <ChartAppUsers />
      <Box>
        <Divider />
        <CardActions sx={{ py: (theme) => theme.spacing(1.5) }}>
          <Link
            href="/lawyers/appointments"
            underline="none"
            lineHeight={1.2}
            fontSize={"16px"}
            fontWeight={700}
            color={"#EABF4E"}
          >
            View All
          </Link>
        </CardActions>
      </Box>
    </JumboCardQuick>
  );
};

export default AppUsers;
