import axios from 'axios';
import { apiHeader, apiUrl } from "app/config";
import { filter } from "app/utils/filter";

export const createDrafting = async (accessToken, draftingData) => {
  try {
    if (!accessToken || !draftingData) {
      throw Error('Missing required parameters in API');
    }

    const body = {
      ...draftingData,
    };


    const headers = {
      'x-api-key': apiHeader,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${apiUrl}/api/v1/drafting`, body, {
      headers,
    });

    if (response.status === 200) {
      console.log('Drafting Added', response.data);
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateDrafting = async (accessToken, draftingData) => {
  try {
    if (!accessToken || !draftingData) {
      throw Error('Missing required parameters in API');
    }

    const body = {
      ...draftingData,
    };


    const headers = {
      'x-api-key': apiHeader,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${apiUrl}/api/v1/drafting?actionId=update`, body, {
      headers,
    });

    if (response.status === 200) {
      console.log('Drafting Added', response.data);
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDraftList = async (
  accessToken,
  caseId,
  pageNumber = 0,
  pageSize = 5,
  basicFilter=null,fromDate=null,toDate=null,
) => {
  let date=""
  if(basicFilter){
    date = filter(basicFilter, fromDate, toDate)
  }
    console.log(basicFilter)
  try {
    let headers = new Headers();
    // headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const params = new URLSearchParams();

    params.append("page", pageNumber);
    params.append("size", pageSize);
    if(basicFilter &&  date?.startDate && date?.endDate){
      params.append("fromDate", date?.startDate);
      params.append("toDate", date?.endDate);
    }
    const url =  caseId ? apiUrl + `/api/v1/drafting?isCase=1&caseId=${caseId}` : apiUrl + "/api/v1/drafting";
    const urlWithParams = caseId ? url : `${url}?${params.toString()}`;
    const response = await fetch(urlWithParams, {
      method: "GET",
      headers,
      // body: JSON.stringify(obj),
    });

    // console.log(response);
    if (response.status === 200) {
      const data = await response.json();
      console.log("all cases fetched", data);
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};