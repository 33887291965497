import { Avatar, Button, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Chip, Box, Modal, Link } from "@mui/material";
import { formatDate } from "app/utils/dateTimeHelpers";
import { getAssetPath } from "../../utils/appHelpers";
import { ASSET_AVATARS } from "../../utils/constants/paths";
import Cookies from "js-cookie";

const avatarImgUrl = getAssetPath(`${ASSET_AVATARS}/lawyer.png`, "40x40");

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderBottom: "2px solid #f5f7fa",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    fontSize:"1rem",
    fontWeight:600,
  },

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CaseReportCard = ({item}) => {
  const assignedRole = Cookies.get("assignedRole");
  return (
    <StyledTableRow>
      <TableCell
        sx={{
          pl: (theme) => theme.spacing(3),
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          gap: 2,
          pb:"17px"
        }}
      >
        <Avatar
          sx={{
            width: { sm: 20, lg: 30 },
            height: { sm: 20, lg: 30 },
            borderRadius: "50%",
          }}
          alt={item?.user?.fullname}
          src={
            item?.user?.userProfileImageLink
              ? item?.user?.userProfileImageLink
              : avatarImgUrl
          }
          variant="rounded"
        />
        {item?.user?.fullname}
      </TableCell>
      <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "left" }}>
        {"1, 100, 10, 420, 402, 251, 25A, 320, 308, 55B, 295"}
      </TableCell>
      <TableCell>
        55
      </TableCell>
      {assignedRole==="ROLE_ADMIN" && (
      <TableCell>
        <Button
        sx={{
          border:"1px solid #eabf4e",
          borderRadius:"8px",
          color:"#eabf4e",
        }}
        >View</Button>
      </TableCell>
      )}
    </StyledTableRow>
  );
}

export default CaseReportCard