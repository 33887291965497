import React from "react";
import { Link, Paper, MenuItem, Select } from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useAppointment from "app/hooks/useAppointment";
import AppointmentDashList from "./AppointmentDashList";
import CircularTabs from "../../CircularTabs/CircularTabs";
import PersonIcon from "@mui/icons-material/Person";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NoDataDisplay from "../NoDataDisplay/NoDataDisplay";
import AppointmentSkeletons from "./AppointmentSkeletons";

const tabs = [
  {
    Icon: <PersonIcon />,
    tagLabel: "In-person",
  },
  {
    Icon: <VideoCallIcon />,
    tagLabel: "Video",
  },
  {
    Icon: <LocalPhoneIcon />,
    tagLabel: "Telephonic",
  },
];
const optionsPageSize = ["10","20","50"];
const AppointmentList = ({ scrollHeight,basicFilter,endDate,startDate,dateRangeStatus,count}) => {
  const currentDate=new Date()
  const [appointmentCount,setAppointmentCount]=useState()
  const [appointmentList, setAppointmentList] = useState([]);
  const [value, setValue] = useState(0);
  const [appointmentType, setAppointmentType] = useState("");
  const [pageSize, setPageSize] = useState("5");
  const [isLoading, setIsLoading] = useState(true);
  const handleChange = (e, v) => {
    setValue(v);
  };
  const getAllAppointment = useAppointment();
  useEffect(() => {
    const getAllAppointmentList = async () => {
      setIsLoading(true);
      const response = await getAllAppointment(0, pageSize,basicFilter,startDate,endDate);
      if (response) {
        setAppointmentList(response?.data?.appointments);
        console.log(response);
        const initValue = {
          inPerson: 0,
          videoCall: 0,
          telephonic: 0,
          // totalCount:0
        }
        const count = response?.data?.appointments.reduce((initValue, item) => {
          // if(new Date(item?.fromTime)>currentDate){
          //   initValue.totalCount++
          // }
          if (item.appointmentType==="Personal Meeting") {
            initValue.inPerson++
          }
          if (item.appointmentType==="Video Call") {
            initValue.videoCall++
          }
          if (item.appointmentType==="Telephonic Call") {
            initValue.telephonic++
          }
          return initValue
        }, initValue)
        console.log(count)
        if(count.inPerson>0){
          setValue(0)
        }else if(count.videoCall>0){
          setValue(1)
        }else if(count.telephonic>0){
          setValue(2)
        }else {
          setValue(0)
        }
        setIsLoading(false)
        setAppointmentCount(count)
      }
    };
    if(basicFilter!=="Choose Date"){
      getAllAppointmentList()
  }
  else if(basicFilter==="Choose Date"){
    if(startDate && endDate){
      getAllAppointmentList()
    }
  }
   
    console.log("in useEff");
  }, [
    // pageSize,
    basicFilter,dateRangeStatus])

  return (
    <Paper
      sx={{
        backgroundColor: "#f2f2f3",
        p: 3,
        color: "black",
        borderRadius: "20px",
      }}
      elevation={20}
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Div
            style={{
              height: "19px",
              width: "5px",
              backgroundColor: "#EABF4E",
              marginRight: "8px",
            }}
          ></Div>
          <Typography
            sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 400 }}
          >
            Appointments ({count})
          </Typography>
        </Div>
        <Div sx={{display:"flex",gap:1,alignItems:"center"}}>
          {/* <Div>
            <Select
              labelId="select-label"
              value={pageSize}
              sx={{ maxHeight: "30px", mr: "1px" }}
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
            >
              {optionsPageSize.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Div> */}
          <Div>
            <Link
              href="/lawyers/appointments"
              underline="none"
              lineHeight={1.2}
              fontSize={"16px"}
              fontWeight={700}
              color={"#EABF4E"}
            >
              View All
            </Link>
          </Div>
        </Div>
      </Div>
      <Div sx={{ mt: 3 }}>
        {/* reaplce '5' when pageSize is implemented */}
        {isLoading ? (
          Array.from(Array(parseInt("5"))).map((item, index) => {
            return <AppointmentSkeletons key={index} />;
          })
        ) : appointmentList.length > 0 && !isLoading ? 
          <>
          <Div sx={{ display: "flex", justifyContent: "center", my: 1 }}>
          <CircularTabs
          value={value}
          handleChange={handleChange}
          tabs={tabs}
          fontSize={"0.5rem"}
          fromAppointment={true}
          fromTask={false}
          count={appointmentCount}
        />
      </Div>
      <AppointmentDashList appointmentList={appointmentList} value={value}/>
      </>:
       <NoDataDisplay
       text={"No Appointments"}
       url={"/lawyers/appointments?addAppointment=true"}
       buttonText={"Add Appointments"}
       minHeight={"210px"}
     />
        }
      </Div>
    </Paper>
  );
};
export default AppointmentList;
