import React from "react";
import { Typography, Radio, FormControlLabel } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTheme } from "@mui/material";
import AddBankForm from "./AddBankForm";
import BankCard from "./BankCard";
import { Grid } from "@mui/material";
import useSettings from "app/hooks/useSettings";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddBankCard from "./AddBankCard";
import EditForm from "./EditForm";
import { boolean } from "yup";
import { Alert, Snackbar } from "@mui/material";

const BankDetails = ({ initSettings, setInitSettings }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [formOpen, setFormOpen] = useState(true);
  const [editFormOpen, setEdiFormOpen] = useState("");
  // const [bankAccAdded, setbankAccAdded] = useState("");
  const [cardOpen, setCardOpen] = useState(true);
  const [setting, setSetting] = useState({
    bankName: "",
    branchAddress: "",
    branchName: "",
    ifscCode: "",
    payeeName: "",
    upiId: "",
  });
  //   useEffect(()=>{
  // if(boolean(initSettings?.paymentDetails)){
  //   setFormOpen(false)
  // }
  // },[initSettings])
  const [typeOf, setTypeOf] = useState(null);
  let name = "UPI ID";
  const lawyerId = useSelector((state) => {
    return state?.onboardingInputs?.lawyerId;
  });
  const accessToken = useSelector((state) => {
    return state?.onboardingInputs?.accessToken;
  });
  // const [initSettings, setInitSettings] = useState({});
  const theme = useTheme();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("Snackbar Message");
  const handleSnackbarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  // const getSetting = useSettings();
  // useEffect(() => {
  //   const getBankSetting = async () => {
  //     const settings = await getSetting(lawyerId, accessToken);
  //     if (!settings) {
  //       console.log("error getting settings");
  //     }
  //     if (settings.paymentDetails) {
  //       setSetting(settings.paymentDetails);
  //     }
  //     setInitSettings(settings);
  //     console.log(initSettings);
  //   };
  //   getBankSetting();
  // }, []);
  useEffect(() => {
    if (initSettings) {
      let upi = false;
      let account = false;
      if (initSettings?.paymentDetails?.upiId) {
        upi = true;
      }
      if (initSettings?.paymentDetails?.bankName) {
        account = true;
      }
      if (upi && account) {
        setTypeOf("Bank Account");
      } else if (!upi && account) {
        setTypeOf("Bank Account");
      } else if (upi && !account) {
        setTypeOf("UPI ID");
      }
    }
  }, [initSettings]);
  return (
    <div>
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
          >
            <Typography variant="h6">{snackBarMessage}</Typography>
          </Alert>
        </Snackbar>
      )}
      <JumboCardQuick
        title={
          <Typography sx={{ fontSize: "1.25rem" }}>Bank Account</Typography>
        }
        headerSx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        sx={{ mb: 3.75 }}
      >
        <Grid container spacing={2} direction="column" wrap="nowrap">
          {formOpen &&
            (!initSettings?.paymentDetails ||
              !initSettings?.paymentDetails?.isActive) && (
              <Grid item md={12}>
                <AddBankForm
                  setOpenSnackBar={setOpenSnackBar}
                  setInitSettings={setInitSettings}
                  setFormOpen={setFormOpen}
                  setSnackBarMessage={setSnackBarMessage}
                  setCardOpen={setCardOpen}
                  initSettings={initSettings}
                />
              </Grid>
            )}

          {editFormOpen && (
            <Grid item md={12}>
              <EditForm
              typeOf={typeOf}
                setInitSettings={setInitSettings}
                setEdiFormOpen={setEdiFormOpen}
                setCardOpen={setCardOpen}
                setSnackBarMessage={setSnackBarMessage}
                setOpenSnackBar={setOpenSnackBar}
                initSettings={initSettings}
              />
            </Grid>
          )}

          {/* {cardOpen && (!initSettings?.paymentDetails ||
              !initSettings?.paymentDetails?.isActive) && (
              <Grid item md={6} xs={12}>
                <AddBankCard setFormOpen={setFormOpen} 
                setCardOpen = {setCardOpen}/>
              </Grid>
            )} */}
          {Boolean(initSettings?.paymentDetails?.isActive) && !editFormOpen && (
        
        <Grid item md={6} xs={12} >
        <BankCard
          setEdiFormOpen={setEdiFormOpen}
          setSnackBarMessage={setSnackBarMessage}
          setOpenSnackBar={setOpenSnackBar}
          setFormOpen={setFormOpen}
          setCardOpen={setCardOpen}
          name={typeOf}
          Bank={initSettings?.paymentDetails}
          initSettings={initSettings}
          setInitSettings={setInitSettings}
        />
      </Grid>
      
          )}
        </Grid>
      </JumboCardQuick>
    </div>
  );
};

export default BankDetails;