import { ASSET_IMAGES } from "app/utils/constants/paths";
import React, { useEffect, useRef, useState } from "react";
import ChatbotUiBlocks from "./ChatbotUiBlocks";
import ChatbotUiFullBlocks from "./ChatbotUiFullBlocks";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
const { default: axios } = require("axios");

function ChatbotFullPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const [chats, setChats] = useState([
		{
			type: "chatbot",
			content:
				"Welcome to Sargun Vakily AI Chatbot!\n How May I help you today?",
		},
	]);
	const [isActive, setActive] = useState(true);
	const [isActiveInput, setActiveInput] = useState(false);
	const [user, setUser] = useState(null);
	const [isResponseLoaded, setisResponseLoaded] = useState(true);

	const inputRef = useRef(null);
	const scrollDownRef = useRef(null);

	useEffect(() => {
		if (!queryParams.get("jwt")) {
			navigate("/");
		}
		if (queryParams.get("jwt")) {
			fetchUser(queryParams.get("jwt"));
		}
		async function fetchUser(token) {
			try {
				let config = {
					method: "get",
					maxBodyLength: Infinity,
					url: "https://erp.lawinzo.com/user/me", //prev https://erp.lawinzo.com
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				const response = await axios.request(config);
				setUser(response.data);
				console.log(response.data);
			} catch (error) {
				navigate("/");
			}
		}
	}, []);

	const handleChat = async () => {
		setActiveInput(true);
		if (scrollDownRef.current) {
			console.log("trigg");
			scrollDownRef.current.scrollTop = scrollDownRef.current.scrollHeight;
		}
		if (inputRef.current.value === "") {
			setActiveInput(false);
			return;
		}

		setisResponseLoaded(false);
		setChats([
			...chats,
			{ type: "user", content: inputRef.current.value },
			{ type: "chatbotLoading", content: "" },
		]);
		async function fetchResponse(dataa) {
			const response = await fetch(
				"https://api.lawinzo.com/node/chatbotreply", // "https://api.lawinzo.com/node/api/v1/documnetDraft"
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${Cookies.get("accessToken")}`,
						"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
					},
					body: JSON.stringify({
						data: dataa,
					}),
				}
			);
			const data = await response.json();

			console.log(data.data);
			setisResponseLoaded(true);
			setChats([
				...chats,
				{ type: "user", content: inputRef.current.value },
				{ type: "chatbot", content: data.data.response },
			]);
		}
		await fetchResponse(inputRef.current.value);
		inputRef.current.value = "";
		setActiveInput(false);
		setActive(true);
	};

	const handleKeyPress = (event) => {
		setActive(true);
		if (inputRef.current.value === "") {
			setActive(false);
		}
		if (event.key === "Enter") {
			handleChat();
		}
	};
	return (
		<div
			ref={scrollDownRef}
			style={{
				backgroundColor: "#343541",
				minHeight: "100vh",
				color: "white",
				overflow: "hidden",
			}}
		>
			<header
				style={{
					position: "fixed",
					top: "0",
					width: "100%",
					backgroundColor: "inherit",
					padding: ".8rem .8rem",
					display: "flex",
					alignItems: "stretch",
					justifyContent: "space-between",
					borderBottom: "2px solid rgba(130, 132, 153, 1)",
				}}
			>
				<div
					className=""
					style={{ display: "flex", alignItems: "center", gap: "10px" }}
				>
					<div
						className=""
						style={{
							width: "2.5rem",
							aspectRatio: "1/1",
							borderRadius: "5px",
							backgroundColor: "#eabf4e",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img
							src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
							style={{ width: "100%" }}
							alt=""
							srcset=""
						/>
					</div>
					<div className="">
						<h3
							style={{
								color: "white",
								margin: "0",
								position: "relative",
							}}
						>
							Chat with
						</h3>
						<h4
							style={{
								color: "white",
								margin: "0",
								position: "relative",
								top: "-4px",
							}}
						>
							Sargun
						</h4>
					</div>
				</div>
			</header>
			<div
				className="chat-grid"
				style={{
					padding: "4.6rem 0",
					paddingBottom: "6rem",
					height: "100%",
					paddingBottom: "10rem",
				}}
			>
				{chats.map((e, index) => {
					return (
						<ChatbotUiFullBlocks
							user={user}
							isResponseLoaded={isResponseLoaded}
							key={index}
							type={e.type}
							content={e.content}
						/>
					);
				})}
			</div>
			<footer
				style={{
					padding: ".8rem .8rem",
					position: "fixed",
					bottom: "0",
					width: "100%",
					backgroundColor: "inherit",
				}}
			>
				<div
					style={{
						width: "100%",
						maxWidth: "800px",
						margin: "0 auto",
						position: "relative",
					}}
				>
					<input
						ref={inputRef}
						disabled={isActiveInput}
						onKeyDown={handleKeyPress}
						type="text"
						style={{
							color: "white",
							width: "100%",
							backgroundColor: "rgba(67, 70, 84, 1)",
							border: "none",
							padding: "16px 12px",
							fontSize: "1.3rem",
							borderRadius: "8px",
						}}
						placeholder="Send a message"
					/>
					<div
						className=""
						style={{
							position: "absolute",
							right: "0",
							height: "100%",
							aspectRatio: "1/1",
							top: "0",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							padding: "8px",
						}}
					>
						<button
							className=""
							disabled={!isActive}
							onClick={handleChat}
							style={{
								width: "100%",
								height: "100%",
								borderRadius: "8px",
								border: "none",
								backgroundColor: "#eabf4e",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="#ffffff"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="lucide lucide-send-horizontal"
							>
								<path d="m3 3 3 9-3 9 19-9Z" />
								<path d="M6 12h16" />
							</svg>
						</button>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default ChatbotFullPage;
