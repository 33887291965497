import { ASSET_IMAGES } from "app/utils/constants/paths";
import React, { useEffect, useRef, useState } from "react";
import ChatbotUiBlocks from "./ChatbotUiBlocks";

import "./chatbot.css";
import { useNavigate } from "react-router-dom";
import Chatblock from "./Chatblock";
import useLogin from "app/hooks/useLogin";
import Cookies from "js-cookie";
// import {
// 	FormControlLabel,
// 	Switch,
// 	ToggleButton,
// 	ToggleButtonGroup,
// } from "@mui/material";
import RecordRTC from "recordrtc";

function ChatBot({ setopenChatbot }) {
	const [recorder, setRecorder] = useState(null);
	const [audioBlob, setAudioBlob] = useState(null);
	const [startRecorder, setStartRecorder] = useState(false);
	const [recording, setRecording] = useState(false);
	const [remainingTime, setRemainingTime] = useState(120); // 2 minutes in seconds

	const autoScrollRef = useRef();

	useEffect(() => {
		let countdownInterval;

		if (recording) {
			countdownInterval = setInterval(() => {
				setRemainingTime((prevTime) => prevTime - 1);
			}, 1000);
		}

		return () => {
			clearInterval(countdownInterval);
		};
	}, [recording]);

	const startRecording = async () => {
		try {
			setRemainingTime(120);

			setStartRecorder(true);
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			const options = {
				type: "audio",
				mimeType: "audio/wav",
				recorderType: RecordRTC.StereoAudioRecorder,
			};

			const record = new RecordRTC(stream, options);
			record.startRecording();

			setRecorder(record);
			setRecording(true);

			// Automatically stop recording after 2 minutes (120 seconds)
			setTimeout(() => {
				stopRecording();
			}, 120000);
		} catch (error) {
			console.log(error.message);
		}
	};

	const stopRecording = () => {
		try {
			setStartRecorder(false);
			recorder.stopRecording(async () => {
				const blob = recorder.getBlob();
				setAudioBlob(blob);
				const formData = new FormData();
				formData.append("audio", blob, "recorded_audio.wav");
				formData.append("lang", lang);

				setisResponseLoaded(false);
				setChats([...chats, { type: "chatbotLoading", content: "" }]);
				try {
					const response = await fetch(
						"https://api.lawinzo.com/node/chatbotreply",
						{
							method: "POST",
							body: formData,
							headers: {
								// "Content-Type": "application/json",
								"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
							},
						}
					);

					if (response.ok) {
						console.log("Audio uploaded successfully");
					} else {
						console.error("Error uploading audio");
					}

					const data = await response.json();
					setChats([
						...chats,
						{ type: "user", content: data.data.content },
						{ type: "chatbot", content: data.data.response },
					]);
					setShouldLogin(data.data.login);
					setisResponseLoaded(true);
				} catch (error) {
					console.error("Network error:", error);
				}
			});
		} catch (error) {
			console.log(error.message);
		}
	};

	const login = useLogin(true);
	const navigate = useNavigate();

	const [chats, setChats] = useState([
		{
			type: "chatbot",
			content:
				"Welcome to Sargun Vakily AI Chatbot!\n How May I help you today?",
		},
	]);
	const [finalResponse, setfinalResponse] = useState(null);
	const [isActive, setActive] = useState(false);
	const [isActiveInput, setActiveInput] = useState(false);
	const [isResponseLoaded, setisResponseLoaded] = useState(true);
	const [shouldLogin, setShouldLogin] = useState(false);
	const [otp, setOtp] = useState(false);
	const [lang, setLang] = useState("English");
	console.log(lang);

	const inputRef = useRef(null);
	const scrollDownRef = useRef(null);

	useEffect(() => {
		if (chats.length) {
			autoScrollRef.current?.scrollIntoView({
				behaviour: "smooth",
				block: "end",
			});
		}
	}, [chats.length]);

	const handleChat = async () => {
		const regex = /^\d+$/;
		console.log(otp);
		if (finalResponse) {
			const text = inputRef.current.value;
			inputRef.current.value = "";

			setChats([
				...chats,
				{ type: "user", content: text },
				{
					type: "chatbot",
					content: chats[chats.length - 1].content,
				},
			]);
			return;
		}
		if (otp) {
			const text = inputRef.current.value;
			inputRef.current.value = "";

			console.log(chats[chats.length - 2].content);
			if (regex.test(text)) {
				try {
					const response = await fetch(
						"https://erp.lawinzo.com/api/auth/verify", // "https://api.lawinzo.com/node/api/v1/documnetDraft"
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								// Authorization: `Bearer ${Cookies.get("accessToken")}`,
								"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
							},
							body: JSON.stringify({
								username: chats[chats.length - 2].content,
								password: text,
								alternate: true,
							}),
						}
					);
					const data = await response.json();
					console.log(data.data);

					if (data.data.accessToken) {
						setOtp(false);
						await login(data.data.accessToken);
						Cookies.set("accessToken", data.data.accessToken);

						if (Cookies.get("assignedRole") === "ROLE_CLIENT") {
							setChats([
								...chats,
								{ type: "user", content: text },
								{
									type: "chatbot",
									content: `Client please download the mobile app - ^linkhttps://bit.ly/lawinzo`,
								},
							]);
							setfinalResponse(true);
							return;
						}

						if (Cookies.get("assignedRole") === "ROLE_MEMBER") {
							setChats([
								...chats,
								{ type: "user", content: text },
								{
									type: "chatbot",
									content: `You have to complete your profile to use it further - ^linkhttps://cloud.lawinzo.com`,
								},
							]);
							setfinalResponse(true);
							return;
						}
						console.log("navitrigg");
						return window.parent.postMessage(
							`https://cloud.lawinzo.com/?accessToken=${data.data.accessToken}&path=chatbot`,
							"*"
						);
					}
				} catch (error) {
					setChats([
						...chats,
						{ type: "user", content: text },
						{ type: "chatbot", content: "Enter Valid OTP" },
					]);

					setOtp(true);
					return;
				}
			}
		}
		if (shouldLogin) {
			const text = inputRef.current.value;
			inputRef.current.value = "";
			setChats([
				...chats,
				{ type: "user", content: text },
				{ type: "chatbotLoading", content: "" },
			]);
			if (regex.test(text)) {
				const response = await fetch(
					"https://erp.lawinzo.com/api/auth/signinByMobile", // "https://api.lawinzo.com/node/api/v1/documnetDraft"
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							// Authorization: `Bearer ${Cookies.get("accessToken")}`,
							"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
						},
						body: JSON.stringify({
							username: text,
							alternate: true,
						}),
					}
				);
				const data = await response.json();
				console.log(data);
				if (!data.success) {
					setChats([
						...chats,
						{ type: "user", content: text },
						{ type: "chatbot", content: data.data },
					]);
				} else {
					setChats([
						...chats,
						{ type: "user", content: text },
						{ type: "chatbot", content: "Enter your OTP.." },
					]);

					setOtp(true);
				}
			} else {
				setChats([
					...chats,
					{ type: "user", content: text },
					{ type: "chatbot", content: "Please Enter Valid Number" },
				]);
			}

			return;
		}
		const text = inputRef.current.value;
		inputRef.current.value = "";
		setActiveInput(true);
		if (scrollDownRef.current) {
			console.log("trigg");
			scrollDownRef.current.scrollTop = scrollDownRef.current.scrollHeight;
		}
		if (text === "") {
			setActiveInput(false);
			return;
		}

		setisResponseLoaded(false);
		setChats([
			...chats,
			{ type: "user", content: text },
			{ type: "chatbotLoading", content: "" },
		]);
		async function fetchResponse(dataa) {
			const response = await fetch(
				"https://api.lawinzo.com/node/chatbotreply", // "https://api.lawinzo.com/node/api/v1/documnetDraft"
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						// Authorization: `Bearer ${Cookies.get("accessToken")}`,
						"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
					},
					body: JSON.stringify({
						data: dataa,
						lang,
					}),
				}
			);
			const data = await response.json();

			console.log(data.data);

			setShouldLogin(data.data.login);
			setisResponseLoaded(true);
			setChats([
				...chats,
				{ type: "user", content: text },
				{ type: "chatbot", content: data.data.response },
			]);
		}
		await fetchResponse(text);
		setActiveInput(false);
		setActive(true);
	};

	const handleKeyPress = (event) => {
		setActive(true);
		if (inputRef.current.value === "") {
			setActive(false);
		}
		if (event.key === "Enter") {
			handleChat();
		}
	};

	return (
		<div
			style={{
				backgroundColor: "#343541",
				height: "100vh",
				width: "400px",
				borderRadius: "15px",
				color: "white",
				overflow: "hidden",
				// overflowY: "scroll",
				position: "relative",
			}}
		>
			<header
				style={{
					position: "absolute",
					width: "100%",
					backgroundColor: "inherit",
					top: "0",
					display: "flex",
					alignItems: "stretch",
					zIndex: 2,
					justifyContent: "space-between",
					padding: "1rem",
					borderBottom: "2px solid rgba(130, 132, 153, 1)",
				}}
			>
				<div
					className=""
					style={{ display: "flex", alignItems: "center", gap: "10px" }}
				>
					<div
						className=""
						style={{
							width: "2.5rem",
							aspectRatio: "1/1",
							borderRadius: "5px",
							backgroundColor: "#eabf4e",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<img
							src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
							// src={'/images/vakily-logo-black.png'
							style={{ width: "100%" }}
							alt=""
							srcset=""
						/>
					</div>
					<div className="">
						<h3
							style={{
								color: "white",
								margin: "0",
								position: "relative",
								top: "1px",
							}}
						>
							Chat with
						</h3>
						<h4
							style={{
								color: "white",
								margin: "0",
								position: "relative",
								top: "-4px",
							}}
						>
							Sargun
						</h4>
					</div>
				</div>

				{/* <ChatbotLangSelect setLang={setLang} /> */}
				<div
					className=""
					style={{ display: "flex", alignItems: "center", gap: "5px" }}
				>
					{/* <FormControlLabel
						control={
							<Switch
								style={{
									color: lang === "English" ? "white" : "rgba(234, 191, 78, 1)",
								}}
								onChange={(event) => {
									if (lang === "English") {
										setLang("Hindi");
									} else {
										setLang("English");
									}
								}}
								defaultChecked
							/>
						}
						label={lang}
					/> */}
					{/* <div
						className=""
						style={{
							backgroundColor: "white",
							borderRadius: "5px",
							position: "relative",
							height: "100%",
						}}
					>
						<ToggleButtonGroup
							style={{
								position: "relative",
								height: "100%",
							}}
							color="primary"
							value={lang}
							exclusive
							onChange={(event, newLang) => {
								console.log(newLang);
								setLang(newLang);
							}}
						>
							<ToggleButton style={{ padding: "6px 12px" }} value="English">
								English
							</ToggleButton>
							<ToggleButton style={{ padding: "6px 12px" }} value="Hindi">
								Hindi
							</ToggleButton>
						</ToggleButtonGroup>
					</div> */}
					<div class="switches-container">
						<input
							type="radio"
							id="switchMonthly"
							name="switchPlan"
							value="Hindi"
							onChange={(event) => {
								setLang("Hindi");
							}}
							checked={lang === "Hindi" ? "checked" : ""}
						/>
						<input
							type="radio"
							id="switchYearly"
							name="switchPlan"
							onChange={(event) => {
								setLang("English");
							}}
							value="English"
							checked={lang === "English" ? "checked" : ""}
						/>
						<label for="switchMonthly">Hindi</label>
						<label for="switchYearly">English</label>
						<div class="switch-wrapper">
							<div class="switch">
								<div>Hindi</div>
								<div>English</div>
							</div>
						</div>
					</div>
					<div
						className=""
						style={{
							display: "flex",
							alignItems: "center",
							padding: "0 20px",
							cursor: "pointer",
						}}
					>
						{/* <svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="#ffffff"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
							class="lucide lucide-x"
						>
							<path d="M18 6 6 18" />
							<path d="m6 6 12 12" />
						</svg> */}
					</div>
				</div>
			</header>
			<div
				className="chat-grid"
				style={{
					height: "100%",
					padding: "5rem 0",
					overflow: "hidden",
					overflowY: "scroll",
				}}
			>
				{chats.map((e, index) => {
					return (
						<Chatblock
							isResponseLoaded={isResponseLoaded}
							key={index}
							type={e.type}
							content={e.content}
						/>
					);
				})}
				<div
					className=""
					style={{ paddingTop: "100px" }}
					ref={autoScrollRef}
				></div>
			</div>
			<footer
				style={{
					padding: "1rem",
					position: "absolute",
					bottom: "0",
					width: "100%",
					backgroundColor: "inherit",
				}}
			>
				<div
					style={{
						width: "100%",
						maxWidth: "800px",
						margin: "0 auto",
						position: "relative",
					}}
				>
					<input
						ref={inputRef}
						onKeyDown={handleKeyPress}
						type="text"
						style={{
							width: "100%",
							backgroundColor: "rgba(67, 70, 84, 1)",
							border: "none",
							padding: "18px 16px",
							paddingRight: "100px",
							fontSize: "1rem",
							color: "white",
							borderRadius: "8px",
						}}
						placeholder="Send a message"
					/>
					<div
						className=""
						style={{
							position: "absolute",
							right: "0",
							height: "100%",
							aspectRatio: "1/1",
							top: "0",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							gap: "10px",
							padding: "8px",
						}}
					>
						<button
							className=""
							onClick={handleChat}
							style={{
								width: "100%",
								height: "100%",
								borderRadius: "8px",
								border: "none",
								backgroundColor: "#eabf4e",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="#ffffff"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="lucide lucide-send-horizontal"
							>
								<path d="m3 3 3 9-3 9 19-9Z" />
								<path d="M6 12h16" />
							</svg>
						</button>
						{!startRecorder ? (
							<button
								onClick={startRecording}
								style={{
									width: "100%",
									height: "100%",
									borderRadius: "8px",
									border: "none",
									backgroundColor: "#eabf4e",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="#ffffff"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="lucide lucide-mic"
								>
									<path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z" />
									<path d="M19 10v2a7 7 0 0 1-14 0v-2" />
									<line x1="12" x2="12" y1="19" y2="22" />
								</svg>
							</button>
						) : (
							<button
								onClick={stopRecording}
								style={{
									width: "100%",
									height: "100%",
									borderRadius: "8px",
									border: "none",
									backgroundColor: "#eabf4e",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="#ffffff"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="lucide lucide-pause"
								>
									<rect width="4" height="16" x="6" y="4" />
									<rect width="4" height="16" x="14" y="4" />
								</svg>
							</button>
						)}
					</div>
					<div
						className=""
						style={{
							position: "absolute",
							right: "0",
							height: "100%",
							aspectRatio: "1/1",
							top: "-100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							gap: "10px",
							padding: "8px",
						}}
					>
						<p>{startRecorder ? remainingTime : null}</p>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default ChatBot;
