import axios from "axios";
import { apiHeader, apiUrl } from "app/config";
import Cookies from "js-cookie";

export const createLawyersForTeams = async (body) => {
  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken || !body) {
      throw Error("No accessToken or body Provided");
    }
    console.log(body);
    const headers = {
      "x-api-key": apiHeader,
      Authorization: "Bearer "+accessToken,
      // 'Content-Type': 'multipart/form-data',
    };
    const Body = body;
    const response = await axios.post(`${apiUrl}/api/v1/teams/lawyer`, Body, {
      headers,
    });

    console.log("Teams Personal Details Created", response);
    return response;
    // }
  } catch (error) {
    console.log(error);
  }
};

export const createLawyersForTeamsProfile = async (body, lawyerId) => {

  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken || !body) {
      throw Error("No accessToken or body Provided");
    }
    console.log(body);
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
      // 'Content-Type': 'multipart/form-data',
    };
    const Body = body;
    const response = await axios.post(
      `${apiUrl}/api/v1/teams/lawyer/profile/${lawyerId}`,
      Body,
      {
        headers,
      }
    );

    console.log("Teams professional Details Created", response);
    return response;
    // }
  } catch (error) {
    console.log(error);
  }
};

export const getLawyersByTeamId = async () => {
  let lawyerId = "64ed9f6341348810b33b2ab8";
  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      throw Error("No accessToken or body Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
      //   Authorization: "Bearer eyJyb2xlIjpbIlJPTEVfTUVNQkVSIl0sImlkIjoiNjRlZDlmNjM0MTM0ODgxMGIzM2IyYWI4IiwiYWxnIjoiSFM1MTIifQ.eyJzdWIiOiI4MjEwNzYyNTU5IiwiaWF0IjoxNjkzMjk0NDU0LCJleHAiOjIwMDg5MTM2NTR9.0_rVNOeVtY-fpl38OY8XRULD_8I1do-oBM-ttq6EtV89mDjKCbkcwPF15HtmzETuxdI5pdauHp_Z_j2OhzLU_g",
      // 'Content-Type': 'multipart/form-data',
    };
    const response = await axios.get(`${apiUrl}/api/v1/teams/${lawyerId}`, {
      headers,
    });

    console.log("Teams Deatils", response);
    return response;
    // }
  } catch (error) {
    console.log(error);
  }
};
export const searchLawyer = async (phone) => {
  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      throw Error("No accessToken or body Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.get(`${apiUrl}/lawyer/search/${phone}`, {
      headers,
    });
    if (response.status === 200) {
      return response?.data?.data;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};
export const getTeamList = async () => {
  try {
    const accessToken = Cookies.get("accessToken");
    const lawyerId = Cookies.get("lawyerId");
    console.log(lawyerId);
    if (!accessToken||!lawyerId) {
      throw Error("No accessToken or lawyerId Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.get(`${apiUrl}/api/v1/teams/${lawyerId}/members`, {
      headers,
    });
    console.log(response);
    if (response.status === 200) {
      return response?.data[0]?.lawyersRecords;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};
export const assignCaseToLawyer = async (lawyerId,caseIds) => {
  try {
    const accessToken = Cookies.get("accessToken");
    console.log(lawyerId);
    if (!accessToken||!lawyerId||!caseIds) {
      throw Error("No accessToken or lawyerId Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${apiUrl}/api/v1/teams/cases`;
    const params = new URLSearchParams();
    params.append("lawyerId",lawyerId);
    params.append("caseId",caseIds);
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.post(urlWithParams,{}, {
      headers,
    });
    console.log(response);
    if (response.status === 201) {
      return response?.data;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const getCasesAssignedToLawyer = async(lawyerId)=>{
  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken||!lawyerId) {
      throw Error("No accessToken or lawyerId Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${apiUrl}/api/v1/cases/cases/member/all`;
    const params = new URLSearchParams();
    params.append("teamId",lawyerId);
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.get(urlWithParams, {
      headers,
    });
    console.log(response);
    if (response.status === 200) {
      return response?.data;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}
export const getTeamLawyerDetails = async(lawyerId)=>{
  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken||!lawyerId) {
      throw Error("No accessToken or lawyerId Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${apiUrl}/api/v1/lawyers/${lawyerId}`;
    const response = await axios.get(url, {
      headers,
    });
    console.log(response);
    if (response.status === 200) {
      return response?.data[0];
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}
export const revokeCaseAccess = async(lawyerId,caseId)=>{
  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken||!lawyerId||!caseId) {
      throw Error("No accessToken or lawyerId Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${apiUrl}/api/v1/teams/cases`;
    const params = new URLSearchParams();
    params.append("lawyerId",lawyerId);
    params.append("caseId",caseId);
    params.append("isRemove",1);
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.post(urlWithParams,{}, {
      headers,
    });
    console.log(response);
    if (response.status === 201) {
      return response?.data;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}
export const getTeamLawyerReports = async(lawyerId)=>{
  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken||!lawyerId) {
      throw Error("No accessToken or lawyerId Provided");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${apiUrl}/api/v3/dashboard/lawyer`;
    const params = new URLSearchParams();
    params.append("lawyerId",lawyerId);
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.get(urlWithParams, {
      headers,
    });
    console.log(response);
    if (response.status === 200) {
      return response?.data?.data;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}
