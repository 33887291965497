import Div from "@jumbo/shared/Div/Div";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import useSettings from "app/hooks/useSettings";
import { useSelector } from "react-redux";
import { updateAddressSettings } from "app/Apis/settings";
import { useFormik } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { AddressValidations } from "../../ValidationsProfile";

const CompleteAddressForm = ({
  Address,
  setConfirm,
  setSearchOpen,
  handleClose,
  setInitSettings,
  initSettings,
  AddressObject,
  editId,
  setEditId,

  // setIsEditing


}) => {
  const getSetting = useSettings();
  const theme = useTheme();
  const lawyerId = useSelector((state) => {
    return state?.onboardingInputs?.lawyerId;
  });
  const accessToken = useSelector((state) => {
    return state?.onboardingInputs?.accessToken;
  });

  // const [initSettings, setInitSettings] = useState(null);
  const handleSubmit = async (e, isActive) => {
    console.log(formik);
    console.log(formik.values);
    console.log(initSettings);
    console.log(lawyerId);
    console.log(accessToken);
    let addressDetails = initSettings?.addressDetails;
    if (addressDetails) {
      const index = addressDetails.findIndex((add) => add.addressId === editId);
      if (index !== -1) {
        addressDetails[index] = {
          ...addressDetails[index],
          ...formik.values,
          isActive: 1,
        };
      } else {
        addressDetails.push({ ...formik.values, isActive: 1 });
      }
    } else {
      addressDetails = [{ ...formik.values, isActive: 1 }];
    }
    const response = await updateAddressSettings(
      initSettings,
      addressDetails,
      lawyerId,
      accessToken
    );
    console.log(response);
    console.log("address details updated successfully");

    setInitSettings(response);
    handleClose();


    // setOpenSnackBar(true)


    if (editId) {
      setEditId("");
    }
  };
  let initial;
  if (AddressObject) {
    initial = {
      addressLabel: AddressObject?.addressLabel,
      completeAddress: AddressObject?.completeAddress,
      country: "India",
      landMark: AddressObject?.landMark,
      streetName: AddressObject?.streetName,
      isActive: 1,
    };
  } else {
    initial = {
      addressLabel: "",
      completeAddress: "",
      country: "India",
      landMark: "",
      streetName: "",
      isActive: 1,
    };
  }
  const formik = useFormik({
    initialValues: initial,
    validationSchema: AddressValidations,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    const getInitSettings = async () => {
      const response = await getSetting(lawyerId, accessToken);
      // console.log(response);
      setInitSettings(response);
    };
    getInitSettings();
  }, []);

  const handleCloseIcon = () => {
    handleClose();
    setConfirm(false);
    // setIsEditing(false);
  };

  return (
    <Div
      sx={{
        padding: "1.5rem 1.5rem 0.25rem 1.5rem",
        overflow: "auto",
      }}
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h3"
          color="black"
          sx={{
            fontSize: "1.875rem",
            fontWeight: 500,
          }}
        >
          Complete your address
        </Typography>
        <CloseIcon
          sx={{
            mt: 0.5,
            fontSize: "24px",
            cursor: "pointer",
          }}
          onClick={handleCloseIcon}
          // onClick={() => setConfirm(false)}
        />
      </Div>
      <Div>
        {/* <Typography
          sx={{
            width: "100%",
            margin: "0.5rem 0px 0.5rem 0px",
            fontSize: "1.15rem",
            fontWeight: 600,
            lineHeight: "150%",
            textTransform: "uppercase",
          }}
        >
          Address
        </Typography> */}

        <Div onClick={() => setSearchOpen(true)}>
          <TextField
            value={Address}
            disabled
            sx={{
              width: "100%",
              // border:"1px solid black",
              mt: "1rem",
              mb: "0.4rem",
              // px:"1rem",
              // py:"0.255rem",
              fontSize: "1rem",
              color: "black",
              borderRadius: "8px",
              cursor: "pointer",
              lineHeight: "1.5rem",
              fontWeight: 700,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            InputProps={{
              startAdornment: (
                <LocationOnOutlinedIcon
                  sx={{ color: theme.palette.primary.main }}
                />
              ),
              endAdornment: (
                <LoadingButton
                  variant="outlined"
                  onClick={() => setSearchOpen(true)}
                  sx={{
                    border: "0px",

                    "&:hover": {
                      border: "0px",
                      backgroundColor: "white",
                    },
                  }}
                >
                  Change
                </LoadingButton>
              ),
              sx: {
                "& input:hover": {
                  cursor: "pointer",
                },
              },
            }}
          />
        </Div>



        <TextField
          value={formik.values.completeAddress}
          placeholder="Complete Address"
          name={"completeAddress"}
          onChange={formik.handleChange}
          error={
            formik.errors.completeAddress && formik.touched.completeAddress
          }
          helperText={
            formik.touched.completeAddress ? formik.errors.completeAddress : ""
          }
          sx={{ width: "100%", mt: "0.875rem", mb: "0.625rem" }}
        />
        <TextField
          value={formik.values.streetName}
          placeholder="Street Name"
          name={"streetName"}
          error={formik.errors.streetName && formik.touched.streetName}
          helperText={formik.touched.streetName ? formik.errors.streetName : ""}
          onChange={formik.handleChange}
          sx={{ width: "100%", mt: "0.875rem", mb: "0.625rem" }}
        />
        <TextField
          value={formik.values.landMark}
          placeholder="Landmark"
          name={"landMark"}
          error={formik.errors.landMark && formik.touched.landMark}
          helperText={formik.touched.landMark ? formik.errors.landMark : ""}
          onChange={formik.handleChange}
          sx={{ width: "100%", mt: "0.875rem", mb: "0.625rem" }}
        />
        <Div>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="addressType"
              defaultValue="office"
              name="addressLabel"
              value={formik.values.addressLabel}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="office"
                control={
                  <Radio
                    sx={{
                      "color": theme.palette.secondary.main,
                      "&.Mui-checked": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  />
                }
                label="Office"
              />
              <FormControlLabel
                value="home"
                control={
                  <Radio
                    sx={{
                      "color": theme.palette.secondary.main,
                      "&.Mui-checked": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  />
                }
                label="Home"
              />
              <FormControlLabel
                value="court"
                control={
                  <Radio
                    sx={{
                      "color": theme.palette.secondary.main,
                      "&.Mui-checked": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  />
                }
                label="Court"
              />
            </RadioGroup>
          </FormControl>
        </Div>
      </Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          type="submit"
          onClick={(e) => formik.handleSubmit(e, 1)}
          sx={{
            "backgroundColor": "black",
            "width": "45%",
            "color": "white",
            "p": 1,
            "mb": "5px",
            "fontWeight": 500,
            "fontSize": "1rem",
            "&:hover": {
              backgroundColor: "#eabf4e",
            },
          }}
        >
          Save Address
        </LoadingButton>
      </Div>
    </Div>
  );
};
export default CompleteAddressForm;
