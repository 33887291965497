import React, { useState } from "react";
import { CardContainer, OnboardingContainer, Screen1Container } from "./style";

//card import
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import lawyer from "../../../app/lawinzoImages/lawyer.png";
import client from "../../../app/lawinzoImages/client.png";
import { border } from "@mui/system";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { defaultRole } from "app/redux/actions/OnboardingInputs";

function Screen1({
  activeStep,
  progressIncrement,
  progressDecrement,
  screenState,
}) {
  const dispatch = useDispatch();
  const assignedRole = useSelector(
    (state) => state?.onboardingInputs?.assignedRole
  );
  // const [activeStep, setActiveStep] = React.useState(0);
  const [selectedCard, setSelectedCard] = useState(
    assignedRole=== "ROLE_LAWYER" 
     ? "ROLE_LAWYER"
      :assignedRole === "ROLE_CLIENT"
        ? "ROLE_CLIENT" 
          : " "
  );

  const CardSelect = (value) => {
    if (value === 1) {
      setSelectedCard("ROLE_LAWYER");
      console.log("lawyer selected");
    } else {
      setSelectedCard("ROLE_CLIENT");
      console.log("client selected");
    }
  };

  const IncrementFn = () => {
    console.log(selectedCard);
    if (selectedCard === "ROLE_CLIENT") {
      dispatch(defaultRole({role:"ROLE_CLIENT",onboarding:false}));
    } else if (selectedCard === "ROLE_LAWYER") {
      dispatch(defaultRole({role:"ROLE_LAWYER",onboarding:true}));
    }

    progressIncrement();
  };
  return (
    <div
      sx={{
        diplay: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          marginTop: "30px",
          diplay: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{ marginBottom: "20px", fontSize: "16px", fontWeight: 600 }}
        >
          Hi We are happy to have you onboard.
        </Typography>
        <Typography
          sx={{ marginBottom: "20px", fontSize: "14px", fontWeight: 500 }}
        >
          This is how Lawyer & Students on Vakily will get to know you
        </Typography>
      </div>
      {/* <CardContainer> */}
      <Div
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 3,
          flexDirection: {
            lg: "row",
            xs: "column",
          },
        }}
      >
        <Card
          onClick={() => CardSelect(1)}
          sx={{
            width: { xs: "100%", lg: "40%" },
            mt: { xs: "20px", lg: "0px" },
            p: 1,
            border: selectedCard === "ROLE_LAWYER" ? "2px solid #EABF4E" : "",
          }}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="100"
              style={{ objectFit: "contain" }}
              image={lawyer}
              alt="green iguana"
            />
            <CardContent>
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "20px",
                }}
                component="div"
              >
                Lawyer
              </Typography>
              <Typography
                style={{ textAlign: "center", fontWeight: 500 }}
                variant="body1"
              >
                Lawyers/Law firm Professional, who serves legal services.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          onClick={() => CardSelect(2)}
          sx={{
            width: { xs: "100%", lg: "40%" },
            mt: { xs: "20px", lg: "0px" },
            p: 1,
            border: selectedCard === "ROLE_CLIENT" ? "2px solid #EABF4E" : "",
          }}
        >
          <CardActionArea
            sx={{
              width:"100"
            }}
          >
            <CardMedia
              component="img"
              height="100"
              image={client}
              style={{ objectFit: "contain" }}
              alt="green iguana"
            />
            <CardContent>
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "20px",
                }}
                component="div"
              >
                Client
              </Typography>
              <Typography
                style={{ textAlign: "center", fontWeight: "500" }}
                variant="body1"
              >
                Users, who wants legal service.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Div>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 6,
        }}
      >
        <LoadingButton
          disabled={activeStep !== 1 ? false : true}
          onClick={progressDecrement}
          style={{
            backgroundColor: activeStep !== 1 ? "black" : "",
          }}
          variant="contained"
          sx={{ width: 100, padding: 1 }}
        >
          Back
        </LoadingButton>

        <LoadingButton
          // onClick={handleNext}
          onClick={IncrementFn}
          style={{
            backgroundColor: "black",
          }}
          variant="contained"
          sx={{ width: 100, padding: 1 }}
        >
          Continue
        </LoadingButton>
      </Div>
    </div>
  );
}

export default Screen1;
