import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCaseByCaseId } from "app/Apis/case";
import Cookies from "js-cookie";
import Div from "@jumbo/shared/Div";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, CircularProgress, Snackbar, Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateCaseListItem } from "app/redux/actions/case";
import Document from "./Document";
import CallBack from "./CallBack";
import Notes from "./Notes";
import Payment from "./Payment";
import About from "./About";
import { reset } from "app/redux/actions/enquiry";

const EnquiryDetailAndEdit = ({ item, setEnquiryDetails ,setShowUpdate}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(location.search);
  const caseId = queryParameters.get("caseId");
  const redirect = queryParameters.get("redirect");
  const menuValue = queryParameters.get("value");
  const [caseDetails, setCaseDetails] = useState(null);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonValue, setButtonValue] = React.useState(0);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  console.log(menuValue);
  const handleNextButton = () => {
    if(value===3){
      setEnquiryDetails(false);
      dispatch(reset());
      setShowUpdate((prev=>!prev));
    }
    if (value !== 3) {
      setValue((prevButtonValue) => prevButtonValue + 1);
    }
    setButtonValue((prevButtonValue) => prevButtonValue + 1);
    
  };
  const [openPaymentSnackBar, setOpenPaymentSnackBar] = useState(true);
  console.log("button value ", buttonValue);

  const handleBackButton = () => {
    if (value !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValue((prevButtonValue) => prevButtonValue - 1);
    } else {
      setEnquiryDetails(false);
      dispatch(reset());
      setShowUpdate((prev=>!prev));
    }
  };
  const getValue = (v) => {
    if (v === 0) {
      return 3;
    } else if (v === 1) {
      return 2;
    } else if (v === 2) {
      return 4;
    } else if (v === 3) {
      return 6;
    } else if (v === 4) {
      // debugger;
      return 0;
    } else {
      return 0;
    }
  };
  const [value, setValue] = React.useState(menuValue ? getValue(menuValue) : 0);
  const dispatch = useDispatch();
  const UpdateCaseListItem = (value) => {
    dispatch(updateCaseListItem(value));
  };
  const getAllCases = async () => {
    setIsLoading(true);
    const accessToken = Cookies.get("accessToken");
    const response = await getCaseByCaseId(accessToken, caseId);
    console.log(response);
    if (response) {
      console.log(response);
      setCaseDetails(response[0]);
      UpdateCaseListItem(response[0]);
      setIsLoading(false);
    }
  };
  const [snackBarText, setSnackBarText] = useState("");
  useEffect(() => {
    setValue(menuValue ? getValue(parseInt(menuValue)) : 0);
    console.log(value, buttonValue);
  }, [menuValue]);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <>
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{
              width: "100%",
              pr: 3,
              pl: 3,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{snackBarText}</Typography>
          </Alert>
        </Snackbar>
      )}

      <Div
        sx={{
          pl: { sm: "0", lg: "8%" },
          pr: { sm: "0", lg: "8%" },
          mt: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Div
          sx={{
            maxWidth: { xs: 320, sm: 600, md: 700, lg: 1350 },
            mt: -1,
          }}
        >
          <Tabs
            indicatorColor="secondary"
            textColor="secondary"
            value={value}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
              }}
              label="Enquiry Details"
              onClick={() => {
                setValue(0);
                setButtonValue(0);
              }}
            />
            <Tab
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
              }}
              label="Documents"
              onClick={() => {
                setValue(1);
                setButtonValue(1);
              }}
            />
            <Tab
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
              }}
              label="Notes"
              onClick={() => {
                setValue(2);
                setButtonValue(2);
              }}
            />
            <Tab
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
              }}
              label="Payment"
              onClick={() => {
                setValue(3);
                setButtonValue(3);
              }}
            />
          </Tabs>
        </Div>
        {isLoading ? (
          <Div
            sx={{
              pl: { sm: "0", lg: "8%" },
              pr: { sm: "0", lg: "8%" },
              mt: 2,
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress color="secondary" />
            <Typography variant={"h5"}>Loading</Typography>
          </Div>
        ) : (
          <Div
            sx={{
              minHeight: "500px",
              width: { xs: 280, sm: 600, md: 700, lg: 1000 },
            }}
          >
            {value === 0 ? (
              <About
                enquiryDetails={item}
                handleNextButton={handleNextButton}
                handleBackButton={handleBackButton}
                setUpdate={setUpdate}
              />
            ) : value === 1 ? (
              <Document
                enquiryDetails={item}
                handleNextButton={handleNextButton}
                handleBackButton={handleBackButton}
                setUpdate={setUpdate}
              />
            ) : value === 2 ? (
              <Notes
                enquiryDetails={item}
                handleNextButton={handleNextButton}
                handleBackButton={handleBackButton}
                setUpdate={setUpdate}
              />
            ) : value === 3 ? (
              <Payment
                enquiryDetails={item}
                handleNextButton={handleNextButton}
                handleBackButton={handleBackButton}
                setUpdate={setUpdate}
              />
            ) : (
              ""
            )}
          </Div>
        )}
      </Div>
    </>
  );
};

export default EnquiryDetailAndEdit;
