import { apiHeader, apiUrl } from "app/config";
import Cookies from "js-cookie";
import axios from "axios";
import store from "../redux/store";
import { updateCaseList } from "app/redux/actions/case";
import { filter } from "app/utils/filter";

const getSearchResults = async (accessToken, mobileNo, pageSize) => {
	try {
		console.log(accessToken, mobileNo);
		const params = new URLSearchParams();
		params.append("mobileNo", mobileNo);
		// params.append("peopleType", peopleType);
		params.append("size", pageSize);
		let headers = new Headers();
		headers.append("Content-Type", "application/json");
		headers.append("x-api-key", apiHeader);
		headers.append("Authorization", "Bearer " + accessToken);
		const url = apiUrl + "/api/v1/people";
		const urlWithParams = `${url}?${params.toString()}`;
		console.log(urlWithParams);
		const response = await fetch(urlWithParams, {
			method: "GET",
			headers,
		});

		if (response.ok) {
			const data = await response.json();
			console.log(data);
			return data;
		} else {
			throw new Error("Request failed with status " + response.status);
		}
	} catch (error) {
		console.error(error);
	}
};

export default getSearchResults;

export const getCaseByCaseId = async (accessToken, caseId) => {
	try {
		if (!accessToken || !caseId) {
			throw Error("at and cid Not found");
		}
		console.log(accessToken, caseId);
		const headers = {
			"x-api-key": apiHeader,
			Authorization: `Bearer ${accessToken}`,
		};
		const url = apiUrl + `/api/v2/cases/${caseId}`;

		const response = await axios.get(url, {
			headers,
		});
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const getHearingsList = async (
	accessToken,
	pageNumber,
	pageSize,
	basicFilter,
	startDate,
	endDate
) => {
	const date = filter(basicFilter, startDate, endDate);
	try {
		console.log(accessToken);
		const params = new URLSearchParams();
		params.append("type", "lawyer");
		params.append("page", pageNumber);
		params.append("size", pageSize);
		params.append("fromDate", date?.startDate);
		params.append("toDate", date?.endDate);
		let headers = new Headers();
		headers.append("Content-Type", "application/json");
		headers.append("x-api-key", apiHeader);
		headers.append("Authorization", "Bearer " + accessToken);
		const url = apiUrl + "/api/v1/hearings";
		const urlWithParams = `${url}?${params.toString()}`;
		console.log(urlWithParams);
		const response = await fetch(urlWithParams, {
			method: "GET",
			headers,
		});

		if (response.ok) {
			const data = await response.json();
			console.log(data);
			return data;
		} else {
			throw new Error("Request failed with status " + response.status);
		}
	} catch (error) {
		console.error(error);
	}
};

export const getCases = async (accessToken) => {
	console.time("redux");
	const casesListFromRedux = store.getState().case.cases;
	console.timeEnd("redux");

	console.log("RREDUX", casesListFromRedux);
	if (casesListFromRedux?.length > 0) {
		console.log("from redux");
		return casesListFromRedux;
	} else {
		console.time("API");
		console.log("from api");

		try {
			console.log(accessToken);
			let headers = new Headers();
			headers.append("Content-Type", "application/json");
			headers.append("x-api-key", apiHeader);
			headers.append("Authorization", "Bearer " + accessToken);
			const params = new URLSearchParams();
			params.append("isList", 1);
			params.append("page", 0);
			params.append("size", 100); //change and request from api for all list
			const url = apiUrl + "/api/v2/cases";
			const urlWithParams = `${url}?${params.toString()}`;
			// const url = apiUrl + "/api/v1/cases";
			console.log(url);
			const response = await fetch(urlWithParams, {
				method: "GET",
				headers,
			});

			if (response.ok) {
				const data = await response.json();
				store.dispatch(updateCaseList(data.cases));

				console.log(data);
				console.timeEnd("API");
				return data.cases;
			} else {
				throw new Error("Request failed with status " + response.status);
			}
		} catch (error) {
			console.error(error);
		}
	}
};

export const getHearingPurpose = async (accessToken) => {
	try {
		console.log(accessToken);
		let headers = new Headers();
		headers.append("Content-Type", "application/json");
		headers.append("x-api-key", apiHeader);
		headers.append("Authorization", "Bearer " + accessToken);

		const params = new URLSearchParams();
		params.append("size", 50);
		const url = apiUrl + "/api/v1/hearingPurpose";
		const urlWithParams = `${url}?${params.toString()}`;
		console.log(urlWithParams);
		const response = await fetch(urlWithParams, {
			method: "GET",
			headers,
		});

		if (response.ok) {
			const data = await response.json();
			console.log(data);
			return data;
		} else {
			throw new Error("Request failed with status " + response.status);
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateHearingDetails = async (
	hearingDate,
	hearingDescription,
	hearingNotes,
	caseId,
	lawyerId,
	accessToken
) => {
	if (!lawyerId) {
		console.log("no lawyer id provided");
	}
	if (!hearingDate) {
		console.log("no hearing date provided");
	}
	if (!hearingNotes) {
		console.log("no hearing description provided");
	}
	if (!caseId) {
		console.log("no caseId provided");
	}
	const hearingDetails = {
		// ...prevSettings,
		caseId: caseId,
		lawyerId: lawyerId,
		hearingDate: hearingDate,
		hearingDescription: hearingDescription,
		hearingNotes: hearingNotes,
	};
	try {
		let headers = new Headers();
		headers.append("Content-Type", "application/json");
		headers.append("x-api-key", apiHeader);
		headers.append("Authorization", "Bearer " + accessToken);
		const response = await fetch(apiUrl + `/api/v1/hearings`, {
			method: "POST",
			headers,
			body: JSON.stringify(hearingDetails),
		});
		if (response.status === 200) {
			const data = await response.json();
			// console.log("OTP sent");
			return { data: data };
		}
	} catch (err) {
		console.log(err);
	}
};

export const getCasesList = async (
	accessToken,
	pageNumber = 0,
	pageSize = 5,
	isList = 1,
	basicFilter,
	fromDate,
	toDate
) => {
	const date = filter(basicFilter, fromDate, toDate);
	try {
		let headers = new Headers();
		// headers.append("Content-Type", "application/json");
		headers.append("x-api-key", apiHeader);
		headers.append("Authorization", "Bearer " + accessToken);
		const params = new URLSearchParams();
		params.append("isList", isList);
		params.append("page", pageNumber);
		params.append("size", pageSize);
		if (basicFilter && date?.startDate && date?.endDate) {
			params.append("fromDate", date?.startDate);
			params.append("toDate", date?.endDate);
		}
		const url = apiUrl + "/api/v2/cases";
		const urlWithParams = `${url}?${params.toString()}`;
		const response = await fetch(urlWithParams, {
			method: "GET",
			headers,
			// body: JSON.stringify(obj),
		});

		// console.log(response);
		if (response.status === 200) {
			const data = await response.json();
			console.log("all cases fetched", data);
			return data;
		}
	} catch (e) {
		console.log(e);
	}
};

export const getCasesReport = async () => {
	try {
		const accessToken = Cookies.get("accessToken");
		const lawyerId = Cookies.get("lawyerId");
		const params = new URLSearchParams();
		params.append("lawyerid", lawyerId);
		let headers = new Headers();
		headers.append("x-api-key", apiHeader);
		headers.append("Authorization", "Bearer " + accessToken);
		const urlWithParams = `https://api.lawinzo.com/node/api/v1/dashboard/getCases?${params.toString()}`;
		const response = await axios.get(urlWithParams, { headers });

		console.log(response);
		if (response.status === 200) {
			console.log("case report fetched", response);
			return response?.data?.data[0]?.report;
		}
	} catch (e) {
		console.log(e);
	}
};

export const casePayment = async (caseId, order_id) => {
	try {
		if (!caseId || !order_id) {
			throw Error("Provide all parameters");
		}
		const accessToken = Cookies.get("accessToken");
		const headers = {
			"x-api-key": apiHeader,
			Authorization: "Bearer " + accessToken,
		};
		const body = {
			caseId,
			payments: [{ order_id }],
		};
		const url = apiUrl + "/api/v2/cases?pageNo=9";
		const response = await axios.post(url, body, { headers });
		if (response) {
			return response?.data;
		}
		return null;
	} catch (err) {
		console.log(err);
	}
};
export const caseClient = async (caseId, clientArray) => {
	try {
		if (!caseId || !clientArray) {
			throw Error("Provide all parameters");
		}
		const accessToken = Cookies.get("accessToken");
		const headers = {
			"x-api-key": apiHeader,
			Authorization: "Bearer " + accessToken,
		};
		const body = {
			caseId,
			clients: clientArray,
		};
		const url = apiUrl + "/api/v2/cases?pageNo=3";
		const response = await axios.post(url, body, { headers });
		// debugger;
		if (response) {
			return response?.data;
		}
		return null;
	} catch (err) {
		console.log(err);
	}
};
export const caseOppClient = async (caseId, clientArray) => {
	try {
		if (!caseId || !clientArray) {
			throw Error("Provide all parameters");
		}
		const accessToken = Cookies.get("accessToken");
		const headers = {
			"x-api-key": apiHeader,
			Authorization: "Bearer " + accessToken,
		};
		const body = {
			caseId,
			oppositeClients: clientArray,
		};
		const url = apiUrl + "/api/v2/cases?pageNo=5";
		const response = await axios.post(url, body, { headers });
		// debugger;
		if (response) {
			return response?.data;
		}
		return null;
	} catch (err) {
		console.log(err);
	}
};
export const caseWitness = async (caseId, clientArray) => {
	try {
		if (!caseId || !clientArray) {
			throw Error("Provide all parameters");
		}
		const accessToken = Cookies.get("accessToken");
		const headers = {
			"x-api-key": apiHeader,
			Authorization: "Bearer " + accessToken,
		};
		const body = {
			caseId,
			witness: clientArray,
		};
		const url = apiUrl + "/api/v2/cases?pageNo=6";
		const response = await axios.post(url, body, { headers });
		// debugger;
		if (response) {
			return response?.data;
		}
		return null;
	} catch (err) {
		console.log(err);
	}
}
export const caseCorporate = async (caseId, clientArray) => {
	try {
		if (!caseId || !clientArray) {
			throw Error("Provide all parameters");
		}
		const accessToken = Cookies.get("accessToken");
		const headers = {
			"x-api-key": apiHeader,
			Authorization: "Bearer " + accessToken,
		};
		const body = {
			caseId,
			corporateClients: clientArray,
		};
		const url = apiUrl + "/api/v2/cases?pageNo=4";
		const response = await axios.post(url, body, { headers });
		// debugger;
		if (response) {
			return response?.data;
		}
		return null;
	} catch (err) {
		console.log(err);
	}
}
export const deleteCase = async (Case) => {
	try {
		if (!Case) {
			throw Error("Provide all parameters");
		}
		const accessToken = Cookies.get("accessToken");
		const headers = {
			"x-api-key": apiHeader,
			Authorization: "Bearer " + accessToken,
		};
		const body = {
			...Case,
			isActive: 1,
		};
		const url = apiUrl + "/api/v2/cases";
		const response = await axios.post(url, body, { headers });
		// debugger;
		if (response) {
			return response?.data;
		}
		return null;
	} catch (err) {
		console.log(err);
	}
};

export const getTeamCases = async () => {
	try {
		const accessToken = Cookies.get("accessToken");
		if (!accessToken) {
			throw Error("No accessToken");
		}
		const headers = {
			"x-api-key": apiHeader,
			Authorization: `Bearer ${accessToken}`,
		};
		const response = await axios.get(`${apiUrl}/api/v2/cases/cases/team/all`, {
			headers,
		});
		console.log(response);
		if (response.status === 200) {
			return response?.data;
		}
		return null;
	} catch (err) {
		console.log(err);
	}
};
