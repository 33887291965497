import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import { Link } from "react-router-dom";
import LawyerListLayout from "./LawyerListLayout";
import TeamSkeletons from "./TeamSkeletons";
import HomeIcon from "@mui/icons-material/Home";
import { GavelOutlined, Diversity3Outlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddLawyerLayout from "./AddLawyerLayout"
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getTeamList } from "app/Apis/teams";
import Cookies from "js-cookie";
function AddLawyer() {
  const [addlawyer,setAddLawyer]=React.useState(false)
  const [loading, setLoading] = React.useState(false);
  const [teamList,setTeamList] = React.useState([]);
  const lawyerId = Cookies.get("lawyerId");
  const getLawyerTeamList = async()=>{
    try{
      const response = await getTeamList();
      console.log(response);
      if(response){
        const filteredArray = response?.filter((lawyer)=>lawyer?.lawyerId!==lawyerId);
        setTeamList(filteredArray);
      }
      setLoading(false);
    }catch(err){
      console.log(err);
    }
  }
  useEffect(() => {
    setLoading(true);
    getLawyerTeamList();
  }, []);

  if(teamList){
    return(
      <LawyerListLayout teamList={teamList} loading={loading}/>
    )
  }
  else if(addlawyer){
    return(
<AddLawyerLayout setAddLawyer={setAddLawyer}/> 
    )
  }
  return (
    <>
      <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      <Link
            href="/home"
            underline="none"
            style={{
              textDecoration:"none"
            }}
          >
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
          Home
        </Typography>
        </Link>

        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Diversity3Outlined
            sx={{ fontSize: 20, mr: 0.5 }}
            fontSize="inherit"
          />
          All Member
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
          height:"95%"
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Typography display={{display: {xs:"none",sm:"flex"}}} variant={"h5"}>My Team</Typography>
              {/* Add New */}
              <Link style={{ textDecoration: "none" }}>
                <Button
                  onClick={()=>setAddLawyer(true)}
                  style={{ fontWeight: "700" }}
                  startIcon={<AddIcon />}
                  color="secondary"
                >
                  Add Lawyer
                </Button>
              </Link>
            </Div>
            
         
        
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Div
            sx={{ width: { xs: "60%", sm: "30%", md: "150px" }, height: "30%" }}
          >
            <img
              src={NoCaseImage}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt=""
            />
          </Div>
          <Typography
            sx={{
              fontSize: { xs: "18px", md: "24px" },
              textAlign: "center",
              mt: 2,
            }}
          >
       
          </Typography>

          <Div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
            }}
          >
           
            {/* </Link> */}
            {/* <Typography sx={{ mb: 2 }}>Or</Typography>
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                color: "secondary.main",
                fontWeight: 500,
              }}
            >
              Import Data
            </Typography> */}
          </Div>
        </Div>
          {/* <Div sx={{ mb: 10 }}>
            {loading && <TeamSkeletons />}
            {!loading && <TeamList />}
          </Div> */}
          {/* <Div
            sx={{ position: "absolute", bottom: 20, right: { sm: 0, lg: 25 } }}
          >
            <CardFooter />
          </Div> */}
        </Div>
      </Card>
    </React.Fragment>
      
    </>
  );
}

export default AddLawyer;
