import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { alpha, Paper, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import { teamData } from "../Teams/CardData";
import ActionsMenu from "./ActionsMenu";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import lawyerImage from "../../ImageAssets/lawyer.png";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ClientCard = ({ item, peopleList }) => {
  //const [expanded, setExpanded] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  console.log(item)
  const handleExpandClick = () => {
      setExpanded(!expanded);
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-1px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            m:0,
            padding: "12px 0",
            "&.Mui-expanded": {
              padding: "12px 0",
            },
            "&:hover": {
              cursor: "default",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
          },
        }}
      >
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore> */}

        <Div sx={{ flexShrink: 0, px: 1 }}>
          <Avatar
            sx={{ width: { sm: 50, lg: 70 }, height: { sm: 50, lg: 70 } }}
            alt={item?.peopleDetails?.user?.fullname}
            src={item?.peopleDetails?.profilePic ? item.profilePic : lawyerImage}
            variant="rounded"
          />
        </Div>

        <Div
          sx={{
            width: { xs: "auto", lg: "15%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item?.peopleDetails?.user?.fullname? item?.peopleDetails?.user?.fullname : item?.fullName}
          </Typography>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "20%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Phone Number
          </Typography>
          <Div sx={{ display: "flex", gap: 1,alignItems:"center" }}>
            <LocalPhoneOutlinedIcon color="secondary" />
            <Typography
              variant={"h3"}
              color={"text.secondary"}
              fontSize={14}
              fontWeight="700"
            >{item?.peopleDetails?.mobileNo ? item?.peopleDetails?.mobileNo : item?.mobileNo}</Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Email
          </Typography>
          <Div sx={{ display: "flex", gap: 1 ,alignItems:"center"}}>
            <EmailOutlinedIcon color="secondary" />
            <Typography
              variant={"h3"}
              color={"text.secondary"}
              fontSize={14}
              fontWeight="700"
            >{item?.peopleDetails?.emailId ? item?.peopleDetails?.emailId : "Email Address Not Provided" }</Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Address
          </Typography>
          <Div sx={{ display: "flex", gap: 1,alignItems:"center" }}>
            <LocationOnIcon color="secondary" />
            <Typography
              variant={"h3"}
              color={"text.secondary"}
              fontSize={14}
              fontWeight="700"
            >
              {item?.peopleDetails?.addressLine1? item?.peopleDetails?.addressLine1 : "Address Not Provided"}
            </Typography>
          </Div>
        </Div>

        {/* <Div
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            alignItems: "center",
            width: "10%",
            gap: 2,
          }}
        >
          <Chip
            label={item.peopleType} // change according to api
            color="secondary"
            size="medium"
            variant="outlined"
            sx={{
              fontSize: 14,
              fontWeight: "700",
              color: "rgb(234, 191, 78)",
              "& .MuiChip-avatar": {
                backgroundColor: "transparent",
                borderRadius: "50%",
              },
            }}
          />
        </Div> */}
      </AccordionSummary>

      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Typography variant={"h5"}>Details</Typography>

        <Div
          sx={{
            display: { xs: "flex", lg: "none" },
            minWidth: 0,
            flexDirection: "column",
          }}
        >
          <Div sx={{ mb: 3, maxWidth: 280 }}>
            <Div
              sx={{
                display: { xs: "flex", lg: "none" },
                flexDirection: "column",
                gap: 1,
              }}
            ></Div>
          </Div>
        </Div>
        <Typography variant={"body1"} color={"text.secondary"}></Typography>
      </Collapse>
    </Paper>
  );
};
/* Todo item prop define */
export default ClientCard;
