import React from "react";
import { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import useSettings from "app/hooks/useSettings";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { updateBankSettings } from "app/Apis/settings";
import { useSelector } from "react-redux";
import { BankValidations } from "../../ValidationsProfile";
import useMediaQuery from "@mui/material/useMediaQuery";
function EditBank({setSnackBarMessage,setOpenSnackBar,initSettings,setInitSettings,setEdiFormOpen}) {
  const lawyerId = useSelector((state)=>{return state?.onboardingInputs?.lawyerId});
  const accessToken = useSelector((state)=>{return state?.onboardingInputs?.accessToken});
  const [setting, setSetting] = useState({
    bankName: "",
    branchAddress: "",
    branchName: "",
    ifscCode: "",
    payeeName: "",
    bankAccountNumber: "",
    confirmBankAccountNumber: "",
  });
  // const [initSettings, setInitSettings] = useState({});


  const handleSubmit = async () => {
    console.log(formik.values);
    console.log(initSettings);
    console.log(lawyerId);
    console.log(accessToken);
    const response = await updateBankSettings(
      initSettings,
      { 
        bankName:formik.values.bankName,
        branchAddress:formik.values.branchAddress,
        branchName:formik.values.branchName,
        ifscCode:formik.values.ifscCode,
        isActive:1,
        payeeName:formik.values.payeeName,
        accountNumber:formik.values.bankAccountNumber
      },
      lawyerId,
      accessToken
    );
    console.log(response);
    setInitSettings(response);
    setEdiFormOpen(false);
    setOpenSnackBar(true)
    setSnackBarMessage("Bank Account Added Successfully!")
  };

  const isBelow990px = useMediaQuery((theme) => theme.breakpoints.down(990));


  const formik = useFormik({
    initialValues: {
      bankName: initSettings?.paymentDetails?.bankName || "",
      branchAddress: initSettings?.paymentDetails?.branchAddress || "",
      branchName: initSettings?.paymentDetails?.branchName || "",
      ifscCode: initSettings?.paymentDetails?.ifscCode || "",
      payeeName: initSettings?.paymentDetails?.payeeName || "",
      upiId: initSettings?.paymentDetails?.upiId || "",
      bankAccountNumber: setting.accountNumber || "",
      confirmBankAccountNumber: setting.confirmBankAccountNumber || "",
    },
    enableReinitialize: true,
    validationSchema: BankValidations,
    onSubmit: handleSubmit,
  });

  

  useEffect(() => {
    console.log(setting);
  }, [setting]);

  const fieldMargin = {
    marginTop: "0.875rem",
  };

  return (
    <Div>
      <form>
        <TextField
         sx={{
          ...fieldMargin,
          width: isBelow990px ? "100%" : "60%",
          backgroundColor: "white"
        }}
        
          name={"payeeName"}
          label="Payee Name"
          required
          color="secondary"
          inputProps={{ maxLength: 150 }}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.payeeName}
          error={formik.errors.payeeName && formik.touched.payeeName}
          helperText={formik.touched.payeeName? formik.errors.payeeName : ''}
        />
        <TextField
          sx={{
            ...fieldMargin,
            width: isBelow990px ? "100%" : "60%",
            backgroundColor: "white"
          }}
          name={"bankName"}
          label="Bank Name"
          required
          color="secondary"
          inputProps={{ maxLength: 150 }}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.bankName}
          error={formik.errors.bankName && formik.touched.bankName}
          helperText={formik.touched.bankName ? formik.errors.bankName : ""}
        />
        <TextField
          sx={{
            ...fieldMargin,
            width: isBelow990px ? "100%" : "60%",
            backgroundColor: "white"
          }}
          name={"bankAccountNumber"}
          label="Bank Account Number"
          required
          type="password"
          color="secondary"
          inputProps={{ maxLength: 150 }}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.bankAccountNumber}
          error={formik.errors.bankAccountNumber && formik.touched.bankAccountNumber}
          helperText={formik.touched.bankAccountNumber ? formik.errors.bankAccountNumber : ""}
        />
        <TextField
          sx={{
            ...fieldMargin,
            width: isBelow990px ? "100%" : "60%",
            backgroundColor: "white"
          }}
          name={"confirmBankAccountNumber"}
          label="Confirm Bank Account Number"
          required
          color="secondary"
          inputProps={{ maxLength: 150 }}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.confirmBankAccountNumber}
          error={formik.errors.confirmBankAccountNumber && formik.touched.confirmBankAccountNumber}
          helperText={formik.touched.confirmBankAccountNumber ? formik.errors.confirmBankAccountNumber : ""}
        />
        <TextField
          sx={{
            ...fieldMargin,
            width: isBelow990px ? "100%" : "60%",
            backgroundColor: "white"
          }}
          name={"branchName"}
          label="Branch Name"
          required
          color="secondary"
          inputProps={{ maxLength: 150 }}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.branchName}
             error={formik.errors.branchName && formik.touched.branchName}
          helperText={formik.touched.branchName ? formik.errors.branchName : ""}
        />
        <TextField
          sx={{
            ...fieldMargin,
            width: isBelow990px ? "100%" : "60%",
            backgroundColor: "white"
          }}
          name={"branchAddress"}
          label="Branch Address"
          required
          color="secondary"
          inputProps={{ maxLength: 150 }}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.branchAddress}
            error={formik.errors.branchAddress && formik.touched.branchAddress}
          helperText={
            formik.touched.branchAddress ? formik.errors.branchAddress : ""
          }
      
        />
     
        <TextField
          sx={{
            ...fieldMargin,
            width: isBelow990px ? "100%" : "60%",
            backgroundColor: "white"
          }}
          name={"ifscCode"}
          label="IFSC code"
          required
          color="secondary"
          inputProps={{ maxLength: 150 }}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.ifscCode}
          error={formik.errors.ifscCode && formik.touched.ifscCode}
          helperText={formik.touched.ifscCode ? formik.errors.ifscCode : "Example: SBIN0005943"}
        />
      </form>
      <LoadingButton
          type="submit"
          onClick={formik.handleSubmit}
          color="secondary"
       
          sx={{
            width: "200px",
            padding: 1,
            mt: "20px",
            mb: "10px",
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "#eabf4e",
            },
          }}
          variant="contained"
        >
        Add Bank Account
      </LoadingButton>
    </Div>
  );
}

export default EditBank;