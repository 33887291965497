import React from "react";
import { Grid, Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useSettings from "app/hooks/useSettings";
import AddressChip from "./addressChip";
import AddAddress from "./addAddress";
import { Alert, Snackbar } from "@mui/material";
const AddressPage = ({lawyerId,initSettings,setInitSettings,accessToken}) => {


  // const [isEditing, setIsEditing] = useState(false);

//   const [openSnackBar, setOpenSnackBar] = useState(false);
//   const handleSnackbarClose = (event, reason) => {
//     setOpenSnackBar(false);
//   };


  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    
  }; 
  const handleClose = () => {
    setOpen(false);
    // setIsEditing(false);
  } 
  const [editId, setEditId] = useState("");
  return (

   
    <JumboCardQuick
      // action={}
      title={<Typography sx={{ fontSize: "1.25rem" }}>Addresses</Typography>}
      headerSx={{
        borderBottom: 1,
        borderColor: "divider",
      }}
      wrapperSx={{ pt: 0 }}
    >
      <Grid
        container
        spacing={2}
        direction={"row"}
        // justifyContent={"space-evenly"}
        alignItems={"stretch"}
      >
        <Grid item xs={12} lg={6}>
          <AddAddress
          // setIsEditing={setIsEditing}
            setInitSettings={setInitSettings}
            initSettings={initSettings}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            editId={editId}
            setEditId={setEditId}
          />
        </Grid>
        {initSettings?.addressDetails ? (
          initSettings?.addressDetails.map((address, index) => {
            if (address.isActive) {
              console.log(address);
              return (
                <Grid item key={index} xs={12} lg={6}>
                  <AddressChip
                    Add={address}
                    // isEditing={isEditing}
                    // setIsEditing={setIsEditing}
                    editId={editId}
                    setEditId={setEditId}
                    handleOpen={handleOpen}
                    setInitSettings={setInitSettings}
                    initSettings={initSettings}
                    lawyerId={lawyerId}
                    accessToken={accessToken}
                  />
                </Grid>
              );
            }
          })
        ) : (
          <></>
        )}
      </Grid>
    </JumboCardQuick>

  );
};

export default AddressPage;