import { apiHeader, apiUrl } from "app/config";
import axios from "axios";
import Cookies from "js-cookie";
export const getPublicationByIds = async (sectionIds, page, size
  ,
  //  tags
   ) => {
  try {
    const accessToken = Cookies.get("accessToken");
    const url = `${apiUrl}/api/v1/judgments`;
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("sections", sectionIds);
    params.append("size", size);
    // if (tags) {
    //   params.append("tags", tags);
    // }
    const urlWithParams = `${url}?${params.toString()}`;
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
      // 'Content-Type': 'multipart/form-data',
    };
    const response = await axios.get(urlWithParams, { headers });
    if (response.status === 200) {
      return response?.data?.data;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
}

export const getPublicationList = async ( page, size, type="Article",caseName=null,court=null,sections=null,language="English") => {
  try {
    const accessToken = Cookies.get("accessToken");
    const url = `${apiUrl}/api/v1/judgments`;
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("size", size);
    params.append("isJudgement",type);
    params.append("type",type);
    if(caseName){
      params.append("caseName",caseName);
    }
    if(court){
      params.append("court",court);
    }
    if(sections){
      params.append("sections",sections);
    }
    if(language){
      params.append("language",language);
    }
    const urlWithParams = `${url}?${params.toString()}`;
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.get(urlWithParams, { headers });
    if (response.status === 200) {
      return response?.data?.data;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
}

export const addPublication = async (body,type="article") => {
  try {
    const accessToken = Cookies.get("accessToken");
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    }
    const params = new URLSearchParams();
    params.append("isJudgement",type);
    const url = `${apiUrl}/api/v1/judgments`;
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.post(
    urlWithParams,
      body,
      { headers }
    );
    console.log(response);
    if (response.status === 201) {
      return response?.data;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
}

export const getPublicationByPublicationsId = async(id)=>{
  try{
    const accessToken = Cookies.get("accessToken");
    if(!id||!accessToken){
      throw Error("error in getting");
    }
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    }
    const url = `${apiUrl}/api/v1/judgments/${id}`
    const response = await axios.get(url,{headers});
    console.log(response);
    if(response){
      return response?.data;
    }
    return null;
  }catch(err){
    console.log(err);
  }
}
