import React from "react";
import { Grid, Typography } from "@mui/material";
import TaskDashCard from "./TasksDashCard";
import Div from "@jumbo/shared/Div/Div";
import NoDataDisplay from "../NoDataDisplay/NoDataDisplay";

const TaskDashList = ({ taskList}) => {

  
  if (taskList.length===0) {
    return  <NoDataDisplay
    text={"No Tasks"}
    url={"/lawyers/todo?addTask=true"}
    buttonText={"Add Task"}
    minHeight={"350px"}
    marginLeft={"3rem"}

  />;
  }
  return (
    <Div sx={{ overflow: 'auto', height:{xs:"400px",sm:"220px"} }}>
    <Grid container spacing={2} sx={{mt:0}}>
      {taskList.map((item, index) => (
        <Grid item xs={12} key={index}>
        <TaskDashCard item={item} index={index} key={item?.paymentId} />
        </Grid>
      ))}
      
    </Grid>
    </Div>
  );
};

export default TaskDashList;
