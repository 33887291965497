import React, { useRef } from "react";
import Header from "./Header";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";

import UserProfileSidebar from "./UserProfileSidebar";
import { Alert, Snackbar, Typography, alpha } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "app/Apis/Login";
import Cookies from "js-cookie";
import { Grid } from "@mui/material";

import Professional from "./components/Professional Details/professional";
import BankDetails from "./components/Bank/bankDetails";
import Timing from "./components/Timing/timing";
import BudgetInfo from "./components/Budget/budget";
import { getSettings } from "app/Apis/settings";
import useSettings from "app/hooks/useSettings";

const UserProfile = () => {
  const personalRef = useRef(null);
  const professionalRef = useRef(null);
  const addressRef = useRef(null);
  const budgetRef = useRef(null);
  const timingRef = useRef(null);
  const bankAccountRef = useRef(null);
  const notificationRef = useRef(null);
 const[initSettings,setInitSettings] = useState(null)
  const handlePersonalTabClick = () => {
    const yOffset = -80;
    const y =
      personalRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const handleProfessionalTabClick = () => {
    const yOffset = -80;
    const y =
    professionalRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
    
  };
  const handleAddressTabClick = () => {
    const yOffset = -80;
    const y =
      addressRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const handleBudgetTabClick = () => {
    const yOffset = -80;
    const y =
      budgetRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const handleTimingTabClick = () => {
    const yOffset = -80;
    const y =
      timingRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const handleBankTabClick = () => {
    const yOffset = -80;
    const y =
      bankAccountRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const handleNotificationClick = () => {
    const yOffset = -80;
    const y =
      notificationRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  // const lawyerId = useSelector((state) => {
  //   return state?.onboardingInputs?.lawyerId;
  // });

  const accessToken = Cookies.get("accessToken");
  const lawyerId = Cookies.get("lawyerId");
  const [details, setDetails] = useState(null);
  const [shouldScrollToBank, setShouldScrollToBank] = useState(false);
  const getSetting = useSettings()
  const [showUpdate,setShowUpdate]=useState(false)
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile(accessToken); 
        setDetails(userProfile.data.data);
        console.log("User profile data fetched:", userProfile);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    const fetchSettings = async()=>{
      console.log("lawyerId",lawyerId)
     if(lawyerId && accessToken){ const response = await getSetting(lawyerId,accessToken)

      if(response){
setInitSettings(response)
console.log(response)
      }
    }
  }
    fetchUserProfile();
    fetchSettings()
  }, [showUpdate]);
  useEffect(() => {
    if (shouldScrollToBank) {
      bankAccountRef.current.scrollIntoView({ behavior: "smooth" });
      setShouldScrollToBank(false);
    }
  }, [shouldScrollToBank]);

  const { theme } = useJumboTheme();
  return (
      // sidebar={<UserProfileSidebar />}
      <>
      <JumboContentLayout
      header={
        <Header
          handleBudgetTabClick={handleBudgetTabClick}
          handlePersonalTabClick={handlePersonalTabClick}
          handleProfessionalTabClick={handleProfessionalTabClick}
          handleAddressTabClick={handleAddressTabClick}
          handleTimingTabClick={handleTimingTabClick}
          handleBankTabClick={handleBankTabClick}
          handleNotificationClick={handleNotificationClick}
          details={details}
        />
      }
    
      layoutOptions={{
        header: {
          sx: {
            mt: -4,
            mb: -7.25,
            mx: { xs: -4, lg: -6 },
            p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
            backgroundColor: "black",
            opacity:"90%",
            backgroundSize: "cover",
            color: "common.white",
            position: "relative",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha(theme.palette.common.black, 0.65),
            },
          },
        },
        sidebar: {
          sx: {
            mr: 3.75,
            width: { xs: "100%", lg: "33%" },
            [theme.breakpoints.down("lg")]: {
              minHeight: 0,
              mr: 0,
              order: 2,
            },
          },
        },
        wrapper: {
          sx: {
            [theme.breakpoints.down("lg")]: {
              flexDirection: "column",
            },
          },
        },
        main: {
          sx: {
            [theme.breakpoints.down("lg")]: {
              minHeight: 0,
            },
          },
        },
      }}
    >
     
      <Grid container columnSpacing={2} sx={{ rowGap: { xs: 2, md: 0 } }}>
        <Grid item md={4} xs={12}>
          <UserProfileSidebar
            handleAddressTabClick={handleAddressTabClick}
            handlePersonalTabClick={handlePersonalTabClick}
            personalRef={personalRef}
            addressRef={addressRef}
            handleNotificationClick={handleNotificationClick}
            notificationRef={notificationRef}
            initSettings = {initSettings}
            setInitSettings ={setInitSettings}
            lawyerId = {lawyerId}
            accessToken = {accessToken}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <div ref={professionalRef} id="personal">
                <Professional details={details} setShowUpdate={setShowUpdate} showUpdate={showUpdate}/>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div ref={bankAccountRef} id="bank">
                <BankDetails 
                     initSettings = {initSettings}
                     setInitSettings ={setInitSettings}
                />
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div ref={budgetRef} id="budget">
                <BudgetInfo
                  accessToken={accessToken}
                  lawyerId={lawyerId}
                  initSettings = {initSettings}
                  setInitSettings ={setInitSettings}
                  sx={{ mb: { xs: 3.75, lg: 0 } }}
                />
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div ref={timingRef} id="timing">
                <Timing accessToken={accessToken} lawyerId={lawyerId} 
                     initSettings = {initSettings}
                     setInitSettings ={setInitSettings}/>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </JumboContentLayout>
      </>
   
    
  );
};

export default UserProfile;
