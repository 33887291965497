import Div from "@jumbo/shared/Div";
import {
  Breadcrumbs,
  Card,
  Divider,
  InputBase,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
} from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import React, { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CardFooter from "../components/CardFooter";
import TeamSkeletons from "../Teams/TeamSkeletons";
import HomeIcon from "@mui/icons-material/Home";
import ClientList from "./ClientList";
import { CorporateFare, GroupOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import usePeople from "app/hooks/usePeople";
import { useState,lazy } from "react";
import AddIcon from "@mui/icons-material/Add";
import FilterBy from "./FilterBy";
import { Select, MenuItem, FormControl, Link, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NoPeople from "./NoPeople";
import { getPeopleByNumber } from "app/Apis/people";

const RenderPeople =  lazy(()=>{console.log("lazy"); return import("./RenderPeople")});
const optionsPageSize = ["5", "10", "20", "25", "50"];
const optionsPeopleTypes = [
  "Client",
  "Corporate Client",
  "Opposite Client",
  "Witness",
];

const ClientListLayout = ({ sx }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [addCase, setAddCase] = useState(false);
  const [showAddPeople, setShowAddPeople] = useState(false);
  const [peopleList, setPeopleList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState("5");
  const [peopleType, setPeopleType] = useState("Client");
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const addClient = queryParameters.get("addClient");
  console.log(queryParameters)
  const getAllPeople = usePeople();
  useEffect(() => {
    if(addClient){
      setShowAddPeople(true)
    }
    const getAllPeopleList = async () => {
      setIsLoading(true);
      const response = await getAllPeople(pageNumber, pageSize, peopleType);
      if (response) {
        console.log(response);
        setPeopleList(response?.content);
        setTotalPages(response?.data?.totalPages);
      }
      setIsLoading(false);
    };
    getAllPeopleList();
  }, [pageNumber, pageSize, showUpdate]);

  const handleClose = () => {
    setOpenModal(false);
    setShowAddPeople(false);
  };

  useEffect(() => {
    (async () => {
      if (searchQuery.length === 10) {
        setIsLoading(true);
        const data = await getPeopleByNumber(searchQuery);
        console.log("data", data);
        if (!data) {
          console.log("error");
        } else {
          if (data.length === 0) {
            setPeopleList([]);
            setAddCase(true);
          } else {
            setPeopleList(data);
            setAddCase(false);
          }
        }
        setIsLoading(false);
      } else if (searchQuery.length < 10) {
        if (searchQuery.length === 0) {
          setShowUpdate(!showUpdate);
        } else {
          setPeopleList([]);
        }
      }
    })();
  }, [searchQuery]);
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };
  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/home" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <GroupOutlined sx={{ mr: 0.5, fontSize: 20 }} />
          &nbsp;People
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
                alignContent: "center",
                gap: 9,
                flex: 1,
              }}
            >
              <Div
                sx={{
                  display: { xs: "none", sm: "flex" },
                  alignItems: "center",
                }}
              >
                <Div
                  style={{
                    height: "19px",
                    width: "5px",
                    backgroundColor: "#EABF4E",
                    marginRight: "8px",
                  }}
                ></Div>
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    lineHeight: 1.5,
                    fontWeight: 400,
                  }}
                >
                  People List
                </Typography>
              </Div>

              {!showAddPeople && (
                <>
                  <Button
                    style={{ fontWeight: "700" }}
                    startIcon={<AddIcon />}
                    color="secondary"
                    onClick={() => {
                      setAddCase(true);
                      setShowAddPeople(true);
                    }}
                  >
                    Add People
                  </Button>

                  <Div
                    sx={{
                      position: "relative",
                      width: "40%",
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    <Div
                      sx={{
                        padding: (theme) => theme.spacing(0, 1),
                        height: "100%",
                        position: "absolute",
                        pointerEvents: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 2,
                      }}
                    >
                      <SearchIcon color="secondary" />
                    </Div>
                    <InputBase
                      id="search-bar"
                      className="search"
                      placeholder="Search the client by Mobile Number"
                      size="small"
                      onChange={handleSearchChange}
                      value={searchQuery}
                      inputProps={{
                        maxLength: 10,
                      }}
                      sx={{
                        color: "inherit",
                        display: "flex",
                        borderRadius: 24,
                        backgroundColor: (theme) =>
                          theme.jumboComponents.JumboSearch.background,
                        paddingRight: "2rem", // Add space for close icon

                        "& .MuiInputBase-input": {
                          padding: (theme) => theme.spacing(1, 1, 1, 0),
                          paddingLeft: (theme) =>
                            `calc(1em + ${theme.spacing(3)})`,
                          transition: (theme) =>
                            theme.transitions.create("width"),
                          width: "100%",
                          height: 24,
                        },
                      }}
                    />
                    {searchQuery && ( // Only show close icon when there's a search query
                      <CloseIcon
                        color="secondary"
                        sx={{
                          position: "absolute",
                          right: "12px",
                          bottom: "11px", // Adjust the positioning as needed
                          cursor: "pointer",
                        }}
                        onClick={() => setSearchQuery("")}
                      />
                    )}
                  </Div>
                </>
              )}
            </Div>
            {!showAddPeople && (
              <>
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {/* Search Bar */}
                  <Div
                    sx={{
                      position: "relative",
                    }}
                  >
                    <FormControl>
                      <Select
                        labelId="select-label"
                        value={peopleType}
                        onChange={(e) => {
                          setPeopleType(e.target.value);
                          setShowUpdate(!showUpdate);
                        }}
                        sx={{ maxHeight: "40px", mr: "10px" }}
                      >
                        {optionsPeopleTypes.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Div>
                  <Div
                    sx={{
                      position: "relative",
                    }}
                  >
                    <FormControl>
                      <Select
                        labelId="select-label"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(e.target.value);
                          setShowUpdate(!showUpdate);
                        }}
                        sx={{ maxHeight: "40px", mr: "10px" }}
                      >
                        {optionsPageSize.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Div>
                  {/* <Div>
                    <Div sx={{ width: "100%" }}>
                      <Button
                        style={{ fontWeight: "600" }}
                        startIcon={<FilterAltOutlinedIcon />}
                        color="secondary"
                        onClick={() => setOpenModal(true)}
                      >
                        Filter By
                      </Button>
                    </Div>
                  </Div> */}
                </Div>
              </>
            )}
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10 }}>
            {showAddPeople ? (
              <Div
                sx={{
                  pl: { sm: "0", lg: "11%" },
                  pr: { sm: "0", lg: "11%" },
                  pt: "10px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <RenderPeople
                addClient={addClient}
                  peopleType={peopleType}
                  setPeopleType={setPeopleType}
                  setShowAddPeople={setShowAddPeople}
                  setShowUpdate={setShowUpdate}
                  showUpdate={showUpdate}
                />
              </Div>
            ) : isLoading ? (
              Array.from(Array(parseInt(pageSize))).map((item, index) => (
                <TeamSkeletons key={index} />
              ))
            ) : peopleList.length > 0 ? (
              <ClientList peopleList={peopleList} />
            ) : (
              <NoPeople
                setAddCase={setAddCase}
                addCase={addCase}
                showAddPeople={showAddPeople}
                setShowAddPeople={setShowAddPeople}
              />
            )}
          </Div>
          <Div
            sx={{ position: "absolute", bottom: 20, right: { sm: 0, lg: 25 } }}
          >
            {peopleList && (
              <CardFooter
                totalPages={totalPages}
                setPageNumber={setPageNumber}
              />
            )}
          </Div>
        </Div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "100%", sm: "300px" },
                height: { xs: "85%", sm: "400px" },
                bgcolor: "background.paper",
                border: "1px solid #000",
                borderRadius: "8px",
                boxShadow: 24,
                p: 4,
              }}
            >
              <span
                style={{
                  position: " absolute",
                  right: " -12px",
                  top: "-14px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  backgroundColor: "white",
                  textAlign: "center",
                  fontSize: "26px",
                }}
              >
                <CancelTwoToneIcon
                  fontSize="inherit"
                  onClick={handleClose}
                  variant
                />
              </span>
              <Div sx={{ width: "100%", height: "100%" }}>
                <FilterBy
                  peopleType={peopleType}
                  setPeopleType={setPeopleType}
                />
              </Div>
            </Box>
          </Fade>
        </Modal>
      </Card>
    </React.Fragment>
  );
};

export default ClientListLayout;
