import React from 'react';
import Div from '@jumbo/shared/Div/Div';
import { Typography,FormControl,RadioGroup,FormControlLabel,Radio,useTheme } from '@mui/material';

function FilterBy({peopleType,setPeopleType}) {
    const theme = useTheme();
  return (
    <Div>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Choose People Type
            </Typography>

            <Div>
              <FormControl>
                <RadioGroup
                  row
                  name="peopleType"
                  onChange={(e) =>
                    setPeopleType(e.target.value)
                  }
                  value={peopleType}
                  defaultValue={peopleType}
                >
                  <FormControlLabel
                    value="Client"
                    control={
                      <Radio
                        sx={{
                          color: theme.palette.secondary.main,
                          "&.Mui-checked": {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      />
                    }
                    label="Client"
                  />
                  <FormControlLabel
                    value="Opposite_Client"
                    control={
                      <Radio
                        sx={{
                          color: theme.palette.secondary.main,
                          "&.Mui-checked": {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      />
                    }
                    label="Opposite Client"
                  />
                  <FormControlLabel
                    value="Witness"
                    control={
                      <Radio
                        sx={{
                          color: theme.palette.secondary.main,
                          "&.Mui-checked": {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      />
                    }
                    label="Witness"
                  />
                </RadioGroup>
              </FormControl>
            </Div>
          </Div>
  )
}

export default FilterBy