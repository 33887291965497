import React from "react";
import Div from "@jumbo/shared/Div/Div";
import { Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function AddAddressChip({ setTimeFormOpen }) {
  return (
    <Div
      sx={{
        "flex": 1,
        "display": "flex",
        "alignContent": "center",
        "justifyContent": "center",
        "mt": 1,
        "padding": "8px 8px 0px 8px",
        "border": "1px solid rgb(232, 232, 232)",
        "borderRadius": "8px",
        "cursor": "pointer",
        "&:hover": {
          backgroundColor: "rgb(232, 232, 232)",
        },
        "height": "100%",
        "minHeight": "150px",
      }}
      onClick={() => setTimeFormOpen(true)}
    >
      <Div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddCircleOutlineIcon sx={{ mb: 0.5 }} />
        <Typography
          sx={{
            fontSize: "1rem",
            color: "rgb(79, 79, 79)",
            fontWeight: 500,
            textTransform: "capitalize",
            lineHeight: 1.5,
          }}
        >
          Add Timing
        </Typography>
      </Div>
    </Div>
  );
}

export default AddAddressChip;
