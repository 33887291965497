import React from "react";
import { Paper, Skeleton } from "@mui/material";

const Skeletons = () => {
  return (
    <Paper sx={{ mb: 1, p: 1 }}>
            <Skeleton />
            <Skeleton animation={"wave"} />
            <Skeleton animation={false} />
          </Paper>

  );
};

export default Skeletons;
