import * as React from "react";
import Div from "@jumbo/shared/Div";
import { Button, Typography } from "@mui/material";

import TaskList from "./TaskList";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import BreadCrumbs from "./Components/BreadCrumbs";

import { Card, Divider } from "@mui/material";
import CardFooter from "../components/CardFooter";
import NoTaskAdded from "./Components/NoTaskAdded";
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import TaskSkeletons from "./TaskSkeletons";
import { element } from "prop-types";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const optionsPageSize = ["5", "10", "20", "25", "50"];

const TaskListLayout = ({
  setAddTaskOpen,
  tasksList,
  setTaskAdded,
  setTaskEditItem,
  pageNumber,
  setPageNumber,
  totalPages,
  setTotalPages,
  setPageSize,
  pageSize,
  taskAdded,
  isLoading,
  getAllTasksList,
  optionsFilter,
  basicFilter,
  setBasicFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) => {
  return (
    <>
      <BreadCrumbs />
      <Card
        sx={{
          p: 3,
          position: "relative",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              mt:-1
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <Div sx={{ display: {xs:"none",sm:"flex"}, alignItems: "center" }}>
                <Div
                  style={{
                    height: "19px",
                    width: "5px",
                    backgroundColor: "#EABF4E",
                    marginRight: "8px",
                  }}
                ></Div>
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    lineHeight: 1.5,
                    fontWeight: 400,
                  }}
                >
                  All Tasks
                </Typography>
              </Div>
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={() => {
                  setAddTaskOpen(true);
                  setTaskAdded(false);
                }}
              >
                Add Task 
              </Button>
            </Div>
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
               <Div sx={{display:"flex",gap:1,alignItems:"center",paddingTop:"6px"}}>
        <Typography sx={{fontWeight:"bold",display:{xs:"none",sm:"block"}}}>Filter By</Typography>
        <FormControl >
          <Select
            labelId="select-label"
            value={basicFilter}
            onChange={(e) => setBasicFilter(e.target.value)}
            sx={{ backgroundColor: "white" ,minWidth:"100px",mb:1}}
          >
            {optionsFilter.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {basicFilter === "Choose Date" &&
              <>  
   <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label="Start Date"
        value={startDate}
        format="DD/MM/YYYY"
        onChange={(newValue) => setStartDate(newValue)}
        maxDate={endDate}
        sx={{backgroundColor: "white",width:"9rem",mb:1}}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          format="DD/MM/YYYY"
          minDate={startDate}
          onChange={(newValue) => setEndDate(newValue)}
          sx={{  backgroundColor: "white" ,width:"9rem",mb:1}}
        />
    </LocalizationProvider>

              {/* <BasicDatePicker  setFromDate={setFromDate}  setToDate={setToDate} /> */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "secondary.main",
                    mb: 1,
                    width: "20px",
                    height:"50px",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "black",
                    },
                  }

                  }
                  onClick={()=>getAllTasksList()}
                >
                  <ManageSearchIcon sx={{ fontSize: 34 }} />
                </Button>

              </>}
      </Div>
              <Div>
                <Select
                  labelId="select-label"
                  value={pageSize}
                  sx={{backgroundColor: "white",ml:2}}
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  {optionsPageSize.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Div>
              {/* <Div sx={{ flex: 1 }}>
                <Button
                  style={{ fontWeight: "600", minWidth: "100%" }}
                  startIcon={<FilterAltOutlinedIcon />}
                  color="secondary"
                  size="small"
                >
                  Filter By
                </Button>
              </Div> */}
    
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10 }}>
            {/* {loading && <TaskSkeletons />} */}
            {isLoading ? 
              Array.from(Array(parseInt(pageSize))).map((item,index)=>{
                return <TaskSkeletons key={index} />
              }):
              tasksList?.length> 0 ? (
                <TaskList
                  tasksList={tasksList}
                  setTaskEditItem={setTaskEditItem}
                  setAddTaskOpen={setAddTaskOpen}
                  setTaskAdded={setTaskAdded}
                  taskAdded={taskAdded}
                />
              )
             : (
              <NoTaskAdded setAddTaskOpen={setAddTaskOpen} />
            )}
          </Div>
          <Div
            sx={{
              position: "absolute",
              bottom: 20,
              right: { sm: 0, lg: 25 },
            }}
          >
            {tasksList && (
              <CardFooter
                setPageNumber={setPageNumber}
                totalPages={totalPages}
              />
            )}
          </Div>
        </Div>
      </Card>
    </>
  );
};

export default TaskListLayout;
