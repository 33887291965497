import React from 'react';
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Div from "@jumbo/shared/Div";
import { AccountBalanceWallet } from '@material-ui/icons';
import { AccountBalanceWalletOutlined, CurrencyRupee ,SouthWest} from '@mui/icons-material';


const DocumentPayments = ({balance}) => {
    return (
        <Card sx={{height: 100, backgroundImage: 'linear-gradient(135deg, hsla(46, 74%, 75%, 1) 17%, hsla(43, 79%, 61%, 1) 100%)        '}}>
            <CardActions disableSpacing sx={{p: 0, alignItems: 'stretch', height: '100%'}}>
                <Div sx={{
                    display: 'flex',
                    width: 126,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    backgroundColor: 'common.white',
                    borderRadius: '50%',
                    outline: 'solid 10px rgba(255, 255, 255, 0.2)',
                    margin: '0 10px 0 -60px'
                }}>
                    <Div sx={{
                        display: 'flex',
                        minWidth: 56,
                        height: 56,
                        mr: '6px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                          <SouthWest style={{fontSize: 36, color: "#EABF4E"}} />
                        {/* <FolderOpenIcon sx={{fontSize: 36}}/> */}
                        {/* <AccountBalanceWallet style={{fontSize: 36, color: "#EABF4E"}} /> */}
                        {/* <AccountBalanceWalletOutlined  color = {"#EABF4E"} sx={{fontSize: 36, }} /> */}
                    </Div>

                </Div>
                <CardContent sx={{p: 2.5, flex: 1, alignSelf: 'center'}}>
                    <Typography variant={"h4"} color={"common.white"}><CurrencyRupee/>{balance}</Typography>
                    <Typography variant={"h5"} color={"common.white"} mb={0}>Pending Balance</Typography>
                </CardContent>
            </CardActions>
        </Card>
    );
};

export default DocumentPayments;
