import {ASSET_IMAGES} from "../../../utils/constants/paths";

export const applicationsData = {
    colors: ['#F53E5E', '#F59691', '#F0C6A4','#BEC2A0','#7CAA95'],
        pieData: [
        {
            name: 'Supereme Court',
            value: 37.6,
        },
        {
            name: 'High Court',
            value: 21.5,
        },
        {
            name: 'District Court ',
            value: 29.8,
        },
        {
            name: 'Lower Court',
            value: 7.1,
        },
        {
            name: 'Special Court ',
            value: 4,
        },
      
    ],
        applicationsList: [`${ASSET_IMAGES}/dashboard/window.png`, `${ASSET_IMAGES}/dashboard/apple.png`, `${ASSET_IMAGES}/dashboard/android.png`],
};
