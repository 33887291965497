import React from 'react';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ScheduleList from "./ScheduleList";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";

const ScheduleCard = ({hearingList,setHearingEdit , setIsEditing,setAddHearing}) => {
    console.log(hearingList)

    return (
        <JumboContent sx={{mr:2}}>
          <ScheduleList
            hearingList={hearingList.sort((a, b) => new Date(a.hearingDate) - new Date(b.hearingDate))}
            setHearingEdit={setHearingEdit}
            setIsEditing={setIsEditing}
            setAddHearing={setAddHearing}
          />
        </JumboContent>
    );
};

export default ScheduleCard;
