import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import Collapse from "@mui/material/Collapse";
import { alpha, Button, Paper, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import DisplayDocumentPreview from "app/Components/Documents/DisplayDocuments/DisplayDocumentPreview";
import AddDocuments from "app/Components/Documents/AddDocuments/AddDocuments";
import { createEnquiry } from "app/Apis/Enquiry";
import { useSelector } from "react-redux";
import { paymentRequest } from "app/Apis/Payment";

const EnquiryItem = ({ item, showUpdate, setShowUpdate }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [addDocument, setAddDocument] = React.useState(false);
  const [documentsArray, setDocumentsArray] = React.useState(null);
  const [documentsAPIList, setDocumentsAPIList] = React.useState(null);
  const userId = useSelector((state) => state?.onboardingInputs?.userId);
  let popUpWindow =null;

  const handleClick = async () => {
    if (item?.payment?.order_status === "Success") {
      setExpanded(true);
      setAddDocument(true);
    } else {
      const posX = (window.screen.width)/3;
      const posY = (window.screen.height)/2;
      const width = 500;
      const height = 680;
      const popUpPosition = `height=${height},width=${width},left=${posX},top=${posY}`;
      popUpWindow = window.open(
        `https://erp.lawinzo.com/payment/initiate?order_id=${item?.payment?.order_id}&retry=1&platform=cloud`,
        "popup",
        popUpPosition
      );
      const checkClosed = setInterval(() => {
        if (popUpWindow.closed) {
          clearInterval(checkClosed);
          handlePopupClose();
        }
      }, 1000);
    }
  };

  const handleAddDocument = async () => {
    try {
      const response = await createEnquiry({
        ...item,
        documentLists: documentsAPIList,
      });
      console.log(response);
      if (response) {
        setAddDocument(false);
        setShowUpdate(!showUpdate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const enquiryType = (type) => {
    if (type === "Regular_Bail_Application") {
      return "Regular Bail Application";
    } else if (type === "Expert_Case_Review") {
      return "Expert Case Review";
    } else if (type === "Anticipatory_Bail_Application") {
      return "Anticipatory Bail Application";
    }
  };

  const caseType = (type) => {
    if (type === "Criminal_Case") {
      return "Criminal Case";
    } else if (type === "Civil_Case") {
      return "Civil Case";
    }
  };
  const courtType = (type) => {
    if (type === "District_Court") {
      return "District Court";
    } else if (type === "Special_Court") {
      return "Special Court";
    } else if (type === "High_Court") {
      return "High Court";
    } else if (type === "Supreme_Court") {
      return "Supreme Court";
    }
  };

  const handlePopupClose = (event)=>{
      console.log('Popup window is closed');
      setShowUpdate(!showUpdate);
  }

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-4px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",

            "&.Mui-expanded": {
              margin: "12px 0",
            },
            "&:hover": {
              cursor: "default",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
            "&:hover": {
              cursor: "default",
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>
        <Div
          sx={{
            width: { xs: "auto", lg: "20%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item?.enquiryTypes
              ? enquiryType(item?.enquiryTypes)
              : "Enquiry type"}
          </Typography>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "25%",
            flexShrink: 0,
            px: 2,
          }}
        >
          <Chip
            label={`FIR No. ${item?.firComplaintNo}`}
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Court
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>
              {item?.courtName
                ? courtType(item?.courtName)
                : "No Related Court "}
            </Typography>
          </Div>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Case Type
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>
              {item?.caseType ? caseType(item?.caseType) : "No Case Type added"}
            </Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            alignItems: "center",
            width: "15%",
            gap: 2,
          }}
        >
          <Chip
            label={
              item?.payment?.order_status === "Success"
                ? " Upload Documents"
                : "Pay Now"
            } // change according to api
            color="secondary"
            size="medium"
            sx={{ fontSize: 14, fontWeight: "700" }}
            onClick={handleClick}
          />
        </Div>
      </AccordionSummary>

      {/* down arrow content */}
      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Div>
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            Documents
          </Typography>
          <Typography variant={"body1"} color={"text.secondary"}>
            {item?.documentLists ? (
              <DisplayDocumentPreview documentLists={item?.documentLists} />
            ) : (
              "Complete Payment to add documents"
            )}
          </Typography>
        </Div>
        <Div>
          {addDocument && (
            <Div
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <AddDocuments
                documentsArray={documentsArray}
                setDocumentsArray={setDocumentsArray}
                documentsAPIList={documentsAPIList}
                setDocumentsAPIList={setDocumentsAPIList}
                type={"enquiry"}
              />
              <Div sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="Submit"
                  onClick={handleAddDocument}
                  color="secondary"
                  style={{
                    backgroundColor: "black",
                  }}
                  sx={{ padding: 1 }}
                  variant="contained"
                >
                  Save Documents
                </Button>
              </Div>
            </Div>
          )}
        </Div>
      </Collapse>
    </Paper>
  );
};

export default EnquiryItem;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));
