import React, { useEffect, useState } from "react";
import { Autocomplete, Typography, Button } from "@mui/material";
import { NoCaseContainer } from "../MyCases/style";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import Div from "@jumbo/shared/Div";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import WitnessCard from "./WitnessCard";
import RenderAccordian from "./RenderAccordian";
import { useFormik } from "formik";
import { MobileSearchValidations } from "app/utils/validators";
import { getPeopleByNumber } from "app/Apis/people";

function RenderClient({
  formDisable,
  count,
  name,
  clientNo,
  setClientNo,
  handleNextButton,
  selectedPeople,
  setSelectedPeople,
  poepleType
}) {
  // console.log("from renderClient", clientNo, name, count);

  const [searchData, setSearchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newClients, setNewClients] = useState(0);
  const [newClientsAdded, setNewClientsAdded] = useState(0);
  const [addNewClient, setAddNewClient] = useState(false);
  const [searchArray,setSearchArray] = useState([]);
  const [pref, setPref] = useState();

  const [showData, setShowData] = useState(
    selectedPeople.length > 0 ? true : false
  );
  const addClient = (varww, event) => {
    console.log(varww)
    setPref(varww);
    setAddNewClient(true);
    setNewClients(newClients + 1);
  };



  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if(searchQuery.length===10){
        const data = await getPeopleByNumber(searchQuery);
        console.log("data", data);
        if (!data) {
          console.log("error");
        } else {
          if (data.length === 0) {
            setSearchData([]);
          } else {
            setSearchData(data);
          }
          console.log(searchData);
        }
      }
    })();
    setIsLoading(false);
  }, [searchQuery]);

  useEffect(() => {
    // console.log(selectedPeople);
    if (selectedPeople.length > 0) {
      setShowData(true);
    } else {
      setShowData(false);
    }
  }, [selectedPeople]);


  const handleCreateIconClick = (data) => {
    setSelectedPeople([...selectedPeople, data]);
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    enableReinitialize: true,
    validationSchema: MobileSearchValidations,
  });

  return (
    <>
    {!formDisable && <Div>
      <Div sx={{ position: "relative", width: "100%" }}>
        <Autocomplete
          id="asynchronous-searchByNumber"
          sx={{ width: "100%", marginTop: "4%" }}
          loading={isLoading}
          loadingText="Loading..."
          noOptionsText="No Options found"
          onChange={(event, value) => {
            
            setSelectedPeople((prev)=>[...prev,value[0]]);
            console.log("Value.....", value);

          }}
          value={searchArray}
          isOptionEqualToValue={(option, value) =>
            option?.mobileNo === value?.mobileNo
          }
          getOptionLabel={(option) => option?.mobileNo}
          options={searchData}
          multiple
          placeholder="Search by Mobile Number"
          renderOption={(props, option, { selected }) => (
            <li {...props} sx={{ width: "100%" }}>
              <WitnessCard
                key={option.peopleId}
                item={option}
                inOption={true}
                selected={selected}
              />
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by Mobile Number"
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
              error={formik.errors.phoneNumber && formik.touched.phoneNumber}
              helperText={formik.errors.phoneNumber}
              name="phoneNumber"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '');
                if (e.target.value.length > 0) {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    <SearchIcon color="secondary" />
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
        disabled={formDisable}
          onClick={addClient}
          sx={{ backgroundColor: "secondary.main" }}
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            width: "100px",
          }}
          variant="contained"
        >
          Add New
        </Button>
      </Div>
      </Div>}
      {selectedPeople.length===0 & addNewClient ?<></>:
      showData ? (
        selectedPeople?.length > 0 &&
        selectedPeople.map((people, key) => {
          // console.log(selectedPeople)
          // console.log(people)
          return (
            <>
              {" "}
              <WitnessCard
               formDisable={formDisable}
                addClient={addClient}
                showIcons={showData}
                key={key}
                inOption={false}
                item={people}
                selectedPeople={selectedPeople}
                setSelectedPeople={setSelectedPeople}
                onCreateIconClick={handleCreateIconClick}
                sx={{ margin: "15px" }}
              />
            </>
          );
        })
      ) : (
        <>
          <NoCaseContainer>
            <Div
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                mt:7
              }}
            >
              <Div
                sx={{
                  width: { xs: "60%", sm: "30%", md: "150px" },
                  height: "30%",
                }}
              >
                <img
                  src={NoCaseImage}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </Div>

            {formDisable?
             <Typography
             sx={{
               fontSize: { xs: "18px", md: "24px" },
               textAlign: "center",
               mt: 2,
             }}
           >
            No {poepleType === "Witness" ? "witness": "Client"}
           </Typography>:
            <Typography
            sx={{
              fontSize: { xs: "18px", md: "24px" },
              textAlign: "center",
              mt: 2,
            }}
          >
            You haven't added any {poepleType === "Witness" ? "witness": "Client"} yet!
          </Typography>
          } 
            </Div>
          </NoCaseContainer>
        </>
      )}
      {addNewClient ? (
        <>
          {Array(newClients)
            .fill(newClients)
            .map((x, idx) => (
              <RenderAccordian
             
                addClient={addClient}
                handleNextButton={handleNextButton}
                key={idx}
                count={idx}
                pref={pref}
                clientNo={newClients}
                setClientNo={setNewClients}
                name={name}
                selectedPeople={selectedPeople}
                setSelectedPeople={setSelectedPeople}
                newClientsAdded={newClientsAdded}
                setNewClientsAdded={setNewClientsAdded}
                poepleType={poepleType}
              />
            ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default RenderClient;
