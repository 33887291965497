import React from "react";
import { Link, Paper, MenuItem, Select } from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useHearings from "app/hooks/useHearings";
import HearingDashList from "./HearingDashList";
import NoDataDisplay from "../NoDataDisplay/NoDataDisplay";
import HearingSkeletons from "./HearingSkeletons";
import { getCasesList } from "app/Apis/case";
import Cookies from "js-cookie";
import { filter } from "app/utils/filter";

const optionsPageSize = ["10", "20", "50"];
const HearingList = ({ scrollHeight, count, basicFilter, endDate, startDate, dateRangeStatus }) => {
  const [hearingList, setHearingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState("10");
  const getHearingsList = useHearings();
  const accessToken = Cookies.get("accessToken");
const date=filter(basicFilter,startDate,endDate)
  useEffect(() => {
    const getAllHearings = async () => {
      setIsLoading(true);
      const response = await getCasesList(accessToken,0, pageSize,0,basicFilter,startDate,endDate);
      if (response) {
        console.log(response?.cases);
        setHearingList(response?.cases);
        setIsLoading(false);
      }
    };
   
    if (basicFilter !== "Choose Date") {
      getAllHearings()
    }
    else if (basicFilter === "Choose Date") {
      if (startDate && endDate) {
        getAllHearings()
      }
    }
  }, [
    // pageSize, 
    dateRangeStatus, basicFilter]);

  // useEffect(() => {
  //   const getAllHearings = async () => {
  //     setIsLoading(true);
  //     const response = await getCasesList(accessToken,0, pageSize,0);
  //     if (response) {
  //       console.log(response?.cases);
  //       setHearingList(response?.cases);
  //       setIsLoading(false);
  //     }
  //   };
  //   getAllHearings();
  // }, [pageSize]);
  // let hearingNewList = hearingList?.filter(function (el) {
  //     return el.hearingDate>=date.startDate && el.hearingDate<=date.endDate+1
  // }

  // );

  return (
    <Paper
      sx={{
        backgroundColor: "#f2f2f3",
        p: 3,
        color: "black",
        borderRadius: "20px",
      }}
      elevation={20}
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Div
            style={{
              height: "19px",
              width: "5px",
              backgroundColor: "#EABF4E",
              marginRight: "8px",
            }}
          ></Div>
          <Typography
            sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 400 }}
          >
            Hearings ({hearingList.length})
          </Typography>
        </Div>
        <Div sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          {/* <Div>
            <Select
              labelId="select-label"
              value={pageSize}
              sx={{ maxHeight: "30px", mr: "1px" }}
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
            >
              {optionsPageSize.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Div> */}
          <Div>
            <Link
              href="/lawyers/hearings"
              underline="none"
              lineHeight={1.2}
              fontSize={"16px"}
              fontWeight={700}
              color={"#EABF4E"}
            >
              View All
            </Link>
          </Div>
        </Div>
      </Div>
      <Div sx={{ mt: 3 }}>
        {/* reaplce '3' when pageSize is implemented */}
        {isLoading ? (
          Array.from(Array(parseInt("5"))).map((item, index) => {
            return <HearingSkeletons key={index} />;
          })
        ) : hearingList.length > 0 ? null : (
          <NoDataDisplay
            text={"No Hearings"}
            url={"/lawyers/hearings?hearing=true"}
            buttonText={"Add Hearing"}
            minHeight={"980px"}
          />
        )}
        {hearingList.length > 0 && (
          <HearingDashList hearingList={hearingList} />
        )}
      </Div>
    </Paper>
  );
};
export default HearingList;
