import {
  Paper,
  Typography,
  Card,
  Grid,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import React, { useState, useEffect } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EastIcon from "@mui/icons-material/East";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import PaymentsList from "./PaymentsList";
import { getPaymentLists } from "app/Apis/Payment";
import Cookies from "js-cookie";
import usePayments from "app/hooks/usePayments";
import NoDataDisplay from "../../NoDataDisplay/NoDataDisplay";
import PaymentCardList from "./PaymentCardList";
import { dashboardOptionsPageSize } from "app/utils/constants/filters";

function PaymentsDash({ paymentList, basicFilter, endDate, startDate }) {
  const accessToken = Cookies.get("accessToken");
  const [paymentsList, setPaymentsList] = useState([]);
  const [pageSize, setPageSize] = useState("10");
  const [paymentsReport, setPaymentsReport] = useState(null);
  const getPaymentsReports = usePayments();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPaymentReport = async () => {
      const response = await getPaymentsReports(accessToken);
      if (response) {
        console.log(response);
        setPaymentsReport(response);
      } else {
        console.log("error getting payment reports");
      }
    };
    const paymentList = async () => {
      const response = await getPaymentLists(
        accessToken,
        "lawyer",
        "All",
        5,
        0,
        basicFilter,
        startDate,
        endDate
      );
      if (response) {
        console.log(response);
        setPaymentsList(response?.paymentAndTransactionsDetails);
      } else {
        console.log("error getting payment lists");
      }
    };
    setIsLoading(true);
    if (basicFilter !== "Choose Date") {
      paymentList();
    } else if (basicFilter === "Choose Date") {
      if (startDate && endDate) {
        paymentList();
      }
    }

    getPaymentReport();
  }, [basicFilter, pageSize]);
  console.log(paymentsList);

  return (
    <Paper
      sx={{
        minHeight: "100%",
        backgroundColor: "#151513",
        p: 3,
        color: "white",
        borderRadius: "20px",
      }}
      elevation={20}
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Div
            style={{
              height: "19px",
              width: "5px",
              backgroundColor: "#EABF4E",
              marginRight: "8px",
            }}
          ></Div>
          <Typography
            sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 400 }}
          >
            Payments
          </Typography>
        </Div>
        <Div>
          <Link
            href="/lawyers/payments"
            underline="none"
            lineHeight={1.2}
            fontSize={"16px"}
            fontWeight={700}
            color={"#EABF4E"}
          >
            View All
          </Link>
        </Div>
      </Div>
      {/* <Grid container spacing={0.5}>
       
        <Grid item xs={12} 
        // md={4}
        
        >
          <Card
            sx={{
              color: "white",
              backgroundColor: "#BFBDC1",
              p: 3,
              mt: 3,
            }}
          >
            <Div
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Div>
                <Typography sx={{ fontSize: "1rem" }}>Requested</Typography>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
                ₹ {paymentList?.totalPaymentReceived +paymentList?.totalPaymentBalanced}
                </Typography>
              </Div>
              <Div>
                <NorthEastIcon
                  style={{
                    fontSize: 36,

                    // color:"#EABF4E"
                  }}
                />
              </Div>
            </Div>
           
          </Card>
        </Grid>
        <Grid item xs={12} 
        // md={4}
        >
          <Card
            sx={{
              color: "white",
              backgroundColor: "#BFBDC1",
              p: 3,
              mt: 3,
            }}
          >
            <Div
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Div>
                <Typography sx={{ fontSize: "1rem" }}>Recieved</Typography>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
                ₹ {paymentList?.totalPaymentReceived || "0"}
                </Typography>
              </Div>
              <Div>
                <AccountBalanceWalletIcon
                  style={{
                    fontSize: 36,
                    // color:"#EABF4E"
                  }}
                />
              </Div>
            </Div>
           
          </Card>
        </Grid>
        <Grid item xs={12} 
        // md={4}
        >
          <Card
            sx={{
              color: "white",
              backgroundColor: "#BFBDC1",
              p: 3,
              mt: 3,
            }}
          >
            <Div
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Div>
                <Typography sx={{ fontSize: "1rem" }}>Pending Balance</Typography>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
                ₹ {paymentList?.totalPaymentBalanced || "0"}
                </Typography>
              </Div>
              <Div>
                <SouthWestIcon
                  style={{
                    fontSize: 36,
                    // ,color:"#EABF4E"
                  }}
                />
              </Div>
            </Div>
            
          </Card>
        </Grid>
      </Grid> */}
      <Div sx={{ mt: 3 }}>
        {paymentsList.length > 0 ? (
          <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
            Payments History
          </Typography>
        ) : (
          <NoDataDisplay
            text={"No Payments To Display"}
            url={"/lawyers/payments"}
            buttonText={"Add Payment"}
            minHeight={"980px"}
            fromPayment={false}
          />
        )}

        {paymentsList.length > 0 && (
          // <PaymentsList paymentsList={paymentsList} />
          <PaymentCardList paymentsList={paymentsList} />
        )}
      </Div>
    </Paper>
  );
}

export default PaymentsDash;
