import React, { useState,useEffect } from "react";
import Div from "@jumbo/shared/Div";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LawyerProfileCard from "./LawyerProfileCard";
import PaymentDetails from "./PaymentDetails";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import lawyerImage from "../../ImageAssets/lawyer.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CasesDash from "app/Components/Dashboard/Display Icons/CasesDash/CasesDash";
import GavelIcon from "@mui/icons-material/Gavel";
import ProfileDash from "app/Components/Dashboard/Display Icons/ProfileDash/ProfileDash";
import EventIcon from "@mui/icons-material/Event";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { Diversity3Outlined } from "@mui/icons-material";
import { GavelOutlined } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import { getTeamLawyerDetails, getTeamLawyerReports } from "app/Apis/teams";
import SharedCard from "app/Components/Dashboard/SharedDataDisplay/SharedCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Div
          sx={{
            pt: 3,
          }}
        >
          {children}
        </Div>
      )}
    </div>
  );
}

const LawyerProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {lawyerId} = useParams();
  const queryParameters = new URLSearchParams(location.search);
  const menuValue = queryParameters.get("value");
  const [update,setUpdate] = useState(false);
  const [personalDetails,setPersonalDetails] = useState({});
  const [lawyerReport,setLawyerReport] = useState({});

  
  console.log(menuValue);
  const [values, setValues] = useState(menuValue ? Number(menuValue) : 0);
  const [buttonValue, setButtonValue] = React.useState(0);
  console.log(values);
  const handleNextButton = () => {
    if (values !== 2) {
      setButtonValue((prevButtonValue) => prevButtonValue + 1);
      setValues((prevButtonValue) => prevButtonValue + 1);
    }
  };

  const handleBackButton = () => {
    if (values !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValues((prevButtonValue) => prevButtonValue - 1);
    }
  };

  
  const getPersoanlDetails = async(lawyerId)=>{
    try{
      if(!lawyerId){
        throw Error("No lawyer Id");
      }
      const response = await getTeamLawyerDetails(lawyerId);
      console.log(response);
      if(response){
        setPersonalDetails(response);
      }else{
        throw Error("Error fetching personal details");
      }
    }catch(err){
      console.error(err);
    }
  }
  const getLawyerReport = async(lawyerId)=>{
    try{
      if(!lawyerId){
        throw Error("No lawyer Id");
      }
      const response = await getTeamLawyerReports(lawyerId);
      console.log(response);
      if(response){
        setLawyerReport(response);
      }
    }catch(err){
      console.log(err);
    }
  }
  useEffect(()=>{
    getPersoanlDetails(lawyerId);
    getLawyerReport(lawyerId);
  },[update])
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/lawyers/dashboards" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>
        </Link>
        <Link href="/lawyers/teams" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <Diversity3Outlined
              sx={{ fontSize: 20, mr: 0.5 }}
              fontSize="inherit"
            />
            My Teams
          </Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <GavelOutlined sx={{ mr: 0.5, fontSize: 20 }} />
          Cases
        </Typography>
      </Breadcrumbs>
      <Div>
        <Grid item xs={12} lg={5}>
          <Grid container spacing={1.8}>
            <Grid item xs={12} lg={4}>
              <Card
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CardHeader
                  sx={{
                    p: 2.65,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={
                    <Avatar
                      sx={{
                        width: { sm: 50, lg: 62 },
                        height: { sm: 50, lg: 62 },
                      }}
                      alt={"profile Image"}
                      src={personalDetails?.user?.userProfileImageLink ? personalDetails?.user?.userProfileImageLink:lawyerImage}
                      variant="rounded"
                    />
                  }
                  title={
                    <Typography sx={{ fontSize: "1rem" }}>
                      {personalDetails?.user ? personalDetails?.user?.fullname:"Lawyer Name"}
                    </Typography>
                  }
                  subheader={
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {personalDetails?.user ? personalDetails?.user?.phone:"9999999999"}
                    </Typography>
                  }
                />
                <Div sx={{  mr: 2,display:"flex",flexDirection:"column",alignItems:"flex-end",justifyContent:"center",gap:1 }}>
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    {personalDetails?.user?.professionalProfile?.lawyerSpecialisation 
                    ? personalDetails?.user?.professionalProfile?.lawyerSpecialisation[0]?.specialityName
                    :"Specialisation"}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    {personalDetails?.user?.professionalProfile?.practiceStates 
                    ? personalDetails?.user?.professionalProfile?.practiceStates[0]?.stateName
                    :"Practice State"}
                  </Typography>
                </Div>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={1.8} sx={{height:"100%"}}>
                <Grid item xs={6} lg={4}>
                  <SharedCard
                    title={lawyerReport?.teamCases}
                    subheader={"Cases"}
                    Icon={
                      <GavelIcon style={{ color: "#EABF4E", height: 65 }} />
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <SharedCard
                    title={lawyerReport?.teamsHearing}
                    subheader={"Hearings"}
                    Icon={
                      <EventIcon style={{ color: "#EABF4E", height: 65 }} />
                    }
                  />
                </Grid>
                
                <Grid item xs={6} lg={4}>
                  <SharedCard
                    title={lawyerReport?.teamAppointment}
                    subheader={"Appointments"}
                    sx={{ fontSize: 20 }}
                    Icon={
                      <EventIcon style={{ color: "#EABF4E", height: 65 }} />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Div>
      <Divider sx={{ mt: 2 }} />
      <Div
        sx={{
          width: "100%",
        }}
      >
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={values}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              onClick={() => setValues(0)}
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
              }}
              label="Cases"
            />
            <Tab
              onClick={() => setValues(1)}
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
              }}
              label="Payment"
            />
          </Tabs>
        </Div>
        <TabPanel value={values} index={0}>
          <LawyerProfileCard
            handleBackButton={handleBackButton}
            handleNextButton={handleNextButton}
            update={update}
            setUpdate={setUpdate}
          />
        </TabPanel>
        <TabPanel value={values} index={1}>
          <PaymentDetails
            handleBackButton={handleBackButton}
            handleNextButton={handleNextButton}
          />
        </TabPanel>
      </Div>
    </>
  );
};

export default LawyerProfile;
