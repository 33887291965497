import { apiHeader, apiUrl } from "app/config";
import axios from "axios";
import Cookies from "js-cookie";

// export const getPeopleList = async (accessToken, pageNumber, pageSize) => {
//   try {
//     const params = new URLSearchParams();
//     params.append("page", pageNumber);
//     params.append("size", pageSize);
//     let headers = new Headers(); // headers.append("Content-Type", "application/json");
//     headers.append("x-api-key", apiHeader);
//     headers.append("Authorization", "Bearer " + accessToken);
//     const url = apiUrl + "/api/v1/people";
//     const urlWithParams = `${url}?${params.toString()}`;
//     console.log("checking Get URL", urlWithParams);
//     const response = await fetch(urlWithParams, {
//       method: "GET",
//       headers, // body: JSON.stringify(obj),
//     });
//     if (response.status === 200) {
//       const data = await response.json();
//       console.log("all people fetched", data);
//       return data;
//     }
//   } catch (e) {
//     console.log(e);
//   }
// };



export const postPeople = async (body) => {
  try {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken||!body) {
      throw Error("No accessToken or body Provided");
    }
    console.log(body);
    const headers = {
      'x-api-key': apiHeader,
      Authorization: `Bearer ${accessToken}`,
      // 'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    formData.append('peopleTypes', body?.peopleType);
    formData.append('fullName', body?.fullName);
    formData.append('mobileNo', body?.mobileNo);
    formData.append('emailId', body?.emailId);
    formData.append('addressLine1', body?.add1);
    formData.append('city', body?.city);
    formData.append('state', body?.state);
    formData.append('pinCode', body?.pinCode);
    formData.append('country', body?.country);
    formData.append('isActive', 1);
    const response = await axios.post(`${apiUrl}/api/v1/people`, formData, {
      headers,
    });
    console.log(response);

    if (response.status===201) {
      
      // console.log("People added",response);
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const getPeopleList = async ( pageNumber=0, pageSize,peopleType,isList=0) => {
  try {
    let people = "";
    if(peopleType==="Opposite Client"){
      people="Opposite_Client"
    }else if(peopleType==="Corporate Client"){
      people="Corporate_Client"
    }else{
      people = peopleType;
    }
    const lawyerId = Cookies.get("lawyerId"); 
    const accessToken = Cookies.get("accessToken"); 
    let params = new URLSearchParams();
    params.append("page",pageNumber);
    params.append("size",pageSize);
    params.append("peopleType",people);
    params.append("isList",isList);
    const headers = {
      "x-api-key": apiHeader,
      "Authorization": `Bearer ${accessToken}`
    };
    const url = apiUrl + `/api/v1/people/lawyer/${lawyerId}`;
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.get(urlWithParams, {headers});
    if (response.status === 200) {
      console.log("all people fetched", response);
      return response?.data?.data;
    }
    return null;
  } catch (e) {
    console.log(e);
  }
};

export const getPeopleByNumber = async (mobileNo ) => {
  try {
    const accessToken = Cookies.get("accessToken"); 
    const headers = {
      "x-api-key": apiHeader,
      "Authorization": `Bearer ${accessToken}`
    };
    const url = apiUrl + `/api/v1/people/search/${mobileNo}`;
    // const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.get(url, {headers});
    if (response.status === 200) {
      console.log("mobile number", response);
      return response?.data;
    }
    return null;
  } catch (e) {
    console.log(e);
  }
};
