import React from "react";
import Div from "@jumbo/shared/Div/Div";
import { Button } from "@mui/material";

function CircularTabs({ value, handleChange ,tabs,fontSize,count,fromTask,fromAppointment}) {
  const buttonStyle = {
    color: "white",
    p:1,
    borderRadius: "28px",
    "&.Mui-focusVisible": { 
      outline: "none",
    },
    "&.MuiButton-root": {
      transition: "background-color 0.8s ease", 
    },
    "&.MuiButton-root:hover": { 
      backgroundColor: "black",
    },
  };
  return (
    <Div
      sx={{
        flex:1,
        display:"flex",
        backgroundColor: "black",
        color: "white",
        borderRadius: "28px",
        overflow: "hidden",
        height:"2.7rem"
      }}
    >
      {
        tabs && tabs.map((tab,index)=>{
          return (
            <Button
            sx={{
              flex:1,
              ...buttonStyle,
              fontSize:fontSize,
              fontWeight:fontSize?"bold":"normal",
              color: value === index ? "black" : "white",
              backgroundColor: value === index ? "#EABF4E" : "black",
              borderRadius:value===index?"28px":"",
              "&.MuiButton-root:hover": {
                backgroundColor: value === index ? "#EABF4E" : "black", 
              },
            }}
            onClick={(e) => handleChange(e, index)}
            startIcon={tab?.Icon?tab?.Icon:null}
          >
           {tab?.tagLabel}
            ({fromTask &&(index===0?count?.high:(index===1?count?.medium:count?.low))}{fromAppointment && index===0?count?.inPerson:(index===1?count?.videoCall:count?.telephonic)})
          
                </Button>
          )
        })
      }
    </Div>
  );
}

export default CircularTabs;
