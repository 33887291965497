import React from "react";
import { teamData } from "./CardData";
import TeamCard from "./TeamCard";
// import { useNavigate } from "react-router-dom";
import LawyerListCard from "./LawyerListCard";
const TeamLawyerList = ({teamList}) => {
  // const nav=useNavigate()
//   let achiveList = teamData.filter(function (el) {
//     if(archiveStatus){
//       return el.isArchive
//     }
//    return el.isArchive===false
// }

  return (
    <React.Fragment>
      {teamList.map((team, index) => (
        <LawyerListCard  Key={team.id} item={team} />
      ))}
    </React.Fragment>
  );
};

export default TeamLawyerList;
