import React, { useEffect, useState, lazy } from "react";
// import AddCase from "./AddCase";
import CaseListLayout from "./CaseListLayout";
import Cookies from "js-cookie";
import { getCasesList, getTeamCases } from "app/Apis/case";
import { clearCaseData } from "app/redux/actions/dashboard";
import { useDispatch } from "react-redux";
import Div from "@jumbo/shared/Div/Div";
import {
  Alert,
  Typography,
  Snackbar,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const AddCase = lazy(()=>{console.log("Lazyyy"); return import("./AddCase")})



function NoCase() {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const addNewCase = queryParameters.get("addCase");
  const fromTeams = queryParameters.get("fromTeams");
  console.log(addNewCase);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [values, setValues] = React.useState(0);

  const [allCases, setAllCases] = useState([]);
  const [addCase, setAddCase] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState("5");
  const [update, setUpdate] = useState(false);
  // use isLoading when making api call
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    setAddCase(false);
  };
  const getAllCases = async () => { 
    const accessToken = Cookies.get("accessToken");
    setIsLoading(true);
    if (values === 0 ) {
      const response = await getCasesList(accessToken, pageNumber, pageSize);
      if (response) {
        console.log(response);
        setAllCases(response.cases);
        setTotalPages(response.totalPages);
        setIsLoading(false);
        // setPageNumber(response.currentPage)
      }
    }else {
      setIsLoading(true);
      const response = await getTeamCases();
      if (response) {
        console.log(response);
        setAllCases(response);
        setTotalPages(response?.totalPages);
        setIsLoading(false);
        // setPageNumber(response.currentPage)
      }
    
    }
  };
  useEffect(() => {
    console.log(addNewCase);
    if(Boolean(addNewCase)){
      setAddCase(true);
    }

    getAllCases();
  }, [pageNumber, pageSize, update, values]);
  useEffect(()=>{
    if(fromTeams){
    setValues(1)
    }
  },[fromTeams])
  useEffect(()=>{
    console.log(values);
  },[values])
  const dispatch = useDispatch();

  const ClearCaseInRedux = () => {
    dispatch(clearCaseData());
  };
  useEffect(() => {
    console.log(addCase);
    if (!addCase) {
      ClearCaseInRedux();
      setUpdate((prev) => !prev);
    }
  }, [addCase]);
  
  if (addCase) {
    return (
      <AddCase
        setAddCase={setAddCase}
        setUpdate={setUpdate}
        setOpenSnackBar={setOpenSnackBar}
        values={values}
      />
    );
  } else {
    return (
      <>
        {openSnackBar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackBar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            sx={{ zIndex: 1000, mt: 10 }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
            >
              <Typography variant="h6">Case Added</Typography>
              <Div sx={{ display: "flex", justifyContent: "center" }}></Div>
            </Alert>
          </Snackbar>
        )}
        <CaseListLayout
          setAddCase={setAddCase}
          setValues={setValues}
          values={values}
          isLoading={isLoading}
          allCaseList={allCases}
          totalPages={totalPages}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setUpdate={setUpdate}
          addCase={addCase}
        />
      </>
    );
  }
}

export default NoCase;
