import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import { Alert, alpha, Link, Paper, Snackbar, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";

import { useSelector } from "react-redux";

import VideoCallIcon from "@mui/icons-material/VideoCall";
import CallIcon from "@mui/icons-material/Call";
import ActionsMenu from "./ActionMenu";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@material-ui/icons/Home";
import WorkIcon from "@material-ui/icons/Work";
import GavelIcon from "@material-ui/icons/Gavel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import JitsiMeetLanding from "app/pages/components/Jitsi Meet/JitsiMeetLanding";
import JitsiMeetComponent from "app/pages/components/Jitsi Meet";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AppointmentCard = ({
  appointmentEdit,
  item,
  appointmentList,
  setAddApp,
  setAppointmentEdit,
  setShowUpdate,
  showUpdate,
  setDoEditUpdate,
  values
}) => {

  const [status,setStatus]=useState(false)
  console.log(appointmentEdit);
  console.log(item.appointmentType);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const fullName = useSelector((state) => {
    return state?.onboardingInputs?.fullName;
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleSnackbarClose=()=>{
    setOpenSnackBar(false)
  }
  const handleVideoCallIconClick = () => {
    if(status){
      setStatus(false)
    }
    else{
      setStatus(true)
    }
   
    // setExpanded(true)
    // window.open(
    //   "/lawyers/meet/648b03040dffc62548b706f2-d4e1f7c5-da3c-4131-92aa-845bc1d306d7",
    //   "__top"
    // )
  };
  const handleExpandClick = () => {
    if (appointmentList.find((appointment) => appointment.id === item.id))
      setExpanded(!expanded);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const startTime = dayjs(item?.fromTime);

  const addressLabel = item.addressLabel;

  let iconComponent;

  if (addressLabel === "home") {
    iconComponent = (
      <HomeIcon style={{ color: "rgb(234, 191, 78)", fontSize: 10 }} />
    );
  } else if (addressLabel === "office") {
    iconComponent = (
      <WorkIcon style={{ color: "rgb(234, 191, 78)", fontSize: 10 }} />
    );
  } else if (addressLabel === "court") {
    iconComponent = (
      <GavelIcon style={{ color: "rgb(234, 191, 78)", fontSize: 10 }} />
    );
  } else {
    iconComponent = null;
  }

  useEffect(() => {
    const currentTime = dayjs();
    const fifteenMinutesBeforeStartTime = startTime.subtract(15, "minutes");

    const timer = setInterval(() => {
      if (currentTime.isAfter(fifteenMinutesBeforeStartTime)) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-4px)",
          borderBottomColor: "transparent",
        },
      }}
    >
         {openSnackBar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackBar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            sx={{ zIndex: 1000, mt: 5 }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
            >
              <Typography variant="h6">ThankYou for Joining the Call</Typography>
            </Alert>
          </Snackbar>
        )}
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",

            "&.Mui-expanded": {
              margin: "12px 0",
            },
            "&:hover": {
              cursor: "default",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>

        <Div
          sx={{
            width: { xs: "auto", lg: "15%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item.appointmentTitle}
          </Typography>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Client Name
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>{item?.client?.fullname}</Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Appointment Date
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>
              {new Date(item.dateOfAppointment).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </Typography>
          </Div>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Appointment Time
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>
              {formatTime(item.fromTime)} to {formatTime(item.toTime)}
            </Typography>
          </Div>
        </Div>

        {/* <Div
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            alignItems: "center",
            width: "10%",
            gap: 2,
          }}
        >
          {item.appointmentType === "Personal Meeting" ? (
            <Chip
              label={item.addressLabel} // Change according to the API
              color="secondary"
              size="medium"
              variant="outlined"
              avatar={iconComponent}
              sx={{
                fontSize: 14,
                fontWeight: "700",
                color: "rgb(234, 191, 78)",
                "& .MuiChip-avatar": {
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                },
              }}
            />
          ) : (
            <Chip
              label={"Virtual"} // change according to api
              color="secondary"
              size="medium"
              variant="outlined"
              avatar={<LocationOnIcon style={{ color: "#EABF4E" }} />}
              sx={{
                fontSize: 14,
                fontWeight: "700",
                color: "rgb(234, 191, 78)",
                "& .MuiChip-avatar": {
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                },
              }}
            />
          )}
        </Div> */}

        <Div
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            // alignItems: "center",
            width: "10%",
            // gap: 2,
          }}
        >
          {item?.appointmentType === "Personal Meeting" && (
            <Chip
              label={"Personal Meeting"} // change according to api
              color="secondary"
              size="medium"
              variant="outlined"
              avatar={<PersonIcon style={{ color: "#EABF4E" }} />}
              sx={{
                fontSize: 14,
                fontWeight: "700",
                color: "rgb(234, 191, 78)",
                "& .MuiChip-avatar": {
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                },
              }}
            />
            //
          )}
          {item?.appointmentType === "Telephonic Call" && (
            <Chip
              label={"Telephonic Call"} // change according to api
              color="secondary"
              size="medium"
              variant="outlined"
              avatar={<CallIcon style={{ color: "#EABF4E" }} />}
              sx={{
                fontSize: 14,
                fontWeight: "700",
                color: "rgb(234, 191, 78)",
                "& .MuiChip-avatar": {
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                },
              }}
            />
          )}
          {item?.appointmentType === "Video Call" && (
          <Link
            target="_blank"
            href="/lawyers/meet/true"
          >
            <Chip
              label={"Video Call"} // change according to api
              color="secondary"
              size="medium"
              variant="outlined"
              avatar={
                <VideoCallIcon
                  style={{ color: "#EABF4E" }}
                  onClick={handleVideoCallIconClick}
                  disabled={isDisabled}
                />
              }
              sx={{
                fontSize: 14,
                fontWeight: "700",
                color: "rgb(234, 191, 78)",
                "& .MuiChip-avatar": {
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                },
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Link>
          )}
        </Div>
        <Div>
        {values=== 0 &&
          <ActionsMenu
            item={item}
            setAddApp={setAddApp}
            appointmentEdit={appointmentEdit}
            setAppointmentEdit={setAppointmentEdit}
            setShowUpdate={setShowUpdate}
            showUpdate={showUpdate}
            setDoEditUpdate={setDoEditUpdate}
          />
        }
        </Div>
      </AccordionSummary>
      {status &&
            <JitsiMeetLanding setOpenSnackBar={setOpenSnackBar} setStatus={setStatus}/>}
      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        
          {!status &&  <div>
            <Typography variant={"h5"}>Description</Typography>

<Div
  sx={{
    display: { xs: "flex", lg: "none" },
    minWidth: 0,
    flexDirection: "column",
  }}
>
  <Div sx={{ mb: 3, maxWidth: 280 }}>
    <Div
      sx={{
        display: { xs: "flex", lg: "none" },
        flexDirection: "column",
        gap: 1,
      }}
    ></Div>
  </Div>
</Div>

<Typography variant={"body1"} color={"text.secondary"}>
  {/* {item.description} */}
  {item.appointmentDescription
    ? item.appointmentDescription
    : "No description added"}
</Typography>
            </div>}
     
      </Collapse>
    </Paper>
  );
};
/* Todo item prop define */
function formatTime(timeString) {
  const date = new Date(timeString);
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

export default AppointmentCard;
