import React from "react";
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { Typography } from "@mui/material";
import Cookies from "js-cookie";

const Logo = ({ mini, mode, sx }) => {
 const role = Cookies.get("assignedRole");
//  console.log(role)
 
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      <Link href={role==="ROLE_ADMIN" || role==="ROLE_MODERATOR" ?"/admin/dashboard": "/home"}>
        {!mini ? (
          <div style={{ width: "100%" ,marginLeft:role==="ROLE_ADMIN" || role==="ROLE_MODERATOR" ?"-5px":"-22px"}}>
            {" "}
            <img
              src={`${ASSET_IMAGES}/lawinzo/vakily-logo-black.png`}
              // src={'/images/vakily-logo-black.png'}
              alt="Vakily Logo"
              style={{
                objectFit: "cover",
                height: "74px",
                width: "100%",
              }}
            />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            {" "}
            <img
              src={`${ASSET_IMAGES}/lawinzo/logo-black.png`}
              alt="Vakily Logo"
              style={{
                objectFit: "cover",
                height: "10vh",
                width: "100%",
              }}
            />
          </div>
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
