export function filter(basicFilter,fromDate,toDate){

    let startDate=new Date()
    let endDate=new Date()
  
    
    if(basicFilter==="Today"){
     return{startDate:`${startDate.getFullYear()}-${(startDate.getMonth()+1).toString().padStart(2,"0")}-${startDate.getDate().toString().padStart(2,"0")}`,
      endDate:`${endDate.getFullYear()}-${(endDate.getMonth()+1).toString().padStart(2,"0")}-${endDate.getDate().toString().padStart(2,"0")}`}
    }
    else if(basicFilter==="Yesterday"){
        return{startDate:`${startDate.getFullYear()}-${(startDate.getMonth()+1).toString().padStart(2,"0")}-${(startDate.getDate()-1).toString().padStart(2,"0")}`,
      endDate:`${endDate.getFullYear()}-${(endDate.getMonth()+1).toString().padStart(2,"0")}-${(endDate.getDate()-1).toString().padStart(2,"0")}`}
    }
    else if(basicFilter==="Tomorrow"){
        return{ startDate:`${startDate.getFullYear()}-${(startDate.getMonth()+1).toString().padStart(2,"0")}-${(startDate.getDate()+1).toString().padStart(2,"0")}`,
      endDate:`${endDate.getFullYear()}-${(endDate.getMonth()+1).toString().padStart(2,"0")}-${(endDate.getDate()+1).toString().padStart(2,"0")}`}
    }
    else if(basicFilter==="This Week" || basicFilter==="Select"){
      let firstday = new Date(startDate.setDate(startDate.getDate() - startDate.getDay()));
      let lastday = new Date(endDate.setDate(endDate.getDate() - endDate.getDay()+6));
        return{startDate:`${firstday.getFullYear()}-${(firstday.getMonth()+1).toString().padStart(2,"0")}-${(firstday.getDate()).toString().padStart(2,"0")}`,
      endDate:`${lastday.getFullYear()}-${(lastday.getMonth()+1).toString().padStart(2,"0")}-${(lastday.getDate()).toString().padStart(2,"0")}`}
    }else if(basicFilter==="This Month"){
      let date_today = new Date();

      let firstDay = new Date(date_today.getFullYear(), date_today.getMonth(), 1);
      
      let lastDay = new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0);
      
      console.log(`The first date of the current month is: ${firstDay.toString()}`); 
      
      console.log(`The last date of the current month is: ${lastDay.toString()}`); 
        return{ startDate:`${firstDay.getFullYear()}-${(firstDay.getMonth()+1).toString().padStart(2,"0")}-${(firstDay.getDate()).toString().padStart(2,"0")}`,
      endDate:`${lastDay.getFullYear()}-${(lastDay.getMonth()+1).toString().padStart(2,"0")}-${(lastDay.getDate()).toString().padStart(2,"0")}`}
    }else{
      let newTodate=new Date(toDate)
    let newFromdate=new Date(fromDate)
    // if(fromDate && toDate){

    // }
    let toDateParam = `${newTodate.getFullYear()}-${(newTodate.getMonth()+1).toString().padStart(2,"0")}-${newTodate.getDate().toString().padStart(2,"0")}`;
    let fromDateParam = `${newFromdate.getFullYear()}-${(newFromdate.getMonth()+1).toString().padStart(2,"0")}-${newFromdate.getDate().toString().padStart(2,"0")}`;
      console.log(startDate,fromDateParam)
      if(toDateParam && endDate){
        return{startDate:fromDateParam,
          endDate:toDateParam}
      }
      else{
        return {startDate:null,endDate:null}
      }
  
    }
}
