import React from "react";
import { CardActions, CardContent, Stack } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Link from "@mui/material/Link";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { getAssetPath } from "app/utils/appHelpers";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { updateNotificationsSettings } from "app/Apis/settings";
import useSettings from "app/hooks/useSettings";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { LoadingButton } from "@mui/lab";
import Div from "@jumbo/shared/Div";

import SmsIcon from "@mui/icons-material/Sms";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Alert, Snackbar } from "@mui/material";

const Notification = ({
  lawyerId,
  accessToken,
  initSettings,
  setInitSettings,
}) => {
  console.log(initSettings);
  console.log(accessToken);
  console.log(lawyerId);
  const theme = useTheme();
  const [setting, setSetting] = useState({});
  // const [initSettings, setInitSettings] = useState({});
  const [update, setUpdate] = useState(false);

  // const getSetting = useSettings(   {  initSettings,
  //   setInitSettings });
  // useEffect(() => {
  //   const getNotifSetting = async () => {
  //     const settings = await getSetting(lawyerId, accessToken);
  //     if (!settings) {
  //       console.log("error getting settings");
  //     }
  //     setSetting(settings.notificationsDetails);
  //     setInitSettings(settings);

  //   };
  //   getNotifSetting();
  //   setUpdate(false)
  // }, [update]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    setOpenSnackBar(false);
  };
  const handleSubmit = async () => {
    const newNotifications = {
      ...formik.values,
      isActive: 1,
    };
    const response = await updateNotificationsSettings(
      initSettings,
      newNotifications,
      lawyerId,
      accessToken
    );
    console.log(response);
    setUpdate(true);
    setOpenSnackBar(true);
  };

  const formik = useFormik({
    initialValues: {
      isEmailUpdate: initSettings?.notificationsDetails?.isEmailUpdate || 0,
      isInAppUpdate: initSettings?.notificationsDetails?.isInAppUpdate || 0,
      isNotificationSoundOn:
        initSettings?.notificationsDetails?.isNotificationSoundOn || 0,
      isSmsUpdate: initSettings?.notificationsDetails?.isSmsUpdate || 0,
      isWhatAppUpdate: initSettings?.notificationsDetails?.isWhatAppUpdate || 0,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
          >
            <Typography variant="h6">
              Notifications Updated Successfully!
            </Typography>
          </Alert>
        </Snackbar>
      )}
      <JumboCardQuick
        action={
          <LoadingButton
            type="submit"
            onClick={handleSubmit}
            color="secondary"
        
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                
                backgroundColor: "#eabf4e",
              },
            }}
            variant="contained"
          >
            Update
          </LoadingButton>
        }
        title={"Notifications"}
        headerSx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        noWrapper
      >
        <List disablePadding sx={{ mb: 2 }}>
          <ListItem
            alignItems="flex-start"
            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
          >
            <ListItemIcon sx={{ minWidth: 36, color: "text.secondary" }}>
              <WhatsAppIcon sx={{ color: theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ letterSpacing: "0.2rem" }}
                  >
                    Whatsapp Update
                  </Typography>
                  <Switch
                    checked={formik.values.isWhatAppUpdate === 1 ? true : false}
                    color="secondary"
                    onChange={(e) => {
                      const { checked } = e.target;
                      const value = checked ? 1 : 0;

                      formik.setFieldValue("isWhatAppUpdate", value);
                    }}
                    id="isWhatAppUpdate"
                  />
                </Stack>
              }
            />
          </ListItem>
          <ListItem
            alignItems="flex-start"
            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
          >
            <ListItemIcon sx={{ minWidth: 36, color: "text.secondary" }}>
              <EmailOutlinedIcon sx={{ color: theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ letterSpacing: "0.2rem" }}
                  >
                    Email Update
                  </Typography>
                  <Switch
                    checked={formik.values.isEmailUpdate === 1 ? true : false}
                    color="secondary"
                    onChange={(e) => {
                      const { checked } = e.target;
                      const value = checked ? 1 : 0;

                      formik.setFieldValue("isEmailUpdate", value);
                    }}
                    id="isEmailUpdate"
                  />
                </Stack>
              }
            />
          </ListItem>
          <ListItem
            alignItems="flex-start"
            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
          >
            <ListItemIcon sx={{ minWidth: 36, color: "text.secondary" }}>
              <SmsIcon sx={{ color: theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ letterSpacing: "0.2rem" }}
                  >
                    SMS Update
                  </Typography>
                  <Switch
                    checked={formik.values.isSmsUpdate === 1 ? true : false}
                    color="secondary"
                    onChange={(e) => {
                      const { checked } = e.target;
                      const value = checked ? 1 : 0;

                      formik.setFieldValue("isSmsUpdate", value);
                    }}
                    id="isSmsUpdate"
                  />
                </Stack>
              }
            />
          </ListItem>
          <ListItem
            alignItems="flex-start"
            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
          >
            <ListItemIcon sx={{ minWidth: 36, color: "text.secondary" }}>
              <SystemSecurityUpdateGoodIcon
                sx={{ color: theme.palette.primary.main }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ letterSpacing: "0.2rem" }}
                  >
                    In-App Update
                  </Typography>
                  <Switch
                    checked={formik.values.isInAppUpdate === 1 ? true : false}
                    color="secondary"
                    onChange={(e) => {
                      const { checked } = e.target;
                      const value = checked ? 1 : 0;

                      formik.setFieldValue("isInAppUpdate", value);
                    }}
                    id="isInAppUpdate"
                  />
                </Stack>
              }
            />
          </ListItem>
          <ListItem
            alignItems="flex-start"
            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
          >
            <ListItemIcon sx={{ minWidth: 36, color: "text.secondary" }}>
              <NotificationsNoneIcon
                sx={{ color: theme.palette.primary.main }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ letterSpacing: "0.2rem" }}
                  >
                    Notification Sound
                  </Typography>
                  <Switch
                    checked={
                      formik.values.isNotificationSoundOn === 1 ? true : false
                    }
                    color="secondary"
                    onChange={(e) => {
                      const { checked } = e.target;
                      const value = checked ? 1 : 0;

                      formik.setFieldValue("isNotificationSoundOn", value);
                    }}
                    id="isNotificationSoundOn"
                  />
                </Stack>
              }
            />
          </ListItem>
        </List>
        <Div style={{ display: "flex", justifyContent: "center" }}></Div>
      </JumboCardQuick>
    </div>
  );
};

export default Notification;
