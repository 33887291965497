import Div from "@jumbo/shared/Div";
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Typography,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";

import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CardFooter from "../components/CardFooter";
import TeamSkeletons from "../Teams/TeamSkeletons";
import HomeIcon from "@mui/icons-material/Home";
import { GavelOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import CaseList from "./CaseList";


const CaseListLayout = ({
  setAddCase,
  setUpdate,
  isLoading,
  allCaseList,
  pageSize,
  setPageSize,
  setPageNumber,
  totalPages,
  pageNumber,
  addCase,
  setValues,
  values
}) => {
  const navigate = useNavigate();
  const optionsPageSize = ["5", "10", "20", "25", "50"];
  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/home" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <GavelOutlined sx={{ mr: 0.5, fontSize: 20 }} />
          Cases
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
          height:"100%"
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              mt:-1
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
               
              }}
            >
              <Div sx={{  display: { xs: "none", sm: "flex" }, alignItems: "center" }}>
                <Div
                  style={{
                    height: "19px",
                    width: "5px",
                    backgroundColor: "#EABF4E",
                    marginRight: "8px",
                  }}
                ></Div>
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    lineHeight: 1.5,
                    fontWeight: 400,
                  }}
                >
                  All Cases
                </Typography>
              </Div>
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={() => setAddCase(true)}
              >
                Add Case
              </Button>
            </Div>
            <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            // borderBottom: 1,
            // borderColor: "divider",

            //maxWidth: { xs: 300, sm: 600, md: 700, lg: 800 },
            //   overflowX: "scroll",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={values}
            textColor="secondary"
            indicatorColor="secondary"
            sx={{mb:0}}

          >
            <Tab
              onClick={() => setValues(0)}
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
                mx:{sx:0,sm:5}
              }}
              label="My Cases"
            />
            <Tab
              onClick={() => setValues(1)}
              sx={{
                fontSize: { xs: "14px", md: "14px" },
                fontWeight: "800",
              }}
              label="Team Cases"
            />
          </Tabs>
        </Div>
            <Div
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Search Bar */}
              <Div >
                <Select
                  labelId="select-label"
                  value={pageSize}
                  sx={{ maxHeight: "40px", mr: "10px" }}
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  {optionsPageSize.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Div>
              {/* <Div sx={{ width: "100%" }}>
                <Button
                  style={{ fontWeight: "600" }}
                  startIcon={<FilterAltOutlinedIcon />}
                  color="secondary"
                  size="small"
                >
                  Filter By
                </Button>
              </Div> */}
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10 }}>
            {/* replace "5" with pageSize when implemented */}
            {isLoading ? (
              Array.from(Array(parseInt(pageSize))).map((item, index) => {
                return <TeamSkeletons key={index} />;
              })
            ) : allCaseList?.length > 0 ? (
              <>

                <CaseList allCaseList={allCaseList} setUpdate={setUpdate} values={values}/>

              </>
            ) : (
              <Div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Div
                  sx={{
                    width: { xs: "60%", sm: "30%", md: "150px" },
                    height: "30%",
                  }}
                >
                  <img
                    src={NoCaseImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </Div>

                <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "24px" },
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  You haven't added any Records yet!          
                </Typography>
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 4,
                  }}
                >
                  {/* <Link to="/lawyers/addcases" style={{ textDecoration: "none" }}> */}
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "secondary.main",
                      mb: 2,
                      width: "150px",
                    }}
                    onClick={() => setAddCase(true)}
                  >
                    Add Case
                  </Button>
                  {/* </Link> */}

                </Div>
              </Div>
            )}
             <Div
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    right: { sm: 0, lg: 25 },
                  }}
                >
                  <CardFooter
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                  />
                </Div>
          </Div>
        </Div>
      </Card>
    </React.Fragment>
  );
};

export default CaseListLayout;
