import React from 'react';
import Grid from "@mui/material/Grid";
import Personal from "./components/Personal/personal";
import { useSelector } from 'react-redux';
import Notification from './components/Notification/notification';
import AddressPage from './components/Address/Address';

const UserProfileSidebar = ({  handleAddressTabClick,
    handlePersonalTabClick ,
    personalRef,
    addressRef,
    notificationRef,
    initSettings ,
    setInitSettings,
lawyerId,
accessToken }) => {
    
  
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={12}>
            <div ref={personalRef} id="personal">
                <Personal/>
              </div>
            </Grid>
            <Grid item xs={12}  lg={12}>
            <div ref={addressRef} id="address">
            <AddressPage
                     initSettings = {initSettings}
                     setInitSettings ={setInitSettings}
                     lawyerId={lawyerId}
                     accessToken={accessToken}
                />
              </div>
                
            </Grid>
            <Grid item xs={12}lg={12}>
            <div ref={notificationRef} id="notification">
                <Notification   
                     initSettings = {initSettings}
                     setInitSettings ={setInitSettings}
                accessToken = {accessToken}
                lawyerId={lawyerId}
                />
                </div>
            </Grid>
        </Grid>
    );
};

export default UserProfileSidebar;
