import React from "react";
import { Grid } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import SingleDocumentPreview from "./SingleDocumentPreview";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useMediaQuery, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import Divider from "@mui/material/Divider";

function DisplayDocumentPreview({ documentLists }) {
	const [currentDoc, setCurrentDoc] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const isMobile = useMediaQuery("(max-width: 990px)");

	const handleOpen = () => {
		setOpenModal(true);
	};
	const handleClose = () => {
		setOpenModal(false);
	};
	console.log("document lists", documentLists);
	if (Boolean(!documentLists)) {
		return <div>no documents to display</div>;
	} else {
		return (
			<Div
				sx={{
					width: "auto",
					border: "1px dotted #d3d3d3",
					maxHeight: "200px",
					overflowY: "auto",
					p: 3,
					py: 1,
				}}
			>
				<Grid container spacing={1}>
					{
						// onClick={()=>{setCurrentDoc(documentLists[index]);console.log("clicked"); handleOpen()}}
						documentLists.map((document, index) => {
							console.log(document);
							return (
								<>
									<Grid item key={document?.documentId} xs={12} md={6} lg={2.5}>
										<Div
											key={index}
											sx={{
												position: "relative",
												backgroundColor: "rgb(243 243 243)",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
												height: "auto",
												borderRadius: "8px",
												"&:hover": {
													cursor: "pointer",
												},
											}}
											onClick={() => {
												setCurrentDoc(documentLists[index]);
												console.log("clicked");
												handleOpen();
											}}
										>
											{document?.documentExtension.startsWith("image/") ? (
												<>
													<Div
														sx={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
															width: "100%",
														}}
													>
														<img
															style={{
																width: "100%",
																height: "113px",
																borderRadius: "5px",
																objectFit: "contain",
															}}
															src={document?.documentUrl}
															alt="document"
														/>
														<Typography
															variant={"body1"}
															color={"primary"}
															sx={{ my: 1 }}
															fontWeight="700"
														>
															{document?.documentName}-{document?.documentType}
														</Typography>
													</Div>
												</>
											) : document?.documentExtension === "application/pdf" ? (
												<Div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														width: "100%",
														height: "150px",
														borderRadius: "5px",
														backgroundColor: "#EABF4E",
													}}
												>
													<PictureAsPdfIcon
														size={12}
														color="red"
														style={{ marginLeft: "5px" }}
													/>
													<Typography
														sx={{
															maxWidth: "120px",
															overflow: "hidden", // Add overflow hidden to hide overflowing content
															whiteSpace: "nowrap", // Prevent text from wrapping
															textOverflow: "ellipsis",
														}}
													>
														{document?.documentName}
													</Typography>
												</Div>
											) : document?.documentExtension ===
											  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
												<Div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														width: "100%",
														height: "150px",
														borderRadius: "5px",
														backgroundColor: "#EABF4E",
													}}
												>
													<PictureAsPdfIcon
														size={12}
														color="red"
														style={{ marginLeft: "5px" }}
													/>
													<Typography
														sx={{
															maxWidth: "120px",
															overflow: "hidden", // Add overflow hidden to hide overflowing content
															whiteSpace: "nowrap", // Prevent text from wrapping
															textOverflow: "ellipsis",
														}}
													>
														{document?.documentName}
													</Typography>
												</Div>
											) : null}
										</Div>
									</Grid>
									{/* <Divider
                    orientation="vertical"
                    sx={{ borderBottomWidth: 5 }}
                    flexItem
                  /> */}
								</>
							);
						})
					}
				</Grid>
				<Modal
					open={openModal}
					onClose={handleClose}
					closeAfterTransition
					slots={{ backdrop: Backdrop }}
					slotProps={{
						backdrop: {
							timeout: 1000,
						},
					}}
				>
					<Fade in={openModal}>
						<Box
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								width: isMobile ? "95%" : "600px",
								height: isMobile ? "85%" : "550px",
								bgcolor: "background.paper",
								border: "1px solid #000",
								borderRadius: "8px",
								boxShadow: 24,
								p: 4,
							}}
						>
							<span
								style={{
									position: " absolute",
									right: " -12px",
									top: "-14px",
									cursor: "pointer",
									borderRadius: "50%",
									height: "30px",
									width: "30px",
									backgroundColor: "white",
									textAlign: "center",
									fontSize: "26px",
								}}
							>
								<CancelTwoToneIcon onClick={handleClose} fontSize="inherit" />
							</span>
							<Div sx={{ width: "100%", height: "100%" }}>
								<SingleDocumentPreview document={currentDoc} />
							</Div>
						</Box>
					</Fade>
				</Modal>
			</Div>
		);
	}
}

export default DisplayDocumentPreview;
