import { getTasksList } from "app/Apis/task";
import Cookies from "js-cookie";
import { useState } from "react";

const useTasks = ()=>{
    const accessToken = Cookies.get("accessToken");
    const getAllTasks = async (pageNumber=0,pageSize=10,basicFilter,startDate,endDate)=>{
        if(!accessToken){
            throw Error("No access Token provided");
        }
        const response = await getTasksList(accessToken,pageNumber,pageSize,basicFilter,startDate,endDate);
        if(response){
            return response;
        }else{
            return null;
        }

    }
    return getAllTasks;
}
export default useTasks;