import React, { useEffect, useRef, useState } from "react";
import Div from "@jumbo/shared/Div";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createDrafting } from "app/Apis/drafting";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import StopOutlinedIcon from "@mui/icons-material/StopOutlined";

import {
  Autocomplete,
  Typography,
  Button,
  TextField,
  Link,
} from "@mui/material";
import Cookies from "js-cookie";
import { getCases } from "app/Apis/case";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { AudioRecorderWidget } from "../legalDrafting/components/audioRecorderWidget";

const hearingValidation = Yup.object().shape({
  caseId: Yup.string().nullable().optional(),
  selectedCase: Yup.object().optional().nullable(),
  draftingName: Yup.string().required("Drafting Name is Required"),
  language: Yup.string().required("Language is Required").nullable(),
});
const languages = ["Hindi", "English"];

export const EditLegalDrafting = ({
  setEdit,
  setEditItem,
  item,
  setUpdate,
  setSnackBarText,
  setOpenSnackBar
}) => {
  const accessToken = Cookies.get("accessToken");
  const [bigChunk, setBigChunk] = useState(null);
  const [caseId, setCaseId] = useState(item?.caseDetails?.caseId || null);
  const [selectedCase, setSelectedCase] = useState(null);
  const [caseOptions, setCaseOptions] = useState([]);
  const [draftingName, setDraftingName] = useState(item.draftName || "");
  const [languageSelection, setLanguageSelection] = useState(
    item.langId || null
  );
  const [documentListFromApi, setDocumentListFromApi] = useState([]);
  const [audioIndex, setAudioIndex] = useState(0);
  const [audioFiles] = useState(item?.documentLists.map((i) => i.documentUrl));
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });

  useEffect(() => {
    const getCasesTemp = async () => {
      const response = await getCases(accessToken);
      console.log(response);
      if (response) {
        setCaseOptions(response);
        setSelectedCase(
          response.find((i) => i.caseId === item?.caseDetails?.caseId)
        );
      }
    };
    getCasesTemp();
  }, []);

  const formik = useFormik({
    initialValues: {
      caseId: caseId,
      selectedCase: selectedCase,
      draftingName: draftingName,
      language: languageSelection,
    },
    validationSchema: hearingValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let data;
      console.log(formik.errors);
      if (item?.documentType === "Audio") {
        if (documentListFromApi?.length > 0) {
          let tempMin = item.audioMinutes + time.minutes;
          let tempSec = item.audioSeconds + time.seconds;
          if (tempSec >= 60) {
            tempSec = tempSec - Math.floor(tempSec / 60) * 60;
            tempMin = tempMin + Math.floor(tempSec / 60);
          }
          data = {
            ...item,
            documentLists: item.documentLists.concat(documentListFromApi),
            newDocs: documentListFromApi,
            isUpdate: 1,
            audioMinutes: tempMin,
            audioSeconds: tempSec,
          };
        } else {
          data = {
            ...item,
          };
        }
      } else {
        data = {
          ...item,
        };
      }
      console.log(data);
      try {
        const response = await createDrafting(accessToken, data);
        console.log(response);
        if (response) {
          console.log(response);
          setEdit(false);
          setEditItem(null);
          setUpdate((prev) => !prev);
          setSnackBarText("Draft added. Please wait while we process it.");
          setOpenSnackBar(true);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  const audioRef = useRef(null);  
  const [minutes, setMinutes] = useState(item.audioMinutes);
  const [seconds, setSeconds] = useState(item.audioSeconds);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let countdownInterval;

    if (isCounting) {
      countdownInterval = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          clearInterval(countdownInterval);
          setIsCounting(false);
        } else {
          if (seconds === 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
          } else {
            setSeconds(seconds - 1);
          }
        }
      }, 1000);
    } else {
      clearInterval(countdownInterval);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [isCounting, minutes, seconds]);
  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.load();
      audioRef.current.play();
      setIsPlaying(true);
      setIsCounting(true);
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
      handleResetCountdown()
    }
  };
  const handleResetCountdown = () => {
    setIsCounting(false);
    setTimeout(() => {
      setMinutes(item.audioMinutes);
      setSeconds(item.audioSeconds);
    }, 0);
  };
  const handleAudioEnded = () => {
    if (audioIndex < audioFiles.length - 1) {
      setAudioIndex(audioIndex + 1);
      playAudio();
      handleResetCountdown()
    }
  };
  return (
    <Div sx={{ height: "100%", width: "100%" }}>
        <Div
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Div sx={{ width: "110%" }}>
            <div
              style={{
                height: "100%",
                width: "100%",
                paddingBottom: "30px",
              }}
            >
              <Div
                sx={{
                  marginLeft: { xs: "5%", md: "18%" },
                  marginRight: { xs: "5%", md: "18%" },
                  marginTop: "20px",
                  height: "100%",
                }}
              >
                <TextField
                  sx={{ mb: 0, mt: 1, width: "100%" }}
                  id="outlined-basic"
                  label="Drafting Name"
                  variant="outlined"
                  disabled
                  value={formik.values.draftingName}
                  name="draftingName"
                  color="secondary"
                  onChange={(e) => setDraftingName(e.target.value)}
                  required
                  error={formik.errors.draftingName}
                  helperText={formik.errors.draftingName}
                />
                <Autocomplete
                  style={{ width: "100%" }}
                  value={languageSelection}
                  disabled
                  onChange={(event, value) => {
                    console.log(value);
                    setLanguageSelection(value);
                  }}
                  options={languages}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option}</li>
                  )}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        mt: 2,
                        backgroundColor: "white",
                      }}
                      {...params}
                      label="Language"
                      placeholder="Language"
                      disabled
                      color="secondary"
                      required
                      error={formik.errors.language}
                      helperText={formik.errors.language}
                    />
                  )}
                />
                <Autocomplete
                  style={{ width: "100%" }}
                  value={selectedCase}
                  disabled
                  onChange={(e, v) => setSelectedCase(v)}
                  isOptionEqualToValue={(o, v) => o.caseId === v.caseId}
                  options={caseOptions}
                  getOptionLabel={(option) => {
                    return `${option?.caseFirstName} VS ${option?.caseSecondName}`;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                    >{`${option?.caseFirstName} VS ${option?.caseSecondName}`}</li>
                  )}
                  sx={{ width: 500, mt: 2 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Case"
                      disabled
                      placeholder="Case"
                      color="secondary"
                      error={formik.errors.selectedCase}
                      helperText={formik.errors.selectedCase}
                      name="case"
                    />
                  )}
                />
                <Div sx={{ mt: 2 }}>
                  <Typography variant="h6" sx={{ mr: 3 }}>
                    Document Link:
                    <Link
                      href={item?.googleDocLink}
                      sx={{ pl: 2, width: "100%" }}
                      target="_blank"
                    >
                      Click Here To Open Document.
                    </Link>
                  </Typography>
                </Div>
                {item?.documentType === "Audio" && languageSelection && (
                  <>
                    <Div
                      sx={{
                        display: "flex",
                        alignItems: { sm: "center", xs: "flex-start" },
                        mb: 0,
                        mt: 2,
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                    >
                      {item?.languageTranslation === "No" && (
                        <Div>
                          {languageSelection === "English" ? (
                            <Typography sx={{ mr: 3 }}>
                              Translation: Translating to Hindi
                            </Typography>
                          ) : (
                            <Typography sx={{ mr: 3 }}>
                              Translation: Translating to English
                            </Typography>
                          )}
                        </Div>
                      )}
                    </Div>
                  </>
                )}
                {/* <TextField
                  sx={{ mb: 0, mt: 2, width: "100%" }}
                  id="outlined-basic"
                  label="Document Link"
                  variant="outlined"
                  defaultValue={item?.googleDocLink}
                  value={item?.googleDocLink}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="docLink"
                  color="secondary"
                /> */}
                {item?.documentType === "Audio" && (
                  <>
                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Div
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2rem",
                            lineHeight: 1.5,
                            fontWeight: 400,
                            marginRight: "5px",
                          }}
                        >
                          {" "}
                          {minutes < 10 ? "0" + minutes : minutes}:
                          {seconds < 10 ? "0" + seconds : seconds}
                        </Typography>
                        <audio
                          autoPlay={false}
                          ref={audioRef}
                          onEnded={handleAudioEnded}
                        >
                          <source
                            src={audioFiles[audioIndex]}
                            type="audio/wav"
                          />
                          Your browser does not support the audio element.
                        </audio>
                        <Button
                          variant="contained"
                          onClick={isPlaying ? pauseAudio : playAudio}
                          sx={{ height: "fit-content", ml: 2 }}
                        >
                          {isPlaying ? (
                            <StopOutlinedIcon sx={{ marginRight: 1 }} />
                          ) : (
                            <PlayCircleFilledWhiteOutlinedIcon
                              sx={{ marginRight: 1 }}
                            />
                          )}
                          {isPlaying ? "Stop" : "Play"}
                        </Button>
                      </Div>
                    </Div>
                    <Div
                      sx={{
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.15rem",
                          lineHeight: 1.5,
                          fontWeight: 400,
                        }}
                      >
                        Add more audio below:
                      </Typography>
                      <AudioRecorderWidget
                        setBigChunk={setBigChunk}
                        bigChunk={bigChunk}
                        setDocumentListFromApi={setDocumentListFromApi}
                        documentListFromApi={documentListFromApi}
                        time={time}
                        setTime={setTime}
                        languageSelection={languageSelection}
                      />
                    </Div>
                  </>
                )}
              </Div>
              <Div
                sx={{
                  marginLeft: { xs: "5%", md: "18%" },
                  marginRight: { xs: "5%", md: "18%" },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 5,
                }}
              >
                <LoadingButton
                  onClick={() => {
                    setEdit(false);
                    setEditItem(null);
                  }}
                  // disabled={true}
                  variant="contained"
                  sx={{ width: 100, padding: 1 }}
                >
                  Back
                </LoadingButton>
                {documentListFromApi.length > 0 || !item?.documentType ==="Audio" ?
                <LoadingButton
                  onClick={() => {
                    console.log(formik.values);
                    console.log(formik.errors);
                    formik.handleSubmit();
                  }}
                  color="secondary"
                  style={{
                    backgroundColor: "black",
                  }}
                  sx={{ width: 100, padding: 1 }}
                  variant="contained"
                >
                  Submit
                </LoadingButton> :
                <LoadingButton
                  onClick={() => {
                    console.log(formik.values);
                    console.log(formik.errors);
                    formik.handleSubmit();
                  }}
                  color="primary"
                  disabled
                  sx={{ width: 100, padding: 1 }}
                  variant="contained"
                >
                  Submit
                </LoadingButton>
              }
              </Div>
            </div>
          </Div>
        </Div>
    </Div>
  );
};
