import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Link,
  Breadcrumbs,
  Grid,
  Tabs,
  Tab,
  InputBase,
  Select,
  MenuItem,
  Button,
  ListItemText,
  MenuList,
  Popover,
  Chip
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TaskSkeletons from "../Todos/TaskSkeletons";
import NoDataDisplay from "app/Components/Dashboard/Widgets/NoDataDisplay/NoDataDisplay";
import SingleDataCard from "app/Components/DataDisplayCards/SingleDataDisplayCard/SingleDataCard";
import GavelIcon from "@mui/icons-material/Gavel";
import GroupIcon from "@mui/icons-material/Group";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { getPublicationList } from "app/Apis/Publications";
import CardFooter from "../components/CardFooter";
import ArticleIcon from "@mui/icons-material/Article";
import EditPublication from "./EditPublication";
import AddIcon from "@mui/icons-material/Add";
import PublicationsList from "./PublicationsList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";



const dayRangeOption = [
  "Select",
  "Today",
  "Yesterday",
  "Tomorrow",
  "This Week",
  "This Month",
  "Choose Date",
];

const optionsPageSize = ["5", "10", "20", "25", "50"];

const publicationOptions = ["Article","Announcement","FAQ","News and Update","Blog"]

const Publications = () => {
  const [value, setValue] = useState(0);
  const [dayRangeFilter, setDayRangeFilter] = useState(dayRangeOption[0]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState("5");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [update, setUpdate] = useState(false);
  const [type, setType] = useState("Article");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCategory, setSearchCatgory] = useState("Title");
  const [title, setTitle] = useState(null);
  const [sections, setSections] = useState(null);
  const [court, setCourt] = useState(null);
  const [publicationEdit, setPublicationEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [publicationAdd, setPublicationAdd] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageType,setLanguageType] = useState("English");
  const open = Boolean(anchorEl);
  const assignedRole = Cookies.get("assignedRole");



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value.length === 0) {
      setUpdate((prev) => !prev);
    }
    setSearchQuery(value);
  };

  const resetFilters = () => {
    setSearchQuery("");
    setTitle(null);
    setSections(null);
    setCourt(null);
  };
  const getPublications = async () => {
    try {
      setIsLoading(true);
      const response = await getPublicationList(
        pageNumber,
        pageSize,
        type,
        title,
        court,
        sections,
        languageType
      );
      console.log(response);
      console.log(Boolean(response));
      // debugger;
      if (response) {
        setList(response?.publicationsDetails);
        console.log(response?.publicationsDetails.length);
        setTotalPages(response?.totalPages);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  console.log(list ? list.length===0:false);
  useEffect(() => {

    getPublications();
    
  }, [pageSize, value, update, type, title, court, sections,pageNumber,languageType]);

  useEffect(() => {
    console.log(searchCategory);
    console.log(searchQuery);
    const getList = async () => {
      if (searchCategory === "Title") {
        if (searchQuery.length >= 3) {
          setTitle(searchQuery);
        }
      } else if (searchCategory === "Sections") {
        if (searchQuery.length > 0) {
          setSections(searchQuery);
        }
      } else if (searchCategory === "Court") {
        if (searchQuery.length >= 3) {
          if (searchQuery === "High") {
            setCourt("High Court");
          } else if (searchQuery === "Lower") {
            setCourt("Lower Court");
          } else if (searchQuery === "Supreme") {
            setCourt("Supreme Court");
          } else if (searchQuery === "District") {
            setCourt("District Court");
          }
        }
      }
    };
    setIsLoading(true);
    getList();
    setIsLoading(false);
  }, [searchCategory, searchQuery]);

  useEffect(() => {
    if (publicationEdit) {
      // setIsEdit(true);
      setPublicationAdd(true);
    }
  }, [publicationEdit]);

  const lawyersFilterBy = [
    {
      label: "Specialisation",
      options: [
        {
          name: "Option 1",
          currentOption: false,
        },
        {
          name: "Option 2",
          currentOption: false,
        },
        {
          name: "Option 3",
          currentOption: false,
        },
        {
          name: "Option 4",
          currentOption: false,
        },
      ],
      setFilter: (option, index) => {
        if (index !== -1 && !option) {
          // setSpecialisationType(this.options[index].name);
          this.options[index].currentOption = true;
        }
      },
    },
  ];

  if (assignedRole === "ROLE_ADMIN" || assignedRole === "ROLE_MODERATOR") {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link href="/admin/daily-activity" underline="none">
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <ArticleIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Publicatioin
            </Typography>
          </Link>
          {/* {addJudgement && (
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <LiveHelpIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Add Judgement
            </Typography>
          )} */}
        </Breadcrumbs>
        <Grid container spacing={3.75} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <SingleDataCard
              title={50}
              subheader={"Total Articles"}
              Icon={<GavelIcon style={{ color: "#EABF4E" }} />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SingleDataCard
              title={50}
              subheader={"Total Blogs"}
              Icon={<GroupIcon style={{ color: "#EABF4E" }} />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SingleDataCard
              title={50}
              subheader={"Total Announcements"}
              Icon={<DirectionsRunIcon style={{ color: "#EABF4E" }} />}
            />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <SingleDataCard
              title={50}
              subheader={"Total Non Active Lawyers"}
              Icon={<VoiceOverOffIcon style={{ color: "#EABF4E" }} />}
            />
          </Grid> */}
        </Grid>
        <Paper
          sx={{
            minHeight: "80%",
            backgroundColor: "white",
            p: 3,
            position: "relative",
          }}
          elevation={20}
        >
          <Grid container spacing={2}>
            {(!publicationAdd) && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap:"wrap"
                }}
              >
                
                {/* <Div
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                   
                  }}
                >
                  <Tabs
                    variant="scrollable"
                    allowScrollButtonsMobile
                    value={value}
                    textColor="secondary"
                    indicatorColor="secondary"
                    sx={{ mb: 0 }}
                  >
                    <Tab
                      onClick={() => {
                        setType("Article");
                        setValue(0);
                      }}
                      sx={{
                        fontSize: { xs: "14px", md: "14px" },
                        fontWeight: "800",
                        p:0
                      }}
                      label="Articles"
                    />
                    <Tab
                      onClick={() => {
                        setType("Blog");
                        setValue(1);
                      }}
                      sx={{
                        fontSize: { xs: "14px", md: "14px" },
                        fontWeight: "800",
                        p:0
                      }}
                      label="Blogs"
                    />
                    <Tab
                      onClick={() => {
                        setType("Announcement");
                        setValue(2);
                      }}
                      sx={{
                        fontSize: { xs: "14px", md: "14px" },
                        fontWeight: "800",
                        p:0
                      }}
                      label="Announcements"
                    />
                    <Tab
                      onClick={() => {
                        setType("FAQ");
                        setValue(3);
                      }}
                      sx={{
                        fontSize: { xs: "14px", md: "14px" },
                        fontWeight: "800",
                        p:0
                      }}
                      label="FAQs"
                    />
                    <Tab
                      onClick={() => {
                        setType("News And Update");
                        setValue(4);
                      }}
                      sx={{
                        fontSize: { xs: "14px", md: "14px" },
                        fontWeight: "800",
                        p:0
                      }}
                      label="News"
                    />
                  </Tabs>
                </Div> */}
                <Div
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "145px",
                    }}
                  >
                    <Div
                      style={{
                        height: "19px",
                        width: "5px",
                        backgroundColor: "#EABF4E",
                        marginRight: "8px",
                      }}
                    ></Div>
                    <Typography
                      sx={{
                        fontSize: "1.25rem",
                        lineHeight: 1.5,
                        fontWeight: 400,
                      }}
                    >
                      {"All Publications"}
                    </Typography>
                  </Div>
                {(!publicationAdd) && (
                    <Button
                      style={{ fontWeight: "700", flex: "1" }}
                      startIcon={<AddIcon />}
                      color="secondary"
                      onClick={() => {
                        setPublicationAdd(true);
                      }}
                    >
                      Add
                    </Button>
                  )}
                {assignedRole === "ROLE_ADMIN" && (
                  <Div
                    sx={{
                      position: "relative",
                      width: "40%",
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    <Div
                      sx={{
                        padding: (theme) => theme.spacing(0, 1),
                        height: "100%",
                        position: "absolute",
                        right: 0,
                        pointerEvents: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 2,
                      }}
                    >
                      <SearchIcon color="secondary" />
                    </Div>
                    <InputBase
                      id="search-bar"
                      className="search"
                      placeholder={`Search  by ${searchCategory}`}
                      size="small"
                      onChange={handleSearchChange}
                      value={searchQuery}
                      inputProps={{
                        maxLength: 10,
                      }}
                      sx={{
                        color: "inherit",
                        display: "flex",
                        borderRadius: 24,
                        backgroundColor: (theme) =>
                          theme.jumboComponents.JumboSearch.background,
                        paddingRight: "5rem",

                        "& .MuiInputBase-input": {
                          padding: (theme) => theme.spacing(1, 1, 1, 0),
                          paddingLeft: (theme) => "6.2rem",
                          transition: (theme) =>
                            theme.transitions.create("width"),
                          width: "100%",
                          height: 24,
                        },
                      }}
                    />
                    {searchQuery && (
                      <CloseIcon
                        color="secondary"
                        sx={{
                          position: "absolute",
                          right: "35px",
                          bottom: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSearchQuery("");
                          resetFilters();
                        }}
                      />
                    )}
                    <Select
                      labelId="select-label"
                      value={searchCategory}
                      sx={{
                        maxHeight: 40,
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        borderBottomLeftRadius: 24,
                        borderTopLeftRadius: 24,
                      }}
                      onChange={(e) => {
                        setSearchCatgory(e.target.value);
                      }}
                    >
                      <MenuItem key={"Title"} value={"Title"}>
                        Title
                      </MenuItem>
                      <MenuItem key={"Sections"} value={"Sections"}>
                        Sections
                      </MenuItem>
                      <MenuItem key={"Court"} value={"Court"}>
                        Court
                      </MenuItem>
                    </Select>
                  </Div>
                )}
                {!publicationAdd && (
                  <Div sx={{ flex: 1 }}>
                  <Button
                    style={{ fontWeight: "600", minWidth: "100%" }}
                    startIcon={<FilterAltOutlinedIcon />}
                    endIcon={<KeyboardArrowDownIcon />}
                    color="secondary"
                    size="small"
                    onClick={handleClick}
                  >
                    Filter By
                  </Button>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{
                      minWidth: "340px",
                    }}
                  >
                    <Div
                      sx={{
                        widht: "100%",
                        height: "100%",
                        display: "flex",
                      }}
                    >
                      <MenuList
                        sx={{
                          boxShadow: "8px 0px 8px 0px rgba(244,244,244,1)",
                          width: "fit-content",
                        }}
                      >
                        <MenuItem>
                        <ListItemText>Type</ListItemText>
                        </MenuItem>
                        <MenuItem>
                          <ListItemText>Language</ListItemText>
                        </MenuItem>
                      </MenuList>
                      <Div
                        sx={{
                          p: "15px",
                          pt: 0,
                          mt: "2px",
                          backgroundColor: "#fefefe",
                        }}
                      >
                        <Div
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                py: "15px",
                                gap: "10px",
                                borderBottom: "1px solid rgb(236, 236, 236)",
                              }}
                            >
                              <Typography
                                sx={{
                                  lineHeight: "16px",
                                  opacity: 0.8,
                                  fontSize: "11px",
                                  color: "rgba(0, 0, 0, 0.8)",
                                  fontWeight: 500,
                                  display: "block",
                                }}
                              >
                                Types
                              </Typography>
                              <Div sx={{ display: "flex", gap: "8px" }}>
                                {publicationOptions && publicationOptions.map((pubLicationType)=>{
                                  return (
                                    <Chip
                                      label={pubLicationType}
                                      sx={type === pubLicationType
                                      ? {
                                          borderRadius: "14px",
                                          color: "white",
                                          backgroundColor: "#eabf4e",
                                          border:
                                            "0px solid rgb(230, 230, 230)",
                                        }
                                      : {
                                          borderRadius: "14px",
                                          color: "rgb(117, 117, 117)",
                                          backgroundColor: "white",
                                          border:
                                            "1px solid rgb(230, 230, 230)",
                                        }}
                                        onClick={()=>{
                                          setType(pubLicationType)
                                        }}
                                    />
                                  )
                                })}
                              </Div>
                            </Div>
                        <Div
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            py: "15px",
                            gap: "10px",
                            borderBottom: "1px solid rgb(236, 236, 236)",
                          }}
                        >
                          <Typography
                            sx={{
                              lineHeight: "16px",
                              opacity: 0.8,
                              fontSize: "11px",
                              color: "rgba(0, 0, 0, 0.8)",
                              fontWeight: 500,
                              display: "block",
                            }}
                          >
                            Language
                          </Typography>
                          <Div sx={{ display: "flex", gap: "8px" }}>
                            <Chip
                              label={"English"}
                              sx={
                                languageType === "English"
                                  ? {
                                      borderRadius: "14px",
                                      color: "white",
                                      backgroundColor: "#eabf4e",
                                      border:
                                        "0px solid rgb(230, 230, 230)",
                                    }
                                  : {
                                      borderRadius: "14px",
                                      color: "rgb(117, 117, 117)",
                                      backgroundColor: "white",
                                      border:
                                        "1px solid rgb(230, 230, 230)",
                                    }
                              }
                              onClick={() => setLanguageType("English")}
                            />
                            <Chip
                              label={"Hindi"}
                              sx={
                                languageType === "Hindi"
                                  ? {
                                      borderRadius: "14px",
                                      color: "white",
                                      backgroundColor: "#eabf4e",
                                      border:
                                        "0px solid rgb(230, 230, 230)",
                                    }
                                  : {
                                      borderRadius: "14px",
                                      color: "rgb(117, 117, 117)",
                                      backgroundColor: "white",
                                      border:
                                        "1px solid rgb(230, 230, 230)",
                                    }
                              }
                              onClick={() => {setLanguageType("Hindi");setPageNumber(0);}}
                            />
                          </Div>
                        </Div>
                      </Div>
                    </Div>
                  </Popover>
                </Div>
                )}
                {!publicationAdd && (
                <Div>
                  <Select
                    labelId="select-label"
                    value={pageSize}
                    sx={{ maxHeight: "40px", mr: "10px" }}
                    onChange={(e) => {
                      setPageSize(e.target.value);
                    }}
                  >
                    {optionsPageSize.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Div>
                )}
              </Grid>
            )}
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Div>
                {isLoading ? (
                  Array.from(Array(parseInt(pageSize))).map((item, index) => {
                    return <TaskSkeletons key={index} />;
                  })
                ) : (isEdit||publicationAdd) ? (
                  // <Div>add judgement</Div>
                  <EditPublication
                    setAddJudgement={setPublicationAdd}
                    setShowUpdate={setUpdate}
                    judgementEdit={publicationEdit}
                    setJudgementEdit={setPublicationEdit}
                  />
                ) : list && list.length === 0 ? (
                  <NoDataDisplay
                    text={"No Publication Found"}
                    url={"/admin/judgement/list"}
                    buttonText={"Add Publication"}
                    minHeight={"80px"}
                    fromPayment={true}
                  />
                ) : (
                  <PublicationsList
                    judgementList={list}
                    setJudgementEdit={setPublicationEdit}
                    isJudgement={false}
                  />
                )}
              </Div>
              {list && list.length !== 0 && !publicationAdd && (
                <Div
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    right: { sm: 0, lg: 25 },
                  }}
                >
                  <CardFooter
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                  />
                </Div>
              )}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
};

export default Publications;

// date selector
