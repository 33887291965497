import React from "react";
import {
  Card,
  Typography,
  CardHeader,
  Link,
  CardContent,
  Chip,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { formatDate } from "app/utils/dateTimeHelpers";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";


const HearingDashCard = ({ item, inCase, caseDetails }) => {
  // let mmm=item?.hearingDetails?.map((n,i)=> { if(i===item?.hearingDetails.length-1) return n})
  // console.log(mmm)
  let desc=""
  let purpose=""
  let result=""
  if(item?.hearingDetails && item?.hearingDetails.length>0){
    desc=item?.hearingDetails[item?.hearingDetails.length-1]?.hearingDescription
    purpose=item?.hearingDetails[item?.hearingDetails.length-1]?.hearingNotes
    result=item?.hearingDetails[item?.hearingDetails.length-1]?.hearingResult
  }
   console.log(desc)
  return (
    <Card sx={{ boxShadow: "none", backgroundColor: inCase ? "#f9f8f9" : "white" }}>
      <CardHeader
        sx={{ p: 2 }}
        action={
          <Chip
            label={
              item?.ecourtDetails?.hearingDate
                ? `${formatDate(item?.ecourtDetails?.hearingDate)}`
                : "Hearing Date"
            }
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        }
        title={

          <Chip
            label={caseDetails?.isPetitioner === 0 ? "Petitioner" : "Respondent"}
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        }
      />
       {/* <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          {item?.caseId ? (
            <Link
              underline="none"
              href={`/lawyers/casedetail?caseId=${
                item?.caseId && item?.caseId
              }`}
            >
              <Typography sx={{ fontSize: "19.2px" }}>
                {item?.caseName ? item?.caseName : "No Case Attatched"}
              </Typography>
            </Link>
          ) : (
            <Typography sx={{ fontSize: "19.2px" }}>
              {item?.caseName ? item?.caseName : "No Case Attatched"}
            </Typography>
          )}
          <Chip
            label={` ${priority[item?.priority]}`} // change according to api
            color="secondary"
            size="medium"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        </Div> */}
      <CardContent
        sx={{ p: 2, pt: 0, display: "flex", flexDirection: "column", gap: 1 }}
      >{item?.caseId ? (
        <Link
          underline="none"
          href={`/lawyers/casedetail?caseId=${item?.caseId && item?.caseId
            }`}
        >
          <Typography sx={{ fontSize: "19.2px" }}>
            {`${item?.caseFirstName} Vs ${item?.caseSecondName}`}
          </Typography>
        </Link>
      ) : (
        <Typography sx={{ fontSize: "19.2px" }}>
          {`${item?.caseFirstName} Vs ${item?.caseSecondName}`}
        </Typography>
      )}
        <Typography variant={"body1"} color={"text.secondary"}>
          {item?.currentCourt?.courtName}
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            maxHeight: "10%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {desc? desc: "Description"}
        </Typography>
        <Typography sx={{ fontSize: "1rem" }}>
          {purpose? purpose: "Purpose"}
        </Typography>
        <Div
          sx={{ 
            backgroundColor: "#FFFAEA",
            mx: "-16px",
            mb: "-24px",
            display: "flex",
            p: 2,
            pt: 1,
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <AutoStoriesIcon style={{ color: "#EABF4E", marginRight: "10px" }} />
          <Div sx={{ display: "flex", justifyContent: "space-around" }}>
            <Div>
              <Typography sx={{ fontSize: "1rem" }} color={"text.secondary"}>
                {" Case Status "}
              </Typography>
              <Typography sx={{ fontSize: "0.875rem" }} color={"text.secondary"}>
                {result?result : "Open" }
              </Typography>
            </Div>
            {/* <Div><Typography sx={{ mx: 10 }} variant={"body1"}>
              {"Created By"}
            </Typography></Div> */}

          </Div>
        </Div>
      </CardContent>
    </Card>
  );
};
export default HearingDashCard;
