import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { OfficialDetailsValidations } from "app/utils/validators";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Div from "@jumbo/shared/Div";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { loginValidations, loginValidations2 } from "app/utils/validators";
import { lang } from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  UserBarCouncil,
  UserLawyerEducation,
  UserLawyerLanguage,
  UserLawyerSpecialisation,
  UserPracticeCourts,
  UserPracticeStates,
} from "app/redux/actions/UpdatedInputs";
import {
  LawyerProfileUpdate,
  getLawyerState,
  getCourt,
  getEdu,
  getLang,
  getSpec,
  getCaseCity,
} from "app/Apis/User";
import { useFormik } from "formik";
import { createLawyersForTeamsProfile } from "app/Apis/teams";

const OfficialDetails = ({
  handleBackButton,
  handleNextButton,
  lawyerId,
  setLawyerId,
  professionalProfile,
  isPhoneNumberFilled,
  isLawyerInDB,
}) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    setOpenSnackBar(false);
    navigate("/lawyers/teams");
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cityList, setCityList] = useState([]);
  const [State, setState] = useState([]);
  const [Court, setCourt] = useState([]);
  const [Edu, setEdu] = useState([]);
  const [Specialisation, setSpecialisation] = useState([]);
  const [Language, setLanguage] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityError, setCityError] = useState(false);

  const getStates = async () => {
    const state = await getLawyerState();
    if (!state) {
      console.log("error while getting states list", state);
    }
    state.shift();
    // console.log(state);
    setState(state);
  };

  const getCourts = async () => {
    const courts = await getCourt();
    if (!courts) {
      console.log("error while getting courts list", courts);
    }
    // state.shift();
    // console.log(courts);
    setCourt(courts);
  };
  const getEduc = async () => {
    const edu = await getEdu();
    if (!edu) {
      console.log("error while getting edu list", edu);
    }
    // console.log(edu);
    setEdu(edu);
  };
  const getSpecialisatioin = async () => {
    const spec = await getSpec();
    if (!spec) {
      console.log("error while getting pec list", spec);
    }
    // state.shift();
    // console.log(spec);
    setSpecialisation(spec);
  };

  const getLanguage = async () => {
    const lang = await getLang();
    if (!lang) {
      console.log("error while getting lang list", lang);
    }
    // state.shift();
    // console.log(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    const getInitValues = async () => {
      await getStates();
      await getCourts();
      await getEduc();
      await getSpecialisatioin();
      await getLanguage();
    };
    getInitValues();
  }, []);

  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );

  const barCouncilRegistrationNo = useSelector(
    (state) => state?.updateInputs?.barCouncilRegistrationNo
  );

  const practiceStates = useSelector(
    (state) => state?.updateInputs?.practiceStates
  );

  const practiceCourts = useSelector(
    (state) => state?.updateInputs?.practiceCourts
  );

  const lawyerEducations = useSelector(
    (state) => state?.updateInputs?.lawyerEducations
  );

  const lawyerSpecialisation = useSelector(
    (state) => state?.updateInputs?.lawyerSpecialisation
  );

  const lawyerLanguage = useSelector(
    (state) => state?.updateInputs?.lawyerLanguage
  );

  const formik = useFormik({
    initialValues: {
      barcouncilnumber: professionalProfile
        ? professionalProfile?.barCouncilRegistrationNo
        : "",
    },
    // validationSchema: loginValidations2,
    enableReinitialize: true,
    onSubmit: (values) => {
      formik.resetForm();
      setOpenSnackBar(true);
      // navigate("/lawyers/teams")
      Incrementfn();
      // navigate("/onboarding");
    },
  });

  const [states, setStates] = useState(
    professionalProfile ? professionalProfile?.practiceStates : []
  );
  const [courts, setCourts] = useState(
    professionalProfile ? professionalProfile?.practiceCourts : []
  );
  const [education, setEducation] = useState(
    professionalProfile ? professionalProfile?.lawyerEducations : []
  );
  const [spec, setSpecial] = useState(
    professionalProfile ? professionalProfile?.lawyerSpecialisation : []
  );
  const [lang, setLang] = useState(
    professionalProfile ? professionalProfile?.lawyerLanguage : []
  );

  const [error, setError] = useState(false);

  //error
  const [stateError, setStateError] = useState(false);
  const [practiceCourtError, setPracticeCourtError] = useState(false);
  const [specialisationError, setSpecialisationError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [educationError, setEducationError] = useState(false);

  // console.log(fieldState);
  // console.log(fieldPracticeCourt);
  useEffect(() => {
    const getCity = async () => {
      try {
        if (states.length > 0) {
          states?.map(async(state)=>{
          const response = await getCaseCity(
            state?.stateId
          );
          if (response) {
            setCityList((prev)=>[...prev,...response]);
            console.log(response);
          }else{
            console.log("No City Found");
          }
        })
        }
      
      } catch (error) {
        console.log(error);
      }
    };
    getCity();
  }, [states.length]);

  const Incrementfn = async () => {
    let state = [];
    let court = [];
    let educ = [];
    let speciality = [];
    let lan = [];
    let city = [];
    if (states.length === 0) {
      setError(true);
      setStateError(true);
    } else {
      console.log("in else state");
      states.map((ele) => {
        state.push({
          stateId: ele.stateId,
        });
      });
      setStates(state);
      console.log(state);
    }
    if (cities.length === 0) {
      setError(true);
      setCityError(true);
    } else {
      console.log("in else city");
      cities.map((ele) => {
        city.push({
          districtId: ele.districtId,
        });
      });
      setCities(city);
      console.log(city);
    }
    if (courts.length === 0) {
      setError(true);
      setPracticeCourtError(true);
    } else {
      courts.map((ele) => {
        court.push({
          courtsId: ele.courtsId,
        });
      });
      console.log(court);
    }
    if (education.length === 0) {
      setError(true);
      setEducationError(true);
    } else {
      education.map((ele) => {
        educ.push({
          educationId: ele.educationId,
        });
      });
      console.log(educ);
    }
    if (spec.length === 0) {
      setError(true);
      setSpecialisationError(true);
    } else {
      spec.map((ele) => {
        speciality.push({
          specialityId: ele.specialityId,
        });
      });
      console.log(speciality);
      setEducation(education);
    }
    if (lang.length === 0) {
      setError(true);
      setLanguageError(true);
    } else {
      lang.map((ele) => {
        lan.push({
          languageId: ele.languageId,
        });
      });
      console.log(lan);
      setError(false);
      setStateError(false);
      setPracticeCourtError(false);
      setEducationError(false);
      setSpecialisationError(false);
      setLanguageError(false);
      setCityError(false);

      let obj = {
        barCouncilRegistrationNo,
        practiceStates: state,
        practiceCourts: court,
        lawyerEducations: educ,
        lawyerSpecialisation: speciality,
        lawyerLanguage: lan,
        practiceDistricts: city,
      };
      if (obj) {
        const response = await createLawyersForTeamsProfile(obj, lawyerId);
        if (response) {
          console.log("lawyer data is ", response);
          setLawyerId(null);
          handleNextButton()
          // navigate("/lawyers/teams")
          // navigate("/onboardingsuccess");
          // navigate("/comingSoon");
          // console.log("onboarding")
        } else {
          console.log("something went wrong!");
        }
      } else {
        console.log("lawyer id is not set");
      }
    }
  };

  const ValuesToReduxForState = () => {
    setStateError(false);
    dispatch(UserPracticeStates(states));
  };

  const ValuesToReduxForCourt = () => {
    setPracticeCourtError(false);
    dispatch(UserPracticeCourts(courts));
  };

  const ValuesToReduxForEducation = () => {
    setEducationError(false);
    dispatch(UserLawyerEducation(education));
  };

  const ValuesToReduxForSpecialisation = () => {
    setSpecialisationError(false);
    dispatch(UserLawyerSpecialisation(spec));
  };

  const valuesToReduxForLanguage = () => {
    setLanguageError(false);
    dispatch(UserLawyerLanguage(lang));
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },

          height: "100%",
        }}
      >
        {/* <form> */}
        {openSnackBar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackBar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            sx={{ zIndex: 1000, mt: 10 }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
            >
              <Typography variant="h6">Lawyer Added Successfully</Typography>
            </Alert>
          </Snackbar>
        )}
        <Div>
          <TextField
            // style={{ width: "30vw" }}
            style={{ width: "100%", backgroundColor: "white" }}
            id="outlined-multiline-flexible"
            label="Bar Council Number"
            // required
            color="secondary"
            inputProps={{ maxLength: 15 }}
            name="barcouncilnumber"
            maxRows={4}
            disabled={isPhoneNumberFilled ? isLawyerInDB : true}
            // error={formik.errors.barcouncilnumber}
            value={formik.values.barcouncilnumber}
            onChange={formik.handleChange}
            // helperText={formik.errors.barcouncilnumber}
            onBlur={dispatch(UserBarCouncil(formik.values.barcouncilnumber))}
          />
        </Div>

        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            value={states}
            onChange={(event, value) => {
              setStates(value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.stateId === value.stateId
            }
            multiple
            disableCloseOnSelect
            options={State}
            disabled={isPhoneNumberFilled ? isLawyerInDB : true}
            // disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.stateName;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.stateName}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => {
              return (
                <TextField
                  sx={{ marginTop: "10px", backgroundColor: "white" }}
                  {...params}
                  label="Select Practice State"
                  placeholder="State"
                  color="secondary"
                  // error={stateError}
                  onBlur={() =>
                    states.length > 0
                      ? ValuesToReduxForState()
                      : null
                  }
                />
              );
            }}
          />
          {/* {stateError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )} */}
        </Div>

        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            value={cities}
            onChange={(event, value) => {
              setCities(value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.districtId === value.districtId
            }
            multiple
            disableCloseOnSelect
            disabled={isPhoneNumberFilled ? isLawyerInDB : true}
            options={cityList}
            // disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.districtName;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.districtName}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => {
              return (
                <TextField
                  sx={{ marginTop: "10px", backgroundColor: "white" }}
                  {...params}
                  label="Select District"
                  placeholder="District"
                  color="secondary"
                  // error={cityError}
                  // onBlur={() =>
                  //   cities.length > 0 ? null : setCityError(false)
                  // }
                />
              );
            }}
          />
          {/* {cityError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )} */}
        </Div>

        {/* practice court */}
        <Div sx={{ mt: 0 }}>
          <Autocomplete
            style={{ width: "100%" }}
            value={courts}
            onChange={(event, value) => setCourts(value)}
            disabled={isPhoneNumberFilled ? isLawyerInDB : true}
            // isOptionEqualToValue={(option, value) => option.stateId === value.stateId}
            multiple
            disableCloseOnSelect
            options={Court}
            // disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.courtName;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.courtName}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px", backgroundColor: "white" }}
                {...params}
                label="Select Practice Court"
                placeholder="Practice Court"
                color="secondary"
                // required
                // error={practiceCourtError}
                onBlur={() =>
                  Court.length > 0
                    ? ValuesToReduxForCourt()
                    : null
                }
              />
            )}
          />
          {/* {practiceCourtError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )} */}
        </Div>

        {/* Education */}
        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            value={education}
            onChange={(event, value) => setEducation(value)}
            disabled={isPhoneNumberFilled ? isLawyerInDB : true}
            multiple
            disableCloseOnSelect
            // id="checkboxes-tags-demo"
            options={Edu}
            // disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.educationName;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.educationName}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px", backgroundColor: "white" }}
                {...params}
                // error={educationError}
                label="Select Education"
                placeholder="Education"
                // required
                color="secondary"
                onBlur={() =>
                  education.length > 0
                    ? ValuesToReduxForEducation()
                    : null
                }
              />
            )}
          />
          {/* {educationError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )} */}
        </Div>

        {/* specialisation */}
        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            value={spec}
            onChange={(event, value) => setSpecial(value)}
            disabled={isPhoneNumberFilled ? isLawyerInDB : true}
            multiple
            disableCloseOnSelect
            // id="checkboxes-tags-demo"
            options={Specialisation}
            // disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.specialityName;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.specialityName}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px", backgroundColor: "white" }}
                {...params}
                label="Specialisation"
                placeholder="Specialisation"
                // required
                color="secondary"
                // error={specialisationError}
                onBlur={() =>
                  spec.length > 0
                    ? ValuesToReduxForSpecialisation()
                    : null
                }
              />
            )}
          />
          {/* {specialisationError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )} */}
        </Div>

        {/* Language */}
        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            multiple
            disableCloseOnSelect
            value={lang}
            onChange={(event, value) => setLang(value)}
            disabled={isPhoneNumberFilled ? isLawyerInDB : true}
            // id="checkboxes-tags-demo"
            options={Language}
            // disableCloseOnSelect
            getOptionLabel={(option) => {
              return option.languageName;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.languageName}
              </li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "10px", backgroundColor: "white" }}
                {...params}
                label="Language"
                placeholder="Select Language"
                // required
                color="secondary"
                // error={languageError}
                onBlur={() =>
                  lang.length > 0
                    ? valuesToReduxForLanguage()
                    : null
                }
              />
            )}
          />
          {/* {languageError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Select atleast one value!
            </Typography>
          )} */}
        </Div>

        {/* </form> */}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            disabled={openSnackBar}
            // disabled={values !== 0 ? false : true}
            variant="contained"
            sx={{ width: 100, padding: 1 }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            loading={openSnackBar}
            onClick={formik.handleSubmit}
            // color="secondary"
            // style={{
            //   backgroundColor: "black",
            // }}
            sx={{ width: 100, padding: 1 , "&:hover": {
              backgroundColor: "#F1C40F",
            },}}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
};

export default OfficialDetails;
