import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  List,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function CardDetailModal({
  item,
  open,
  setOpen,
  handleModalClose,
  handleScreen,

  openSnackBar,
  setOpenSnackBar,
  // handleSnackbarClose,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  // const handleClose = () => {
  //   setAnchorEl(null);
  //   console.log("hello");
  // };

  const handleArchieve = () => {
    setOpen(false);
    setOpenSnackBar(true);
  };
  return (
    <Div sx={{}}>
      <Div sx={{ backgroundColor: "green" }}>
        <Modal
          keepMounted
          open={open}
          onClose={handleModalClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          sx={{ position: "relative", backgroundColor: "green" }}
        >
         
          <Div sx={style}>
          <Div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingTop: "2px",
                              cursor: "pointer",
                            }}
                          >
                            <CloseIcon onClick={handleModalClose} />
                          </Div>
            <Div sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
              >
                Document Details
              </Typography>

              <Button
                id="basic-button"
                aria-controls={openMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleScreen(1)}>Edit</MenuItem>
                <MenuItem onClick={() => handleScreen(1)}>Duplicate</MenuItem>
                <MenuItem onClick={handleArchieve}>Archieve</MenuItem>
              </Menu>
            </Div>
            
            {/* case Name */}
            <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "12px" }}
                component="h2"
              >
                Document Type
              </Typography>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "16px" }}
                component="h2"
              >
                {item.documentType}
              </Typography>
            </Div>
            <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "12px" }}
                component="h2"
              >
                Document Name
              </Typography>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "16px" }}
                component="h2"
              >
                {item.documentName}
              </Typography>
            </Div>
            <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "12px" }}
                component="h2"
              >
                Case Type
              </Typography>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "16px" }}
                component="h2"
              >
                {item.caseType}
              </Typography>
            </Div>
            <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "12px" }}
                component="h2"
              >
                Admissible Evidence
              </Typography>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "16px" }}
                component="h2"
              >
                Checkbox
              </Typography>
            </Div>
            <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "12px" }}
                component="h2"
              >
                Should This be visible to client
              </Typography>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "16px" }}
                component="h2"
              >
                Yes
              </Typography>
            </Div>
            {/* lawyer Name */}
            {/* <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "12px" }}
                component="h2"
              >
                Case lawyer
              </Typography>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "16px" }}
                component="h2"
              >
                {item.LawyerName}
              </Typography>
            </Div> */}
            {/* client Name */}
            {/* <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "12px" }}
                component="h2"
              >
                Case Client
              </Typography>
              <Typography
                id="keep-mounted-modal-title"
                sx={{ fontSize: "16px" }}
                component="h2"
              >
                {item.clientName}
              </Typography>
            </Div> */}
            {/* uploaded documents */}
            <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
              >
                Uploaded Documents
              </Typography>
              <Div
                sx={{
                  mt: 2,
                  border: "dashed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  padding: 2,
                }}
              >
                <img
                  style={{
                    object: "contain",
                    width: "100px",
                    marginRight: "10px",
                  }}
                  src="https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
                <img
                  style={{
                    object: "contain",
                    width: "100px",
                    marginRight: "10px",
                  }}
                  src="https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
                <img
                  style={{
                    object: "contain",
                    width: "100px",
                  }}
                  src="https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
              </Div>
            </Div>
            {/* document description */}
            <Div sx={{ mt: 2 }}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
              >
                Document Description
              </Typography>
              <Div
                sx={{
                  mt: 2,
                  border: "solid",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  padding: 2,
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Distinctio tempora nesciunt molestias ullam enim illo odio,
                voluptatum quidem facilis corporis doloremque possimus corrupti
                iure maxime voluptatem aliquam, officia saepe perferendis.
              </Div>
            </Div>
          </Div>
        </Modal>
      </Div>
    </Div>
  );
}

export default CardDetailModal;
