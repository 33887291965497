export const UPDATE_CASE_NAME = 'UPDATE_CASE_NAME';
export const UPDATE_CASE_FIRST_NAME = 'UPDATE_CASE_FIRST_NAME';
export const UPDATE_CASE_SECOND_NAME = 'UPDATE_CASE_SECOND_NAME';
export const UPDATE_CASE_TYPE = 'UPDATE_CASE_TYPE';
export const UPDATE_CASE_NUMBER = 'UPDATE_CASE_NUMBER';
export const UPDATE_GR_NO = 'UPDATE_GR_NO';
export const UPDATE_FIR_NO = 'UPDATE_FIR_NO';
export const UPDATE_COURT = 'UPDATE_COURT';
export const UPDATE_ASSIGN_LAWYER = 'UPDATE_ASSIGN_LAWYER';
export const UPDATE_CASE_DESCRIPTION = 'UPDATE_CASE_DESCRIPTION';
export const UPDATE_UNDER_SECTION = 'UPDATE_UNDER_SECTION';
export const UPDATE_IS_PETITIONER= "UPDATE_IS_PETITIONER";
export const UPDATE_CASE_ID = "UPDATE_CASE_ID";

export const CLEAR_CASE_DATA = "CLEAR_CASE_DATA";

export const UPDATE_FILLING_NUMBER = 'UPDATE_FILLING_NUMBER';
export const UPDATE_REGISTRATION_NUMBER = 'UPDATE_REGISTRATION_NUMBER';
export const UPDATE_CNR_NUMBER = 'UPDATE_CNR_NUMBER';
export const UPDATE_OPPOSING_LAWYER = 'UPDATE_OPPOSING_LAWYER';
export const UPDATE_FILLING_DATE = 'UPDATE_FILLING_DATE';
export const UPDATE_REGISTRATION_DATE = 'UPDATE_REGISTRATION_DATE';
export const UPDATE_LAST_HEARING_DATE = 'UPDATE_LAST_HEARING_DATE';
export const UPDATE_NEXT_HEARING_DATE = 'UPDATE_NEXT_HEARING_DATE';
export const UPDATE_CASE_STAGE = 'UPDATE_CASE_STAGE';

export const UPDATE_DOCUMENT_LISTS = 'UPDATE_DOCUMENT_LISTS';


export const UPDATE_HEARING_DATE = 'UPDATE_HEARING_DATE';
export const UPDATE_HEARING_PURPOSE = 'UPDATE_HEARING_PURPOSE';
export const UPDATE_HEARING_DOCUMENT_LISTS = 'UPDATE_HEARING_DOCUMENT_LISTS';
export const UPDATE_HEARING_DESCRIPTION = 'UPDATE_HEARING_DESCRIPTION';

export const UPDATE_CASE_TASK_DESCRIPTION = 'UPDATE_CASE_TASK_DESCRIPTION';
export const UPDATE_CASE_TASK_NAME = 'UPDATE_CASE_TASK_NAME';
export const UPDATE_CASE_TASK_PRIORITY = 'UPDATE_CASE_TASK_PRIORITY';
export const UPDATE_CASE_TASK_DOCUMENT_LISTS = 'UPDATE_CASE_TASK_DOCUMENT_LISTS';
export const UPDATE_CLIENTS = "UPDATE_CLIENTS";
export const UPDATE_WITNESS = "UPDATE_WITNESS";
export const UPDATE_OPP_CLIENTS = "UPDATE_OPP_CLIENTS";



export const UPDATE_CASE_RESPONSE = 'UPDATE_CASE_RESPONSE';


export const UPDATE_WITNESS_DETAILS = "UPDATE_WITNESS_DETAILS";