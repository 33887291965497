import React from "react";
import Div from "@jumbo/shared/Div";
import { useState } from "react";
import { NoCaseContainer } from "../MyCases/style";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import {
  Alert,
  Breadcrumbs,
  Radio,
  RadioGroup,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { Grid } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import DocumentSkeletons from "./DocumentSkeletons";
import DocumentList from "./DocumentList";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { FilePresentOutlined } from "@mui/icons-material";
import { Card, Divider, InputBase, TextField } from "@mui/material";
import CardFooter from "../components/CardFooter";
import { useJumboTheme } from "@jumbo/hooks";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useMediaQuery } from "@mui/material";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { documentValidations } from "app/utils/validators";
import { createDocument } from "app/Apis/document";

function NoDocument() {
  const [addDocument, setAddDocument] = useState(false);
  useEffect(() => {
    setAddDocument(false);
  }, []);

  const location = useLocation();
  const [reset, setReset] = React.useState(false);
  const [allTasks, setAllTasks] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const { theme } = useJumboTheme();

  useEffect(() => {
    setAllTasks(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  //validation
  const [error, setError] = useState("");

  const [value, setValue] = React.useState("all");


 

  const handleChange = (event, e) => {
    setValue(e);
  };

  

  const [allDocuments, setAllDocuments] = useState(false);
  useEffect(() => {
    setAllDocuments(false);
  }, []);

  const AllDocumentsDisplay = () => {
    setAllDocuments(true);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hiddenFileInput = React.useRef(null);

  const [fieldCase, setFieldCase] = React.useState([]);
  const [fieldLawyer, setFieldLawyer] = React.useState([]);
  const [fieldClient, setFieldClient] = React.useState([]);
  const [fieldDescription, setFieldDescription] = React.useState();
  const [documentName, setDocumentName] = React.useState();
  const [documentType, setDocumentType] = useState([]);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const isBelow768px = useMediaQuery((theme) => theme.breakpoints.down(768));

  const [documentTypeError, setDocumentTypeError] = useState(false);
  const [documentNameError, setDocumentNameError] = useState(false);

  const formik = useFormik({
    initialValues: {
      case: fieldCase,
      type: documentType,
      caseLawyer: fieldLawyer,
      client: fieldClient,

      documentName: documentName,
      documentDescription: fieldDescription,
    },
    validationSchema: documentValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "FORMIK");
      AllDocumentsDisplay();
    //   setConfirm(true);
    },
  });
  const Case = ["Case 1", "Case 2"];
  const Type = ["FIR", "Case Diary"];
  const [typeList, setTypeList] = useState([
    {
      typeId: "1",
      typeName: "FIR",
    },
    {
      typeId: "2",
      typeName: "Case Diary",
    },
  ]);
  const Lawyer = ["Assigner 1", "Assigner 2"];
  const Client = ["Assigned 1", "Assigned 2"];

  const [documentUploaded, setDocumentUploaded] = React.useState([]);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
    // setConfirm(false);
  };

//   const [confirm, setConfirm] = useState(false);
  const accessToken = useSelector((state)=>state?.onboardingInputs?.accessToken);
  const handleContinue = async() => {
    
    if (
      documentType &&
      Object.keys(documentType).length > 0 &&
      documentName &&
      documentName.length > 0
    ) {
      setDocumentTypeError(false);
      setDocumentNameError(false);
      const documentData = {
        documentName:documentName,
        documentFile:singleFile,
        documentType:documentType,
        documentDescription:fieldDescription,
        isVisibleToClient:1,
        documentExtention:"jpg",
        isActive:1
      }
      console.log(documentData);
      const response = await createDocument(accessToken,documentData,"tasks");
      if(response){
        console.log("document created successfully");
      }
      setOpenSnackBar(true);
      handleClose();
    } else {
      setDocumentTypeError(true);
      setDocumentNameError(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  const [singleFile,setSingleFile] = useState(0);
  const handleChanged = (event) => {
    const files = event.target.files;
    console.log(files[0]);
    setSingleFile(files[0]);
    const updatedDocuments = [...documentUploaded];
    handleOpen();

    hiddenFileInput.current = event.target;

    for (let i = 0; i < files.length; i++) {
      updatedDocuments.push({
        url: URL.createObjectURL(files[i]),
        type: files[i].type,
        name: files[i].name,
      });
    }

    setDocumentUploaded(updatedDocuments);
  };

  const removeDocument = (deletedUrl) => {
    const newDocumentUploaded = documentUploaded.filter(
      (document) => document.url !== deletedUrl
    );

    setDocumentUploaded(newDocumentUploaded);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleRemoveDocument = () => {
    removeDocument(document.url);
  };

  const handleReset = () => {
    setDocumentName("");
    setFieldDescription("");
    setFieldCase([]);
    setDocumentType([]);
  };

  return (
    <NoCaseContainer>
      {/* <DocumentListLayout handleOpen={handleOpen} /> */}

      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "99%",
          mb: 1,
        }}
      >
        {openSnackBar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackBar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            sx={{ zIndex: 1000, mt: 10 }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
            >
              <Typography variant="h6">Your Document has been Added</Typography>
            </Alert>
          </Snackbar>
        )}

        <input
          style={{ marginTop: "10px", width: "30vw" }}
          accept="image/*,application/pdf"
          multiple
          type="file"
          ref={hiddenFileInput}
          onChange={handleChanged}
          hidden
        />

        <Div>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 0 }}>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} fontSize="inherit" />
              Home
            </Typography>

            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <FilePresentOutlined sx={{ fontSize: 20 }} />
              &nbsp; Documents
            </Typography>
          </Breadcrumbs>
        </Div>
      </Div>

      <Card
        sx={{
          p: 2,
          position: "relative",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
                flexWrap: "wrap",
              }}
            >
              <Typography variant={"h5"}>All Documents</Typography>
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={handleClick}
              >
                Add Document
              </Button>
            </Div>
            <Div>
              <ToggleButtonGroup
                color="secondary"
                value={value}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                size="small"
              >
                <ToggleButton value="all">All Cases</ToggleButton>
                <ToggleButton value="hearing">Latest Hearing</ToggleButton>
                <ToggleButton value="case">Latest Case</ToggleButton>
              </ToggleButtonGroup>
            </Div>
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                width: "30%",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                  mr: 0,
                  mb: 3,
                },
              }}
            >
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 4,
                }}
              >
                <Div
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <Div
                    sx={{
                      padding: (theme) => theme.spacing(0, 2),
                      height: "100%",
                      position: "absolute",
                      pointerEvents: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                    }}
                  >
                    <SearchIcon color="secondary" />
                  </Div>
                  <InputBase
                    id="search-bar"
                    className="search"
                    placeholder="Search the client"
                    size="small"
                    sx={{
                      color: "inherit",
                      display: "flex",
                      borderRadius: 24,
                      backgroundColor: (theme) =>
                        theme.jumboComponents.JumboSearch.background,

                      "& .MuiInputBase-input": {
                        padding: (theme) => theme.spacing(1, 1, 1, 0),
                        paddingLeft: (theme) =>
                          `calc(1em + ${theme.spacing(4)})`,
                        transition: (theme) =>
                          theme.transitions.create("width"),
                        width: "100%",
                        height: 24,
                      },
                    }}
                  />
                </Div>

                {/* filter */}
                <Button
                  id="basic-button"
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <Button
                    style={{ fontWeight: "700" }}
                    startIcon={<FilterAltOutlinedIcon />}
                    color="secondary"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Filter By
                  </Button>
                </Button>
              </Div>
            </Div>
          </Div>

          {/* div hidden on large and medium view */}
          <Div
            sx={{
              display: "none",
              alignItems: "center",
              width: "100%",
              [theme.breakpoints.down("md")]: {
                display: "flex",
                mr: 0,
                mt: 3,
                //   mb: 3,
              },
            }}
          >
            <Div
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Div
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Div
                  sx={{
                    padding: (theme) => theme.spacing(0, 2),
                    height: "100%",
                    position: "absolute",
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2,
                  }}
                >
                  <SearchIcon color="secondary" />
                </Div>
                <InputBase
                  id="search-bar"
                  className="search"
                  placeholder="Search the client"
                  size="small"
                  sx={{
                    color: "inherit",
                    display: "flex",
                    borderRadius: 24,
                    backgroundColor: (theme) =>
                      theme.jumboComponents.JumboSearch.background,

                    "& .MuiInputBase-input": {
                      padding: (theme) => theme.spacing(1, 1, 1, 0),
                      paddingLeft: (theme) => `calc(1em + ${theme.spacing(4)})`,
                      transition: (theme) => theme.transitions.create("width"),
                      width: "100%",
                      height: 24,
                    },
                  }}
                />
              </Div>

              <Button
                style={{ fontWeight: "700" }}
                startIcon={<FilterAltOutlinedIcon />}
                color="secondary"
                sx={{ whiteSpace: "nowrap" }}
              >
                Filter By
              </Button>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div sx={{ mb: 10 }}>
            {loading && <DocumentSkeletons />}
            {!loading && <DocumentList />}
          </Div>
          <Div
            sx={{
              position: "absolute",
              bottom: 20,
              right: { sm: 0, lg: 25 },
            }}
          >
            <CardFooter />
          </Div>
        </Div>
      </Card>

      <Div
        sx={{
          display: "none",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <Div>
            <JumboDemoCard wrapperSx={{ pt: 0 }}>
              <Div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Div
                    sx={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: "9999",
                    }}
                    onClick={handleClose}
                  >
                    <Div
                      sx={{
                        width: "600px",
                        bgcolor: "white",
                        borderRadius: "0.6rem 0.6rem 0 0",
                        boxShadow: 24,
                        p: 3,
                        backgroundColor: "rgb(255, 255, 255)",
                        maxHeight: "80vh",
                        minHeight: "50vh",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "0.4em",
                          backgroundColor: "transparent",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {/* <DocumentDetails /> */}

                      <Div
                        style={{
                          height: "100%",
                          width: "100%",
                          paddingBottom: "0px",
                        }}
                      >
                        <Div
                          sx={{
                            height: "100%",
                          }}
                        >
                          <Div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingTop: "2px",
                              cursor: "pointer",
                            }}
                          >
                            <CloseIcon onClick={handleClose} />
                          </Div>

                          <Div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  paddingBottom: "0px",
                                }}
                              >
                                <Div
                                  sx={{
                                    height: "100%",
                                  }}
                                >
                                  <Div>
                                    {/* <UploadDocument handleClick={handleClick} /> */}

                                    <JumboCardQuick
                                      headerSx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                        backgroundColor: "background.paper",
                                      }}
                                      wrapperSx={{ pt: 0 }}
                                      sx={{
                                        boxShadow: "none",
                                      }}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        direction={"row"}
                                        alignItems={"stretch"}
                                      >


                                                {/* <UploadChip/> */}
                                        <Grid item md={4}>
                                          {/* <UploadDocumentChip handleClick={handleClick} /> */}

                                          <Div
                                            sx={{
                                              display: "flex",
                                              alignContent: "center",
                                              justifyContent: "center",
                                              mt: 1,
                                              p: 1,
                                              border:
                                                "1px solid rgb(232, 232, 232)",
                                              borderRadius: "8px",
                                              cursor: "pointer",
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgb(232, 232, 232)",
                                              },
                                              minHeight: "150px",
                                            }}
                                            onClick={handleClick}
                                          >
                                            <Div
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "1rem",
                                                  color: "rgb(79, 79, 79)",
                                                  fontWeight: 500,
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                Add Files
                                              </Typography>
                                              <AddCircleOutlineIcon />
                                            </Div>
                                          </Div>
                                        </Grid>
                                        {documentUploaded.map((document) => (
                                          <Grid item md={4}>
                                    

                                            <Div
                                              sx={{
                                                display: "flex",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                mt: 1,

                                                borderRadius: "8px",
                                                cursor: "pointer",
                                                "&:hover": {
                                                  backgroundColor:
                                                    "rgb(232, 232, 232)",
                                                },
                                                minHeight: "150px",
                                              }}
                                            >
                                              <Div
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  flexWrap: "wrap",
                                                  justifyContent: "center",
                                                  maxWidth: "100%",
                                                  maxHeight: "100%",
                                                  overflow: "auto",
                                                  width: "100%",
                                                }}
                                              >
                                                <Div
                                                  key={document.url}
                                                  sx={{
                                                    position: "relative",

                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    height: "auto",
                                                  }}
                                                >
                                                  {document.type.startsWith(
                                                    "image/"
                                                  ) ||
                                                  document.type ===
                                                    "application/pdf" ? (
                                                    <img
                                                      style={{
                                                        width: "154px",
                                                        height: "150px",
                                                        borderRadius: "5px",
                                                        objectFit: "contain",
                                                      }}
                                                      src={document.url}
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <Box
                                                      sx={{
                                                        Width: "100%",
                                                        height: "auto",
                                                        borderRadius: "5px",
                                                        backgroundColor:
                                                          "#f5f5f5",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        marginRight: "10px",
                                                        padding: "10px",
                                                      }}
                                                    >
                                                      <Typography
                                                        variant="body2"
                                                        sx={{
                                                          color: "#777",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {document.name}
                                                      </Typography>
                                                    </Box>
                                                  )}
                                                  <CloseIcon
                                                    onClick={() =>
                                                      removeDocument(
                                                        document.url
                                                      )
                                                    }
                                                    sx={{
                                                      position: "absolute",
                                                      right: "0px",
                                                      top: 2,
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </Div>
                                                {/* ))} */}
                                              </Div>
                                            </Div>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </JumboCardQuick>
                                  </Div>

                                  <Div>
                                    <Autocomplete
                                      style={{ width: "100%" }}
                                      value={formik.values.type}
                                      onChange={(event, value) =>
                                        setDocumentType(value)
                                      }
                                      options={Type}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => <li {...props}>{option}</li>}
                                      sx={{ width: 500 }}
                                      renderInput={(params) => (
                                        <TextField
                                          sx={{ marginTop: "10px" }}
                                          {...params}
                                          label="Document Type*"
                                          placeholder="Select Document Type*"
                                          color="secondary"
                                          error={documentTypeError}
                                          name="type"
                                        />
                                      )}
                                    />
                                    {documentTypeError && (
                                      <Typography
                                        sx={{
                                          fontSize: "10px",
                                          color: "#E73145",
                                        }}
                                      >
                                        Please Select the Document Type
                                      </Typography>
                                    )}

                                    <TextField
                                      sx={{ mb: 0, mt: 2, width: "100%" }}
                                      id="outlined-basic"
                                      label="Document Name*"
                                      placeholder="Enter Document Name*"
                                      variant="outlined"
                                      color="secondary"
                                      value={formik.values.documentName}
                                      error={documentNameError}
                                      name="documentName"
                                      onChange={(e) =>
                                        setDocumentName(e.target.value)
                                      }
                                    />
                                    {documentNameError && (
                                      <Typography
                                        sx={{
                                          fontSize: "10px",
                                          color: "#E73145",
                                        }}
                                      >
                                        Please Enter Document Name
                                      </Typography>
                                    )}

                                    <TextField
                                      sx={{ mb: 0, mt: 2, width: "100%" }}
                                      id="outlined-multiline-static"
                                      label="Write Description here"
                                      color="secondary"
                                      placeholder="Write Description here"
                                      multiline
                                      rows={4}
                                      value={formik.values.fieldDescription}
                                      onChange={(e) =>
                                        setFieldDescription(e.target.value)
                                      }
                                      name="documentDescription"
                                    />

                                    <Autocomplete
                                      style={{ width: "100%" }}
                                      value={formik.values.case}
                                      onChange={(event, value) =>
                                        setFieldCase(value)
                                      }
                                      options={Case}
                                      disableCloseOnSelect
                                      getOptionLabel={(option) => option}
                                      renderOption={(
                                        props,
                                        option,
                                        { selected }
                                      ) => <li {...props}>{option}</li>}
                                      sx={{ width: 500 }}
                                      renderInput={(params) => (
                                        <TextField
                                          sx={{ marginTop: "10px" }}
                                          {...params}
                                          label="Case"
                                          placeholder="Case"
                                          color="secondary"
                                          name="case"
                                        />
                                      )}
                                    />

                  

                                    <Div
                                      sx={{
                                        display: "flex",
                                        marginTop: "1rem",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox color="secondary" />

                                      <Typography>
                                        Admissible Evidence
                                      </Typography>
                                    </Div>
                                    <Div
                                      style={{
                                        display: "flex",
                                        alignItems: isBelow768px
                                          ? "normal"
                                          : "center",
                                        flexDirection: isBelow768px
                                          ? "column"
                                          : "row",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: "Nunito",
                                          fontWeight: 400,
                                          fontSize: "0.8571428571428571rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        Should this be visible to client?
                                      </Typography>
                                      <RadioGroup
                                        row
                                        aria-label="visibility"
                                        style={{
                                          marginLeft: isBelow768px
                                            ? "0px"
                                            : "12px",
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                        defaultValue="yes" // Set value prop to "yes"
                                      >
                                        <FormControlLabel
                                          value="yes"
                                          control={
                                            <Radio
                                              style={{ color: "#f1c40f" }}
                                            />
                                          }
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={
                                            <Radio
                                              style={{ color: "#f1c40f" }}
                                            />
                                          }
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </Div>
                                  </Div>

                                  <Div
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      alignItems: "center",
                                      margin: "1rem 0",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      disabled={
                                        !(
                                          documentType &&
                                          Object.keys(documentType).length >
                                            0 &&
                                          documentName &&
                                          documentName.length > 0 &&
                                          hiddenFileInput.current &&
                                          hiddenFileInput.current.files &&
                                          hiddenFileInput.current.files.length >
                                            0
                                        )
                                      }
                                      onClick={handleContinue}
                                      sx={{
                                        mt: 0,
                                        mr: 0,
                                        width: "100%",
                                        padding: 1,
                                        backgroundColor: "#f1c40f",
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </Div>
                                </Div>
                              </Div>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Modal>
              </Div>
            </JumboDemoCard>
          </Div>
        </Div>
      </Div>
    </NoCaseContainer>
  );
  // }
}

export default NoDocument;
