import React, { useState } from "react";
import { Screen2Container, UploadSection } from "./style";
import TextField from "@mui/material/TextField";
import { Typography ,InputAdornment} from "@mui/material";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";

import FormControlLabel from "@mui/material/FormControlLabel";
import Div from "@jumbo/shared/Div";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import {
  DefaultProfileImage,
  DefaultUserMobile,
  DefaultName,
  defaultProfileStatus,
} from "app/redux/actions/OnboardingInputs";
import { useFormik } from "formik";
import { loginValidations } from "app/utils/validators";
import { useNavigate } from "react-router-dom";
import { updateProfilePicture, UpdateUserProfile } from "app/Apis/User";
import { useEffect } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { DefaultUserEmail } from "app/redux/actions/OnboardingInputs";
import Cookies from "js-cookie";
import EditOffIcon from '@mui/icons-material/EditOff';

function Screen2({ activeStep, progressIncrement, progressDecrement }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileImage = useSelector(
    (state) => state?.onboardingInputs?.profileImage
  );
  const [checked, setChecked] = React.useState(true);
  useEffect(() => {
    {
      isMobileVerified && setIfMobile(true);
      isEmailVerified && setIfEmail(true);
    }
  }, [profileImage]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const name = useSelector((state) => state?.onboardingInputs?.fullName);
  const email = useSelector((state) => state?.onboardingInputs?.emailId);
  const mobile = useSelector((state) => state?.onboardingInputs?.mobileNumber);
  let accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );
  if(!accessToken){
    accessToken =  Cookies.get("accesstoken")
  }

  const isEmailVerified = useSelector(
    (state) => state?.onboardingInputs?.emailVerified
  );
  const isMobileVerified = useSelector(
    (state) => state?.onboardingInputs?.mobileVerified
  );

  let role = useSelector((state) => state?.onboardingInputs?.assignedRole);
  const [ifMobile, setIfMobile] = useState(false);
  const [ifEmail, setIfEmail] = useState(false);

  console.log(name, email, mobile, role);

  const formik = useFormik({
    initialValues: {
      fullname: name,
      email: email,
      mobile: mobile,
    },
    validationSchema: loginValidations,
    enableReinitialize: true,
    onSubmit: (values) => {
      IncrementFn();
    },
  });

  // const [profileImg, setProfileImg] = useState(profileImage);

  const profileImgChange = async (e) => {

    const response = await updateProfilePicture(accessToken, e.target.files[0]);

    dispatch(DefaultProfileImage(URL.createObjectURL(e.target.files[0])));
  };

  const IncrementFn = async () => {
    if(role==="ROLE_LAWYER"){
      role = "lawyer"
    }
    const response = await UpdateUserProfile(
      mobile,
      name,
      email,
      role,
      accessToken
    );
    console.log(response);
    if (response) {
      dispatch(DefaultName(response.data.data.fullname));
      dispatch(DefaultUserMobile(response.data.data.phone));
      dispatch(DefaultUserEmail(response.data.data.email));
      dispatch(defaultProfileStatus(response.data.data.profileStatus));

      if (role === "ROLE_CLIENT") {
        navigate("/clients");
        // navigate("/onboardingsuccess");

      } else if (role === "ROLE_LAWYER"|| role==="lawyer" ) {
        progressIncrement();
      }
    }
    console.log(response);

    // progressIncrement();
  };

  return (
    <Screen2Container>
      <Typography
        sx={{ fontSize: "20px", fontWeight: "600", textAlign: "left" }}
      >
        Your Personal Information
      </Typography>
      <Typography
        variant="body1"
        style={{
          marginBottom: "20px",
          fontWeight: "500",
          marginTop: "8px",
          textAlign: "left",
        }}
      >
        Upload your profile picture
      </Typography>
      <UploadSection style={{ width: "100%" }}>
        <Div
          sx={{
            display: "flex",
            minWidth: 0,
            justifyContent: "center",
            mb: 3,
            backgroundColor: "white",
            borderRadius: "50%",
            alignItems: "center",
            p: 1,
          }}
        >
          {profileImage ? (
            <img
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
              src={profileImage}
              alt=""
            />
          ) : (
            <PersonIcon sx={{ fontSize: "40px" }} />
          )}
        </Div>

        {/* </Div> */}
        <LoadingButton
          style={{
            width: "fit-content",
            backgroundColor: "black",
            marginTop: "-25px",
          }}
          variant="contained"
          component="label"
          disableElevation
        >
          Upload Image
          <input
            hidden
            accept="image/*"
            onChange={profileImgChange}
            multiple
            type="file"
          />
        </LoadingButton>
      </UploadSection>

      <TextField
        style={{ width: "100%" ,backgroundColor:"white"}}
        id="outlined-multiline-flexible"
        label="Enter Your Name"
        required
        color="secondary"
        inputProps={{ maxLength: 150 }}
        maxRows={4}
        error={formik.errors.fullname}
        name="fullname"
        value={formik.values.fullname}
        onChange={formik.handleChange}
        helperText={formik.errors.fullname}
        onBlur={dispatch(DefaultName(formik.values.fullname))}
      />

      <TextField
        style={{ width: "100%",backgroundColor:"white" }}
        id="outlined-multiline-flexible"
        label="Enter Email Address"
        inputProps={{ maxLength: 150 }}
        color="secondary"
        name="email"
        maxRows={4}
        error={formik.errors.email && formik.touched.email}
        value={formik.values.email}
        onChange={!ifEmail && formik.handleChange}
        helperText={formik.touched.email ? formik.errors.email:""}
        onBlur={dispatch(DefaultUserEmail(formik.values.email))}
        disabled={isEmailVerified}
        InputProps={{
          endAdornment: isEmailVerified && (
            <InputAdornment position="end">
              <EditOffIcon/>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        style={{ width: "100%",backgroundColor:"white" }}
        id="outlined-multiline-flexible"
        label="Enter Your Mobile Number"
        color="secondary"
        inputProps={{ maxLength: 10 }}
        name="mobile"
        maxRows={4}
        error={formik.errors.mobile}
        value={formik.values.mobile}
        onChange={!ifMobile && formik.handleChange}
        helperText={formik.errors.mobile}
        onBlur={dispatch(DefaultUserMobile(formik.values.mobile))}
        disabled={isMobileVerified}
        InputProps={{
          endAdornment: isMobileVerified && (
            <InputAdornment position="end">
              <EditOffIcon/>
            </InputAdornment>
          ),
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
        >
          <WhatsAppIcon fontSize="medium" />
          <Typography sx={{ textAlign: "left", fontSize: "14px" }}>
            Get important updates on WhatsApp ?
          </Typography>
        </div>
        <FormControlLabel
          control={<Switch  checked={checked}
          onChange={handleChange} color="secondary" />}
          label={checked?"Yes":"No"}
        />
      </div>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <LoadingButton
          // onClick={handleNext}
          disabled={activeStep !== 1 ? false : true}
          onClick={progressDecrement}
          style={{
            backgroundColor: activeStep !== 1 ? "black" : "",
          }}
          variant="contained"
          sx={{ width: 100, padding: 1 }}
        >
          Back
        </LoadingButton>

        <LoadingButton
          // onClick={handleNext}
          onClick={formik.handleSubmit}
          style={{
            backgroundColor: "black",
          }}
          variant="contained"
          sx={{ width: 100, padding: 1 }}
        >
          Continue
        </LoadingButton>
      </Div>
    </Screen2Container>
  );
}

export default Screen2;
