import React from 'react';
import {ListItem, ListItemIcon, ListItemText, Skeleton} from "@mui/material";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";

const SidebarSkeleton = () => {
    return (
        <List
            // disablePadding
            sx={{
                mr: 2,
                pb: 2,
            }}
        >
            {
                [...Array(1).keys()].map((index) => (
                    <React.Fragment key={index}>
                        {
                            [...Array(10).keys()].map((index) => (
                                <ListItem sx={{p:0.5}} key={index}>
                                    <ListItemIcon sx={{minWidth: 32}}>
                                        <Skeleton variant="circular" width={40} height={40}/>
                                    </ListItemIcon>
                                    {/* <ListItemText primary={<Skeleton width={150} height={20}/>}/> */}
                                </ListItem>
                            ))
                        }
                    </React.Fragment>
                ))
            }
        </List>
    );
};
export default SidebarSkeleton;
