import * as React from "react";

import TextField from "@mui/material/TextField";
import {
	Autocomplete,
	Checkbox,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormik } from "formik";
import { todoValidations } from "app/utils/validators";
import { LoadingButton } from "@mui/lab";
import { createTodoTask } from "app/Apis/task";
import { useSelector } from "react-redux";

const priorityOptions = ["Low", "Medium", "High"];

const EditTaskDetails = ({ setTasksList, setAddTaskOpen, setTaskAdded }) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const accessToken = useSelector((state) => {
		return state?.onboardingInputs?.accessToken;
	});
	const [fieldCase, setFieldCase] = React.useState();
	const [fieldTask, setFieldTask] = React.useState();
	const [fieldDescription, setFieldDescription] = React.useState();
	// const [fieldAssignedBy, setFieldAssignedBy] = React.useState();
	// const [fieldAssignedTo, setFieldAssignedTo] = React.useState();
	const [priority, setPriority] = React.useState();
	// const [documentName, setDocumentName] = React.useState();
	// const [documentUploaded, setDocumentUploaded] = React.useState([]);

	const formik = useFormik({
		initialValues: {
			case: fieldCase,
			taskName: fieldTask,
			taskDescription: fieldDescription,
			// assignedBy: fieldAssignedBy,
			// assignedTo: fieldAssignedTo,
			// documentName: documentName,
			// documentUploaded: documentUploaded,
			priority: priority,
		},
		validationSchema: todoValidations,
		enableReinitialize: true,
		onSubmit: async (values) => {
			console.log(values, "FORMIK");
			const updatedTaskToAPI = {
				// casId:null,
				isActive: 1,
				taskDescription: formik.values.taskDescription,
				taskName: formik.values.taskName,
				// documentLists:null,
				// assignedBy:null,
				priority: priorityOptions.findIndex(
					(option) => option === formik.values.priority
				),
				taskType: "todo",
				isVisibleToClient: 1,
				isAdmissibleEvidence: 1,
			};
			const response = await createTodoTask(accessToken, updatedTaskToAPI);
			if (response) {
				console.log(response);
				console.log("task added successFully");
				setTaskAdded(true);
				setAddTaskOpen(false);
			}
		},
	});
	const hiddenFileInput = React.useRef(null);

	const Case = ["Case 1", "Case 2"];
	const AssignedBy = ["Assigner 1", "Assigner 2"];
	const AssignedTo = ["Assigned 1", "Assigned 2"];

	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const handleChange = (event) => {
		// const fileUploaded = event.target.files[0];
		// console.log(fileUploaded);
		// setDocumentUploaded((state) => [
		//   ...state,
		//   URL.createObjectURL(event.target.files[0]),
		// ]);
	};

	const removeDocument = (deletedUrl) => {
		// const newDocumentUploaded = documentUploaded?.filter((document) => {
		//   return document !== deletedUrl;
		// });
		// setDocumentUploaded(newDocumentUploaded);
	};

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				paddingBottom: "30px",
			}}
		>
			{/* <CaseContainer> */}
			<Div
				sx={{
					marginLeft: { xs: "5%", md: "18%" },
					marginRight: { xs: "5%", md: "18%" },

					height: "100%",
				}}
			>
				<Typography variant="h6" sx={{ mt: "0px" }}>
					Edit Task
				</Typography>

				{/* task name */}

				<TextField
					sx={{ mb: 0, mt: 2 }}
					style={{ width: "100%" }}
					id="outlined-multiline-flexible"
					label="Task Name*"
					// multiline
					color="secondary"
					inputProps={{ maxLength: 15 }}
					// name="barcouncilnumber"
					maxRows={4}
					value={formik.values.taskName}
					// onChange={formik.handleChange}
					onChange={(e) => setFieldTask(e.target.value)}
					error={formik.errors.taskName}
					name="taskName"
					helperText={formik.errors.taskName}
				/>

				{/* description */}

				<TextField
					sx={{ mb: 0, mt: 2, width: "100%" }}
					id="outlined-multiline-static"
					label="Task Description*"
					color="secondary"
					placeholder="Task Description*"
					multiline
					rows={4}
					//  onChange={formik.handleChange}
					onChange={(e) => setFieldDescription(e.target.value)}
					error={formik.errors.taskDescription}
					name="taskDescription"
					helperText={formik.errors.taskDescription}
					// value={fieldDescription}
					// onChange={(e) => setFieldDescription(e.target.value)}
				/>

				{/* Task for case */}
				<Autocomplete
					style={{ width: "100%" }}
					// value={fieldAssignedBy}
					value={formik.values.case}
					onChange={(event, value) => setFieldCase(value)}
					multiple
					// id="checkboxes-tags-demo"
					options={Case}
					disableCloseOnSelect
					getOptionLabel={(option) => option}
					renderOption={(props, option, { selected }) => (
						<li {...props}>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
								checkedIcon={<CheckBoxIcon fontSize="small" />}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option}
						</li>
					)}
					sx={{ width: 500 }}
					renderInput={(params) => (
						<TextField
							sx={{ mb: 0, mt: 2 }}
							{...params}
							label="Case*"
							placeholder="Case*"
							color="secondary"
							error={formik.errors.case}
							name="case"
							helperText={formik.errors.case}
						/>
					)}
				/>

				{/* assigned by */}

				<Autocomplete
					style={{ width: "100%" }}
					// value={fieldAssignedBy}
					value={formik.values.assignedBy}
					// onChange={(event, value) => setFieldAssignedBy(value)}
					multiple
					// id="checkboxes-tags-demo"
					disabled
					options={AssignedBy}
					disableCloseOnSelect
					getOptionLabel={(option) => option}
					renderOption={(props, option, { selected }) => (
						<li {...props}>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
								checkedIcon={<CheckBoxIcon fontSize="small" />}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option}
						</li>
					)}
					sx={{ width: 500 }}
					renderInput={(params) => (
						<TextField
							sx={{ mb: 0, mt: 2 }}
							{...params}
							label="Assigned By*"
							placeholder="Assigned By*"
							color="secondary"
							error={formik.errors.assignedBy}
							name="assignedBy"
							helperText={formik.errors.assignedBy}
						/>
					)}
				/>

				{/* assigned to */}

				{/* <Autocomplete
        disabled
          style={{ width: "100%" }}
          value={formik.values.assignedTo}
          onChange={(event, value) => setFieldAssignedTo(value)}
          multiple
          options={AssignedTo}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ mb: 0, mt: 2 }}
              {...params}
              label="Assigned To*"
              placeholder="Assigned To*"
              color="secondary"
              error={formik.errors.assignedTo}
              name="assignedTo"
              helperText={formik.errors.assignedTo}
            />
          )}
        /> */}

				{/* priority */}
				<Div sx={{ display: "flex", alignItems: "center", mb: 0, mt: 2 }}>
					<Typography variant="h6" sx={{ mr: 3 }}>
						Priority*
					</Typography>
					<FormControl>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="row-radio-buttons-group"
							onChange={(e, value) => setPriority(value)}
						>
							<FormControlLabel
								value="High"
								control={<Radio color="secondary" />}
								label="High"
							/>
							<FormControlLabel
								value="Medium"
								control={<Radio color="secondary" />}
								label="Medium"
							/>
							<FormControlLabel
								value="Low"
								control={<Radio color="secondary" />}
								label="Low"
							/>
						</RadioGroup>
					</FormControl>
				</Div>
				{formik.errors.priority && (
					<Typography sx={{ fontSize: "10px", color: "#E73145", ml: 1.8 }}>
						Please Select atleast one value!
					</Typography>
				)}
				{/* attached documents */}

				{/* <Typography variant="h6" sx={{ mb: 0, mt: 2 }}>
          Attach Documents*
        </Typography> */}
				{/* document name */}

				{/* <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Document Name*"
          variant="outlined"
          color="secondary"
          value={formik.values.documentName}
          // onChange={(e) => setDocumentName(e.target.value)}
          error={formik.errors.documentName}
          name="documentName"
          helperText={formik.errors.documentName}
        /> */}

				{/* upload */}

				{/* <Box
          sx={{
            width: "100%",
            height: "50.71px",
            mb: 0,
            mt: 2,
            border: "1px solid #cdced1",
            borderRadius: "3px",

            // padding: "17px 14px 17px 14px",
            // "&:hover": {
            //   backgroundColor: "primary.main",
            //   opacity: [0.9, 0.8, 0.7],
            // },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Div sx={{ display: "flex", alignItems: "center" }}>
            <UploadFileIcon sx={{ fontSize: "40px" }} onClick={handleClick} />
            <Typography sx={{ fontSize: "14px", color: "#929faf" }}>
              Upload Document
            </Typography>
          </Div>
        </Box> */}
				{/* 
        <input
          style={{ mb: 0, mt: 2, width: "30vw" }}
          accept="image/*"
          multiple
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          hidden
        /> */}
				{/* <Typography sx={{ color: "#929faf", fontSize: "12px", mt: "2px" }}>
          File Should be jpg, jpeg, png, Pdf
        </Typography> */}
				{/* uploaded image */}
				{/* <Div
          sx={{
            display: "flex",
            alignItems: "center",
            overflowX: "scroll",
            width: "100%",
          }}
        >
          {/* {documentUploaded &&
            documentUploaded.map((e) => (
              <Div
                sx={{
                  position: "relative",
                  // backgroundColor: "green",
                  mr: 2,
                }}
              >
                <img
                  style={{
                    width: { md: "5vw" },
                    height: "10vh",
                    borderRadius: "50%",
                    objectFit: "contain",
                    marginRight: "10px",
                  }}
                  src={e}
                  alt=""
                />
                <CloseIcon
                  onClick={() => removeDocument(e)}
                  sx={{ position: "absolute", right: 2 }}
                />
              </Div>
            ))}
        </Div>*/}
			</Div>
			<Div
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					textAlign: "center",
					alignItems: "center",
					paddingLeft: { xs: "0%", md: "18%" },
					paddingRight: { xs: "0%", md: "18%" },
					mt: 5,
				}}
			>
				<LoadingButton
					type="submit"
					onClick={formik.handleSubmit}
					style={{
						backgroundColor: "black",
					}}
					sx={{ width: 100, padding: 1 }}
					variant="contained"
				>
					Edit
				</LoadingButton>
			</Div>
		</div>
	);
};

export default EditTaskDetails;
