// import React from 'react';
// import { TableCell,Chip,TableRow } from '@mui/material';
// import styled from "@emotion/styled";
// import { formatDate } from 'app/utils/dateTimeHelpers';

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     "& .MuiTableCell-root": {
//       borderBottom: "1px solid black",
//       paddingTop: theme.spacing(1.5),
//       paddingBottom: theme.spacing(1.5),
//       // color:"white",
//       backgroundColor:"#f2f2f3"
//     },
//   }));
//   const enquiryType = (type) => {
//     if (type === "Regular_Bail_Application") {
//       return "Regular Bail Application";
//     } else if (type === "Expert_Case_Review") {
//       return "Expert Case Review";
//     } else if (type === "Anticipatory_Bail_Application") {
//       return "Anticipatory Bail Application";
//     }
//   };
//   const courtType = (type) => {
//     if (type === "District_Court") {
//       return "District Court";
//     } else if (type === "Special_Court") {
//       return "Special Court";
//     } else if (type === "High_Court") {
//       return "High Court";
//     } else if (type === "Supreme_Court") {
//       return "Supreme Court";
//     }
//   };
//   const caseType = (type) => {
//     if (type === "Criminal_Case") {
//       return "Criminal Case";
//     } else if (type === "Civil_Case") {
//       return "Civil Case";
//     }
//   };

// function EnquiryItem({item,index}) {
//     let popUpWindow =null;
//     const handlePayment = async () => {
//         console.log("clicked");
//         if (item?.payment?.order_status === "Success") {
//         //   setExpanded(true);
//         //   setAddDocument(true);
//         } else {
//           const posX = (window.screen.width)/3;
//           const posY = (window.screen.height)/2;
//           const width = 500;
//           const height = 680;
//           const popUpPosition = `height=${height},width=${width},left=${posX},top=${posY}`;
//           popUpWindow = window.open(
//             `https://erp.lawinzo.com/payment/initiate?order_id=${item?.payment?.order_id}&retry=1&platform=cloud`,
//             "popup",
//             popUpPosition
//           );
//           const checkClosed = setInterval(() => {
//             if (popUpWindow.closed) {
//               clearInterval(checkClosed);
//               handlePopupClose();
//             }
//           }, 1000);
//         }
//       };
//       const handlePopupClose = (event)=>{
//         console.log('Popup window is closed');
//         // setShowUpdate(!showUpdate);
//     }
//   return (
//     <>
//     <StyledTableRow>
//       {/* <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "left" }}>
//         {index >= 9 ? `#${index + 1}` : `#0${index + 1}`}
//       </TableCell> */}
//       <TableCell sx={{ pl: (theme) => theme.spacing(2), textAlign: "center" }}>
//       {enquiryType(item?.enquiryTypes) || "Enquiry Type"}
//       </TableCell>
//       <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
//       {item?.lawyerId ?item?.lawyerId: "Lawyer Assigned"}
//       </TableCell>
//       <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
//        {/* {item?.createdDate ?formatDate(item?.createdDate): "date" }
//         */}
//         {item?.firComplaintNo ?item?.firComplaintNo:"FIR No."}
//       </TableCell>
//       <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
//       {item?.courtName
//                 ? courtType(item?.courtName)
//                 : "No Related Court "}
//       </TableCell>

//       <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
//       {item?.caseType ? caseType(item?.caseType) : "No Case Type added"}
//       </TableCell>
//       <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "center" }}>
//         {item?.createdDate ?formatDate(item?.createdDate): "date" }

//       </TableCell>
//       <TableCell align="right" sx={{ pr: (theme) => theme.spacing(3) }}>
//           <Chip
//           label={item?.payment?.order_status === "Success"?"Documents":"Pay Now"}
//           onClick={item?.payment?.order_status === "Success"?null:handlePayment}
//           variant="primary"
//           sx={{
//             fontSize:"12px",
//             fontWeight:700,
//             color:"#151513",
//             backgroundColor:"#EABF4E",
//             minWidth:"73.62px"
//           }}
//           />
//       </TableCell>
//     </StyledTableRow>

//     </>
//   )
// }

// export default EnquiryItem;
// {/* <Modal
//     open={openModal}
//     onClose={() => setOpenModal(false)}
//     aria-labelledby="delete-modal-title"
//     aria-describedby="delete-modal-description"
//   >
//     <Box
//       sx={{
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         bgcolor: "background.paper",
//         boxShadow: 24,
//         outline: "none",
//         height:500,
//         width:500,
//         borderRadius: "8px",
//         textAlign: "center",
//       }}
//     >
//      {/* {item?.paymentRequest?.order_id} */}
//       {/* <iframe
//       height={"100%"}
//       width={"100%"}
//       style={{border:"none"}}
//       src={`https://erp.lawinzo.com/payment/initiate?order_id=${item?.paymentRequest?.order_id}&retry=1&platform=W`}
//       // src={`https://secure.ccavenue.com/transaction.do?command=initiateTransaction&merchant_id=${item?.merchant_id}&encRequest=${item?.encRequest}&access_code=AVRY04KF99BT78YRTB`}
//       />
//       <div ref={divRef}> </div>
//     </Box>
//   </Modal> */}

import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import Collapse from "@mui/material/Collapse";
import { alpha, Button, Paper, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import DisplayDocumentPreview from "app/Components/Documents/DisplayDocuments/DisplayDocumentPreview";
import AddDocuments from "app/Components/Documents/AddDocuments/AddDocuments";
import { createEnquiry } from "app/Apis/Enquiry";
import { useSelector } from "react-redux";
import { paymentRequest } from "app/Apis/Payment";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const EnquiryItem = ({ item, showUpdate, setShowUpdate,setEnquiryDetails,setDetailItem }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [addDocument, setAddDocument] = React.useState(false);
  const [documentsArray, setDocumentsArray] = React.useState(null);
  const [documentsAPIList, setDocumentsAPIList] = React.useState(null);
  const userId = useSelector((state) => state?.onboardingInputs?.userId);
  let popUpWindow = null;

  const handleClick = async () => {
    if (item?.payment?.order_status === "Success") {
    //   setExpanded(true);
      setAddDocument(true);
    } else {
      const posX = window.screen.width / 3;
      const posY = window.screen.height / 2;
      const width = 500;
      const height = 680;
      const popUpPosition = `height=${height},width=${width},left=${posX},top=${posY}`;
      popUpWindow = window.open(
        `https://erp.lawinzo.com/payment/initiate?order_id=${item?.payment?.order_id}&retry=1&platform=cloud`,
        "popup",
        popUpPosition
      );
      const checkClosed = setInterval(() => {
        if (popUpWindow.closed) {
          clearInterval(checkClosed);
          handlePopupClose();
        }
      }, 1000);
    }
  };

  const handleAddDocument = async () => {
    try {
      const response = await createEnquiry({
        ...item,
        documentLists: documentsAPIList,
      });
      console.log(response);
      if (response) {
        setAddDocument(false);
        setShowUpdate(!showUpdate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleExpandClick = () => {
    setEnquiryDetails(true);
    setDetailItem(item);
    // setExpanded(!expanded);
  };
  const enquiryType = (type) => {
    if (type === "Regular_Bail_Application") {
      return "Regular Bail Application";
    } else if (type === "Expert_Case_Review") {
      return "Expert Case Review";
    } else if (type === "Anticipatory_Bail_Application") {
      return "Anticipatory Bail Application";
    }
  };

  const caseType = (type) => {
    if (type === "Criminal_Case") {
      return "Criminal Case";
    } else if (type === "Civil_Case") {
      return "Civil Case";
    }
  };
  const courtType = (type) => {
    if (type === "District_Court") {
      return "District Court";
    } else if (type === "Special_Court") {
      return "Special Court";
    } else if (type === "High_Court") {
      return "High Court";
    } else if (type === "Supreme_Court") {
      return "Supreme Court";
    }
  };

  const handlePopupClose = (event) => {
    console.log("Popup window is closed");
    setShowUpdate(!showUpdate);
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-4px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",

            "&.Mui-expanded": {
              margin: "12px 0",
            },
            "&:hover": {
              cursor: "default",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
            "&:hover": {
              cursor: "default",
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>
        <Div
          sx={{
            width: { xs: "auto", lg: "20%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
            display:"flex",
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary" sx={{flex:1}}>
            {item?.enquiryTypes
              ? enquiryType(item?.enquiryTypes)
              : "Enquiry type"}
              {item?.documentLists && (
            <PictureAsPdfIcon  sx={{ml:1}}/>
          )}
          </Typography>
          
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "25%",
            flexShrink: 0,
            px: 2,
          }}
        >
          <Chip
            label={`FIR No. ${item?.firComplaintNo}`}
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Court
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>
              {item?.courtName
                ? courtType(item?.courtName)
                : "No Related Court "}
            </Typography>
          </Div>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Case Type
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>
              {item?.caseType ? caseType(item?.caseType) : "No Case Type added"}
            </Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            alignItems: "center",
            width: "15%",
            gap: 2,
          }}
        >
          <Chip
          label={ item?.payment?.order_status
            ? item?.payment?.order_status
            : "Not Paid"}
          sx={{
            color:item?.payment?.order_status !=="Success"? "#F57202":"#52A652",
            backgroundColor: item?.payment?.order_status !=="Success"? "rgb(245,114,2,16%)":"rgb(82, 166, 82,16%)",
            fontWeight: "bold",
          }}
        />
        </Div>
      </AccordionSummary>

      {/* down arrow content */}
      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Div>
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            Documents
          </Typography>
          <Typography variant={"body1"} color={"text.secondary"}>
            {item?.documentLists ? (
              <DisplayDocumentPreview documentLists={item?.documentLists} />
            ) : (
              "Client has not added any documents"
            )}
          </Typography>
        </Div>
        <Div>
          {item?.payment?.order_status === "Success" && (
            <Button
              type="button"
              color="secondary"
              style={{
                backgroundColor: "black",
              }}
              sx={{ padding: 1 }}
              variant="contained"
              onClick={() => setAddDocument(true)}
            >
              Add Documents
            </Button>
          )}
        </Div>
        <Div>
          {addDocument && (
            <Div
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <AddDocuments
                documentsArray={documentsArray}
                setDocumentsArray={setDocumentsArray}
                documentsAPIList={documentsAPIList}
                setDocumentsAPIList={setDocumentsAPIList}
                type={"enquiry"}
              />
              <Div sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="button"
                  onClick={() => setAddDocument(false)}
                  color="secondary"
                  style={{
                    backgroundColor: "black",
                  }}
                  sx={{ padding: 1 }}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  type="Submit"
                  onClick={handleAddDocument}
                  color="secondary"
                  style={{
                    backgroundColor: "black",
                  }}
                  sx={{ padding: 1 }}
                  variant="contained"
                >
                  Save Documents
                </Button>
              </Div>
            </Div>
          )}
        </Div>
      </Collapse>
    </Paper>
  );
};

export default EnquiryItem;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));
