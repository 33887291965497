import React from "react";
import ClientCard from "./ClientCard";

const ClientList = ({peopleList}) => {
  console.log("peop",peopleList)
  return (
    <React.Fragment>
      
      {peopleList && peopleList.map((team, index) => (
        <ClientCard Key={team.peopleId} item={team} 
        peopleList = {peopleList}
        />
      ))}
    </React.Fragment>
  );
};

export default ClientList;
