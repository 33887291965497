import React, { useEffect, useState } from "react";
import ChatBotMobile from "./ChatbotMobile";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useLogin from "app/hooks/useLogin";
import Cookies from "js-cookie";

function ChatbotMobileView() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	const login = useLogin(true);

	useEffect(() => {
		if (!queryParams.get("jwt")) {
			navigate("/");
		}
		if (queryParams.get("jwt")) {
			fetchUser(queryParams.get("jwt"));
		}
		async function fetchUser(token) {
			try {
				let config = {
					method: "get",
					maxBodyLength: Infinity,
					url: "https://erp.lawinzo.com/user/me", //prev https://erp.lawinzo.com
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				const response = await axios.request(config);
				console.log(response.data);
				setUser(response.data.data);
				login(token);
				Cookies.set("accessToken", token);
			} catch (error) {
				navigate("/");
			}
		}
	}, []);
	useEffect(() => {}, []);
	return (
		<div style={{ backgroundColor: "transparent" }}>
			{user && <ChatBotMobile userData={user} />}
		</div>
	);
}

export default ChatbotMobileView;
