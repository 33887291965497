import React from "react";
import { Card, Typography, CardHeader, Link, CardContent,Chip } from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ActionsMenu from "./ActionsMenu";
const priority = ["Low", "Medium", "High"];

const TaskDashCard = ({ item, inCase,setTaskEditItem,setAddTaskOpen,handleDelete, formDisable }) => {
  const handleEdit = ()=>{
    setTaskEditItem(item);
    setAddTaskOpen(true);
  }
  console.log(formDisable)
  return (
    <Card sx={{ boxShadow: "none" }}>
      <CardHeader
        sx={{ p: 2, backgroundColor: "#F9F8F9", mb: 1 }}
        action={
          <Div sx={{ display: "flex" }}>
            
            <Chip
              label={`Open Task`}
              color="primary"
              size="medium"
              variant="outlined"
              sx={{ fontSize: 14, fontWeight: "700" }}
            />
            {!formDisable &&
            inCase  && (
              <ActionsMenu
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            )}
          </Div>
        }
        title={
          <Typography sx={{ fontSize: "19.2px", fontWeight: 500 }}>
            {item?.taskName ? `${item?.taskName}` : "Task Name"}
          </Typography>
        }
      />
      <CardContent sx={{ p: 2, pt: 0 }}>
        <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          {item?.caseId ? (
            <Link
              underline="none"
              href={`/lawyers/casedetail?caseId=${
                item?.caseId && item?.caseId
              }`}
            >
              <Typography sx={{ fontSize: "19.2px" }}>
                {item?.caseName ? item?.caseName : "No Case Attatched"}
              </Typography>
            </Link>
          ) : (
            <Typography sx={{ fontSize: "19.2px" }}>
              {item?.caseName ? item?.caseName : "No Case Attatched"}
            </Typography>
          )}
          <Chip
            label={` ${priority[item?.priority]}`} // change according to api
            color="secondary"
            size="medium"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        </Div>
        <Div
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              maxHeight: "10%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item?.taskDescription ? item?.taskDescription : "Description"}
          </Typography>
        </Div>
      </CardContent>
    </Card>
  );
};
export default TaskDashCard;
