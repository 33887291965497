import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createDrafting } from "app/Apis/drafting";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import Cookies from "js-cookie";
import { getCases } from "app/Apis/case";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { AudioRecorderWidget } from "../legalDrafting/components/audioRecorderWidget";
import AddDocuments from "app/Components/Documents/AddDocuments/AddDocuments";

const hearingValidation = Yup.object().shape({
  draftingName: Yup.string().required("Drafting Name is Required"),
  language: Yup.string().required("Language is Required").nullable()
});
const languages = [
  "Hindi",
  "English",
];


const AddLegalDrafting = ({setAddApp,setUpdate, caseId,setSnackBarText,
  setOpenSnackBar}) => {
  const accessToken = Cookies.get("accessToken");
  const userId = useSelector((state) => state?.onboardingInputs?.userId);


  const [bigChunk, setBigChunk] = useState(null);
  const [documentsArray, setDocumentsArray] = useState([]);
  const [documentsAPIList, setDocumentsAPIList] = useState(null);
  const [selectedCase, setSelectedCase] = useState(null);
  const [caseOptions, setCaseOptions] = useState([]);
  const [draftingName, setDraftingName] = useState("");
  const [languageSelection, setLanguageSelection] = useState(null);
  const [documentListFromApi, setDocumentListFromApi] = useState([]);
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [languageTranslation, setLanguageTranslation] = useState("No");
  const [documentType, setDocumentType] = useState("Audio");

  useEffect(() => {
    const getCasesTemp = async () => {
      const response = await getCases(accessToken);
      console.log(response);
      if (response) {
        setCaseOptions(response);
      }
    };
    getCasesTemp();
  }, []);

  const formik = useFormik({
    initialValues: {
      caseId: caseId,
      selectedCase: selectedCase,
      draftingName: draftingName,
      language:languageSelection
    },
    validationSchema: hearingValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let data = {
        caseId:caseId,
        caseDetails: {
          caseId: caseId,
        },
        draftName: values.draftingName,
        langId: values.language,
        documentLists: documentListFromApi,
        languageTranslation: languageTranslation,
        isUpdate: 0,
        newDocs: [],
        draftingType: "drafting",
        userId: userId,
        isActive: 1,
        isCron: 0,
        googleDocCreated: 0,
        audioMinutes: time.minutes,
        audioSeconds: time.seconds,
      };
      if (documentType ==="Audio"){
        data = {
          ...data,
          documentType:documentType,
          documentLists: documentListFromApi,
          languageTranslation: languageTranslation,
        }
      }
      if (documentType === "Document"){
        data = {
          ...data,
          documentType:documentType,
          documentLists: documentsAPIList,
          languageTranslation: "No",
        }
      }
      const response = await createDrafting(accessToken, data)
      if(response){
        console.log(response)
        setAddApp(false)
        setUpdate((prev)=> !prev)
        setSnackBarText("Draft added. Please wait while we process it.")
        setOpenSnackBar(true)
      }
    }
  });
  return (
    <Div sx={{ height: "100%", width: "100%" }}>      
        <Div
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Div sx={{ width: "110%" }}>
            <div
              style={{
                height: "100%",
                width: "100%",
                paddingBottom: "30px",
              }}
            >
               <Div
                sx={{
                  marginLeft: { xs: "5%", md: "18%" },
                  marginRight: { xs: "5%", md: "18%" },
                  marginTop: "20px",
                  height: "100%",
                }}
              >
                <TextField
                  sx={{ mb: 0, mt: 1, width: "100%" }}
                  id="outlined-basic"
                  label="Drafting Name"
                  variant="outlined"
                  value={formik.values.draftingName}
                  name="draftingName"
                  color="secondary"
                  onChange={(e) => setDraftingName(e.target.value)}
                  required
                  error={formik.errors.draftingName}
                  helperText={formik.errors.draftingName}
                />
                <Autocomplete
                  style={{ width: "100%" }}
                  value={languageSelection}
                  onChange={(event, value) => {
                    console.log(value);
                    setLanguageSelection(value);
                  }}
                  options={languages}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option}</li>
                  )}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        mt: 2,
                        backgroundColor: "white",
                      }}
                      {...params}
                      label="Language"
                      placeholder="Language"
                      color="secondary"
                      required
                      error={formik.errors.language}
                      helperText={formik.errors.language}
                    />
                  )}
                />
                <Div
                  sx={{
                    display: "flex",
                    alignItems: { sm: "center", xs: "flex-start" },
                    mb: 0,
                    mt: 2,
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <FormControl
                    sx={{
                      display: { sm: "flex" },
                      flexDirection: { sm: "column" },
                      alignItems: { sm: "center" },
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="priority"
                      defaultValue={"Audio"}
                      onChange={(e, value) => setDocumentType(value)}
                      error={
                        formik.errors.documentType &&
                        formik.touched.documentType
                      }
                      helperText={formik.errors.documentType}
                    >
                      <FormControlLabel
                        value="Audio"
                        control={<Radio color="secondary" />}
                        label="Audio"
                      />
                      <FormControlLabel
                        value="Document"
                        control={<Radio color="secondary" />}
                        label="Upload Document"
                      />
                    </RadioGroup>
                  </FormControl>
                </Div>
                {documentType === "Audio" && languageSelection && (
                  <Div
                    sx={{
                      display: "flex",
                      alignItems: { sm: "center", xs: "flex-start" },
                      mb: 0,
                      mt: 2,
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Typography variant="h6" sx={{ mr: 3 }}>
                      Do you want to translate:*
                    </Typography>
                    <FormControl
                      sx={{
                        display: { sm: "flex" },
                        flexDirection: { sm: "column" },
                        alignItems: { sm: "center" },
                      }}
                    >
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="priority"
                        defaultValue={"No"}
                        onChange={(e, value) => setLanguageTranslation(value)}
                        error={
                          formik.errors.priority && formik.touched.priority
                        }
                        helperText={formik.errors.priority}
                      >
                        <FormControlLabel
                          value="No"
                          control={<Radio color="secondary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="Yes"
                          control={<Radio color="secondary" />}
                          label="Yes"
                        />
                      </RadioGroup>
                    </FormControl>
                    {languageTranslation === "Yes" &&
                  <Div>
                    { languageSelection === "English" ?
                    <Typography sx={{ mr: 3 }}>
                      (Translating to Hindi)
                    </Typography> : 
                    <Typography sx={{ mr: 3 }}>
                   (Translating to English)
                  </Typography>
                    
                    }

                  </Div>            
                  }
                  </Div>
                )}
                {documentType === "Audio" ? (
                  <>
                    {!languageSelection && (
                      <Typography
                        sx={{
                          fontSize: "1.15rem",
                          lineHeight: 1.5,
                          fontWeight: 400,
                          mt: 2,
                        }}
                      >
                        Select language to start recording.
                      </Typography>
                    )}
                    <AudioRecorderWidget
                      setBigChunk={setBigChunk}
                      bigChunk={bigChunk}
                      setDocumentListFromApi={setDocumentListFromApi}
                      documentListFromApi={documentListFromApi}
                      time={time}
                      setTime={setTime}
                      languageSelection={languageSelection}
                    />
                  </>
                ) : (
                  <AddDocuments
                    documentsArray={documentsArray}
                    setDocumentsArray={setDocumentsArray}
                    documentsAPIList={documentsAPIList}
                    setDocumentsAPIList={setDocumentsAPIList}
                    type={"tasks"}
                  />
                )}
              </Div>
              <Div
                    sx={{
                      marginLeft: { xs: "5%", md: "18%" },
                      marginRight: { xs: "5%", md: "18%" },
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 5,
                    }}
                  >
                    <LoadingButton
                      onClick={() => {
                        setAddApp(false);
                      }}
                      // disabled={true}
                      variant="contained"
                      sx={{ width: 100, padding: 1 }}
                    >
                      Back
                    </LoadingButton>
                    {documentListFromApi.length > 0 ? (
                  <LoadingButton
                    onClick={() => {
                      console.log(formik.values);
                      console.log(formik.errors);
                      formik.handleSubmit();
                    }}
                    color="secondary"
                    // style={{
                    //   backgroundColor: "black",
                    // }}
                    sx={{ width: 100, padding: 1 }}
                    variant="contained"
                  >
                    Submit
                  </LoadingButton>
                ) : (
                  <Tooltip title="Record audio in order to save draft.">
                    <span>
                      <Button
                        color="primary"
                        // style={{
                        //   backgroundColor: "black",
                        // }}
                        disabled
                        type="button"
                        sx={{ width: 100, padding: 1 }}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </span>
                  </Tooltip>
                )}
                  </Div>
            </div>
          </Div>
        </Div>
      
    </Div>
  );
};

export default AddLegalDrafting;
