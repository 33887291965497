import React from 'react';
import {TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import {Typography, Button} from "@mui/material";
import dayjs from 'dayjs';
import { formatDate } from 'app/utils/dateTimeHelpers';

const ScheduleItem = ({item,setHearingEdit , setIsEditing,setAddHearing,index}) => {
    return (
        <TimelineItem

            sx={{ 
                '&::before': {
                    display: 'none'
                
            }}}
        >
            <TimelineSeparator>
                <TimelineDot 
                sx={{
                    m: theme => theme.spacing(.5, 0),
                    bgcolor: "#eabf4e",
                    width: 20,
                    height: 20,
                    boxShadow: '0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)',
                }}
                >
                   
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent
                sx={
                    
                index === 0 ? {
                px: 2,
                py:1,
                ml: 2.5,
                mb:2,
                'border':"2px solid #eabf4e",
                'borderRadius':"5px",
            }
            :
            {
                p: theme => theme.spacing(.5, 0),
                ml: 2.5,
            }
                    
                    }
            >
                {
                <Button
                onClick={()=>{
                    setHearingEdit(item);
                    setIsEditing(true);
                    setAddHearing(true);
                }}
                sx={{px:0}}
                color="secondary"
              >
                <Typography variant={"body1"}
                                mb={2}> {item?.hearingPurpose?.purposeHearing ? item?.hearingPurpose?.purposeHearing : item?.hearingNotes}</Typography>
               
              </Button>
                }
                
                    <Typography variant={"body1"} color={"text.secondary"} fontSize={12}
                                mb={2}>{formatDate(item?.hearingDate)}</Typography>
                                {index === 0 &&
                                <Typography variant={"body1"} color={"text.secondary"} fontSize={12}
                                mb={2}>Ongoing Hearing</Typography>
                                }
                
            </TimelineContent>
        </TimelineItem>
    )
};

/* Todo item prop define */
export default ScheduleItem;
