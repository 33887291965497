import Div from "@jumbo/shared/Div";
import { Avatar, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { OnboardingSuccessContainer } from "./style";
import PersonIcon from "@mui/icons-material/Person";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";

function OnboardingSuccess() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [celebrate, setCelebrate] = useState(true);

  const fullname = useSelector((state) => state?.onboardingInputs?.fullName);

  const profileImage = useSelector(
    (state) => state?.onboardingInputs?.profileImage
  );

  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );

  const role = useSelector((state) => state?.onboardingInputs?.assignedRole);

  useEffect(() => {
    setTimeout(() => {
      setCelebrate(false);
    }, 8000);
  }, []);
  return (
    <Div
      sx={{
        position: "relative",
        zIndex: "400",
        height: "100%",
      }}
    >
      {celebrate && (
        <Confetti
          width={windowSize.current[0]}
          height={windowSize.current[1]-80}
          tweenDuration="5000"
          gravity={0.1}
          numberOfPieces={400}
        />
      )}
      <OnboardingSuccessContainer>
        <Div
          sx={{
            display: "flex",
            minWidth: 0,
            justifyContent: "center",
            mb: 3,
            backgroundColor: "secondary.main",
            borderRadius: "50%",
            p: 1,
          }}
        >
          <Avatar
            sx={{ height: "60px", width: "60px" }}
            src={
              profileImage ? (
                profileImage
              ) : (
                <PersonIcon sx={{ fontSize: "60px" }} />
              )
            }
            alt=""
          />
        </Div>
        <Typography
          color="primary.main"
          sx={{
            mb: "40px",
            fontSize: { xs: "26px", md: "32px" },
            fontWeight: "600",
          }}
        >
          Congratulations!
        </Typography>

        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          color="primary.main"
        >
          Hi, {fullname}
        </Typography>
        <Typography
          sx={{ fontWeight: 500, fontSize: "16px" }}
          color="primary.main"
        >
          Thank you for Signing Up.
        </Typography>

        <Link
          to={
            role === "client" || role === "ROLE_CLIENT"
              ? "/clients/dashboards"
              : role === "lawyer" || role === "ROLE_LAWYER"
              ? "/lawyers/dashboards"
              : ""
          }
          style={{ textDecoration: "none" }}
        >
          <LoadingButton
            disableElevation
            variant="contained"
            size={"small"}
            sx={{
              ":hover": {
                color: "white"
              },
              textDecoration: "none",
              marginTop: "40px",
              backgroundColor: "secondary.main",
              padding: "10px",
              color: "black",
            }}
          >
            Go to Dashboard
          </LoadingButton>
        </Link>
      </OnboardingSuccessContainer>
    </Div>
  );
}

export default OnboardingSuccess;
