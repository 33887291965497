import React,{useEffect,useState} from "react";
import Div from "@jumbo/shared/Div/Div";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import TabContext from "@mui/lab/TabContext";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Button, Typography, Breadcrumbs, Link, Grid, FormControl } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Home } from "@material-ui/icons";
import {Card,Select,MenuItem,Divider} from "@mui/material";
import { getPaymentLists } from 'app/Apis/Payment';
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import CardFooter from "app/pages/components/CardFooter";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PaymentsList from "../Payments/Components/ClientPayments/WeeklySales/PaymentsList";

const optionsPageSize = ["5", "10", "20", "25", "50"];
const paymentTypeOption = ["Daily Cases Fee","Bail Filing Fee","Appointment Fee","Enquiry Fee","Other Court Fee","Certified Copy Fee"];
const paymentStatusOption = ["Initiated","Requested","Completed","Refunded","Hold","Cancelled"];
const optionsFilter = ["Payment Status","Payment Type","Day Range"];
const dayRangeOption = ["Select","Today","Yesterday","Tomorrow","This Week","This Month","Choose Date"];

const PaymentHistory = () => {
  const accessToken = Cookies.get("accessToken");
  const [showUpdate,setShowUpdate]=useState(false)
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentsList,setPaymentsList] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState("5");
  const [basicFilter, setBasicFilter] = React.useState(optionsFilter[0]);
  const [paymentStatus, setPaymentStatus] = React.useState(paymentStatusOption[0]);
  const [paymentType, setPaymentType] = React.useState(paymentTypeOption[0]);
  const [dayRangeFilter,setDayRangeFilter]=useState(dayRangeOption[0])

  const paymentList = async () => {
    const response = await getPaymentLists(
      accessToken,
      "lawyer",
      "All",
      pageSize,
      pageNumber,
      dayRangeFilter,
      startDate,
      endDate,
    );
    if (response) {
      console.log(response);
      setPaymentsList(response?.paymentAndTransactionsDetails);
      setTotalPages(response.totalPages)
      
    } else {
      console.log("error getting payment lists");
    }
  
 
};
useEffect(() => {
  if(basicFilter!=="Choose Date"){
    paymentList()
}
else if(basicFilter==="Choose Date"){
  if(startDate && endDate){
    paymentList()
  }}
  
}, [showUpdate,pageNumber,pageSize,dayRangeFilter]);
  return (
    <>
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      <Link href="/home" underline="none">
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Home sx={{ mr: 0.5, fontSize: 20 }} />
          Home
        </Typography>
      </Link>
      <Typography
        sx={{ display: "flex", alignItems: "center" }}
        color="text.primary"
      >
        <PaymentsIcon sx={{ fontSize: 20 }} />
        &nbsp;Payments
      </Typography>
    </Breadcrumbs>
    <Card
      sx={{
        p: 3,
        position: "relative",
        marginTop:"2rem"
      }}
    >
      <Div
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 5,
            }}
          >
            <Div
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
              }}
            >
              <Div
                style={{
                  height: "19px",
                  width: "5px",
                  backgroundColor: "#EABF4E",
                  marginRight: "8px",
                }}
              ></Div>
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  lineHeight: 1.5,
                  fontWeight: 400,
                }}
              >
                All Payments
              </Typography>
            </Div>
          </Div>
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
      <Div sx={{display:"flex",gap:1,alignItems:"center",paddingTop:"6px"}}>
      <Typography sx={{fontWeight:"bold",display:{xs:"none",sm:"block"}}}>Filter By</Typography>
    
      {/* <FormControl >
        <Select
          labelId="select-label"
          value={basicFilter}
          onChange={(e) => setBasicFilter(e.target.value)}
          sx={{ backgroundColor: "white" ,minWidth:"100px",mb:1}}
        >
          {optionsFilter.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      {/* {basicFilter ==="Payment Status" ? 
      <FormControl >
        <Select
          labelId="select-label"
          value={paymentStatus}
          onChange={(e) => setPaymentStatus(e.target.value)}
          sx={{ backgroundColor: "white" ,minWidth:"100px",mb:1}}
        >
          {paymentStatusOption.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      :basicFilter==="Payment Type" ? 
       <FormControl >
      <Select
        labelId="select-label"
        value={paymentType}
        onChange={(e) => setPaymentType(e.target.value)}
        sx={{ backgroundColor: "white" ,minWidth:"100px",mb:1}}
      >
        {paymentTypeOption.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    : <FormControl > */}
    <Select
      labelId="select-label"
      value={dayRangeFilter}
      onChange={(e) => setDayRangeFilter(e.target.value)}
      sx={{ backgroundColor: "white" ,minWidth:"100px",mb:1}}
    >
      {dayRangeOption.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  {/* </FormControl>} */}
  {dayRangeFilter==="Choose Date" &&     
   <>  
   <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label="Start Date"
        value={startDate}
        onChange={(newValue) => setStartDate(newValue)}
        maxDate={endDate}
        sx={{backgroundColor: "white",width:"9rem",mb:1}}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          minDate={startDate}
          sx={{  backgroundColor: "white" ,width:"9rem",mb:1}}
        />
    </LocalizationProvider>

              {/* <BasicDatePicker  setFromDate={setFromDate}  setToDate={setToDate} /> */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "secondary.main",
                    mb: 1,
                    width: "20px",

                    "&:hover": {
                      color: "white",
                      backgroundColor: "black",
                    },
                  }

                  }
                  onClick={()=>paymentList()}
                >
                  <ManageSearchIcon sx={{ fontSize: 34 }} />
                </Button>

              </> }
    </Div>
            <Div>
              <Select
                labelId="select-label"
                value={pageSize}
                sx={{backgroundColor: "white",ml:2}}
                onChange={(e) => {
                  setPageSize(e.target.value);
                }}
              >
                {optionsPageSize.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Div>
          </Div>
        </Div>
        <Divider sx={{ mt: 2 }} />

        <Div sx={{ mt: 0, p: "16px 16px 0 16px", width: "100%",mb:5 }}>
          <PaymentsList paymentsList={paymentsList}/>
        </Div>
      </Div>
      <Div
          sx={{ position: "absolute", bottom: 30, right: { sm: 0, lg: 25 },mt:"1rem" }}
        >
          <CardFooter  setPageNumber={setPageNumber}
                  totalPages={totalPages} />
        </Div>
    </Card>
  </>
  
  )
}

export default PaymentHistory