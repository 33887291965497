import React from 'react'
import Div from '@jumbo/shared/Div/Div';
import Grid from "@mui/material/Grid";
import Requests from './PaymentRequests/Requests';
import Withdraw from './PaymentsWithdraw/Withdraw';


const BalanceAndForm = ({showUpdate,setShowUpdate}) => {
  return (
    <Div>
<Grid container spacing={3.75}>
      <Grid item xs={12} lg={6}>
        <Div id="request">
        <Requests showUpdate={showUpdate} setShowUpdate={setShowUpdate}/>

        </Div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Div id="withdraw">
          <Withdraw />

        </Div>
      </Grid>
    </Grid>
    </Div>
  )
}

export default BalanceAndForm
