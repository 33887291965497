import React from "react";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import MiscDashboard from "app/pages/dashboards/misc/MiscDashboard";
import Onboarding from "app/pages/Onboarding/Onboarding";
import OnboardingSuccess from "app/pages/OnboardingSuccess/OnboardingSuccess";
import Schedule from "../pages/schedules/Schedule";
import NoCase from "app/pages/MyCases/NoCase";
import AddCase from "app/pages/MyCases/AddCase";
import CaseDetailAndEdit from "app/pages/MyCases/CaseDetailAndEdit";
import AddLawyer from "app/pages/Teams/AddLawyer";
import AddLawyerLayout from "app/pages/Teams/AddLawyerLayout";
import NoTask from "app/pages/Todos/NoTask";
import ClientListLayout from "app/pages/Clients/ClientListLayout";
import NoDocument from "app/pages/Documents/NoDocument";
import NoCasePoint from "app/pages/CasePoints/NoCasePoint";
import AddClientLayout from "app/pages/Clients/AddClientLayout";
import Login from "../pages/authPages/login";
import Otp from "app/pages/authPages/otp";
import RequireAuth from "../Components/RequireAuth";
import RequireAuthOnboard from "../Components/RequireAuthOnboard";

import { Route, Routes } from "react-router-dom";
import PageNotFound from "app/pages/404/PageNotFound";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import NoHearings from "app/pages/MyHearings/NoHearings";
import HearingPayments from "app/pages/MyHearings/HearingPayments";

import JitsiMeetLanding from "app/pages/components/Jitsi Meet/JitsiMeetLanding";

import VerticalStepper from "app/pages/VerticalStepper/VerticalStepper";
import PaymentGate from "app/pages/PaymentGate/paymentGate";
import UserProfile from "app/pages/userProfile/profile";
import Payments from "app/pages/Payments/Payments";
import WithdrawalHistory from "app/pages/Payments/Components/PaymentsWithdraw/WithdrawalHistory";
import ComingSoon from "app/pages/ComingSoon/ComingSoon";

import LegalDrafting from "app/pages/legalDrafting/legalDrafting";
import PaymentStatus from "app/pages/Payments/Status/PaymentStatus";
import Enquiry from "app/pages/Enquiry/Enquiry";
import { Terms } from "app/pages/terms/terms";
import ClientScreen from "app/pages/Onboarding/ClientScreen";
import EnquiryDash from "app/pages/EnquiryDash/Enquiry";
import EnquiryLogin from "app/pages/authPages/login/EnquiryLogin";

import help from "app/pages/help/help";
import DraftEditor from "app/Components/Editor/DraftEditor";
import DraftEditor2 from "app/Components/Editor/DraftEditor2";
import DraftEditor3 from "app/Components/Editor/DraftEditor3";
import EditorWrapper from "app/Components/Editor/EditorWrapper";
import ChatBot from "app/Components/ChatBot/ChatBot";
import ChatbotFullPage from "app/Components/ChatBot/ChatbotFullPage";
import ChatbotUi from "app/Components/ChatBot/ChatbotUi";
import ChatbotWeb from "app/Components/ChatBot/ChatbotWeb";

import LawyerProfile from "app/pages/Teams/LawyerProfile";
import PaymentHistory from "app/pages/Teams/PaymentHistory";
import NoTeams from "app/pages/Teams/NoTeams";
import JudgementLogin from "app/pages/authPages/login/JudgementLogin";
import Judgement from "app/pages/JudgementDash/JudgementDash";
import HelpPage from "app/pages/help/help";
import ChatbotMobileView from "app/Components/ChatBot/ChatbotMobileView";
import DailyActivity from "app/pages/DailyActivity/DailyActivity";
import Publications from "app/pages/Publications/Publications";

// import Payments from "app/pages/Payments/Payments";

export const Router = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	// console.log(theme.breakpoints.down('md'))
	// console.log("is mobile",matches);
	return (
		<Routes>
			<Route
				path="/"
				element={
					<Page
						component={Login}
						layout={"solo-page"}
						disableSmLogin={matches}
					/>
				}
			/>
			<Route
				path="/chatbotweb"
				element={
					<Page
						component={ChatbotWeb}
						layout={"solo-page"}
						disableSmLogin={matches}
					/>
				}
			/>
			<Route
				path="chatbot"
				element={
					<Page
						component={ChatbotFullPage}
						layout={"solo-page"}
						disableSmLogin={matches}
					/>
				}
			/>
			<Route
				path="chatbotMobileView"
				element={
					<Page
						component={ChatbotMobileView}
						layout={"solo-page"}
						disableSmLogin={matches}
					/>
				}
			/>
			<Route
				path="otp"
				element={
					<Page component={Otp} layout={"solo-page"} disableSmLogin={matches} />
				}
			/>

			{/* <Route
				path="editor2"
				element={<Page component={DraftEditor2} layout={"solo-page"} />}
			/>
			<Route
				path="editor"
				element={<Page component={DraftEditor} layout={"solo-page"} />}
			/> */}
			<Route
				path="enquiry"
				element={<Page component={VerticalStepper} layout={"solo-page"} />}
			/>
			<Route
				path="payment"
				element={<Page component={PaymentGate} layout={"solo-page"} />}
			/>

			<Route
				element={
					<RequireAuthOnboard
						allowedRoles={["ROLE_LAWYER", "ROLE_MEMBER", "ROLE_CLIENT"]}
					/>
				}
			>
				<Route
					path="editor"
					element={<Page component={EditorWrapper} layout={"solo-page"} />}
				/>
				<Route
					path="onboarding"
					element={
						<Page
							component={Onboarding}
							layout={"horizontal-layout"}
							disableSmLogin={true}
						/>
					}
				/>
				<Route
					path="onboardingsuccess"
					element={
						<Page
							component={OnboardingSuccess}
							layout={"horizontal-layout"}
							disableSmLogin={true}
						/>
					}
				/>
				<Route
					path="clients"
					element={
						<Page
							component={ClientScreen}
							layout={"solo-page"}
							disableSmLogin={true}
						/>
					}
				/>
				<Route
					path="oauth2/redirect"
					element={
						<Page
							component={Onboarding}
							layout={"horizontal-layout"}
							disableSmLogin={true}
						/>
					}
				/>
			</Route>
			<Route
				path="payment/status"
				element={
					<Page
						component={PaymentStatus}
						layout={"solo-page"}
						disableSmLogin={true}
					/>
				}
			/>
			<Route element={<RequireAuth allowedRole={"ROLE_ADMIN"} />}>
				<Route
					path="admin/cc/enquiry/list"
					element={<Page component={EnquiryDash} />}
				/>
				<Route
					path="admin/judgement/list"
					element={<Page component={Judgement} />}
				/>
				<Route
					path="admin/dashboard"
					element={<Page component={DailyActivity} />}
				/>
				<Route
					path="admin/publications"
					element={<Page component={Publications} />}
				/>
			</Route>
			<Route element={<RequireAuth allowedRole={"ROLE_MODERATOR"} />}>
				<Route
					path="moderator/cc/enquiry/list"
					element={<Page component={EnquiryDash} />}
				/>
				<Route
					path="moderator/judgement/list"
					element={<Page component={Judgement} />}
				/>
				<Route
					path="moderator/dashboard"
					element={<Page component={DailyActivity} />}
				/>
				<Route
					path="moderator/publications"
					element={<Page component={Publications} />}
				/>
			</Route>
			<Route
				path="/helpline"
				element={<Page component={HelpPage} layout={"solo-page"} />}
			/>
			{/* Routes for lawyers */}
			<Route element={<RequireAuth allowedRole={"ROLE_LAWYER"} />}>
				<Route path="lawyers/dashboards" element={<Page component={Home} />} />
				<Route path="home" element={<Page component={Home} />} />
				<Route
					path="dashboards/misc"
					element={<Page component={MiscDashboard} />}
				/>

				<Route
					path="lawyers/schedules"
					element={<Page component={Schedule} />}
				/>
				<Route
					path="lawyers/hearings"
					element={<Page component={NoHearings} />}
				/>
				<Route
					path="lawyers/teams/lawyerprofile/:lawyerId"
					element={<Page component={LawyerProfile} />}
				/>
				<Route
					path="lawyers/legalDrafting"
					element={<Page component={LegalDrafting} />}
				/>
				<Route
					path="lawyers/payments"
					element={<Page component={Payments} />}
				/>
				<Route
					path="lawyers/withdrawal/history"
					element={<Page component={WithdrawalHistory} />}
				/>
				<Route
					path="payments/:caseId"
					element={<Page component={HearingPayments} />}
				/>
				<Route
					path="lawyers/paymentHistory"
					element={<Page component={PaymentHistory} />}
				/>
				<Route
					path="lawyers/appointments"
					element={<Page component={Schedule} />}
				/>

				<Route path="lawyers/cases" element={<Page component={NoCase} />} />
				<Route
					path="lawyers/meet/:true"
					element={<Page component={JitsiMeetLanding} layout={"solo-page"} />}
				/>

				<Route
					path="lawyers/clients"
					element={<Page component={ClientListLayout} />}
				/>
				<Route path="lawyers/addcases" element={<Page component={AddCase} />} />
				<Route path="lawyers/teams" element={<Page component={AddLawyer} />} />
				<Route path="lawyers/todo" element={<Page component={NoTask} />} />
				<Route
					path="lawyers/casepoints"
					element={<Page component={NoCasePoint} />}
				/>
				<Route
					path="lawyers/chatbot"
					element={<Page component={ChatbotUi} />}
				/>
				<Route
					path="lawyers/documents"
					element={<Page component={NoDocument} />}
				/>
				<Route
					path="lawyers/addclient"
					element={<Page component={AddClientLayout} />}
				/>
				<Route
					path="lawyers/casedetail"
					element={<Page component={CaseDetailAndEdit} />}
				/>
				<Route
					path="lawyers/addlawyer"
					element={<Page component={AddLawyerLayout} />}
				/>
				<Route
					path="lawyers/editor"
					element={<Page component={EditorWrapper} />}
				/>
				<Route
					path="lawyers/settings"
					element={<Page component={UserProfile} />}
				/>

				<Route path="/terms" element={<Page component={Terms} />} />
				<Route path="/help" element={<Page component={HelpPage} />} />

				{/* <Route path="*" element={<Page component={PageNotFound} />}/> */}
			</Route>

			{/* routes for clients */}
			<Route element={<RequireAuth allowedRole={"ROLE_CLIENT"} />}>
				<Route
					path="clients/dashboards"
					element={<Page component={ClientScreen} />}
				/>
				<Route
					path="clients/payments"
					element={<Page component={Payments} />}
				/>
				<Route path="clients/enquiry" element={<Page component={Enquiry} />} />
			</Route>

			<Route path="*" element={<Page component={PageNotFound} />} />
		</Routes>
	);
};
export default Router;
