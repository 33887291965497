import React, { useEffect, useState } from "react";
import DraftEditor3 from "./DraftEditor3";
import EditorPopup from "./EditorPopup";
// import EditorAlert from "./EditorAlert";
// import htmlDocx from "html-docx-js";
import "./editor.css";

import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
	Alert,
	Breadcrumbs,
	Button,
	Collapse,
	IconButton,
	Typography,
} from "@mui/material";
// import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Link } from "react-router-dom";

import { Card, Divider } from "@mui/material";

import Div from "@jumbo/shared/Div";
// // import { Breadcrumbs, Link } from "@mui/material";
// import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

function EditorWrapper() {
	const [html, setHtml] = useState(null);
	const [redirectLink, setRedirectLink] = useState(null);
	const [apiData, setApiData] = useState(null);
	const [apiDataCases, setApiDataCases] = useState(null);
	const [open, setOpen] = React.useState(false);
	const [Loading, setLoading] = React.useState(false);
	const [openModal, setOpenModal] = React.useState(false);
	const [AlertContent, seAlertContent] = React.useState(null);
	const [responseData, setResponseData] = useState(null);

	useEffect(() => {
		console.log(html);
		console.log(redirectLink);
	}, [html]);

	useEffect(() => {
		let isMounted = true;
		async function fetchData() {
			let config = {
				method: "get",
				maxBodyLength: Infinity,
				url: "https://erp.lawinzo.com/api/v1/cases?size=100",
				headers: {
					"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
					Authorization:
						"Bearer eyJyb2xlIjpbIlJPTEVfTEFXWUVSIl0sImlkIjoiNjRhNGVlMzJjMzYwZTA2ZmI5NGNjOGMyIiwiYWxnIjoiSFM1MTIifQ.eyJzdWIiOiI4NDA5ODc4OTAwIiwiaWF0IjoxNjg5ODcxNzM1LCJleHAiOjIwMDU0OTA5MzV9.9RQqU17hs0jqAgIpKG6gEjvENtukwGFS6MMjfKRmYDZT0IknCY4Y3IwMvJIH0iFMDrVR-dqgUudxqM_aPOwpJQ",
				},
			};

			axios
				.request(config)
				.then((response) => {
					console.log(response.data);
					if (isMounted) setApiDataCases(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
		fetchData();

		return () => {
			isMounted = false; // Component is unmounted, set flag to false
		};
	}, []);

	const convertAndDownload = async () => {
		// const docxData = htmlDocx.asBlob(html);
		// saveAs(docxData, "outputgg.docx");
		// setRedirectLink("loading");
		setLoading(true);
		if (html === null || html === "<p><br/></p>") {
			setRedirectLink(null);
			setLoading(false);
			return;
		}

		if (apiData) {
			console.log(apiData.fileId);
			const response = await fetch(
				"http://localhost:4000/api/v1/documnetDraft", // "https://api.lawinzo.com/node/api/v1/documnetDraft"
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization:
							"Bearer eyJyb2xlIjpbIlJPTEVfTEFXWUVSIl0sImlkIjoiNjRhNGVlMzJjMzYwZTA2ZmI5NGNjOGMyIiwiYWxnIjoiSFM1MTIifQ.eyJzdWIiOiI4NDA5ODc4OTAwIiwiaWF0IjoxNjg5ODcxNzM1LCJleHAiOjIwMDU0OTA5MzV9.9RQqU17hs0jqAgIpKG6gEjvENtukwGFS6MMjfKRmYDZT0IknCY4Y3IwMvJIH0iFMDrVR-dqgUudxqM_aPOwpJQ",
						"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
					},
					body: JSON.stringify({ document: html, fileId: apiData.fileId }),
				}
			);
			const data = await response.json();

			console.log(data.data);
			setLoading(false);
			return;
		}
		const response = await fetch(
			"http://localhost:4000/api/v1/documnetDraft", // "https://api.lawinzo.com/node/api/v1/documnetDraft"
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization:
						"Bearer eyJyb2xlIjpbIlJPTEVfTEFXWUVSIl0sImlkIjoiNjRhNGVlMzJjMzYwZTA2ZmI5NGNjOGMyIiwiYWxnIjoiSFM1MTIifQ.eyJzdWIiOiI4NDA5ODc4OTAwIiwiaWF0IjoxNjg5ODcxNzM1LCJleHAiOjIwMDU0OTA5MzV9.9RQqU17hs0jqAgIpKG6gEjvENtukwGFS6MMjfKRmYDZT0IknCY4Y3IwMvJIH0iFMDrVR-dqgUudxqM_aPOwpJQ",
					"x-api-key": "449772DE-2780-4412-B9F7-E49E48605875", // Set the content type to text/plain
				},
				body: JSON.stringify({
					document: html,
					caseId:
						responseData.caseId === ""
							? "No Case Id Selected"
							: responseData.caseId,
					fileName: responseData.fileName,
				}),
			}
		);
		const data = await response.json();

		console.log(data.data);
		// setRedirectLink(data.data);
		setApiData(data.data);
		setLoading(false);
		// const blob = await response.blob();
		// const url = window.URL.createObjectURL(blob);
		// const link = document.createElement("a");
		// link.href = url;
		// link.download = "testDoc.docx";
		// document.body.appendChild(link);
		// link.click();
		// document.body.removeChild(link);
	};

	return (
		<>
			<Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
				<Link href="/home" underline="none">
					<Typography
						sx={{ display: "flex", alignItems: "center" }}
						color="text.primary"
					>
						<HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
						Home
					</Typography>
				</Link>
				<Typography
					sx={{ display: "flex", alignItems: "center" }}
					color="text.primary"
				>
					<CalendarMonthOutlinedIcon sx={{ fontSize: 20 }} />
					&nbsp;Draft Editor
				</Typography>
			</Breadcrumbs>

			<Card
				sx={{
					p: 3,
					position: "relative",
					minHeight: "90%",
				}}
			>
				<Div
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<Div
						sx={{
							display: "flex",

							alignItems: "center",
							justifyContent: "space-between",
							flexWrap: "wrap",
						}}
					>
						<Div
							sx={{
								display: "flex",
								width: "100%",
								alignItems: "center",
								justifyContent: "space-between",
								// gap: 5,
							}}
						>
							<Div
								sx={{
									display: { xs: "none", sm: "flex" },
									alignItems: "center",
								}}
							>
								<Div
									sx={{
										display: { xs: "none", sm: "flex" },
										alignItems: "center",
									}}
								>
									<Div
										style={{
											height: "19px",
											width: "5px",
											backgroundColor: "#EABF4E",
											marginRight: "8px",
										}}
									></Div>
									<Typography
										sx={{
											fontSize: "1.25rem",
											lineHeight: 1.5,
											fontWeight: 400,
										}}
									>
										Draft Editor
									</Typography>
								</Div>
								{/* <Button
									style={{ fontWeight: "700" }}
									startIcon={<AddIcon />}
									color="secondary"
									// onClick={() => setAddApp(true)}
								>
									Add Appointment
								</Button> */}
							</Div>
							<div className="" style={{ display: "flex", gap: "10px" }}>
								<button
									style={{
										backgroundColor: "transparent",
										border: "2px solid black",
										borderRadius: "5px",
										padding: "8px 12px",
										cursor: "pointer",
									}}
									disabled={Loading}
									onClick={() => {
										if (apiData) {
											convertAndDownload();
										} else {
											if (html === null || html === "<p><br/></p>") {
												seAlertContent("Add Some Content before Saving");
												setOpen(true);
												setTimeout(() => {
													setOpen(false);
													seAlertContent("");
												}, 2000);
												return;
											}
											setOpenModal(true);
										}
									}}
								>
									Save Documnet
								</button>
								<button
									style={{
										backgroundColor: "transparent",
										border: "2px solid black",
										borderRadius: "5px",
										padding: "8px 12px",
										cursor: "pointer",
									}}
									disabled={Loading}
								>
									Share Doc
								</button>
							</div>
						</Div>
					</Div>
					<Divider sx={{ mt: 2 }} />

					<div className="lawinzo-editor-start" style={{ height: "100%" }}>
						<Collapse in={open}>
							<Alert
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											setOpen(false);
										}}
									>
										<CloseIcon fontSize="inherit" />
									</IconButton>
								}
								sx={{ mb: 2 }}
							>
								{AlertContent}
							</Alert>
						</Collapse>

						<div
							style={{
								// maxWidth: "1100px",
								width: "100%",
								margin: "0 auto",
								position: "relative",
								border: "2px solid black",
								height: "100%",
							}}
						>
							<DraftEditor3 setHtml={setHtml} />
						</div>
					</div>
				</Div>
			</Card>

			{apiDataCases ? (
				<EditorPopup
					open={openModal}
					setOpen={setOpenModal}
					convertAndDownload={convertAndDownload}
					apiDataCases={apiDataCases}
					responseData={responseData}
					setResponseData={setResponseData}
				/>
			) : null}
		</>
	);
}

export default EditorWrapper;
