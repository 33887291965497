import Div from "@jumbo/shared/Div";
import React, { useEffect, useState } from "react";
import AddTask from "./AddTask";
import useTasks from "app/hooks/useTasks";
import TaskListLayout from "./TaskListLayout";
import { useLocation, useParams } from "react-router-dom";

const optionsFilter = ["Select","Today","Yesterday","Tomorrow", "This Week","This Month","Choose Date"];
function NoTask() {
  const [tasksList, setTasksList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addTaskOpen, setAddTaskOpen] = useState(false);
  const [taskAdded, setTaskAdded] = useState(false);
  const [taskEditItem,setTaskEditItem] = useState(null);
  const [totalTasks,setTotalTasks] = useState(null);
  const [totalPages,setTotalPages] = useState(null);
  const [pageNumber,setPageNumber] = useState(0);
  const [pageSize,setPageSize] = useState("5");
  const status=useParams()
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const addTask = queryParameters.get("addTask");
  const [basicFilter, setBasicFilter] = React.useState(optionsFilter[0]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState();

  console.log(addTask)
  const getAllTasks= useTasks();
  const getAllTasksList = async () => {
    setIsLoading(true);
    console.log(pageNumber)
    const response = await getAllTasks(pageNumber,pageSize,basicFilter,startDate,endDate);
    if (response) {
      console.log(response);
      setTasksList(response?.data?.tasks);
      setTotalPages(response?.data?.totalPages);
      setIsLoading(false);
    }
    
  };
  useEffect(() => {
    if(addTask){
      setAddTaskOpen(true)
    }
    if(basicFilter!=="Choose Date"){
      getAllTasksList()
  }
  else if(basicFilter==="Choose Date"){
    if(startDate && endDate){
      getAllTasksList()
    }}
   
  }, [taskAdded,pageNumber,pageSize,basicFilter]);
  useEffect(()=>{

  },[])
  useEffect(()=>{
    console.log(taskEditItem)
  },[taskEditItem])
  return (
    <>
      {addTaskOpen ? (
        <AddTask
          setAddTaskOpen={setAddTaskOpen}
          setTasksList={setTasksList}
          setTaskAdded={setTaskAdded}
          taskEditItem={taskEditItem}
          setTaskEditItem={setTaskEditItem}
          taskAdded={taskAdded}
        />
      ) : (
        <TaskListLayout
          tasksList={tasksList}
          setTasksList={setTasksList}
          setAddTaskOpen={setAddTaskOpen}
          setTaskAdded={setTaskAdded}
          setTaskEditItem={setTaskEditItem}
          totalTasks={totalTasks}
          setTotalTasks={setTotalTasks}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          setPageSize={setPageSize}
          pageSize={pageSize}
          taskAdded={taskAdded}
          isLoading={isLoading}
          getAllTasksList={getAllTasksList}
          optionsFilter={optionsFilter}
          basicFilter={basicFilter}
          setBasicFilter={setBasicFilter}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        
        />
      )}
    </>
  );
}

export default NoTask;
