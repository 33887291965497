import React, { useState } from "react";
import ChartSalesOverview from "./ChartSalesOverview";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { salesChartData } from "./data";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div/Div";

const SalesOverview = () => {
  const [chartData, setChartData] = useState(salesChartData.monthly);
  return (
    <JumboCardQuick
      noWrapper
      sx={{ height: "100%" }}
      title={
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Div
            style={{
              height: "19px",
              width: "5px",
              backgroundColor: "#EABF4E",
              marginRight: "8px",
            }}
          ></Div>
          <Typography
            sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 400 }}
          >
            Payments
          </Typography>
        </Div>
      }
      action={
        <Stack direction={"row"} spacing={1}>
          <Button
            size={"small"}
            variant={"contained"}
            onClick={() => setChartData(salesChartData.monthly)}
            sx={{
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
              },
            }}
          >
            Monthly
          </Button>
          <Button
            size={"small"}
            sx={{
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
              },
            }}
            variant={"contained"}
            onClick={() => setChartData(salesChartData.yearly)}
          >
            Yearly
          </Button>
        </Stack>
      }
    >
      <ChartSalesOverview data={chartData} />
    </JumboCardQuick>
  );
};

export default SalesOverview;
