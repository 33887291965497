import React from "react";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div/Div";
import GavelIcon from "@mui/icons-material/Gavel";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import EditIcon from "@mui/icons-material/Edit"; // Import the EditIcon
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import DeleteIcon from '@mui/icons-material/Delete';
import { updateTimingSettings } from "app/Apis/settings";
import { useState } from "react";
import { Modal } from "@mui/material";
import { Button } from "@mui/material";
const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const repetitionFor = [
  "Doesn't Repeat",
  "All Days",
  "Monday to Alternate Saturdays",
];

const TimingCard = ({setOpenSnackBar ,setSnackbarMessage, setInitSettings,initSettings,setEditId, timing ,setInitFormTiming,setEditTimeFormOpen,setValue,accessToken,lawyerId}) => {
  const { theme } = useJumboTheme();
  const openingTime = timing.openingTime;
  const date = new Date(openingTime);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const readableTime = `${hours % 12}:${minutes.toString().padStart(2, '0')} ${hours < 12 ? 'AM' : 'PM'}`;

  const closingTime = timing.closingTime;
  const closingDate = new Date(closingTime);
  const closingHours = closingDate.getHours();
  const closingMinutes = closingDate.getMinutes();
  const Time = `${closingHours % 12}:${closingMinutes.toString().padStart(2, '0')} ${closingHours < 12 ? 'AM' : 'PM'}`;

  const openingDateTime = date.getTime();
  const closingDateTime = closingDate.getTime();
  const differenceMs = closingDateTime - openingDateTime;

  // Convert the difference to hours and minutes
  const differenceHours = Math.floor(differenceMs / (1000 * 60 * 60));
  const differenceMinutes = Math.floor(
    (differenceMs % (1000 * 60 * 60)) / (1000 * 60)
  );

  // Format the difference as a readable time
  const differenceReadableTime = `${differenceHours} hours ${differenceMinutes} minutes`;
  const dayOfWeek =
    timing.repetitionFor === 0
      ? weekDays[timing.dayOfWeek]
      : timing.repetitionFor === 1
      ? "Monday to Sunday"
      : "Monday to Saturday";

  const setActionValue = (label)=>{
    if(label==="court"){
        setValue(0);
    }else if(label==="office"){
        setValue(1);
    }else if(label==="home"){
        setValue(2);
    }
  }    
  const handleEdit = ()=>{
    setActionValue(timing?.timingLabel);
    setInitFormTiming(timing?.timingId);
    setEditTimeFormOpen(true);
    setEditId(timing?.timingId)
  }
  
  const handleDeleteConfirmation = async () => {
    console.log("")
    const timingDetails = initSettings?.timingDetails;
    const index = timingDetails.findIndex(
      (time) => time?.timingId === timing?.timingId
    );
    if (index === -1) {
      throw Error("Index not found");
    } else {
      timingDetails[index] = {
        ...timingDetails[index],
        isActive: 0,
      };
  const response = await updateTimingSettings(initSettings,timingDetails,lawyerId,accessToken)
  if(response){
    console.log(response)
    setInitSettings(response)
    setOpenSnackBar(true);
    setSnackbarMessage("Timing Deleted Successfully!")

  }
}
  }

  const [openModal, setOpenModal] = useState(false);
  const handleDelete = () => {
    setOpenModal(true);
  };
  return (
    <Div
      alignItems="flex-start"
      sx={{
        mt: 1,
        px: 1,
        py: 1,
        // minHeight: "",
        height:"100%",
        border: "1px solid rgb(232, 232, 232)",
        borderRadius: "8px",
      }}
    >
      <Div
        sx={{
          display: "flex",
          borderBottom: "1px solid",
          borderColor: theme.palette.divider,
          pb: 1,
          mb: 1,
          alignItems: "center",
          justifyContent:"space-between"
        }}
      >
       <Div
       sx={{display:"flex", flexDirection:"row", alignItems:"center"}}
       >
               {(() => {
          if (timing?.timingLabel === "Home") {
            return <HomeIcon sx={{ mb: 0 ,ml:"5px",mr:"5px" }} />;
          } else if (timing?.timingLabel === "Court") {
            return <GavelIcon sx={{ mb: 0,ml:"5px",mr:"5px" }} />;
          } else if (timing?.timingLabel === "Office") {
            return (
              
                <WorkIcon sx={{ mb: 0,ml:"5px",mr:"5px"   }} />
            );
          } else {
            return null;
          }
        })()}
        <Typography
          variant="h3"
          color="black"
          sx={{
            fontSize: "1rem",
            mb: "1",
            fontWeight: 500,
            color: "rgb(79,79,79)",
            textTransform: "capitalize",
            width: "100%",
          }}
        >
          {timing?.timingLabel}
        </Typography>
 
        </Div>

        <Div >
        
        <EditIcon sx={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleEdit}/> 
      <DeleteIcon sx={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleDelete} />

      </Div>
      </Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Div>
          <Typography variant={"h5"} mb={1.25}>
            {dayOfWeek}
          </Typography>
          <Typography variant={"h5"} mb={1.25}>
            {readableTime} to {Time}
          </Typography>
          <Typography
            variant={"body1"}
            color={"text.secondary"}
            sx={{
              display: "flex",
              minWidth: 0,
            }}
          >
            {`Available for: ${differenceReadableTime}`}
          </Typography>
        </Div>
      </Div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            minWidth: 300,
            maxWidth: 500,
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
            <Typography variant="h6" id="delete-modal-title" gutterBottom>
            Are you sure you want to delete this Timing?
          </Typography>
          <Typography variant="body1" id="delete-modal-description">
            This action cannot be undone.
          </Typography>
 <Box sx={{ mt: 2 }}>

      
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteConfirmation}
               sx={{ mr: 2 }}
            >
              Delete
            </Button>
            <Button
                variant="outlined"
              color="primary"
              onClick={() => setOpenModal(false)}
              
            >
              Cancel
            </Button>
          
        </Box>
        </Box>
      </Modal>     
    </Div>
  );
};

export default TimingCard;
