import React, { useEffect, useState } from "react";
import { Alert, Button, Snackbar, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import EachClient from "./EachClient";
import { LoadingButton } from "@mui/lab";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import RenderClient from "./RenderClient";
import { caseCorporate, caseWitness } from "app/Apis/case";
import { useSelector } from "react-redux";

function CorporateEdit({
  handleBackButton,
  handleNextButton,
  caseId,
  setCaseDetails,
  caseDetails,
  setUpdate,
  setIsLoading,
  formDisable,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [error, setError] = useState(false); // Corrected the initial value to false
  const [showData, setShowData] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState(
    caseDetails?.corporateClients ? caseDetails?.corporateClients : []
  );
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const AddClient = () => {
    setShowData(true);
    setCount((state) => state + 1);
  };

  const handleClose = () => setOpen(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [addClient, setAddClient] = useState(false);

  const handleArchieve = () => {
    if (error === false) {
      // setOpen(false);
      // setOpenSnackBar(true);
      handleNextButton();
    } else {
      setOpenSnackBar(false);
    }
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await caseCorporate(caseId, selectedPeople);
      setIsLoading(false);

      if (response) {
        console.log(response);
        setUpdate((prev) => !prev);
        setCaseDetails(response);
      }
      // navigate("/lawyers/cases");
      handleNextButton();
    } catch (err) {
      console.log(err);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },
          height: "100%",
        }}
      >
        <RenderClient
          formDisable={formDisable}
          addClient={addClient}
          setAddClient={setAddClient}
          showData={showData}
          setShowData={setShowData}
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
          selectedPeople={selectedPeople}
          setSelectedPeople={setSelectedPeople}
          clientNo={count}
          setClientNo={setCount}
          name={"Corporate Client"}
          setError={setError}
          poepleType={"Corporate_Client"}
        />

        <Div
          sx={{
            display: "flex",
            pr: 2,
            justifyContent: "flex-end",
          }}
        ></Div>
        <Div
          sx={{
            width: "100%",
            pl: 2,
            pr: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
            // backgroundColor: "green",
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            variant="contained"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            color="secondary"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default CorporateEdit;
