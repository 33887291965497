import React, { useEffect, useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List } from "@mui/material";
import { events } from "./AddTime";
import TimingItem from "./timingItem";
import useSettings from "app/hooks/useSettings";
import AddTime from "./AddTime";
import AddTimeForm from "./AddTimeForm";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import { NoCaseContainer } from "app/pages/MyCases/style";
import TimingCard from "./TimingCard";
import AddTimingCard from "./AddTimingCard";
import EditTimeForm from "./editTimeForm";
import { Alert, Snackbar } from "@mui/material";


const name = ["Court", "Office", "Home"];

const Timing = ({
  sx,
  accessToken,
  lawyerId,
  initSettings,
  setInitSettings,
}) => {
  // const [initSettings, setInitSettings] = useState();
  const [timing, setTiming] = useState();
  const [timeFormOpen, setTimeFormOpen] = useState(false);
  const [editTimeFormOpen, setEditTimeFormOpen] = useState(false);
  const [value, setValue] = useState("0");
  // const [homeTiming, setHomeTiming] = useState([]);
  // const [officeTiming, setOfficeTiming] = useState([]);
  // const [courtTiming, setCourtTiming] = useState([]);
  const [initFormTiming, setInitFormTiming] = useState(null);
  const [editId, setEditId] = useState("");
  
  const handleValueChange = (e, value) => {
    // console.log(value);
    setValue(value);
  };
  // const getSetting = useSettings();
  // useEffect(() => {
  //   const getTiming = async () => {
  //     const settings = await getSetting(lawyerId, accessToken);
  //     if (!settings) {
  //       console.log("error getting settings");
  //     }

  //     setTiming(settings.timingDetails);
  //     setInitSettings(settings);
  //   };
  //   getTiming();
  // }, []);
  // useEffect(() => {
  //   if (timing) {
  //     timing.map((timing) => {
  //       timing.timingLabel === "office"
  //         ? setOfficeTiming([...officeTiming, { ...timing }])
  //         : timing.timingLabel === "court"
  //         ? setCourtTiming([...courtTiming, { ...timing }])
  //         : setHomeTiming([...homeTiming, { ...timing }]);
  //     });
  //   }
  // }, [initSettings, timing]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const handleSnackbarClose = (event, reason) => {
    setOpenSnackBar(false);
  };
  return (<div>
  
    {openSnackBar && (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        sx={{ zIndex: 1000, mt: 10 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
        >
          <Typography variant="h6">
           {snackbarMessage}
          </Typography>
        </Alert>
      </Snackbar>
    )}
    <JumboCardQuick
      title={<Typography sx={{ fontSize: "1.25rem" }}>Timing</Typography>}
      action={
        timeFormOpen && (
          <TabContext value={value}>
            <Div
              sx={{
                "marginTop": -2.25,
                "marginBottom": -2.5,

                "& .MuiTab-root": {
                  paddingTop: "25px",
                  paddingBottom: "27px",
                },
              }}
            >
              <TabList
                onChange={handleValueChange}
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="Timing Tabs"
              >
                <Tab label="Court" value="0" />
                <Tab label="Office" value="1" />
                <Tab label="Home" value="2" />
              </TabList>
            </Div>
          </TabContext>
        )
      }
      wrapperSx={{ p: 0 }}
      headerSx={{
        borderBottom: 1,
        borderColor: "divider",
      }}
      sx={sx}
    >
      <Grid
        container
        direction={"column"}
        // justifyContent={"space-between"}
        sx={{ padding: "1.5rem 1.5rem 0.25rem 1.5rem" }}
      >
        <Grid item md={12}>
          {editTimeFormOpen && (
            <EditTimeForm
              setEditTimeFormOpen={setEditTimeFormOpen}
              initSettings={initSettings}
              setInitSettings={setInitSettings}
              name={name[value]}
              initFormTiming={initFormTiming}
              setInitFormTiming={setInitFormTiming}
              editId={editId}
              setOpenSnackBar={setOpenSnackBar} 
              setSnackbarMessage={setSnackbarMessage}
            />
          )}
          {timeFormOpen && (
            <AddTimeForm
              setTimeFormOpen={setTimeFormOpen}
              initSettings={initSettings}
              setInitSettings={setInitSettings}
              name={name[value]}
              initFormTiming={initFormTiming}
              setInitFormTiming={setInitFormTiming}
              setOpenSnackBar={setOpenSnackBar} 
              setSnackbarMessage={setSnackbarMessage}
            />
          )}
        </Grid>

        <Grid item md={12}>
          <Grid container spacing={2}>
            {!timeFormOpen && (
              <Grid item lg={4} md={6} xs={12}>
                <AddTimingCard setTimeFormOpen={setTimeFormOpen} />
              </Grid>
            )}
            {/* {
              value==="0"?
              courtTiming.map((timing, index) => (
                <TimingItem timing={timing} key={index} />
              )):
              value==="1"?
              officeTiming.map((timing, index) => (
                <TimingItem timing={timing} key={index} />
              )):
              homeTiming.map((timing, index) => (
                <TimingItem timing={timing} key={index} />
              ))
            } */}
            {/* {timing &&
              timing.map((timing, index) => (
                <TimingItem timing={timing} key={index} />
              ))} */}
            {initSettings?.timingDetails &&
              initSettings?.timingDetails.map((timing, index) => {
                if (timing?.isActive) {
                  return (
                    <Grid item lg={4} md={6} xs={12}>
                      <TimingCard
                        timing={timing}
                        key={index}
                        setInitFormTiming={setInitFormTiming}
                        setEditTimeFormOpen={setEditTimeFormOpen}
                        setValue={setValue}
                        setEditId={setEditId}
                        initSettings={initSettings}
                        setInitSettings={setInitSettings}
                        accessToken={accessToken}
                        lawyerId={lawyerId}
                        setOpenSnackBar={setOpenSnackBar} 
                        setSnackbarMessage={setSnackbarMessage}
                      />
                    </Grid>
                  );
                }
              })}
          </Grid>
        </Grid>
      </Grid>
    </JumboCardQuick>
    </div>
  );
};

export default Timing;
