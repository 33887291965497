import React from "react";
import { ImageList, ImageListItem, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import FavoriteIcon from '@mui/icons-material/Favorite';
const Footer = () => {
  return (
    <Div
      sx={{
        py: 2,
        px: { lg: 6, xs: 4 },
        borderTop: 2,
        borderColor: "divider",
        bgcolor: "background.paper",
      }}
    >
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant={"body1"} color={"text.primary"}>
          Copyright Vakily © 2023
        </Typography>
        <Div
          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
        >
          <Typography
            variant={"body1"}
            sx={{
              display: { xs: "none", sm: "flex" },
              mb: 0,
              mr: 2,
              color: "text.primary",
              alignItems:"center"
            }}
          >
            Made with{" "}
            <FavoriteIcon sx={{color:"#ff4040",fontSize:"1.75rem", paddingLeft:"4px",paddingRight:"4px"}}/>
            In India
          </Typography>
          <ImageList
            sx={{ display: { xs: "none", sm: "block", width: "15%" } }}
          >
            <ImageListItem>
              <img
                alt="Make In India"
                src="/Make_In_India-min.webp"
                loading="lazy"

              />
            </ImageListItem>
          </ImageList>
        </Div>
      </Div>
    </Div>
  );
};

export default Footer;
