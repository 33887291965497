import HearingPayments from 'app/pages/MyHearings/HearingPayments'
import React from 'react'
import RequestForm from './RequestForm'
import { Typography } from '@mui/material'
import Div from '@jumbo/shared/Div/Div'
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";

const Requests = ({showUpdate,setShowUpdate}) => {

  const isMobile = window.innerWidth < 990;
  const [value, setValue] = React.useState("0");
  return (
    <JumboCardQuick
    wrapperSx={{p:0,}}
      title={<Typography sx={{ fontSize: 20 }}>Payment-Raise Invoice</Typography>}
      action={
        <TabContext value={value}>
          <Div
            sx={{
              overflowX: "auto",
              overflowY:"auto",  
              p:0,
              marginTop: -2.25,
              marginBottom: -2.5,
              display: "flex",
              justifyContent: "flex-end",
           
              "& .MuiTab-root": {
                paddingTop: isMobile ? "70px" : "25px",
                paddingBottom: isMobile ? "12px" : "27px", 
              },
            }}
          >
          </Div>
        </TabContext>
      }
      headerSx={{
        borderBottom: 1,
        borderColor: "divider",
      }}
      sx={{
        mb: 3.75,
        height: "35rem",
        // overflowY: "auto", 
        // pb: 1
      }}
    >
    <Div sx={{maxHeight:"32rem",overflow:"auto"}}>
      <RequestForm showUpdate={showUpdate} setShowUpdate={setShowUpdate}/>
      {/* Requests */}
    </Div>
    </JumboCardQuick>
  )
}

export default Requests
