import React from "react";
import Div from "@jumbo/shared/Div/Div";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { Snackbar, Typography, Alert,Modal,Backdrop,Fade,Box } from "@mui/material";
import AddDocumentForm from "./AddDocumentForm";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DisplayDocumentsAPI from "../DisplayDocuments/DisplayDocumentsAPI";



const AddSingleDocument = ({
  documentsArray,
  setDocumentsArray,
  documentsAPIList,
  setDocumentsAPIList,
  setDocumentFile,
  setDocumentUrl,
  type,
  fromEnquiry
}) => {
  const fileInput = React.useRef(null);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [documentSaveAPI, setDocumentSaveAPI] = React.useState(false);


  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);

    setDocumentsArray(null);
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleFileInputChange = (e) => {
    console.log(documentsArray);
    console.log(e.target.files);
    if(documentsAPIList &&  documentsAPIList.length>0){
      setSnackBarOpen(true);
    }else{
      const array = [...e.target.files];
      // debugger;
      
        if (array?.length === 1) {
          console.log(array);
          setDocumentsArray(array);
          handleOpen();
        } else if (array?.length > 1){
          setSnackBarOpen(true);
        }
    }
  };
  const handleSnackbarClose = () => {
    console.log("file dialog closed");
    setSnackBarOpen(false);
  };

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
      >
        <Alert severity="warning">You can only select one file. </Alert>
      </Snackbar>
      <Div
        sx={{
          width: "100%",
          border: "1px dotted #d3d3d3",
          overflowY: "auto",
          mt: 2,
          p: 2,
        }}
      >
        <input
          style={{ marginTop: "10px", width: "30vw" }}
          accept={type==="Judgement"?"application/pdf":"image/*"}
          multiple
          type="file"
          ref={fileInput}
          onChange={handleFileInputChange}
          hidden
        />
        {!(documentsAPIList && documentSaveAPI) && (
        <UploadFileIcon sx={{ fontSize: "40px" }} onClick={handleClick} />
      )}
      {documentsAPIList && documentSaveAPI && (
        <DisplayDocumentsAPI
          handleClick={handleClick}
          documentsAPIList={documentsAPIList}
          setDocumentsAPIList={setDocumentsAPIList}
        />
      )}
      </Div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "93%", lg: "600px" },
              height: { xs: "85%", lg: "550px" },
              bgcolor: "background.paper",
              border: "1px solid #000",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <span
              style={{
                position: " absolute",
                right: " -8px",
                top: "-8px",
                cursor: "pointer",
                borderRadius: "50%",
                height: "22px",
                width: "22px",
                display: "inline-flex",
                backgroundColor: "rgb(232 232 232)",
                fontSize:"22px",
              }}
            >
              <CancelTwoToneIcon
                fontSize="inherit"
                onClick={handleClose}
                variant
              />
            </span>
            <Div sx={{ width: "100%", height: "100%" }}>
                <Div
                sx={{
                    display:"flex",
                    justifyContent:"center",
                    width:"100"
                }}
                >
            {documentsArray && documentsArray[0].type.startsWith("image/") ? (
                <img
                  style={{
                    width: "100%",
                    height: "150px",
                    borderRadius: "5px",
                    objectFit: "contain",
                  }}
                  src={URL.createObjectURL(documentsArray[0])}
                  alt="document"
                />
              ) : documentsArray && documentsArray[0].type === "application/pdf" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "150px",
                    borderRadius: "5px",
                    backgroundColor: "#EABF4E",
                    fontSize: "1rem",
                  }}
                >
                  <PictureAsPdfIcon size={16} color="red" style={{marginLeft:"5px"}}/>
                  <Typography sx={{
                    maxWidth:"120px",
                    overflow: "hidden", // Add overflow hidden to hide overflowing content
                    whiteSpace: "nowrap", // Prevent text from wrapping
                    textOverflow: "ellipsis",
                  }}>{documentsArray[0]?.name}</Typography>
                </div>
              ) : null}
              </Div>
              <AddDocumentForm
              documentsArray={documentsArray}
              handleClose={handleClose}
              setDocumentSaveAPI={setDocumentSaveAPI}
              type={type}
              documentsAPIList={documentsAPIList}
              fromEnquiry={fromEnquiry}
              setDocumentsAPIList={setDocumentsAPIList}
              setDocumentFile={setDocumentFile}
              setDocumentUrl={setDocumentUrl}
              />
            </Div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddSingleDocument;
