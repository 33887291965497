import React from "react";
import { Grid } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function DisplayDocuments({
	handleClick,
	setDocumentsArray,
	documentsArray,
	enablePreview,
	formDisable,
}) {
	const removeDocument = (name) => {
		const newDocumentsArray = documentsArray.filter(
			(file) => file?.name !== name
		);
		console.log(newDocumentsArray);
		setDocumentsArray(newDocumentsArray);
	};
	return (
		<Grid container spacing={2} direction={"row"} alignItems={"stretch"}>
			<Grid item md={3}>
				<Div
					sx={{
						display: "flex",
						alignContent: "center",
						justifyContent: "center",

						mt: 1,

						p: 1,
						border: "1px solid rgb(232, 232, 232)",
						borderRadius: "8px",
						cursor: "pointer",
						"&:hover": {
							backgroundColor: "rgb(232, 232, 232)",
						},
						minHeight: "150px",

						height: "100%",
					}}
					onClick={handleClick}
				>
					<Div
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<AddCircleOutlineIcon />

						<Typography
							sx={{
								fontSize: "1rem",
								color: "rgb(79, 79, 79)",
								fontWeight: 500,
								textTransform: "capitalize",
							}}
						>
							Add Files
						</Typography>
					</Div>
				</Div>
			</Grid>
			{documentsArray &&
				documentsArray.map((file, index) => (
					<Grid item md={3}>
						<Div
							key={index}
							sx={{
								position: "relative",
								backgroundColor: "rgb(243 243 243)",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								height: "auto",
								mt: 1,
								borderRadius: "8px",
							}}
						>
							{file.type.startsWith("image/") ? (
								<img
									style={{
										width: "100%",
										height: "150px",
										borderRadius: "5px",
										objectFit: "contain",
									}}
									src={URL.createObjectURL(file)}
									alt="document"
								/>
							) : file.type === "application/pdf" ? (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
										height: "150px",
										borderRadius: "5px",
										backgroundColor: "#EABF4E",
										fontSize: "1rem",
									}}
								>
									<PictureAsPdfIcon
										size={16}
										color="red"
										style={{ marginLeft: "5px" }}
									/>
									<Typography
										sx={{
											maxWidth: "120px",
											overflow: "hidden", // Add overflow hidden to hide overflowing content
											whiteSpace: "nowrap", // Prevent text from wrapping
											textOverflow: "ellipsis",
										}}
									>
										{file?.name}
									</Typography>
								</div>
							) : file.type ===
							  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
										height: "150px",
										borderRadius: "5px",
										backgroundColor: "#EABF4E",
										fontSize: "1rem",
									}}
								>
									<PictureAsPdfIcon
										size={16}
										color="red"
										style={{ marginLeft: "5px" }}
									/>
									<Typography
										sx={{
											maxWidth: "120px",
											overflow: "hidden", // Add overflow hidden to hide overflowing content
											whiteSpace: "nowrap", // Prevent text from wrapping
											textOverflow: "ellipsis",
										}}
									>
										{file?.name}
									</Typography>
								</div>
							) : null}
							<span
								style={{
									position: " absolute",
									right: " -8px",
									top: "-8px",
									cursor: "pointer",
									borderRadius: "50%",
									height: "22px",
									width: "22px",
									display: "inline-flex",
									backgroundColor: "rgb(232 232 232)",
								}}
							>
								<CancelTwoToneIcon
									onClick={() => removeDocument(file?.name)}
									sx={{}}
								/>
							</span>
						</Div>
					</Grid>
				))}
		</Grid>
	);
}

export default DisplayDocuments;
