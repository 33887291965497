import React from "react";
import AppointmentCard from "./AppointmentCard";
import Skeletons from "../Skeletons";
import NoAppointment from "../NoAppointment";

const ProjectsList = ({
  appointmentEdit,
  appointmentList,
  setAddApp,
  setAppointmentEdit,
  setShowUpdate,
  showUpdate,
  setDoEditUpdate,
  isLoading,
  pageSize,
  values
}) => {
  console.log("ndsk", appointmentList);
  return (
    <React.Fragment>
      {isLoading? 
        Array.from(Array(parseInt(pageSize))).map((item,index)=><Skeletons key={index}/>)
      :
      (appointmentList.length > 0 ?(
        appointmentList.map((appointment, index) => {
          if (appointment?.isActive) {
            return (
              <AppointmentCard
                Key={appointment.id}
                setShowUpdate={setShowUpdate}
                setAppointmentEdit={setAppointmentEdit}
                setAddApp={setAddApp}
                item={appointment}
                appointmentEdit={appointmentEdit}
                appointmentList={appointmentList}
                showUpdate={showUpdate}
                values={values}
              />
            );
          }
        }))
        : <NoAppointment hide={true} setAddApp={setAddApp} /> 
        )
        
        }
        
    </React.Fragment>
  );
};

export default ProjectsList;
