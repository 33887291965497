import React from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Div from "@jumbo/shared/Div";

const EditorSelect = ({ apiDataCases, CaseData, setCaseData }) => {
	const [age, setAge] = React.useState("");
	// console.log(CaseData);
	// console.log(apiDataCases.cases);
	return (
		<Div style={{ marginTop: "3px" }}>
			<FormControl sx={{ width: "100%" }} size="small">
				<InputLabel id="demo-select-small">Select Case</InputLabel>
				<Select
					labelId="demo-select-small"
					id="demo-select-small"
					value={age}
					label="Age"
					onChange={(event) => {
						setAge(event.target.value);
						setCaseData(event.target.value);
					}}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{apiDataCases
						? apiDataCases.cases.map((e) => (
								<MenuItem key={e.caseId} value={e.caseId}>
									{e.caseDescription}
								</MenuItem>
						  ))
						: null}
				</Select>
			</FormControl>
		</Div>
	);
};

export default EditorSelect;
