import { getUserProfile } from "app/Apis/Login";
import { useDispatch } from "react-redux";
import {
    defaultAccessToken,
    DefaultEmailVerified,
    DefaultName,
    DefaultPhoneVerified,
    DefaultProfileImage,
    defaultProfileStatus,
    defaultRole,
    DefaultUserEmail,
    DefaultUserMobile,
    DefaultUserName,
    defaultLawyerId,
    defaultUserId
  } from "app/redux/actions/OnboardingInputs";
import Cookies from "js-cookie";
import { LAWYERSPECIALISATION } from "app/utils/constants/LawinzoUpdatedInputs";
import { UserLawyerSpecialisation } from "app/redux/actions/UpdatedInputs";

const useLogin = ({onboarding}) => {
  const dispatch = useDispatch();
  const login = async (accessToken) => {
    let role=""
      if (!accessToken) {
          throw Error("Provide access token to useLogin Hook");
        }
    const response = await getUserProfile(accessToken);
    response?.data.data.roles.map((e) => (role = e.name));
    console.log(role);
    if(role === "ROLE_LAWYER"){
      dispatch(defaultLawyerId(response?.data.data.professionalProfile.lawyerId));
      Cookies.set("lawyerId",response?.data.data.professionalProfile.lawyerId,{expires:1});
      dispatch(UserLawyerSpecialisation(response?.data.data.professionalProfile?.lawyerSpecialisation))
    }
    // Cookies.set("lawinzo",accessToken);
    dispatch(defaultUserId(response?.data?.data?.id))
    dispatch(defaultRole({role,onboarding:onboarding}));
    dispatch(defaultAccessToken(accessToken)); 
    dispatch(defaultProfileStatus(response?.data?.data?.profileStatus));
    dispatch(DefaultUserEmail(response?.data?.data?.email));
    dispatch(DefaultName(response?.data?.data?.fullname));
    dispatch(DefaultUserName(response?.data?.data?.username));
    dispatch(DefaultUserMobile(response?.data?.data?.phone));
    dispatch(DefaultProfileImage(response?.data?.data?.userProfileImageLink));
    dispatch(DefaultEmailVerified(response?.data?.data?.isEmailVerified));
    dispatch(DefaultPhoneVerified(response.data?.data?.isPhoneVerified));
  };
  return login;
};
export default useLogin;
