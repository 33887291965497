import React from "react";
import { Paper, Skeleton } from "@mui/material";

const TeamSkeletons = () => {
  return (
    <Paper sx={{ mb: 2, p: 2 }} elevation={1}>
      <Skeleton />
      <Skeleton animation={"wave"} />
      <Skeleton animation={false} />
    </Paper>
  );
};

export default TeamSkeletons;
