import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";

import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { alpha, Paper, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import ActionsMenu from "./ActionsMenu";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import EditTaskDetails from "./Components/EditTaskForm";
import DisplayDocumentPreview from "app/Components/Documents/DisplayDocuments/DisplayDocumentPreview";
import { formatDate } from "app/utils/dateTimeHelpers";

const priority = ["Low ", "Medium ", "High "];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TaskCard = ({
  taskItem,
  tasksList,
  setTaskEditItem,
  setAddTaskOpen,
  setTaskAdded,
  taskAdded,
}) => {
  const fullName = useSelector((state) => {
    return state?.onboardingInputs?.fullName;
  });
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    if (tasksList.find((task) => task.tasksId === taskItem.tasksId))
      setExpanded(!expanded);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-1px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          m:0,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            m:0,
            padding: "12px 0",
            "&.Mui-expanded": {
              padding: "12px 0",
            },
            "&:hover": {
              cursor: "default",

            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
            "&:hover": {
              cursor: "default",
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>
        <Div
          sx={{
            width: { xs: "auto", lg: "15%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {taskItem.taskName}
          </Typography>
          
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "25%",
            flexShrink: 0,
            px: 2,
          }}
        >
          <Chip
            label={` ${priority[taskItem.priority]}`}
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Case
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            {/* <AccountBalanceIcon color="secondary" />  */}
            <Typography fontSize={"14px"}>
              {taskItem.caseName ? taskItem.caseName : "No Case Attatched"}
            </Typography>
          </Div>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Date Added
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>
              {taskItem.createdDate
                ? formatDate(taskItem.createdDate)
                : formatDate(taskItem.updatedDate)}
            </Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            alignItems: "center",
            width: "10%",
            gap: 2,
          }}
        >
          <Chip
            label={"Open Task"} // change according to api
            color="secondary"
            size="medium"
            sx={{ fontSize: 14, fontWeight: "700" }}
          />
        </Div>
        <Div>
          <ActionsMenu
            handleOpen={handleOpen}
            setTaskEditItem={setTaskEditItem}
            setAddTaskOpen={setAddTaskOpen}
            taskItem={taskItem}
            setTaskAdded={setTaskAdded}
            taskAdded={taskAdded}
          />
        </Div>
        <Div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openModal}>
              <Box sx={style}>
                <EditTaskDetails />
              </Box>
            </Fade>
          </Modal>
        </Div>
      </AccordionSummary>

      {/* down arrow content */}
      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
          Description
        </Typography>

        <Typography variant={"body1"} color={"text.secondary"}>
          {taskItem.taskDescription
            ? taskItem.taskDescription
            : "No description added"}
        </Typography>
        <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
          Documents
        </Typography>
        <DisplayDocumentPreview documentLists={taskItem?.documentLists} />
      </Collapse>
    </Paper>
  );
};
/* Todo item prop define */
export default TaskCard;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));
