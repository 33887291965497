import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  InputBase,
  TextField,
  Typography,
  Link,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div/Div";
import { useLocation } from "react-router-dom";

const HelpPage = () => {
  const openDialer = (event) => {
    event.preventDefault();
    const phoneNumber = event.target.getAttribute('href');
    window.location.href = phoneNumber;
};
const [hide,setHide]=useState(false)
const location=useLocation()
console.log(location)
useEffect(()=>{
if(location.pathname==="/helpline")
{
  setHide(true)
}
},[])
    return (
      <>
      {!hide &&
      
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/home" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          Help & Support
        </Typography>
      </Breadcrumbs>}
      
      <Card
        sx={{
          p: 3,
          height:hide?"100vh":"auto",
          position: "relative",
          paddingLeft:{ xs: "5%", md: "18%" },
          paddingRight:{ xs: "5%", md: "18%" },
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 5,
              }}
            >
              <Typography sx={{display: "flex",
                alignItems: "center",
                justifyContent: "center",}} variant={"h5"}>Help & Support</Typography>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Div>
          
          <img src='/images/vakily-logo-black.png' style={{marginLeft:"45%",marginTop:'15px'}} width="10%" />
          <Typography sx={{color:"black",display:"flex",justifyContent:"center"}} fontSize={"30px"}>Call Us</Typography>
          <Typography sx={{color:"#EABF4E",display:"flex",justifyContent:"center"}} fontSize={"30px"}> <a 
          style={{color:"#EABF4E",textDecoration:"none"}} href="tel:+91-8920766203" onClick={openDialer}>
          +91-8920766203  
            </a> </Typography>
          <Typography sx={{color:"black",display:"flex",justifyContent:"center"}} fontSize={"30px"}>Or</Typography>
          <Typography sx={{color:"black",display:"flex",justifyContent:"center"}} fontSize={"30px"}>Send email to</Typography>
          <Typography sx={{color:"#EABF4E",display:"flex",justifyContent:"center"}} fontSize={"30px"}> <a style={{color:"#EABF4E",textDecoration:"none"}} href="mailto:connect@vakily.app">
          connect@vakily.app 
            </a></Typography>
          </Div>
         
        </Div>
      </Card>
    </>
    );

}

export default HelpPage