import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Breadcrumbs,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Divider,
  Link,
  IconButton,
  Button,
  Checkbox,
  CircularProgress,
  Switch,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { AppointmentValidations } from "app/utils/validators";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import useSettings from "app/hooks/useSettings";
import { useSelector } from "react-redux";
import AddAddress from "app/Components/Address/addAddress";
import { getPeopleList } from "app/Apis/people";
import { getCasesList } from "app/Apis/task";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddSingleDocument from "app/Components/Documents/SingleDocumentUpload/AddSingleDocument";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getCourt } from "app/Apis/User";
import { addJudgement } from "app/Apis/Judgements";
import * as Yup from "yup";
import { addPublication } from "app/Apis/Publications";

const addJudgementValidationSchema = Yup.object().shape({
  caseName: Yup.string().required("Case Name is required").nullable(),
  sections: Yup.array().required("Sections are required").nullable(),
  court: Yup.object().required("Court is required").nullable(),
  judgeName: Yup.string().required("Judge Name is required").nullable(),
  dateOfDecision: Yup.date().required("Date is Required").nullable(),
  description: Yup.string().required("Description is required").nullable(),
  type: Yup.string().required("Type is required").nullable(),
});

const type = ["Judgement", "Article", "Blog", "Announcement","FAQ","News And Update"];
const languageOptions = ["English", "Hindi"];

const AddJudgement = ({
  setAddJudgement,
  setShowUpdate,
  judgementEdit,
  setJudgementEdit,
}) => {
  const nav = useNavigate();
  const [documentsArray, setDocumentsArray] = useState(null);
  const [documentsAPIList, setDocumentsAPIList] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [underSections, setUnderSections] = useState([]);
  const [courtOptions, setCourtOptions] = useState([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [addDocument, setAddDocument] = useState(judgementEdit ? false : true);

  useEffect(() => {
    const fetchUnderSections = async () => {
      try {
        const response = await fetch("https://erp.lawinzo.com/api/v1/section", {
          headers: {
            "x-api-key": "449772DE-2780-4412-B9F7-E49E48605875",
          },
        });
        const data = await response.json();
        if (data.success) {
          setUnderSections(data.data.mSectionRecords);
        }
      } catch (error) {
        console.error("Failed to fetch under sections:", error);
      }
    };

    fetchUnderSections();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const courts = await getCourt();
      setCourtOptions(courts);
    };

    fetchData();
  }, []);

  const findSections = (sections) => {
    try {
      let indexes = [];
      let fullSections = [];
      if (sections && sections.length > 0) {
        sections.map((section) => {
          const foundIndex = underSections.findIndex(
            (all) => all.sectionName === section
          );
          if (foundIndex !== -1) {
            indexes.push(foundIndex);
          }
        });
        indexes.map((index) => {
          fullSections.push(underSections[index]);
        });
        return fullSections;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const findCourt = (court) => {
    if (court && courtOptions && courtOptions.length > 0) {
      const index = courtOptions.findIndex(
        (courtObject) => courtObject.courtName === court
      );
      // console.log(index);
      if (index !== -1) {
        // console.log(courtOptions[index]);
        return courtOptions[index];
      }
    }
    return null;
  };
  const convertDate = useMemo(() => {
    if (judgementEdit?.dateOfDecision) {
      const dateArray = judgementEdit?.dateOfDecision.split("/");
      console.log(dateArray, `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`);
      return dayjs(`${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`);
    }
    return judgementEdit?.dateOfDecision;
  }, [judgementEdit]);
  console.log(convertDate);
  console.log(judgementEdit);

  const formik = useFormik({
    initialValues: {
      caseName: judgementEdit?.caseName ? judgementEdit?.caseName : "",
      sections: judgementEdit?.sections
        ? findSections(judgementEdit?.sections)
        : [],
      court: judgementEdit?.court ? findCourt(judgementEdit?.court) : null,
      judgeName: judgementEdit?.judge ? judgementEdit?.judge : "",
      dateOfDecision: judgementEdit?.dateOfDecision ? convertDate : null,
      description: judgementEdit?.judgmentDetails
        ? judgementEdit?.judgmentDetails
        : "",
      facts: judgementEdit?.facts ? judgementEdit?.facts : "",
      historyOfCase: judgementEdit?.historyOfCase
        ? judgementEdit?.historyOfCase
        : "",
      issues: judgementEdit?.issues ? judgementEdit?.issues : "",
      lawActsApplied: judgementEdit?.lawActsApplied
        ? judgementEdit?.lawActsApplied
        : "",
      bindingLawToFacts: judgementEdit?.bindingLawToFacts
        ? judgementEdit?.bindingLawToFacts
        : "",
      ratioOfCase: judgementEdit?.ratioOfCase ? judgementEdit?.ratioOfCase : "",
      conclusionRuling: judgementEdit?.conclusionRuling
        ? judgementEdit?.conclusionRuling
        : "",
      type: judgementEdit?.type ? judgementEdit?.type : "Judgement",
      language: judgementEdit?.language ? judgementEdit?.language : "English",
    },
    validationSchema: addJudgementValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmiting(true);
      console.log(values);
      console.log(documentsAPIList, documentFile);
      const section = values?.sections.map((section) => section?.sectionName);
      try {
        let body = {
          caseName: values?.caseName,
          sections: section.join(","),
          court: values?.court?.courtName,
          // dateofDecision: values?.dateOfDecision.format("DD/MM/YYYY"),
          judge: values?.judgeName,
          judgmentDetails: values?.description,
          facts: values?.facts,
          historyOfCase: values?.historyOfCase,
          issues: values?.issues,
          lawActsApplied: values?.lawActsApplied,
          bindingLawToFacts: values?.bindingLawToFacts,
          ratioOfCase: values?.ratioOfCase,
          conclusionRuling: values?.conclusionRuling,
          type: values?.type,
          language: values?.language,
        };
        if (judgementEdit) {
          body = {
            ...body,
            judgementId: judgementEdit?.judgmentId,
          };
        }
        let response = null;
        if (values?.type === "Judgement") {
          body = {
            ...body,
            dateofDecision: values?.dateOfDecision.format("DD/MM/YYYY"),
          };
          response = await addJudgement(body, documentFile);
        } else {
          body = {
            ...body,
            sections: values?.sections.map((section) => section.sectionName),
            rawDocumentUrls: documentUrl,
            dateOfDecision: values?.dateOfDecision.format("DD/MM/YYYY"),
          };
          response = await addPublication(body, values?.type);
        }
        console.log(response);
        if (response) {
          setIsSubmiting(false);
          console.log("judgement added");
          setAddJudgement(false);
          setShowUpdate((prev) => !prev);
          setJudgementEdit(null);
        } else {
          throw Error("error in adding judgement");
        }
        setIsSubmiting(false);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <>
      <Div sx={{ height: "100%", width: "100%" }}>
        <Div
          sx={{
            pl: { sm: "0", lg: "11%" },
            pr: { sm: "0", lg: "11%" },
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Div sx={{ width: "110%" }}>
            <div
              style={{
                height: "100%",
                width: "100%",
                paddingBottom: "30px",
              }}
            >
              <Div
                sx={{
                  marginLeft: { xs: "5%", md: "18%" },
                  marginRight: { xs: "5%", md: "18%" },
                  marginTop: "20px",
                  height: "100%",
                }}
              >
                <Typography variant="h6" sx={{ mt: "0px" }}>
                  {judgementEdit ? "Edit Judgement" : "Add Judgement"}
                </Typography>

                <Autocomplete
                  sx={{ width: "100%", mb: 0, mt: 2 }}
                  onChange={(e, v) => {
                    formik.setFieldValue("language", v);
                  }}
                  name="language"
                  value={formik.values.language}
                  options={languageOptions}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="secondary"
                      label="Select Language*"
                      placeholder="Select Language*"
                      error={formik.errors.language && formik.touched.language}
                      helperText={
                        formik.errors.language && formik.touched.language
                          ? formik.errors.language
                          : null
                      }
                      name="language"
                    />
                  )}
                />

                <Autocomplete
                  sx={{ width: "100%", mb: 0, mt: 2 }}
                  onChange={(e, v) => {
                    formik.setFieldValue("type", v);
                  }}
                  disabled
                  name="type"
                  value={formik.values.type}
                  options={type}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="secondary"
                      label="Select Type*"
                      placeholder="Select Type*"
                      error={formik.errors.type && formik.touched.type}
                      helperText={
                        formik.errors.type && formik.touched.type
                          ? formik.errors.type
                          : null
                      }
                      name="type"
                    />
                  )}
                />

                <TextField
                  sx={{ mt: 2, width: "100%" }}
                  id="outlined-basic"
                  label={
                    formik.values.type === "Judgement" ? "Case Name" : "Title"
                  }
                  color="secondary"
                  variant="outlined"
                  required
                  value={formik.values.caseName}
                  onChange={formik.handleChange}
                  error={formik.errors.caseName && formik.touched.caseName}
                  helperText={
                    formik.errors.caseName && formik.touched.caseName
                      ? formik.errors.caseName
                      : null
                  }
                  name="caseName"
                />

                <Autocomplete
                  sx={{ width: "100%", mb: 2 }}
                  multiple
                  onChange={(e, v) => {
                    formik.setFieldValue("sections", v);
                  }}
                  name="underSection"
                  disableCloseOnSelect
                  value={formik.values.sections}
                  isOptionEqualToValue={(option, value) =>
                    option.sectionId === value.sectionId
                  }
                  options={underSections}
                  getOptionLabel={(option) =>
                    option.hasOwnProperty("sectionName")
                      ? option.sectionName
                      : ""
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.hasOwnProperty("sectionName")
                        ? option.sectionName
                        : ""}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      sx={{ mb: 0, mt: 2 }}
                      {...params}
                      color="secondary"
                      label="Select Under Section"
                      placeholder="Select Under Section"
                      required
                      error={formik.errors.sections && formik.touched.sections}
                      helperText={
                        formik.errors.sections && formik.touched.sections
                          ? formik.errors.sections
                          : null
                      }
                      name="underSection"
                    />
                  )}
                />

                <Autocomplete
                  style={{ width: "100%", marginBottom: 17 }}
                  value={formik.values.court}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("court", newValue);
                  }}
                  id="controllable-states-demo"
                  options={courtOptions}
                  sx={{ width: 500 }}
                  getOptionLabel={(option) => option.courtName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option.courtName}</li>
                  )}
                  isOptionEqualToValue={(o, v) => o.courtsId === v.courtsId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="secondary"
                      label="Court"
                      placeholder="Court"
                      required
                      name="court"
                      error={formik.errors.court && formik.touched.court}
                      helperText={
                        formik.errors.court && formik.touched.court
                          ? formik.errors.court
                          : null
                      }
                    />
                  )}
                />

                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-basic"
                  label="Judge Name"
                  color="secondary"
                  variant="outlined"
                  value={formik.values.judgeName}
                  onChange={formik.handleChange}
                  required
                  error={formik.errors.judgeName && formik.touched.judgeName}
                  helperText={
                    formik.errors.judgeName && formik.touched.judgeName
                      ? formik.errors.judgeName
                      : null
                  }
                  name="judgeName"
                />

                {/* <BasicDatePickerComponent style={{ mb: 0, mt: 2 }} /> */}
                <Div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Decision"
                      format="DD/MM/YYYY"
                      value={formik.values.dateOfDecision}
                      onChange={(newValue) => {
                        formik.setFieldValue("dateOfDecision", newValue);
                      }}
                      disableFuture
                      sx={{ width: "100%", mb: 2 }}
                      // renderInput={(params) => (
                      //   <TextField
                      //     {...params}
                      //     fullWidth
                      //     name="date"
                      //     color="secondary"
                      //     required
                      //     error={
                      //       formik.errors.dateOfDecision &&
                      //       formik.touched.dateOfDecision
                      //     }
                      //     helperText={
                      //       formik.errors.dateOfDecision &&
                      //       formik.touched.dateOfDecision
                      //         ? formik.errors.dateOfDecision
                      //         : null
                      //     }
                      //   />
                      // )}
                      slotProps={{
                        textField:{
                          fullWidth:true,
                          name:"date",
                          color:"secondary",
                          required:true,
                          error:formik.errors.dateOfDecision && formik.touched.dateOfDecision,
                          helperText:formik.errors.dateOfDecision &&
                                formik.touched.dateOfDecision
                                  ? formik.errors.dateOfDecision
                                  : null,
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Div>

                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-basic"
                  label="Facts"
                  color="secondary"
                  variant="outlined"
                  value={formik.values.facts}
                  rows={4}
                  multiline
                  onChange={formik.handleChange}
                  error={formik.errors.facts && formik.touched.facts}
                  helperText={
                    formik.errors.facts && formik.touched.facts
                      ? formik.errors.facts
                      : null
                  }
                  name="facts"
                />
                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-basic"
                  label="History Of Case"
                  color="secondary"
                  variant="outlined"
                  rows={4}
                  multiline
                  value={formik.values.historyOfCase}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.historyOfCase && formik.touched.historyOfCase
                  }
                  helperText={
                    formik.errors.historyOfCase && formik.touched.historyOfCase
                      ? formik.errors.historyOfCase
                      : null
                  }
                  name="historyOfCase"
                />
                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-basic"
                  label="Issues"
                  color="secondary"
                  variant="outlined"
                  rows={4}
                  multiline
                  value={formik.values.issues}
                  onChange={formik.handleChange}
                  error={formik.errors.issues && formik.touched.issues}
                  helperText={
                    formik.errors.issues && formik.touched.issues
                      ? formik.errors.issues
                      : null
                  }
                  name="issues"
                />
                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-basic"
                  label="Law Acts Applied"
                  color="secondary"
                  variant="outlined"
                  rows={4}
                  multiline
                  value={formik.values.lawActsApplied}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.lawActsApplied &&
                    formik.touched.lawActsApplied
                  }
                  helperText={
                    formik.errors.lawActsApplied &&
                    formik.touched.lawActsApplied
                      ? formik.errors.lawActsApplied
                      : null
                  }
                  name="lawActsApplied"
                />
                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-basic"
                  label="Binding Law To Facts"
                  color="secondary"
                  variant="outlined"
                  rows={4}
                  multiline
                  value={formik.values.bindingLawToFacts}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.bindingLawToFacts &&
                    formik.touched.bindingLawToFacts
                  }
                  helperText={
                    formik.errors.bindingLawToFacts &&
                    formik.touched.bindingLawToFacts
                      ? formik.errors.bindingLawToFacts
                      : null
                  }
                  name="bindingLawToFacts"
                />
                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-basic"
                  label="Ratio Of Case"
                  color="secondary"
                  variant="outlined"
                  rows={4}
                  multiline
                  value={formik.values.ratioOfCase}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.ratioOfCase && formik.touched.ratioOfCase
                  }
                  helperText={
                    formik.errors.ratioOfCase && formik.touched.ratioOfCase
                      ? formik.errors.ratioOfCase
                      : null
                  }
                  name="ratioOfCase"
                />
                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-basic"
                  label="Conclusion Ruling"
                  color="secondary"
                  variant="outlined"
                  rows={4}
                  multiline
                  value={formik.values.conclusionRuling}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.conclusionRuling &&
                    formik.touched.conclusionRuling
                  }
                  helperText={
                    formik.errors.conclusionRuling &&
                    formik.touched.conclusionRuling
                      ? formik.errors.conclusionRuling
                      : null
                  }
                  name="conclusionRuling"
                />

                <TextField
                  sx={{ mb: 2, width: "100%" }}
                  id="outlined-multiline-static"
                  label="Judgement  Description..."
                  multiline
                  color="secondary"
                  rows={4}
                  required
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  error={
                    formik.errors.description && formik.touched.description
                  }
                  helperText={
                    formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : null
                  }
                  name="description"
                />
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6">
                    {judgementEdit
                      ? formik.values.type === "Judgement"
                        ? "Edit Judgement"
                        : "Edit Banner Image"
                      : formik.values.type === "Judgement"
                      ? "Upload Judgement"
                      : "Upload Banner Image"}{" "}
                    <Typography sx={{ display: "inline-block" }}>
                      (You can upload only single file of max size 25MB)
                    </Typography>
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={addDocument}
                          onChange={() => setAddDocument((prev) => !prev)}
                          color="secondary"
                        />
                      }
                      label={addDocument ? "Yes" : "No"}
                    />
                  </div>
                </Div>
                {/* {formik.values.type==="Judgement" && ( */}
                {addDocument && (
                  <AddSingleDocument
                    documentsArray={documentsArray}
                    setDocumentsArray={setDocumentsArray}
                    documentsAPIList={documentsAPIList}
                    setDocumentsAPIList={setDocumentsAPIList}
                    setDocumentFile={setDocumentFile}
                    setDocumentUrl={setDocumentUrl}
                    type={formik.values.type}
                    fromEnquiry={true}
                  />
                )}

                {/*  )} */}
                <Div
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 5,
                  }}
                >
                  <LoadingButton
                    onClick={() => {
                      if (judgementEdit) {
                        setJudgementEdit(null);
                      }
                      setAddJudgement(false);
                    }}
                    // disabled={true}
                    variant="contained"
                    sx={{ width: 100, padding: 1 }}
                  >
                    Back
                  </LoadingButton>
                  <LoadingButton
                    disabled={isSubmiting}
                    onClick={formik.handleSubmit}
                    color="secondary"
                    sx={{ width: 100, padding: 1, backgroundColor: "black" }}
                    variant="contained"
                  >
                    {isSubmiting ? (
                      <>
                        <CircularProgress
                          size="1.2rem"
                          sx={{ color: "white", mr: "5px" }}
                        />
                        {"Submit"}
                      </>
                    ) : (
                      "Submit"
                    )}
                  </LoadingButton>
                </Div>
              </Div>
            </div>
          </Div>
        </Div>
      </Div>
    </>
  );
};
export default AddJudgement;
