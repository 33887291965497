import Div from "@jumbo/shared/Div";
import React from "react";
import { NoCaseContainer } from "app/pages/MyCases/style";
// import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import NoCaseImage from "app/ImageAssets/NoCaseImage.png";
import { Button, Typography } from "@mui/material";
import NoDataDisplay from "app/Components/Dashboard/Widgets/NoDataDisplay/NoDataDisplay";
import { LoadingButton } from "@mui/lab";


const NoTaskAdded = ({setAddTaskOpen,formDisable,handleBackButton,handleNextButton})=>{
    return (
      <>
      {formDisable?
     <div style={{ height: "100%", width: "100%", paddingBottom: "30px" }}>
     <Div
       sx={{
         marginLeft: { xs: "5%", md: "18%" },
         marginRight: { xs: "5%", md: "18%" },
         marginTop: "20px",
         height: "100%",
       }}
     > 
     
       <NoDataDisplay
       formDisable={formDisable}
       text={"No Task"}
       url={""}
       buttonText={"Add Hearing"}
       minHeight={"205px"}
     />
    
    
      <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            variant="contained"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={handleNextButton}
            color="secondary"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
       </Div>
     </div>:    <NoCaseContainer>
    <Div
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Div
        sx={{ width: { xs: "50%", sm: "30%", md: "150px" }, height: "30%" }}
      >
        <img
          src={NoCaseImage}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          alt=""
        />
      </Div>

      <Typography
        sx={{
          fontSize: { xs: "18px", md: "24px" },
          textAlign: "center",
          mt: 2,
        }}
      >
        You haven't added any Task yet!
      </Typography>
      <Div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: 4,
        }}
      >
        {/* <Link to="/lawyers/addcases" style={{ textDecoration: "none" }}> */}
        <Button
        disabled={formDisable}
          variant="contained"
          sx={{ backgroundColor: "secondary.main", mb: 2, width: "150px" }}
          onClick={() => setAddTaskOpen(true)}
        >
          Add Task 
        </Button>
        {/* </Link> */}
        {/* <Typography sx={{ mb: 2 }}>Or</Typography>
        <Typography
          sx={{
            fontSize: { xs: "18px", md: "20px" },
            color: "secondary.main",
            fontWeight: 500,
          }}
        >
          Import Data
        </Typography> */}
      </Div>
    </Div>
  </NoCaseContainer>
    }
      
      </>
      
    );
};
export default NoTaskAdded;