import * as Yup from "yup";

export const AddressValidations = Yup.object().shape({
  addressLabel: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid Label")
    .required("Label is required!"),
  country: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid Country")
    .required("Address is required!"),
  completeAddress: Yup.string()
    .matches(/^[A-Za-z0-9\s,-]+$/, "Please enter valid Address")
    .required("Complete address is required"),
  landMark: Yup.string()
    .matches(/^[A-Za-z0-9\s,"&-]+$/, "Please enter valid Landmark"),
    
  streetName: Yup.string()
    .matches(/^[A-Za-z0-9\s,"&-]+$/, "Please enter valid Street Name"),
    
});
export const BankValidations = Yup.object().shape({
  payeeName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid Payee Name")
    .required("Payee Name is required!"),
  ifscCode: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Please enter valid IFSC Code.")
    .required("IFSC Code is required!"),
  branchName: Yup.string()
    .matches(/^[a-zA-Z0-9\s.-]+$/, "Please enter valid Branch Name")
    .required("Branch Name is required!"),
  bankName: Yup.string()
    .matches(/^[a-zA-Z0-9\s.&'-]+$/, "Please enter valid BankName")
    .required("Bank Name is required!"),
  branchAddress: Yup.string()
    .matches(/^[a-zA-Z0-9\s.,'-]+$/, "Please enter valid Branch Address")
    .required("Branch Address is required!"),
    bankAccountNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Bank account number must contain only numbers')
    .min(9, 'Bank account number must have at least 9 digits')
    .required('Bank account number is required'),
  confirmBankAccountNumber: Yup.string()
    .oneOf([Yup.ref('bankAccountNumber')], 'Bank account numbers must match')
    .required('Confirm bank account number is required')
    .when('bankAccountNumber', {
      is: (val) => val && val.length > 0,
      then: Yup.string().required('Confirm bank account number is required'),
    }),

});
export const UPIValidations = Yup.object().shape({
  upiId: Yup.string()
    .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+$/, "Please enter valid UPI Id ")
    .required("UPI Id is required!"),
});

export const formValidationSchema = Yup.object().shape({
  openingTime: Yup.string().required("Opening time is required"),
  closingTime: Yup.string().required("Closing time is required"),
  repetitionFor: Yup.string().required("Repetition for is required"),
});

export const budgetValidations = Yup.object().shape({
  directConsultingFee: Yup.number()
    .typeError("Must be of number type")
    .when("intrestedDirectConsulting", {
      is: true,
      then: Yup.number()
        .typeError("Must be of number type")
        .required("In-Person Rate is required")
        .min(0.01, "Rate must be greater than 0"),
      otherwise: Yup.number().typeError("Must be of number type"),
    }),
  emailConsultingFee: Yup.number()
    .typeError("Must be of number type")
    .when("intrestedEmailConsulting", {
      is: true,
      then: Yup.number()
        .typeError("Must be of number type")
        .required("Email Consulting Rate is required")
        .min(0.01, "Rate must be greater than 0"),
      otherwise: Yup.number().typeError("Must be of number type"),
    }),
  videoConsultingFee: Yup.number()
    .typeError("Must be of number type")
    .when("intrestedVideoConsulting", {
      is: true,
      then: Yup.number()
        .typeError("Must be of number type")
        .required("Video Consulting Rate is required")
        .min(0.01, "Rate must be greater than 0"),
      otherwise: Yup.number().typeError("Must be of number type"),
    }),
  phoneConsultingFee: Yup.number()
    .typeError("Must be of number type")
    .when("intrestedPhoneConsulting", {
      is: true,
      then: Yup.number()
        .typeError("Must be of number type")
        .required("Phone Consulting Rate is required")
        .min(0.01, "Rate must be greater than 0"),
      otherwise: Yup.number().typeError("Must be of number type"),
    }),
  monthlyBillRate: Yup.number()
    .typeError("Must be of number type")
    .when("intrestedOtherConsulting", {
      is: true,
      then: Yup.number().typeError("Must be of number type").min(0.01, "Rate must be greater than 0"),
      otherwise: Yup.number().typeError("Must be of number type")
      .min(0.01, "Rate must be greater than 0"),
    }),
  timeBasedBillRate: Yup.number()
    .typeError("Must be of number type")
    .when("intrestedOtherConsulting", {
      is: true,
      then: Yup.number().typeError("Must be of number type").min(0.01, "Rate must be greater than 0"),
      otherwise: Yup.number().typeError("Must be of number type")
      .min(0.01, "Rate must be greater than 0"),
    }),

  caseBasedBillRate: Yup.number()
    .typeError("Must be of number type")
    .when("intrestedOtherConsulting", {
      is: true,
      then: Yup.number().typeError("Must be of number type").min(0.01, "Rate must be greater than 0"),
      otherwise: Yup.number().typeError("Must be of number type")
      .min(0.01, "Rate must be greater than 0"),
    }),
});