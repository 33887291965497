import React from "react";
import { Card, Paper, Skeleton } from "@mui/material";
import Div from "@jumbo/shared/Div";

const Skeletons = () => {
  return (
    <Div>
          <Paper sx={{mb:2 ,p: 2, height:"150px" }}>
            <Skeleton />
            <Skeleton animation={"wave"} />
            <Skeleton animation={false} />
            <Skeleton />
            <Skeleton animation={"wave"} />
            <Skeleton animation={false} />
          </Paper>
    </Div>
  );
};

export default Skeletons;
