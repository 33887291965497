import {
  UPDATE_CASE_LIST,
  UPDATE_CASE_LIST_ITEM,
} from "../../utils/constants/case";

export const updateCaseList = (caseList) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CASE_LIST, payload: caseList });
  };
};

export const updateCaseListItem = (caseItem) => {
  console.log(caseItem);
  return {
    type: UPDATE_CASE_LIST_ITEM,
    payload: caseItem,
  };
};
