import { getPeopleList } from "app/Apis/people";

const usePeople = () => {
  const getAllPeople = async (pageNumber=0,pageSize=5,peopleType="Client") => {
    const response = await getPeopleList(pageNumber,pageSize,peopleType);
    if (response) {
      return response;
    } 
      return null;
  };
  return getAllPeople;
};
export default usePeople;
