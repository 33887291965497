import { ChatBubble } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ChatBot from "./ChatBot";
import { useLocation } from "react-router-dom";

function ChatbotWeb() {
	const [openChatbot, setopenChatbot] = useState(false);
	console.log(openChatbot);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	useEffect(() => {
		setopenChatbot(queryParams.get("open") === "true");
	}, []);

	console.log(typeof openChatbot);
	return (
		<div style={{ backgroundColor: "transparent" }}>
			<div
				style={{
					display: openChatbot ? "inline" : "none",
					position: "fixed",
					height: "100%",
					zIndex: "1000",
					borderRadius: "15px",
					backgroundColor: "transparent",
				}}
			>
				<ChatBot setopenChatbot={setopenChatbot} />
			</div>
		</div>
	);
}

export default ChatbotWeb;
