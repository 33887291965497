import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import { deleteTodoTask } from "app/Apis/task";
import { Box, Button, Modal, Typography } from "@mui/material";
const options = ["Edit"];

const ITEM_HEIGHT = 48;

export default function ActionsMenu({ handleEdit }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [option, setOption] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDotClick = async (option) => {
    if (option === "Edit") {
      handleClose();
      handleEdit();
    }
    // else if (option === "Delete") {
    // //   handleClose();
    // //   const response = await deleteTodoTask(accessToken, taskItem);
    // //   if (response) {
    // //     console.log(response, "deleted successfully");
    // //     setTaskAdded(!taskAdded);
    // //   } else {
    // //     console.log("error deleting");
    // //   }
    // }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => {
              setOption(option);
              handleDotClick(option);
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            minWidth: 300,
            maxWidth: 500,
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" id="delete-modal-title" gutterBottom>
            Are you sure you want to delete this Task?
          </Typography>
          <Typography variant="body1" id="delete-modal-description">
            This action cannot be undone.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenModal(false);
                handleDotClick(option);
              }}
              sx={{ mr: 2 }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
