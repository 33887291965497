import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import lawyerImage from "app/ImageAssets/lawyer.png";
import { useSelector } from "react-redux";
import Div from "@jumbo/shared/Div/Div";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import Span from "@jumbo/shared/Span/Span";
import { Edit } from "@mui/icons-material";

function ProfileDash() {
  const profileImage = useSelector(
    (state) => state?.onboardingInputs?.profileImage
  );
  const fullName = useSelector((state) => state?.onboardingInputs?.fullName);
  const mobileNumber = useSelector(
    (state) => state?.onboardingInputs?.mobileNumber
  );
  const specialisation = useSelector((state)=>state?.updateInputs?.lawyerSpecialisation);
  console.log(specialisation)
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/lawyers/settings");
  };
  return (
    <Card
      sx={{
        backgroundColor: "#151513",
        color: "white",
        display: "flex",
        flexDirection: { xs: "row", sm: "column" },
        height:"100%"
      }}
      elevation={12}
    >
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom:"0rem"
          //   borderBottom:"1px solid white",
        }}
        avatar={
          <Div sx={{ position: "relative" }}>
            <Avatar
              sx={{
                width: { sm: 50, lg: 62 },
                height: { sm: 50, lg: 62 },
                borderRadius: "50%",
                border: "2px solid #EABF4E",
                ml: "17px",
              }}
              alt={"profile Image"}
              src={profileImage ? profileImage : lawyerImage}
              variant="rounded"
            />
            <Span
              sx={{
                position: "absolute",
                borderRadius: "50%",
                top: "-6px",
                right: "-4px",
                backgroundColor: "transparent",
                width: "25px",
                height: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "#EABF4E",
                },
              }}
            >
              <Edit
                onClick={handleClick}
                sx={{
                  color: "#FFF",
                  fontSize: {xs:"14px",sm:"18px"},
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Span>
          </Div>
        }
        title={<Typography sx={{ fontSize: "0.7rem",mb:{xs:1,sm:0},width:"max-content",mt:1}}>{specialisation.length>0 ? specialisation?.[0]?.specialityName:"Specialisation"}</Typography>}
      />
      <CardContent
        sx={{
          p:1,
          mb:{xs:"-24px",sm:0},
          display: "flex",
          flexDirection: "column",
          justifyContent:{xs:"space-evenly",sm:"space-evenly"},
          gap: 1,
          flex:{xs:1,sm:"0 1 auto"},
          mr:{xs:2,sm:0}, 
        }}
      >
        <Div sx={{ display: "flex", justifyContent: "space-between"}}>
          <Typography sx={{ fontSize: {xs:"14px",sm:"9px",lg:"9px",xl:"0.70rem"}, fontWeight: 500 }}>
            Name
          </Typography>
          <Typography sx={{ fontSize: {xs:"14px",sm:"9px",lg:"9px",xl:"0.70rem"}, fontWeight: 500 }}>
            {fullName}
          </Typography>
        </Div>
        <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: {xs:"14px",sm:"9px",lg:"9px",xl:"0.70rem"}, fontWeight: 500 }}>
            Phone
          </Typography>
          <Typography sx={{ fontSize: {xs:"14px",sm:"9px",lg:"9px",xl:"0.70rem"}, fontWeight: 500 }}>
            {mobileNumber}
          </Typography>
        </Div>
        {/* <Div
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleClick}
        >
          <Typography
            sx={{
              fontSize: "0.75rem",
              fontWeight: 600,
              mr: "3px",
            }}
          >
            Profile
          </Typography>
          <EastIcon style={{ fontSize: "11px" }} />
        </Div> */}
      </CardContent>
    </Card>
  );
}

export default ProfileDash;
