import React from "react";
import { useState } from "react";
import Div from "@jumbo/shared/Div";
import AddDocumentForm from "./AddDocumentForm";

function SaveDocuments({
	documentsAPIList,
	setDocumentsAPIList,
	setDocumentSaveAPI,
	handleClose,
	documentsArray,
}) {
	const [currentPage, setCurrentPage] = useState(0);
	const handleNext = () => {
		setCurrentPage(currentPage + 1);
	};

	return (
		<>
			{documentsArray && (
				<>
					<Div>
						<Div>
							{documentsArray[currentPage]?.type === "application/pdf" ||
							documentsArray[currentPage]?.type ===
								"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
								<iframe
									src={URL.createObjectURL(documentsArray[currentPage])}
									title="PDF Preview"
									style={{ width: "100%", height: "300px" }}
								/>
							) : documentsArray[currentPage]?.type.startsWith("image/") ? (
								<img
									style={{
										width: "154px",
										height: "150px",
										borderRadius: "5px",
										objectFit: "contain",
									}}
									src={URL.createObjectURL(documentsArray[currentPage])}
									alt="selected image"
								/>
							) : (
								<Div>No preview Available</Div>
							)}
						</Div>
						<Div>
							<AddDocumentForm
								documentsArray={documentsArray}
								handleClose={handleClose}
								setDocumentSaveAPI={setDocumentSaveAPI}
								setDocumentsAPIList={setDocumentsAPIList}
								documentsAPIList={documentsAPIList}
								handleNext={handleNext}
								currentPage={currentPage}
							/>
						</Div>
					</Div>
				</>
			)}
		</>
	);
}

export default SaveDocuments;
