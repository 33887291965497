import React from "react";
import SalesReportChart1 from "./SalesReportChart1";
import { Chip, Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import TrafficAnalysisChart from "../TrafficAnalysis/TrafficAnalysisChart";

const SalesReport1 = () => {
  const { t } = useTranslation();
  return (
    <JumboCardQuick
      title={<Typography variant={"h5"} fontSize="20.5px">{"Weekly Report"}</Typography>}
      wrapperSx={{ pt: 0 ,}}
    >
        <TrafficAnalysisChart />
      
     
    </JumboCardQuick>
  );
};

export default SalesReport1;
