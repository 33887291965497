import * as React from "react";
import { useState,useEffect } from "react";
import { Alert, Autocomplete, Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Button, Typography,InputAdornment } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { paymentRequest } from "app/Apis/Payment";
import { getCases } from "app/Apis/case";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";



const paymentValidation = Yup.object().shape({
  case : Yup.object().required("Case is Required").nullable(),
  amount: Yup.string()
  .matches(/^(?=\D*\d)[a-zA-Z0-9 -]+$/
  , {
    message: "Please enter valid amount.",
    excludeEmptyString: false,
  })
  .required("Amount is required"),
  payment_type : Yup.object().required("Payment Type Is Required").nullable(),
  client: Yup.object().required("Client is required").nullable()
})

const payment_type_options = [
  {
    label:"Daily Cases Fee",
    value:"DAILY_CASES_FEE"
  },
  {
    label:"Bail Filing Fee",
    value:"BAIL_FILING_FEE"
  },
  {
    label:"Appointment Fee",
    value:"APPOINTMENT_FEE"
  },
  {
    label:"Enquiry Fee",
    value:"ENQUIRY_FEE"
  },
  {
    label:"Other Court Fee",
    value:"OTHER_COURT_FEE"
  },
  {
    label:"Certified Copy Fee",
    value:"CERTIFIED_COPY_FEE"
  },
];
const RequestForm = ({ setAddHearing, caseName ,showUpdate,setShowUpdate}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isBelow768px = useMediaQuery((theme) => theme.breakpoints.down(768));

  const accessToken = Cookies.get("accessToken");
  const userId = useSelector((state)=>state?.onboardingInputs?.lawyerId);

  const [openCase, setOpenCase] = useState(false);
  const [caseOptions, setCaseOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  // const [paymentStatus,setPaymentStatus]=useState(false)
  const [snackBarOpen,setSnackBarOpen]=useState(false)
  const [btnLoading,setBtnLoading]=useState(false)
  const loading = openCase && caseOptions.length === 0;

  const handleSnackbarClose=()=>{
    setSnackBarOpen(false)
  }
  //case dropdown open
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await getCases(accessToken,0,100,1);
      console.log(response);
      if (active) {
        setCaseOptions(response);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  React.useEffect(() => {
    if (!openCase) {
      setCaseOptions([]);
    }
  }, [openCase]);

  const [amount,setAmount] = useState("");
  const [paymentType,setPaymentType] = useState(null);
  const [paymentDescription,setPaymentDescription] = useState("");
  

  const formik = useFormik({
    initialValues:{
      amount: amount || "",
      payment_type: paymentType?.value || null,
      paymentDescription:paymentDescription||"",
      client:null,
      case:null,
    },
    validationSchema: paymentValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setBtnLoading(true)
      console.log(values, "FORMIK");
      try{
        const body = {
          amount:values.amount,
          caseId:values?.case.caseId,
          userId: values?.client?.user?.id,
          payment_type: values?.payment_type?.value,
          payment_mode:"ONLINE",
          paymentDescription:values?.paymentDescription,
          requester:"cloud",
      }
        const response = await paymentRequest(body);
        if(response){
          setBtnLoading(false)
          console.log("request generated successfully");
          setSnackBarOpen(true)
          setShowUpdate((prev)=>!prev)
          setPaymentType(null)
          formik.resetForm()
          console.log(formik.values)
        }
      }catch(err){
        console.log(err);
      }

    },
  });

  useEffect(()=>{
    console.log(formik.values.case);
    if(formik.values.case){
      if(formik.values.case.clients){
      setClientOptions(formik.values.case.clients);
      formik.setFieldValue("client",formik.values.case.clients[0]);
      }
    }else{
      setClientOptions([]);
    }
  },[formik.values.case])



  return (
    <Div
      style={{
        height: "100%",
        width: "100%",
      }}
    > {snackBarOpen &&
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackBarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10}}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px"}}
          >
            <Typography variant="h6">
           Payment Request Send successfully
            </Typography>
          </Alert>
        </Snackbar>}
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "5%" },
          marginRight: { xs: "5%", md: "5%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        <Div>
          <Autocomplete
            style={{ width: "100%" }}
            value={formik.values.case}
            onChange={(e,v)=>formik.setFieldValue("case",v)}
            open={openCase}
            onOpen={() => {
              setOpenCase(true);
            }}
            onClose={() => {
              setOpenCase(false);
            }}
            loading={loading}
            options={caseOptions}
            isOptionEqualToValue={(option,value)=>option?.caseId===value?.caseId}
            getOptionLabel={(option) => `${option?.caseFirstName} vs ${option?.caseSecondName}`}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option?.caseFirstName} vs {option?.caseSecondName}</li>
            )}
            sx={{ width: 500, mt: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Case"
                placeholder="Case"
                color="secondary"
                name="case"
                error={formik.errors.case && formik.touched.case}
                helperText={formik.errors.case && formik.touched.case?formik.errors.case:""}
              />
            )}
          />
          <Autocomplete
            style={{ width: "100%" }}
            value={formik.values.client}
            onChange={(event, value) => formik.setFieldValue("client",value)}
            options={clientOptions}
            getOptionLabel={(option) => option?.fullName}
            
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option?.fullName}</li>
            )}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "16px" }}
                {...params}
                label="Client*"
                placeholder="Client"
                error={formik.errors.client && formik.touched.client}
                helperText={formik.errors.client && formik.touched.client?formik.errors.client:""}
                color="secondary"
                name="type"
              />
            )}
          />

          <TextField
            sx={{ mb: 0, mt: 2, width: "100%" }}
            label="Enter Amount*"
            color="secondary"
            variant="outlined"
            value={formik.values.amount}
            onChange={formik.handleChange}
            name="amount"
            id="amount"
            error={formik.errors.amount && formik.touched.amount}
            helperText={formik.errors.amount && formik.touched.amount?formik.errors.amount:""}
            InputProps={{
              inputProps: {
                inputMode: "numeric",
                pattern: "[0-9]*",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeIcon />
                </InputAdornment>
              ),
            }}
          />


          <Autocomplete
            style={{ width: "100%" }}
            value={formik.values.payment_type}
            onChange={(event, value) => formik.setFieldValue("payment_type",value)}
            options={payment_type_options}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option.label}</li>
            )}
            sx={{ width: 500 }}
           
            renderInput={(params) => (
              <TextField
                sx={{ marginTop: "16px" }}
                {...params}
                label="Payment Type*"
                placeholder="Select Payment Type"
                color="secondary"
                error={formik.errors.payment_type && formik.touched.payment_type}
                helperText={formik.errors.payment_type && formik.touched.payment_type?formik.errors.payment_type:""}
                name="payment_type"
              />
            )}
          />
        </Div>

        <Div>
          <TextField
            sx={{ mb: 0, mt: 2, width: "100%" }}
            id="outlined-basic"
            label="Write Payment Notes..."
            variant="outlined"
            multiline
            rows={2}
            value={formik.values.paymentDescription}
            name="paymentDescription"
            color="secondary"
            onChange={formik.handleChange}
          />
        </Div>

        <Div
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            margin: "1.0rem 0",
          }}
        >
          <Div sx={{ width: isBelow768px ? "50%" : "40%" }}>
            <LoadingButton
              variant="contained"
              loading={btnLoading}
              sx={{
                mt: 2,
                width: "100%",
                "&:hover": {
                  backgroundColor: "#F1C40F",
                },
              }}
              onClick={formik.handleSubmit}
            >
              Send Request
            </LoadingButton>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default RequestForm;
