import React from "react";
import HearingCard from "./HearingCard";
const HearingList = ({
  hearingList,
  setAddHearing,
  setHearingEdit,
  setIsEditing,
  showUpdate,
  setShowUpdate,
}) => {
  console.log(hearingList)
  return (
    <React.Fragment>
      {hearingList.map((hearing, index) => {
            return (
              <HearingCard
                key={index}
                setAddHearing={setAddHearing}
                setIsEditing={setIsEditing}
                setHearingEdit={setHearingEdit}
                Key={hearing.id}
                item={hearing}
                hearingList={hearingList}
                showUpdate={showUpdate}
                setShowUpdate={setShowUpdate}
              />
            );
          
        })}
    </React.Fragment>
  );
};

export default HearingList;
