import { getAppointmentList} from "app/Apis/appointment";
import Cookies from "js-cookie";

const useAppointment = () => {
  const accessToken = Cookies.get("accessToken");
  const getAllAppointment = async (pageNumber=0,pageSize=10,basicFilter,startDate,endDate) => {
    if (!accessToken) {
      throw Error("No access Token provided");
    }
    const response = await getAppointmentList(accessToken, pageNumber,pageSize,basicFilter,startDate,endDate);
    if (response) {
      return response;
    } else {
      return null;
    }
  };
  return getAllAppointment;
};
export default useAppointment;
