import React from "react";
import Div from "@jumbo/shared/Div/Div";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useRef } from "react";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Div sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Div>
      )}
    </Div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Payment = () => {
  const [value, setValue] = React.useState(0);
  const isBelow768px = useMediaQuery((theme) => theme.breakpoints.down(768));
  const isBelow990px = useMediaQuery((theme) => theme.breakpoints.down(990));

  const navigate = useNavigate();

  const tabRef = useRef(null);
  const tabWidth = 100 / 3; // Calculate the width for each tab (assuming 3 tabs)

  const handleEdit = () => {
    navigate("/enquiry");
  };

  useEffect(() => {
    if (tabRef.current) {
      const tabs = tabRef.current.querySelectorAll(".MuiTab-root");
      tabs.forEach((tab) => {
        tab.style.width = `${tabWidth}%`;
      });
    }
  }, [tabWidth]);

  return (
    <>
    <Div
      sx={{
        flexDirection: isBelow768px ? "column-reverse" : "row", // Reverse the order of flex items for small screens
        display: "flex",
        height: "85%",
        width: isBelow768px ? "100%" : "90%",
        marginLeft: isBelow990px ? "0px" : "150px",
        marginTop: isBelow990px ? "30px" : "10px",
        justifyContent: isBelow768px ? "flex-end" : "normal",
      }}
    >
      {/* 75% wali div */}
      <Div
        sx={{ width: isBelow768px ? "100%" : "60%", alignContent: "center" }}
      >
        <JumboDemoCard wrapperSx={{ backgroundColor: "white", pt: 0 }}>
          <Div sx={{ width: "100%" }}>
            <Div sx={{ borderBottom: 1, borderColor: "divider" }} ref={tabRef}>
              <Tabs
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
              >
                <Tab label="Via Card" {...a11yProps(0)} />
                <Tab label="Netbanking" {...a11yProps(1)} />
                <Tab label="UPI" {...a11yProps(2)} />
              </Tabs>
            </Div>
            <TabPanel value={value} index={0}>
              <Div sx={{margin: "1.0rem 0"}} >
              <TextField
                          sx={{ mb: 0, mt: 2, width: "100%" }}
                          id="outlined-basic"
                          label="Email Address"
                          required
                          color="secondary"
                          placeholder="Email Address"
                          variant="outlined"
                        //   value={emailAddress}
                        //   onChange={(e) => setEmailAddress(e.target.value)}
                        //   error= {emailAddressError}
                        //   onBlur={() => {
                        //     if (emailAddress.length <= 0) {
                        //       setEmailAddressError(true);
                        //     }
                        //   }}
                          name="Email Address"
                        //   helperText={formik.errors.PoliceStationName}
                        />
                        {/* {emailAddressError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Enter Police Station Name
            </Typography>
          )} */}
              </Div>
              <Div sx={{margin: "1.0rem 0"}} >
              <TextField
                          sx={{ mb: 0, mt: 2, width: "100%" }}
                          id="outlined-basic"
                          label="Card Holder Name"
                          required
                          color="secondary"
                          placeholder="Card Holder Name"
                          variant="outlined"
                        //   value={emailAddress}
                        //   onChange={(e) => setEmailAddress(e.target.value)}
                        //   error= {emailAddressError}
                        //   onBlur={() => {
                        //     if (emailAddress.length <= 0) {
                        //       setEmailAddressError(true);
                        //     }
                        //   }}
                          name="Cardholder Name"
                        //   helperText={formik.errors.PoliceStationName}
                        />
                        {/* {emailAddressError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Enter Police Station Name
            </Typography>
          )} */}
              </Div>
              <Div sx={{margin: "1.0rem 0"}} >
              <TextField
                          sx={{ mb: 0, mt: 2, width: "100%" }}
                          id="outlined-basic"
                          label="Card Number"
                          required
                          color="secondary"
                          placeholder="0000  0000  0000  0000"
                          variant="outlined"
                        //   value={emailAddress}
                        //   onChange={(e) => setEmailAddress(e.target.value)}
                        //   error= {emailAddressError}
                        //   onBlur={() => {
                        //     if (emailAddress.length <= 0) {
                        //       setEmailAddressError(true);
                        //     }
                        //   }}
                          name="Card Number"
                        //   helperText={formik.errors.PoliceStationName}
                        />
                        {/* {emailAddressError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Enter Police Station Name
            </Typography>
          )} */}
              </Div>
              <Div sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}} >
              <Div sx={{margin: "1.0rem 0"}} >
              <TextField
                          sx={{ mb: 0, mt: 0, mr: 1,  width: "100%" }}
                          id="outlined-basic"
                          label="Expiry Date"
                          required
                          color="secondary"
                          placeholder="mm  /  yy"
                          variant="outlined"
                        //   value={emailAddress}
                        //   onChange={(e) => setEmailAddress(e.target.value)}
                        //   error= {emailAddressError}
                        //   onBlur={() => {
                        //     if (emailAddress.length <= 0) {
                        //       setEmailAddressError(true);
                        //     }
                        //   }}
                          name="Expiry Date"
                        //   helperText={formik.errors.PoliceStationName}
                        />
                        {/* {emailAddressError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Enter Police Station Name
            </Typography>
          )} */}
              </Div>
              <Div sx={{margin: "1.0rem 0"}} >
              <TextField
                          sx={{ mb: 0, mt: 0, ml: isBelow768px ? 1 : 0 ,  width: "100%" }}
                          id="outlined-basic"
                          label="CVV"
                          required
                          color="secondary"
                          placeholder="* * *"
                          variant="outlined"
                        //   value={emailAddress}
                        //   onChange={(e) => setEmailAddress(e.target.value)}
                        //   error= {emailAddressError}
                        //   onBlur={() => {
                        //     if (emailAddress.length <= 0) {
                        //       setEmailAddressError(true);
                        //     }
                        //   }}
                          name="CVV"
                        //   helperText={formik.errors.PoliceStationName}
                        />
                        {/* {emailAddressError && (
            <Typography sx={{ fontSize: "10px", color: "#E73145" }}>
              Please Enter Police Station Name
            </Typography>
          )} */}
              </Div>
              </Div>
              <Div sx={{display: "flex", justifyContent: "flex-end", width: "100%", margin: "1.0rem 0"}} >
        <Div sx={{  width: isBelow768px ? "100%" : "30%",}} >
        <Button
            variant="contained"
            sx={{ mt: 0, mb:"2rem" , width: "100%", backgroundColor: "#F1C40F" }}
          >
            Make Payment
          </Button>
        </Div>
     </Div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              Netbanking
            </TabPanel>
            <TabPanel value={value} index={2}>
              UPI
            </TabPanel>
          </Div>
        </JumboDemoCard>
      </Div>
      {/*  25% wali div */}
      <Div
        sx={{ width: isBelow768px ? "100%" : "30%", mb:"2rem", alignContent: "center" }}
      >
        <Div
          sx={{
            p: 3,
            lineHeight: 1.2,
            fontFamily: "Nunito, sans-serif",
            // backgroundColor: "#F1C40F",
            // backgroundColor: "#f9f5c3",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              width: "100%",
            }}
          >
            <Typography variant="h6">Summary</Typography>
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant={"h2"}
                onClick={handleEdit}
                sx={{
                  color: "white",
                  display: "flex",
                  fontSize: "0.9rem",
                  fontWeight: "700",
                  // mb: 2,
                  cursor: "pointer",
                }}
              >
                Edit ?
              </Typography>
            </Div>
          </Div>
          <hr
            style={{
              height: "1px",
              width: "100%",
              backgroundColor: "white",
              border: "none",
              margin: "0.5rem 0 1rem 0",
              borderColor: "white",
            }}
          />

          <Typography sx={{ fontWeight: 600, fontSize: "16px" }} variant="p">
            Breakdown of your Enquiry.
          </Typography>
          <Div sx={{ marginTop: "1.25rem", marginBottom: "0.75rem" }}>
          <Typography sx={{ fontWeight: 600, }} variant="p">
            Service:
          </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              Enquiry For:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              Enquiry 1
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              Case Type:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              Case Type 1
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              FIR/Complain Number:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              1234567890
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              Police Station Name:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              qwertyuioop
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              Court Name :
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              District Court 1
            </Typography>
          </Div>
          <hr
            style={{
              height: "0.5px",
              width: "100%",
              backgroundColor: "white",
              border: "none",
              margin: "0.5rem 0 1rem 0",
              borderColor: "white",
            }}
          />
          <Div sx={{ marginTop: "1.25rem", marginBottom: "0.75rem" }}>
          <Typography sx={{ fontWeight: 600 }} variant="p">
            Personal Information:
          </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              Full Name :
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              asdfghjkl
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              Mobile Number:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              +91 XXXXXXXXXX
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              State:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              Jharkhand
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 300 }} variant="p">
              City:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              Dhanbad
            </Typography>
          </Div>
          <hr
            style={{
              height: "0.5px",
              width: "100%",
              backgroundColor: "white",
              border: "none",
              margin: "0.5rem 0 1rem 0",
              borderColor: "white",
            }}
          />
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 600 }} variant="p">
              Subtotal:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              $100.00
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 600 }} variant="p">
              Discount:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              $00.00
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 600 }} variant="p">
              Taxes:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              $5.00
            </Typography>
          </Div>
          <Div
            sx={{ display: "flex", mb: "5px", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontWeight: 600 }} variant="p">
            Convenience Fees:
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              $10.00
            </Typography>
          </Div>
          <hr
            style={{
              height: "2px",
              width: "100%",
              backgroundColor: "white",
              border: "none",
              margin: "0.5rem 0 1rem 0",
              borderColor: "white",
            }}
          />
          <Div sx={{ display: "flex", mb: "5px", justifyContent: "flex-end" }}>
            <Typography sx={{ fontWeight: 600 }} variant="p">
              Total: $ 115.00
            </Typography>
          </Div>
          {/* <Button
            variant="contained"
            sx={{ mt: 1, width: "100%", backgroundColor: "black" }}
          >
            Make Payment
          </Button> */}
        </Div>
      {/* <Div sx={{heigth: "10%"}} >
        <Div sx={{display: "flex", justifyContent: "flex-end", marginRight: isBelow768px ? "0" : "90px",}} >
        <Button
            variant="contained"
            sx={{ mt: 1, mb:"4rem" , width: isBelow768px ? "100%" : "100%", backgroundColor: "#F1C40F" }}
          >
            Make Payment
          </Button>
        </Div>
     </Div> */}
      </Div>
    </Div>
     {/* <Div sx={{display: "flex", justifyContent: "flex-start", heigth: "5%", width: isBelow768px ? "100%" : "90%",}} >
        <Div sx={{  width: isBelow768px ? "100%" : "30%", marginLeft: isBelow768px ? "0px" : "150px"}} >
        <Button
            variant="contained"
            sx={{ mt: 1, mb:"4rem" , width: "100%", backgroundColor: "#F1C40F" }}
          >
            Make Payment
          </Button>
        </Div>
     </Div> */}
     </>
  );
};

export default Payment;
