import React, { useState } from "react";
import Div from "@jumbo/shared/Div/Div";
import { Grid } from "@mui/material";
import BalanceAndForm from "./Components/BalanceAndForm";
import ClientPaymentsHistory from "./Components/ClientPayments/ClientPaymentsHistory";
import BalanceWidgets from "./Components/BalanceWidgets";
import { useSelector } from "react-redux";
import CardFooter from "../components/CardFooter";

const Payments = () => {
  const role = useSelector((state)=>state?.onboardingInputs?.assignedRole);
const[showUpdate,setShowUpdate]=useState(false)
  if(role==="ROLE_CLIENT"){
    return (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Div id="clientPayments">
            <ClientPaymentsHistory />
          </Div>
        </Grid>
      </Grid>
    );
  }
  else if(role==="ROLE_LAWYER"){
  return (
    <Grid container direction="row" spacing={2}>  
      <Grid item xs={12}>
        <Div id="clientPayments">
          <ClientPaymentsHistory showUpdate={showUpdate} />
        </Div>
      </Grid>
      <Grid item xs={12}>
        <Div id="balanceAndForm" >
        <BalanceAndForm showUpdate={showUpdate} setShowUpdate={setShowUpdate}/>
        </Div>
      </Grid>
    </Grid>
  );
  }
  return null;
};

export default Payments;
