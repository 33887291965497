import React, { useState, useEffect } from "react";
import Div from "@jumbo/shared/Div/Div";
import {
  Autocomplete,
  TextField,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import CaseCard from "./CaseCard";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import NoCaseImage from "../../ImageAssets/NoCaseImage.png";
import CaseList from "../MyCases/CaseList";
import { useParams } from "react-router-dom";
import { assignCaseToLawyer } from "app/Apis/teams";
import { getCasesList } from "app/Apis/case";
import Cookies from "js-cookie";
import TeamSkeletons from "./TeamSkeletons";

const AssignCase = ({ setUpdate, setIsAssignCase }) => {
  const navigate = useNavigate();
  const { lawyerId } = useParams();
  const [caseList, setCaseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let caseIds = [];

  const handleSubmit = async () => {
    try {
      if (!lawyerId) {
        throw Error("No lawyer");
      }
      let caseString = caseIds.join(',');
      console.log(caseString);
      const response = await assignCaseToLawyer(lawyerId, caseString);
      console.log(response);
      if (response) {
        console.log(response);
        setUpdate((prev) => !prev);
        setIsAssignCase(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAllCases = async () => {
    const accessToken = Cookies.get("accessToken");
    setIsLoading(true);
    const response = await getCasesList(accessToken, 0, 100);
    if (response) {
      console.log(response);
      if (response?.cases) {
        const filteredArray = response?.cases.filter((Case) => {
          if (Case?.teamIds) {
            const index = Case?.teamIds.findIndex((id) => id === lawyerId);
            if (index === -1) {
              return true;
            }
            return false;
          }
          return true;
        });
        setCaseList(filteredArray);
        // setTotalPages(response.totalPages);
        // setPageNumber(response.currentPage)
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getAllCases();
  }, []);
  useEffect(() => {
    if (searchQuery.length > 3) {
      setIsLoading(true);
      const filteredArray = caseList.filter((Case) => {
        const caseName = `${Case?.caseFirstName?.toLowerCase()} V/s ${Case?.caseFirstName?.toLowerCase()}`;
        return caseName.includes(searchQuery);
      });
      setCaseList(filteredArray);
      console.log(filteredArray);
      setIsLoading(false);
    } else {
      setUpdate((prev) => !prev);
      // setIsLoading(false);
    }
  }, [searchQuery]);
console.log(isLoading)
  return (
    <Div
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        flexDirection: "column",
        // px: { xs: "5%", sm: "18%" },
        gap: 2,
      }}
    >
      <Div sx={{ display: "flex", gap: 2, alignItems: "flex-end", my: 2 }}>
        <TextField
          sx={{ width: "100%" }}
          label="Search Cases"
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                <SearchIcon color="secondary" />
              </React.Fragment>
            ),
          }}
        />
        <LoadingButton
          color="secondary"
          sx={{
            width: 100,
            height: "52px",
            padding: 1,
            backgroundColor: "#eabf4e",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          variant="contained"
          // onClick={handleSubmit}
        >
          Add Case
        </LoadingButton>
      </Div>
      <Div>
      {isLoading ? (
          Array.from(Array(parseInt("5"))).map((item, index) => {
            return <TeamSkeletons key={index} />;
          })
        ) : caseList.length > 0 && !isLoading ? 
          <>
          {caseList.map((team) => {
              if (team.isActive) {
                return (
                  <CaseCard
                    key={team.id}
                    item={team}
                    setUpdate={setUpdate}
                    caseIds={caseIds}
                    isAssignCase={true}
                  />
                );
              }
            })}
      </>:
      <Div
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Div
        sx={{
          width: { xs: "60%", sm: "30%", md: "150px" },
          height: "30%",
        }}
      >
        <img
          src={NoCaseImage}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          alt=""
        />
      </Div>

      <Typography
        sx={{
          fontSize: { xs: "18px", md: "24px" },
          textAlign: "center",
          mt: 2,
        }}
      >
        You haven't added any Records yet!
      </Typography>
      <Div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: 4,
        }}
      >
        {/* <Link to="/lawyers/addcases" style={{ textDecoration: "none" }}> */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "secondary.main",
            mb: 2,
            width: "150px",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={() => navigate("/lawyers/cases?addCase=true")}
        >
          Add Case
        </Button>
        {/* </Link> */}
      </Div>
    </Div>
        }

      </Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <LoadingButton
          color="secondary"
          sx={{
            width: 100,
            padding: 1,
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "#eabf4e",
              color: "black",
            },
          }}
          variant="contained"
          onClick={() => {
            setIsAssignCase(false);
            setUpdate((prev) => !prev);
          }}
        >
          Back
        </LoadingButton>
        <LoadingButton
          color="secondary"
          sx={{
            width: 100,
            padding: 1,
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "#eabf4e",
              color: "black",
            },
          }}
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </Div>
    </Div>
  );
};

export default AssignCase;
