import React, { useEffect, useState,lazy } from "react";
import { Card, Divider, Tabs, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ProjectsList from "./ProjectsList";
import Div from "@jumbo/shared/Div";
import { Breadcrumbs, Link } from "@mui/material";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CardFooter from "../components/CardFooter";
import AddIcon from "@mui/icons-material/Add";
import useAppointment from "app/hooks/useAppointment";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
} from "@mui/material";
import NoAppointment from "./NoAppointment";
import { useLocation, useParams } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getTeamAppointmentList } from "app/Apis/appointment";
import Cookies from "js-cookie";

const AddAppointment = lazy(()=>import("./AddAppointment"));



const optionsPageSize = ["5", "10", "20", "25", "50"];
const optionsFilter = [
  "Select",
  "Today",
  "Yesterday",
  "Tomorrow",
  "This Week",
  "This Month",
  "Choose Date",
];
const Schedule = () => {
  const [basicFilter, setBasicFilter] = React.useState(optionsFilter[0]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [AddApp, setAddApp] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentEdit, setAppointmentEdit] = useState(null);
  const [showUpdate, setShowUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState("5");
  const [doEditUpdate, setDoEditUpdate] = useState(false);
  const [values, setValues] = useState(0);
  const lawyerId = Cookies.get("lawyerId");
  console.log(lawyerId);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const addAppointment = queryParameters.get("addAppointment");
  const handleSnackbarClose = () => {
    setSnackBarAddStatus(false);
  };
  console.log(queryParameters);
  const getAllAppointment = useAppointment();
  const [snackBarAddStatus, setSnackBarAddStatus] = useState(false);
  const getAllAppointmentList = async () => {
    setIsLoading(true);
    if (values === 0) {
      const response = await getAllAppointment(
        pageNumber,
        pageSize,
        basicFilter,
        startDate,
        endDate
      );
      if (response) {
        setAppointmentList(response?.data?.appointments);
        // console.log(appointmentList);
        setTotalPages(response?.data?.totalPages);
      }
    } else if (values === 1) {
      const response = await getTeamAppointmentList(
        pageNumber,
        pageSize,
        basicFilter,
        startDate,
        endDate,
        lawyerId
      );
      if (response) {
        setAppointmentList(response?.data?.appointments);
        // console.log(appointmentList);
        setTotalPages(response?.data?.totalPages);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (addAppointment) {
      setAddApp(true);
    }
    if (basicFilter !== "Choose Date") {
      getAllAppointmentList();
    } else if (basicFilter === "Choose Date") {
      if (startDate && endDate) {
        getAllAppointmentList();
      }
    }

    // console.log("in useEff");
  }, [showUpdate, pageNumber, doEditUpdate, basicFilter, pageSize,values]);

  if (AddApp) {
    return (
      <>
        <AddAppointment
          setAppointmentEdit={setAppointmentEdit}
          appointmentEdit={appointmentEdit}
          setAddApp={setAddApp}
          setShowUpdate={setShowUpdate}
          showUpdate={showUpdate}
          setDoEditUpdate={setDoEditUpdate}
          setSnackBarAddStatus={setSnackBarAddStatus}
          addAppointment={addAppointment}
        />
      </>
    );
  } else
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link href="/home" underline="none">
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Home
            </Typography>
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <CalendarMonthOutlinedIcon sx={{ fontSize: 20 }} />
            &nbsp;Appointments
          </Typography>
        </Breadcrumbs>

        <Card
          sx={{
            p: 3,
            position: "relative",
            minHeight: "90%",
          }}
        >
          <Div
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                mt:-1
              }}
            >
              <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                <Div
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Div
                    style={{
                      height: "19px",
                      width: "5px",
                      backgroundColor: "#EABF4E",
                      marginRight: "8px",
                    }}
                  ></Div>
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      lineHeight: 1.5,
                      fontWeight: 400,
                    }}
                  >
                    All Appointments
                  </Typography>
                </Div>
                <Button
                  style={{ fontWeight: "700" }}
                  startIcon={<AddIcon />}
                  color="secondary"
                  onClick={() => setAddApp(true)}
                >
                  Add Appointment
                </Button>
              </Div>
              {basicFilter !== "Choose Date" &&
                <Div
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                
                  }}
                >

                  <Tabs
                    variant="scrollable"
                    value={values}
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <Tab
                      onClick={() => setValues(0)}
                      sx={{
                        fontSize: { xs: "14px", md: "14px" },
                        fontWeight: "800",
                       
                      }}
                      label="My Appointments"
                    />
                    <Tab
                      onClick={() => setValues(1)}
                      sx={{
                        fontSize: { xs: "14px", md: "14px" },
                        fontWeight: "800",
                      }}
                      label="Team Appointments"
                    />
                  </Tabs>
                </Div>}

              <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Div
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    paddingTop: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    Filter By
                  </Typography>
                  <FormControl>
                    <Select
                      labelId="select-label"
                      value={basicFilter}
                      onChange={(e) => setBasicFilter(e.target.value)}
                      sx={{
                        backgroundColor: "white",
                        minWidth: "100px",
                        mb: 1,
                      }}
                    >
                      {optionsFilter.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {basicFilter === "Choose Date" && (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          format="DD/MM/YYYY"
                          onChange={(newValue) => setStartDate(newValue)}
                          maxDate={endDate}
                          sx={{
                            backgroundColor: "white",
                            width: "9rem",
                            mb: 1,
                          }}
                        />
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          format="DD/MM/YYYY"
                          onChange={(newValue) => setEndDate(newValue)}
                          minDate={startDate}
                          sx={{
                            backgroundColor: "white",
                            width: "9rem",
                            mb: 1,
                          }}
                        />
                      </LocalizationProvider>

                      {/* <BasicDatePicker  setFromDate={setFromDate}  setToDate={setToDate} /> */}
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "secondary.main",
                          mb: 1,
                          width: "20px",
                          height:"50px",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "black",
                          },
                        }}
                        onClick={() => getAllAppointmentList()}
                      >
                        <ManageSearchIcon sx={{ fontSize: 34 }} />
                      </Button>
                    </>
                  )}
                </Div>
                <Div>
                  <Select
                    labelId="select-label"
                    value={pageSize}
                    sx={{ backgroundColor: "white", ml: 2 }}
                    onChange={(e) => {
                      setPageSize(e.target.value);
                    }}
                  >
                    {optionsPageSize.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Div>
              </Div>
            </Div>
            <Divider sx={{ mt: 2 }} />

            <Div sx={{ mb: 10 }}>
              <ProjectsList
                setAppointmentEdit={setAppointmentEdit}
                appointmentList={appointmentList?.sort((a, b) => new Date(a.fromTime) - new Date(b.fromTime))}
                setAppointmentList={setAppointmentList}
                setAddApp={setAddApp}
                setShowUpdate={setShowUpdate}
                showUpdate={showUpdate}
                appointmentEdit={appointmentEdit}
                setDoEditUpdate={setDoEditUpdate}
                isLoading={isLoading}
                pageSize={pageSize}
                values={values}
              />
            </Div>
            <Div
              sx={{
                position: "absolute",
                bottom: 20,
                right: { sm: 0, lg: 25 },
              }}
            >
              {appointmentList && (
                <CardFooter
                  totalPages={totalPages}
                  setPageNumber={setPageNumber}
                />
              )}
            </Div>
          </Div>
        </Card>
      </>
    );
};
// else {

//   return <NoAppointment setAddApp={setAddApp} />;
// }
// };

export default Schedule;
