import { JitsiMeeting } from "@jitsi/react-sdk";
import { useSelector } from "react-redux";
import { config } from "./Config";
import './JitsiMeetComponent.css';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom/dist";

const JitsiMeetComponent = ({ roomName,setJitsiMeetApi,setStatus,setOpenSnackBar}) => {
  const fullName = useSelector((state) => {
    return state?.onboardingInputs?.fullName;
  });
  const res=useParams()
  const nav = useNavigate();
  const customConfig = {
    ...config,
    interfaceConfig: {
      DEFAULT_BACKGROUND: 'red',
      TOOLBAR_BACKGROUND: 'green',
      TOOLBAR_BUTTONS_COLOR: 'white',
      TOOLBAR_BUTTONS_FONT_COLOR: 'red',
      TOOLBAR_TITLE_COLOR: 'black',
      APP_FONT_FAMILY: 'Arial, sans-serif',
    //   BRAND_WATERMARK: 'url(https://example.com/logo.png)',
    },
  };
  console.log(res)
  return (
    <div className="custom-jitsi-container" style={{height:res.true==="true"?"100%":"80vh"}}>
    <JitsiMeeting
      domain={"meet.advocacy.live/external_api.js"}
      roomName={"roomName"}
      configOverwrite={customConfig}
      userInfo={{
        displayName: fullName,
      }}
      onApiReady={(externalApi) => { 
        console.log("APi",externalApi);
        setJitsiMeetApi(externalApi);
        try{
            if(externalApi){ 
          externalApi.addEventListener('readyToClose', () => {
            
          // setStatus(false)
          // {res.true==="true"?nav("/lawyers/dashboards"):setStatus(false)}
          {res.true==="true"?window.close():setStatus(false);setOpenSnackBar(true)}
        });
            }
          }
          catch(err){
            console.log(err);
          }
          }
      }
      getIFrameRef={(iframeRef) => {
        iframeRef.style.height = "100%";
      }}
    />
    </div>
  );
};

export default JitsiMeetComponent;
