import Div from "@jumbo/shared/Div/Div";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  Card,
  Paper,
  InputBase,
  Menu,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Breadcrumbs,
  Link,
  MenuList,
  Chip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoDataDisplay from "app/Components/Dashboard/Widgets/NoDataDisplay/NoDataDisplay";
import { styled, alpha } from "@mui/material/styles";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import GavelIcon from "@mui/icons-material/Gavel";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HomeIcon from "@mui/icons-material/Home";
import TaskSkeletons from "app/Components/Dashboard/Widgets/TasksList/TasksSkeletons";
import UnauthorisedAccessPage from "app/pages/404";
import Cookies from "js-cookie";
import AddJudgement from "./AddJudgement";
import { data } from "./staticData";
import JudgementList from "./JugementList";
import { getJudgementsList } from "app/Apis/Judgements";
import CardFooter from "../components/CardFooter";
import { getCourt } from "app/Apis/User";
import { useLocation } from "react-router-dom";
import { getPublicationByPublicationsId } from "app/Apis/Publications";

const optionsPageSize = ["5", "10", "20", "25", "50"];

const filterByOptions = [
  {
    label: "Enquiry ",
    icon: <LiveHelpIcon />,
  },
  {
    label: "Case ",
    icon: <GavelIcon />,
  },
  {
    label: "Court ",
    icon: <AccountBalanceIcon />,
  },
];

const radioOptions = [
  [
    {
      label: "Expert Case Review",
      value: "Expert_Case_Review",
    },
    {
      label: "Anticipatory_Bail_Application",
      value: "Anticipatory Bail Application",
    },
    {
      label: "Regular Bail Application",
      value: "Regular_Bail_Application",
    },
  ],
  [
    {
      label: "Civil Case",
      value: "Civil_Case",
    },
    {
      label: "Criminal Case",
      value: "Criminal_Case",
    },
  ],
  [
    {
      label: "District Court",
      value: "District_Court",
    },
    {
      label: "High Court",
      value: "High_Court",
    },
    {
      label: "Supreme Court",
      value: "Supreme_Court",
    },
    {
      label: "Special Court",
      value: "Special_Court",
    },
  ],
];

function Judgement() {
  // const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState("5");
  const [judgementList, setJudgementList] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [showUpdate, setShowUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enquiryDetails, setEnquiryDetails] = useState(false);
  const [detailItem, setDetailItem] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [filterBy, setFilterBy] = useState(0);
  const [searchCategory, setSearchCatgory] = useState("Case");
  const [caseType, setCaseType] = useState(null);
  const [courtType, setCourtType] = useState(null);
  const [caseName,setCaseName] = useState(null);
  const [judge,setJudge] = useState(null);
  const [sections,setSections] = useState(null);
  const [languageType,setLanguageType] = useState("English");
  const assignedRole = Cookies.get("assignedRole");
  const [addJudgement, setAddJudgement] = useState(false);
  const [courtOptions, setCourtOptions] = useState([]);
  const [judgementEdit,setJudgementEdit] = useState(null);
  const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
  const edit = queryParams.get("edit");
  console.log(edit);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value.length === 0) {
      setShowUpdate((prev) => !prev);
    }
    setSearchQuery(value);
  };

  const handleFilterByChange = (index) => {
    setFilterBy(index);
    handleClose();
  }

  const getPublicationsById = async ()=>{
    try{
      const response = await getPublicationByPublicationsId(edit);
      console.log(response);
      if(response){
        setJudgementEdit(response);
      }
    }catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    console.log(searchCategory);
    console.log(searchQuery);
    const list = async () => {
      if (searchCategory === "Case") {
        if (searchQuery.length > 3) {
          setCaseName(searchQuery);
        }
      } else if (searchCategory === "Judge") {
        if (searchQuery.length > 3) {
          setJudge(searchQuery);
        }
      }
      else if (searchCategory === "section") {
        // if (searchQuery.length > 3) {
          setSections(searchQuery);
        // }
      }
    };
    setIsLoading(true);
    if(searchQuery.length===0){
      resetFilters();
    }else{
      list();
    }
    setIsLoading(false);
  }, [searchCategory, searchQuery]);


  useEffect(() => {
    const fetchData = async () => {
      const courts = await getCourt();
      setCourtOptions(courts);
    }

    fetchData();
    console.log(edit);
    if(edit){
      getPublicationsById();
    }
  }, []);

  useEffect(()=>{
    if(judgementEdit){
      setAddJudgement(true);
    }
  },[judgementEdit]);

  useEffect(() => {
    const list = async () => {
      setIsLoading(true);
      const response = await getJudgementsList(pageNumber, pageSize, null,caseName,sections,judge,courtType,languageType);
      if (response) {
        console.log(response);
        setJudgementList(response?.data?.judgementsDetails);
        setTotalPages(response?.data?.totalPages);
      }
      setIsLoading(false);
    };
    list();
  }, [
    pageNumber,
    showUpdate,
    pageSize,
    ,caseName,sections,judge,courtType,languageType
  ]);
  useEffect(() => {
    console.log(detailItem);
  }, [detailItem]);

  const resetFilters = () => {
    setCaseType(null);
    setSearchQuery("");
    setCaseName(null);
    setJudge(null);
    setSections(null);
    // setTag(null);
    // setEnquiryType(null);
    setCourtType(null);
    // if (mobileNo) {
    //   setMobileNo(null);
    // }
  }
  if (assignedRole === "ROLE_ADMIN"||assignedRole === "ROLE_MODERATOR") {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link href="/admin/judgement/list" underline="none">
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Judgement List
            </Typography>
          </Link>
          {addJudgement && (
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <LiveHelpIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Add Judgement
            </Typography>
          )}
        </Breadcrumbs>
        <Paper
          sx={{
            minHeight: "80%",
            backgroundColor: "white",
            p: 3,
            position: "relative",
          }}
          elevation={20}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 5,
                  }}
                >
                  <Div
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "145px",
                    }}
                  >
                    <Div
                      style={{
                        height: "19px",
                        width: "5px",
                        backgroundColor: "#EABF4E",
                        marginRight: "8px",
                      }}
                    ></Div>
                    <Typography
                      sx={{
                        fontSize: "1.25rem",
                        lineHeight: 1.5,
                        fontWeight: 400,
                      }}
                    >
                      {enquiryDetails ? "Details" : "All Judgements"}
                    </Typography>
                  </Div>
                  {!addJudgement && (
                    <Button
                      style={{ fontWeight: "700", flex: "1" }}
                      startIcon={<AddIcon />}
                      color="secondary"
                      onClick={() => {
                        setAddJudgement(true);
                      }}
                    >
                      Add Judgement
                    </Button>
                  )}
                </Div>
                {!addJudgement && (
                  <Div
                    sx={{
                      position: "relative",
                      width: "40%",
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    <Div
                      sx={{
                        padding: (theme) => theme.spacing(0, 1),
                        height: "100%",
                        position: "absolute",
                        right: 0,
                        pointerEvents: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 2,
                      }}
                    >
                      <SearchIcon color="secondary" />
                    </Div>
                    <InputBase
                      id="search-bar"
                      className="search"
                      placeholder={`Search  by ${searchCategory}`}
                      size="small"
                      onChange={handleSearchChange}
                      value={searchQuery}
                      inputProps={{
                        maxLength: 10,
                      }}
                      sx={{
                        color: "inherit",
                        display: "flex",
                        borderRadius: 24,
                        backgroundColor: (theme) =>
                          theme.jumboComponents.JumboSearch.background,
                        paddingRight: "5rem",
                        "& .MuiInputBase-input": {
                          padding: (theme) => theme.spacing(1, 1, 1, 0),
                          paddingLeft: (theme) => "8rem",
                          transition: (theme) =>
                            theme.transitions.create("width"),
                          width: "100%",
                          height: 24,
                        },
                      }}
                    />
                    {searchQuery && (
                      <CloseIcon
                        color="secondary"
                        sx={{
                          position: "absolute",
                          right: "35px",
                          bottom: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSearchQuery("");
                          resetFilters();
                        }}
                      />
                    )}
                    <Select
                      labelId="select-label"
                      value={searchCategory}
                      sx={{
                        maxHeight: 40,
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        borderBottomLeftRadius: 24,
                        borderTopLeftRadius: 24,
                      }}
                      onChange={(e) => {
                        setSearchCatgory(e.target.value);
                      }}
                    >
                      <MenuItem key={"Case"} value={"Case"}>
                        Case Name
                      </MenuItem>
                      <MenuItem key={"Judge"} value={"Judge"}>
                        Judge Name
                      </MenuItem>
                      <MenuItem key={"section"} value={"section"}>
                        Sections
                      </MenuItem>
                    </Select>
                  </Div>
                )}
                {!addJudgement && (
                  <Div
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Div>
                      <Select
                        labelId="select-label"
                        value={pageSize}
                        sx={{ maxHeight: "40px", mr: "10px" }}
                        onChange={(e) => {
                          setPageSize(e.target.value);
                        }}
                      >
                        {optionsPageSize.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </Div>
                    <Div sx={{ flex: 1 }}>
                      <Button
                        style={{ fontWeight: "600", minWidth: "100%" }}
                        startIcon={<FilterAltOutlinedIcon />}
                        endIcon={<KeyboardArrowDownIcon />}
                        color="secondary"
                        size="small"
                        onClick={handleClick}
                      >
                        Filter By
                      </Button>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        sx={{
                          minWidth: "340px",
                        }}
                      >
                        <Div
                          sx={{
                            widht: "100%",
                            height: "100%",
                            display: "flex",
                          }}
                        >
                          <MenuList
                            sx={{
                              boxShadow: "8px 0px 8px 0px rgba(244,244,244,1)",
                              width: "fit-content",
                            }}
                          >
                            <MenuItem>
                              <ListItemText>Court</ListItemText>
                            </MenuItem>
                            <MenuItem>
                              <ListItemText>Tags</ListItemText>
                            </MenuItem>
                            <MenuItem>
                              <ListItemText>Language</ListItemText>
                            </MenuItem>
                          </MenuList>
                          <Div
                            sx={{
                              p: "15px",
                              pt: 0,
                              mt: "2px",
                              backgroundColor: "#fefefe",
                            }}
                          >
                            <Div
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                py: "15px",
                                gap: "10px",
                                borderBottom: "1px solid rgb(236, 236, 236)",
                              }}
                            >
                              <Typography
                                sx={{
                                  lineHeight: "16px",
                                  opacity: 0.8,
                                  fontSize: "11px",
                                  color: "rgba(0, 0, 0, 0.8)",
                                  fontWeight: 500,
                                  display: "block",
                                }}
                              >
                                Court Types
                              </Typography>
                              <Div sx={{ display: "flex", gap: "8px" }}>
                                {courtOptions && courtOptions.map((court)=>{
                                  return (
                                    <Chip
                                      label={court.courtName}
                                      sx={courtType === court.courtName
                                      ? {
                                          borderRadius: "14px",
                                          color: "white",
                                          backgroundColor: "#eabf4e",
                                          border:
                                            "0px solid rgb(230, 230, 230)",
                                        }
                                      : {
                                          borderRadius: "14px",
                                          color: "rgb(117, 117, 117)",
                                          backgroundColor: "white",
                                          border:
                                            "1px solid rgb(230, 230, 230)",
                                        }}
                                        onClick={()=>{
                                          setCourtType(court.courtName)
                                        }}
                                    />
                                  )
                                })}
                              </Div>
                            </Div>
                            <Div
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                py: "15px",
                                gap: "10px",
                                borderBottom: "1px solid rgb(236, 236, 236)",
                              }}
                            >
                              <Typography
                                sx={{
                                  lineHeight: "16px",
                                  opacity: 0.8,
                                  fontSize: "11px",
                                  color: "rgba(0, 0, 0, 0.8)",
                                  fontWeight: 500,
                                  display: "block",
                                }}
                              >
                                Tags
                              </Typography>
                              <Div sx={{ display: "flex", gap: "8px" }}>
                                <Chip
                                  label={"Sections"}
                                  sx={
                                    caseType === "Civil_Case"
                                      ? {
                                          borderRadius: "14px",
                                          color: "white",
                                          backgroundColor: "#eabf4e",
                                          border:
                                            "0px solid rgb(230, 230, 230)",
                                        }
                                      : {
                                          borderRadius: "14px",
                                          color: "rgb(117, 117, 117)",
                                          backgroundColor: "white",
                                          border:
                                            "1px solid rgb(230, 230, 230)",
                                        }
                                  }
                                  onClick={() => setCaseType("Civil_Case")}
                                />
                                <Chip
                                  label={"Judge"}
                                  sx={
                                    caseType === "Criminal_Case"
                                      ? {
                                          borderRadius: "14px",
                                          color: "white",
                                          backgroundColor: "#eabf4e",
                                          border:
                                            "0px solid rgb(230, 230, 230)",
                                        }
                                      : {
                                          borderRadius: "14px",
                                          color: "rgb(117, 117, 117)",
                                          backgroundColor: "white",
                                          border:
                                            "1px solid rgb(230, 230, 230)",
                                        }
                                  }
                                  onClick={() => setCaseType("Criminal_Case")}
                                />
                              </Div>
                            </Div>
                            <Div
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                py: "15px",
                                gap: "10px",
                                borderBottom: "1px solid rgb(236, 236, 236)",
                              }}
                            >
                              <Typography
                                sx={{
                                  lineHeight: "16px",
                                  opacity: 0.8,
                                  fontSize: "11px",
                                  color: "rgba(0, 0, 0, 0.8)",
                                  fontWeight: 500,
                                  display: "block",
                                }}
                              >
                                Language
                              </Typography>
                              <Div sx={{ display: "flex", gap: "8px" }}>
                                <Chip
                                  label={"English"}
                                  sx={
                                    languageType === "English"
                                      ? {
                                          borderRadius: "14px",
                                          color: "white",
                                          backgroundColor: "#eabf4e",
                                          border:
                                            "0px solid rgb(230, 230, 230)",
                                        }
                                      : {
                                          borderRadius: "14px",
                                          color: "rgb(117, 117, 117)",
                                          backgroundColor: "white",
                                          border:
                                            "1px solid rgb(230, 230, 230)",
                                        }
                                  }
                                  onClick={() => setLanguageType("English")}
                                />
                                <Chip
                                  label={"Hindi"}
                                  sx={
                                    languageType === "Hindi"
                                      ? {
                                          borderRadius: "14px",
                                          color: "white",
                                          backgroundColor: "#eabf4e",
                                          border:
                                            "0px solid rgb(230, 230, 230)",
                                        }
                                      : {
                                          borderRadius: "14px",
                                          color: "rgb(117, 117, 117)",
                                          backgroundColor: "white",
                                          border:
                                            "1px solid rgb(230, 230, 230)",
                                        }
                                  }
                                  onClick={() => {setLanguageType("Hindi");setPageNumber(0);}}
                                />
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Popover>
                    </Div>
                  </Div>
                )}
              </Div>
              <Div sx={{ mt: 3 }}>
                {
                  isLoading ? (
                    Array.from(Array(parseInt(pageSize))).map((item, index) => {
                      return <TaskSkeletons key={index} />;
                    })
                  ) : enquiryDetails ? (
                    <></>
                  ) : addJudgement ? (
                    // <Div>add judgement</Div>
                    <AddJudgement setAddJudgement={setAddJudgement} setShowUpdate={setShowUpdate} judgementEdit={judgementEdit} setJudgementEdit={setJudgementEdit}/>
                  ) : judgementList.length === 0 ? (
                    <NoDataDisplay
                      text={"No Judgement Found"}
                      url={"/admin/judgement/list"}
                      buttonText={"Add Judgement"}
                      minHeight={"80px"}
                      fromPayment={true}
                    />
                  ) : (
                    <></>
                  )
                  // <EnquiryList
                  //     enquiryList={enquiryList}
                  //     setEnquiryDetails={setEnquiryDetails}
                  //     setDetailItem={setDetailItem}
                  //   />
                }
                

                {judgementList.length !== 0 && !addJudgement && !isLoading && (
                  <JudgementList judgementList={judgementList} setJudgementEdit={setJudgementEdit} isJudgement={true}/>
                )}
              </Div>
              {judgementList.length !== 0 && !addJudgement  && (
                <Div
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    right: { sm: 0, lg: 25 },
                  }}
                >
                  <CardFooter
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                  />
                </Div>
              )}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  } else {
    return <UnauthorisedAccessPage />;
  }
}

export default Judgement;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      alignItems: "flex-start",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

{
  /* <StyledMenu
                    id="demo-customized-menu"
                    // MenuListProps={{
                    //   "aria-labelledby": "demo-customized-button",
                    // }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {filterByOptions.map((option, index) => {
                      return (
                        <>
                          <MenuItem
                            disableRipple
                            sx={{
                              flexDirection: "column",
                            }}
                          >
                            <Div sx={{ display: "flex" }}>
                              {option.icon}
                              <Typography mr={1}>{option.label}</Typography>
                            </Div>
                            {index === 0 && (
                              <RadioGroup
                                column
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="priority"
                                onChange={(e, value) => setEnquiryType(value)}
                              >
                                <FormControlLabel
                                  value="Expert_Case_Review"
                                  control={<Radio color="secondary" />}
                                  label="Expert Case Review"
                                />
                                <FormControlLabel
                                  value="Anticipatory_Bail_Application"
                                  control={<Radio color="secondary" />}
                                  label="Anticipatory Bail Application"
                                />
                                <FormControlLabel
                                  value="Regular_Bail_Application"
                                  control={<Radio color="secondary" />}
                                  label="Regular Bail Application"
                                />
                              </RadioGroup>
                            )}
                            {index === 1 && (
                              <RadioGroup
                                column
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="priority"
                                onChange={(e, value) => setCaseType(value)}
                              >
                                <FormControlLabel
                                  value="Civil_Case"
                                  control={<Radio color="secondary" />}
                                  label="Civil Case"
                                />
                                <FormControlLabel
                                  value="Criminal_Case"
                                  control={<Radio color="secondary" />}
                                  label="Criminal Case"
                                />
                              </RadioGroup>
                            )}
                            {index === 2 && (
                              <RadioGroup
                                column
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="priority"
                                onChange={(e, value) => setEnquiryType(value)}
                              >
                                <FormControlLabel
                                  value="District_Court"
                                  control={<Radio color="secondary" />}
                                  label="District Court"
                                />
                                <FormControlLabel
                                  value="High_Court"
                                  control={<Radio color="secondary" />}
                                  label="High Court"
                                />
                                <FormControlLabel
                                  value="Supreme_Court"
                                  control={<Radio color="secondary" />}
                                  label="Supreme Court"
                                />
                                <FormControlLabel
                                  value="Special_Court"
                                  control={<Radio color="secondary" />}
                                  label="Special Court"
                                />
                              </RadioGroup>
                            )}
                          </MenuItem>
                          {/* <Divider sx={{
                       m:0
                       }} /> 
                        </>
                      );
                    })}

                    {/* <MenuItem onClick={handleClose} disableRipple>
                      <FileCopyIcon />
                      Duplicate
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={handleClose} disableRipple>
                      <ArchiveIcon />
                      Archive
                    </MenuItem>
                    <MenuItem onClick={handleClose} disableRipple>
                      <MoreHorizIcon />
                      More
                    </MenuItem> 
                  </StyledMenu> */
}

// const list = async () => {
//   console.log(pageNumber);
//   const response = await getEquiryList(0, 100);
//   if (response) {
//     console.log(response);
//     let filteredList = response?.data?.enquiry.filter((enquiry) => {
//       if (filterBy === 0) {
//         if (searchQuery.length === 10) {
//           return enquiry?.user?.phone === searchQuery;
//         }
//         // return false;
//       } else if (filterBy === 1) {
//       } else if (filterBy === 2) {
//       } else if (filterBy === 3) {
//       } else if (filterBy === 4) {
//       } else if (filterBy === 5) {
//       }
//       return false;
//     });
//     setEnquiryList(filteredList);
//     console.log(filteredList);
//     // setTotalPages(response?.data?.totalPages);
//   }
// };

{
  /* <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      sx={{
                        minWidth:"10vw"
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <List>
                            {filterByOptions.map((option,index) => {
                              const Icon = option.icon;
                              return (
                                <>                                <ListItem>
                                  {/* <ListItemIcon>
                                    <Icon />
                                  </ListItemIcon> 
                                  <ListItemText primary={option.label} />
                                  
                                </ListItem>
                                <Divider
                                sx={{
                                  m: 0,
                                }}
                              />
                              </>
                              );
                            })}
                          </List>
                        </Grid>
                        <Grid item xs={8}>
                          filter checkboxes
                        </Grid>
                      </Grid>
                    </Popover> */
}

//  {/* <Grid item xs={12}>
//             <Grid container spacing={2}>
//               <Grid item xs={3}>
//                 <Card
//                   sx={{
//                     color: "white",
//                     backgroundColor: "#BFBDC1",
//                     p: 3,
//                     mt: 3,
//                   }}
//                 >
//                   <Div
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Div>
//                       <Typography sx={{ fontSize: "1rem" }}>
//                         Total Enquiry
//                       </Typography>
//                       <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
//                         {23}
//                       </Typography>
//                     </Div>
//                     <Div>
//                       {/* <AccountBalanceWalletIcon
//       style={{
//         fontSize: 36,
//         // color:"#EABF4E"
//       }}
//     /> */}
//                     </Div>
//                   </Div>
//                 </Card>
//               </Grid>
//               <Grid item xs={3}>
//                 <Card
//                   sx={{
//                     color: "white",
//                     backgroundColor: "#BFBDC1",
//                     p: 3,
//                     mt: 3,
//                   }}
//                 >
//                   <Div
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Div>
//                       <Typography sx={{ fontSize: "1rem" }}>
//                         Progress
//                       </Typography>
//                       <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
//                         {23}
//                       </Typography>
//                     </Div>
//                     <Div>
//                       {/* <AccountBalanceWalletIcon
//       style={{
//         fontSize: 36,
//         // color:"#EABF4E"
//       }}
//     /> */}
//                     </Div>
//                   </Div>
//                 </Card>
//               </Grid>
//               <Grid item xs={3}>
//                 <Card
//                   sx={{
//                     color: "white",
//                     backgroundColor: "#BFBDC1",
//                     p: 3,
//                     mt: 3,
//                   }}
//                 >
//                   <Div
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Div>
//                       <Typography sx={{ fontSize: "1rem" }}>Pending</Typography>
//                       <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
//                         {23}
//                       </Typography>
//                     </Div>
//                     <Div>
//                       {/* <AccountBalanceWalletIcon
//       style={{
//         fontSize: 36,
//         // color:"#EABF4E"
//       }}
//     /> */}
//                     </Div>
//                   </Div>
//                 </Card>
//               </Grid>
//               <Grid item xs={3}>
//                 <Card
//                   sx={{
//                     color: "white",
//                     backgroundColor: "#BFBDC1",
//                     p: 3,
//                     mt: 3,
//                   }}
//                 >
//                   <Div
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Div>
//                       <Typography sx={{ fontSize: "1rem" }}>
//                         Assigned Lawyer
//                       </Typography>
//                       <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
//                         {23}
//                       </Typography>
//                     </Div>
//                     <Div>
//                       {/* <AccountBalanceWalletIcon
//       style={{
//         fontSize: 36,
//         // color:"#EABF4E"
//       }}
//     /> */}
//                     </Div>
//                   </Div>
//                 </Card>
//               </Grid>
//             </Grid>
//           </Grid>
