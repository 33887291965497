import React from 'react';
import Chip from "@mui/material/Chip";
import {CardActions, Link} from "@mui/material";
import { Box } from "@mui/material";
import {Divider} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { Typography } from "@mui/material";
import HearingDashList from './hearingDashList';
import { useState, useEffect } from 'react';
import useHearings from 'app/hooks/useHearings';




const LatestAlerts = ({scrollHeight}) => {
  
    const [hearingList, setHearingList] = useState(null);
    const [showUpdate, setShowUpdate] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(null);
    const [pageSize, setPageSize] = useState("10");
  
  
  
    const getHearingsList = useHearings();
    useEffect(() => {
      const getAllHearings = async () => {
        const response = await getHearingsList(pageNumber,pageSize);
        if (response) {
          console.log(response?.data?.hearings);
          setHearingList(response?.data?.hearings);
          setTotalPages(response?.data?.totalPages);
        }
      };
      getAllHearings();
    }, [showUpdate, pageNumber,pageSize]);

    return (

        <JumboCardQuick
                     noWrapper
            title={ <Typography variant={"h5"} fontSize={"20.5px"} color={"white"}>{`Hearings (${hearingList ? hearingList.length:"..."})`}</Typography>}
            action={<Link href="/lawyers/hearings" underline="none" lineHeight={1.2}fontSize={"16px"} fontWeight={700} color={"#EABF4E"}>View All</Link>}
            
            bgColor={"black"}
            headerSx={{
                borderBottom: 1,
                borderBottomColor: 'divider'
            }}
            wrapperSx={{p: 0,}}
        >
            <JumboScrollbar
                autoHeight
                autoHeightMin={scrollHeight ? scrollHeight : "100%"}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}

            >
                <HearingDashList 

                hearingList={hearingList}
                setHearingList={setHearingList}
                totalPages={totalPages}
                setPageNumber={setPageNumber}
                pageSize={pageSize}
                setPageSize={setPageSize}
                showUpdate={showUpdate}
                setShowUpdate={setShowUpdate}
                />
            </JumboScrollbar>


        </JumboCardQuick>
    );
};

export default LatestAlerts;
