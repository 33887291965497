import { Typography, Radio, FormControlLabel,Box } from '@mui/material';
import Bank from './bank';
import UPI from './upi';
import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import EditBank from './EditBank';
import EditUPI from './EditUPI';


const EditForm = ({setSnackBarMessage,setOpenSnackBar,setCardOpen,typeOf,setInitSettings,initSettings,setEdiFormOpen})=>{
console.log(initSettings);
    const [selectedOption, setSelectedOption] = useState(typeOf);
    console.log(typeOf);
  const theme = useTheme();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
    return (<>  
      
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
    control={
      <Radio
        checked={selectedOption === 'UPI ID'}
        onChange={handleOptionChange}
        value="UPI ID"
        sx={{
          color: theme.palette.secondary.main,
          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          },
        }}
      />
    }
    label="Add UPI Id"
  />
{/* {console.log(selectedOption);} */}
  <FormControlLabel
    control={
      <Radio
        checked={selectedOption === 'Bank Account'}
        onChange={handleOptionChange}
        value="Bank Account"
        sx={{
          color: theme.palette.secondary.main,
          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          },
        }}
      />
    }
    label={
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
        Add Bank account
        {/* <CloseIcon
          sx={{
            ml: 50,
                fontSize: "24px",
            position: 'relative',
            top: '-2px',
          }}
          
          onClick={() => {setEdiFormOpen(false);
          setCardOpen(true);}}
      
        /> */}
      </Box>
    }
  />
  </Box>
  
      
  {selectedOption === 'Bank Account' && <EditBank setSnackBarMessage={setSnackBarMessage} setOpenSnackBar={setOpenSnackBar} setInitSettings={setInitSettings} initSettings={initSettings} setEdiFormOpen={setEdiFormOpen}/>}
  {selectedOption === 'UPI ID' && <EditUPI setSnackBarMessage={setSnackBarMessage} setOpenSnackBar={setOpenSnackBar} setInitSettings={setInitSettings} initSettings={initSettings} setEdiFormOpen={setEdiFormOpen}/>}
  </>

    )
}
export default EditForm;