import { string } from "prop-types";
import * as Yup from "yup";

export const loginValidations = Yup.object().shape({
	fullname: Yup.string()
		.matches(/^[A-Za-z ]*$/, "Please enter valid name")
		.required("Name is required!"),
	email: Yup.string().email("Invalid email").nullable(),

	mobile: Yup.number()
		.typeError("That doesn't look like a phone number")
		.positive("A phone number can't start with a minus")
		.integer("A phone number can't include a decimal point")
		.required("Phone Number is required"),
});

export const loginValidations2 = Yup.object().shape({
	barcouncilnumber: Yup.string()
		.required()
		.matches(/^\S+$/, "Bar Council Number number is required!"),

	// state: Yup.array()
	//   .max(10, "Only 10 tags are allowed")
	//   .required("Provide at least one tag"),
});

export const BankValidations = Yup.object().shape({
	payeeName: Yup.string()
		.matches(/^[A-Za-z ]*$/, "Please enter valid Payee Name")
		.required("Payee Name is required!"),
	ifscCode: Yup.string()
		.matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Please enter valid IFSC Code")
		.required("IFSC Code is required!"),
	branchName: Yup.string()
		.matches(/^[a-zA-Z0-9\s.-]+$/, "Please enter valid Branch Name")
		.required("Branch Name is required!"),
	bankName: Yup.string()
		.matches(/^[a-zA-Z0-9\s.&'-]+$/, "Please enter valid BankName")
		.required("Bank Name is required!"),
	branchAddress: Yup.string()
		.matches(/^[a-zA-Z0-9\s.,'-]+$/, "Please enter valid Branch Address")
		.required("Branch Address is required!"),
	bankAccountNumber: Yup.string()
		.matches(/^[0-9]+$/, "Bank account number must contain only numbers")
		.min(9, "Bank account number must have at least 9 digits")
		.required("Bank account number is required"),
	confirmBankAccountNumber: Yup.string()
		.oneOf([Yup.ref("bankAccountNumber")], "Bank account numbers must match")
		.required("Confirm bank account number is required")
		.when("bankAccountNumber", {
			is: (val) => val && val.length > 0,
			then: Yup.string().required("Confirm bank account number is required"),
		}),
});

export const todoValidations = Yup.object().shape({
	caseId: Yup.string().nullable(),
	taskName: Yup.string().required("Task Name is required!"),
	// taskDescription: Yup.string().required("Task Description is required!"),
	priority: Yup.string().required("Please select a priority!"),
});
export const todoEditValidations = Yup.object().shape({
	caseDetails: Yup.object().nullable(),
	taskName: Yup.string().required("Task Name is required!"),
	// taskDescription: Yup.string().required("Task Description is required!"),
	priority: Yup.string().required("Please select a priority!"),
});

export const documentValidations = Yup.object().shape({
	documentType: Yup.string()
		.min(1, "Please select atleast one Type")
		.required("Please select atleast one Type"),

	documentName: Yup.string().required("Document name is required!"),
	documentDescription: Yup.string(),
	// visibility: Yup.string().required("Visibility is required"),
});

export const casePointValidations = Yup.object().shape({
	case: Yup.array()
		.min(1, "Please select atleast one Case!")
		.required("Please select atleast one Case!"),
	caseLawyer: Yup.array()
		.min(1, "Please select atleast one Case!")
		.required("Please select atleast one Case!"),

	client: Yup.array()
		.min(1, "Please select atleast one Client!")
		.required("Please select atleast one Client!"),
	lawPoint: Yup.string().required("Document name is required!"),
	linkedDocuments: Yup.array()
		.min(1, "Please select atleast one Linked Documents!")
		.required("Please select atleast one Linked Documents!"),
	casePointDescription: Yup.string().required("Task Description is required!"),
});

//My cases ->

export const CaseValidations = Yup.object().shape({
	// caseName: Yup.string()
	//   //.min(1, "Please select atleast one value!"),
	//   .required("Case Name is required"),
	caseFirstName: Yup.string()
		//.min(1, "Please select atleast one value!"),
		.required("Case First Name is required"),
	caseSecondName: Yup.string()
		//.min(1, "Please select atleast one value!"),
		.required("Case Second Name is required"),

	caseType: Yup.object()
		.required("Please select atleast one Case Type!")
		.nullable(),
	isPetitioner: Yup.object().required("Please select!").nullable(),

	caseNumber: Yup.string().required("Case Number is required"),

	grNo: Yup.number().default(0),

	FIRNumber: Yup.number().default(0),

	underSection: Yup.array()
		.required("Please select atleast one Under Section!")
		.min(1, "Under Section is Required"),

	courtSelection: Yup.object()
		.required("Please select atleast one Court Section!")
		.nullable(),

	assignLawyer: Yup.object().required(
		"Please select atleast one Assign Lawyer!"
	),

	description: Yup.string(),
});

export const EnquiryValidations = Yup.object().shape({
	caseType: Yup.object().required("Please select Case Type!"),

	enquiry: Yup.array().required("Please select Enquiry Type!"),

	assignLawyer: Yup.array()
		.min(1, "Please select atleast one Assign Lawyer!")
		.required("Please select atleast one Assign Lawyer!"),

	states: Yup.array().required("Please select your State!"),

	city: Yup.array().required("Please select your City!"),

	// FullName: Yup.string().required("Full Name is required"),

	PoliceStationName: Yup.string().required("Police station Name is required"),

	phoneNumber: Yup.number()
		.typeError("That doesn't look like a phone number")
		.positive("A phone number can't start with a minus")
		.integer("A phone number can't include a decimal point")
		.required("Phone Number is required"),

	FIRNumber: Yup.string().required("FIR Number is required"),

	courtSelection: Yup.object().required("Please select atleast one Court!"),
});

export const ECourtValidations = Yup.object().shape({
	FillingNumber: Yup.string().nullable(),
	RegistrationNumber: Yup.string()
		.nullable()
		.when("FillingNumber", (FillingNumber) => {
			if (FillingNumber) {
				return Yup.string()
					.nullable()
					.required("Registration Number is required");
			}
		}),
	FillingDate: Yup.string()
		.nullable()
		.when("FillingNumber", (FillingNumber) => {
			if (FillingNumber) {
				return Yup.string()
					.nullable()
					.required("Registration Number is required");
			}
		}),
	caseStage: Yup.object()
		.nullable()
		.when("FillingNumber", (FillingNumber) => {
			if (FillingNumber) {
				return Yup.object().nullable().required("Case Stage is Required");
			}
		}),
	// CnrNumber: Yup.string().required("Cnr Number is required"),
	// opposingLawyer: Yup.string().required("Opposing Lawyer is required"),
	// FillingDate: Yup.string().nullable().when('FillingNumber', (FillingNumber) => {
	//   if (FillingNumber) {
	//     return Yup.string().required("Case Filling Date is required.")
	//   }
	// }),
	RegistrationDate: Yup.string()
		.nullable()
		.when("FillingNumber", (FillingNumber) => {
			if (FillingNumber) {
				return Yup.string()
					.nullable()
					.required("Registration Date is required.");
			}
		}),
	// lastHearingDate: Yup.date()
	// .required("Last Hearing Date is required")
	// .max(new Date(), "You can only select past dates"),
	// nextHearingDate: Yup.date()
	// .required("Next Hearing Date is required")
});
export const DocumentValidations = Yup.object().shape({
	DocumentDescription: Yup.string().required("Description is required!"),
});

export const CaseHearingValidations = Yup.object().shape({
	hearingPurpose: Yup.object().nullable(),
	hearingDate: Yup.date().when("hearingPurpose", (hearingPurpose) => {
		if (hearingPurpose) {
			return Yup.date().required("Hearing Date is required");
		} else {
			return Yup.date().nullable();
		}
	}),
	hearingDescription: Yup.string().nullable(),
});

export const CaseTaskValidations = Yup.object().shape({
	taskName: Yup.string()
		// .required("Task Name is required.")
		.nullable(),
	taskDescription: Yup.string().nullable(),
	taskPriority: Yup.string().required("Task Priority is required.").nullable(),
	taskRemainderDate: Yup.date().nullable(),
	taskStatus: Yup.string().nullable(),
});

export const toDoValidations = Yup.object().shape({
	ToDo: Yup.string().required("Notes are required!"),
});

export const HearingValidations = Yup.object().shape({
	hearingNotes: Yup.object().required("Hearing Purpose is required").nullable(),
	hearingDate: Yup.string().required("Hearing date is required!"),
});
export const HearingsPaymentValidations = Yup.object().shape({
	caseType: Yup.object().required("Case is required").nullable(),

	hearingPurpose: Yup.string().required("Payment Type is required").nullable(),

	startingDate: Yup.string(),
});
export const ClientValidations = Yup.object().shape({
	// startingDate: startingDate,
	// casePoints: casePoints,
	// currentDate: currentDate,
	// documentName: documentName,
	// hearingDescription: hearingDescription,

	FullName: Yup.string().required("Full Name is required!").nullable(),
	email: Yup.string().email("Invalid email").nullable(),
	num: Yup.string()
		.matches(/^[6-9]\d{9}$/, "Phone number is not valid")
		.required("Mobile number is required!"),
	add1: Yup.string().nullable(),
	// .required("Address Line 1 is required!"),

	city: Yup.object().nullable(),
	// .required("City is required!"),
	pin: Yup.number()
		.typeError("That doesn't look like a Pin Code")
		.positive("A Pin Code can't start with a minus")
		.integer("A Pin Code can't include a decimal point")
		.nullable(),
	// .required("Pin Code is required!"),
	states: Yup.object()
		// .required("State is required!")
		.nullable(),
	country: Yup.string().nullable(),
	// .required("Please select a country!"),
});

export const PersonalDetailsValidations = Yup.object().shape({
	fullName: Yup.string().required("Full Name is required!"),
	email: Yup.string().email("Invalid email"),
	phone: Yup.string()
		.matches(/^[6-9]\d{9}$/, {
			message: "Please enter valid number.",
			excludeEmptyString: false,
		})
		.required("Phone number is required"),
});

export const OfficialDetailsValidations = Yup.object().shape({
	lawyer: Yup.array()
		.min(1, "Please select atleast one lawyer!")
		.required("Please select atleast one lawyer!"),
	court: Yup.array()
		.min(1, "Please select atleast one court!")
		.required("Please select atleast one court!"),
	value: Yup.string().required("Please select atleast one court!"),
	value2: Yup.string().required("Please select atleast one court!"),
});

export const PaymentDetailsValidations = Yup.object().shape({
	lawyer: Yup.array()
		.min(1, "Please select atleast one lawyer!")
		.required("Please select atleast one lawyer!"),
	court: Yup.array()
		.min(1, "Please select atleast one court!")
		.required("Please select atleast one court!"),
});

export const PaymentWithdrawValidations = Yup.object().shape({
	amount: Yup.string()
		.matches(/^(?=\D*\d)[a-zA-Z0-9 -]+$/, {
			message: "Please enter valid amount.",
			excludeEmptyString: false,
		})
		.required("Withdraw amount is required"),
});

export const AppointmentValidations = Yup.object().shape({
	client: Yup.object().required("Client Name is required").nullable(),
	meetType: Yup.string().required("Meet Type is required").nullable(),
	fromTime: Yup.date().required("From Time is required"),
	toTime: Yup.date()
		.required("To Time is required")
		.test(
			"is-valid-time-range",
			"Must be more than 15 minutes ",
			function (value) {
				const { fromTime } = this.parent;
				console.log(fromTime);
				const timeDifference = value - fromTime;
				const minutesDifference = Math.floor(timeDifference / 60000);
				console.log(minutesDifference);
				return minutesDifference >= 15;
			}
		),
});

export const MobileSearchValidations = Yup.object().shape({
	phoneNumber: Yup.string()
		.matches(/^[6-9]\d{9}$/, "Phone number is not valid")
		.required("Phone number is required"),
});

export const addPeopleValidationSchema = Yup.object().shape({
	mobileNo: Yup.string()
		.matches(/^[6-9]\d{9}$/, "Phone number is not valid")
		.required("Phone number is required"),
	fullName: Yup.string().required("Full Name is required!"),
	emailId: Yup.string().email("Invalid email"),
	add1: Yup.string().nullable(),
	add2: Yup.string().nullable(),
	city: Yup.object().nullable(),
	state: Yup.object().nullable(),
	country: Yup.string().nullable(),
	pinCode: Yup.string().nullable(),
});

export const enquiryPaymentValidationSchema = Yup.object().shape({
	amount: Yup.string().required("Amount is required").nullable(),
});
// export const addJudgementValidationSchema = Yup.object().shape({
//   caseName:Yup.string().required("Case Name is required").nullable(),
//   sections:Yup.array().required("Sections are required").nullable(),
//   court:Yup.object().required("Court is required").nullable(),
//   judgeName:Yup.string().required("Judge Name is required").nullable(),
//   dateOfDecision:Yup.required("Date is required").nullable(),
//   description:Yup.string().required("Description is required").nullable(),
// });
