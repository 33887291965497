import Div from "@jumbo/shared/Div/Div";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import JitsiMeetComponent from "./JitsiMeet";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import SwipeableTextMobileStepper from "../SwipeableTextMobileStepper";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { Schedule } from "@mui/icons-material";

const JitsiMeetLanding = ({setStatus,setOpenSnackBar}) => {

  const  roomName  = "648b03040dffc62548b706f2-d4e1f7c5-da3c-4131-92aa-845bc1d306d7"
  const [showJitsiMeet, setShowJitsiMeet] = useState(false);
  const [jitsiMeetApi, setJitsiMeetApi] = useState(null);
  const handleJoinMeeting = () => {
    setShowJitsiMeet(true);
  };
  // const handleCloseTab = ()=>{
    useEffect(()=>{
      handleJoinMeeting()
      
    },[])

  return (
    <>
      {/* {!showJitsiMeet && (
        <Div sx={{ height: "100vh", width: "100vw" }}>
          <Grid container alignItems="stretch" sx={{ height: "100vh", width: "100vw", alignItems: "center" }}>
            <Grid item xs={6}>
              <Div sx={{ margin: "auto", marginLeft: "70px" }}>
                <SwipeableTextMobileStepper />
              </Div>
            </Grid>
            <Grid item xs={6}>
              <Div sx={{ fontSize: "55px", fontWeight: "bold" }}>Join The Meet</Div>
              <Div sx={{ fontSize: "20px", marginTop: "20px" }}>
                <ul>
                  <li>For privacy reason you may turn off your camera.</li>
                  <li>By default, your audio and video are muted.</li>
                </ul>
              </Div>
              <LoadingButton
          type="submit"
          onClick={handleJoinMeeting}
          sx={{
            backgroundColor: "#eabf4e",
            width: "45%",
            color: "white",
            p: 1,
            mb: "5px",
            fontWeight: 500,
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "black",
            },
          }}
        > 
                Join Meeting
              </LoadingButton>
            </Grid>
          </Grid>
        </Div>
      )} */}
      {showJitsiMeet && <JitsiMeetComponent
      setStatus={setStatus}
      setOpenSnackBar={setOpenSnackBar}
      roomName={roomName}
      setJitsiMeetApi={setJitsiMeetApi}
      jitsiMeetApi={jitsiMeetApi}
      />}
    </>
  );
};
export default JitsiMeetLanding;
