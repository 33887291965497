import {
    Card,
    CardContent,
    Typography,
  } from "@mui/material";




const UnauthorisedAccessPage = ()=>{
    return (
        <Card
        sx={{
          backgroundColor: "white",
          height: "100%",
          position: "relative",
          paddingTop: "10px",
        }}
      >
          <CardContent
              sx={{
                  height:"100%",
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",
                  flexDirection:"column"
              }}
          >
                    <Typography 
                        variant="h1"
                        sx={{
                            fontSize:"6rem",
                            fontWeight:"700",
                            color:"#EABF4E"
                        }}
                    >
                        Uh..Oh! 
                    </Typography>
              <Typography 
                  variant="h3"
                  sx={{
                      textAlign:"center"
                  }}
              >
                Full Authentication is required to access this page
              </Typography>
          </CardContent>
      </Card>
    )
}
export default UnauthorisedAccessPage;