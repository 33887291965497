import React from "react";
import TaskCard from "./TaskCard";
import { Grid } from "@mui/material";

const TaskList = ({
  tasksList,
  setTaskEditItem,
  setAddTaskOpen,
  setTaskAdded,
  taskAdded,
}) => {
  return (
    <React.Fragment>
      <Grid
        container

        // spacing={2}
      >
        {tasksList.map((task, index) => {
          if (task?.isActive) {
            return (
              <Grid item xs={12} md={12}>
                <TaskCard
                  Key={task.tasksId}
                  taskItem={task}
                  tasksList={tasksList}
                  setTaskEditItem={setTaskEditItem}
                  setAddTaskOpen={setAddTaskOpen}
                  setTaskAdded={setTaskAdded}
                  taskAdded={taskAdded}
                />
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Grid>
    </React.Fragment>
  );
};

export default TaskList;
