import React from 'react'

import Div from '@jumbo/shared/Div/Div'
import { DraftCard } from './draftCard'
import { Grid } from '@mui/material'

export const DraftList = ({draftList,setEdit,setEditItem}) => {
  return (
    <>
    <Div
      sx={{
        pl: { sm: "0", lg: "18%" },
        pr: { sm: "0", lg: "18%" },
      }}
    ><Grid container spacing={2} sx={{mt:0}}>
        {draftList.map((item,index)=>{
        return <Grid item xs={12} md={6} key={index}>
        <DraftCard  item={item} key={index} setEdit={setEdit} setEditItem={setEditItem}/>
        </Grid>
        })}
    
  </Grid>

    </Div>
    </>
  )
}
