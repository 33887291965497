import RecordRTC, { RecordRTCPromisesHandler } from "recordrtc";
import React, { useState, useEffect } from "react";
import { apiHeader, apiUrl } from "app/config";
import Cookies from "js-cookie";
import axios from "axios";


export async function sendBlobToAPI(blob, setDocumentListFromApi) {
  const accessToken = Cookies.get("accessToken");

  // Create a FormData object to send the blob and other parameters
  console.log(blob);
  // const file = new File([blob], "random.wav", { type: "audio/wav" });
  const myFile = new File([blob], 'image.wav', {
    type: blob.type,
});
  const formData = new FormData();
  formData.append("documentName", "Draft");
  formData.append("documentType", "audio");
  formData.append("document", myFile);
  const headers = {
    "x-api-key": apiHeader,
    Authorization: `Bearer ${accessToken}`,
  };
  // Make the POST request
  const response = await axios.post(
    `${apiUrl}/api/v1/document/audio?actionId=ai`,
    formData,
    {
      headers,
    }
  );
  if (response.status === 200) {
    const { data } = response.data;
    console.log("Document created:", data.documentUrl);

    setDocumentListFromApi((prev)=>[...prev,data])
    return data;
  }
}

export const useRecorderPermission = (
  recordingType,
  setRecordedChunks,
  setDocumentListFromApi,
  setAllBlobSize,
  setStream
) => {
  const [recorder, setRecorder] = useState();
  useEffect(() => {
    getPermissionInitializeRecorder();
  }, []);
  const getPermissionInitializeRecorder = async () => {
    let stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    let recorder = new RecordRTCPromisesHandler(stream, {
      type: recordingType,
      recorderType: RecordRTC.StereoAudioRecorder, // force for all browsers
      numberOfAudioChannels: 1,
      mimeType: "audio/wav",
      timeSlice: 3000, // pass this parameter
      // desiredSampRate: 16000,
      //   numberOfAudioChannels: 1, // real-time requires only one channel
      //   bufferSize: 4096,
      //   audioBitsPerSecond: 128000,
      ondataavailable: function (blub) {
        // const blob = new Blob([blub], {type:"audio/wav"})
        console.log(blub);
        // setRecordedChunks((prev) => [...prev, blub]);
        sendBlobToAPI(blub, setDocumentListFromApi);
        setAllBlobSize((prev)=>prev+blub.size)
      },
    });
    setRecorder(recorder);
    setStream(stream)
    console.log(recorder);
  };
  return recorder;
};
