import React, { useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import ContentHeader from 'app/layouts/shared/headers/ContentHeader';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'react-redux';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabScrollButton } from '@mui/material';
import { useEffect } from 'react';
import { Opacity } from '@mui/icons-material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {IconButton} from '@mui/material';
import Span from '@jumbo/shared/Span/Span';
import EditIcon from "@mui/icons-material/Edit"; // Import the EditIcon
import Div from '@jumbo/shared/Div/Div';
import { useDispatch} from 'react-redux';
import { DefaultProfileImage } from 'app/redux/actions/OnboardingInputs';
import { updateProfilePicture } from 'app/Apis/User';
import { UploadSection } from '../Onboarding/style';


const Header = ({
  handleBudgetTabClick,
  handleProfessionalTabClick,
  handlePersonalTabClick,
  handleAddressTabClick,
  handleTimingTabClick,
  handleBankTabClick,
  handleNotificationClick,
  details
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const accessToken = useSelector(
    (state) => state?.onboardingInputs?.accessToken
  );
  const [value, setValue] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const profileImage = useSelector((state) => state?.onboardingInputs?.profileImage);
  const name = useSelector((state) => state?.onboardingInputs?.fullName);
  const role = useSelector((state) => state?.onboardingInputs?.assignedRole);


  const handlePersonalClick = () => {
    handlePersonalTabClick();
    setValue(0);
  };
  const handleProfessionalClick = () => {
    handleProfessionalTabClick();
    setValue(1);
  };
  const handleAddressClick = () => {
    handleAddressTabClick();
    setValue(2);
  };
  const handleTimingClick = () => {
    handleTimingTabClick();
    setValue(3);
  };
  const handleBankClick = () => {
    handleBankTabClick();
    setValue(4);
  };

  const handleBudgetClick = () => {
    handleBudgetTabClick();
    setValue(5);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 990);
  };
  const handleNotification = () => {
    handleNotificationClick();

  }
  const handleClick = () => {
    inputRef.current.click()

  }
  const handleProfileImage= async (e) => {
    const response = await updateProfilePicture(accessToken, e.target.files[0]);
    dispatch(DefaultProfileImage(URL.createObjectURL(e.target.files[0])));
  }

  useEffect(() => {
   
    window.addEventListener('resize', handleResize);
 
    setIsMobile(window.innerWidth < 990);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ContentHeader
      avatar={
        <Div
    sx={{
      position: "relative",
      outline: "3px solid #eabf4e",
      borderRadius: "50%",
      display: "inline-block",
    }}
  >
    <Avatar
      src={profileImage ? profileImage : <PersonIcon sx={{}} />}
      sx={{ width: 72, height: 72 }}
    />
    <UploadSection>
      <input
        ref={inputRef}
        hidden
        accept="image/*"
        onChange={handleProfileImage}
        multiple
        type="file"
      />
      <Span
        sx={{
          position: "absolute",
          borderRadius: "50%",
          top: '-8px', // Adjust the top position as needed
          right: '-8px', // Adjust the right position as needed
          backgroundColor: "transparent",
          width: '30px', // Adjust the size of the background circle as needed
          height: '30px', // Adjust the size of the background circle as needed
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: '#EABF4E',
          },
        }}
      >
        <EditIcon
          onClick={handleClick}
          sx={{
            color: '#FFF',
            fontSize: '20px',
          }}
        />
      </Span>
    </UploadSection>
  </Div>
      }
      title={name}
      subheader={
        <Typography fontSize={12} variant="body1" color="inherit" mt={0.5}>
          {details?.professionalProfile?.lawyerSpecialisation?details?.professionalProfile?.lawyerSpecialisation[0].specialityName:"Specialisation"}
        </Typography>
      }
      tabs={
        <Tabs
       
          sx={{ maxWidth: '85vw' }}
          indicatorColor="black"
          textColor="white"
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable force tabs example"
          allowScrollButtonsMobile
          ScrollButtonComponent={CustomScrollButton}
        >
          <Tab label="Personal" onClick={handlePersonalClick} />
          <Tab label="Professional" onClick={handleProfessionalClick} />
          <Tab label="Address" onClick={handleAddressClick} />
          <Tab label="Timing" onClick={handleTimingClick} />
          <Tab label="Bank Account" onClick={handleBankClick} />
         
         <Tab label="Budget" onClick={handleBudgetClick} />
       
        </Tabs>

        
      }
    action={
      !isMobile && (
        <Button
          disableRipple
          onClick={handleNotification}
          variant="text"
          startIcon={<SettingsIcon />}
          sx={{
            color: 'inherit',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          Notification
        </Button>
   ) 
    }
      
      sx={{
        position: 'relative',
        zIndex: 1,
        '& .MuiCardHeader-action': {
          alignSelf: 'center',
        },
      }}

    >
      
  

      {isMobile && (
        <Button
        onClick={handleNotification}
          disableRipple
          variant="text"
          startIcon={<SettingsIcon />}
          sx={{
            color: 'inherit',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          Notification
        </Button>
      )}
    </ContentHeader>
  );
};

const CustomScrollButton = ({ direction, onClick }) => {
  
  const Icon = direction === 'left' ? KeyboardArrowLeft : KeyboardArrowRight;

  const StyledIcon = styled(Icon)({
    fontSize: '2rem',
    color: 'white',
  });

  return (
    <IconButton onClick={onClick}>
      <StyledIcon />
    </IconButton>
  );
};

export default Header;

