import React from 'react';
import TimelineList from "./TimelineList";
import JumboContent from "@jumbo/components/JumboContent";


const TimelineCard = ({keys,notesObject,title}) => {

    return (
        <JumboContent sx={{mr:2}}>
          <TimelineList
            keys={keys}
            notesObject={notesObject}
            title={title}
          />
        </JumboContent>
    );
};

export default TimelineCard;
