import {
  UPDATE_DOCUMENTS_LIST,
  UPDATE_NOTES_LIST,
  ENQUIRY_DETAILS_REST
} from "app/utils/constants/enquiry";

export const updateDocumentList = (documentList) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DOCUMENTS_LIST, payload: documentList });
  };
};
export const updateNotesList = (notesList) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_NOTES_LIST, payload: notesList });
  };
};
export const reset = () => {
  return (dispatch) => {
    dispatch({ type: ENQUIRY_DETAILS_REST });
  };
};
