import React, { Suspense } from "react";
import { IconButton } from "@mui/material";
import { LawyerMenu, ClientMenu, AdminMenu, ModeratorMenu } from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom/dist";
import { ASSET_IMAGES } from "app/utils/constants/paths";

const Sidebar = () => {
  const role = useSelector((state) => state?.onboardingInputs?.assignedRole);

  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                // justifyContent:"center",
                px: 1
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar
            translate
            items={
              role === "lawyer" || role === "ROLE_LAWYER"
                ? LawyerMenu
                : role === "ROLE_CLINET"
                  ? ClientMenu
                  : role === "ROLE_ADMIN"
                    ? AdminMenu
                    : role === "ROLE_MODERATOR"
                      ? ModeratorMenu
                      : []
            }
          />
        </Suspense>
      </JumboScrollbar>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <Div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
          {sidebarOptions?.open && <Div
            sx={{
              display: "flex",
              marginTop: 2,
              paddingLeft:5
            }}
          >
            <Link href="https://vakily.app">
              {/* <img
                src={`${ASSET_IMAGES}/lawinzo/vakily-logo-wide-white.png`}
                style={{
                  objectFit: "contain",
                  width: "109.33px",
                }}
                alt="Vakily"
              /> */}
                <img
                src={`${ASSET_IMAGES}/lawinzo/vakily-logo.png`}
                style={{
                  objectFit: "contain",
                  width: "44.33px",
                }}
                alt="Vakily"
              />
            </Link>
          </Div>}
          <DrawerHeader sx={{ justifyContent: "end" }}>

            {/* <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )} */}
            <Div >
              {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
            </Div>
          </DrawerHeader>
        </Div>

      )}
    </React.Fragment>
  );
};

export default Sidebar;
