import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { AccountBalance } from "@mui/icons-material";
import { CalendarMonth } from "@mui/icons-material";
import { alpha, Paper, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { HearingData } from "./HearingsData";
import ActionsMenu from "./ActionsMenu";
import DisplayDocumentPreview from "app/Components/Documents/DisplayDocuments/DisplayDocumentPreview";
import { formatDate } from "app/utils/dateTimeHelpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const HearingCard = ({
  item,
  hearingList,
  setIsEditing,
  setHearingEdit,
  setAddHearing,
  showUpdate,
  setShowUpdate,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const phone = useSelector((state)=>state?.onboardingInputs?.mobileNumber);
  console.log(phone,item?.createdByUser,phone===item?.createdByUser);
  let desc=""
  let purpose=""
  let result=""
  if(item?.hearingDetails && item?.hearingDetails.length>0){
    desc=item?.hearingDetails[item?.hearingDetails.length-1]?.hearingDescription
    purpose=item?.hearingDetails[item?.hearingDetails.length-1]?.hearingNotes
    result=item?.hearingDetails[item?.hearingDetails.length-1]?.hearingResult
  }
  // const history = useHistory();

  const handleExpandClick = () => {
  setExpanded(!expanded);
    // navigate(`/lawyers/casedetail?caseId=${item?.caseId}&value=3`);
    // history.push()
  };
  console.log(item)
  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-1px)",
          borderBottomColor: "transparent",
        },
      }}
     
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            m:0,
            padding: "12px 0",
            "&.Mui-expanded": {
              padding: "12px 0",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
          },
        }}
        //  onClick={()=>{if(!anchorEl){handleExpandClick()}}}
      >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </ExpandMore>
        <Div
          sx={{
            width: { xs: "auto", lg: "25%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item?.caseId ? `${item?.caseFirstName} VS ${item?.caseSecondName}` : " Case Name"}
          </Typography>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "flex" },
            width: "15%",
            flexShrink: 0,
            px: 2,
            gap: 5,
          }}
        >
          <Div>
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Lawyer
            </Typography>
            <Typography fontSize={"14px"}>{item?.isPetitioner ? "Respondent" : "Petitioner"}</Typography>
          </Div>
        </Div>
          <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
          >
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Court
            </Typography>
            <Div sx={{ display: "flex", gap: 1 }}>
              <AccountBalance color="secondary" />
              <Typography fontSize={"14px"}>{item?.currentCourt?.courtName}</Typography>
            </Div>
          </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "30%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Hearing Purpose
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <Typography fontSize={"14px"}>{purpose?purpose:"Purpose"}</Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "20%",
            flexShrink: 0,
            px: 1,
            flex: { xs: "0 1 auto", lg: 1 },
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Next Hearing Date
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <CalendarMonth color="secondary" />
            <Typography fontSize={"14px"}>
              {formatDate(item?.ecourtDetails?.hearingDate)}
            </Typography>
          </Div>
        </Div>
        {phone===item?.createdByUser && (
        <Div>
          <ActionsMenu
            item={item}
            setAddHearing={setAddHearing}
            setHearingEdit={setHearingEdit}
            setIsEditing={setIsEditing}
            showUpdate={showUpdate}
            setShowUpdate={setShowUpdate}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </Div>
        )}
      </AccordionSummary>


        <Collapse
          in={expanded}
          timeout="auto"
          sx={{
            borderTop: 1,
            borderColor: "divider",
            p: (theme) => theme.spacing(2, 2, 2, 2),
          }}
          unmountOnExit
        >
          <Div
          sx={{
            display: { xs: "none", lg: "flex" },
            width: "50%",
            flexShrink: 0,
            gap: 5,
          }}
        >
          <Div>
        <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
        GR Number
      </Typography>
        <Typography variant={"body1"} color={"text.secondary"}>
          {item?.grnumber
            ? item?.grnumber
            : "No GR Number added"}
        </Typography>
        </Div>
          {item?.hearingResult &&
          <Div>
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
           Status
         </Typography>
           <Typography variant={"body1"} color={"text.secondary"}>
             {result?result:"Pending"}
           </Typography>
 
           </Div>
          }

          <Div>
         <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
          Description
        </Typography>
          <Typography variant={"body1"} color={"text.secondary"}>
            {desc?desc: "No description added"}
          </Typography>

          </Div>
          
        </Div>
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
          Documents
        </Typography>
        <DisplayDocumentPreview documentLists={item?.documentLists} />
        </Collapse>

    </Paper>
  );
};
/* Todo item prop define */

/* Todo item prop define */
export default HearingCard;
