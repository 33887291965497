import React from "react";
import {
  TextField,
  Alert,
  InputAdornment,
  Autocomplete,
  Typography,
  Snackbar,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { paymentRequest } from "app/Apis/Payment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { casePayment } from "app/Apis/case";
import { clearCaseData } from "app/redux/actions/dashboard";
import { updateCaseListItem } from "app/redux/actions/case";
import { useNavigate } from "react-router-dom";

const payment_type_options = [
  {
    label: "Daily Cases Fee",
    value: "DAILY_CASES_FEE",
  },
  {
    label: "Bail Filing Fee",
    value: "BAIL_FILING_FEE",
  },
  {
    label: "Appointment Fee",
    value: "APPOINTMENT_FEE",
  },
  {
    label: "Enquiry Fee",
    value: "ENQUIRY_FEE",
  },
  {
    label: "Other Court Fee",
    value: "OTHER_COURT_FEE",
  },
  {
    label: "Certified Copy Fee",
    value: "CERTIFIED_COPY_FEE",
  },
];

function CasePayment({ handleBackButton, setAddCase, setUpdate,setCaseAddedSnackBar,setIsLoading }) {
  const [error, setError] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const dispatch = useDispatch();

  const ClearCaseInRedux = () => {
    dispatch(clearCaseData());
  };
  const UpdateCaseListItem = (value) => {
    dispatch(updateCaseListItem(value));
  };
  const caseDetailsFromRedux = useSelector((state) => state.dashboard.clients);
  console.log(caseDetailsFromRedux?.clients);
  const handleArchieve = () => {
    setOpenSnackBar(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    setAddCase(false);
  };
  const navigate=useNavigate()
  const caseId = useSelector((state) => state?.dashboard?.caseDetails?._id);
  const formik = useFormik({
    initialValues: {
      amount: "",
      userId: "",
      paymentDescription: "",
      type: null,
      client: null,
    },
    enableReinitialize: true,
    // validationSchema:
    onSubmit: async (values) => {
      console.log(values);
      try {
        setIsLoading(true)
        const response = await paymentRequest({
          amount: values.amount,
          // userId:values?.userId,
          caseId : caseId ? caseId : null,
          userId: values?.client.peopleId,
          payment_type: values?.type?.value,
          payment_mode: "ONLINE",
          paymentDescription: values?.paymentDescription,
          requester: "cloud",
        });
        if (response) {
          console.log("req sent", response);
          const caseResponse = await casePayment(caseId, response?.order_id);
          setIsLoading(false)
          if (caseResponse) {
            console.log(caseResponse);
            UpdateCaseListItem(caseResponse)
            handleArchieve();
            setUpdate((prev) => !prev);
            ClearCaseInRedux();     
          }
          
          // console.log(caseResponse)
        }
        setIsLoading(false)
      } catch (err) {
        console.log(err);
        setIsLoading(false)
      }
      setAddCase(false);
      setCaseAddedSnackBar(true)
    },
  });
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
          >
            <Typography variant="h6">Case Added</Typography>
            <Div sx={{ display: "flex", justifyContent: "center" }}></Div>
          </Alert>
        </Snackbar>
      )}
      <Div
        sx={{
          marginLeft: { xs: "0%", md: "18%" },
          marginRight: { xs: "0%", md: "18%" },
          height: "100%",
        }}
      >
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          label="Enter Amount*"
          color="secondary"
          variant="outlined"
          value={formik.values.amount}
          onChange={formik.handleChange}
          name="amount"
          id="amount"
          InputProps={{
            inputProps: {
              inputMode: "numeric",
              pattern: "[0-9]*",
            },
            startAdornment: (
              <InputAdornment position="start">
                <CurrencyRupeeIcon />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.client}
          onChange={(event, value) => formik.setFieldValue("client", value)}
          isOptionEqualToValue={(o, v) => o.peopleId === v.peopleId}
          options={caseDetailsFromRedux ? caseDetailsFromRedux : []}
          getOptionLabel={(option) => option?.fullName}
          renderOption={(props, option, { selected }) => (
            <li {...props}>{option?.fullName}</li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "16px" }}
              {...params}
              label="Client*"
              placeholder="Client"
              color="secondary"
              name="type"
            />
          )}
        />
        <Autocomplete
          style={{ width: "100%" }}
          value={formik.values.type}
          onChange={(event, value) => formik.setFieldValue("type", value)}
          options={payment_type_options}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>{option.label}</li>
          )}
          sx={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              sx={{ marginTop: "16px" }}
              {...params}
              label="Payment Type*"
              placeholder="Select Payment Type"
              color="secondary"
              name="type"
            />
          )}
        />
        <Div>
          <TextField
            sx={{ mb: 0, mt: 2, width: "100%" }}
            id="outlined-basic"
            label="Write Payment Notes..."
            variant="outlined"
            multiline
            rows={3}
            value={formik.values.paymentDescription}
            name="paymentDescription"
            color="secondary"
            onChange={formik.handleChange}
          />
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
            // backgroundColor: "green",
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            //   disabled={values !== 0 ? false : true}
            variant="contained"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={formik.handleSubmit}
            color="secondary"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default CasePayment;
