 const logoUrl = "https://lawinzo-profile-dev.s3.ap-south-1.amazonaws.com/64ac5358c4009727e3768dc1/tasks/854f5f66-e021-49ea-8436-dacb61ab22e1.jpeg"


 
 export const jitsiTheme = {
    // The domain url to apply (will replace the domain in the sharing conference link/embed section)
    inviteDomain: 'cloud.vakily.app',
    // The hex value for the colour used as background
    backgroundColor: '#fff',
    // The url for the image used as background
    // backgroundImageUrl: 'https://example.com/background-img.png',
    // The anchor url used when clicking the logo image
    logoClickUrl: 'https://www.vakily.app',
    // The url used for the image used as logo
    logoImageUrl: logoUrl,
    

    customTheme: {
        palette: {
            ui01: "#EABF4E",
            ui02: "#EABF4E",
            surface02: '#EABF4E',
            ui03: "#EABF4E",
            ui04: "#EABF4E",
            ui05: "#EABF4E",
            action01: "#EABF4E",
            action01Hover: "#EABF4E",
            disabled01: "#EABF4E",
            success02: "#EABF4E",
            action02Hover: "#EABF4E",
        },
        typography: {
            labelRegular: {
                fontSize: 25,
                lineHeight: 30,
                fontWeight: 500,
            }
        }
    }
}