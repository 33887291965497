import { apiHeader, apiUrl } from "app/config";
import { filter } from "app/utils/filter";
import Cookies from "js-cookie";

export const getAppointmentList = async (
  accessToken,
  pageNumber,
  pageSize,
  basicFilter,
  startDate,
  endDate
) => {
  const date = filter(basicFilter, startDate, endDate);
  try {
    const params = new URLSearchParams();
    params.append("type", "lawyer");
    // params.append("appointmentType", appointmentType);
    params.append("page", pageNumber);
    params.append("size", pageSize);
    params.append("fromDate", date?.startDate);
    params.append("toDate", date?.endDate);
    let headers = new Headers(); // headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const url = apiUrl + "/api/v1/appointment";
    const urlWithParams = `${url}?${params.toString()}`;
    console.log("checking Get URL", urlWithParams);
    const response = await fetch(urlWithParams, {
      method: "GET",
      headers, // body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      const data = await response.json();
      console.log("all appointments fetched", data);
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};
export const getTeamAppointmentList = async (
  pageNumber,
  pageSize,
  basicFilter,
  startDate,
  endDate,
  lawyerId
) => {
  const date = filter(basicFilter, startDate, endDate);
  try {
    const accessToken = Cookies.get("accessToken");
    const params = new URLSearchParams();
    params.append("type", "lawyer");
    params.append("page", pageNumber);
    params.append("size", pageSize);
    // params.append("lawyerId", lawyerId);
    params.append("fromDate", date?.startDate);
    params.append("toDate", date?.endDate);
    let headers = new Headers(); // headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const url = apiUrl + "/api/v1/appointment/members";
    const urlWithParams = `${url}?${params.toString()}`;
    console.log("checking Get URL", urlWithParams);
    const response = await fetch(urlWithParams, {
      method: "GET",
      headers, // body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      const data = await response.json();
      console.log("all appointments fetched", data);
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const getAppointmentListByCaseId = async (accessToken, caseId) => {
  try {
    const params = new URLSearchParams();
    // params.append("type", "lawyer");
    params.append("caseId", caseId);
    let headers = new Headers(); // headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const url = apiUrl + `/api/v1/appointment/case/${caseId}`;
    const urlWithParams = `${url}`;
    console.log("checking Get URL", urlWithParams);
    const response = await fetch(urlWithParams, {
      method: "GET",
      headers, // body: JSON.stringify(obj),
    });
    if (response.status === 200) {
      const data = await response.json();
      console.log("all appointments fetched", data);
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const CancelAppointment = async (accessToken, app) => {
  const expandedItem = { ...app };
  try {
    if (!accessToken) {
      throw Error("No accessToken Provided");
    }
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    const deletedApp = {
      addressLabel: expandedItem.addressLabel,
      appointmentDescription: expandedItem.appointmentDescription,
      appointmentTitle: expandedItem.appointmentTitle,
      appointmentType: expandedItem.appointmentType,
      client: { id: 1 },
      dateOfAppointment: expandedItem.dateOfAppointment,
      fromTime: expandedItem.fromTime,
      isActive: 0,
      isConfirmedFromLawyer: expandedItem.isConfirmedFromLawyer,
      isNotAvailable: expandedItem.isNotAvailable,
      toTime: expandedItem.toTime,
      id: expandedItem.id,
    };
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    console.log();
    const response = await fetch(apiUrl + "/api/v1/appointment", {
      method: "POST",
      headers,
      body: JSON.stringify(deletedApp),
    });
    // console.log(response);

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};
