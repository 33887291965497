import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import AppointmentDashCard from "./AppointmentDashCard";
import Div from "@jumbo/shared/Div/Div";
import NoDataDisplay from "../NoDataDisplay/NoDataDisplay";


const AppointmentDashList = ({ appointmentList, showUpdate, setShowUpdate, value }) => {

  let appointments = appointmentList?.sort((a, b) => new Date(a.fromTime) - new Date(b.fromTime)).filter(function (el) {
    if (value === 0) {
      return el.appointmentType === "Personal Meeting"
    }
    if (value === 1) {
      return el.appointmentType === "Video Call"
    }
    if (value === 2) {
      return el.appointmentType === "Telephonic Call"
    }
  }

  );

  if (appointments.length === 0) {
    return <Div sx={{ width: "100%" }}>
      <NoDataDisplay
        text={"No Appointments"}
        url={"/lawyers/appointments?addAppointment=true"}
        buttonText={"Add Appointments"}
        minHeight={"550px"}
      />
    </Div>

  }

 
  
  
  return (
    <React.Fragment>
      <Div sx={{ overflow: 'auto', height: { xs: "400px", sm: "245px" }, marginTop: "1rem" }}>
        <Grid container spacing={2} sx={{ mt: 0 }}>

          {appointments.length > 0 &&
            appointments?.map((appointment, index) => {
              if (appointment?.isActive) {
                return (
                  <Grid item xs={12}>
                    <AppointmentDashCard
                      key={appointment?.id}
                      item={appointment}
                    />
                  </Grid>
                );
              }
            }) 
        }
        </Grid>
      </Div>
    </React.Fragment>
  );
};

export default AppointmentDashList;
