import React, { useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick/JumboCardQuick";
import {
  Button,
  Checkbox,
  Grid,
  TextField,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Div from "@jumbo/shared/Div/Div";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useMediaQuery } from "@mui/material";
import { budgetValidations } from "../../ValidationsProfile";
import { updateBudgetSettings } from "app/Apis/settings";
import { Alert, Snackbar } from "@mui/material";
const BudgetInfo = ({ lawyerId, accessToken,initSettings,setInitSettings }) => {

  const settings = initSettings?.practiceFeeDetails;
  const [isEditing, setIsEditing] = useState(false);

  const handleCheckChange = (event, name) => {
    const value = event.target.checked;

    if (!value) {
      const fieldName =
        name === "intrestedDirectConsulting"
          ? "directConsultingFee"
          : name === "intrestedEmailConsulting"
          ? "emailConsultingFee"
          : name === "intrestedPhoneConsulting"
          ? "phoneConsultingFee"
          : name === "intrestedVideoConsulting"
          ? "videoConsultingFee"
          : "";

      formik.setFieldValue(fieldName, "");
      if (name === "interstedOtherConsulting") {
        formik.setFieldValue("monthlyBillRate", "");
        formik.setFieldValue("timeBasedBillRate", "");
        formik.setFieldValue("caseBasedBillRate", "");
      }
    }
    formik.setFieldValue(name, value);
    console.log(formik.values);
  };

  const handleUpdateClick = () => {
    formik.handleSubmit();
    // console.log(formik.errors);
    // console.log(Object.keys(formik.errors).length);
    if (Object.keys(formik.errors).length === 0) {
      setIsEditing(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    setOpenSnackBar(false);
  };
  // const isWidthBelow990 = useMediaQuery("(max-width: 990px)");
  const handleSubmit = async () => {
    console.log(formik.values);
    console.log(formik.errors);
    const updatedSettings = {
      caseBasedBillRate: formik.values.caseBasedBillRate,
      directConsultingFee : formik.values.directConsultingFee,
      emailConsultingFee: formik.values.emailConsultingFee,
      phoneConsultingFee: formik.values.phoneConsultingFee,
      timeBasedBillRate:formik.values.timeBasedBillRate,
      videoConsultingFee:formik.values.videoConsultingFee,
      intrestedDirectConsulting: formik.values.intrestedDirectConsulting,
      intrestedPhoneConsulting: formik.values. intrestedPhoneConsulting,
      intrestedEmailConsulting: formik.values.intrestedEmailConsulting,
      intrestedVideoConsulting:formik.values.intrestedVideoConsulting,
      interstedOtherConsulting:formik.values.interstedOtherConsulting
    }
    const response = await updateBudgetSettings(initSettings,updatedSettings,lawyerId,accessToken)
    if(response){
      console.log(response);
      setInitSettings(response)
      setOpenSnackBar(true);
    }
    else{
      console.log("error updating settings")
    }
};
const checkForNoValueSelected = (settings)=>{
  if(!settings?.intrestedDirectConsulting &&
      !settings?.intrestedEmailConsulting &&
      !settings?.intrestedPhoneConsulting &&
      !settings?.intrestedVideoConsulting &&
      !settings?.interstedOtherConsulting
    ){
      return true;
    }
    return false;
}
  const formik = useFormik({
    initialValues: {
      caseBasedBillRate: settings?.caseBasedBillRate||"",
      directConsultingFee: settings?.directConsultingFee|| "",
      emailConsultingFee: settings?.emailConsultingFee||"",
      intrestedDirectConsulting: settings?.intrestedDirectConsulting|| false,
      intrestedEmailConsulting:settings?.intrestedEmailConsulting|| false,
      intrestedPhoneConsulting: settings?.intrestedPhoneConsulting|| false,
      intrestedVideoConsulting: settings?.intrestedVideoConsulting||false,
      interstedOtherConsulting: settings?.interstedOtherConsulting||false, 
      monthlyBillRate: settings?.monthlyBillRate|| "",
      phoneConsultingFee: settings?.phoneConsultingFee||"",
      timeBasedBillRate: settings?. timeBasedBillRate||"",
      videoConsultingFee: settings?.videoConsultingFee||"",
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: budgetValidations,
  });

  return (
    <div>
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
          >
            <Typography variant="h6">
              Fee Updated Successfully!
            </Typography>
          </Alert>
        </Snackbar>
      )}
    <JumboCardQuick
      action={
        <LoadingButton
          onClick={isEditing ? handleUpdateClick : handleEditClick}
          color="secondary"
      
          sx={{ width: 100, padding: 1, mb: "6px", mt: "6px" ,backgroundColor: "black",
          "&:hover": {
            
            backgroundColor: "#eabf4e",
          },}}
          variant="contained"
        >
          {isEditing ? "Update" : checkForNoValueSelected(settings)?"Add":"Edit"}
        </LoadingButton>
      }
      title={<Typography sx={{ fontSize: 20 }}>Lawyer Meeting Fee</Typography>}
      headerSx={{
        borderBottom: 1,
        borderColor: "divider",
      }}
      sx={{ mb: 3.75 }}
    >
      <Grid container>
        {/* col1 */}
        <Grid item xs={12} md={6} sx={{ mb: 0.7 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center">
              <Grid item xs={1} alignItems={"center"} justifyContent={"center"}>
                {isEditing ? (
                  <Checkbox
                    checked={formik.values.intrestedDirectConsulting}
                    onChange={(e) =>
                      handleCheckChange(e, "intrestedDirectConsulting")
                    }
                    disabled={!isEditing}
                    sx={{
                      // "mb": "10px",
                      "color": "secondary.main",
                      "&.Mui-checked": {
                        color: "secondary.main",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                      "marginRight": "3px",
                    }}
                  />
                ) : (
                  <PersonIcon
                    sx={{
                      "textAlign": "center",
                      "marginLeft": "3px",
                      "marginTop": "3px",
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={11}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography sx={{ ml: 0.5, mr: 3 }}>In-Person</Typography>
              </Grid>
              <Grid item xs={12} alignItems={"center"}>
                <TextField
                  sx={{
                    marginBottom: "12px",
                    width: "90%",
                    mt:"7px"
                  }}
                  id="outlined-basic"
                  color="secondary"
                  variant="outlined"
                  label={
                    formik.values.intrestedDirectConsulting
                      ? "In-Person Rate"
                      : "No Meeting Fee Added"
                  }
                  name="directConsultingFee"
                  value={formik.values.directConsultingFee}
                  disabled={
                    !isEditing || !formik.values.intrestedDirectConsulting
                  }
                  error={
                    formik.errors.directConsultingFee &&
                    formik.touched.directConsultingFee
                  }
                  helperText={formik.touched.directConsultingFee ? formik.errors.directConsultingFee : ""}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 0.7 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center">
              <Grid item xs={1} alignItems={"center"} justifyContent={"center"}>
                {isEditing ? (
                  <Checkbox
                    checked={formik.values.intrestedVideoConsulting}
                    onChange={(e) =>
                      handleCheckChange(e, "intrestedVideoConsulting")
                    }
                    sx={{
                      // "mb": "10px",
                      "color": "secondary.main",
                      "&.Mui-checked": {
                        color: "secondary.main",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                      "marginRight": "3px",
                    }}
                  />
                ) : (
                  <VideoCallIcon
                    sx={{
                      "textAlign": "center",
                      "marginLeft": "3px",
                      "marginTop": "3px",
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={11}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography sx={{ ml: 0.5, mr: 3 }}>Video</Typography>
              </Grid>

              <Grid item xs={12} alignItems={"center"}>
                <TextField
                  sx={{
                    marginBottom: "12px",
                    width: "90%",
                    mt:"7px"
                  }}
                  id="outlined-basic"
                  color="secondary"
                  variant="outlined"
                  label={
                    formik.values.intrestedVideoConsulting
                      ? "Video Consulting Rate"
                      : "No Meeting Fee Added"
                  }
                  name="videoConsultingFee"
                  error={
                    formik.errors.videoConsultingFee &&
                    formik.touched.videoConsultingFee
                  }
                  helperText={formik.touched.videoConsultingFee ? formik.errors.videoConsultingFee : ""}
                  value={formik.values.videoConsultingFee}
                  disabled={
                    !isEditing || !formik.values.intrestedVideoConsulting
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 0.7 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center">
             
              <Grid item xs={1} alignItems={"center"} justifyContent={"center"}>
                {isEditing ? (
                  <Checkbox
                    checked={formik.values.intrestedEmailConsulting}
                    onChange={(e) =>
                      handleCheckChange(e, "intrestedEmailConsulting")
                    }
                    disabled={!isEditing}
                    sx={{
                      // "mb": "10px",
                      "color": "secondary.main",
                      "&.Mui-checked": {
                        color: "secondary.main",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                      "marginRight": "3px",
                    }}
                  />
                ) : (
                  <EmailOutlinedIcon
                    sx={{
                      "textAlign": "center",
                      "marginLeft": "3px",
                      "marginTop": "3px",
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={11}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography sx={{ ml: 0.5, mr: 3 }}>Email/ WhatsApp</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{
                    marginBottom: "12px",
                    width: "90%",
                    mt:"7px"
                  }}
                  id="outlined-basic"
                  color="secondary"
                  variant="outlined"
                  label={
                    formik.values.intrestedEmailConsulting
                      ? "Email Consulting Rate"
                      : "No Meeting Fee Added"
                  }
                  name="emailConsultingFee"
                  value={formik.values.emailConsultingFee}
                  error={
                    formik.errors.emailConsultingFee &&
                    formik.touched.emailConsultingFee
                  }
                  helperText={formik.touched.emailConsultingFee ? formik.errors.emailConsultingFee : ""}
                  disabled={
                    !isEditing || !formik.values.intrestedEmailConsulting
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 0.7 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center">
              <Grid item xs={1} alignItems={"center"} justifyContent={"center"}>
                {isEditing ? (
                  <Checkbox
                    checked={formik.values.intrestedPhoneConsulting}
                    onChange={(e) =>
                      handleCheckChange(e, "intrestedPhoneConsulting")
                    }
                    disabled={!isEditing}
                    sx={{
                      // "mb": "10px",
                      "color": "secondary.main",
                      "&.Mui-checked": {
                        color: "secondary.main",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                      "marginRight": "3px",
                    }}
                  />
                ) : (
                  <CallIcon
                    sx={{
                      "textAlign": "center",
                      "marginLeft": "3px",
                      "marginTop": "3px",
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={11}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography sx={{ ml: 0.5, mr: 3 }}>Phone</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{
                    marginBottom: "12px",
                    width: "90%",
                    mt:"7px"
                  }}
                  label={
                    formik.values.intrestedPhoneConsulting
                      ? "Phone Consulting Rate"
                      : "No Meeting Fee Added"
                  }
                  id="outlined-basic"
                  color="secondary"
                  variant="outlined"
                  name="phoneConsultingFee"
                  value={formik.values.phoneConsultingFee}
                  error={
                    formik.errors.phoneConsultingFee &&
                    formik.touched.phoneConsultingFee
                  }
                  helperText={formik.touched.phoneConsultingFee ? formik.errors.phoneConsultingFee : ""}
                  disabled={
                    !isEditing || !formik.values.intrestedPhoneConsulting
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 0.7 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center">
              <Grid item xs={1} alignItems={"center"} justifyContent={"center"}>
                {isEditing ? (
                  <Checkbox
                    checked={formik.values.interstedOtherConsulting}
                    onChange={(e) =>
                      handleCheckChange(e, "interstedOtherConsulting")
                    }
                    disabled={!isEditing}
                    sx={{
                      "mb": "7px",
                      "color": "secondary.main",
                      "&.Mui-checked": {
                        color: "secondary.main",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                      "marginRight": "3px",
                    }}
                  />
                ) : (
                  <LocalOfferIcon
                    sx={{
                      "textAlign": "center",
                      "marginLeft": "3px",
                      "marginTop": "3px",
                      mb:"7px",
                      "& .MuiSvgIcon-root": { fontSize: 17 },
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={11}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography sx={{ ml: 0.5, mr: 3,mb:"7px" }}>Other</Typography>
              </Grid>

              <Grid item xs={12}>
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  {(isEditing ||
                    (!isEditing &&
                      formik.values.monthlyBillRate === "" &&
                      formik.values.caseBasedBillRate === "") ||
                    (!isEditing && formik.values.timeBasedBillRate !== "")) && (
                    <TextField
                      sx={{
                        marginBottom: "12px",
                        width: "90%",
                      }}
                      id="outlined-basic"
                      color="secondary"
                      variant="outlined"
                      label={
                        formik.values.interstedOtherConsulting
                          ? "Time Based Bill Rate"
                          : "No Meeting Fee Added"
                      }
                      name="timeBasedBillRate"
                      error={
                        formik.errors.timeBasedBillRate &&
                        formik.touched.timeBasedBillRate
                      }
                      helperText={formik.touched.timeBasedBillRate ? formik.errors.timeBasedBillRate : ""}
                      value={formik.values.timeBasedBillRate}
                      disabled={
                        !isEditing || !formik.values.interstedOtherConsulting
                      }
                      onChange={formik.handleChange}
                    />
                  )}
                  {((isEditing && formik.values.interstedOtherConsulting) ||
                    formik.values.monthlyBillRate !== "") && (
                    <TextField
                      sx={{
                        marginBottom: "12px",
                        width: "90%",
                      }}
                      id="outlined-basic"
                      color="secondary"
                      variant="outlined"
                      label={
                        formik.values.interstedOtherConsulting
                          ? "Monthly Bill Rate"
                          : "No Meeting Fee Added"
                      }
                      name="monthlyBillRate"
                      value={formik.values.monthlyBillRate}
                      error={
                        formik.errors.monthlyBillRate &&
                        formik.touched.monthlyBillRate
                      }
                      helperText={formik.touched.monthlyBillRate ? formik.errors.monthlyBillRate : ""}
                      disabled={
                        !isEditing || !formik.values.interstedOtherConsulting
                      }
                      onChange={formik.handleChange}
                    />
                  )}
                  {((isEditing && formik.values.interstedOtherConsulting) ||
                    formik.values.caseBasedBillRate !== "") && (
                    <TextField
                      sx={{
                        marginBottom: "12px",
                        width: "90%",
                      }}
                      label={
                        formik.values.interstedOtherConsulting
                          ? "Case Based Bill Rate"
                          : "No Meeting Fee Added"
                      }
                      id="outlined-basic"
                      color="secondary"
                      variant="outlined"
                      name="caseBasedBillRate"
                      value={formik.values.caseBasedBillRate}
                      error={
                        formik.errors.caseBasedBillRate &&
                        formik.touched.caseBasedBillRate
                      }
                      helperText={formik.touched.caseBasedBillRate ? formik.errors.caseBasedBillRate : ""}
                      disabled={
                        !isEditing || !formik.values.interstedOtherConsulting
                      }
                      onChange={formik.handleChange}
                    />
                  )}
                </Div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </JumboCardQuick>
    </div>
  );
};

export default BudgetInfo;