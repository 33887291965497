// import React from 'react';
// import EnquiryItem from "./EnquiryItem";
// import { Table,TableHead,TableBody,TableRow,TableCell } from '@mui/material';
// import styled from "@emotion/styled";


// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     borderBottom: "none",
//     paddingTop: theme.spacing(1.5),
//     paddingBottom: theme.spacing(1.5),
//     backgroundColor:"#6D6A75",
//     color:"white"
//   }));

// function EnquiryList({enquiryList}) {
//   return (
//     <div style={{ overflow: 'auto', maxHeight: '500px'}}>
//    <Table stickyHeader sx={{my:3,borderRadius:"20px",overflow:"hidden"}}>
//         <TableHead>
//           <TableRow>
//             {/* <StyledTableCell
//               sx={{
//                 pl: 3,
//                 fontSize: "1rem",
//                 fontWeight: 600,
//                 textAlign: "left",
//                 width: "5%",
//               }}
//             >
//               S.No
//             </StyledTableCell> */}
//             <StyledTableCell
//               sx={{
//                 pl: 2,
//                 fontSize: "1rem",
//                 fontWeight: 600,
//                 textAlign: "center",
//                 width: "21%",
//               }}
//             >
//             Enquiry Type
//             </StyledTableCell>
//             <StyledTableCell
//               sx={{
//                 pl: 3,
//                 fontSize: "1rem",
//                 fontWeight: 600,
//                 textAlign: "center",
//                 width: "16%",
//               }}
//             >
//               Assigned Lawyer
//             </StyledTableCell>
//             <StyledTableCell
//               sx={{
//                 pl: 3,
//                 fontSize: "1rem",
//                 fontWeight: 600,
//                 textAlign: "center",
//                 width: "16%",
//               }}
//             >
//                 FIR Number
//             </StyledTableCell>
//             <StyledTableCell
//               sx={{
//                 pl: 3,
//                 fontSize: "1rem",
//                 fontWeight: 600,
//                 textAlign: "center",
//                 width: "16%",
//               }}
//             >
//               Court
//             </StyledTableCell>

//             <StyledTableCell
//               sx={{
//                 pl: 3,
//                 fontSize: "1rem",
//                 fontWeight: 600,
//                 textAlign: "center",
//                 width: "16%",
//               }}
//             >
//               Case Type
//             </StyledTableCell>
//             <StyledTableCell
//               sx={{ pl: 3, fontSize: "1rem", fontWeight: 600, width: "16%" }}
//               align={"center"}
//             >
//               Call Back 
//             </StyledTableCell>
//             <StyledTableCell
//               sx={{ pr: 3, fontSize: "1rem", fontWeight: 600, width: "10%" }}
//               align={"right"}
//             >
//               Action
//             </StyledTableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody sx={{
//            borderBottom:"2"
//         }
//         }>
//           {enquiryList &&
//             enquiryList.map((enquiry, index) => (
//               <EnquiryItem
//                 enquiryList={enquiryList}
//                 item={enquiry}
//                 key={index}
//                 index={index}
//               />
//             ))}
//         </TableBody>
//       </Table>
//     </div>
//   )
// }

// export default EnquiryList


import React from "react";
import EnquiryItem from "./EnquiryItem";
import { Grid } from "@mui/material";

const EnquiryList = ({
  enquiryList,
  setShowUpdate,
  showUpdate,
  setEnquiryDetails,
  setDetailItem,
}) => {
  return (
    <React.Fragment>
      <Grid
        container

        // spacing={2}
      >
        {enquiryList.map((enquiry, index) => {
          if (enquiry?.isActive) {
            return (
              <Grid item xs={12} md={12}>
                <EnquiryItem
                  key={enquiry.enquiryId}
                  item={enquiry}
                  setShowUpdate={setShowUpdate}
                  showUpdate={showUpdate}
                  setEnquiryDetails={setEnquiryDetails}
                  setDetailItem={setDetailItem}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </React.Fragment>
  );
};

export default EnquiryList;
