import React from "react";
import { Paper, Typography, Link, MenuItem, Select } from "@mui/material";
import { useState, useEffect } from "react";
import useTasks from "app/hooks/useTasks";
import Div from "@jumbo/shared/Div/Div";
import TaskDashList from "./TasksDashList";
import CircularTabs from "../../CircularTabs/CircularTabs";
import NoDataDisplay from "../NoDataDisplay/NoDataDisplay";
import TasksSkeletons from "./TasksSkeletons";

const tabs = [
  {
    tagLabel: "High",
    
  },
  {
    tagLabel: "Medium",
  },
  {
    tagLabel: "Low",
  },
];
const optionsPageSize = ["10", "20","50"];
const TaskList = ({basicFilter,endDate,startDate,dateRangeStatus,count,setTotalCountTask,totalCountTask}) => {
  const [taskCount, setTaskCount] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState("5");


  const handleChange = (e, v) => {
    setValue(v);
  };
  const getAllTasks = useTasks();

  useEffect(() => {
    const getAllTasksList = async () => {
      setIsLoading(true);
      const response = await getAllTasks(0,pageSize,basicFilter,startDate,endDate);
      if (response) {
        console.log(response);
        setTotalCountTask(response?.data?.totalItems)
        setTaskList(response?.data?.tasks);
        const initValue = {
          high:0,
          medium:0,
          low:0,
        }
        const taskCount =  response?.data?.tasks.reduce((initValue,item)=>{
          if (item.priority === 2){
            initValue.high++
          }
          if (item.priority === 1){
            initValue.medium++
          }
          if (item.priority === 0){
            initValue.low++
          }
          console.log(item.priority)
          return initValue
        },initValue)
        console.log(taskCount)
        console.log(response?.data?.tasks);
        setIsLoading(false);
        if(taskCount.high>0){
          setValue(0)
        }else if(taskCount.medium>0){
          setValue(1)
        }
        else if(taskCount.low>0){
          setValue(2)
        }else {
          setValue(0)
        }
        setTaskCount(taskCount)
      }
    };
    if(basicFilter!=="Choose Date"){
      getAllTasksList()
  }
  else if(basicFilter==="Choose Date"){
    if(startDate && endDate){
      getAllTasksList()
    }
  }
  }, [
    // pageSize,
    basicFilter,dateRangeStatus]);
  console.log(taskCount) 

  let tasks = taskList.filter(function (el) {
    if (value === 0) {
      return el.priority === 2
    }
    if (value === 1) {
      return el.priority === 1
    }
    if (value === 2) {
      return el.priority === 0
    }
  }
  );

     

  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#f2f2f3",
          p: 3,
          color: "black",
          borderRadius: "20px",
        }}
        elevation={20}
      >
        <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Div sx={{ display: "flex", alignItems: "center" }}>
          <Div
            style={{
              height: "19px",
              width: "5px",
              backgroundColor: "#EABF4E",
              marginRight: "8px",
            }}
          ></Div>
          <Typography
            sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 400 }}
          >
            Tasks ({count?count:totalCountTask})
          </Typography>
        </Div>
        <Div sx={{display:"flex",gap:1,alignItems:"center"}}>
          {/* <Div>
            <Select
              labelId="select-label"
              value={pageSize}
              sx={{ maxHeight: "30px", mr: "1px" }}
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
            >
              {optionsPageSize.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Div> */}
          <Div>
            <Link
              href="/lawyers/appointments"
              underline="none"
              lineHeight={1.2}
              fontSize={"16px"}
              fontWeight={700}
              color={"#EABF4E"}
            >
              View All
            </Link>
          </Div>
        </Div>
      </Div>

        <Div sx={{ mt: 3 }}>
          {/* reaplce '3' when pageSize is implemented */}
          {isLoading ? Array.from(Array(parseInt("3"))).map((item, index) => {
            return <TasksSkeletons key={index} />
          }) : taskList.length > 0 && !isLoading ? <>
          <Div sx={{ display: "flex", justifyContent: "center", my: 1 }}>
            <CircularTabs
              value={value}
              handleChange={handleChange}
              tabs={tabs}
              count={taskCount}
              fromTask={true}
              fromAppointment={false}
            />
          </Div>
          <TaskDashList taskList={tasks} />
        </> : (
            <NoDataDisplay
              text={"No Tasks"}
              url={"/lawyers/todo?addTask=true"}
              buttonText={"Add Tasks"}
              minHeight={"200px"}
            />
          )}

          {/* {taskList.length > 0 && (
            <>
              <Div sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                <CircularTabs
                  value={value}
                  handleChange={handleChange}
                  tabs={tabs}
                  count={taskCount}
                  fromTask={true}
                  fromAppointment={false}
                />
              </Div>
              <TaskDashList taskList={tasks} />
            </>
          )} */}
        </Div>
      </Paper>


    </>
  );
};
export default TaskList;
