import React from "react";
import { CaseData } from "./CaseData";
import CaseCard from "./CaseCard";
const CaseList = ({allCaseList,setUpdate,values}) => {
  return (
    <>
      {allCaseList.map((team) => {
        if(team.isActive){
        return <CaseCard key={team.id} item={team} setUpdate={setUpdate} values={values}/>
        }
})}
    </>
  );
};

export default CaseList;
