import React, { Suspense, lazy, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCaseByCaseId } from "app/Apis/case";
import Cookies from "js-cookie";
import Div from "@jumbo/shared/Div";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import HomeIcon from "@mui/icons-material/Home";
import { GavelOutlined } from "@mui/icons-material";
import {
  Breadcrumbs,
  Card,
  Divider,
  Typography,
  Link,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";

import ListAltIcon from "@mui/icons-material/ListAlt";
import { useDispatch } from "react-redux";
import { updateCaseListItem } from "app/redux/actions/case";
import { DraftingEdit } from "./DraftingEdit";
import CorporateEdit from "./CorporateEdit";

const DocumentEdit = lazy(() => import("./DocumentEdit"));
const CaseEdit = lazy(() => import("./CaseEdit"));
const ECourtDetailsEdit = lazy(() => import("./ECourtDetailsEdit"));
const AppointmentEdit = lazy(() => import("./AppointmentEdit"));
const CasePaymentEdit = lazy(() => import("./PaymentEdit"));
const ClientEdit = lazy(() => import("./ClientEdit"));
const OppositeClientEdit = lazy(() => import("./OppClientEdit"));
const WitnessEdit = lazy(() => import("./WitnessEdit"));
const TaskEdit = lazy(() => import("./TaskEdit"));
const HearingEdit = lazy(() => import("./HearingEdit"));
const RelatedJudgement = lazy(() => import("./RelatedJudgement"));

const LoadingDiv = () => {
  return (
    <Div
      sx={{
        pl: { sm: "0", lg: "8%" },
        pr: { sm: "0", lg: "8%" },
        mt: 2,
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CircularProgress color="secondary" />
      <Typography variant={"h5"}>Loading</Typography>
    </Div>
  );
};

const CaseDetailAndEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(location.search);
  const caseId = queryParameters.get("caseId");
  const redirect = queryParameters.get("redirect");
  const menuValue = queryParameters.get("value");
  const [caseDetails, setCaseDetails] = useState(null);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const disable = queryParameters.get("disable");
  const [buttonValue, setButtonValue] = React.useState(0);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  console.log(menuValue);
  const [formDisable, setFormDisable] = useState(false);
  const handleNextButton = () => {
    // ValidateValues();

    // if (error.length > 0) {
    if (value !== 12) {
      setValue((prevButtonValue) => prevButtonValue + 1);
    }

    setButtonValue((prevButtonValue) => prevButtonValue + 1);
    // }
  };
  const [openPaymentSnackBar, setOpenPaymentSnackBar] = useState(true);
  console.log("button value ", buttonValue);

  const handleBackButton = () => {
    if (value !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValue((prevButtonValue) => prevButtonValue - 1);
    } else {
      navigate("/lawyers/cases");
    }
  };
  const getValue = (v) => {
    if (v === 0) {
      return 3;
    } else if (v === 1) {
      return 2;
    } else if (v === 2) {
      return 4;
    } else if (v === 3) {
      return 8;
    } else if (v === 4) {
      // debugger;
      return 0;
    } else {
      return 0;
    }
  };
  const dispatch = useDispatch();

  const UpdateCaseListItem = (value) => {
    dispatch(updateCaseListItem(value));
  };
  const [value, setValue] = React.useState(menuValue ? getValue(menuValue) : 0);
  const getAllCases = async () => {
    setIsLoading(true);

    const accessToken = Cookies.get("accessToken");
    const response = await getCaseByCaseId(accessToken, caseId);

    // debugger

    console.log(response);
    if (response) {
      console.log(response);
      setCaseDetails(response[0]);
      UpdateCaseListItem(response[0])
    }
    setIsLoading(false);
  };
  const [snackBarText, setSnackBarText] = useState("");
  useEffect(() => {
    console.log(isLoading);
    getAllCases();
  }, [update]);
  useEffect(() => {
    // Set the initial tab value based on menuValue
    setValue(menuValue ? getValue(parseInt(menuValue)) : 0);
    console.log(value, buttonValue);
  }, [menuValue]);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  // useEffect(()=>console.log(openPaymentSnackBar),[openPaymentSnackBar])
  // const handlePaymentSnackbarClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   // setOpenSnackBar(false);
  // };
  useEffect(() => {
    if (disable === "1") {
      console.log("inside");
      setFormDisable(true);
    } else {
      setFormDisable(false);
    }
  }, [disable]);

  return (
    <>
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{
              width: "100%",
              pr: 3,
              pl: 3,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{snackBarText}</Typography>
          </Alert>
        </Snackbar>
      )}
      {/* {openPaymentSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          onClose={handlePaymentSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handlePaymentSnackbarClose}
            severity="success"
            sx={{
              width: "100%",
              pr: 3,
              pl: 3,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Payment Request Sent.</Typography>
          </Alert>
        </Snackbar>
      )} */}

      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/home" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>
        </Link>
        <Link underline="none" href="/lawyers/cases">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <ListAltIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Case List
          </Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <GavelOutlined sx={{ mr: 0.5, fontSize: 20 }} />
          Cases
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Div
                style={{
                  height: "19px",
                  width: "5px",
                  backgroundColor: "#EABF4E",
                  marginRight: "8px",
                }}
              ></Div>
              <Typography variant={"h5"}>
                Case Details -{" "}
                <span>
                  {`${caseDetails?.caseFirstName} V/s ${caseDetails?.caseSecondName}`}
                </span>{" "}
              </Typography>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />

          <Div
            sx={{
              pl: { sm: "0", lg: "8%" },
              pr: { sm: "0", lg: "8%" },
              mt: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Div
              sx={{
                maxWidth: { xs: 320, sm: 600, md: 700, lg: 1350 },
                mt: -1,
              }}
            >
              <Tabs
                indicatorColor="secondary"
                textColor="secondary"
                value={value}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{
                  maxWidth: "1000px",
                }}
              >
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Case"
                  onClick={() => {
                    setValue(0);
                    setButtonValue(0);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="E-Court Details"
                  onClick={() => {
                    setValue(1);
                    setButtonValue(1);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Hearing"
                  onClick={() => {
                    setValue(2);
                    setButtonValue(2);
                  }}
                />

                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Payment"
                  disabled={formDisable}
                  onClick={() => {
                    setValue(3);
                    setButtonValue(3);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Related Judgement"
                  onClick={() => {
                    setValue(4);
                    setButtonValue(4);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Appointment"
                  onClick={() => {
                    if (formDisable) {
                      setValue(5);
                      setButtonValue(5);
                    } else if (caseDetails?.clients?.length > 0) {
                      setValue(5);
                      setButtonValue(5);
                    } else {
                      setSnackBarText("Add client before adding appointments");
                      setOpenSnackBar(true);
                      setValue(9);
                      setButtonValue(9);
                    }
                  }}
                />

                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Task"
                  onClick={() => {
                    setValue(6);
                    setButtonValue(6);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Document"
                  onClick={() => {
                    setValue(7);
                    setButtonValue(7);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Legal Drafting"
                  onClick={() => {
                    setValue(8);
                    setButtonValue(8);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Client"
                  onClick={() => {
                    setValue(9);
                    setButtonValue(9);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Corporate Client"
                  onClick={() => {
                    setValue(10);
                    setButtonValue(10);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Opposite Client"
                  onClick={() => {
                    setValue(11);
                    setButtonValue(11);
                  }}
                />
                <Tab
                  sx={{
                    fontSize: { xs: "14px", md: "14px" },
                    fontWeight: "800",
                  }}
                  label="Witness"
                  onClick={() => {
                    setValue(12);
                    setButtonValue(12);
                  }}
                />
                
              </Tabs>
            </Div>
            {isLoading ? (
              <LoadingDiv />
            ) : (
              <Div
                sx={{
                  minHeight: "500px",
                  width: { xs: 280, sm: 600, md: 700, lg: 1000 },
                }}
              >
                {value === 0 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <CaseEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 1 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <ECourtDetailsEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setCaseDetails={setCaseDetails}
                      setUpdate={setUpdate}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 2 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <HearingEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 3 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <CasePaymentEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setSnackBarText={setSnackBarText}
                      setOpenSnackBar={setOpenSnackBar}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                      // setOpenPaymentSnackBar={setOpenPaymentSnackBar}
                    />
                  </Suspense>
                ) : value === 4 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <RelatedJudgement
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 5 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <AppointmentEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 6 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <TaskEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 7 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <DocumentEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 8 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <DraftingEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setSnackBarText={setSnackBarText}
                      setOpenSnackBar={setOpenSnackBar}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 9 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <ClientEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 11 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <OppositeClientEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : value === 12 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <WitnessEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) :value === 10 ? (
                  <Suspense fallback={<LoadingDiv />}>
                    <CorporateEdit
                      caseDetails={caseDetails}
                      handleNextButton={handleNextButton}
                      handleBackButton={handleBackButton}
                      setUpdate={setUpdate}
                      setCaseDetails={setCaseDetails}
                      caseId={caseId}
                      setIsLoading={setIsLoading}
                      formDisable={formDisable}
                    />
                  </Suspense>
                ) : (
                  ""
                )}
              </Div>
            )}
          </Div>
        </Div>
      </Card>
    </>
  );
};

export default CaseDetailAndEdit;
