import { getSettings } from "app/Apis/settings";


const useSettings = ()=>{
    const getSetting = async(lawyerId,accessToken)=>{
        const response = await getSettings(lawyerId,accessToken);
     
        if(response){
            
            return response[0];
        }
        else{
            console.log("Problem getting settingd")
        }
        return null;
    }
    return getSetting;
}
export default useSettings;