import Div from "@jumbo/shared/Div";
import React, { useState, useEffect, lazy } from "react";
import {Alert, Snackbar} from "@mui/material";
import { SvgIcon } from '@mui/material';
import {ReactComponent as DraftingIcon} from "../../ImageAssets/pngegg.svg"
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { DraftingListLayout } from "./draftingListLayout";
import { getDraftList } from "app/Apis/drafting";
import Cookies from "js-cookie";


const AddLegalDrafting  = lazy(()=>import("./addLegalDrafting"));
const EditLegalDrafting = lazy(()=>import("./EditLegalDrafting"));



const optionsPageSize = ["5", "10", "20", "25", "50"];
// const optionsFilter = ["Select","Today","Yesterday","Tomorrow", "This Week","This Month","Choose Date"];
const LegalDrafting = () => {
  const [pageSize, setPageSize] = useState("5");
  const [AddApp, setAddApp] = useState(false);
  const [draftList, setDraftList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [editItem, setEditItem] = useState(null)
  const [edit , setEdit  ] = useState(false)
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackBarText, setSnackBarText] = useState("")
  const [pageNumber, setPageNumber] = useState(0)
  const [totalPages,setTotalPages] = useState(0)
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    const getDrafts = async () => {
      setIsLoading(true)
      const response = await getDraftList(accessToken);
      console.log(response);
      if (response) {
        setDraftList(response.data.drafts);
        setTotalPages(response.data.totalPages)
      }
      setIsLoading(false)
    };
    getDrafts();
  }, []);
  useEffect(() => {
    const getDrafts = async () => {
      setIsLoading(true)
      const response = await getDraftList(accessToken);
      console.log(response);
      if (response) {
        setDraftList(response.data.drafts);
      }
      setIsLoading(false)
    };
    getDrafts();
  }, [update]);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
if(editItem){
  return <EditLegalDrafting setEdit={setEdit} setEditItem={setEditItem} item={editItem} setUpdate={setUpdate} setSnackBarText={setSnackBarText}
  setOpenSnackBar={setOpenSnackBar}/>

}

if(AddApp){
return <AddLegalDrafting setAddApp={setAddApp} setUpdate={setUpdate} setSnackBarText={setSnackBarText}
setOpenSnackBar={setOpenSnackBar}/>
}
  return (
    <>
    {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{
              width: "100%",
              pr: 3,
              pl: 3,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{snackBarText}</Typography>
          </Alert>
        </Snackbar>
      )}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/home" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <SvgIcon component={DraftingIcon} sx={{ fontSize: 20,mr:1 }} viewBox="0 0 700 600" />
          {/* <GradingOutlinedIcon sx={{ fontSize: 20 }}  /> */}
          &nbsp;Legal Drafting
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
          minHeight: "90%",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <Div
                sx={{
                  display: { xs: "none", sm: "flex" },
                  alignItems: "center",
                }}
              >
                <Div
                  style={{
                    height: "19px",
                    width: "5px",
                    backgroundColor: "#EABF4E",
                    marginRight: "8px",
                  }}
                ></Div>
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    lineHeight: 1.5,
                    fontWeight: 400,
                  }}
                >
                  All Drafts
                </Typography>
              </Div>
              <Button
                style={{ fontWeight: "700" }}
                startIcon={<AddIcon />}
                color="secondary"
                onClick={() => setAddApp(true)}
              >
                Add Draft
              </Button>
            </Div>
            {/* <Div
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Div
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  paddingTop: "6px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: { xs: "none", sm: "block" },
                  }}
                >
                 Page Size
                </Typography>
              </Div>
              <Div>
                <Select
                  labelId="select-label"
                  value={pageSize}
                  sx={{ backgroundColor: "white", ml: 2 }}
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  {optionsPageSize.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Div>
            </Div> */}
          </Div>
          <Divider sx={{ mt: 2 }} />
          <DraftingListLayout draftList={draftList} isLoading={isLoading} setAddApp={setAddApp} pageSize={pageSize} setEdit={setEdit} setEditItem={setEditItem}/>
          <Div
              sx={{
                position: "absolute",
                bottom: 20,
                right: { sm: 0, lg: 25 },
              }}
            >
                {/* {draftList.length > 0 && (
                  <CardFooter
                    totalPages={totalPages}
                    setPageNumber={setPageNumber}
                  />
                )} */}
            </Div>
        </Div>
      </Card>
    </>
  );
};

export default LegalDrafting;
