import { Typography, Radio, FormControlLabel, Box } from '@mui/material';
import Bank from './bank';
import UPI from './upi';
import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";

const AddBankForm = ({ setSnackBarMessage,setOpenSnackBar,setCardOpen, setInitSettings, initSettings, setFormOpen }) => {
  console.log(initSettings);
  const [selectedOption, setSelectedOption] = useState('Bank Account');
  const theme = useTheme();

  console.log(selectedOption);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <FormControlLabel
          control={
            <Radio
              checked={selectedOption === 'UPI ID'}
              onChange={handleOptionChange}
              value="UPI ID"
              sx={{
                color: theme.palette.secondary.main,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
            />
          }
          label="Add UPI Id"
        /> */}

        <FormControlLabel
          control={
            <Radio
              checked={selectedOption === 'Bank Account'}
              onChange={handleOptionChange}
              value="Bank Account"
              sx={{
                color: theme.palette.secondary.main,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
            />
          }
          label={
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              Add Bank account
              {/* <CloseIcon
                sx={{
                  ml: 50,
                      fontSize: "24px",
                  position: 'relative',
                  top: '-2px',
                }}
              
              onClick={() => {setFormOpen(false);
              setCardOpen(true);
              } }
              /> */}
            </Box>
          }
        />
      </Box>

      {selectedOption === 'Bank Account' && <Bank setOpenSnackBar={setOpenSnackBar} setInitSettings={setInitSettings} initSettings={initSettings} setFormOpen={setFormOpen} setSnackBarMessage={setSnackBarMessage} />}
      {selectedOption === 'UPI ID' && <UPI setOpenSnackBar={setOpenSnackBar} setInitSettings={setInitSettings} initSettings={initSettings} setFormOpen={setFormOpen} setSnackBarMessage={setSnackBarMessage} />}
    </>
  )
}

export default AddBankForm;