import React, { useEffect, useState } from "react";
import { CardContent, TextField, Typography } from "@mui/material";
import GoogleNest from "../../../shared/widgets/GoogleNest";
import Link from "@mui/material/Link";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import SwipeableTextMobileStepper from "../../components/SwipeableTextMobileStepper";
import Otp from "../otp";
import SocialButtons from "app/pages/components/SocialButtons";
import LoginFooter from "app/pages/components/LoginFooter";
import { generateOtp } from "app/Apis/Login";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Animation from "app/pages/components/Animations/Animation";
import PersonIcon from "@mui/icons-material/Person";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import Cookies from "js-cookie";
import ChatBot from "app/Components/ChatBot/ChatBot";
import useLogin from "app/hooks/useLogin";
import { ChatBubble } from "@mui/icons-material";

const Login = ({ disableSmLogin, editNumber }) => {
	const isBelow990px = useMediaQuery((theme) => theme.breakpoints.down(990));
	const navigate = useNavigate();
	const login = useLogin(true);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	useEffect(() => {
		async function setUser(jwt) {
			await login(jwt);
			Cookies.set("accessToken", jwt,{expires:1});
			const isLoggedIn = Cookies.get("isLoggedIn");
			if (isLoggedIn) {
				const role = Cookies.get("assignedRole");
				if (role === "ROLE_LAWYER") {
					if (queryParams.get("path") === "chatbot") {
						navigate("/lawyers/chatbot?isLastTrue=true");
					} else {
						navigate("/lawyers/dashboards");
					}
				} else if (role === "ROLE_CLIENT") {
					navigate("/clients");
				} else if (role === "ROLE_MEMBER") {
					navigate("/onboarding");
				}
			}
		}
		if (!queryParams.get("accessToken")) {
			const isLoggedIn = Cookies.get("isLoggedIn");
			if (isLoggedIn) {
				const role = Cookies.get("assignedRole");
				if (role === "ROLE_LAWYER") {
					navigate("/lawyers/dashboards");
				} else if (role === "ROLE_CLIENT") {
					navigate("/clients");
				} else if (role === "ROLE_MEMBER") {
					navigate("/onboarding");
				}
			}
		} else {
			setUser(queryParams.get("accessToken"));
		}
	}, []);

	useEffect(() => {
		console.log("is mobile", disableSmLogin);
	}, [disableSmLogin]);
	const phoneUrl = queryParams.get("phoneNumber");
	const [isLoading, setIsLoading] = useState(false);
	const [showOtp, setShowOtp] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [error, setError] = useState("");
	const [openChatbot, setopenChatbot] = useState(false);

	const handleChange = (event) => {
		setPhoneNumber(event.target.value);
	};
	const getOtp = async () => {
		if (phoneNumber) {
			const response = await generateOtp(phoneNumber);
		} else {
			const response = await generateOtp(phoneUrl);
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (
			/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phoneNumber)
		) {
			setShowOtp(true);
			setError("");
			getOtp();
		} else {
			setError("Enter a valid phone number");
			setTimeout(() => {
				setError("");
			}, 4000);
		}
	};

	useEffect(() => {
		if (phoneUrl) {
			if (
				/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(phoneUrl)
			) {
				setShowOtp(true);
				getOtp();
			} else {
				setError("Enter a valid phone number");
			}
		}
		if (editNumber) {
			setPhoneNumber(editNumber);
		}
	}, [phoneUrl]);

	return (
		<>
			{isLoading ? <Animation /> : null}
			{showOtp ? (
				<Otp
					phoneNumber={phoneNumber ? phoneNumber : phoneUrl}
					disableSmLogin={disableSmLogin}
				/>
			) : (
				// <Div
				//   sx={{
				//     width: "100%",
				//     height: "100vh",
				//     margin: "auto",
				//     backgroundColor: "#FFFFFF",
				//   }}
				// >
				<Div
					sx={{
						backgroundColor: "#FFFFFF",
						margin: 0,
						display: "flex",
						flex: "1 1",
						minWidth: 0,
						flexDirection: { xs: "column", md: "row" },
						height: "100%",
						borderRadius: "0",
					}}
				>
					<Div
						sx={{
							flex: 1,
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<Div
							sx={{
								flex: "1",
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
								my: "10%",
								marginLeft: "auto",
								marginBottom: "0rem",
								marginRight: isBelow990px ? "auto" : "15%",
								width: "380px",
								padding: "20px",
							}}
						>
							<Div
								sx={{
									display: "flex",
									mb: 1,
								}}
							>
								<Link href="https://vakily.app">
									<img
										src={`${ASSET_IMAGES}/vakily-logo-black.png`}
										style={{
											objectFit: "contain",
											width: "74.33px",
											width: "74.33px",
										}}
										alt="Vakily"
									/>
								</Link>
							</Div>
							<Typography
								variant={"h2"}
								sx={{
									color: "#364a63",
									fontSize: "1.25rem",
									fontWeight: "700",
									fontFamily: "Nunito, sans-serif",
									mb: 2,
								}}
							>
								Access the Vakily App Panel
							</Typography>

							<form onSubmit={handleSubmit} autoComplete="off">
								<Div
									sx={{ mb: 4, color: "#3c4d62", fontWeight: "400", mt: 2.9 }}
								>
									<label>
										<TextField
											fullWidth
											id="Enter Mobile Number"
											label="Enter Mobile Number"
											type="tel"
											color="secondary"
											onChange={handleChange}
											value={phoneNumber}
											inputProps={{
												maxLength: 10,
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<PersonIcon />
													</InputAdornment>
												),
											}}
											required
										/>
									</label>
									{error && (
										<Div sx={{ display: "flex", alignItems: "center" }}>
											<ErrorOutlineIcon color="error" fontSize="small" />
											<Typography
												sx={{
													color: "red",
													fontSize: "12px",
													ml: 1,
												}}
											>
												{error}
											</Typography>
										</Div>
									)}
								</Div>

								{/* <LoadingButton
									fullWidth
									type="submit"
									variant="contained"
									size="large"
									disableElevation
									sx={{
										mb: 4,
										backgroundColor: "#EABF4E",
										position: "relative",
										letterSpacing: "0.02em",
										alignItems: "center",
										fontFamily: "Nunito, sans-serif",
										fontWeight: "900",
									}}
									// onClick={getOtp}
								>
									Get OTP
								</LoadingButton> */}

								<Div sx={{ display: "flex", alignItems: "center", mb: 2 }}>
									<Div sx={{ display: "flex" }}>
										<Typography
											sx={{
												fontFamily: "Roboto, sans-serif",
												fontSize: "0.875rem",
												fontWeight: "550",
												marginBottom: "0.5rem",
												color: "#344357",
											}}
										>
											By continuing, I agree to Vakily's{" "}
											<Link
												href="https://vakily.app/privacy.html"
												underline="none"
												sx={{
													color: "#EABF4E",
													cursor: "pointer",
													"&:hover": {
														color: "black",
													},
												}}
												target="_blank"
												rel="noreferrer"
											>
												Privacy Policy
											</Link>{" "}
											and{" "}
											<Link
												href="https://vakily.app/terms.html"
												underline="none"
												sx={{
													color: "#EABF4E",
													"&:hover": {
														color: "black",
													},
												}}
												target="_blank"
												rel="noreferrer"
											>
												Terms & Conditions
											</Link>
											, and receive communication from Vakily via SMS, E-Mail and WhatsApp
										</Typography>
									</Div>
								</Div>
								<LoadingButton
									fullWidth
									type="submit"
									variant="contained"
									size="large"
									disableElevation
									sx={{
										mb: 4,
										backgroundColor: "#EABF4E",
										position: "relative",
										letterSpacing: "0.02em",
										alignItems: "center",
										fontFamily: "Nunito, sans-serif",
										fontWeight: "900",
									}}
									// onClick={getOtp}
								>
									Get OTP
								</LoadingButton>
							</form>
							{/* 
              <React.Fragment>
                <Typography
                  variant={"h6"}
                  mb={2}
                  pt={4}
                  pb={3}
                  sx={{
                    textAlign: "center",
                    paddingTop: "0",
                    paddingBottom: "0",
                    color: "#B6C6E3",
                    fontSize: "11px",
                    fontWeight: "700",
                    lineHeight: "1.2",
                    letterSpacing: "0.2em",
                  }}
                >
                  <span>-OTHER LOGIN OPTION-</span>
                </Typography>
                <SocialButtons />
              </React.Fragment> */}
						</Div>
						<Div
							sx={{
								flex: "1 ",
								display: "flex",
								my: "10%",
								width: "380px",
								maxHeight: "25%",
								marginLeft: "auto",
								marginBottom: "2%",
								marginRight: isBelow990px ? "auto" : "15%",
								marginTop: 0,
								padding: "20px",
								justifyContent: "center",
								alignContent: "center",
							}}
						>
							<LoginFooter />
						</Div>
					</Div>
					{!disableSmLogin && (
						<Div
							sx={{
								height: "100%",
								backgroundColor: "#f5f6fa",
								display: "flex",
								width: "53%",
								position: "relative",
								alignItems: "center",
								justifyContent: "center",
								flexWrap: "wrap",
								padding: "2%",
								zIndex: "1",
								textAlign: "center",
							}}
						>
							<SwipeableTextMobileStepper />
						</Div>
					)}
				</Div>
				// </Div>
			)}

			{/* <div
				style={{
					// display: "none",
					display: openChatbot ? "none" : "inline",
					position: "fixed",
					right: "50px",
					bottom: "50px",
					zIndex: "1000",
				}}
			>
				<button
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
						padding: "10px 16px",
						borderRadius: "8px",
						border: "2px solid black",
						background: "white",
						cursor: "pointer",
					}}
					onClick={() => {
						setopenChatbot(true);
					}}
				>
					<ChatBubble />
					<h4 style={{ margin: 0 }}>Legal Chat</h4>
				</button>
			</div>
			<div
				style={{
					display: openChatbot ? "inline" : "none",
					position: "fixed",
					right: "50px",
					bottom: "50px",
					zIndex: "1000",
				}}
			>
				<ChatBot setopenChatbot={setopenChatbot} />
			</div> */}
		</>
	);
};

export default Login;
