import axios from "axios";
import { apiHeader, apiUrl } from "app/config";
import Cookies from "js-cookie";

export const createEnquiry = async (body) => {
  try {
    const Body = {
      ...body,
      isActive: 1,
    };

    const response = await axios.post(`${apiUrl}/enquiry`, Body);

    // if (response.status === 200) {
    console.log("Enquiry Created", response);
    return response;
    // }
  } catch (error) {
    console.log(error);
  }
};
export const completeEnquiry = async (body) => {
  try {
    const accessToken = Cookies.get("accessToken");
    let headers = new Headers();
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const params = new URLSearchParams();
    params.append("isPay", 1);
    params.append("platform", "cloud");
    const url = apiUrl + "/enquiry";
    const urlWithParams = `${url}?${params.toString()}`;
    const Body = {
      ...body,
      isActive: 1,
    };

    const response = await axios.post(urlWithParams, Body, { headers });

    // if (response.status === 200) {
    console.log("Enquiry Completed", response);
    return response?.data?.data;
    // }
  } catch (error) {
    console.log(error);
  }
};
export const getEquiryList = async (
  page,
  pageSize,
  courtType = null,
  enquiryType = null,
  caseType = null,
  firNo = null,
  mobileNo = null
) => {
  try {
    const accessToken = Cookies.get("accessToken");
    let headers = new Headers();
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("size", pageSize);
    if (caseType) {
      params.append("caseType", caseType);
    }
    if (enquiryType) {
      params.append("enquiryTypes", enquiryType);
    }
    if (courtType) {
      params.append("courtType", courtType);
    }
    if (firNo) {
      params.append("firComplaintNo", firNo);
    }
    if (mobileNo) {
      params.append("searchTerm", mobileNo);
    }
    // headers.append("Content-Type", "application/json");
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const url = apiUrl + "/enquiry/list/cc";
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await fetch(urlWithParams, {
      method: "GET",
      headers,
      // body: JSON.stringify(obj),
    });

    // console.log(response);
    if (response.status === 200) {
      const data = await response.json();
      console.log("enquiry list fetched");
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const getEnquiryPaymentList = async (enquiryId, size = 10) => {
  try {
    if (!enquiryId) {
      throw Error("No enquiry Id provided");
    }
    const accessToken = Cookies.get("accessToken");
    const params = new URLSearchParams();
    params.append("enquiryId", enquiryId);
    params.append("size", size);
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const url = apiUrl + "/payment/list";
    const urlWithParams = `${url}?${params.toString()}`;
    const response = await axios.get(urlWithParams, { headers });

    // console.log(response);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    console.log(e);
  }
};

export const addEnquiryNotes = async (body) => {
  try {
    const accessToken = Cookies.get("accessToken");
    let headers = new Headers();
    headers.append("x-api-key", apiHeader);
    headers.append("Authorization", "Bearer " + accessToken);
    const params = new URLSearchParams();
    // params.append("isPay", 1);
    // params.append("platform", "cloud");
    params.append("pageNo", 3);
    const url = apiUrl + "/enquiry";
    const urlWithParams = `${url}?${params.toString()}`;
    const Body = {
      ...body,
    };

    const response = await axios.post(urlWithParams, Body, { headers });

    // if (response.status === 200) {
    console.log("Enquiry Completed", response);
    return response?.data?.data;
    // }
  } catch (error) {
    console.log(error);
  }
};


export const getEnquiryDetailsById = async(enquiryId)=>{
  try{
    if(!enquiryId){
      throw Error("No id ");
    }
    const accessToken = Cookies.get("accessToken");
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.get(apiUrl+`/enquiry/${enquiryId}`,{headers});
    console.log(response);
    if(response.status===200){
      return response?.data;
    }
    return null;

  }catch(err){
    console.log(err);
  }
}
