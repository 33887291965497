import { getAssetPath } from "../../utils/appHelpers";
import { ASSET_AVATARS } from "../../utils/constants/paths";
import lawyerImage from "../../ImageAssets/lawyer.png";

export const teamDataList = [
  {
    id: 1,
    name: "MY team 1",
    typeOfLawyer: "Jaya",
    ongoingCases: "28",
    practiceCourt: "High Court",
    NextHearing: "10-Nov-2019",
    isArchive:false,
    profilePic: getAssetPath(`${ASSET_AVATARS}/lawyer.png`, "40x40"),
  },
  {
    id: 2,
    name: "My team 2",
    typeOfLawyer: "Ashish",
    ongoingCases: "15",
    practiceCourt: "High Court",
    NextHearing: "10-Dec-2023",
    isArchive:false,
    profilePic: getAssetPath(`${ASSET_AVATARS}/lawyer.png`, "40x40"),
  },
  {
    id: 3,
    name: "Sanjay Singh Teams",
    typeOfLawyer: "Sanjay Singh",
    ongoingCases: "20",
    practiceCourt: "Supreme Court",
    NextHearing: "14-Nov-2023",
    isArchive:false,
    profilePic: getAssetPath(`${ASSET_AVATARS}/lawyer.png`, "40x40"),
  },
  {
    id: 4,
    name: "Soniya Singh teams",
    typeOfLawyer: "Soniya Singh",
    ongoingCases: "20",
    practiceCourt: "Supreme Court",
    NextHearing: "24-Aug-2023",
    isArchive:false,
    profilePic: getAssetPath(`${ASSET_AVATARS}/lawyer.png`, "40x40"),
  },
  {
    id: 5,
    name: "Pragya Singh Teams",
    typeOfLawyer: "Pragya Singh",
    ongoingCases: "20",
    practiceCourt: "High Court",
    NextHearing: "18-Feb-2024",
    isArchive:false,
    profilePic: getAssetPath(`${ASSET_AVATARS}/lawyer.png`, "40x40"),
  },
  {
    id: 6,
    name: "Rishabh Thakur",
    typeOfLawyer: "Lawyer Type 4",
    ongoingCases: "20",
    practiceCourt: "High Court",
    NextHearing: "18-Nov-2023",
    isArchive:true,
    profilePic: getAssetPath(`${ASSET_AVATARS}/lawyer.png`, "40x40"),
  },
];
