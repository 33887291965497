import React from "react";
import { Paper, Skeleton } from "@mui/material";
import Div from "@jumbo/shared/Div";

const AppointmentSkeletons = () => {
  return (
    <Div sx={{height:"100%"}}>
          <Paper sx={{ mb: 2, p: 2 }} elevation={0}>
            <Skeleton />
            <Skeleton animation={"wave"} />
            <Skeleton animation={false} />
          </Paper>
    </Div>
  );
};

export default AppointmentSkeletons;
