import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { alpha, Checkbox, Paper, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import ActionsMenu from "./ActionsMenu";
import { formatDate } from "app/utils/dateTimeHelpers";
import { useNavigate } from "react-router-dom";
import {Modal,Box,Fade,Backdrop,Button} from "@mui/material";
import { useParams } from "react-router-dom";
import { revokeCaseAccess } from "app/Apis/teams";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  // marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CaseCard = ({ item ,setUpdate,caseIds,isAssignCase,setIsLoading}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [checked, setChecked] = React.useState(isAssignCase?false:true);
  const {lawyerId} = useParams();
  const navigate = useNavigate()
  console.log(item)
  const handleExpandClick = () => {
    navigate(`/lawyers/casedetail?caseId=${item?.caseId}`)
  }
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);
  const handleRevoke = async()=>{
    setIsLoading(true);
      const response = await revokeCaseAccess(lawyerId,item?.caseId);
    if(response){
      setUpdate((prev)=>!prev);
    }
  }

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      sx={{
        mt: 1,
        cursor:"default",
        "&:hover": {
          boxShadow: `0 3px 10px 0 ${alpha("#000", 0.2)}`,
          transform: "translateY(-1px)",
          borderBottomColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          px: 1,
          flexDirection: "row-reverse",
          cursor:"default",
         

          "& .MuiAccordionSummary-content": {
            alignItems: "center",
            m:0,
            padding: "12px 0",
            "&.Mui-expanded": {
              padding: "12px 0",
            },
            cursor:"default",

          },
          ".MuiAccordionSummary-expandIconWrapper": {
            borderRadius: 1,
            border: 1,
            color: "text.secondary",
            borderColor: "divider",
            transform: "none",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            mr: 1,

            "&.Mui-expanded": {
              transform: "none",
              color: "primary.main",
              borderColor: "primary.main",
            },

            "& svg": {
              fontSize: "1.25rem",
            },
            "&:hover": {
              cursor:"default"
            },
          },
        }}
      >
        <ExpandMore
          expand={expanded}
        //   onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >

          {isAssignCase
            ?(<Checkbox
          checked={checked}
          onChange={(e)=>{
            setChecked(e.target.checked);
            if(e.target.checked){
              caseIds.push(item?.caseId);
            }
            else{
              caseIds = caseIds.filter((id)=>id!=item?.caseId);
            }
          }}
          />)
          :expanded ? <RemoveIcon /> : <AddIcon />
          }
          
        </ExpandMore>
        <Div
          sx={{
            width: { xs: "auto", sm: "20%" },
            flexShrink: 0,
            px: 1,
            flex: { xs: "1", lg: "0 1 auto" },
          }}
        >
          <Typography mb={0.5} fontSize={18} fontWeight="700" color="primary">
            {item.caseFirstName} VS {item.caseSecondName}
          </Typography>
          <Typography
            variant={"h3"}
            color={"text.secondary"}
            fontSize={14}
            fontWeight="700"
          >
            Section&nbsp;{item.sections?.map((section)=>(`${section?.sectionName} `))}
          </Typography>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "10%",
            flexShrink: 0,
            px: 2,
          }}
        >
          <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Stage
            </Typography>
            <Div sx={{ display: "flex", gap: 1 }}>
              <AccountBalanceIcon color="secondary" />
              <Typography noWrap fontSize={"14px"}>{item.ecourtDetails?.caseStage?.purposeHearing?item.ecourtDetails?.caseStage?.purposeHearing: "Case Stage not updated"}</Typography>
            </Div>
        </Div>

        <Div
          sx={{
            display: { xs: "none", lg: "flex" },
            width: "15%",
            flexShrink: 0,
            px: 2,
            gap: 5,
          }}
        >
          <Div>
          <Chip
            label={item.caseType?.caseName}
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ fontSize: 14, fontWeight: "700",ml:"12px" }}
          />
            {/* <Typography fontSize={"14px"}>{item.caseType?.caseName}</Typography> */}
          </Div>
          {/* <Div>
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Lawyer
            </Typography>
            <Typography fontSize={"14px"}>{item.isPetitioner ? "Respondent" : "Petitioner"}</Typography>
          </Div> */}
        </Div>
          <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
          >
            <Typography
              fontSize={"16px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Court
            </Typography>
            <Div sx={{ display: "flex", gap: 1 }}>
              <AccountBalanceIcon color="secondary" />
              <Typography fontSize={"14px"}>{item.currentCourt?.courtName}</Typography>
            </Div>
          </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Last Hearing Date
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <CalendarMonthIcon color="secondary" />
            <Typography fontSize={"14px"}>{item.ecourtDetails?.lastHearingDate? formatDate(item.ecourtDetails?.lastHearingDate) : "Date not given."}</Typography>
          </Div>
        </Div>
        <Div
          sx={{
            display: { xs: "none", lg: "block" },
            width: "15%",
            flexShrink: 0,
            px: 1,
          }}
        >
          <Typography
            fontSize={"16px"}
            variant={"h6"}
            color={"text.secondary"}
            mb={0.25}
          >
            Next Hearing Date
          </Typography>
          <Div sx={{ display: "flex", gap: 1 }}>
            <CalendarMonthIcon color="secondary" />
            <Typography fontSize={"14px"}>{item.ecourtDetails?.hearingDate ? formatDate(item.ecourtDetails?.hearingDate): "Date not given"}</Typography>
          </Div>
        </Div>

        {!isAssignCase && (
        <Div sx={{display:"flex",gap:1}}>
          <Checkbox
          checked={checked}
          onChange={(e)=>{
            setChecked(e.target.checked);
            if(!e.target.checked){
              handleOpen();
              // revokeAccess(item?.caseId);
            }
          }}
          />
          {/* <ActionsMenu caseId={item?.caseId}/> */}
        </Div>
        )}
        <Div>
        <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            minWidth: 300,
            maxWidth: 500,
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" id="delete-modal-title" gutterBottom>
            Are you sure you want to revoke access for  this Case?
          </Typography>
          <Typography variant="body1" id="delete-modal-description">
            This action cannot be undone.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenModal(false);
                // handleDotClick(option);
                handleRevoke();
              }}
              sx={{ mr: 2 }}
            >
              Revoke
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
        </Div>
      </AccordionSummary>

      <Collapse
        in={expanded}
        timeout="auto"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          p: (theme) => theme.spacing(2, 2, 2, 2),
        }}
        unmountOnExit
      >
        <Typography variant={"h5"}>Details</Typography>
        <Div
          sx={{
            display: { xs: "flex", lg: "none" },
            minWidth: 0,
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
              mb: 2,
              flexWrap: "wrap",
            }}
          >
            <Div>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Client
              </Typography>
              <Typography variant={"body1"}>{item.client}</Typography>
            </Div>
            <Div>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Lawyer
              </Typography>
              <Typography variant={"body1"}>{item.lawyer}</Typography>
            </Div>
            <Div>
              <Typography
                fontSize={"12px"}
                variant={"h6"}
                color={"text.secondary"}
                mb={0.25}
              >
                Court
              </Typography>
              <Typography variant={"body1"}>{item.court}</Typography>
            </Div>
          </Div>
          <Div sx={{ mb: 2, display: { xs: "inline-block", lg: "none" } }}>
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              Status
            </Typography>
            <Chip
              label={`${item.status}`}
              color="chipColor"
              size="small"
              sx={{ fontSize: 12, fontWeight: "500" }}
            />
          </Div>

          <Div sx={{ mb: 3, maxWidth: 280 }}>
            <Div
              sx={{
                display: { xs: "flex", lg: "none" },
                flexDirection: { xs: "column", lg: "row" },
                gap: 3,
              }}
            >
              <Div>
                <Typography
                  fontSize={"16px"}
                  variant={"h6"}
                  color={"text.secondary"}
                  mb={0.25}
                >
                  Starting Hearing
                </Typography>
                <Div sx={{ display: "flex", gap: 1 }}>
                  <CalendarMonthIcon color="secondary" />
                  <Typography fontSize={"14px"}>{item.startingDate}</Typography>
                </Div>
              </Div>
              <Div>
                <Typography
                  fontSize={"16px"}
                  variant={"h6"}
                  color={"text.secondary"}
                  mb={0.25}
                >
                  Next Hearing
                </Typography>
                <Div sx={{ display: "flex", gap: 1 }}>
                  <CalendarMonthIcon color="secondary" />
                  <Typography fontSize={"14px"}>{item.NextHearing}</Typography>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>

        <Typography variant={"body1"} color={"text.secondary"}>
          {item.name}
        </Typography>
      </Collapse>
    </Paper>
  );
};
/* Todo item prop define */
export default CaseCard;
