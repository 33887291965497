import { Button, Grid, Select, TextField, Typography } from "@mui/material";
import * as React from 'react';
import Div from "@jumbo/shared/Div";
import { MenuItem, FormControl, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import AppointmentList from "app/Components/Dashboard/Widgets/AppointmentsList/AppointmentList";
import TaskList from "app/Components/Dashboard/Widgets/TasksList/TasksList";
import PaymentsDash from "app/Components/Dashboard/Widgets/Payments/PaymentsDash/PaymentsDash";
import HearingList from "app/Components/Dashboard/Widgets/HearingsList/HearingList";
import ProfileDash from "app/Components/Dashboard/Display Icons/ProfileDash/ProfileDash";
import SalesOverview from "app/shared/metrics/SalesOverview/SalesOverview";
import CasesDash from "app/Components/Dashboard/Display Icons/CasesDash/CasesDash";
import GavelIcon from "@mui/icons-material/Gavel";
import EventIcon from "@mui/icons-material/Event";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import HomeIcon from "@mui/icons-material/Home";
import AppointmentHorizontal from "app/Components/Dashboard/Widgets/AppointmentsList/AppointmentHorizontal";
import { useSelector } from "react-redux";
import { getCasesReport } from "app/Apis/case";
import { getDashboardData, getSharedDashboardData } from "app/Apis/dashboard";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import NoTask from "../Todos/NoTask";
import NoHearings from "../MyHearings/NoHearings";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { RuleTwoTone, Today } from "@mui/icons-material";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import NorthEastIcon from "@mui/icons-material/NorthEast";
import DisplayCard from "app/Components/Dashboard/SharedDataDisplay/DisplayCard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const optionsFilter = ["Select","Today", "Yesterday", "Tomorrow", "This Week", "This Month", "Choose Date"];

function Home() {
  const [dateRangeStatus, setDateRangeStatus] = useState(false)
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [basicFilter, setBasicFilter] = useState(optionsFilter[0]);
  const [caseReport, setCaseReport] = useState(0);
  const [dashboardData, setDashboardData] = useState();
  const lawyerId = useSelector((state) => state?.onboardingInputs?.lawyerId);
  const [totalCountTask,setTotalCountTask]=useState(0);
  const [sharedData,setSharedData] = useState();

  const handleFilter = () => {
    getDashData()
    setDateRangeStatus(true)
  }
  const getDashData = async () => {
    const response = await getDashboardData(basicFilter, endDate, startDate)
    if (response) {
      setDashboardData(response)
    }
  }
  const getSharedData = async () => {
    const response = await getSharedDashboardData(basicFilter, endDate, startDate)
    if (response) {
      setSharedData(response);
    }
  }

  useEffect(() => {
    // const getCaseReport = async()=>{
    //   const response = await getCasesReport(lawyerId);
    //   console.log(response);
    //   if(response){
    //     setCaseReport(response.reduce((accumulator,report)=>{
    //       return accumulator+report.count
    //     },0));
    //   }
    // }
    // getCaseReport();
    if(basicFilter!=="Choose Date"){
      getDashData();
      getSharedData();
    }
    else if(basicFilter==="Choose Date"){
      if(startDate && endDate){
        getDashData();
        getSharedData();
    }
 
  }
  
    if (dateRangeStatus) {
      setDateRangeStatus(false)
    }
  }, [basicFilter,dateRangeStatus]);

  return (
    <>
      <Div sx={{ mx: "-24px", my: "-16px", mt: "-32px" }}>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Div >
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Home
            </Typography>
          </Div>
          <Div sx={{ display: "flex", gap: 1, alignItems: "center", paddingTop: "9px" }}>
            <Typography sx={{ fontWeight: "bold", display: { xs: "none", sm: "block" } }}>Filter By</Typography>
            <FormControl>
              <Select
                labelId="select-label"
                value={basicFilter}
                onChange={(e) => setBasicFilter(e.target.value)}
                sx={{ height: "35px", backgroundColor: "white", minWidth: "100px", mb: 1 }}
              >
                {optionsFilter.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {basicFilter === "Choose Date" &&
              <Div sx={{display: { xs: "grid", sm: "flex" }}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label="Start Date"
                    value={startDate}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(newValue) => setStartDate(newValue)}
                    maxDate={endDate}
                    sx={{ backgroundColor: "white", width: "9.5rem", mb: 1,px:1 }}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(newValue) => setEndDate(newValue)}
                    minDate={startDate}
                    sx={{ backgroundColor: "white", width: "9.5rem", mb: 1,px:1 }}
                  />
                </LocalizationProvider>

                {/* <BasicDatePicker  setFromDate={setFromDate}  setToDate={setToDate} /> */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "secondary.main",
                    mb: 1.2,
                    minWidth: "45px",
                    padding: "2px 0px",

                    "&:hover": {
                      color: "white",
                      backgroundColor: "black",
                    },
                  }

                  }
                  onClick={handleFilter}
                >

                  <ManageSearchIcon sx={{ fontSize: 30 }} />
                </Button>

              </Div>}
          </Div>
        </Div>
        <Grid container sx={{ minHeight: "100%" }} spacing={2}>
          <Grid item xs={12} lg={5}>
            <Grid container spacing={1.8}>
              <Grid item xs={12} lg={4}>
                <ProfileDash />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={1.8}>
                  <Grid item xs={6} lg={6}>
                    <CasesDash
                      title={dashboardData?.case}
                      subheader={"Cases"}
                      Icon={<GavelIcon style={{ color: "#EABF4E" }} />}
                      shared={sharedData?.teamCases}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <CasesDash
                      title={dashboardData?.hearing}
                      subheader={"Hearings"}
                      Icon={<EventIcon style={{ color: "#EABF4E" }} />}
                      shared={sharedData?.teamsHearing}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <CasesDash
                      title={dashboardData?.task?dashboardData?.task:totalCountTask}
                      subheader={"Tasks"}
                      Icon={<ListAltOutlinedIcon style={{ color: "#EABF4E" }} />
                      }
                      shared={sharedData?.teamAppointment}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <CasesDash
                      title={dashboardData?.appointment}
                      subheader={"Appointments"} sx={{ fontSize: 20 }}
                      Icon={<EventIcon style={{ color: "#EABF4E" }} />}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12} sx={{ marginTop: ".2rem" }}>
                <Grid container spacing={1.8}>
                      <Grid item xs={12} lg={4}>
                        <DisplayCard
                          title={`₹ ${dashboardData?.payment?.totalPaymentReceived +dashboardData?.payment?.totalPaymentBalanced}`}
                          subheader={"Requested"}
                          Icon={<NorthEastIcon
                            style={{
                              fontSize: 20.56,

          
                              color:"#EABF4E"
                            }}
                          />}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                      <DisplayCard
                          title={`₹ ${dashboardData?.payment?.totalPaymentReceived || "0"}`}
                          subheader={"Recieved"}
                          Icon={<AccountBalanceWalletIcon
                            style={{
                              fontSize: 20.56,

          
                              color:"#EABF4E"
                            }}
                          />}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                      <DisplayCard
                          title={`₹ ${dashboardData?.payment?.totalPaymentBalanced || "0"}`}
                          subheader={"Pending"}
                          Icon={<SouthWestIcon
                            style={{
                              fontSize: 20.56,

          
                              color:"#EABF4E"
                            }}
                          />}
                        />
                      </Grid>
                      
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12} sx={{ marginTop: ".2rem" }}>
                <TaskList totalCountTask={totalCountTask} setTotalCountTask={setTotalCountTask} basicFilter={basicFilter} endDate={endDate} startDate={startDate} dateRangeStatus={dateRangeStatus} count={dashboardData?.task} />
              </Grid>
              <Grid item xs={12} lg={12} sx={{ marginTop: ".2rem" }}>
              <AppointmentList basicFilter={basicFilter} endDate={endDate} startDate={startDate} dateRangeStatus={dateRangeStatus} count={dashboardData?.appointment} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <HearingList count={dashboardData?.hearing} basicFilter={basicFilter} endDate={endDate} startDate={startDate} dateRangeStatus={dateRangeStatus}/>
              </Grid>
              <Grid item xs={12} lg={6}>
              <PaymentsDash paymentList={dashboardData?.payment} basicFilter={basicFilter} endDate={endDate} startDate={startDate} />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={12} sx={{ mb: "60px" }}>
          <AppointmentList basicFilter={basicFilter} endDate={endDate} startDate={startDate} dateRangeStatus={dateRangeStatus} count={dashboardData?.appointment} />
            
          </Grid> */}
          {/* <Grid item xs={12} lg={5}>

      </Grid> */}
        </Grid>
      </Div>

    </>

  );
}

export default Home;
