import { Avatar, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Chip, Box, Modal, Link } from "@mui/material";
import { formatDate } from "app/utils/dateTimeHelpers";
import { getAssetPath } from "../../utils/appHelpers";
import { ASSET_AVATARS } from "../../utils/constants/paths";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Cookies from "js-cookie";

const avatarImgUrl = getAssetPath(`${ASSET_AVATARS}/lawyer.png`, "40x40");

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    borderBottom: "2px solid #f5f7fa",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const NonActiveLawyerCard = ({ item, index }) => {
  const openDialer = (event) => {
    event.preventDefault();
    const phoneNumber = event.target.getAttribute("href");
    window.location.href = phoneNumber;
  }
  const assignedRole = Cookies.get("assignedRole");
  return (
    <StyledTableRow>
      <TableCell
        sx={{
          pl: (theme) => theme.spacing(3),
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Avatar
          sx={{
            width: { sm: 20, lg: 30 },
            height: { sm: 20, lg: 30 },
            borderRadius: "50%",
          }}
          alt={item?.user?.fullname}
          src={
            item?.user?.userProfileImageLink
              ? item?.user?.userProfileImageLink
              : avatarImgUrl
          }
          variant="rounded"
        />
        {item?.user?.fullname}
      </TableCell>
      <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "left" }}>
        {item?.user?.professionalProfile?.lawyerSpecialisation
          ? item?.user?.professionalProfile?.lawyerSpecialisation[0]
              ?.specialityName
          : "Specialisation"}
      </TableCell>
      <TableCell sx={{ pl: (theme) => theme.spacing(3), textAlign: "left" }}>
        {item?.user?.professionalProfile?.practiceCourts
          ? item?.user?.professionalProfile?.practiceCourts[0]?.courtName
          : "Court"}
      </TableCell>
      {assignedRole==="ROLE_ADMIN" && (
      <TableCell>
        <a href={`mailto:${item?.user?.email}`}>
          {" "}
          <EmailOutlinedIcon
            fontSize="medium"
            color="secondary"
            style={{ marginRight: "16px" }}
          />
        </a>
        <LocalPhoneOutlinedIcon
          fontSize="medium"
          color="secondary"
          href={`tel:${item?.user?.phone}`}
          onClick={openDialer}
        />
      </TableCell>
      )}
    </StyledTableRow>
  );
};

export default NonActiveLawyerCard;
