import React from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,Legend } from "recharts";
import Div from "@jumbo/shared/Div";
import { capitalizeFLetter } from "@jumbo/utils";

const ChartSalesOverview = ({ data }) => {
  return (
    <div style={{ width: '100%', height: '400px', position: 'relative' }}>
      <ResponsiveContainer>
        <AreaChart data={data} margin={{ top: 50, right: 20, left: 20, bottom: 30 }}>
          <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="black" stopOpacity={0.7} />
            <stop offset="95%" stopColor="black" stopOpacity={0} />
          </linearGradient>
          {/* Define gradient for "Received" */}
          <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#EABF4E" stopOpacity={0.7} />
            <stop offset="95%" stopColor="#EABF4E" stopOpacity={0} />
          </linearGradient>
          </defs>
          <XAxis dataKey="month"  tick={{ fontSize: 12 }} label={{ value: "Months", position: "insideBottom", offset: -5, dy: 10 }} />
          <YAxis tickFormatter={(value) => `${value / 1000}k`} tick={{ fontSize: 12 }} label={{ value: "Money in thousands", angle: -90, position: "insideLeft", dy:50 }} />
          <Tooltip
            animationEasing={"ease-in-out"}
            content={({ active, label, payload }) => {
              return active ? (
                <Div sx={{ color: "common.white" }}>
                  {payload.map((row, index) => {
                    return (
                      <div key={index} className={index !== payload.length - 1 ? "mb-1" : ""}>
                        <div style={{
                          color: row.color,
                          fontSize: 8,
                          letterSpacing: 2,
                          textTransform: 'uppercase'
                        }}>
                          {capitalizeFLetter(row.name)}
                        </div>
                        <div style={{
                          color: row.color
                        }}
                        > ₹ {row.value} 
                        </div>
                      </div>
                    )
                  })}
                </Div>
              ) : null;
            }}
            wrapperStyle={{
              background: 'rgba(255,255,255,0.8)',
              borderRadius: 4,
              padding: '5px 8px',
              fontWeight: 500,
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
            }}
            cursor={false}
          />
          <CartesianGrid strokeDasharray="6 1 2" horizontal={false} strokeOpacity={0.2} stroke={"black"} />
          <Area
            type="monotone"
            dataKey={"Requested"}
            stackId="2"
            strokeWidth={2}
            fillOpacity={.7}
            stroke={"black"}
            fill="url(#colorUv)"
          />
          <Area
            type="monotone"
            dataKey={"Received"}
            stackId="1" strokeWidth={2}
            fillOpacity={.7}
            stroke={"#EABF4E"}
            fill="url(#colorUv1)"
          />
           <Legend verticalAlign="top" align="center" height={36} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

/* Todo data prop define */
export default ChartSalesOverview;
