import { Card, CardHeader, Typography, Divider } from "@mui/material";
import React from "react";
import Div from "@jumbo/shared/Div/Div";

function CasesDash({ title, subheader, Icon, shared }) {
	const IconComponent = Icon;
	return (
		<Card
			sx={{
				backgroundColor: "#151513",
				color: "white",
				height: "100%",
				display: "flex",
				p: 2.5,
				py: "10px",
				flexDirection: "column",
			}}
		>
			<Div
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{IconComponent}
				<Typography sx={{ fontSize: "1rem" }}>{subheader}</Typography>
			</Div>
			<Div sx={{ display: "flex" }}>
				<Div
					sx={{
						width: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: "8px",
					}}
				>
					<Div
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography sx={{ fontSize: "1rem" }}>
							{title ? title : 0}
						</Typography>
						<Typography sx={{ fontSize: "0.70rem" }}>{`Created`}</Typography>
					</Div>
				</Div>

				<Divider
					orientation="vertical"
					// variant="middle"
					flexItem
				/>
				<Div
					sx={{
						width: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: "8px",
					}}
				>
					<Div
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography sx={{ fontSize: "1rem" }}>
							{shared ? shared : 0}
						</Typography>
						<Typography sx={{ fontSize: "0.70rem" }}>{`Shared`}</Typography>
					</Div>
				</Div>
			</Div>
		</Card>
	);
}

export default CasesDash;
