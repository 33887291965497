import React from "react";
import { Button, Chip, ListItemButton, ListItemText, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Card, CardHeader, Link, CardContent } from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { formatDate, formattedTime } from "app/utils/dateTimeHelpers";
import { useNavigate } from "react-router-dom";
import ActionsMenu from "./ActionsMenu";
import { CancelAppointment } from "app/Apis/appointment";
import Cookies from "js-cookie";

const AppointmentDashCard = ({ item,inCase,setAddApp,setAppointmentEdit}) => {
  const nav = useNavigate()
  const accessToken = Cookies.get("accessToken");
  function AppointmentIcon(type) {
    if (type === "Video Call") {
      return <VideoCallIcon style={{ color: "#EABF4E" }} />;
    } else if (type === "Personal Meeting") {
      return <PersonIcon style={{ color: "#EABF4E" }} />;
    } else if (type === "Telephonic Call") {
      return <LocalPhoneIcon style={{ color: "#EABF4E" }} />;
    }
    return null;
  }

  const handleEdit = ()=>{
    setAppointmentEdit(item);
    setAddApp(true);
  }
  const removeApp = async () => {
    const response = await CancelAppointment(accessToken, item);
    if (response) {
      console.log(response, "deleted successfully");
      // setUpdate((prev) => !prev);
      // setAddApp(false);
    } else {
      console.log("error deleting");
    }
  }
  return (
    <Card sx={{ boxShadow: "none" }}>
      <CardHeader
        sx={{ p: 2, backgroundColor: "#F9F8F9", mb: 1 }}
        action={
          inCase
          ?(
          <Div sx={{display:"flex"}}>
          <Chip
            label={item?.addressLabel ? item?.addressLabel : "Virtual"}
            color="secondary"
            size="medium"
            variant="outlined"
            sx={{ fontWeight: "bold" }}
          />
          <ActionsMenu
                handleEdit={handleEdit}
                handleDelete={removeApp}
              />
          </Div>)
          :(
          <Chip
            label={item?.addressLabel ? item?.addressLabel : "Virtual"}
            color="secondary"
            size="medium"
            variant="outlined"
            sx={{ fontWeight: "bold" }}
          />)
        }
        title={
          <Typography sx={{ fontWeight: 400, fontSize: "1.2rem" }}>
            {item?.appointmentTitle ? item?.appointmentTitle : "Title"}
          </Typography>
        }
      />
      <CardContent sx={{ p: 2, pt: 0 }}>
        <Div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Div sx={{ display: "flex", justifyContent: "space-between" }}>
            <Div sx={{ display: "flex" }}>
              <EventIcon style={{ marginRight: "10px", color: "#EABF4E" }} />
              <Typography>
                {item?.dateOfAppointment
                  ? `${formatDate(item?.dateOfAppointment)}`
                  : "Date and Time"}
              </Typography>
            </Div>
            {/* <Div>
              {item?.appointmentType === "Video Call" ?
                <Link
                  target="_blank"
                  href="/lawyers/meet/true"
                > {item?.appointmentType ?
                  AppointmentIcon(item?.appointmentType)
                  : "Icon"}
                </Link> :
                AppointmentIcon(item?.appointmentType)}
            </Div> */}
            {/* <Div sx={{ display: "flex" }}>
            <EventIcon style={{ marginRight: "10px", color: "#EABF4E" }} />
            <Typography>
              {"Created By"}
            </Typography>
          </Div> */}
          </Div>
          <Div sx={{ display: "flex" }}>
            <AccessTimeFilledIcon style={{ marginRight: "10px", color: "#EABF4E" }} />
            <Typography>
              {Boolean(item?.fromTime && item?.toTime)
                ? `${formattedTime(item?.fromTime)} to ${formattedTime(
                  item?.toTime
                )}`
                : "Date and Time"}
            </Typography>
          </Div>
          <Div sx={{ display: "flex" }}>
            <PersonIcon style={{ marginRight: "10px", color: "#EABF4E" }} />
            <Typography>
              {item?.client
                ? item?.client?.fullname
                : "Client Name"}
            </Typography>
          </Div>
        </Div>
      </CardContent>
    </Card>
  );
};
/* Todo item prop define */
export default AppointmentDashCard;
