import Div from "@jumbo/shared/Div/Div";
import { TextField, Typography, FormHelperText } from "@mui/material";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material";
import { Chip } from "@mui/material";
import { useFormik } from "formik";
import { FormikErrors } from "formik";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateTimingSettings } from "app/Apis/settings";
import dayjs from "dayjs";
import { Repeat } from "@material-ui/icons";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import GavelIcon from "@mui/icons-material/Gavel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Autocomplete } from "@mui/material";
import { Alert, Snackbar } from "@mui/material";

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const repetitionForOptions = [
  "Doesn't Repeat",
  "All Days",
  "Monday to Alternate Saturdays",
];

const AddTimeForm = ({
  setTimeFormOpen,
  initSettings,
  setInitSettings,
  name,
  initFormTiming,
  setInitFormTiming,
  setOpenSnackBar,
  setSnackbarMessage
}) => {
  console.log("name", name);
  // const currentTime =dayjs();
  const [openTime, setOpenTime] = useState(dayjs());
  const [closeTime, setCloseTime] = useState(dayjs().add(15, "minutes"));
  const theme = useTheme();
  const lawyerId = useSelector((state) => {
    return state?.onboardingInputs?.lawyerId;
  });
  const accessToken = useSelector((state) => {
    return state?.onboardingInputs?.accessToken;
  });
  // const fullOpeningTime = currentTime;
  // const fullClosingTime = currentTime;
  const timingObject = initFormTiming
    ? initSettings?.timingDetails.filter(
        (timing) => timing?.timingId === initFormTiming
      )
    : null;
  const formikInitalValues = timingObject
    ? timingObject[0]
    : {
        closingTime: "",
        dayOfWeek: 0,
        openingTime: "",
        repetitionFor: "",
        timingLabel: "",
      };
  // console.log(timingObject);
  // console.log(formikInitalValues);
  const handleWeekChange = (value) => {
    formik.setFieldValue("dayOfWeek", value);
    console.log(value);
    console.log(formik.values);
  };
  const width = window.innerWidth;
  
  const handleSubmit = async (e, isActive) => {
    console.log(formik.values);
    console.log(accessToken);
    console.log(lawyerId);
    console.log(initSettings);
    const currentTiming = {
      closingTime: closeTime.format("YYYY-MM-DD HH:mm:ss"),
      openingTime: openTime.format("YYYY-MM-DD HH:mm:ss"),

      repetitionFor: repetitionForOptions.findIndex(
        (repeat) => repeat === formik.values.repetitionFor
      ),
      dayOfWeek: formik.values.dayOfWeek,
      isActive: isActive,
      timingLabel: name,
    };
    const openingTimestamp = dayjs(openTime).unix();
    const closingTimestamp = dayjs(closeTime).unix();
    const timeDifference = closingTimestamp - openingTimestamp;
    if (timeDifference < 900) {
      formik.setFieldError(
        "closingTime",
        "Closing time should be at least 15 minutes later than opening time"
      );
      return;
    }
    let timingDetails = initSettings?.timingDetails;
    if (timingDetails) {
      timingDetails.push({ ...currentTiming, isActive: isActive });
    } else {
      timingDetails = [{ ...currentTiming, isActive: 1 }];
    }
    const response = await updateTimingSettings(
      initSettings,
      timingDetails,
      lawyerId,
      accessToken
    );
    if (response) {
      console.log(response);
      setInitSettings(response);
      console.log("timing added successfully");
      setTimeFormOpen(false);
      setOpenSnackBar(true)
      setSnackbarMessage("Timing Added Successfully!")
    }
  };

  const formik = useFormik({
    initialValues: formikInitalValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  const handleClose = () => {
    setTimeFormOpen(false);
    setInitFormTiming(null);
  };

  return (
    <>
    
        <Div
          sx={{
            padding: "24px 24px 4px 24px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Div
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {(() => {
              if (name === "Home") {
                return <HomeIcon sx={{ mb: 0, ml: "5px", mr: "5px" }} />;
              } else if (name === "Court") {
                return <GavelIcon sx={{ mb: 0, ml: "5px", mr: "5px" }} />;
              } else if (name === "Office") {
                return <WorkIcon sx={{ mb: 0, ml: "5px", mr: "5px" }} />;
              } else {
                return null;
              }
            })()}
            <Typography
              variant="h3"
              color="black"
              sx={{
                fontSize: "1.4rem",
                fontWeight: 500,
              }}
            >
              {` ${name} Timing`}
            </Typography>
          </Div>
          <CloseIcon
            sx={{
              mt: 0.5,
              fontSize: "24px",
            }}
            onClick={handleClose}
          />
        </Div>
        <Grid
          container
          flexDirection="column"
          sx={{ padding: "24px 24px 4px 24px" }}
        >
          {/* days row */}
          <Grid item minWidth={12}>
          <Grid container justifyContent={width < 990 ? 'flex-start' : 'space-evenly'}>
              {formik.values.repetitionFor === "Doesn't Repeat" &&
                weekDays.map((day, idx) => {
                  return (
                    <Grid item key={day} minWidth={12}>
                      <Chip
                        label={day}
                        onClick={() => handleWeekChange(idx)}
                        size="medium"
                        variant={
                          formik.values.dayOfWeek === idx
                            ? "default"
                            : "outlined"
                        }
                        color={
                          formik.values.dayOfWeek === idx
                            ? "success"
                            : "default"
                        }
                        sx={{ mr: 1, mt: 1 }}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>

          {/* filds row */}
          <Grid item minWidth={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Opening Time"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    inputProps={{ sx: { color: "green" } }}
                    renderInput={(props) => (
                      <TextField sx={{ color: "blue" }} {...props} />
                    )}
                    color="secondary"
                    value={openTime}
                    onChange={(value) => {
                      setOpenTime(value);
                      if (value !== null) {
                        setCloseTime(value.add(15, "minutes"));
                      }
                      console.log(value);
                    }}
                    sx={{
                      "mt": 2,
                      "width": "100%",
                      "color": "secondary",
                      "& .MuiInputBase-root": {
                        "& fieldset": {
                          borderColor: "grey",
                        },
                        "&:hover fieldset": {
                          borderColor: "#EABF4E",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#EABF4E",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EABF4E",
                      },
                      "& .MuiFormHelperText-root": {
                        color: "#EABF4E",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Closing Time"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    inputProps={{ sx: { color: "green" } }}
                    renderInput={(props) => (
                      <TextField sx={{ color: "blue" }} {...props} />
                    )}
                    color="secondary"
                    value={closeTime}
                    onChange={(value) => {
                      setCloseTime(value);
                      formik.setFieldTouched("closingTime", true); // Mark the field as touched
                    }}
                    error={
                      formik.touched.closingTime && formik.errors.closingTime
                    }
                    helperText={
                      formik.touched.closingTime && formik.errors.closingTime
                    }
                    onBlur={formik.handleBlur("closingTime")} // Handle onBlur event
                    sx={{
                      "mt": 2,
                      "width": "100%",
                      "color": "secondary",
                      "& .MuiInputBase-root": {
                        "& fieldset": {
                          borderColor: "grey",
                        },
                        "&:hover fieldset": {
                          borderColor: "#EABF4E",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#EABF4E",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#EABF4E",
                      },
                      "& .MuiFormHelperText-root": {
                        color: "#EABF4E",
                      },
                    }}
                  />
                  {formik.touched.closingTime && formik.errors.closingTime && (
                    <FormHelperText error>
                      {formik.errors.closingTime}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Autocomplete
                  value={formik.values.repetitionFor}
                  name={"repetitionFor"}
                  onChange={(event, value) =>
                    formik.setFieldValue("repetitionFor", value)
                  }
                  options={repetitionForOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Repeat For"
                      color="secondary"
                      sx={{
                        width: "100%",
                        mt: 2,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* button row */}
          <Grid item minWidth={12}>
            <Div
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                type="submit"
                onClick={(e) => handleSubmit(e, 1)}
                sx={{
                  "backgroundColor": "black",
                  "width": "30%",
                  "color": "white",
                  "p": 1,
                  "mt": 2,
                  "mb": "5px",
                  "fontWeight": 500,
                  "fontSize": { xs: "0.8rem", md: "1rem" },
                  "&:hover": {
                    backgroundColor: "#eabf4e",
                  },
                  "@media (max-width: 990px)": {
                    width: "100%",
                  },
                }}
              >
                {initFormTiming ? "Edit Timing" : `Add Timing`}
              </LoadingButton>
            </Div>
          </Grid>
        </Grid>

    </>
  );
};

export default AddTimeForm;
