import axios from 'axios';
import { apiHeader, apiUrl } from "app/config";

export const createHearing = async (accessToken, lawyerId, hearingData) => {
  try {
    if (!accessToken || !lawyerId || !hearingData) {
      throw Error('Missing required parameters in API');
    }

    const body = {
      ...hearingData,
      hearingDate: hearingData?.hearingDate.format("YYYY-MM-DD hh:mm:ss.SSS"),
      hearingNotes: hearingData?.hearingNotes?.purposeHearing,
    };
    console.log(hearingData?.hearingDate.format("YYYY-MM-DD hh:mm:ss.SSS"));


    const headers = {
      'x-api-key': apiHeader,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${apiUrl}/api/v1/hearings`, body, {
      headers,
    });

    if (response.status === 200) {
      console.log('Hearing Added', response.data);
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteHearing = async (accessToken, hearingData) => {
  try {
    if (!accessToken  || !hearingData) {
      throw Error('Missing required parameters in API');
    }
    const body = {
      ...hearingData,
      isActive:0
    };



    const headers = {
      'x-api-key': apiHeader,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${apiUrl}/api/v1/hearings`, body, {
      headers,
    });

    if (response) {
      console.log('Hearing Deleted', response);
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};