import React from "react";
import EnquiryItem from "./EnquiryItem";
import { Grid } from "@mui/material";

const EnquiryList = ({
  enquiryList,
  setShowUpdate,
  showUpdate
}) => {
  return (
    <React.Fragment>
      <Grid
        container

        // spacing={2}
      >
        {enquiryList.map((enquiry, index) => {
          if (enquiry?.isActive) {
            return (
              <Grid item xs={12} md={12}>
                <EnquiryItem
                  key={enquiry.enquiryId}
                  item={enquiry}
                  setShowUpdate={setShowUpdate}
                  showUpdate={showUpdate}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </React.Fragment>
  );
};

export default EnquiryList;
