import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  Typography,
  Paper,
  Link,
  Breadcrumbs,
  Grid,
  Tabs,
  Tab,
  Select,
  Button,
  MenuItem,
  Chip,
  Popover,
  ListItemText,
  InputBase,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TaskSkeletons from "../Todos/TaskSkeletons";
import NoDataDisplay from "app/Components/Dashboard/Widgets/NoDataDisplay/NoDataDisplay";
import ActiveLawyerCard from "./ActiveLawyerCard";
import NonActiveLawyerCard from "./NonActiveLawyerCard";
import CaseReportCard from "./CaseReportCard";
import LawyerListCard from "../Teams/LawyerListCard";
import CaseCard from "../MyCases/CaseCard";
import { activeStaticData } from "./staticData";
import { getTeamList } from "app/Apis/teams";
import { getCasesList } from "app/Apis/case";
import SingleDataCard from "app/Components/DataDisplayCards/SingleDataDisplayCard/SingleDataCard";
import GavelIcon from "@mui/icons-material/Gavel";
import GroupIcon from "@mui/icons-material/Group";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import VoiceOverOffIcon from "@mui/icons-material/VoiceOverOff";
import NonActiveLawyersList from "./NonActiveLawyersList";
import CaseReportList from "./CaseReportList";
import FilterBy from "app/Components/FilterBy/FilterBy";

const dayRangeOption = [
  "Select",
  "Today",
  "Yesterday",
  "Tomorrow",
  "This Week",
  "This Month",
  "Choose Date",
];

const DailyActivity = () => {
  const [value, setValue] = useState(0);
  const [dayRangeFilter, setDayRangeFilter] = useState(dayRangeOption[0]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState("5");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [update, setUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [specialisationType,setSpecialisationType] = useState(null);
  const [courtType,setCourtType] = useState(null);
  const assignedRole = Cookies.get("assignedRole");
  const lawyerId = "64f30a8f6f303d2bb3837058";
  const accessToken =
    "eyJyb2xlIjpbIlJPTEVfTEFXWUVSIl0sImlkIjoiNjRmMzBhOGY2ZjMwM2QyYmIzODM3MDU4IiwiYWxnIjoiSFM1MTIifQ.eyJzdWIiOiI5NDY2Nzk3Njc4IiwiaWF0IjoxNjk1MDI4NjExLCJleHAiOjIwMTA2NDc4MTF9.BBG5kEFMMwOgUPJcGarCjyGwtHUZAyUhkTtRjdKzntqfY3grt5XIKbidubq5-pPuTJMYNPVOVgOwUSrI95a2ag";

  const getNonActiveLawyers = async () => {
    try {
      setIsLoading(true);
      const response = await getTeamList();
      console.log(response);
      if (response) {
        const filteredArray = response?.filter(
          (lawyer) => lawyer?.lawyerId !== lawyerId
        );
        setList(filteredArray);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  const getCasesSectionReport = async () => {
    try {
      const response = await getCasesList(accessToken, pageNumber, pageSize);
      console.log(response);
      if (response) {
        console.log(response);
        setList(response.cases);
        setTotalPages(response.totalPages);
        // setPageNumber(response.currentPage)
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value.length === 0) {
      setUpdate((prev) => !prev);
    }
    setSearchQuery(value);
  }

  const resetFilters = () => {
    setSearchQuery("");
  }

  // const handleClose = () => {
  //   setAnchorEl(null);
  // }

  useEffect(() => {
    // if (value === 0) {
      getNonActiveLawyers();
    // }
    //  else {
    //   getCasesSectionReport();
    // }
  }, [pageSize, value, update,specialisationType]);
  
  const lawyersFilterBy = [
    {
      label:"Specialisation",
      options:[
        {
          name:"Option 1",
          currentOption:false,
        },
        {
          name:"Option 2",
          currentOption:false,
        },
        {
          name:"Option 3",
          currentOption:false,
        },
        {
          name:"Option 4",
          currentOption:false,
        },
      ],
      setFilter:(option,index)=>{
        if(index!==-1 && !option){
          setSpecialisationType(this.options[index].name);
          this.options[index].currentOption = true;
        }
      }
    }
  ];

  if (assignedRole === "ROLE_ADMIN" || assignedRole==="ROLE_MODERATOR") {
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link href="/admin/daily-activity" underline="none">
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <AssessmentIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Dashboard
            </Typography>
          </Link>
          {/* {addJudgement && (
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <LiveHelpIcon sx={{ mr: 0.5, fontSize: 20 }} />
              Add Judgement
            </Typography>
          )} */}
        </Breadcrumbs>
        <Grid container spacing={3.75} sx={{ mb: 3 }}>
          <Grid item xs={12} md={3}>
            <SingleDataCard
              title={50}
              subheader={"Total Lawyers"}
              Icon={<GavelIcon style={{ color: "#EABF4E" }} />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SingleDataCard
              title={50}
              subheader={"Total Clients"}
              Icon={<GroupIcon style={{ color: "#EABF4E" }} />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SingleDataCard
              title={50}
              subheader={"Total Active Lawyers"}
              Icon={<DirectionsRunIcon style={{ color: "#EABF4E" }} />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SingleDataCard
              title={50}
              subheader={"Total Non Active Lawyers"}
              Icon={<VoiceOverOffIcon style={{ color: "#EABF4E" }} />}
            />
          </Grid>
        </Grid>
        <Paper
          sx={{
            minHeight: "80%",
            backgroundColor: "white",
            p: 3,
            position: "relative",
          }}
          elevation={20}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "145px",
                  }}
                >
                  <Div
                    style={{
                      height: "19px",
                      width: "5px",
                      backgroundColor: "#EABF4E",
                      marginRight: "8px",
                    }}
                  ></Div>
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      lineHeight: 1.5,
                      fontWeight: 400,
                    }}
                  >
                    {"Daily Activity"}
                  </Typography>
                </Div>
              </Div> */}
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  // borderBottom: 1,
                  // borderColor: "divider",

                  //maxWidth: { xs: 300, sm: 600, md: 700, lg: 800 },
                  //   overflowX: "scroll",
                }}
              >
                <Tabs
                  variant="scrollable"
                  allowScrollButtonsMobile
                  value={value}
                  textColor="secondary"
                  indicatorColor="secondary"
                  sx={{ mb: 0 }}
                >
                  <Tab
                    onClick={() => setValue(0)}
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Active Lawyers"
                  />
                  <Tab
                    onClick={() => setValue(1)}
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Non Active Lawyers"
                  />
                  <Tab
                    onClick={() => setValue(2)}
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Cases Report"
                  />
                </Tabs>
              </Div>
              {assignedRole==="ROLE_ADMIN" && (
              <Div
                    sx={{
                      position: "relative",
                      width: "40%",
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    <Div
                      sx={{
                        padding: (theme) => theme.spacing(0, 1),
                        height: "100%",
                        position: "absolute",
                        right: 0,
                        pointerEvents: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 2,
                      }}
                    >
                      <SearchIcon color="secondary" />
                    </Div>
                    <InputBase
                      id="search-bar"
                      className="search"
                      placeholder={`Search by Mobile`}
                      size="small"
                      onChange={handleSearchChange}
                      value={searchQuery}
                      inputProps={{
                        maxLength: 10,
                      }}
                      sx={{
                        color: "inherit",
                        display: "flex",
                        borderRadius: 24,
                        backgroundColor: (theme) =>
                          theme.jumboComponents.JumboSearch.background,
                        paddingRight: "5rem",
                        "& .MuiInputBase-input": {
                          padding: (theme) => theme.spacing(1, 1, 1, 0),
                          paddingLeft: (theme) => "1rem",
                          transition: (theme) =>
                            theme.transitions.create("width"),
                          width: "100%",
                          height: 24,
                        },
                      }}
                    />
                    {searchQuery && (
                      <CloseIcon
                        color="secondary"
                        sx={{
                          position: "absolute",
                          right: "35px",
                          bottom: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSearchQuery("");
                          resetFilters();
                        }}
                      />
                    )}
                  </Div>
                  )}
              {/* <Div>
                <Button
                  style={{ fontWeight: "600", minWidth: "100%" }}
                  startIcon={<FilterAltOutlinedIcon />}
                  endIcon={<KeyboardArrowDownIcon />}
                  color="secondary"
                  size="small"
                  //   onClick={handleClick}
                >
                  Filter By
                </Button>
                {/* <FilterBy menuList={lawyersFilterBy}/> 
              </Div> */}
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              {isLoading ? (
                Array.from(Array(parseInt(pageSize))).map((item, index) => {
                  return <TaskSkeletons key={index} />;
                })
              ) : list.length === 0 ? (
                <NoDataDisplay
                  text={"No Lawyers Found"}
                  url={"/admin/judgement/list"}
                  buttonText={"Add Lawyers"}
                  minHeight={"80px"}
                  fromPayment={true}
                />
              ) : (
                <></>
              )}

              {list.length !== 0 && !isLoading && (
                <Grid
                  container
                  sx={{ mb: 4 }}
                  // spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    {value === 0 ? (
                      <NonActiveLawyersList list={list} />
                    ) : value === 1 ? (
                      <NonActiveLawyersList list={list} />
                    ) : (
                      <CaseReportList list={list} />
                    )}
                    {/* {value === 0 ? (
                      // <LawyerListCard item={item} key={index} />
                      <NonActiveLawyersList list={list} />
                    ) : value === 1 ? (
                      // <CaseCard
                      //   item={item}
                      //   key={index}
                      //   setUpdate={setUpdate}
                      //   values={2}
                      // />
                      <CaseReportList list={list}/>
                    ) : (
                      // <ActiveLawyerCard item={item} key={index} />
                      <></>
                    )} */}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
};

export default DailyActivity;

// date selector
