import React, { useState } from "react";
import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography, Autocomplete, Checkbox } from "@mui/material";
import { useFormik } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { formatDate } from "app/utils/dateTimeHelpers";

function About({ handleBackButton,enquiryDetails,handleNextButton }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(enquiryDetails);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      {/* <CaseContainer> */}
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        <TextField
          sx={{ mb: 0, mt: 0, width: "100%" }}
          id="outlined-basic"
          label="Enquiry Type"
          value={enquiryDetails?.enquiryTypes ? enquiryDetails?.enquiryTypes: "Enquiry Type"}
          disabled
          color="secondary"
          variant="outlined"
          name="caseFirstName"
        />
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Created Date"
          value={enquiryDetails?.createdDate ? formatDate(enquiryDetails?.createdDate): "Created Date"}
          disabled
          color="secondary"
          variant="outlined"
          name="caseFirstName"
        />
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Case Type"
          disabled
          value={enquiryDetails?.caseType ? enquiryDetails?.caseType: "Case Type"}
          color="secondary"
          variant="outlined"
          name="caseFirstName"
        />
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="FIR/Complaint Number"
          disabled
          value={enquiryDetails?.firComplaintNo ? enquiryDetails?.firComplaintNo: "FIR Number"}
          color="secondary"
          variant="outlined"
          name="caseFirstName"
        />
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Police Station "
          disabled
          value={enquiryDetails?.policeStationName ? enquiryDetails?.policeStationName: "Police Station Name"}
          color="secondary"
          variant="outlined"
          name="caseFirstName"
        />
        <TextField
          sx={{ mb: 0, mt: 2, width: "100%" }}
          id="outlined-basic"
          label="Court "
          disabled
          value={enquiryDetails?.courtName ? enquiryDetails?.courtName: "Police Station Name"}
          color="secondary"
          variant="outlined"
          name="caseFirstName"
        />
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
        <LoadingButton
          onClick={handleBackButton}
          variant="contained"
          sx={{
            width: 100,
            padding: 1,
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "#eabf4e",
            },
          }}
        >
          Back
        </LoadingButton>
        <LoadingButton
          onClick={() => {
            handleNextButton();
          }}
          color="secondary"
          sx={{
            width: 100,
            padding: 1,
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "#eabf4e",
              color: "black",
            },
          }}
          variant="contained"
        >
          Continue
        </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default About;


