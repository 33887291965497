import React from "react";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import MiscDashboard from "app/pages/dashboards/misc/MiscDashboard";
import authRoutes from "./authRoutes";
import Onboarding from "app/pages/Onboarding/Onboarding";
import OnboardingSuccess from "app/pages/OnboardingSuccess/OnboardingSuccess";
import Schedule from "../pages/schedules/Schedule";
import NoCase from "app/pages/MyCases/NoCase";
import AddCase from "app/pages/MyCases/AddCase";
import AddLawyer from "app/pages/Teams/AddLawyer";
import AddLawyerLayout from "app/pages/Teams/AddLawyerLayout";
import NoTask from "app/pages/Todos/NoTask";
import ClientListLayout from "app/pages/Clients/ClientListLayout";
import NoDocument from "app/pages/Documents/NoDocument";
import NoCasePoint from "app/pages/CasePoints/NoCasePoint";
import AddClientLayout from "app/pages/Clients/AddClientLayout";
import pageNotFound from '../pages/404';
import Auth from './middleware/Auth';
import ComingSoon from "app/pages/ComingSoon/ComingSoon";


/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: "/lawyers/dashboards",
    element: <Page component={Home} />,
  },

  {
    path: "/clients/dashboards",
    element: <Page component={Home} />,
  },
  {
    path: "/home",
    element: <Page component={Home} />,
  },

  {
    path: "/dashboards/misc",
    element: <Page component={MiscDashboard} />,
  },
  ...authRoutes,
  {
    path: "/lawyers/schedules",
    element: <Page component={Schedule} />,
  },

  {
    path: "/onboarding",
    element: (
      <Page
        component={Onboarding}
        layout={"horizontal-layout"}
        disableSmLogin={true}
      />
    ),
  },

  {
    path: "/onboardingsuccess",
    element: (
      <Page
        component={OnboardingSuccess}
        layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  // {
  //   path: "/comingSoon",
  //   element: (
  //     <Page
  //       component={ComingSoon}
  //       layout={"horizontal-layout"}
  //       // disableSmLogin={true}
  //     />
  //   ),
  // },

  {
    path: "/lawyers/cases",
    element: (
      <Page
        component={NoCase}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  {
    path: "/lawyers/todo",
    element: (
      <Page
        component={NoTask}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  {
    path: "/lawyers/documents",
    element: (
      <Page
        component={NoDocument}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  {
    path: "/lawyers/casepoints",
    element: (
      <Page
        component={NoCasePoint}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
];

const routesForLawyers = [
  {
    path: "/lawyers/dashboards",
    element: <Page component={Home} />,
  },
  {
    path: "/home",
    element: <Page component={Home} />,
  },
  {
    path: "/dashboards/misc",
    element: <Page component={MiscDashboard} />,
  },
  {
    path: "/lawyers/schedules",
    element: <Page component={Schedule} />,
  },

  {
    path: "/onboarding",
    element: (
      <Page
        component={Onboarding}
        layout={"horizontal-layout"}
        disableSmLogin={true}
      />
    ),
  },

  {
    path: "/onboardingsuccess",
    element: (
      <Page
        component={OnboardingSuccess}
        layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },

  {
    path: "/lawyers/cases",
    element: (
      <Page
        component={NoCase}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  {
    path: "/lawyers/todo",
    element: (
      <Page
        component={NoTask}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  {
    path: "/lawyers/documents",
    element: (
      <Page
        component={NoDocument}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  {
    path: "/lawyers/casepoints",
    element: (
      <Page
        component={NoCasePoint}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  ...authRoutes
]

// ""

/**
 routes only accessible to authenticated users
 **/

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: "/lawyers/clients",
    element: (
      <Page
        component={ClientListLayout}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
  {
    path: "/lawyers/addclient",
    element: (
      <Page
        component={AddClientLayout}
        // disableSmLogin={true}
      />
    ),
  },
  {
    path: "/lawyers/teams",
    element: <Page component={AddLawyer} />,
  },
  {
    path: "/lawyers/addlawyer",
    element: (
      <Page
        component={AddLawyerLayout}
        // disableSmLogin={true}
      />
    ),
  },

  {
    path: "/lawyers/addcases",
    element: (
      <Page
        component={AddCase}
        // layout={"horizontal-layout"}
        // disableSmLogin={true}
      />
    ),
  },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
  routesForLawyers
};
