import React from "react";
import Div from "@jumbo/shared/Div";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

// import DisplayDocuments from "../DisplayDocuments/DisplayDocuments";
import DocumentModal from "./DocumentModal";
import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DisplayDocumentsAPI from "../DisplayDocuments/DisplayDocumentsAPI";

function EditDocuments({
  documentsArray,
  setDocumentsArray,
  documentsAPIList,
  setDocumentsAPIList,
  formDisable,
}) {
  const fileInput = React.useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [documentSaveAPI, setDocumentSaveAPI] = useState(true);

  const isMobile = useMediaQuery("(max-width: 990px)");

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);

    setDocumentsArray(null);
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  useEffect(() => {
    console.log(documentsArray, documentsAPIList);
  }, [documentsArray, documentsAPIList]);

  const handleFileInputChange = (e) => {
    console.log(documentsArray);
    console.log(e.target.files);
    // debugger;
    if (documentsArray) {
      const array = [...e.target.files, ...documentsArray];
      console.log(array);
      setDocumentsArray([...array]);
    } else {
      console.log(e.target.files);
      const array = [...e.target.files];
      console.log(array);
      setDocumentsArray([...array]);
      handleOpen();
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpenSnackBar(false);
  };
  return (
    <Div
      sx={{
        width: "100%",
        border: "1px dotted #d3d3d3",

        mt: 2,
        p: 2,
      }}
    >
      <input
        style={{ marginTop: "10px", width: "30vw" }}
        accept="image/*,application/pdf"
        multiple
        type="file"
        ref={fileInput}
        onChange={handleFileInputChange}
        hidden
      />

      {!(documentsAPIList && documentSaveAPI) && (
        <UploadFileIcon sx={{ fontSize: "40px" }} onClick={handleClick} />
      )}
      {documentsAPIList && (
        <DisplayDocumentsAPI
          formDisable={formDisable}
          handleClick={handleClick}
          documentsAPIList={documentsAPIList}
          setDocumentsAPIList={setDocumentsAPIList}
        />
      )}
      <Modal
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "93%", lg: "600px" },
              height: { xs: "85%", lg: "550px" },
              bgcolor: "background.paper",
              border: "1px solid #000",
              borderRadius: "8px",
              boxShadow: 24,
              px: 4,
              py: 1,
            }}
          >
            <span
              style={{
                position: " absolute",
                right: " -12px",
                top: "-14px",
                cursor: "pointer",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
                backgroundColor: "white",
                textAlign: "center",
                fontSize: "26px",
              }}
            >
              <CancelTwoToneIcon onClick={handleClose} fontSize="inherit" />
            </span>
            <Div sx={{ width: "100%", height: "100%" }}>
              <DocumentModal
                formDisable={formDisable}
                handleClose={handleClose}
                handleClick={handleClick}
                documentsArray={documentsArray}
                setDocumentsArray={setDocumentsArray}
                setDocumentSaveAPI={setDocumentSaveAPI}
                documentsAPIList={documentsAPIList}
                setDocumentsAPIList={setDocumentsAPIList}
              />
            </Div>
          </Box>
        </Fade>
      </Modal>
    </Div>
  );
}

export default EditDocuments;
