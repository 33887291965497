import React from "react";
import Div from "@jumbo/shared/Div";
import { ListItem, ListItemText, TextField, Typography,List } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import usePlacesAutocomplete ,{getGeocode,getLatLng,geocodebyPlaceId} from 'use-places-autocomplete';
function SearchPlaces({setAddress,setSearchOpen,setMarkerPosition}) {

    
  return (
    <Div
        sx={{
            height:"100%"
        }}
    >
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin:"1.25rem 1.375rem 0px 1.5rem"
          }}
        >
          <Typography
            variant="h3"
            color="black"
            sx={{
              fontSize: "1.875rem",
              fontWeight:500
            }}
          >
            Set your address
          </Typography>
            <CloseIcon 
              sx={{
                mt:0.5,
                fontSize:"24px",
                cursor: "pointer",
              }}
              onClick={()=>setSearchOpen(false)}
            />
        </Div>
        <PlacesAuto setAddress={setAddress} setSearchOpen={setSearchOpen} setMarkerPosition={setMarkerPosition}/>
    </Div>
  );
}

export default SearchPlaces;


const PlacesAuto = ({setAddress,setSearchOpen,setMarkerPosition})=>{
    const handleSelect = async(address)=>{
        const response = await getGeocode({placeId:address?.place_id});
        const {lat,lng} =  getLatLng(response[0]);
        console.log(lat,lng);
        // debugger
        setMarkerPosition({lat,lng})
        setAddress(address?.description);
        setSearchOpen(false);
    }
    const {ready,value,setValue,suggestions:{status,data}} = usePlacesAutocomplete();
    return (
        <Div
            sx={{
                padding:"1.5rem 1.5rem 0.25rem 1.5rem"
            }}
        >
            <TextField 
            value={value} 
            onChange={(e)=>setValue(e.target.value)}
            placeholder="Start typing to search your address"
            disabled={!ready}
            sx={{
                width:"100%"
            }}
            InputProps={{
                startAdornment: (
                  <LocationOnOutlinedIcon sx={{ color: 'gray' }} />
                )
              }}
            />
            <List>
                {status === "OK" && data.map((address)=>{
                    console.log(address);
                    return <>
                        <ListItem key={address?.place_id} onClick={(e)=>handleSelect(address)} sx={{cursor:"pointer"}}>
                            <ListItemText
                            primary={<Typography>{address?.description}</Typography>}
                            />
                        </ListItem>
                    </>
                })}
            </List>
        </Div>
    )
}