import { apiHeader, apiUrl } from "app/config";
import axios from "axios";
import Cookies from "js-cookie";
export const getJudgementsByIds = async (sectionIds, page, size
  ,
  //  tags
   ) => {
  try {
    const accessToken = Cookies.get("accessToken");
    const url = `${apiUrl}/api/v1/judgments`;
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("sections", sectionIds);
    params.append("size", size);
    // if (tags) {
    //   params.append("tags", tags);
    // }
    const urlWithParams = `${url}?${params.toString()}`;
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
      // 'Content-Type': 'multipart/form-data',
    };
    const response = await axios.get(urlWithParams, { headers });
    if (response.status === 200) {
      return response?.data?.data;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
};
export const getJudgementsList = async ( page, size, tags,caseName=null,sections=null,judge=null,court=null,language="English") => {
  try {
    const accessToken = Cookies.get("accessToken");
    const url = `${apiUrl}/api/v1/judgments`;
    const params = new URLSearchParams();
    params.append("page", page);
    params.append("size", size);
    if (tags) {
      params.append("tags", tags);
    }
    if (caseName) {
      params.append("caseName", caseName);
    }
    if (judge) {
      params.append("judge", judge);
    }
    if (sections) {
      params.append("sections", sections);
    }
    if (court) {
      params.append("court", court);
    }
    if (language) {
      params.append("language", language);
    }
    const urlWithParams = `${url}?${params.toString()}`;
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.get(urlWithParams, { headers });
    if (response.status === 200) {
      return response?.data;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
};

export const addJudgement = async (body,rawfile) => {
  try {
    const accessToken = Cookies.get("accessToken");
    const headers = {
      "x-api-key": apiHeader,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    if(rawfile){
      formData.append("rawfile", rawfile);
    }
    formData.append("caseName", body?.caseName);
    formData.append("sections", body?.sections);
    formData.append("court", body?.court);
    formData.append("dateofDecision", body?.dateofDecision);
    formData.append("judge", body?.judge);
    formData.append("judgmentDetails", body?.judgmentDetails);
    if(body?.facts){
      formData.append("facts", body?.facts);
    }
    if(body?.historyOfCase){
      formData.append("historyOfCase", body?.historyOfCase);
    }
    if(body?.issues){
      formData.append("issues", body?.issues);
    }
    if(body?.lawActsApplied){
      formData.append("lawActsApplied", body?.lawActsApplied);
    }
    if(body?.bindingLawToFacts){
      formData.append("bindingLawToFacts", body?.bindingLawToFacts);
    }
    if(body?.ratioOfCase){
      formData.append("ratioOfCase", body?.ratioOfCase);
    }
    if(body?.conclusionRuling){
      formData.append("conclusionRuling", body?.conclusionRuling);
    }
    if(body?.judgementId){
      formData.append("judgmentsId", body?.judgementId);
    }
    if(body?.type){
      formData.append("type", body?.type);
    }
    if(body?.language){
      formData.append("language", body?.language);
    }
    const response = await axios.post(
      "https://judgments.lawinzo.com/judgment-api/rawUpload",
      formData,
      { headers }
    );
    console.log(response);
    if (response.status === 200) {
      return response?.data;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
};
