import { apiHeader, apiUrl } from "app/config";
import Cookies from "js-cookie";
import axios from "axios";
import { filter } from "app/utils/filter";
export const getDashboardData = async (basicFilter, toDate, fromDate) => {
  const date = filter(basicFilter, fromDate, toDate)
  try {
    console.log(date)
    const accessToken = Cookies.get("accessToken");
    const lawyerId = Cookies.get("lawyerId");
    const params = new URLSearchParams();
    params.append("lawyerid", lawyerId);
    params.append("fromDate", date?.startDate);
    params.append("toDate", date?.endDate);
    const headers = {
      'x-api-key': apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const urlWithParams = `https://erp.lawinzo.com/api/v1/dashboard?${params.toString()}`;
    const response = await axios.get(urlWithParams, { headers });

    console.log(response);
    if (response.status === 200) {
      console.log("case report fetched", response);
      return response?.data?.data
    }
  } catch (e) {
    console.log(e);
  }
};
export const getSharedDashboardData = async (basicFilter, toDate, fromDate) => {
  const date = filter(basicFilter, fromDate, toDate)
  try {
    console.log(date)
    const accessToken = Cookies.get("accessToken");
    const lawyerId = Cookies.get("lawyerId");
    const params = new URLSearchParams();
    params.append("type","lawyer");
    // params.append("lawyerid", lawyerId);
    params.append("fromDate", date?.startDate);
    params.append("toDate", date?.endDate);
    const headers = {
      'x-api-key': apiHeader,
      Authorization: `Bearer ${accessToken}`,
    };
    const urlWithParams = `https://erp.lawinzo.com/api/v3/dashboard?${params.toString()}`;
    const response = await axios.get(urlWithParams, { headers });

    console.log(response);
    if (response.status === 200) {
      console.log("case report fetched", response);
      return response?.data?.data
    }
  } catch (e) {
    console.log(e);
  }
};