import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  InputBase,
  TextField,
  Typography,
  Link,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

import React from "react";
import Div from "@jumbo/shared/Div/Div";

export const Terms = () => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link href="/home" underline="none">
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            Home
          </Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          Terms And Conditions
        </Typography>
      </Breadcrumbs>
      <Card
        sx={{
          p: 3,
          position: "relative",
          paddingLeft:{ xs: "5%", md: "18%" },
          paddingRight:{ xs: "5%", md: "18%" },
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <Typography variant={"h5"}>Terms And Conditions</Typography>
            </Div>
          </Div>
          <Divider sx={{ mt: 2 }} />
          <Typography fontSize={"14px"}>
            The following terms and conditions constitute an agreement between
            you and Vakily (“vakily.app,” “we,” or “us”), the operator
            vakily.app (the “Website”). BY USING THE WEBSITE, AND/OR BY
            REGISTERING WITH US, YOU SIGNIFY THAT YOU AGREE TO THESE TERMS and;
            CONDITIONS, including that you consent to the information practices
            disclosed in our Privacy Policy, which is incorporated herein by
            reference, and that you are agree to comply with applicable laws,
            governed and interpreted by the country (hereinafter referred to as
            “India”). Please note that we offer the Website 'AS IS' and without
            warranties. If you are registering an account or using the Website
            on behalf of an individual or entity other than yourself, you
            represent that you are authorized by such individual or entity to
            accept these Terms & Conditions on such individual or entity's
            behalf.
          </Typography>
          <Typography fontSize={"14px"}>
            In addition, you understand that the specific method, features and
            services offered on vakily.app may be altered, improved, removed or
            adapted at any time by vakily.app in its sole discretion and
            without any notice. If you do not agree with any of these terms,
            refrain using this Website.
          </Typography>
          <Typography variant="h5">GENERAL TERMS:</Typography>
          <Typography fontSize={"14px"}>
            vakily.app is no way, directly or indirectly responsible for the
            consultation and assistance provided by the Lawyers. We are a
            technology company enabling legal service providers to access the
            client demand through our platform. vakily.app has no control over
            the Services provided by the Lawyer listed on our platform as he is
            independent of vakily and not an employee ours. vakily.app will
            not be responsible in case of any issues/discrepancies with the
            Services provided by the Lawyer.
          </Typography>
          <Typography fontSize={"14px"}>
            All of the materials and information on the Website are provided for
            informational purposes only, and may not reflect current legal
            developments or variances in the law of different jurisdictions. The
            materials and information on the Website do not necessarily reflect
            the opinions of the Lawyers of the Law Firms, their partners,
            clients or affiliates. The information in the Website is not
            guaranteed to be correct,complete or up to date. The mere receipt of
            an e-mail from or a 'post' on the Website does not create an
            Lawyer-client relationship. The applicable Lawyer / Law Firm is
            solely responsible for providing its services to you, and you agree
            that vakily.app shall not be liable for any damages or costs of any
            type arising out of or in any way connected with your use of such
            services (including, without limitation, claims based on
            malpractice). You agree that any claim arising out of your
            relationship with a Legal expert, Law Firm or Lawyer shall be
            brought solely against such Legal Expert, Law Firm or Lawyer because
            vakily.app is doing nothing more than aggregating all kind legal
            service demand generated on the website for the listed lawyers,
            legal experts and the Law Firms to enable their respective
            practices, neither vakily.app nor any of its licensors or
            affiliates shall be included within any such claim.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app, Legal experts, Lawyers / Professionals using
            vakily.app to provide online consultations (collectively
            “Lawyers”), Affiliates and Third-party providers (collectively
            “Affiliates”) would serve you to the best of their ability under the
            following terms and conditions:
          </Typography>
          <Typography variant="h5">1. USING THE vakily.app WEBSITE</Typography>
          <Typography fontSize={"14px"}>
            All users must be eighteen years or older. In the case of minor
            Consumers (under eighteen (18 years), a parent or guardian must
            accept this Agreement and guide all consultations under the services
            offered.
          </Typography>
          <Typography fontSize={"14px"}>
            The User takes full responsibility for ensuring that the information
            submitted is accurate. vakily.app shall make no effort to validate
            any information provided by the Consumer for use in Services with
            respect to content, correctness or usability. We with an intention
            to provide the best service possible could ask you to share more
            information as and when needed.
          </Typography>
          <Typography variant="h5">2. HOW DOES IT WORK?</Typography>
          <Typography fontSize={"14px"}>
            If you choose to opt a Paid Service at vakily.app, you will be
            charged a fee. The fee paid by you is paid to the Lawyer and is a
            payment by which you compensate the Lawyer for the time spent in
            providing you with services in a limited-scope Lawyer-client
            relationship. The fee is also intended in part to cover
            vakily.app's technology maintenance and other costs involved in
            operating the Website business. The fee paid towards paid services
            is only towards the scope defined under the services offered for
            that particular booking and post the consultation, you may or may
            not engage the Lawyer directly towards any further services you wish
            to avail from the respective Lawyer. vakily.app is no way, directly
            or indirectly responsible for the consultation and assistance
            provided by the Lawyers. Once you pay the fee and your booking is
            confirmed, you are intimated about the same via email, SMS and / or
            phone.
          </Typography>
          <Typography fontSize={"14px"}>
            WE DO NOT IN ANY WAY SOLICIT, ENDORSE, PROMOTE ANY INDIVIDUAL LISTED
            ON WEBSITE. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANYONE ELSE FOR
            ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH
            INFORMATION.
          </Typography>
          <Typography variant="h5">3. INDEPENDENCE OF LAWYERS</Typography>
          <Typography fontSize={"14px"}>
            The opinions, statements, answers (collectively “consultation”)
            provided by the Lawyers via vakily.app are solely the individual
            and independent opinions and statements of such individuals and do
            not reflect the opinions of vakily.app, its affiliates or any other
            organizations or institutions to which such Lawyer or such
            specialist or professional is affiliated or provides services.
            vakily.app does not recommend or endorse any specific Lawyers,
            products, procedures, opinions, or other information that may be
            mentioned on vakily.app or by a licensee of vakily.app.
            vakily.app is a technology platform to allow individual
            professionals to list themselves in order to have an access to
            demand in addition to their causal offline work.
          </Typography>
          <Typography fontSize={"14px"}>
            The inclusion of professionals and specialists on vakily.app or in
            any professional directory on vakily.app does not imply
            recommendation or endorsement of such professional nor is such
            information intended as a tool for verifying the credentials,
            qualifications, or abilities of any professional contained therein.
            Such information is provided on an 'as-is' basis and vakily.app
            disclaims all warranties, either express or implied, including but
            not limited to the implied warranties of merchantability and fitness
            for particular purpose.
          </Typography>
          <Typography fontSize={"14px"}>
            You agree that you understand Lawyers who participate in the
            vakily.app are not employed by or agents of vakily.app. Although
            vakily.app does require Lawyers to provide basic information about
            themselves to vakily.app and although vakily.app does rely upon
            and pass on this information to Users for their use, vakily.app
            does not separately verify this information and makes no
            representations as to the qualifications of any Lawyers. No listing
            of any Lawyer on this Website, and no information of any kind or
            answers provided by any Lawyer, constitutes an endorsement,
            recommendation or referral of any Lawyer.
          </Typography>
          <Typography fontSize={"14px"}>
            In connection with their participation on vakily.app, Lawyers do
            make certain representations and warranties regarding the
            information they provide about the jurisdictions in which they are
            licensed to practice their profession and in good standing.
            Nevertheless, you are encouraged to investigate or perform your own
            due diligence concerning the Lawyers and their qualifications.
          </Typography>
          <Typography fontSize={"14px"}>
            No representation is made that the quality of the professional
            services to be performed is greater than the quality of professional
            services performed by other Lawyers. We urge all potential clients
            to make their own independent investigation and evaluation before
            any Lawyer is being considered.
          </Typography>
          <Typography variant="h5">4. MEMBERSHIP TERMS:</Typography>
          <Typography fontSize={"14px"}>
            You represent and warrant that you are at least eighteen years of
            age and that you possess the legalright and the ability to enter
            into agreements pertaining to Terms & Conditions, to register on the
            vakily.app Services under your own name and to use the vakily.app
            Services in accordance with the Terms & Conditions and abide by the
            obligations hereunder.
          </Typography>
          <Typography fontSize={"14px"}>
            You are solely responsible for maintaining the confidentiality of
            your password and for all activities carried out under your
            vakily.app account. You agree to prohibit anyone else from using
            your password and immediately notify vakily.app of any unauthorized
            use of your password or other security concerns.
          </Typography>
          <Typography fontSize={"14px"}>
            No party has the power or authority as agent, employee or in any
            other capacity to represent, act for, bind or otherwise create or
            assume any obligation on behalf of the other party for any purpose
            whatsoever.
          </Typography>
          <Typography fontSize={"14px"}>
            International members acknowledge and agree that vakily.app may
            collect and use your Information and disclose it to other entities
            outside your resident jurisdiction. In addition, such Information
            may be stored on servers located outside your resident jurisdiction.
            By providing us with your Information, you acknowledge that you
            consent to the transfer of such Information outside your resident
            jurisdiction as detailed in our Privacy Policy. If you do not
            consent to such transfer, you may not use our Content or Services.
          </Typography>
          <Typography fontSize={"14px"}>
            Your affirmative act of using this Website and/or registering for
            the Website or the Services constitutes your electronic signature to
            these Terms & Conditions and your consent to enter into agreements
            with us electronically.
          </Typography>
          <Typography fontSize={"14px"}>
            The Website may only be used in good faith and may not be used to
            transmit or otherwise make available any information that is false
            or that you do not have a right to make available under any law or
            under contractual relationships (such as inside information,
            proprietary and confidential information learned or disclosed as
            part of employment relationships or under nondisclosure agreements),
            to threaten, abuse, harass, or invade the rights of any person or
            entity, to infringe on any person or entity's intellectual property
            rights, or in any other way that could reasonably be deemed
            unethical, illegal, or offensive.
          </Typography>
          <Typography fontSize={"14px"}>
            You may not misidentify yourself or impersonate any person or
            entity, or falsely state or otherwise misrepresent your affiliation
            with a person or entity (e.g., pretend to be a different person or
            from a different company or organization).
          </Typography>
          <Typography fontSize={"14px"}>
            Unless you have our prior written consent, you will not post
            advertisements or promotional materials, solicit participants and/or
            visitors of the Website, reproduce, duplicate, copy, sell, resell or
            exploit for any commercial purposes, any portion of the Website or
            its Services, use of the Website or it Services, or access to the
            Website or its Services.
          </Typography>
          <Typography fontSize={"14px"}>
            You are prohibited from using any type of computer 'worm,' 'virus'
            or any other device that is intended or is likely to disrupt,
            overload, or otherwise impair the workings of any part of the
            Website. If you do engage in such conduct, the resulting damage will
            be hard to quantify as a fixed amount and thus you hereby agree to
            pay us liquidated damages in the amount of INR 1,00,0000 for each
            day that the Website is damaged until the Website is completely
            repaired and further unquantified damages which will occur due to
            your act will be accessed and you shall be liable to pay the same.
            This amount is derived by estimating the value of (1) the loss of
            good will caused by an inoperable Website, (2) the time and money it
            will take to repair the Website and to address the concerns of
            visitors. We are required to use reasonable efforts to repair the
            Website as quickly as possible. This clause will not prohibit us
            from seeking additional compensation if your conduct injures us in a
            way not expressly contemplated herein.
          </Typography>
          <Typography fontSize={"14px"}>
            You are not permitted to collect or store personal data about other
            users. You are not permitted to access the Website for the purpose
            of data mining or extracting content from the Website beyond your
            personal end use. You are permitted to use the Website to find a
            Legal professional/Lawyer to perform services for you related to
            your issues and to obtain general information about similar cases
            (each, a 'Permissible Use'). You are not permitted to use the
            Website for any other reason other than a Permissible Use as
            described above.
          </Typography>
          <Typography fontSize={"14px"}>
            Impermissible Uses shall include, but are not limited to, using the
            Website to order to solicit, hire, engage or otherwise work with the
            employees or affiliates of vakily.app or the Lawyers, to interfere
            or attempt to interfere in the relationship between vakily.app and
            such employees, affiliates, Lawyers, or for any other purpose other
            than described herein (each, an 'Impermissible Use'). If you do
            engage in such conduct, the resulting damage will be hard to
            quantify as a fixed amount and thus you hereby agree to pay us
            liquidated damages in the amount of Rs.2,50,000 for each
            Impermissible Use, and further unquantified damages which will occur
            due to your act will be accessed and you shall be liable to pay the
            same. You further agree that this liquidated damages provision
            reasonably approximates actual costs, losses, and expenses which
            would be incurred by vakily.app due to any such Impermissible Use.
            You also agree that nothing in this section is intended to limit
            vakily.app’s right to obtain injunctive and other relief as may be
            appropriate.
          </Typography>
          <Typography fontSize={"14px"}>
            You may not forge headers or otherwise manipulate identifiers in
            order to disguise the origin of any Content transmitted through the
            Website. You agree to not harm minors in any way.
          </Typography>
          <Typography fontSize={"14px"}>
            In the event you submit information through the Website, you agree
            to provide true, accurate, current and complete information and
            agree to promptly update the information to keep it true, accurate,
            current and complete. If you provide any information that is untrue,
            inaccurate, not current or incomplete, and/or we have reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, we have the right to suspend or terminate
            your participation in the Website and/or refuse any and all current
            or future use of the Website or its services (or any portion
            thereof).
          </Typography>
          <Typography fontSize={"14px"}>
            You understand and agree that all information, statistical data,
            text, software, music, sound, photographs, graphics, video, messages
            or other materials (Content), whether publicly posted or privately
            transmitted by you and other users of our service, are the sole
            responsibility of the person from which such Content originated.
            This means that you, and not us, are entirely responsible for all
            Content that you upload, post, email or otherwise transmit via the
            Website. We do not control all of the Content posted via the Website
            and, as such, do not guarantee the accuracy, integrity or quality of
            such Content. You understand that by using the Website, you may be
            exposed to Content that is offensive, indecent or objectionable.
            Under no circumstances will we be liable in any way for any Content,
            including, but not limited to, for any errors or omissions in any
            Content, or for any loss or damage of any kind incurred as a result
            of the use of any Content posted, emailed or otherwise transmitted
            via the Website.
          </Typography>
          <Typography fontSize={"14px"}>
            You acknowledge that we may or may not pre-screen Content, but that
            our designees and we shall have the right (but not the obligation)
            in our sole discretion to prescreen, refuse, or move any Content
            that is available via the Website. Without limiting the foregoing,
            our designees and we shall have the right (but not the obligation)
            to remove any Content that violates this agreement or is otherwise
            objectionable.
          </Typography>
          <Typography fontSize={"14px"}>
            The decision to form Lawyer-client relationship is an important one
            and is between you and the Lawyer. You may wish to seek and retain
            the consultation of other Lawyers so as to meet all applicable
            deadlines that govern your query / problem. You hereby agree that,
            by taking any of the actions described above, you are soliciting and
            requesting a contact by telephone and as such, you may be contacted
            by telephone or e-mail as set forth in this Agreement and in our
            Privacy Policy, notwithstanding the listing of your telephone number
            and/or e- mail address in any applicable do-not-call registries.
          </Typography>
          <Typography variant="h5">5. SCOPE OF SERVICES</Typography>
          <Typography fontSize={"14px"}>
            vakily.app (the owners and the employee staff of the Website) and
            third- party professionals who offer services through the Website
            accept no responsibility for any medical, legal or financial events
            or outcomes related to services achieved through the use of the
            Website.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app does not guarantee that a web-based consultation,
            over-phone consultation or in-person / video consultation is the
            appropriate way to resolve/ solution for your particular problem.
          </Typography>
          <Typography variant="h5">6. USER’S PROFILE AT VAKILY.APP</Typography>
          <Typography fontSize={"14px"}>
            Your vakily.app Profile is created to store record of your
            consultations and your personal query / problem information online,
            including history, etc.
          </Typography>
          <Typography fontSize={"14px"}>
            Any information provided as part of a web, over-phone or an
            in-person / video consultation becomes part of your vakily.app
            Record. You agree to provide accurate information to help us serve
            you best to our knowledge, to periodically review such information
            and to update such information as and when necessary.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app reserves the right to maintain, delete or destroy all
            communications and materials posted or uploaded to the vakily.app
            Service according to its internal record retention and/or
            destruction policies.
          </Typography>
          <Typography fontSize={"14px"}>
            You might be contacted via email to review the information provided
            by you for vakily.app Record or for Online Consultations. Please
            make sure you provide a valid email-id and you update it as and when
            needed.
          </Typography>
          <Typography variant="h5">7. SUPPLEMENTARY COMMUNICATION</Typography>
          <Typography fontSize={"14px"}>
            vakily.app after your consent may send you newsletters and other
            information regarding common related topics or preventive care
            messages containing specific services’ related information, links to
            other related Websites and specific questions related to your
            vakily.app Record. Additionally, vakily.app makes available
            self-care informational services that provide general, and specific
            services’ information.
          </Typography>
          <Typography fontSize={"14px"}>
            These communications and resources are not comprehensive and do not
            include complete potential information pertaining to the subject
            matter. These are solely meant for general, educational and
            informational purposes and should not be relied upon as a substitute
            for user-specific query / problem evaluation or construed, directly
            or indirectly as the practice of that particular service or
            dispensing of that particular service by vakily.app.
          </Typography>
          <Typography fontSize={"14px"}>
            The information contained in these communications and resources is
            compiled from a variety of sources and may or may not be authored by
            vakily.app. vakily.app makes no warranty with respect to the
            content of these materials or the information contained therein.
          </Typography>
          <Typography fontSize={"14px"}>
            We are not responsible for anything outside of vakily.app. Now or
            in the future, the Content and Services may link you to other web
            Websites or information, software, data, or other contents, on or
            off the Internet. These other Websites or and contents are not
            vakily.app, and the information there is outside of our control. We
            do not control, endorse, or review the content of these Websites,
            and we are not responsible for such content.
          </Typography>
          <Typography variant="h5">8. PROTECTION OF PROPRIETARY INFORMATION</Typography>
          <Typography fontSize={"14px"}>
            You agree that the Services, which include all software and
            information, both electronic and printed media, contain trade
            secrets and other proprietary intellectual property and are the
            proprietary property of vakily.app. They are protected by trademark
            laws and other proprietary rights and laws, and they may only be
            used or accessed as specifically provided for, in this Agreement.
          </Typography>
          <Typography fontSize={"14px"}>
            You agree that you will not store, copy, modify, rent, lease, loan,
            sell, distribute, transfer, transmit, display, reverse-engineer,
            reverse-assemble, or otherwise attempt to discover any programming
            code or any source code used in or with the Services. You will not
            otherwise attempt to transfer any right in the Services (which
            includes its software, design and content), create derivative works
            based on it or in any manner commercially exploit the Services in
            whole or in part. You agree that violations by you, any other person
            or entity, of these trade secrets and / or other intellectual
            property protections or the terms of this Agreement will be lead to
            prosecution under the Indian Laws and Bylaws. The final choice of
            whether the Consumer is in violation of any of these policies is at
            the sole discretion of vakily.app.
          </Typography>
          <Typography fontSize={"14px"}>
            Only for the duration of being logged into the Consumers’ valid
            vakily.app account, the consumer is hereby granted a revocable,
            non-exclusive, non- transferable license to use the services for
            personal, non-commercial purposes; subject to terms and
            qualifications of Agreements of vakily.app.
          </Typography>
          <Typography fontSize={"14px"}>
            With the exception of your case records, vakily.app retains all
            rights, titles and interests pertaining to vakily.app, the
            vakily.app Services and any content, products, documentation,
            software or other materials on the vakily.app web Website along
            with any trade secret, trademark, service mark or other intellectual
            property or proprietary right in any of the foregoing.
          </Typography>
          <Typography fontSize={"14px"}>
            Contact us if you believe information on our Website infringes your
            copyright. If you believe any materials accessible on or from
            vakily.app infringe your copyright, you may request removal of
            those materials.
          </Typography>
          <Typography fontSize={"14px"}>
            No part of the materials including graphics or logos, available in
            this Website may be copied, photocopied, reproduced, translated or
            reduced to any electronic medium or machine-readable form, in whole
            or in part, without specific permission.
          </Typography>
          <Typography fontSize={"14px"}>
            Your license to access and use the Website and its services are
            subject to the following additional restrictions and prohibitions on
            use. You may not (1) copy, print (except for the express limited
            purpose permitted by paragraph 8, above), republish, display,
            transmit, distribute, sell, rent, lease, loan or otherwise make
            available in any form or by any means all or any portion of the
            Website, or any information or materials retrieved therefrom; (2)
            use the Website or any other materials from the Website to develop,
            or as a component of, an information storage and retrieval system,
            database, infobase, or similar information resource (in any media
            now existing or hereafter developed), that is offered for commercial
            distribution of any kind, including through sale, license, lease,
            rental, subscription, or any other commercial distribution
            mechanism; (3) create compilations or derivative works of the
            Website or any other materials from the Website; (4) use the Website
            or any other materials from the Website in any manner that may
            infringe any intellectual property right, or proprietary right of
            vakily.app or any third parties; (5) remove, change, or obscure any
            proprietary notice or Terms & Conditions contained in the Website;
            (6) make any portion of the Website available through any
            timesharing system, service bureau, the Internet, or any other
            technology now existing or developed in the future, without our
            express written consent; (7) remove, disable, defeat or change any
            functionality or appearance of the Website; (8) decompile,
            disassemble or reverse engineer any Website software or use any
            network monitoring or discovery software to determine the Website
            architecture; (9) use any automatic or manual process to harvest
            information from the Website; (10) use the Website for the purpose
            of gathering information for or transmitting (a) unsolicited
            commercial email or SMS; (b) email or SMS that makes use of our name
            or trademarks, including in connection with invalid or forged
            headers, invalid or nonexistent domain names, or other means of
            deceptive addressing; and (c) unsolicited telephone calls or
            facsimile transmissions; (11) use the Website in a manner that
            violates any state or federal law regulating commercial email,
            facsimile transmissions or telephone solicitations; and (12) export
            or re- export the Website or any portion thereof, or any software
            available on or through the Website, in violation of the export
            control laws and regulations of the United States.
          </Typography>
          <Typography variant="h5">9. ETIQUETTE AND PRIVACY</Typography>
          <Typography fontSize={"14px"}>
            The resources of vakily.app should not be used to impersonate
            another person or misrepresent authorization to act on behalf of
            others or vakily.app. All messages transmitted via vakily.app
            should correctly identify the sender. The Consumer should never
            alter the attribution of origin in electronic mail messages or while
            posting any information.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app has zero-tolerance for unsolicited bulk email, bulk SMS,
            unsolicited posting to news groups, marketing gimmicks/spam/scams in
            community resources, or other illegal activities. The Consumer shall
            not infringe upon the rights of others, spam, propagate computer
            worms or viruses, use a false identity, attempt to gain unauthorized
            entry to any Website or network, infringe trademarks, or other
            intellectual property rights. The Consumer further agrees to comply
            with Indian export laws concerning the transmission of technical
            data and other regulated materials via the World Wide Web.
          </Typography>
          <Typography fontSize={"14px"}>
            The Consumer agrees to indemnify and hold vakily.app harmless for
            any and all acts found or suspected to be in violation hereof. The
            Consumer’s account will be terminated for any of the above
            infractions.
          </Typography>
          <Typography fontSize={"14px"}>
            The Consumer shall not attempt to undermine the security or
            integrity of computing systems or networks of vakily.app, its
            partners, or those accessed through or with their product, and must
            not attempt to gain unauthorized access.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app reserves the right to terminate any account which
            doesn’t follow our Terms and Conditions.
          </Typography>
          <Typography variant="h5">10. ELECTRONIC PAYMENT</Typography>
          <Typography fontSize={"14px"}>
            vakily.app may from time to time contract with third party payment
            service providers including banks to open nodal bank account under
            applicable Indian laws, to facilitate the payment between users i.e.
            buyers and sellers and for collection of Platform Fees and other
            charges. These third party payment service providers may include
            third party banking or credit card payment gateways, payment
            aggregators, cash on delivery or demand draft / pay order on
            delivery service providers, mobile payment service providers or
            through any facility as may be authorized by the Reserve Bank of
            India for collection, refund and remittance, as the case may be of
            payment or supporting the same in any manner.
          </Typography>
          <Typography fontSize={"14px"}>
            You agree, understand and confirm that the bank / debit / credit
            card details provided by you for availing services of vakily.app
            will be correct and accurate. These details are entered by you
            directly at the third party payment service provider webpage and
            vakily.app does not have any access whatsoever to your payment
            details.
          </Typography>
          <Typography fontSize={"14px"}>
            Your use of these Third Party Payment service providers is subject
            to their Terms & Conditions. You understand that vakily.app is not
            responsible or liable for the behavior, features, or content of any
            Third Party Payment service provider.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app cannot assist with a stop payment request for any
            payment scheduled using a Payment Account credit/debit card. In any
            such case, you must immediately and directly contact your
            credit/debit card company/bank.
          </Typography>
          <Typography variant="h5">11. PAYMENT, CANCELLATION & REFUND POLICY</Typography>
          <Typography fontSize={"14px"}>
            Payment Policy All fee has to be paid upfront before availing any
            paid service at vakily.app.
          </Typography>
          <Typography fontSize={"14px"}>
            Cancellation Policy In the event of cancellation of any paid
            consultation for which payment has been successfully made and a
            confirmation number has been generated, and the consultation has not
            been concluded due to any avoidable / unavoidable reason(s) we must
            be notified of the same in writing – by an email at
            info@vakily.app.
          </Typography>
          <Typography fontSize={"14px"}>
            Cancellation charges will be effective from the date we receive your
            email. Cancellation can be requested no later than 24 hours before
            final delivery time / Appointment time.
          </Typography>
          <Typography fontSize={"14px"}>
            Our Customer Service team will contact you within 7 business days
            and in cases where the Lawyer has not delivered the booked
            consultation, we will return your money within 15 business days from
            the day the request for Cancellation was received.
          </Typography>
          <Typography fontSize={"14px"}>
            Please note that there will be a nominal deduction (10%) in the
            amount refunded, payable towards online payment processing. The
            refund will be processed directly in your Bank / Credit Card account
            that was used to make the payment.
          </Typography>
          <Typography fontSize={"14px"}>
            Refund Policy In the event your request for consultation is accepted
            but post-payment due to unexpected and extenuating circumstances,
            your request for consultation is rejected or cancelled; You will be
            given an option of opting for another request for consultation OR
            will be offered a complete refund, if you so desire. The refund will
            be credited to your account within 15 working days.
          </Typography>
          <Typography fontSize={"14px"}>
            In case your credit card/debit card/payment account has been
            accidentally over-charged, please notify vakily.app of the same at
            the earliest. vakily.app will refund the outstanding amount to your
            Payment Account within 15 working days. In the reverse scenario, if
            the transaction amount during payment of the fee happens to be less
            than the actual fee amount, vakily.app will debit the outstanding
            amount from your Payment Account and notify you of the same.
          </Typography>
          <Typography fontSize={"14px"}>
            It is mandatory for the users to maintain unique Transaction ID
            numbers, which would be provided at the time of placing a request.
            This number would be required to address any problems, if any.
          </Typography>
          <Typography fontSize={"14px"}>
            In the majority of cases, there is NO other situation under which
            vakily.app will provide a refund, except for the condition
            expressly mentioned above under Refund Policy. In exceptional
            circumstances, the vakily.app team will work with you to reach a
            mutually acceptable solution. vakily.app intends to be objective,
            fair and reasonable in resolving such situations in order to
            maintain a positive and harmonious relationship with consumers. The
            maximum refund amount claimable from vakily.app cannot exceed the
            amount paid by the user at vakily.app for availing the Lawyer's
            service(s).
          </Typography>
          <Typography fontSize={"14px"}>
            No refund in any case whatsoever can be claimed from vakily.app for
            the amount paid to the Lawyer directly by the user.
          </Typography>
          <Typography fontSize={"14px"}>
            Please note that there will be a nominal deduction (10%) in the
            amount refunded, payable towards online payment processing. The
            refund will be processed directly in your Bank / Credit Card account
            that was used to make the payment.
          </Typography>
          <Typography variant="h5">
            12. SUSPENSION DUE TO NON-COMPLIANCE WITH AGREEMENT
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app will make all possible efforts to provide you the best
            services for your payment. However, vakily.app shall not incur any
            liability and shall declare any service guarantee void in event of
            one or more of the following circumstances:
          </Typography>
          <Typography fontSize={"14px"}>
            If you have not provided accurate details including contact
            information (user’s email and/or phone number), username and Payment
            account information.
          </Typography>
          <Typography fontSize={"14px"}>
            If the transaction for payment doesn’t go through with your bank due
            to (including but not limited) insufficient funds, fraudulent
            access, failure of processing the request by our Affiliate merchants
            or your bank.
          </Typography>
          <Typography fontSize={"14px"}>
            If circumstances beyond the control of vakily.app (such as, but not
            limited to, fire, flood, or interference from external forces)
            prevent proper execution of the transaction.
          </Typography>
          <Typography fontSize={"14px"}>
            If you don’t respond to requests of more information (if needed) by
            the Lawyers to make a consultation. We would intimate you the
            needful via email and/or phone and/or SMS and/or as a message in
            your vakily.app account.
          </Typography>
          <Typography fontSize={"14px"}>
            If the user is in breach of any of the Terms and Conditions of this
            Agreement and/or the Terms and Conditions of the usage of
            vakily.app.
          </Typography>
          <Typography variant="h5">13. DISCLAIMERS</Typography>
          <Typography fontSize={"14px"}>
            You acknowledge that your use of the vakily.app Services is solely
            at your own risk, and that you assume full responsibility for all
            risks associated therewith. To the extent that any of the Content
            included on vakily.app is provided by third party content providers
            or other users, we have no editorial control or responsibility of
            such content appearing on vakily.app. All information, products or
            services contained on or provided through this Website are provided
            'as is', without any warranty of any kind, express or implied, to
            the fullest extent permissible under applicable law. vakily.app and
            its Affiliates hereby disclaim all representations and warranties,
            express or implied, statutory or otherwise, including but not
            limited to warranties of merchantability, appropriateness, title,
            non-infringement and freedom from virus strains.
          </Typography>
          <Typography fontSize={"14px"}>
            Third-party content may appear on this Website or may be accessible
            via links from this Website. We shall not be responsible for and
            assume no liability for any mistakes, misstatements of law,
            defamation, omissions, falsehood, obscenity, pornography or
            profanity in the statements, opinions, representations or any other
            form of content contained in any third-party content appearing on
            the Website. You understand that the information and opinions in the
            third-party content represent solely the thoughts of the author and
            is neither endorsed by us nor does it reflect our beliefs.
          </Typography>
          <Typography fontSize={"14px"}>
            The Website may contain 'hyperlinks' to Websites neither owned nor
            controlled by us and we are not responsible for, and, unless
            otherwise noted specifically, make no representations or
            endorsements with respect to these Websites or with respect to any
            service or product associated with these Websites. By including
            these hyperlinks, we do not mean to state or imply that we sponsor,
            are affiliated with, or are legally authorized to use any trade
            name, registered trademark, service mark, logo, legal or official
            seal, or copyrighted symbol that may be reflected in the hyperlinks.
          </Typography>
          <Typography fontSize={"14px"}>
            Your correspondence or business dealings with, or participation in
            promotions of, advertisers found on or through the Website,
            including payment and delivery of related goods or services, and any
            other terms, conditions, warranties or representations associated
            with such dealings, are solely between you and such advertiser. You
            agree that we shall not be responsible or liable for any loss or
            damage of any sort incurred as the result of any such dealings or as
            the result of the presence of such advertisers on the Website.
          </Typography>
          <Typography fontSize={"14px"}>
            Without limiting the foregoing, vakily.app and its affiliates make
            no warranty as to the reliability, accuracy, timelines, usefulness,
            adequacy, completeness or suitability of the vakily.app products
            and services provided hereunder. vakily.app makes no
            representations or warranties about the satisfaction of government
            regulations.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app will take all necessary precautions to protect itself
            against failure of our hardware and our software. The Consumer
            acknowledges and agrees that temporary interruptions in service may
            occur, and that vakily.app shall have no liability for any claim,
            cost, charge, loss or expense arising from or relating to use of the
            Services. vakily.app makes no warranty that the Services will meet
            your requirements, or that the Service(s) will be uninterrupted,
            timely, secure, or error free. The Consumer acknowledges and agrees
            that in the event of failure, restoration of service may take
            several days.
          </Typography>
          <Typography fontSize={"14px"}>
            We reserve the right at any time and from time to time to modify or
            discontinue, temporarily or permanently, the Website (or any part of
            it) with or without notice. In the event of a modification or
            discontinuance, all information submitted by you and others may be
            lost. You agree to keep a permanent record of all information
            provided to us, and you agree that all information submitted to us
            is at your sole risk. You agree that we have no responsibility or
            liability for the deletion or failure to store any Content
            maintained or transmitted by the Website including without
            limitation messages and other communications. You agree that we
            shall not be liable to you or to any third party for any
            modification, suspension or discontinuance of the Service.
          </Typography>
          <Typography fontSize={"14px"}>
            We do not represent or warrant that the Website will be error-free,
            free of viruses or other harmful components, or that defects will be
            corrected. We do not warrant or represent that the information
            available on or through the Website will be correct, accurate,
            timely, or otherwise reliable. We may (but are not obligated to)
            make improvements and/or changes to its features, functionality or
            content of the Website at any time. In no event shall we be liable
            for any such errors or defects.
          </Typography>
            <Typography variant="h5">
            14. DISCLAIMER OF WARRANTIES

            </Typography>
          <Typography fontSize={"14px"}>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT: YOUR USE OF THE WEBSITE IS
            AT YOUR SOLE RISK. ALL CONTENT AND SERVICES ON THE WEBSITE IS
            PROVIDED SOLELY ON AN 'AS-IS/AS-AVAILABLE' BASIS. TO THE EXTENT
            PERMITTED BY APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF
            ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO
            THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
            SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE OR USE, AS TO
            THE RESULTS YOU MAY ACHIEVE ON ACCOUNT YOUR USE OF THE WEBSITE, AS
            TO THE RESULTS YOU MAY ACHIEVE ON ACCOUNT OF ANY RELATIONSHIP YOU
            ELECT TO CREATE WITH Lawyer / PROFESSIONAL, AND NON-INFRINGEMENT.
            NEITHER vakily.app NOR ANY OF ITS LICENSORS OR AFFILIATES MAKES ANY
            REPRESENTATIONS OR WARRANTIES REGARDING ANY Lawyer / PROFESSIONAL,
            INCLUDING, WITHOUT LIMITATION, ANY Lawyer / PROFESSIONAL’s ABILITY
            TO OBTAIN A FAVORABLE RESULT FOR YOU.
          </Typography>
          <Typography fontSize={"14px"}>
            WE DO NOT GUARANTEE THE ACCURACY OR COMPLETENESS OF ANY CONTENT OR
            SERVICES AND WE DO NOT GUARANTY IN ANY SERVICES OR GOODS ASSOCIATED
            WITH THE WEBSITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY
            SERVICE OR GOOD WILL CONTINUE TO BE AVAILABLE.
          </Typography>
          <Typography fontSize={"14px"}>
            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
            SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
            DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
          </Typography>
          <Typography fontSize={"14px"}>
            NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR
            THROUGH OR FROM THE WEBSITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY
            STATED IN THIS AGREEMENT.
          </Typography>
          <Typography variant="h5">15. LIMITATION OF LIABILITY</Typography>
          <Typography fontSize={"14px"}>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT WE SHALL NOT BE LIABLE FOR
            ANY, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
            DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES,
            PROFITS, GOODWILL, USE, DATA, FAILURE TO REALIZE EXPECTED SAVINGS,
            OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE
            INABILITY TO USE THE WEBSITE; (ii) THE COST OF PROCUREMENT OF
            SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
            INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED
            OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE WEBSITE; (iii)
            INVALID DESTINATIONS, TRANSMISSION ERRORS, OR UNAUTHORIZED ACCESS TO
            OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR
            CONDUCT OF ANY THIRD PARTY ON THE WEBSITE; (v) YOUR FAILURE TO
            RECEIVE ANY THIRD PARTY SERVICES OR PRODUCTS REQUESTED THROUGH THE
            WEBSITE OR (vi) ANY OTHER MATTER RELATING TO THE WEBSITE. IN NO
            EVENT ARE WE LIABLE TO YOU FOR AN AMOUNT IN EXCESS OF THE AMOUNT
            PAID BY YOU TO US FOR THE SERVICES IN QUESTION, IF ANY.
          </Typography>
          <Typography fontSize={"14px"}>16. EXCLUSIONS AND LIMITATIONS</Typography>
          <Typography fontSize={"14px"}>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES
            OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF
            THE SECTIONS ABOVE MAY NOT APPLY TO YOU.
          </Typography>
          <Typography variant="h5">17. INDEMNIFICATION</Typography>
          <Typography fontSize={"14px"}>
            You shall defend, indemnify, save and hold vakily.app, its
            affiliates harmless from all demands, liabilities, claims and
            expenses, including Lawyer's fees, fees of third parties relating to
            or arising under this Agreement, the Services provided hereunder or
            your use of the Services, including without limitation, infringement
            by you or someone else using the service with your computer, of any
            intellectual property or other proprietary right of any person or
            entity, or from the violation of any of our operating rules or
            policy relating to the Service(s) provided.
          </Typography>
          <Typography fontSize={"14px"}>
            When we are threatened with suit by a third party, we may seek
            written assurances from you concerning your promise to indemnify us;
            your failure to provide those assurances may be considered by us to
            be a breach of your agreement and may result in deactivation of your
            service(s) with immediate effect.
          </Typography>
          <Typography fontSize={"14px"}>
            Under no circumstances, including negligence, shall vakily.app
            including (it’s Affiliates) or anyone else involved in creating,
            producing or distributing vakily.app Services be liable for any
            direct, indirect, incidental, special or consequential damages that
            result from the use of or inability to use vakily.app Services, or
            that results from mistakes, omissions, interruptions, deletion of
            files, errors, defects, delays in operation, or transmission or any
            failure of performance, whether or not limited to acts of God,
            communication failure, theft, destruction or unauthorized access to
            vakily.app records, programs or services. This paragraph shall
            apply to all content and functionality of vakily.app Services.
          </Typography>
          <Typography fontSize={"14px"}>
            Notwithstanding the above, the Consumer's exclusive remedies for all
            damages, losses and causes of actions whether in contract, including
            negligence or otherwise, shall not exceed the aggregate amount,
            which the Consumer paid directly to vakily.app for the service in
            question. Such limitations shall apply to vakily.app total
            liability, including without limitation any liability for damages
            caused or allegedly caused by any failure of performance, error,
            omission, interruption, deletion, defect, delay in operation of
            transmission, communications failure, theft of destruction of or
            unauthorized access to, alteration of, or use of records, whether
            for breach of contract, tortuous behavior, negligence, or under any
            other cause of action.
          </Typography>
          <Typography fontSize={"14px"}>
            In no event shall vakily.app nor any of its affiliates be liable
            for any direct damages in excess of the purchase price for the
            product. In addition, to the maximum extent permitted by law, in no
            event shall vakily.app nor any of its affiliates be liable for any
            special, punitive, indirect, incidental or consequential damages,
            including but not limited to personal injury, wrongful death, loss
            of use, loss of profits, interruption of service or loss of data,
            whether in any action in warranty, contract, tort (including, but
            not limited to negligence or fundamental breach), or otherwise
            arising out of or in any way connected with the use of, or the
            inability to use, this Website or any service offered through this
            Website or any material or information contained in, accessed
            through, or products purchased on this Website, even if an
            authorized representative of vakily.app is advised of the
            likelihood or possibility of the same. To the extent certain
            jurisdictions restrict any of the above limitations of liability;
            such limitations shall not apply in such jurisdictions to the extent
            of such restrictions.
          </Typography>
          <Typography variant="h5">18. THIRD PARTY PROTECTION</Typography>
          <Typography fontSize={"14px"}>
            The indemnification and disclaimers provisions set forth above are
            for the benefit of vakily.app and its affiliates. Each of these
            individuals or entities shall have the right to assert and enforce
            those provisions directly against you, the Consumer on its own
            behalf.
          </Typography>
          <Typography variant="h5">19. JURISDICTION</Typography>
          <Typography fontSize={"14px"}>
            This Agreement constitutes the entire understanding of the parties,
            and is agreed to being entered into within the jurisdiction of
            Ranchi, Jharkhand, India and shall be governed by and shall be
            construed in accordance with the laws of the Republic of India,
            without giving effect to any choice of law rules or principles.
          </Typography>
          <Typography fontSize={"14px"}>
            Any dispute with vakily.app shall exclusively be subject to the
            jurisdiction of the appropriate Courts situated at Ranchi,
            Jharkhand, India. No party shall object to removal or prosecution of
            any litigation to a court within the jurisdiction of Ranchi,
            Jharkhand, India.
          </Typography>
          <Typography fontSize={"14px"}>
            Any cause of action or claim you may have with respect to
            vakily.app must be commenced within one (1) year after it arises,
            except to the extent such litigation is not enforceable.
          </Typography>
          <Typography fontSize={"14px"}>
            To the fullest extent permitted by law, each party to this Terms &
            Conditions waives its or his or her right to a jury trial with
            respect to any dispute or other controversy arising from hereunder
            or your use of or access to the vakily.app Services or Website.
          </Typography>
          <Typography fontSize={"14px"}>
            To the fullest extent permitted by law, each party to this Terms &
            Conditions waives its or his or her right to a jury trial with
            respect to any dispute or other controversy arising from hereunder
            or your use of or access to the vakily.app Services or Website.
          </Typography>
          <Typography fontSize={"14px"}>
            Failure to exercise or delay in exercising any right hereunder, or
            failure to insist upon or enforce strict performance of any
            provision of this Terms & Conditions, shall not be considered waiver
            thereof, which can only be made by signed writing. No single waiver
            shall be considered a continuing or permanent waiver.
          </Typography>
          <Typography variant="h5">20. RIGHT TO OPERATIONAL FUNCTIONALITY</Typography>
          <Typography fontSize={"14px"}>
            vakily.app reserves complete and sole discretion with respect to
            the operation of our Services. We may, among other things withdraw,
            suspend or discontinue any functionality or feature of our Services.
          </Typography>
          <Typography fontSize={"14px"}>
            By using the Website, you do not acquire any rights to the Website
            other than the limited license to use the Website that can be
            terminated in accordance with this section. You agree that we, in
            our sole discretion, may terminate your password, account (or any
            part thereof) or use of the Website, and remove and discard any
            Content within the Website, for any reason, including, without
            limitation, non-payment, for lack of use, or if we believe that you
            have violated or acted inconsistently with the letter or spirit of
            this agreement. We may also in our sole discretion and at any time
            discontinue providing the Website, or any part thereof, with or
            without notice. You agree that any termination of your access to the
            Website under any provision of this agreement may be effected
            without prior notice, and acknowledge and agree that we may
            immediately deactivate or delete your account and all related
            Content information and files in your account and/or bar any further
            access to such files or the Website. Further, you agree that we
            shall not be liable to you or any third-party for any termination of
            your access to the Website.
          </Typography>
          <Typography variant="h5">21. RIGHT TO MODIFY TERMS OF SERVICE</Typography>
          <Typography fontSize={"14px"}>
            You agree and confirm that the Terms of Service may be amended from
            time to time without notice and take responsibility to update
            yourself periodically with the latest version available on
            vakily.app Website. Any such revision or change will be binding and
            effective immediately on release of the revised Agreement or change
            to the Service(s) on our Website. Unless we notify otherwise, these
            terms incorporate and supersede any other terms associated with the
            Services.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app has the right to discontinue service, or deny access to
            anyone who violates our policies or the terms and conditions stated
            herein, without prior notice or warning.
          </Typography>
          <Typography fontSize={"14px"}>
            vakily.app may freely transfer, assign or delegate all or any part
            of this Terms & Conditions, and any rights and duties hereunder.
            This Terms & Conditions will be binding upon and inure to the
            benefit of the heirs, successors and permitted assignees of the
            parties.
          </Typography>
          <Typography variant="h5">22. NOTICE</Typography>
          <Typography fontSize={"14px"}>
            vakily.app may provide notice to you- the Consumer, by e-mail to
            the e-mail address provided by you during registration, by a general
            notice on the vakily.app Website. You may give notice to
            vakily.app at any time via email to connect@vakily.app
          </Typography>
          <Typography variant="h5">23. ASSIGNMENT</Typography>
          <Typography fontSize={"14px"}>
            We may assign this contract at any time to any parent, subsidiary,
            or any affiliated company, or as part of the sale to, merger with,
            or other transfer of our company to another entity. We will post a
            notice on the Website regarding any change of ownership so that you
            have the opportunity to discontinue your use of the Website or
            cancel your registration if you do not wish to continue to use the
            Website and the Services under the new ownership. You may not
            assign, transfer or sublicense these Terms & Conditions to anyone
            else and any attempt to do so in violation of this section shall be
            null and void.
          </Typography>
          <Typography variant="h5">24. WAIVER AND SEVERABILITY OF TERMS</Typography>
          <Typography fontSize={"14px"}>
            Our failure to exercise or enforce any right or provision of this
            agreement shall not constitute a waiver of such right or provision.
            If any provision of the agreement is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties' intentions as
            reflected in the provision, and the other provisions of the
            agreement shall remain in full force and effect.
          </Typography>
          <Typography variant="h5">25. LAWYER / PROFESSIONAL ETHICS NOTICE</Typography>
          <Typography fontSize={"14px"}>
            If you are Lawyer / Professional, participating in any aspect of
            this Website, you acknowledge that rules of professional conduct
            apply to all aspects of your participation and that you will abide
            by such rules. The rules include, but are not limited to, the rules
            relating to advertising, solicitation of clients, unauthorized
            practice of your respective profession, and misrepresentations of
            fact. We disclaim all responsibility for your compliance with these
            rules.
          </Typography>
          <Typography fontSize={"14px"}>
            The section titles in this agreement are for convenience only and
            have no legal or contractual effect.
          </Typography>
          <Typography variant="h5">26. PRIVACY</Typography>
          <Typography fontSize={"14px"}>
            vakily.app does its best to protect your privacy with respect to
            information not otherwise disclosed by you in questions submitted to
            vakily.app.
          </Typography>
          <Typography fontSize={"14px"}>
            In connection with the submission of Free Questions and Paid
            Consultations, we only require, and we encourage you to only
            provide, the minimum amount of personal identifying information
            necessary to process a question. All of your personal information
            provided to vakily.app, including email addresses, is concealed
            from all other Users of vakily.app and is never published as part
            of the vakily.app public database. Only the vakily.app
            administrators have access, for purposes of Website administration
            only, to this information. vakily.app will not disclose this
            information to third parties without your permission, except in the
            following circumstances: (i) to protect ourselves from liability, or
            (ii) to respond to legal process or to otherwise comply with the
            law. With respect to certain information supplied by you, as
            explained further below, there will be a need for disclosure to
            Lawyers / Professionals when you have booked a paid consultation.
          </Typography>
          <Typography fontSize={"14px"}>
            Whether other information submitted to vakily.app by you, such as
            the contents of the Question itself, will be publicly disclosed will
            vary as between Free Questions and Paid Consultations:
          </Typography>
          <Typography variant="h5">27. OWNERSHIP OF CONTENT</Typography>
          <Typography fontSize={"14px"}>
            By submitting a Free Question to vakily.app, you understand and
            agree that your question and any answer provided by Lawyer /
            Professional will become part of the vakily.app public database and
            the sole property of vakily.app.
          </Typography>
          <Typography fontSize={"14px"}>
            Thus, as to all Free Questions and any answers, you agree that,
            without any compensation to you, vakily.app will own all rights to
            the submitted questions and answers (including any intellectual
            property and moral rights) and will have the sole unrestricted right
            to use, reproduce, edit, modify, sell, adapt, publish, translate,
            create derivative works from, distribute, communicate to the public,
            perform and display the submitted content (in whole or in part)
            worldwide and/or to incorporate it in other works in any form,
            media, or technology now known or later developed, for the full term
            of any rights that may exist in the content.
          </Typography>
          <Typography variant="h5">28. ENTIRE AGREEMENT</Typography>
          <Typography fontSize={"14px"}>
            These Terms & Conditions, together with the privacy policy, which
            shall be deemed to be a part hereof and incorporated herein by
            reference represent the complete agreement between you and
            vakily.app concerning the subject matter hereof, and it replaces
            all prior oral or written communications (if any) concerning such
            subject matter.
          </Typography>
          <Typography fontSize={"14px"}>
            The Terms & Conditions shall commence upon the date you use the
            Website in any form. This Terms & Conditions and the licenses
            granted hereunder shall be terminated without notice in the event
            you (or any authorized person using your account) fail to comply
            with the Terms and Conditions of this Terms & Conditions or the
            rules for use of the vakily.app service promulgated by vakily.app
            from time to time. We may also cancel or suspend your registration
            for any other reason, including inactivity for an extended period,
            but will attempt to notify you in advance of such cancellation or
            suspension. Upon any termination, you will destroy all copies of
            vakily.app materials in your possession and cease any access to or
            use of the Website. Upon this suspension or termination we may
            delete information, files, and other previously available content to
            you including but not limited to information provided by you.
            vakily.app shall not be liable to you or any third party for any
            termination of your access to the Website and/or the Services.
          </Typography>
          <Typography fontSize={"14px"}>
            The provisions of this Terms & Conditions are severable, and in the
            event any provision hereof is determined to be invalid or
            unenforceable, such invalidity or unenforceability shall not affect
            the validity or enforceability of the remaining provisions, but such
            a provision shall be reformed, if reasonably possible, only to the
            extent necessary to make it enforceable.
          </Typography>
          <Typography fontSize={"14px"}>
            The failure of vakily.app to exercise or enforce any right or
            provision of these Terms & Conditions shall not constitute a waiver
            of such right or provision. The failure of either party to exercise
            in any respect any right provided for herein shall not be deemed a
            waiver of any further rights hereunder.
          </Typography>
          <Typography fontSize={"14px"}>
            Your affirmative act of using this Website and/or registering for
            the Website or the Services constitutes your electronic signature to
            these Terms & Conditions, Privacy Policy that shall be deemed to be
            a part hereof and incorporated herein by reference and your consent
            to enter into agreements with us electronically.
          </Typography>
          <Typography fontSize={"14px"}>
            I, the User/client Understand the risks and benefits of the online
            consultation Acknowledge and agree to assume the risk of the
            above-mentioned limitations pursuant to the use of vakily.app. I
            further understand and agree that no warranty or guarantee has been
            made to me concerning any consultation on particular problem,
            result, solution of my query / problem. I understand that the laws
            that protect privacy and the confidentiality of personal and / or
            Professional Services’ information also apply to vakily.app, and
            that my information will be only used as defined by the
            vakily.app’s Terms & Conditions and Privacy Statement. I understand
            that the consultation through vakily.app may involve electronic
            communication of my personal information to other Lawyers who may be
            located in other areas, including out of state. Notwithstanding
            User’s registration with National Do Not Call Registry (In Fully or
            Partly blocked category under National Customer Preference Register
            set up under Telecom Regulatory Authority of India), User hereby
            expresses his interest and accord its willful consent to receive
            communication (including commercial communication) in relation to
            company name. User further confirms that any communication, as
            mentioned herein above, shall not be construed as Unsolicited
            Commercial Communication under the TRAI guidelines and User has
            specifically opted to receive communication in this regard on the
            telephone number provided by the User. If you do not agree to our
            Terms & Conditions or our Privacy Policy we kindly request you
            REFRAIN USING this Website.
          </Typography>
          <Typography fontSize={"14px"}>Grievance officer</Typography>
          <Typography fontSize={"14px"}>Name : Sanjay Singh</Typography>
          <Typography fontSize={"14px"}>Mobile : +91-8920766203</Typography>
          <Typography fontSize={"14px"}>Email : connect@vakily.app</Typography>
        </Div>
      </Card>
    </>
  );
};
