import React, { useState } from "react";
import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import { CardContent, CardHeader, TextField, Card,Grid } from "@mui/material";
import { addEnquiryNotes } from "app/Apis/Enquiry";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatDate } from "app/utils/dateTimeHelpers";
import { useSelector, useDispatch } from "react-redux";
import { updateNotesList } from "app/redux/actions/enquiry";
import TimelineCard from "app/Components/Timeline/TimelineCard";

const testData = [
  {
    callBackDate: "19 AUG 2023",
    notes: "Test Notes",
  },
  {
    callBackDate: "19 AUG 2023",
    notes: "Test Notes",
  },
  {
    callBackDate: "19 AUG 2023",
    notes: "Test Notes",
  },
  {
    callBackDate: "19 AUG 2023",
    notes: "Test Notes",
  },
  {
    callBackDate: "19 AUG 2023",
    notes: "Test Notes",
  },
  {
    callBackDate: "19 AUG 2023",
    notes: "Test Notes",
  },
];

function Notes({ enquiryDetails, handleNextButton, handleBackButton }) {
  const notesFromredux = useSelector((state) => state?.enquiry?.notes);

  const [date, setDate] = useState(null);
  const [desc, setDesc] = useState("");
  const [isAddNotes, setIsAddNotes] = useState(false);
  const [notesObject, setNotesObject] = useState(
    // Boolean(
    //   enquiryDetails?.notes &&
    //     Object.keys(notesFromredux).length === 0 &&
    //     notesFromredux.constructor === Object
    // )
    //   ? enquiryDetails?.notes
    //   : Object.keys(notesFromredux).length !== 0 &&
    //     notesFromredux.constructor === Object
    //   ? notesFromredux
    //   : {}
    Object.keys(notesFromredux).length !== 0 &&
      notesFromredux.constructor === Object
      ? notesFromredux
      : enquiryDetails?.notes
  );
  const dispatch = useDispatch();
  const keys = notesObject ? Object.keys(notesObject) : [];
  // console.log(keys);
  const addNotes = async () => {
    try {
      let body={};
      let index= -1;
      console.log(keys);
      if(notesObject){
      // keys.findIndex((dateParam)=>toString(formatDate(dateParam))===toString(date.format("YYYY-MM-DD")));
        keys.map((pDate,idx)=>{
          console.log(pDate,date.format("YYYY-MM-DD"));
          if(pDate===date.format("YYYY-MM-DD")){
            index=idx;
            console.log(idx);
          }
        })
      }
      if(index===-1){
        body = {
          enquiryId: enquiryDetails?.enquiryId,
          date: date.format("YYYY-MM-DD"),
          notes: {
            [date.format("YYYY-MM-DD")]: [desc],
          },
        }
      }else{
        body={

            enquiryId: enquiryDetails?.enquiryId,
            date: date.format("YYYY-MM-DD"),
            notes: {
              [date.format("YYYY-MM-DD")]: [...notesObject[date.format("YYYY-MM-DD")],desc],
            },
          }

      }
      console.log(body);
      const response = await addEnquiryNotes(body);
      console.log(response);
      if (response) {
        dispatch(updateNotesList(response?.notes));
        setNotesObject(response?.notes);
        // setShowUpdate(!showUpdate);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div style={{ height: "100%", width: "100%", paddingBottom: "30px" }}>
      <Div
        sx={{
          marginLeft: { xs: "5%", md: "18%" },
          marginRight: { xs: "5%", md: "18%" },
          marginTop: "20px",
          height: "100%",
        }}
      >
        <Div
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            mb: 3,
            // pl: { sm: "0", lg: "18%" },
            // pr: { sm: "0", lg: "18%" },
            mt: "20px",
          }}
        >
          {isAddNotes ? (
            <LoadingButton
              onClick={() => {
                setIsAddNotes(false);
              }}
              color="secondary"
              sx={{
                width: 200,
                padding: 1,
                backgroundColor: "#eabf4e",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="contained"
            >
              See All Notes
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={() => {
                setIsAddNotes(true);
              }}
              color="secondary"
              sx={{
                width: 200,
                padding: 1,
                backgroundColor: "#eabf4e",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="contained"
            >
              Add Notes
            </LoadingButton>
          )}
        </Div>
        {isAddNotes ? (
          <Div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Choose the Call Back Date"
                format="DD/MM/YYYY"
                disablePast
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                sx={{ width: "100%", mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    name="date"
                    color="secondary"
                    required
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              sx={{ mb: 0, mt: 2, width: "100%" }}
              id="outlined-basic"
              label="Write  Notes..."
              variant="outlined"
              multiline
              rows={3}
              value={desc}
              name="paymentDescription"
              color="secondary"
              onChange={(e) => setDesc(e.target.value)}
            />
          </Div>
        ) : (
          <Div
            sx={{
              // display: "flex",
              // justifyContent: "space-evenly",
              // flexDirection: "column",
              // gap: 2,
            }}
          >
            {/* {keys.map((key, index) => {
              console.log(notesObject[key]);
              return (
                <Card>
                  <CardHeader title={formatDate(key)} />
                  <CardContent sx={{ pt: 0 }}>
                    {notesObject[key].map((desc, index) => {
                      return `${desc}, `;
                    })}
                  </CardContent>
                </Card>
              );
            })} */}
            <Grid container
          >
            <Grid item xs={12} sm={4}>
            <TimelineCard
              keys={keys}
              notesObject={notesObject}
              title={" Notes Timeline"}
            />
            </Grid>
            <Grid item xs={12} sm={8}>
                <Div sx={{maxHeight:"65vh",overflow:"auto"}}>
              <Grid container gap={1}
              sx={{
                p:2
              }}
              >
                {keys.map((key, index) => {
              console.log(notesObject[key]);
              return (
                <Card sx={{width:"100%"}}>
                  <CardHeader title={formatDate(key)} />
                  <CardContent sx={{ pt: 0 }}>
                    {notesObject[key].map((desc, index) => {
                      return `${desc}, `;
                    })}
                  </CardContent>
                </Card>
              );
            })}
              </Grid>
              </Div>
            </Grid>
          </Grid>
          </Div>
        )}
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <LoadingButton
            onClick={handleBackButton}
            variant="contained"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
          >
            Back
          </LoadingButton>
          <LoadingButton
            onClick={() => {
              addNotes();
              setIsAddNotes(false);
            }}
            color="secondary"
            sx={{
              width: 100,
              padding: 1,
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "#eabf4e",
                color: "black",
              },
            }}
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Div>
      </Div>
    </div>
  );
}

export default Notes;
