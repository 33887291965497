import React, { useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
// import List from "@mui/material/List";
// import { Chip } from "@mui/material";
// import { Tabs } from "@mui/material";
import { Alert, Snackbar, Typography } from "@mui/material";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";

import Court from "./court";
import Education from "./education";
import Span from "@jumbo/shared/Span/Span";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";

const Professional = ({ details, setShowUpdate, showUpdate }) => {
  console.log(details);
  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [dataEdit, setDataEdit] = useState(false);
  const isMobile = window.innerWidth < 990;
  const handleClick = () => {
    //  navigate("/lawyers/professionalEdit?edit=true")
    setDataEdit(!dataEdit);
  };
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    setOpenSnackBar(false);
  };
  const [submit, setSubmit] = useState(false);

  return (
    <Div>
      <>
        {openSnackBar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackBar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            sx={{ zIndex: 1000, mt: 10 }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%", pr: 3, pl: 3, borderRadius: "10px" }}
            >
              <Typography variant="h6">Deatils Update SuccessFully</Typography>
            </Alert>
          </Snackbar>
        )}
      </>
      <JumboCardQuick
        title={
          <Typography sx={{ fontSize: 20 }}>
            Professional Details
            <EditIcon
              sx={{
                mx: 2,
                transition: "background-color 0.3s",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "#EABF4E",
                  cursor: "pointer",
                },
              }}
              onClick={handleClick}
            />
            {dataEdit ? (
              <LoadingButton
                onClick={() => setSubmit(true)}
                color="secondary"
                sx={{
                  width: 100,
                  padding: 1,
                  mt: "-11px",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "#eabf4e",
                  },
                }}
                variant="contained"
              >
                Update
              </LoadingButton>
            ) : null}
          </Typography>
        }
        action={
          <TabContext value={value}>
            <Div
              sx={{
                overflowX: "auto",
                overflowY: "auto",

                marginTop: -2.25,
                marginBottom: -2.5,
                display: "flex",
                justifyContent: "flex-end",

                "& .MuiTab-root": {
                  paddingTop: isMobile ? "70px" : "25px",
                  paddingBottom: isMobile ? "12px" : "27px",
                },
              }}
            >
              <TabList
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="lab API tabs example"
              >
                <Tab label="Court" value="0" />
                <Tab label="Education" value="1" />
              </TabList>
            </Div>
          </TabContext>
        }
        headerSx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        sx={{
          mb: 3.75,
          height: "19rem",
          overflowY: "auto",
        }}
      >
        {value === "0" ? (
          <Court
            setOpenSnackBar={setOpenSnackBar}
            details={details}
            dataEdit={dataEdit}
            submit={submit}
            setDataEdit={setDataEdit}
            setShowUpdate={setShowUpdate}
          />
        ) : value === "1" ? (
          <Education
            setOpenSnackBar={setOpenSnackBar}
            details={details}
            dataEdit={dataEdit}
            submit={submit}
            setDataEdit={setDataEdit}
            setShowUpdate={setShowUpdate}
          />
        ) : (
          ""
        )}
      </JumboCardQuick>
    </Div>
  );
};

export default Professional;
