import React, { useState ,lazy } from "react";

// import Case from "./Case";
// import Document from "./Document";
// import Hearing from "./Hearing";
// import ECourtDetails from "./E-Court Details";
// import Client from "./Client";
// import OppositeClient from "./OppositeClient";
// import Witness from "./Witness";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ToDo from "./ToDO";
import {
  Breadcrumbs,
  Card,
  CardContent,
  Typography,
  Link,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Div from "@jumbo/shared/Div";
import { GavelOutlined } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import CasePayment from "./CasePayment";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CorporateClient from "./CorporateClient";

// lazy load all forms
const Case = lazy(()=>{console.log("Lazy Case"); return import("./Case")})
const Document = lazy(()=>{console.log("Lazy Document"); return import("./Document")})
const Hearing = lazy(()=>{console.log("Lazy Hearing"); return import("./Hearing")})
const ECourtDetails= lazy(()=>{console.log("Lazy ECourt"); return import("./E-Court Details")})
const Client = lazy(()=>{console.log("Lazy Client "); return import("./Client")})
const OppositeClient = lazy(()=>{console.log("Lazy OppClient"); return import("./OppositeClient")})
const Witness = lazy(()=>{console.log("Lazy Witness"); return import("./Witness")})

const AddCase = ({
  setAddCase,
  setUpdate,
  setOpenSnackBar: setCaseAddedSnackBar,
  values,
}) => {
  const [reset, setReset] = useState(false);
  const resetValue = () => {
    setReset(true);
  };

  const [team, setTeam] = useState({});
  const [addClient, setAddClient] = useState(false);
  const [value, setValue] = useState(0);
  const [buttonValue, setButtonValue] = useState(0);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const paramAddCAse = queryParameters.get("addCase")
  const navigate=useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const caseId = useSelector((state) => state?.dashboard?.caseResponse?.caseId);

  console.log("button is ", buttonValue);
  console.log("value rn is", value);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    setValue(0);
    setButtonValue(0);
  };

  const handleNextButton = () => {
    if (value !== 8) {
      setValue((prevButtonValue) => prevButtonValue + 1);
    }
    setButtonValue((prevButtonValue) => prevButtonValue + 1);
  };

  const handleBackButton = () => {
   
    if(paramAddCAse){
      navigate("/lawyers/cases")
    }
    if (value !== 0) {
      setButtonValue((prevButtonValue) => prevButtonValue - 1);
      setValue((prevButtonValue) => prevButtonValue - 1);
    } else {
      setAddCase(false);
    }
  };

  //modals
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log("open value is ", open);

  return (
    <>
      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackBar}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          sx={{ zIndex: 1000, mt: 10 }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{
              width: "100%",
              pr: 3,
              pl: 3,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Fill Case form first</Typography>
          </Alert>
        </Snackbar>
      )}
      <Div sx={{ height: "100%", width: "100%" }}>
        <Div sx={{ mb: 2}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link href="/home" underline="none">
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <HomeIcon style={{ marginRight: 0.5, fontSize: 20 }} />
                Home
              </Typography>
            </Link>

            <Link
              underline="none"
              onClick={() => setAddCase(false)}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <ListAltIcon sx={{ mr: 0.5, fontSize: 20 }} />
                Case List
              </Typography>
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <GavelOutlined sx={{ fontSize: 20 }} />
              My Cases
            </Typography>
          </Breadcrumbs>
        </Div>
        <Card
          sx={{
            backgroundColor: "white",
            height: "100%",
            position: "relative",
            paddingTop: "10px",
          }}
        >
          <CardContent>
            <Div
              sx={{
                width: "100%",
                position: "relative",
                height: "fit-content",
                mt: -2,
                mb: 8,
              }}
            >
              <Div
                sx={{
                  position: "absolute",
                  borderBottom: "1px solid #D3D3D3 ",
                  width: "100%",
                  pt: 0,
                  pb: 2,
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Div
                  style={{
                    height: "19px",
                    width: "5px",
                    backgroundColor: "#EABF4E",
                    marginRight: "8px",
                  }}
                ></Div>
                <Typography
                  variant="h5"
                  color="text.primary"
                  sx={{
                    fontSize: "1.25rem",
                    lineHeight: 1.5,
                    fontWeight: 400,
                  }}
                >
                  My Cases
                </Typography>
              </Div>
            </Div>
            <Div
              sx={{
                pl: { sm: "0", lg: "11%" },
                pr: { sm: "0", lg: "11%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Div
                sx={{
                  maxWidth: { xs: 320, sm: 600, md: 700, lg: 800 },
                  mt: -1,
                }}
              >
                <Tabs
                  indicatorColor="secondary"
                  textColor="secondary"
                  value={value}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Case"
                    onClick={() => {
                      setValue(0);
                      setButtonValue(0);
                    }}
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="E-Court Details"
                    onClick={() => {
                      if (caseId) {
                        setValue(1);
                        setButtonValue(1);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Document"
                    onClick={() => {
                      if (caseId) {
                        setValue(2);
                        setButtonValue(2);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />

                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Client"
                    onClick={() => {
                      if (caseId) {
                        setValue(3);
                        setButtonValue(3);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Corporate Client"
                    onClick={() => {
                      if (caseId) {
                        setValue(4);
                        setButtonValue(4);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Opposite Client"
                    onClick={() => {
                      if (caseId) {
                        setValue(5);
                        setButtonValue(5);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />

                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Witness"
                    onClick={() => {
                      if (caseId) {
                        setValue(6);
                        setButtonValue(6);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />
                  
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Hearing"
                    onClick={() => {
                      if (caseId) {
                        setValue(7);
                        setButtonValue(7);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Task"
                    onClick={() => {
                      if (caseId) {
                        setValue(8);
                        setButtonValue(8);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />
                  <Tab
                    sx={{
                      fontSize: { xs: "14px", md: "14px" },
                      fontWeight: "800",
                    }}
                    label="Payment"
                    onClick={() => {
                      if (caseId) {
                        setValue(9);
                        setButtonValue(9);
                      } else {
                        setOpenSnackBar(true);
                      }
                    }}
                  />
                </Tabs>
              </Div>
              {isLoading ? (
              <Div
                sx={{
                  pl: { sm: "0", lg: "8%" },
                  pr: { sm: "0", lg: "8%" },
                  mt: 2,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="secondary" />
                <Typography variant={"h5"}>Loading</Typography>
              </Div>
            ) : (
              <Div sx={{ width: "110%" }}>
                {value === 0 ? (
                  <Case
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    setIsLoading={setIsLoading}
                  />
                ) : value === 1 ? (
                  <ECourtDetails
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    setIsLoading={setIsLoading}
                  />
                ) : value === 2 ? (
                  <Document
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    setIsLoading={setIsLoading}
                  />
                ) : value === 3 ? (
                  <Client
                    addClient={addClient}
                    setAddClient={setAddClient}
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    setIsLoading={setIsLoading}
                  />
                ) : value === 5 ? (
                  <OppositeClient
                    addClient={addClient}
                    setAddClient={setAddClient}
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    setIsLoading={setIsLoading}
                  />
                ) :value === 4 ? (
                  <CorporateClient
                    addClient={addClient}
                    setAddClient={setAddClient}
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    setIsLoading={setIsLoading}
                  />
                ) : value === 7 ? (
                  <Hearing
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    setIsLoading={setIsLoading}
                  />
                ) : value === 8 ? (
                  <ToDo
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    setIsLoading={setIsLoading}
                  />
                ) : value === 6 ? (
                  <Witness
                    addClient={addClient}
                    setAddClient={setAddClient}
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    open={open}
                    setOpen={setOpen}
                    setAddCase={setAddCase}
                    setTeam={setTeam}
                    setIsLoading={setIsLoading}
                  />
                ) : value === 9 ? (
                  <CasePayment
                    handleBackButton={handleBackButton}
                    handleNextButton={handleNextButton}
                    open={open}
                    setOpen={setOpen}
                    setAddCase={setAddCase}
                    setUpdate={setUpdate}
                    setCaseAddedSnackBar={setCaseAddedSnackBar}
                    setIsLoading={setIsLoading}
                  />
                ) : (
                  ""
                )}
              </Div>
            )}
            </Div>
          </CardContent>
        </Card>
      </Div>
    </>
  );
};

export default AddCase;
